export const cityInfo = city => (city ? `${city.name}, ${cityRegion(city)}, ${cityRegionCountry(city)}` : "");

const cityRegionCountry = city => {
  if (!city) {
    return "";
  } else if (!city.region) {
    return "";
  }
  return city.region.country ? city.region.country.name : "";
};

const cityRegion = city => (city.region ? city.region.name : "");
