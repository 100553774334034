import React from "react";

/**
 * Filter guide service custom filter
 */
export default function FilterGuideService({ filter, onChange, enumTypes }) {
  return enumTypes ? (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : ""}
    >
      <option value="">Prikaži sve</option>
      {enumTypes.map(enumType => (
        <option key={enumType.name} value={enumType.name}>
          {enumType.description}
        </option>
      ))}
    </select>
  ) : null;
}
