import React from "react";
import { Switch, Route } from "react-router-dom";

import AlotmanCalendar from "./AlotmanCalendar";
import AlotmanContracts from "./AlotmanContracts/AlotmanContracts";
import AlotmanReservations from "./AlotmanReservations/AlotmanReservations";

const AlotmanRouter = () => {
  return (
    <Switch>
      <Route
        path="/alotman/contracts"
        render={props => <AlotmanContracts {...props} tableName={"alotmanContracts"} />}
      />
      <Route
        path="/alotman/reservations"
        render={props => <AlotmanReservations {...props} tableName={"alotmanReservations"} />}
      />

      <Route path="/" component={AlotmanCalendar} />
    </Switch>
  );
};

export default AlotmanRouter;
