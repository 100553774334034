import gql from "graphql-tag";
import {
  alotmanDayFragment,
  contigentFragment,
  mainTerminFragment,
  partnerClientFragment,
  serviceFragment,
  serviceTypeFragment,
  typologyFragment,
} from "../fragments";

export const listEstablishment = gql`
  query($input: Condition) {
    listEstablishment(input: $input) {
      id
      name
      city {
        id
        name
        count
        postal_code
        area_code
      }
      typologies {
        id
        code
        establishment_code
        persons_capacity
        priority
        typology_translations {
          id
          language_id
          language {
            id
            code
          }
          name
          desc
        }
      }
    }
  }
`;

export const getAlotmanDay = gql`
  query($id: ID!) {
    getAlotmanDay(id: $id) {
      ...AlotmanDay
      contracts {
        id
        offer_contract_document {
          id
          partner_client {
            id
            name
            first_name
            last_name
          }
          contract {
            id
            external_id
            reservations {
              id
              reservation_status {
                id
                code
                desc
              }
            }
          }
        }
      }
    }
  }
  ${alotmanDayFragment}
`;

export const getEstablishment = gql`
  query($id: ID!) {
    getEstablishment(id: $id) {
      id
      name
      typologies {
        ...Typology
      }
    }
  }
  ${typologyFragment}
`;

export const getEstablishmentCalendar = gql`
  query($establishment_id: ID!, $from: DateTime, $to: DateTime) {
    getAlotmanCalendar(input: { establishment_id: $establishment_id, from: $from, to: $to }) {
      establishment_id
      alotman_days {
        ...AlotmanDay
      }
    }
  }
  ${alotmanDayFragment}
`;

export const listAlotmanDayByTypology = gql`
  query($typology_id: ID, $from: DateTime, $to: DateTime) {
    listAlotmanDayByTypology(input: { where: { typology_id: $typology_id, from: $from, to: $to } }) {
      ...AlotmanDay
    }
  }
  ${alotmanDayFragment}
`;

export const listReservation = gql`
  query($input: ReservationCondition) {
    listReservation(input: $input) {
      id
      guest_name
      from
      to
      count
      remark
      service_type_id
      starting_service_id
      ending_service_id
      check_in
      check_out
      reservation_status_id
      typology_id
      typology {
        ...Typology
      }
      service_type {
        ...ServiceType
        services {
          ...Service
        }
      }
      starting_service {
        id
        service_translations {
          id
          name
          language_id
        }
      }
      ending_service {
        id
        service_translations {
          id
          name
          language_id
        }
      }
      contract {
        id
        offer_contract_document {
          id
          language_id
          release_day
          partner_client {
            ...Partner
          }
          termin_periods {
            ...MainTermin
            contigents {
              ...Contigent
            }
          }
        }
      }
      created_by {
        id
        first_name
        last_name
        signature_url
      }
    }
  }
  ${serviceTypeFragment}
  ${serviceFragment}
  ${mainTerminFragment}
  ${contigentFragment}
  ${partnerClientFragment}
  ${typologyFragment}
`;

export const listReservationStatus = gql`
  {
    listReservationStatus {
      id
      code
      desc
    }
  }
`;
