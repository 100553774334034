import React, { Component } from "react";
import { connect } from "react-redux";

import { changeDocumentParam, changeTemplateLanguage } from "../../../../store/actions";

import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import TemplateVatHOC from "./TemplateVat";
import TemplateTaxHOC from "./TemplateTax";
import TemplateFreePlaceHOC from "./TemplateFreePlace";
import TemplateMinPersonsHOC from "./TemplateMinPersons";
import TemplateBookingStateHOC from "./TemplateBookingState";
import TemplateRoomStateHOC from "./TemplateRoomState";
import TemplateProgramHOC from "./TemplateProgram";
import TemplateContentHOC from "./TemplateContent";
import TemplateProgramChangesHOC from "./TemplateProgramChanges";
import TemplateStornoHOC from "./TemplateStorno";
import TemplatePayingHOC from "./TemplatePaying";
import TemplateSurchargesHOC from "./TemplateSurcharges";

class Templates extends Component {
  render() {
    const isNotDraft = true;
    const { language_id } = this.props;

    return (
      <React.Fragment>
        <div className="form-unit-double">
          <div />
          {!language_id ? (
            <Typography variant="body2" gutterBottom>
              Potrebno je odabrati jezik prije upisa teksta
            </Typography>
          ) : (
            <div />
          )}

          <TemplateVatHOC isNotDraft={isNotDraft} vat_content={this.props.vat_content} />
        </div>
        <TemplateTaxHOC
          isNotDraft={isNotDraft}
          sojourn_tax_value={this.props.sojourn_tax_value}
          sojourn_tax_content={this.props.sojourn_tax_content}
        />

        <TemplateFreePlaceHOC isNotDraft={isNotDraft} free_place_content={this.props.free_place_content} />

        <div>
          <h2>Ostale usluge</h2>
        </div>

        <div className="form-unit-double">
          <TemplateMinPersonsHOC
            isNotDraft={isNotDraft}
            notification_price_validity={this.props.notification_price_validity}
            price_validity_content={this.props.price_validity_content}
          />

          <TemplateBookingStateHOC
            isNotDraft={isNotDraft}
            booking_state_content={this.props.booking_state_content}
            notification_booking_state={this.props.notification_booking_state}
          />

          <TemplateRoomStateHOC
            isNotDraft={isNotDraft}
            notification_room_list={this.props.notification_room_list}
            room_list_content={this.props.room_list_content}
          />
        </div>

        <div className="form-unit-double">
          <TemplateProgramHOC program_content={this.props.program_content} isNotDraft={isNotDraft} />

          <TemplateContentHOC content_content={this.props.content_content} isNotDraft={isNotDraft} />

          <TemplateProgramChangesHOC
            program_changes_content={this.props.program_changes_content}
            isNotDraft={isNotDraft}
          />
        </div>

        <div className="form-unit-double">
          <TemplateStornoHOC
            isNotDraft={isNotDraft}
            notification_storno={this.props.notification_storno}
            storno_content={this.props.storno_content}
          />

          <TemplatePayingHOC
            isNotDraft={isNotDraft}
            notification_payment={this.props.notification_payment}
            payment_content={this.props.payment_content}
          />
        </div>

        <TemplateSurchargesHOC isNotDraft={isNotDraft} surcharges_content={this.props.surcharges_content} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      offer_contract_document: {
        language_id,
        sojourn_tax_value,
        notification_price_validity,
        notification_booking_state,
        notification_room_list,
        notification_storno,
        notification_payment,
        sojourn_tax_content,
        vat_content,
        free_place_content,
        price_validity_content,
        surcharges_content,
        payment_content,
        storno_content,
        program_changes_content,
        content_content,
        program_content,
        room_list_content,
        booking_state_content,
      },
    },
  } = state.offer;

  return {
    language_id,
    sojourn_tax_value,
    notification_price_validity,
    notification_booking_state,
    notification_room_list,
    notification_storno,
    notification_payment,
    sojourn_tax_content,
    vat_content,
    free_place_content,
    price_validity_content,
    surcharges_content,
    payment_content,
    storno_content,
    program_changes_content,
    content_content,
    program_content,
    room_list_content,
    booking_state_content,
  };
};

export default connect(mapStateToProps, { changeDocumentParam, changeTemplateLanguage })(withRouter(Templates));
