import React, { Component } from "react";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import { autosize } from "../../../../utility/globals";

class GuideDriverDrawer extends Component {
  state = {
    guide: "",
    driver: "",
    comment: "",
  };

  changeGuide = value => {
    this.setState({ guide: value === "true" });
  };
  changeDriver = value => {
    this.setState({ driver: value === "true" });
  };

  componentDidMount = () => {
    const { guide, driver, comment } = this.props;
    this.setState({ driver, guide, comment });
  };

  prepareToSend = () => {
    const { guide, driver, comment } = this.state;
    let obj = {};
    if (guide !== this.props.guide) {
      obj["guide"] = guide;
    }
    if (driver !== this.props.driver) {
      obj["driver"] = driver;
    }
    if (comment !== this.props.comment) {
      obj["driver_guide_comment"] = comment;
    }

    return {
      ...obj,
    };
  };

  componentDidUpdate = () => {
    // Resizing textarea
    autosize(this.refs.remark);
  };

  updateData = async e => {
    e.preventDefault();

    try {
      await this.props.mutate({
        variables: {
          id: this.props.id,
          patch: this.prepareToSend(),
        },
      });

      toastr.success("Uspješno ažurirano");

      this.props.toggle();
    } catch (error) {}
  };

  render() {
    const { open, toggle } = this.props;
    const { guide, driver, comment } = this.state;

    return (
      <DrawerWrapper toggle={toggle} update={this.updateData} open={open} title="Promjena vodiča / vozača">
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="select-guide"
              select
              label="Vodić"
              className=""
              value={`${guide}`}
              onChange={({ target: { value } }) => this.changeGuide(value)}
            >
              <MenuItem key={0} value={"false"}>
                Ne
              </MenuItem>
              <MenuItem key={1} value={"true"}>
                Da
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="select-driver"
              select
              label="Vozač"
              className=""
              value={`${driver}`}
              onChange={({ target: { value } }) => this.changeDriver(value)}
            >
              <MenuItem key={0} value={"false"}>
                Ne
              </MenuItem>
              <MenuItem key={1} value={"true"}>
                Da
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <textarea
              id="remark"
              placeholder="Napomena"
              ref="remark"
              onKeyUp={el => autosize(el.target)}
              className="preview-content-content preview-textarea textarea-stretch"
              value={comment || ""}
              onChange={({ target: { value } }) => this.setState({ comment: value })}
            />
          </Grid>
        </Grid>
      </DrawerWrapper>
    );
  }
}

export default compose(graphql(updateTerminPeriod))(GuideDriverDrawer);
