import { List, ListItem, makeSelectable } from "material-ui/List";
import Paper from "material-ui/Paper";
import Subheader from "material-ui/Subheader";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ActivityRouter from "./Activity/activityRouter";
import EstablishmentRouter from "./Establishment/establishmentRouter";
import PartnerClientRouter from "./PartnerClient/partnerClientRouter";
import PartnerServiceTypesRouter from "./PartnerServiceTypes/partnerServiceTypesRouter";
import PlacesRouter from "./Places/placesRouter";
import ServiceRouter from "./Services/serviceRouter";
import TemplateRouter from "./Template/templateRouter";
import TypologyGroupRouter from "./TypologyGroup/typologyGroupRouter";
import UserRouter from "./User/userRouter";
import TemplateAnnouncementRouter from "./TemplateAnnouncement/templateAnnouncementRouter";

let SelectableList = makeSelectable(List);

function wrapState(ComposedComponent) {
  return class SelectableList extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      defaultValue: PropTypes.number.isRequired,
    };

    componentWillMount() {
      this.setState({
        selectedIndex: this.props.defaultValue,
      });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.defaultValue !== this.props.defaultValue) {
        this.setState({
          selectedIndex: this.props.defaultValue,
        });
      }
    }

    handleRequestChange = (event, index) => {
      this.setState({
        selectedIndex: index,
      });
      this.props.handleRequestChange(index);
    };

    render() {
      return (
        <ComposedComponent value={this.state.selectedIndex} onChange={this.handleRequestChange}>
          {this.props.children}
        </ComposedComponent>
      );
    }
  };
}

SelectableList = wrapState(SelectableList);

class Administration extends Component {
  state = {
    selected: 2,
  };

  handleRequestChange = index => {
    this.setState({
      selected: index,
    });
  };

  componentDidUpdate() {
    const newRouteIndex = this.findDefaultValue();

    if (newRouteIndex !== this.state.selected) {
      this.setState({
        selected: newRouteIndex,
      });
    }
  }

  findDefaultValue() {
    const { pathname } = this.props.location;

    switch (true) {
      case !pathname.split("/administracija/grad_regija_drzava")[0]:
        return 1;
      case !pathname.split("/administracija/grupa_tipologije")[0]:
        return 2;
      case !pathname.split("/administracija/smjestaj")[0]:
        return 3;
      case !pathname.split("/administracija/aktivnosti")[0]:
        return 4;
      case !pathname.split("/administracija/zaposlenik")[0]:
        return 5;
      case !pathname.split("/administracija/usluga_smjestaja")[0]:
        return 6;
      case !pathname.split("/administracija/tip_usluge_partnera")[0]:
        return 7;
      case !pathname.split("/administracija/partner_klijent")[0]:
        return 8;
      case !pathname.split("/administracija/predlozak")[0]:
        return 9;
      case !pathname.split("/administracija/najava_predlozak")[0]:
        return 10;

      default:
        return 1;
    }
  }

  changeRoute = routeToGo => () => {
    const path = this.props.location.pathname;
    // refresh same route
    if (path.includes(routeToGo) && path === routeToGo) {
      this.props.history.replace(`/administracija/reload`);
      setTimeout(() => {
        this.props.history.replace(routeToGo);
      });
      return;
    }

    // if subroute of route,  keep router stack intact
    if (path.includes(routeToGo)) {
      this.props.history.push(`/administracija/reload`);
      setTimeout(() => {
        this.props.history.replace(routeToGo);
      });
      return;
    }

    this.props.history.push(routeToGo);
  };

  render() {
    return (
      <div className="mainAdministration">
        <Paper>
          <SelectableList
            defaultValue={this.state.selected}
            handleRequestChange={this.handleRequestChange.bind(this)}
            changeRoute={this.changeRoute}
          >
            <Subheader>Administracija</Subheader>

            <ListItem
              value={1}
              primaryText="Gradovi"
              onClick={this.changeRoute("/administracija/grad_regija_drzava")}
            />

            <ListItem
              value={2}
              primaryText="Grupa tipologije"
              onClick={this.changeRoute("/administracija/grupa_tipologije")}
            />
            <ListItem value={3} primaryText="Smještaj" onClick={this.changeRoute("/administracija/smjestaj")} />
            <ListItem value={4} primaryText="Aktivnosti" onClick={this.changeRoute("/administracija/aktivnosti")} />
            <ListItem value={5} primaryText="Zaposlenici" onClick={this.changeRoute("/administracija/zaposlenik")} />
            <ListItem
              value={6}
              primaryText="Usluge smještaja"
              onClick={this.changeRoute("/administracija/usluga_smjestaja")}
            />
            <ListItem
              value={7}
              primaryText="Tip usluge partnera"
              onClick={this.changeRoute("/administracija/tip_usluge_partnera")}
            />
            <ListItem
              value={8}
              primaryText="Partneri / Klijenti"
              onClick={this.changeRoute("/administracija/partner_klijent")}
            />
            <ListItem value={9} primaryText="Predložak" onClick={this.changeRoute("/administracija/predlozak")} />
            <ListItem
              value={10}
              primaryText="Predložak / Najava"
              onClick={this.changeRoute("/administracija/najava_predlozak")}
            />
          </SelectableList>
        </Paper>
        <Paper>
          <Switch>
            <Route path="/administracija/grad_regija_drzava" component={PlacesRouter} />
            <Route path="/administracija/grupa_tipologije" component={TypologyGroupRouter} />
            <Route path="/administracija/smjestaj" component={EstablishmentRouter} />
            <Route path="/administracija/zaposlenik" component={UserRouter} />
            <Route path="/administracija/aktivnosti" component={ActivityRouter} />
            <Route path="/administracija/usluga_smjestaja" component={ServiceRouter} />
            <Route path="/administracija/tip_usluge_partnera" component={PartnerServiceTypesRouter} />
            <Route path="/administracija/partner_klijent/" component={PartnerClientRouter} />
            <Route path="/administracija/predlozak/" component={TemplateRouter} />
            <Route path="/administracija/najava_predlozak/" component={TemplateAnnouncementRouter} />
            <Route path="/administracija/reload/" component={null} />

            <Redirect to="/administracija/grad_regija_drzava" />
          </Switch>
        </Paper>
      </div>
    );
  }
}

export default Administration;
