import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BootstrapInput from "../../../../../components/UI/BootstrapLikeInput";
import { autosize } from "../../../../../utility/globals";
import { FormControl, FormHelperText } from "@material-ui/core";

class CreateServiceTranslations extends Component {
  render() {
    const { listLanguage, service_translations, changeTranslationField, error, errorMsg } = this.props;

    const showServiceTranslation =
      service_translations &&
      listLanguage &&
      listLanguage.map((language, index) => {
        const service_translation = service_translations.find(translation => translation.language_id === language.id);

        if (!service_translation) {
          return null;
        }

        return (
          <TableRow key={language.id} className="align-top">
            <TableCell component="th" scope="row">
              {language.desc}
            </TableCell>
            <TableCell>
              <BootstrapInput
                label=""
                id="name"
                error={error(`/partner_client_service_translations/${index}/name`)}
                errorMsg={errorMsg(`/partner_client_service_translations/${index}/name`)}
                value={service_translation.name}
                onChange={({ target: { value } }) => changeTranslationField({ key: "name", value }, language.id)}
              />
            </TableCell>
            <TableCell padding="dense">
              <FormControl fullWidth error={error(`/partner_client_service_translations/${index}/description`)}>
                <textarea
                  id="service_translation"
                  onKeyUp={el => autosize(el.target)}
                  className={`preview-content-content preview-textarea textarea-stretch ${
                    error(`/partner_client_service_translations/${index}/description`) ? "textarea-error" : ""
                  }`}
                  value={service_translation.description}
                  onChange={({ target: { value } }) => {
                    changeTranslationField({ key: "description", value }, language.id);
                  }}
                />
                {error(`/partner_client_service_translations/${index}/description`) && (
                  <FormHelperText>
                    {errorMsg(`/partner_client_service_translations/${index}/description`)}
                  </FormHelperText>
                )}
              </FormControl>
            </TableCell>
          </TableRow>
        );
      });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jezik</TableCell>
            <TableCell>Naziv</TableCell>
            <TableCell>Opis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{showServiceTranslation ? showServiceTranslation : <React.Fragment />}</TableBody>
      </Table>
    );
  }
}

export default CreateServiceTranslations;
