import React from "react";
import { graphql } from "react-apollo";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import AutocompleteSelect from "../../../components/UI/AutocompleteSelect";

/**
 * LIST ROLES
 */
import { listRole } from "../../../graphql/query/administration";
import { getRoleInfo } from "../../../utility/user";
import { withRouter } from "react-router-dom";
import Upload from "../../../components/UI/Upload";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import { FormHelperText } from "@material-ui/core";

class UserForm extends React.Component {
  static EditUserRoles = ({ edit, role_ids, removeRole, listRole }) =>
    edit
      ? role_ids &&
        role_ids.map(role => (
          <Grid item key={role.id}>
            <Chip
              label={getRoleInfo(role.id, listRole)}
              onDelete={role_ids.length > 1 ? () => removeRole(role.id) : null}
              clickable={true}
            />
          </Grid>
        ))
      : null;

  static CreateUserRoles = ({ edit, role_ids, removeRole, listRole }) =>
    !edit
      ? role_ids &&
        role_ids.map(role => (
          <Grid item key={role.id}>
            <Chip label={getRoleInfo(role.id, listRole)} onDelete={() => removeRole(role.id)} />
          </Grid>
        ))
      : null;

  render() {
    const {
      user,
      data: { loading, listRole },
      changeUserData,
      addRole,
      removeRole,
      onSubmit,
      files,
      onDrop,
      deleteUploadedFile,
      error,
      errorMsg,
      edit,
    } = this.props;

    if (loading) {
      return <div>Učitavanje...</div>;
    }

    // stores displayed roles
    const role_ids = user && user.role_ids ? user.role_ids.map(({ id }) => id) : [];

    return (
      <form className="user-form" onSubmit={onSubmit}>
        <div className="form-control-grid">
          <FormControl fullWidth error={error("/first_name")}>
            <InputLabel shrink={true} htmlFor="first_name">
              Ime
            </InputLabel>
            <Input
              id="first_name"
              name="first_name"
              value={user.first_name || ""}
              onChange={({ target: { value } }) => changeUserData({ key: "first_name", value })}
            />
            {error("/first_name") && <FormHelperText>{errorMsg("/first_name")}</FormHelperText>}
          </FormControl>
        </div>
        <div className="form-control-grid">
          <FormControl fullWidth error={error("/last_name")}>
            <InputLabel shrink={true} htmlFor="last_name">
              Prezime
            </InputLabel>
            <Input
              id="last_name"
              name="last_name"
              value={user.last_name || ""}
              onChange={({ target: { value } }) => changeUserData({ key: "last_name", value })}
            />
            {error("/last_name") && <FormHelperText>{errorMsg("/last_name")}</FormHelperText>}
          </FormControl>
        </div>
        <div className="form-control-grid">
          <FormControl fullWidth error={error("/username")}>
            <InputLabel shrink={true} htmlFor="username">
              Korisničko ime
            </InputLabel>
            <Input
              id="username"
              name="username"
              value={user.username || ""}
              onChange={({ target: { value } }) => changeUserData({ key: "username", value })}
            />
            {error("/username") && <FormHelperText>{errorMsg("/username")}</FormHelperText>}
          </FormControl>
        </div>
        <div className="form-control-grid">
          <FormControl fullWidth error={error("/password")}>
            <InputLabel shrink={true} htmlFor="password">
              Zaporka
            </InputLabel>
            <Input
              id="password"
              name="password"
              value={user.password || ""}
              onChange={({ target: { value } }) => changeUserData({ key: "password", value })}
            />
            {error("/password") && <FormHelperText>{errorMsg("/password")}</FormHelperText>}
          </FormControl>
        </div>
        {edit && (
          <div className="form-control-grid">
            <FormControl fullWidth>
              <InputLabel shrink={true} htmlFor="previous_password">
                Prijašnja zaporka
              </InputLabel>
              <Input
                id="previous_password"
                name="previous_password"
                value={user.previous_password || ""}
                onChange={({ target: { value } }) => changeUserData({ key: "previous_password", value })}
              />
            </FormControl>
          </div>
        )}
        <div className="form-control-grid">
          <AutocompleteSelect
            label="Role"
            autocompleteHandler={addRole}
            error={error("/role_ids")}
            errorMsg={errorMsg("/role_ids")}
            dataSource={
              listRole &&
              listRole
                .filter(
                  // if role is already displayed, filter it out
                  role => !role_ids.includes(role.id),
                )
                .map(
                  // show only remaining roles
                  role => ({
                    value: role.id,
                    label: role.desc,
                  }),
                )
            }
            placeholder="Odabir role"
          />
        </div>
        <br />
        <Grid container spacing={8}>
          <UserForm.CreateUserRoles
            edit={this.props.match.params.id}
            role_ids={user.role_ids}
            listRole={listRole}
            removeRole={removeRole}
          />
          <UserForm.EditUserRoles
            edit={this.props.match.params.id}
            role_ids={user.role_ids}
            listRole={listRole}
            removeRole={removeRole}
          />
        </Grid>
        <br />
        <Grid container spacing={16}>
          <Grid item>
            <label className="form-label-left label-margin-block-start-14">Potpis</label>
          </Grid>
          {user.signature_id ? (
            <Grid item lg container sm={12}>
              <Grid item xs={10} sm={10} lg={3} className="flex-left">
                <Button href={user.signature_url} variant="flat" target="_blank">
                  {user.signature_filename}
                </Button>
              </Grid>
              <Grid item xs={2} sm={2} lg={1}>
                <IconButton aria-label="Delete" href="" onClick={() => deleteUploadedFile(user.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <br />
        {!user.signature_url && (
          <Grid container spacing={16}>
            <Grid item>
              <label className="form-label-left">Datoteke za poslati</label>
            </Grid>
            <Grid item sm container xs={12}>
              <Upload
                multiple={false}
                onDrop={onDrop}
                files={files}
                deletePreparedFileToUpload={this.props.deletePreparedFileToUpload}
              />
            </Grid>
          </Grid>
        )}
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Spremi
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          onClick={e => {
            e.preventDefault();
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/zaposlenik/");
            } else {
              this.props.history.goBack();
            }
          }}
        >
          Poništi
        </Button>
      </form>
    );
  }
}

export default graphql(listRole)(withRouter(UserForm));
