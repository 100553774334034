import { useCallback, useEffect } from "react";

// libs
import { useQuery } from "react-apollo";
import { get } from "lodash";
// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectProgramId,
  selectSupplier,
  selectSupplierAnnouncements,
  setGuideScheduleSelectTypeOpen,
  setSupplierAnnouncements,
} from "src/views/Guides/guidesSlice";
import {
  selectAnnouncementSelected,
  setAnnouncementSelected,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { SUPPLIER_ANNOUNCEMENTS } from "src/graphql/query/announcement";

// constants
import { announcementTypes } from "src/utility/constants";

/**
 * useAnnouncementList is entry point of data for announcementList component
 */
export default function useAnnouncementList() {
  // redux state
  const announcementSelected = useSelector(selectAnnouncementSelected);
  const programId = useSelector(selectProgramId);
  const supplier = useSelector(selectSupplier);
  const supplierAnnouncements = useSelector(selectSupplierAnnouncements);

  const dispatch = useDispatch();

  /**
   * handle get program announcements
   */
  const { data, loading: loadingAnnouncements } = useQuery(SUPPLIER_ANNOUNCEMENTS, {
    variables: {
      program_id: programId,
      establishment_id: supplier.establishment_id,
      guide_id: supplier.guide_id,
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setSupplierAnnouncements(get(data, "getAllAnnouncementsByProgram", [])));
    }
  }, [data, dispatch]);

  /**
   * get type description in croatian
   */
  const getAnnouncementTypeDescription = announcementEnumType => {
    const announcementType =
      announcementTypes && announcementTypes.find(announcementType => announcementType.name === announcementEnumType);

    return announcementType.description;
  };

  /*
   * handle on create click
   */
  const handleOnCreateClick = useCallback(() => {
    dispatch(setGuideScheduleSelectTypeOpen(true));
  }, [dispatch]);

  /*
   * default select first element from list
   */
  useEffect(() => {
    if (supplierAnnouncements && supplierAnnouncements.length && !announcementSelected) {
      dispatch(
        setAnnouncementSelected({
          id: get(supplierAnnouncements, "[0].id"),
          announcement_type: get(supplierAnnouncements, "[0].announcement_type"),
        }),
      );
    }
  }, [announcementSelected, dispatch, supplierAnnouncements]);

  /**
   * handle on announcement select
   */
  const handleOnAnnouncementSelect = announcement => {
    // check for already selected announcement
    if (announcementSelected.id === announcement.id) {
      return;
    }

    dispatch(
      setAnnouncementSelected({
        id: announcement.id,
        announcement_type: announcement.announcement_type,
      }),
    );
  };

  return {
    announcementSelected,
    getAnnouncementTypeDescription,
    handleOnCreateClick,
    handleOnAnnouncementSelect,
    loadingAnnouncements,
    supplierAnnouncements,
  };
}
