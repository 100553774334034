import React, { Component } from "react";
import toastr from "toastr";
import DrawerWrapper from "../../../../components/UI/Drawer";

import DateRangePicker from "../../../../components/UI/DateRangePicker";
import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import { graphql } from "react-apollo";
import { formatDate } from "../../../../utility/dates";
import { terminsFragment } from "../../../../graphql/fragments";

class TerminsDrawer extends Component {
  state = {
    from: "",
    to: "",
  };

  componentDidMount = () => {
    const { from, to } = this.props;
    this.setState({ from, to });
  };

  prepareDataToSend = () => {
    let obj = {};
    const { from, to } = this.state;

    if (from !== this.props.from) {
      obj["from"] = from;
    }
    if (to !== this.props.to) {
      obj["to"] = to;
    }

    return obj;
  };

  updateData = async () => {
    const { from, to } = this.state;

    if (formatDate(from) !== formatDate(this.props.from) || formatDate(to) !== formatDate(this.props.to)) {
      try {
        await this.props.mutate({
          variables: {
            id: this.props.id,
            patch: this.prepareDataToSend(),
          },
          update: (store, { data: { updateTerminPeriod } }) => {
            store.writeFragment({
              id: `Termin:${updateTerminPeriod.id}`,
              fragment: terminsFragment,
              fragmentName: "Termin",
              data: updateTerminPeriod,
            });
          },
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  handleFromChange = date => {
    if (date) {
      this.setState({ from: date });
    }
  };

  handleToChange = date => {
    if (date) {
      this.setState({ to: date });
    }
  };

  render() {
    const { open, toggle } = this.props;
    const { from, to } = this.state;

    return (
      <DrawerWrapper toggle={toggle} open={open} update={this.updateData} title="Izmjena termina">
        <DateRangePicker
          from={from}
          to={to}
          handleFromChange={this.handleFromChange}
          handleToChange={this.handleToChange}
          vertical
        />
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(TerminsDrawer);
