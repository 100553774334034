import React from "react";

// labels
import { HINT_COMMENT } from "src/utility/labels";

// hooks
import useLayoutComment from "./hooks/useLayoutComment";
// components
import TextFieldMultilineUncontrolled from "src/components/UI/TextFieldMultilineUncontrolled";

/**
 * LayoutComment contains all components inside announcement drawer
 */
export default function LayoutComment() {
  const { guideLayoutComment, onGuideLayoutCommentChange } = useLayoutComment();

  return (
    <div>
      <TextFieldMultilineUncontrolled
        className="textField"
        rows={4}
        defaultValue={guideLayoutComment || ""}
        placeholder={HINT_COMMENT}
        customOnChange={event => onGuideLayoutCommentChange(event)}
      />
    </div>
  );
}
