import React, { Component, Fragment } from "react";

class PreviewService extends Component {
  render() {
    const { check_in, check_out } = this.props.voucher;

    return (
      <Fragment>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>Check in</h4>
          </div>
          <div disabled id="check_in" className="preview-content-div preview-textarea">
            {check_in}
          </div>
        </div>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>Check out</h4>
          </div>

          <div disabled id="check_in" className="preview-content-div preview-textarea">
            {check_out}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PreviewService;
