import React from "react";

// ui
import Button from "@material-ui/core/Button";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { blue500 } from "material-ui/styles/colors";
import Grid from "@material-ui/core/Grid";

// hooks
import useAdditionalFunctions from "./hooks/useAnnouncementActions";

// custom mui theme
const theme = createMuiTheme({
  palette: {
    primary: { main: blue500 },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: "5px",
      },
      raisedPrimary: {
        maxWidth: 100,
        color: "white",
      },
    },
  },
});

/**
 * AnnouncementActions column
 */
export default function AnnouncementActions({ guideId, establishmentId }) {
  const { isDisabled, handleOnAnnouncementClick } = useAdditionalFunctions(guideId, establishmentId);
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container justify="center">
        <Grid item xs={5}>
          <Button
            disabled={isDisabled()}
            onClick={() => handleOnAnnouncementClick()}
            color="primary"
            fullWidth
            variant="contained"
            size="small"
          >
            Najave
          </Button>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}
