import moment from "moment";

export const formatDate = date => moment(date).format("DD.MM.YYYY");

export const dateDiff = (from, to, byType) => moment(to).diff(moment(from), byType);

export const formatDateISO8601 = date => moment(date).format("YYYY-MM-DD");

/**
 * check to see if there's block dates in selected period of time
 */
export const isDatesBlocked = (startDate, endDate, dates) => {
  const diff = moment(endDate).diff(startDate, "days");

  for (let i = 0; i < diff; i++) {
    const checkDate = formatDate(moment(startDate).add(i, "d"));

    if (!dates.find(day => formatDate(day) === checkDate)) {
      return true;
    }
  }

  return false;
};

/**
 * get moment from string date
 */
export const getMomentFromString = date => {
  return moment(new Date(date));
};

/**
 * get today date
 */
export const getCurrentDate = () => {
  return moment().format("MM-DD-YYYY");
};

/**
 * format date for view as DD.MM.YYYY
 */
export const getDateForView = date => {
  return moment(date).format("DD.MM.YYYY | HH:mm");
};
