import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_TOP, FONT_SIZE_LABEL } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_TOP,
    display: "flex",
    flexDirection: "column",
  },

  textBold: {
    fontSize: FONT_SIZE_LABEL,
    fontFamily: "OpenSansBold",
  },

  text: {
    fontSize: FONT_SIZE_LABEL,
    fontFamily: "OpenSans",
  },
});

// VoucherSubjectMultiline
export default function VoucherSubjectMultiline({ subjectTextValues }) {
  return (
    <View style={styles.container}>
      {subjectTextValues &&
        subjectTextValues.map((subjectText, index) => {
          if (index === 0) {
            return <Text style={styles.textBold}>{subjectText}</Text>;
          } else {
            return <Text style={styles.text}>{subjectText}</Text>;
          }
        })}
    </View>
  );
}
