import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";

import { updateService } from "../../../../graphql/mutation/administration";
import { getService as fetchService } from "../../../../graphql/query/administration";

import ServiceForm from "./ServiceForm";
import { prepareForSending } from "../../../../utility/prepare";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class EditService extends React.Component {
  state = {
    service: {
      service_type_id: "",
      service_translations: [
        {
          name: "",
          language_id: "",
        },
      ],
    },
  };

  async componentDidMount() {
    const res = await this.props.client.query({
      query: fetchService,
      variables: {
        id: this.props.service.id,
      },
    });

    const { getService } = res.data;

    this.setState({
      service: { ...prepareForSending(getService, ["__typename"]) },
    });
  }

  changeFieldData = obj => {
    this.setState(prev => ({
      ...prev,
      service: {
        ...prev.service,
        [obj.key]: obj.value,
      },
    }));
  };

  changeTranslationFieldData = language_id => ({ target: { value } }) => {
    this.setState(prev => ({
      ...prev,
      service: {
        ...prev.service,
        service_translations: prev.service.service_translations.map(translation => {
          if (translation.language_id === language_id) {
            return {
              ...translation,
              name: value,
            };
          }
          return translation;
        }),
      },
    }));
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          id: this.props.service.id,
          patch: {
            ...prepareForSending(prepareForSending(this.state.service, ["id"], ["service_translations"]), ["language"]),
          },
        },
      });
      toastr.success("Usluga uspješno ažurirana");

      this.props.closeEdit();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title="Ažuriranje uključene usluge" onBackPressed={this.props.closeEdit} />
        <ServiceForm
          onSubmit={this.submit}
          service={this.state.service}
          cancelToggle={this.props.closeEdit}
          onChangeField={this.changeFieldData}
          changeTranslationField={this.changeTranslationFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(updateService)(withApollo(EditService));
