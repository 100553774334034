import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";

import { formatDate } from "../../../utility/dates";
import { config, removeDuplicateObjects } from "../../../utility/globals";
import { getTypology } from "../../../graphql/query/administration";
import { connect } from "react-redux";

class TerminHotel extends Component {
  state = {
    ui: null,
  };

  async componentDidMount() {
    const { type_id, termin } = this.props;
    // If not advanced
    if (config.offer_type[type_id] !== "Tour") {
      let ui;

      switch (true) {
        case !termin:
          ui = <div>Nema termina</div>;
          break;
        case !termin.contigents || !termin.contigents.length:
          ui = <div>Nema kontigenta</div>;
          break;
        default:
          // fetching establishments name
          const establishments = await Promise.all(
            termin.contigents &&
              termin.contigents.map(async contigent => {
                if (contigent.typology) {
                  return contigent.typology.establishment;
                } else {
                  const response = await this.props.client.query({
                    query: getTypology,
                    variables: {
                      id: contigent.typology_id,
                    },
                  });

                  return response.data.getTypology.establishment;
                }
              }),
          );

          //removing duplicate hotels of subtermin
          const filteredEstablishments = removeDuplicateObjects(establishments);

          // Fetching hotels of subtermin

          ui =
            filteredEstablishments &&
            filteredEstablishments.map((establishment, cIndex) => {
              return (
                <p key={cIndex}>{`${establishment.name} - ${establishment.city ? establishment.city.name : ""}`}</p>
              );
            });
          break;
      }

      this.setState({
        ui,
      });
    }
  }

  render() {
    const { int, comment_establishment } = this.props;

    return this.state.ui ? (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.hotel}</h4>
        </div>
        <div className="preview-no-padding preview-no-margin">
          <div className="preview-content-div preview-no-padding preview-no-margin bold-text">{this.state.ui}</div>
          {comment_establishment.trim().length ? (
            <div disabled id="comment_establishment" className="preview-content-div preview-textarea">
              {comment_establishment.trim()}
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

class SubterminHotels extends Component {
  state = { ui: null };

  async componentDidMount() {
    const { type_id, termin_periods } = this.props;
    // If  advanced
    if (config.offer_type[type_id] === "Tour") {
      let ui;

      if (!termin_periods || !termin_periods.length) {
        ui = <div>Nema termina</div>;
      } else {
        const { subtermin_periods } = termin_periods[0];

        if (!subtermin_periods || !subtermin_periods.length) {
          ui = <div>Nema odabranog međutermina</div>;
        } else {
          ui =
            subtermin_periods &&
            [...subtermin_periods]
              .sort(function(a, b) {
                return new Date(a.from) - new Date(b.from);
              })
              .map(termin => {
                let ui_subtermin;

                if (!termin.contigents || !termin.contigents.length) {
                  ui_subtermin = <div>Nema kontigenta</div>;
                  return ui_subtermin;
                } else {
                  // fetching establishments name
                  const establishments =
                    termin.contigents && termin.contigents.map(contigent => contigent.typology.establishment);

                  //removing duplicate hotels of subtermin
                  const filteredEstablishments = removeDuplicateObjects(establishments);

                  // Fetching hotels of subtermin

                  ui_subtermin =
                    filteredEstablishments &&
                    filteredEstablishments.map((establishment, cIndex) => {
                      return (
                        <p key={cIndex}>{`${establishment.name} - ${
                          establishment.city ? establishment.city.name : ""
                        }`}</p>
                      );
                    });

                  return (
                    <Fragment key={termin.id}>
                      <div className="preview-content-div preview-no-padding preview-no-margin">
                        <p>
                          {formatDate(termin.from)} - {formatDate(termin.to)}
                        </p>
                      </div>
                      <div className="preview-content-div  preview-no-padding preview-no-margin">{ui_subtermin}</div>
                    </Fragment>
                  );
                }
              });
        }
      }
      this.setState({
        ui,
      });
    }
  }

  render() {
    const { int, comment_establishment } = this.props;

    return this.state.ui ? (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.hotels}</h4>
        </div>
        <div className="preview-no-padding preview-no-margin">
          <div className="preview-content-hotels-double preview-no-padding preview-no-margin bold-text">
            {this.state.ui}
          </div>
          {comment_establishment.trim().length ? (
            <div disabled id="comment_establishment" className="preview-content-div preview-textarea">
              {comment_establishment.trim()}
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  const {
    templateInt,
    preview: {
      offer_contract_document: { offer_contract_document_type_id: type_id, termin_periods, comment_establishment },
    },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();
  const termin = termin_periods[0];

  return {
    int,
    type_id,
    termin,
    termin_periods,
    comment_establishment,
  };
};

const TerminHotelPreview = connect(mapStateToProps)(withApollo(TerminHotel));
const SubterminHotelsPreview = connect(mapStateToProps)(withApollo(SubterminHotels));

function Usage() {
  return (
    <Fragment>
      <TerminHotelPreview />
      <SubterminHotelsPreview />
    </Fragment>
  );
}

export default Usage;
