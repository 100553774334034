import React, { Fragment } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/hr";
import moment from "moment";
import "./DateRangePickerOverrides.css";
import { FormHelperText } from "@material-ui/core";
import { guidIdGenerator } from "../../store/reducers/offer/helpers";

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    const { from, to } = this.props;

    if (props.autoFocus) {
      focusedInput = from ? moment(from) : null;
    } else if (props.autoFocusEndDate) {
      focusedInput = to ? moment(to) : null;
    }

    this.state = {
      focusedInput,
    };
  }

  handleDateChange = (startDate, endDate) => {
    this.props.handleFromChange(startDate);
    this.props.handleToChange(endDate);
  };

  changeFocusedInput = focusedInput => {
    // debugger;
    this.setState({
      focusedInput: focusedInput,
    });
  };

  yearArray = Array.from(Array(10));

  renderPreviousYears = () =>
    this.yearArray.map((year, index) => (
      <option value={moment().year() - (this.yearArray.length - index)} key={guidIdGenerator()}>
        {moment().year() - (this.yearArray.length - index)}
      </option>
    ));

  renderNextYears = () =>
    this.yearArray.map((year, index) => (
      <option value={moment().year() + index + 1} key={guidIdGenerator()}>
        {moment().year() + index + 1}
      </option>
    ));

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className="month-year">
      <div>
        <select
          className="booking-select"
          value={month.month()}
          onChange={this.selectMonthChange(month, onMonthSelect)}
        >
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select className="booking-select" value={month.year()} onChange={this.selectYearChange(month, onYearSelect)}>
          {this.renderPreviousYears()}
          <option value={moment().year()} key={moment().year()}>
            {moment().year()}
          </option>
          {this.renderNextYears()}
        </select>
      </div>
    </div>
  );

  selectMonthChange = (month, onMonthSelect) => e => {
    if (this.props.onSelectMonthYearChange) {
      const newDate = month.set("month", e.target.value);
      this.props.onSelectMonthYearChange(newDate);
    }

    onMonthSelect(month, e.target.value);
  };

  selectYearChange = (month, onYearSelect) => e => {
    if (this.props.onSelectMonthYearChange) {
      const newDate = month.set("year", e.target.value);
      this.props.onSelectMonthYearChange(newDate);
    }

    onYearSelect(month, e.target.value);
  };

  render() {
    let {
      from,
      to,
      vertical,
      small,
      error,
      errorMsg,
      handleChange,
      handleFromChange,
      handleToChange,
      onSelectMonthYearChange,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <div className={error ? "SingleDatePicker-error" : ""}>
          <DateRangePicker
            renderMonthElement={this.renderMonthElement}
            startDate={from ? moment(from) : null} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={to ? moment(to) : null} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={this.changeFocusedInput} // PropTypes.func.isRequired,
            firstDayOfWeek={1}
            isOutsideRange={date => false}
            minimumNights={0}
            orientation={vertical ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
            small={small ? true : undefined}
            {...rest}
          />
        </div>
        {error && <FormHelperText error={error}>{errorMsg}</FormHelperText>}
      </Fragment>
    );
  }
}
