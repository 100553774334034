import { useCallback, useState, useRef } from "react";

// libs
import { debounce, get } from "lodash";
import { useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectPartnerIdSelected,
  selectProgramDayActivities,
  setPartnerSearchValues,
  setPartnerSelected,
} from "src/views/Guides/guidesSlice";

// graphql
import { listPartnerClientPagination } from "src/graphql/query/administration";

// constants
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "src/utility/constants";

import { DEBOUNCE_FETCH_DELAY } from "src/utility/reactTable";

/**
 * usePartnerSearch is entry point of data for partnerSearch component
 */
export default function usePartnerSearchContainer() {
  // redux state
  const isDialogPartnerSearchOpen = useSelector(selectProgramDayActivities);
  const partnerIdSelected = useSelector(selectPartnerIdSelected);

  const dispatch = useDispatch();

  // handle on partner search click
  const handleOnDialogClose = useCallback(() => {
    dispatch(setPartnerSearchValues({ isDialogPartnerSearchOpen: false }));
  }, [dispatch]);

  // fetch initial guides
  const { data, loading, fetchMore } = useQuery(listPartnerClientPagination, {
    variables: {
      input: {
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !isDialogPartnerSearchOpen,
  });

  /**
   * fetch strategy for fetching partner client list
   */

  // on filtered change
  const onFilteredChange = useCallback(() => {
    filtering.current = true;
  }, []);

  // state
  const [pages, setPages] = useState(null);

  // temp variable for filtering
  const filtering = useRef(false);

  const prepareFilters = filtered => {
    let filtersToSend = {};

    filtered.forEach(obj => {
      switch (obj.id) {
        case "city":
          filtersToSend = {
            ...filtersToSend,
            city: {
              name: obj.value,
            },
          };
          break;
        case "country":
          filtersToSend = {
            ...filtersToSend,
            country: {
              name: obj.value,
            },
          };
          break;
        case "language":
          filtersToSend = {
            ...filtersToSend,
            language: {
              desc: obj.value,
            },
          };
          break;
        case "languages":
          filtersToSend = {
            ...filtersToSend,
            languages: obj.value,
          };
          break;
        case "partner_client_types":
          filtersToSend = {
            ...filtersToSend,
            partner_client_type: {
              desc: obj.value,
            },
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  };

  // our custom function, called whenever react table changes or any of filters not directly in react table
  const applyFiltersAndFetch = useCallback(
    async tableGuideState => {
      let filtersToSend;
      let where;

      if (tableGuideState.filtered.length) {
        filtersToSend = prepareFilters(tableGuideState.filtered);
      }

      if (filtersToSend) {
        where = JSON.stringify(filtersToSend);
      }

      filtering.current = false;

      fetchMore({
        variables: {
          input: {
            paginationLimit: {
              limit: tableGuideState.pageSize,
              offset: tableGuideState.page * tableGuideState.pageSize,
            },

            where,
          },
        },
        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const partnerCount = get(fetchMoreResult, "listPartnerClient[0].count");
          if (partnerCount) {
            setPages(Math.ceil(partnerCount / tableGuideState.pageSize));
          }

          return {
            ...prev,
            listPartnerClient: [...fetchMoreResult.listPartnerClient],
          };
        },
      });
    },
    [fetchMore],
  );

  // applyFiltersAndFetchWithDebounce
  const applyFiltersAndFetchWithDebounce = debounce(applyFiltersAndFetch, DEBOUNCE_FETCH_DELAY);

  // fetch strategy
  const fetchStrategy = useCallback(
    tableGuideState => {
      // fetch data
      if (filtering) {
        return applyFiltersAndFetchWithDebounce(tableGuideState);
      } else {
        return applyFiltersAndFetch(tableGuideState);
      }
    },
    [applyFiltersAndFetch, applyFiltersAndFetchWithDebounce, filtering],
  );

  /**
   * format partner client list to show
   */
  const listPartnerToShow = useCallback(() => {
    const listPartnerClient = get(data, "listPartnerClient");

    return listPartnerClient
      ? listPartnerClient.map(partnerClient => {
          return {
            ...partnerClient,
            language: partnerClient.language ? partnerClient.language.desc : "",
            languages: partnerClient.languages ? partnerClient.languages : "",
            city: `${partnerClient && partnerClient.city && partnerClient.city.name}, ${partnerClient &&
              partnerClient.city &&
              partnerClient.city.region &&
              partnerClient.city.region.name}`,
            country: `${partnerClient &&
              partnerClient.city &&
              partnerClient.city.region &&
              partnerClient.city.region.country &&
              partnerClient.city.region.country.name}`,
            num_services: partnerClient.services.reduce((total, key) => total + 1, 0),
          };
        })
      : [];
  }, [data]);

  /**
   * table partner client handling
   */
  const [expanded, setExpanded] = useState(0);

  // table tr props
  const getPartnerTdProps = useCallback(
    (state, rowInfo, col, instance) => {
      return {
        onClick: e => {
          const path = rowInfo.nestingPath[0];
          const diff = { [path]: !expanded[path] };

          let partnerIdToSelect = partnerIdSelected === rowInfo.original.id ? null : rowInfo.original.id;

          // save selected partner in state
          dispatch(setPartnerSelected(partnerIdToSelect));

          setExpanded({
            ...diff,
          });
        },
      };
    },
    [dispatch, expanded, partnerIdSelected],
  );

  const getPartnerTrProps = useCallback((state, rowInfo, col, instance) => {
    if (rowInfo && state) {
      return {
        style: {
          background: state.expanded[rowInfo.index] ? "#81D4FA" : "",
        },
      };
    }

    return {};
  }, []);

  return {
    expanded,
    fetchStrategy,
    getPartnerTdProps,
    getPartnerTrProps,
    handleOnDialogClose,
    listPartnerToShow,
    loading,
    onFilteredChange,
    pages,
  };
}
