import * as types from "../../actionTypes";
import { saveCreatedProgram, setProgramToSend, setProgramDays, resetProgramDays } from "./dailyProgramFunctions";
import initialState from "./initialState";
import {
  addAdditionalService,
  addAlotmanTerminHandler,
  addAttachmentHandler,
  addContigentHandler,
  addSubterminContigentHandler,
  addSubterminPeriodHandler,
  addTerminHandler,
  changeAdditionalService,
  changeEditData,
  changePriceStructure,
  changeProgramDayContentHandler,
  changeProgramDayDescription,
  changeProgramDayTitleHandler,
  copyUpdatedProgramDays,
  changeTemplateLanguage,
  copyUpdatedOffer,
  createProgramDaysHandler,
  deleteAdditionalService,
  establishmentPicked,
  fetchedOfferHandler,
  removeAttachmentHandler,
  removeContigentHandler,
  removeSubterminContigent,
  removeSubterminHandler,
  removeTerminHandler,
  saveEditedSubtermin,
  saveSubterminHandler,
  setSubterminToEdit,
  subterminEstablishmentPicked,
  toggleCreateSubtermin,
  toggleEditSubtermin,
  changePriceStructurePrice,
} from "./offerReducerFunctions";

import {
  addToCreateServiceTypeHandler,
  updateToCreateServiceTypeHandler,
  removeServiceTypeHandler,
  removeToCreateServiceTypeHandler,
  addCreatedServiceTypeHandler,
  updateCreatedServiceTypeHandler,
  removeCreatedServiceTypeHandler,
} from "./serviceFunctions";

import {
  cancelCreateSubterminHandler,
  cancelEditSubterminHandler,
  changeSubterminFieldHandler,
} from "./subterminFunctions";
import {
  addAlotmanContigentHandler,
  resetPriceStructureChangesHandler,
  saveEditedContigentTypologySumHandler,
  saveEditedTerminHandler,
  setAddedAlotmanTerminHandler,
  setAddedTerminandler,
} from "./terminFunctions";
import { updateOfferContractDocumentParam, updateOfferParam, setupOfferDraft } from "./utility";

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ALL_OFFERS_INITIAL_QUERY:
      return {
        ...state,
        limit: action.limit,
        offset: action.offset,
        selectedFilters: action.selectedFilters,
        allOffersFilter: action.allOffersFilter,
      };

    case types.SETUP_INITIAL_DRAFT_OFFER:
      return setupOfferDraft(state, action);

    case types.CHANGE_OFFER_PARAMETER:
      return updateOfferParam(state, action);

    case types.CHANGE_OFFER_CONTRACT_DOCUMENT_PARAMETER:
      return updateOfferContractDocumentParam(state, action);

    case types.CANCEL_CREATE_SUBTERMIN:
      return cancelCreateSubterminHandler(state);

    case types.CANCEL_EDIT_SUBTERMIN:
      return cancelEditSubterminHandler(state);

    case types.TOGGLE_CREATE_SUBTERMIN:
      return toggleCreateSubtermin(state);

    case types.TOGGLE_EDIT_SUBTERMIN:
      return toggleEditSubtermin(state, action.id);

    case types.ADD_TERMIN:
      return addTerminHandler(state);

    case types.ADD_ALOTMAN_TERMIN:
      return addAlotmanTerminHandler(state);

    case types.SAVE_EDITED_TERMIN:
      return saveEditedTerminHandler(state, action.termin, action.old_termin);

    case types.SET_ADDED_TERMIN:
      return setAddedTerminandler(state, action.addedTermin);

    case types.SET_ADDED_ALOTMAN_TERMIN:
      return setAddedAlotmanTerminHandler(state, action.addedTermin);

    case types.RESET_PROGRAM_DAYS:
      return resetProgramDays(state);

    case types.CREATE_PROGRAM_DAYS:
      return createProgramDaysHandler(state, action.days);

    case types.CHANGE_PROGRAM_DAY_DESCRIPTION:
      return changeProgramDayDescription(state, action.dayId, action.value);

    case types.CHANGE_PROGRAM_DAY_TITLE:
      return changeProgramDayTitleHandler(state, action.dayId, action.value);

    case types.CHANGE_PROGRAM_DAY_CONTENT:
      return changeProgramDayContentHandler(
        state,
        action.programDayId,
        action.programDayContentId,
        action.key,
        action.id,
        action.value,
      );

    case types.REMOVE_TERMIN:
      return removeTerminHandler(state, action.id);

    case types.ADD_CONTIGENT:
      return addContigentHandler(state, action.id, action.dontResetFields);

    case types.ADD_ALOTMAN_CONTIGENT:
      return addAlotmanContigentHandler(state, action.termin);

    case types.SAVE_EDITED_CONTIGENT_TYPOLOGY_SUM:
      return saveEditedContigentTypologySumHandler(state, action.contigent, action.old_contigent);

    case types.REMOVE_CONTIGENT:
      return removeContigentHandler(state, action.id);

    case types.SAVE_NEW_SUBTERMIN:
      return saveSubterminHandler(state, action.newSubtermin);

    case types.REMOVE_SUBTERMIN:
      return removeSubterminHandler(state, action.id);

    case types.ADD_SUBTERMIN_CONTIGENT:
      return addSubterminContigentHandler(state, action.id);

    case types.ADD_SUBTERMIN_PERIOD:
      return addSubterminPeriodHandler(state);

    /**
     * services_object_to_create handlers
     */
    case types.ADD_TO_CREATE_SERVICE_TYPE:
      return addToCreateServiceTypeHandler(state, action.service_type);

    case types.UPDATE_TO_CREATE_SERVICE_TYPE:
      return updateToCreateServiceTypeHandler(state, action.service_type);

    case types.REMOVE_TO_CREATE_SERVICE_TYPE:
      return removeToCreateServiceTypeHandler(state, action.id);

    case types.REMOVE_CREATE_SERVICE_TYPE:
      return removeServiceTypeHandler(state, action.id);

    /**
     * documentServices - handlers
     */
    case types.ADD_CREATED_SERVICE_TYPE:
      return addCreatedServiceTypeHandler(state, action.service_type);

    case types.UPDATE_CREATED_SERVICE_TYPE:
      return updateCreatedServiceTypeHandler(state, action.service_type);

    case types.REMOVE_CREATED_SERVICE_TYPE:
      return removeCreatedServiceTypeHandler(state, action.id);

    /**
     * attacmhents handlers
     */
    case types.ADD_ATTACHMENT:
      return addAttachmentHandler(state, action.attachment);

    case types.REMOVE_ATTACHMENT:
      return removeAttachmentHandler(state, action.id);

    case types.REMOVE_SUBTERMIN_CONTIGENT:
      return removeSubterminContigent(state, action.id);

    case types.CHANGE_SUBTERMIN_FIELD:
      return changeSubterminFieldHandler(state, action.obj);

    case types.COPY_SUBTERMIN_TO_EDIT:
      return setSubterminToEdit(state, action.index);

    case types.SAVE_EDITED_SUBTERMIN:
      return saveEditedSubtermin(state);

    //EDIT DATA
    case types.CHANGE_EDIT_DATA:
      return changeEditData(state, action);

    case types.PICK_ESTABLISHMENT:
      return establishmentPicked(state, action);

    case types.PICK_SUBTERMIN_ESTABLISHMENT:
      return subterminEstablishmentPicked(state, action);

    case types.CHANGE_PRICE_STRUCTURE:
      return changePriceStructure(state, action.changes);

    case types.CHANGE_PRICE_STRUCTURE_PRICE:
      return changePriceStructurePrice(state, action.changes);

    case types.RESET_PRICE_STRUCTURE_CHANGES:
      return resetPriceStructureChangesHandler(state, action.price_structure, action.terminId);

    case types.CHANGE_TEMPLATE_LANGUAGE:
      return changeTemplateLanguage(state, action.template);

    case types.ADD_ADDITIONAL_SERVICE:
      return addAdditionalService(state, action.service);

    case types.CHANGE_ADDITIONAL_SERVICE:
      return changeAdditionalService(state, action.param, action.index);

    case types.DELETE_ADDITIONAL_SERVICE:
      return deleteAdditionalService(state, action.id);

    case types.SET_PROGRAM_DAYS:
      return setProgramDays(state, action.value);

    case types.SAVE_CREATED_PROGRAM:
      return saveCreatedProgram(state, action.program);

    case types.SET_PROGRAM_TO_SEND:
      return setProgramToSend(state, action.value);

    case types.COPY_UPDATED_PROGRAM_DAYS:
      return copyUpdatedProgramDays(state, action.program);

    case types.RESET_STORE: {
      // reset store to initial state, and leave some variables, like filters
      return { ...initialState };
    }

    case types.FETCHED_OFFER_TO_EDIT:
      return fetchedOfferHandler(state, action.offer);

    case types.COPY_UPDATED_OFFER:
      return copyUpdatedOffer(state, action.offer);

    case types.CHANGE_OFFER_COPY_FIELDS:
      return {
        ...state,
        offer_copy: action.offer_copy,
      };

    default:
      return state;
  }
}
