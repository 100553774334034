// lib
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectAnnouncementStatusTypes,
  selectGuideScheduleDrawerPayload,
  selectSupplierPartners,
  setGuideScheduleAnnouncementPartners,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_ANNOUNCEMENT_VOUCHER_STATUS } from "src/graphql/mutation/announcement";

/**
 * useAnnouncementStatus is entry point of data for Announcement component
 */
export default function useAnnouncementStatus() {
  // redux state
  const announcementStatusTypes = useSelector(selectAnnouncementStatusTypes);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const supplierPartners = useSelector(selectSupplierPartners);

  const dispatch = useDispatch();

  // mutation update announcement status
  const [updateAnnouncementStatus] = useMutation(UPDATE_ANNOUNCEMENT_VOUCHER_STATUS);

  /**
   * handle on announcement status change
   */
  const handleOnAnnouncementStatusChange = async (announcementStatus, terminPeriodContigentId, programDayContentId) => {
    /* add conditionally terminPeriodContigentId or programDayContentId regarding supplier partner type
     * is it hotel or guide
     */
    const responseUpdateAnnouncementStatus = await updateAnnouncementStatus({
      variables: {
        input: {
          program_id: guidePayload.programId,
          announcement_status: announcementStatus,
          ...(terminPeriodContigentId && { termin_period_contigent_id: terminPeriodContigentId }),
          ...(programDayContentId && { program_day_content_id: programDayContentId }),
        },
      },
    });

    const updatedSupplier = get(responseUpdateAnnouncementStatus, "data.updateAnnounecementVoucherStatus");

    // update supplierPartner in state
    if (updatedSupplier) {
      const partnerClientId = get(updatedSupplier, "partner_client_id");
      const guideId = get(updatedSupplier, "guide_id");
      const establishmentId = get(updatedSupplier, "establishment_id");

      const updatedSupplierPartners = supplierPartners.map(supplierPartner => {
        // check if this is guide partner
        if (guideId && supplierPartner.guide_id === guideId) {
          return updatedSupplier;
        }

        // check if this is establishment partner
        if (establishmentId && supplierPartner.establishment_id === establishmentId) {
          return updatedSupplier;
        }

        // check if this is main partner
        if (partnerClientId && supplierPartner.partner_client_id === partnerClientId) {
          return updatedSupplier;
        }
        return supplierPartner;
      });

      // update supplier partners
      dispatch(setGuideScheduleAnnouncementPartners({ supplierPartners: updatedSupplierPartners }));

      toastr.success("Uspješno ažuriran status");
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return { handleOnAnnouncementStatusChange, announcementStatusTypes };
}
