import React from "react";

// constants
import { TITLE_GUIDE_ANNOUNCEMENT } from "src/utility/labels";

// hooks
import useAnnouncementView from "./hooks/useAnnouncementView";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import AnnouncementForm from "src/views/Guides/Container/components/Drawers/components/Announcement/components/AnnouncementEdit/components/EditForm/AnnouncementForm";

/**
 * AnnouncementView drawer is used for showing data of announcements
 */
export default function AnnouncementView() {
  const { announcementSelected, isDrawerOpen, onCloseDrawer } = useAnnouncementView();

  // return root
  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isSaveVisible={false}
      title={TITLE_GUIDE_ANNOUNCEMENT}
      customWidth="60%"
    >
      <AnnouncementForm isVoucherCreateVisible={false} announcementSelected={announcementSelected} />
    </DrawerWrapper>
  );
}
