import React from "react";

import { Tabs, Tab } from "material-ui/Tabs";
import PlacesTabsHOC from "./PlacesTabsHOC";

class PlacesTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = value => {
    this.setState({ value: value });
  };

  render() {
    const { showInTabCity, showInTabRegion, showInTabCountry } = this.props;

    return (
      <Tabs value={this.state.value} onChange={this.handleChange}>
        <Tab label="Grad" value={0}>
          <div className="form">{showInTabCity()}</div>
        </Tab>
        <Tab label="Regija" value={1}>
          <div className="form">{showInTabRegion()}</div>
        </Tab>
        <Tab label="Država" value={2}>
          <div className="form">{showInTabCountry()}</div>
        </Tab>
      </Tabs>
    );
  }
}

export default PlacesTabsHOC(PlacesTabs);
