import React from "react";

// hooks
import useCatalogContainer from "./hooks/useCatalogContainer";

// constants
import { HINT_CATALOG, LABEL_CATALOG } from "src/utility/labels";

// components
import TextFieldMultilineOutline from "src/components/UI/TextFieldMultilineOutline";

/**
 * CatalogContainer contains all components inside CatalogDrawer
 */
export default function CatalogContainer() {
  const { onCatalogTextChange, catalog } = useCatalogContainer();

  return (
    <TextFieldMultilineOutline
      id="catalogInput"
      fullWidth={true}
      rows={5}
      rowsMax={5}
      value={catalog || ""}
      label={LABEL_CATALOG}
      placeholder={HINT_CATALOG}
      onChange={event => onCatalogTextChange(event)}
    />
  );
}
