import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { formatDate } from "../../../utility/dates";
import { withRouter } from "react-router-dom";

class PreviewOfferValidity extends Component {
  render() {
    const { document_date_validity, document_date_validity_comment, int } = this.props;

    if (!this.props.match.path.includes("ponude")) {
      return null;
    }

    return (
      <Fragment>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.offer_validity}</h4>
          </div>
          <div>
            <strong style={{ display: "block" }}>{formatDate(document_date_validity)}</strong>
          </div>
        </div>
        <div className="preview-content">
          <div />
          <div disabled id="document_date_validity_comment" className="preview-content-div preview-textarea">
            {document_date_validity_comment && document_date_validity_comment.trim()}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: { document_date_validity, document_date_validity_comment },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    document_date_validity,
    document_date_validity_comment,
    int,
  };
};

export default connect(mapStateToProps)(withRouter(PreviewOfferValidity));
