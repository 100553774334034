// libs
import toastr from "toastr";
import { get } from "lodash";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGroupOperativeStatusTypes,
  selectGuideScheduleDrawerPayload,
  selectGuideScheduleDrawers,
  selectGuideSchedules,
  setGuideDrawerPayload,
  setGuideDrawers,
  setGuideSchedules,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";

// constants
import { GUIDE_DRAWER_OPERATIVE_GROUP_STATUS } from "src/utility/constants";

/**
 * useGroupStatus is entry point of data for groupStatus drawer
 */
export default function useGroupStatus() {
  // redux state
  const groupOperativeStatusTypes = useSelector(selectGroupOperativeStatusTypes);
  const guideDrawers = useSelector(selectGuideScheduleDrawers);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // change program day title
  const [updateGuideExtraFields, { loading }] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_OPERATIVE_GROUP_STATUS,
        isOpen: false,
      }),
    );
  };

  /**
   * handle on group status change
   */
  const handleOnGroupStatusChange = async () => {
    const groupStatusEnumName = groupOperativeStatusTypes.find(
      groupStatus => groupStatus.description === guidePayload.groupStatus,
    );

    if (!groupStatusEnumName) {
      return toastr.error("Pogreška prilikom promjene status, pokušajte ponovo");
    }

    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: guidePayload.programId,
          group_operativa_status: groupStatusEnumName.name,
        },
      },
    });

    let guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran status");

      // update list guides
      let listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === guidePayload.programId) {
          return {
            ...guide,
            group_operativa_status: get(guideUpdate, "group_operativa_status"),
          };
        }
        return guide;
      });

      // update guide shedules list in state
      dispatch(setGuideSchedules(listGuidesUpdate));

      // close drawer
      onCloseDrawer();
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    handleOnGroupStatusChange,
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_OPERATIVE_GROUP_STATUS],
    loading,
    onCloseDrawer,
  };
}
