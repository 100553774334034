import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useAgencyTourAnnouncement from "./hooks/useAgencyTourAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import ProgramDetails from "../ProgramDetails";
import HotelDetailsList from "../HotelDetailsList";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * AgencyTourAnnouncement
 */
const AgencyTourAnnouncement = ({ classes }) => {
  // hooks
  const {
    agencyTourAnnouncement,
    handleOnUpdateHotelDetails,
    handleOnUpdateAgencyTourAnnouncement,
  } = useAgencyTourAnnouncement();

  const title = get(agencyTourAnnouncement, "announcement_title.value", "");
  const intro = get(agencyTourAnnouncement, "intro_message.placeholder", "");
  const footer = get(agencyTourAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* termin */}
      <AnnouncementTextField announcementField={get(agencyTourAnnouncement, "termin")} keyOfValue={"termin"} />

      {/* hotel table list */}
      <HotelDetailsList
        label={`${get(agencyTourAnnouncement, "hotel_label.value", "")} : `}
        hotelList={get(agencyTourAnnouncement, "hotel", "")}
        handleOnUpdateHotelDetails={handleOnUpdateHotelDetails}
      />

      {/* bus parking */}
      <AnnouncementTextField
        announcementField={get(agencyTourAnnouncement, "bus_parking")}
        keyOfValue={"bus_parking"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyTourAnnouncement}
      />

      {/* olivari guide with group */}
      <AnnouncementTextField
        announcementField={get(agencyTourAnnouncement, "olivari_guide_with_group")}
        keyOfValue={"olivari_guide_with_group"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyTourAnnouncement}
      />

      {/* dinner drink */}
      <AnnouncementTextField
        announcementField={get(agencyTourAnnouncement, "dinner_drink")}
        keyOfValue={"dinner_drink"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyTourAnnouncement}
      />

      {/* program */}
      <ProgramDetails
        label={`${get(agencyTourAnnouncement, "program_label.value", "")} : `}
        programList={get(agencyTourAnnouncement, "program", "")}
      />

      {/* payment */}
      <AnnouncementTextField
        announcementField={get(agencyTourAnnouncement, "payment")}
        keyOfValue={"payment"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyTourAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AgencyTourAnnouncement);
