import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";
import FormValidator from "../../../components/UI/FormValidator";
import { deleteTypologyGroup, updateTypologyGroup } from "../../../graphql/mutation/administration";
import { getTypologyGroup } from "../../../graphql/query/administration";
import { AdministrationTabs } from "../AdministrationTabs";
import TypologyGroupForm from "./TypologyGroupForm";
import TableTitle from "src/components/UI/TableTitle";

class EditTypologyGroup extends React.Component {
  static schema = {
    required: ["name"],
    properties: {
      name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
    },
  };

  state = {
    name: "",
  };

  async componentDidMount() {}

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data.loading !== this.props.data.loading && this.props.data.getTypologyGroup) {
      this.setState({
        name: this.props.data.getTypologyGroup.name,
      });
    }
  };

  onChangeData = key => ({ target: { value } }) => {
    this.setState({
      [key]: value,
    });
  };

  submit = async () => {
    try {
      const userDifference = this.state.name !== this.props.data.getTypologyGroup.name;

      if (userDifference) {
        await this.props.mutate({
          variables: {
            id: this.props.match.params.id,
            name: this.state.name,
          },
        });
      }

      this.props.history.push("/administracija/grupa_tipologije/");
      toastr.success("Uspješno ažurirano");
    } catch (error) {}
  };

  deleteTypologyGroup = async () => {
    try {
      await this.props.deleteTypologyGroup({
        variables: {
          id: this.props.match.params.id,
        },
      });

      toastr.success("Uspješno izbrisano");
    } catch (error) {}

    this.props.history.push("/administracija/grupa_tipologije/");
  };

  render() {
    if (this.props.data.loading) {
      return <div>Dohvaćam tipologiju grupe . . . </div>;
    }
    return (
      <Fragment>
        <TableTitle
          title="Ažuriranje grupe tipologije"
          onBackPressed={() => {
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/grupa_tipologije/");
            } else {
              this.props.history.goBack();
            }
          }}
        />
        <FormValidator schema={EditTypologyGroup.schema} submit={this.submit} data={this.state}>
          {({ error, errorMsg, submit }) => (
            <TypologyGroupForm
              {...this.state}
              onSubmit={submit}
              error={error}
              errorMsg={errorMsg}
              onChangeData={this.onChangeData}
              deleteTypologyGroup={this.deleteTypologyGroup}
            />
          )}
        </FormValidator>
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(
    graphql(deleteTypologyGroup, {
      name: "deleteTypologyGroup",
    }),
    graphql(updateTypologyGroup),
    graphql(getTypologyGroup, {
      options: props => ({
        variables: {
          id: props.match.params.id,
        },
        fetchPolicy: "network-only",
      }),
    }),
  )(withApollo(EditTypologyGroup)),
  "Ažuriranje grupe tipologije",
);
