import initialState from "./initialState";
import { changeEditData } from "./offerReducerFunctions";
import { updateOfferContractDocumentParam } from "./utility";
import { terminClone, guidIdGenerator, addingSubterminHelper, addingSubterminContigentHelper } from "./helpers";
import { get } from "lodash";

export const cancelEditSubterminHandler = state => {
  return {
    ...state,
    selected_termin_id: "",
    subtermin_establishment_id: "",
    subtermin_typologies_sum: "",
    subtermin_typologies: [],
    subtermin: {
      id: guidIdGenerator(),
      contigents: [],
    },
    subtermin_typology_id: "",
    subtermin_to: undefined,
    subtermin_from: undefined,
    create_subtermin: true,
    edit_subtermin: false,
  };
};

export const cancelCreateSubterminHandler = state => {
  return {
    ...state,
    selected_termin_id: "",
    subtermin_establishment_id: "",
    subtermin_typologies_sum: "",
    subtermin_typologies: [],
    subtermin: {
      id: guidIdGenerator(),
      contigents: [],
    },
    subtermin_typology_id: "",
    subtermin_to: undefined,
    subtermin_from: undefined,
    create_subtermin: true,
    edit_subtermin: false,
  };
};

export const saveSubterminHandler = (state, newSubtermin) => {
  const { subtermin } = state;

  if (!subtermin.from || !subtermin.to || !subtermin.contigents || !subtermin.contigents.length) {
    return state;
  }

  const termins_clone = addingSubterminHelper(state, newSubtermin);

  state = {
    ...state,
    selected_termin_id: "",
    subtermin_establishment_id: "",
    subtermin_typologies_sum: "",
    subtermin_typologies: [],
    subtermin: {
      id: guidIdGenerator(),
      contigents: [],
    },
    subtermin_typology_id: "",
    subtermin_to: undefined,
    subtermin_from: undefined,
  };

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const removeSubterminHandler = (state, id) => {
  const { subtermin_period_ids_delete, termin_periods } = state.offer.offer_contract_document;

  const termin_periods_clone = termin_periods.map(termin => {
    const { subtermin_periods } = termin;

    let subtermins_clone = [...subtermin_periods];

    // Find index of subtermin to delete
    const subterminIndex = subtermin_periods.map(subtermin => subtermin.id).indexOf(id);

    // If  offer id and subterminIndex exists , it means it is in edit mode
    if (state.offer.id && subterminIndex > -1) {
      const subtermin = subtermins_clone[subterminIndex];

      let subtermins_to_delete;

      //Checking if array for deletion already exists and if subtermin is already stored in database
      if (subtermin_period_ids_delete && subtermin_period_ids_delete.length && !subtermin.id.includes("-")) {
        subtermins_to_delete = [...subtermin_period_ids_delete];
        subtermins_to_delete.push(subtermin.id);
      }

      state = updateOfferContractDocumentParam(state, {
        param: {
          key: "subtermin_period_ids_delete",
          value: subtermins_to_delete || subtermin_period_ids_delete,
        },
      });
    }

    const pt = { ...JSON.parse(termin.price_structure.price_typology) };

    if (subterminIndex > -1) {
      // group ids, from subtermin to delete
      const subtermin_to_delete = subtermins_clone[subterminIndex];

      const subtermin_to_delete_typology_group_ids = subtermin_to_delete.contigents.map(
        ({ typology }) => typology.typology_group_id,
      );

      const subtermin_to_delete_typology_group_unique_ids = subtermin_to_delete_typology_group_ids.filter(
        (typology_group_id, i) =>
          subtermin_to_delete_typology_group_ids.findIndex(
            typology_group_id2 => typology_group_id === typology_group_id2,
          ) === i,
      );

      subtermins_clone.splice(subterminIndex, 1);

      // group ids from rest of the subtermins
      const typology_group_ids = subtermins_clone.reduce((arr, st) => {
        const { contigents } = st;

        return [...arr, ...contigents.map(({ typology }) => typology.typology_group_id)];
      }, []);

      const typology_group_unique_ids = typology_group_ids.filter(
        (typology_group_id, i) =>
          typology_group_ids.findIndex(typology_group_id2 => typology_group_id === typology_group_id2) === i,
      );

      subtermin_to_delete_typology_group_unique_ids.forEach(group_id => {
        // if there are no more same typology_group_ids, delete it from price_typology
        if (!typology_group_unique_ids.includes(group_id)) {
          if (pt[group_id]) {
            delete pt[group_id];
          }
        }
      });
    }

    return {
      ...termin,
      subtermin_periods: subtermins_clone,
      price_structure: {
        ...termin.price_structure,
        price_typology: JSON.stringify(pt),
      },
    };
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termin_periods_clone,
    },
  });
};

export const addSubterminPeriodHandler = state => {
  const { subtermin, subtermin_to, subtermin_from, transport } = state;

  subtermin["from"] = subtermin_from;
  subtermin["to"] = subtermin_to;
  subtermin["transport"] = transport;

  return {
    ...state,
    subtermin,
    subtermin_from: "",
    subtermin_to: "",
  };
};

export const setSubterminToEdit = (state, index) => {
  const { termin_periods } = state.offer.offer_contract_document;

  const subtermin = { ...termin_periods[0].subtermin_periods[index] };

  return changeEditData(state, {
    param: {
      key: "subtermin",
      value: subtermin,
    },
  });
};

export const saveEditedSubtermin = (state, dontReset) => {
  const { termin_periods } = state.offer.offer_contract_document;
  const { subtermin } = state;

  const termin_periods_clone = [
    {
      ...termin_periods[0],
      subtermin_periods: termin_periods[0].subtermin_periods.map(subt => {
        if (subt.id === subtermin.id) {
          return {
            ...subtermin,
          };
        } else {
          return subt;
        }
      }),
    },
  ];

  const newState = updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termin_periods_clone,
    },
  });

  const resetFields = dontReset
    ? {}
    : {
        selected_termin_id: "",
        subtermin_establishment_id: "",
        subtermin_typologies_sum: "",
        subtermin_typologies: [],
        subtermin: {
          id: guidIdGenerator(),
          contigents: [],
        },
        subtermin_typology_id: "",
        subtermin_to: undefined,
        subtermin_from: undefined,
        create_subtermin: true,
        edit_subtermin: false,
      };

  return {
    ...newState,
    ...resetFields,
  };
};

export const toggleCreateSubtermin = state => {
  return {
    ...state,
    subtermin: initialState.subtermin,
    create_subtermin: true,
    edit_subtermin: false,
  };
};

export const toggleEditSubtermin = (state, id) => {
  const { termin_periods } = state.offer.offer_contract_document;

  const subtermin = {
    ...termin_periods[0].subtermin_periods.find(obj => obj.id === id),
  };

  let subtermin_establishment_id = get(subtermin, "contigents[0].typology.establishment_id", null);

  let establishment = get(subtermin, "contigents[0].typology.establishment", {});

  let subtermin_typologies = get(subtermin, "contigents[0].typology.establishment.typologies", []);

  return {
    ...state,
    subtermin,
    create_subtermin: false,
    edit_subtermin: true,
    establishment,
    subtermin_establishment_id,
    subtermin_typologies,
  };
};

export const removeSubterminContigent = (state, id) => {
  let {
    subtermin,
    subtermin: { contigents },
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const contigents_clone = contigents.filter(contigent => contigent.id !== id);

  const termin_periods_clone =
    termin_periods &&
    termin_periods.map(termin => {
      if (!termin.subtermin_periods) {
        return termin;
      }

      const price_typology = { ...JSON.parse(termin.price_structure.price_typology) };
      let typology_group_ids_after_delete = [];
      let contigent_to_delete = null;

      const subtermin_periods =
        termin.subtermin_periods &&
        termin.subtermin_periods.map(termin => {
          if (termin.id !== subtermin.id) {
            typology_group_ids_after_delete = [
              ...typology_group_ids_after_delete,
              ...termin.contigents.map(({ typology }) => typology.typology_group_id),
            ];

            return termin;
          }

          contigent_to_delete = termin.contigents.find(contigent => contigent.id === id);

          const contigents = termin.contigents.filter(contigent => contigent.id !== id);

          typology_group_ids_after_delete = [
            ...typology_group_ids_after_delete,
            ...contigents.map(({ typology }) => typology.typology_group_id),
          ];

          return {
            ...termin,
            contigents,
          };
        });

      const typology_group_ids_unique_after_delete = typology_group_ids_after_delete.filter(
        (typology_group_id, i) =>
          typology_group_ids_after_delete.findIndex(typology_group_id2 => typology_group_id === typology_group_id2) ===
          i,
      );
      debugger;

      if (!typology_group_ids_unique_after_delete.includes(contigent_to_delete.typology.typology_group_id)) {
        delete price_typology[contigent_to_delete.typology.typology_group_id];
      }

      return {
        ...termin,
        subtermin_periods,
        price_typology: JSON.stringify(price_typology),
      };
    });

  const newState = updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termin_periods_clone,
    },
  });

  return {
    ...newState,
    subtermin: {
      ...newState.subtermin,
      contigents: contigents_clone,
    },
  };
};

export const changeSubterminFieldHandler = (state, obj) => {
  const { subtermin } = state;
  let subtermin_clone = {
    ...subtermin,
    contigents: [...subtermin.contigents],
  };

  subtermin_clone = {
    ...subtermin_clone,
    [obj.key]: obj.value,
  };

  return {
    ...state,
    subtermin: subtermin_clone,
  };
};

export const removeSubtermin = (state, id) => {
  const { subtermin_period_ids_delete } = state;
  let termins_clone = terminClone(state);

  const subtermin_index = termins_clone[0].subtermins.map(termin => termin.id).indexOf(id);

  // If  offer exists , it means it is in edit mode
  if (state.offer.id && subtermin_index > -1) {
    state = updateOfferContractDocumentParam(state, {
      param: {
        key: "subtermin_period_ids_delete",
        value: subtermin_period_ids_delete
          ? subtermin_period_ids_delete.push(termins_clone[0].subtermin_periods[subtermin_index].id)
          : [termins_clone[0].subtermin_periods[subtermin_index].id],
      },
    });
  }

  if (subtermin_index > -1) {
    termins_clone[0].subtermin_periods.splice(subtermin_index, 1);
  }

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const addSubterminContigentHandler = (state, id) => {
  const subtermin = addingSubterminContigentHelper(state, id);

  // if subtermin is updating and new contigent of subtermin is created
  // save that into termin_periods subtermins, so that it's updated in subtermin list table
  return saveEditedSubtermin(
    {
      ...state,
      subtermin,
      subtermin_typologies_sum: "",
      subtermin_typology_id: "",
    },
    true,
  );
};

export const subterminEstablishmentPicked = (oldState, action) => {
  const { listEstablishment, value, key } = action.data;

  // If none is picked, reset fields
  if (!value) {
    return {
      ...oldState,
      subtermin_establishment_id: "",
      subtermin_typologies_sum: "",
      subtermin_typology_id: "",
      subtermin_typologies: [],
    };
  }

  const index = listEstablishment.findIndex(el => el.id === value);

  const subtermin_typologies = listEstablishment[index].typologies;

  return {
    ...oldState,
    [key]: value,
    establishment: listEstablishment.find(entry => entry.id === value),
    subtermin_typologies,
  };
};

export const subterminEstablishmentObjectPicked = (oldState, action) => {
  const { value } = action.data;

  let index, subtermin_establishment_object_typologies;
  if (value) {
    index = oldState.subtermin_establishment_objects.findIndex(el => el.id === value);

    subtermin_establishment_object_typologies = oldState.subtermin_establishment_objects[index].typologies;
  }

  return {
    ...oldState,
    [action.data.key]: action.data.value,
    subtermin_establishment_object_typologies: value ? subtermin_establishment_object_typologies : [],
  };
};
