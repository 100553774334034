// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_HOTEL_NO_GUIDE_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateHotelNoGuideAnnouncement is used for createHotelNoGuideAnnouncement mutation
 */
export default function useCreateHotelNoGuideAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createHotelNoGuideAnnouncement] = useMutation(CREATE_HOTEL_NO_GUIDE_ANNOUNCEMENT);

  const handleCreateHotelNoGuideAnnouncement = async languageSelected => {
    const createAnnouncementResponse = await createHotelNoGuideAnnouncement({
      variables: {
        input: {
          program_id: programId,
          establishment_id: supplier.establishment_id,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createHotelNoGuideAnnouncement");
  };

  return {
    handleCreateHotelNoGuideAnnouncement,
  };
}
