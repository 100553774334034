import React from "react";
import { config } from "./globals";

export const getTypologyName = (typo_id, typologies) => {
  return typologies.find(el => el.id === typo_id);
};

export const getEstablishmentNameByTypology = (typology_id, establishmentsList) => {
  // Find establishment name and store it in
  for (let establishment of establishmentsList) {
    for (let typo of establishment.typologies) {
      if (typo.id === typology_id) {
        return establishment.name;
      }
    }
  }
};

export const readEstablishmentDataByTypology = (typology_id, listEstablishment) => {
  let establishment, typology;

  listEstablishment.forEach(est => {
    est.typologies.forEach(typo => {
      if (typo.id === typology_id.toString()) {
        typology = typo;
        establishment = est;
      }
    });
  });

  return [establishment, typology];
};

export const readEstablishmentChain = (establishment_id, listEstablishmentChain) => {
  let establishment, establishmentChain, establishmentList;

  listEstablishmentChain.forEach(estChain => {
    estChain.establishments.forEach(est => {
      if (est.id === establishment_id) {
        establishment = est;
        establishmentChain = estChain;
        establishmentList = estChain.establishments;
      }
    });
  });

  return [establishmentChain, establishmentList, establishment];
};

export const getEstablishmentById = (id, listEstablishment) =>
  listEstablishment.find(establishment => establishment.id === id);

export const getEstablishmentCity = (city_id, citiesList) => citiesList.find(city => city.id === city_id);

export const getTranslatedTypology = (typologies, language_id = 1) => {
  return typologies.map(typology => {
    const translated = typology.typology_translations.find(
      translation => translation.language_id === language_id.toString(),
    );

    return {
      ...typology,
      desc: translated ? translated.desc : "Nema opisa na odabranom jeziku",
      name: translated ? translated.name : "Nema naziva na odabranom jeziku",
    };
  });
};

export const sortContigentsByTypologyPriority = (contigents, listEstablishment) => {
  let contigents_clone = [...contigents];

  contigents_clone.sort((a, b) => {
    if (a.id.includes("-") || b.id.includes("-")) {
      return -1;
    }
    const arr1 = readEstablishmentDataByTypology(a.typology_id, listEstablishment);

    const priorityA = arr1[1].priority;

    const arr2 = readEstablishmentDataByTypology(b.typology_id, listEstablishment);

    const priorityB = arr2[1].priority;
    // DESC
    return returningSorted(priorityA, priorityB);
  });

  return contigents_clone;
};

export const sortContigentsByTypologyPriorityWithoutListEstablishment = contigents => {
  let contigents_clone = [...contigents];

  contigents_clone.sort((a, b) => {
    if (typeof a.id === "string" && a.id.includes("-")) {
      return 1;
    }
    const priorityA = a && a.typology && a.typology.priority;
    const priorityB = b && b.typology && b.typology.priority;

    if (priorityA < priorityB) {
      return -1;
    } else {
      return 0;
    }
  });

  return contigents_clone;
};

export const sortTypologies = ([...typologies]) => typologies.sort((a, b) => returningSorted(a.priority, b.priority));

const returningSorted = (priorityA, priorityB) => {
  if (priorityA < priorityB) {
    return 1;
  } else if (priorityB < priorityA) {
    return -1;
  } else {
    return 0;
  }
};

export const findTypologyByCroLang = typology => {
  return (
    typology &&
    typology.typology_translations &&
    typology.typology_translations.find(({ language_id }) => config.language[String(language_id)] === "Croatian")
  );
};

export const establishmentContigentsAndHotels = contigents => {
  let establishmentsShown = new Map();
  let sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

  sortedContigents.forEach(contigent => {
    if (establishmentsShown.has(contigent.typology.establishment_id)) {
      const { establishment_name, contigents_list } = establishmentsShown.get(contigent.typology.establishment_id);

      // TODO: if neccessary return back value of contigent.typology.persons_capacity

      // CAN ADD CITY AND ESTABLISHMENT CHAIN TOO
      establishmentsShown.set(contigent.typology.establishment_id, {
        establishment_name: [...establishment_name, <div key={`en_${contigent.id}`}>&nbsp;</div>],
        contigents_list: [
          ...contigents_list,
          <div key={`cl_${contigent.id}`}>{`${contigent.typologies_sum} / ${contigent.typology.code}`}</div>,
        ],
      });
    } else {
      establishmentsShown.set(contigent.typology.establishment_id, {
        establishment_name: [<div key={`en_${contigent.id}`}>{contigent.typology.establishment.name}</div>],
        contigents_list: [
          <div key={`cl_${contigent.id}`}>{`${contigent.typologies_sum} / ${contigent.typology.code}`}</div>,
        ],
      });
    }
  });

  const iterator1 = establishmentsShown.keys();

  let key = iterator1.next().value;

  let establishment_names = [];
  let contigent_lists = [];

  // if only one establishment, remove empty spaces
  if (establishmentsShown.size && establishmentsShown.size < 2) {
    const { establishment_name } = establishmentsShown.get(key);

    establishment_name.splice(1, establishment_name.length);
  }

  while (key) {
    const { establishment_name, contigents_list } = establishmentsShown.get(key);

    establishment_names = [...establishment_names, ...establishment_name];
    contigent_lists = [...contigent_lists, ...contigents_list];

    key = iterator1.next().value;

    if (key) {
      establishment_names = [...establishment_names, <div key={`en_${key}`}>&nbsp;</div>];
      contigent_lists = [...contigent_lists, <div key={`cl_${key}`}>&nbsp;</div>];
    }
  }

  return { establishment_names, contigent_lists };
};
