import React from "react";
import { withStyles } from "@material-ui/core/styles";

import ReactTableCustom from "../../../../components/UI/ReactTable";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto"
  },
  subterminContainer: {
    padding: "24px 10px"
  }
});

const ContigentsList = props => {
  const { contigents } = props.termin_periods[0];

  const dataToShow =
    contigents && contigents.length
      ? contigents.map(contigent => {
          const typology_translated = contigent.typology.typology_translations.find(
            translation => translation.language_id === props.language_id
          );

          return {
            ...contigent,
            persons_capacity: `${typology_translated.name} [${
              contigent.typology.persons_capacity
            }]`,
            establishment_name: contigent.typology.establishment.name
          };

          // return (
          //   <Chip
          //     key={index}
          //     onRequestDelete={() => props.removeContigent(contigent.id)}
          //   >
          //     {contigent && contigent.typologies_sum + " / "}
          //     {typology && typology.persons_capacity}
          //     {` - (${establishment && establishment.name})`}
          //   </Chip>
          // );
        })
      : [];

  return (
    <ReactTableCustom
      data={dataToShow}
      columns={[
        {
          Header: "Kontigenti",
          columns: [
            {
              Header: "Br_osoba",
              accessor: "typologies_sum"
            },
            {
              Header: "Kapacitet",
              accessor: "persons_capacity"
            },
            {
              Header: "Smještaj",
              accessor: "establishment_name"
            }
          ]
        }
      ]}
      innerProps={{
        pageSize: dataToShow.length,
        showPagination: false,
        sortable: false,
        filterable: false,
        filterAll: false,
        noDataText: ""
      }}
    />
  );
};

export default withStyles(styles)(ContigentsList);
