import React from "react";

// libs
import { Switch, Route } from "react-router-dom";

// components
import AnnouncementOrdersContainer from "./List";
import AnnouncementOrderEditContainer from "./Edit";

/**
 * AnnouncementOrdersRouter
 */
const AnnouncementOrdersRouter = () => {
  return (
    <Switch>
      <Route path="/nalozi/edit/:id" exact component={AnnouncementOrderEditContainer} />
      <Route path="/" component={AnnouncementOrdersContainer} />
    </Switch>
  );
};

export default AnnouncementOrdersRouter;
