import gql from "graphql-tag";
import { cityFragment, attachmentFragment, partnerClientFragment } from "../fragments";

export const inquiryList = gql`
  query($input: Condition) {
    listInquiry(input: $input) {
      id
      external_id
      count
      partner_client_id
      created_at
      title
      remark
      last_edit_by_user_time
      last_edit_by_user {
        id
        username
      }
      offers {
        id
      }
      contracts {
        id
      }
      partner_client {
        id
        name
        address
        city {
          ...City
        }
      }
      offer_contract_documents {
        id
        offer {
          id
        }
        contract {
          id
        }
      }
      attachments {
        ...Attachment
      }
    }
  }
  ${attachmentFragment}
  ${cityFragment}
`;

export const fetchInquiry = gql`
  query($id: ID!) {
    getInquiry(id: $id) {
      id
      external_id
      partner_client_id
      created_at
      title
      remark
      off_key
      partner_client {
        ...Partner
      }
      title
      remark
      offers {
        id
        external_id

        offer_contract_document {
          id
          attachments {
            ...Attachment
          }
        }
      }
      contracts {
        id
        external_id

        offer_contract_document {
          id
          attachments {
            ...Attachment
          }
        }
      }
      attachments {
        ...Attachment
      }
      offer_contract_documents {
        id
        offer {
          id
        }
        contract {
          id
        }
      }
    }
  }
  ${attachmentFragment}
  ${partnerClientFragment}
`;

export const getInquiry = gql`
  query($id: ID!) {
    getInquiry(id: $id) {
      id
      external_id
      partner_client_id
      created_at
      off_key
      partner_client {
        ...Partner
      }
      title
      remark
      attachments {
        ...Attachment
      }
      offer_contract_documents {
        id
      }
    }
  }
  ${attachmentFragment}
  ${partnerClientFragment}
`;
