import gql from "graphql-tag";

// fragments
import {
  hotelNoGuideAnnouncementFragment,
  hotelguideAnnouncementFragment,
  guideAnnouncementFragment,
  guideTourAnnouncementFragment,
  agencyAnnouncementFragment,
  agencyTourAnnouncementFragment,
  agencyLocalAnnouncementFragment,
  agencyLocalProgramAnnouncementFragment,
  supplierAnnouncementFragment,
  phraseAnnouncementFragment,
  guideLocalAnnouncementFragment,
  supplierPartnerFragment,
} from "../announcementFragments";

/**
 * announcement queries
 */
// announcement partners and hotels
export const ANNOUNCEMENT_PARTNERS = gql`
  query($program_id: ID!, $announcementStatusName: String!, $voucherStatusName: String!) {
    getAnnouncementPartnersAndHotels(program_id: $program_id) {
      group_operativa_status
      suppliers_partners {
        ...SupplierPartner
      }

      all_guides {
        guide_id
        guide_first_name
        guide_last_name
      }

      guide_layout_comment
    }

    announcementStatusTypes: __type(name: $announcementStatusName) {
      enumValues {
        name
        description
      }
    }

    voucherStatusTypes: __type(name: $voucherStatusName) {
      enumValues {
        name
        description
      }
    }
  }
  ${supplierPartnerFragment}
`;

// get all announcement by program for establishment or partner
export const SUPPLIER_ANNOUNCEMENTS = gql`
  query($program_id: ID!, $establishment_id: ID, $guide_id: ID, $language_id: ID) {
    getAllAnnouncementsByProgram(
      program_id: $program_id
      establishment_id: $establishment_id
      guide_id: $guide_id
      language_id: $language_id
    ) {
      id
      announcement_type
      language_id
      language_desc
      guide_id
      guide_first_name
      guide_last_name
    }
  }
`;

// get hotel guide announcement
export const GET_HOTEL_GUIDE_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getHotelGuideAnnouncement(id: $id) {
      ...HotelGuideAnnouncement
    }
  }
  ${hotelguideAnnouncementFragment}
`;

// get hotel no guide announcement
export const GET_HOTEL_NO_GUIDE_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getHotelNoGuideAnnouncement(id: $id) {
      ...HotelNoGuideAnnouncement
    }
  }
  ${hotelNoGuideAnnouncementFragment}
`;

// get guide announcement
export const GET_GUIDE_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getGuideAnnouncement(id: $id) {
      ...AnnouncementGuide
    }
  }
  ${guideAnnouncementFragment}
`;

// get guide tour announcement
export const GET_GUIDE_TOUR_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getGuideTourAnnouncement(id: $id) {
      ...AnnouncementGuide
    }
  }
  ${guideTourAnnouncementFragment}
`;

// get guide local announcement
export const GET_GUIDE_LOCAL_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getGuideLocalAnnouncement(id: $id) {
      ...AnnouncementGuide
    }
  }
  ${guideLocalAnnouncementFragment}
`;

// get agency announcement
export const GET_AGENCY_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getAgencyAnnouncement(id: $id) {
      ...AnnouncementAgency
    }
  }
  ${agencyAnnouncementFragment}
`;

// get agency tour announcement
export const GET_AGENCY_TOUR_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getAgencyTourAnnouncement(id: $id) {
      ...AnnouncementAgency
    }
  }
  ${agencyTourAnnouncementFragment}
`;

// get agency local announcement
export const GET_AGENCY_LOCAL_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getAgencyLocalAnnouncement(id: $id) {
      ...AnnouncementAgency
    }
  }
  ${agencyLocalAnnouncementFragment}
`;

// get agency local program announcement
export const GET_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getAgencyLocalProgramAnnouncement(id: $id) {
      ...AnnouncementAgency
    }
  }
  ${agencyLocalProgramAnnouncementFragment}
`;

// get supplier announcement
export const GET_SUPPLIER_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getSupplierAnnouncement(id: $id) {
      ...AnnouncementSupplier
    }
  }
  ${supplierAnnouncementFragment}
`;

// get phrase announcement
export const GET_PHRASE_ANNOUNCEMENT = gql`
  query($id: ID!) {
    getPhraseAnnouncement(id: $id) {
      ...AnnouncementPhrase
    }
  }
  ${phraseAnnouncementFragment}
`;

/**
 * list announcement with different types
 */

// hotel guide announcement
export const FETCH_LIST_HOTEL_GUIDE_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listHotelGuideAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      guide {
        value
      }

      olivari_guide_with_group {
        value
      }

      language_desc
    }
  }
`;

// hotel no guide announcement
export const FETCH_LIST_HOTEL_NO_GUIDE_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listHotelNoGuideAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// guide announcement
export const FETCH_LIST_GUIDE_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listGuideAnnouncementFilter(input: $input) {
      id
      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// guide tour announcement
export const FETCH_LIST_GUIDE_TOUR_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listGuideTourAnnouncementFilter(input: $input) {
      id
      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// guide local announcement
export const FETCH_LIST_GUIDE_LOCAL_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listGuideLocalAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// agency announcement
export const FETCH_LIST_AGENCY_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listAgencyAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// agency tour announcement
export const FETCH_LIST_AGENCY_TOUR_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listAgencyTourAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// agency local announcement
export const FETCH_LIST_AGENCY_LOCAL_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listAgencyLocalAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// agency local program announcement
export const FETCH_LIST_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listAgencyLocalProgramAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      hotel {
        establishment_id
        hotel_name {
          value
        }
      }

      language_desc
    }
  }
`;

// supplier announcement
export const FETCH_LIST_SUPPLIER_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listSupplierAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      group {
        value
      }

      guide {
        value
      }

      from
      to

      language_desc
    }
  }
`;

// phrase announcement
export const FETCH_LIST_PHRASE_ANNOUNCEMENT = gql`
  query($input: AnnouncementConditionInput) {
    listPhraseAnnouncementFilter(input: $input) {
      id

      total_table_count

      partner_name {
        value
      }

      external_id

      phrase_title {
        value
      }

      phrase_text {
        value
      }

      language_desc
    }
  }
`;
