import React from "react";
import { Switch, Route } from "react-router-dom";

import PlacesTabs from "./PlacesTabs";

const PlacesRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/grad_regija_drzava/"
        exact
        component={PlacesTabs}
      />
    </Switch>
  );
};

export default PlacesRouter;
