import gql from "graphql-tag";

/**
 * operative fragments
 */
export const guidesFragment = gql`
  fragment Guides on GuidesAll {
    partner_client_id
    partner_client_name
    partner_client_type_desc
    guide_id
    guide_first_name
    guide_last_name
    guide_phone
    guide_email
    dates
  }
`;
