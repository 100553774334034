import React, { Component } from "react";
import { connect } from "react-redux";

import ReactTableCustom from "../../../../components/UI/ReactTable";

import { changeEditData, removeTermin } from "../../../../store/actions";
import { dateDiff, formatDate } from "../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { establishmentContigentsAndHotels } from "../../../../utility/establishment";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class TerminsList extends Component {
  render() {
    if (this.props.offer_contract_document_type_id === "2") {
      return null;
    }

    const dataToShow = [...this.props.termins]
      .sort(function(a, b) {
        return new Date(a.from) - new Date(b.from);
      })
      .map(termin => {
        const { contigents } = termin;
        const {
          establishment_names: establishment_name,
          contigent_lists: contigents_list,
        } = establishmentContigentsAndHotels(contigents);

        return {
          ...termin,
          from: formatDate(termin.from),
          to: formatDate(termin.to),
          diff: dateDiff(termin.from, termin.to, "day") + 1,
          contigents_list,
          establishment_name,
        };
      });

    return (
      <div className="cols-2">
        <div className="form-section-one">
          <Paper elevation={1}>
            <ReactTableCustom
              data={dataToShow}
              columns={[
                {
                  Header: "TERMINI",
                  columns: [
                    {
                      Header: "Od",
                      accessor: "from",
                    },
                    {
                      Header: "Do",
                      accessor: "to",
                    },
                    {
                      Header: "Dana",
                      accessor: "diff",
                      width: 70,
                    },
                    {
                      Header: "Kontigent",
                      id: "contigents_list",
                      accessor: d => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0 auto",
                          }}
                        >
                          {d.contigents_list}
                        </div>
                      ),
                      width: 150,
                    },
                    {
                      Header: "Smještaj",
                      id: "establishment_name",
                      accessor: d => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0 auto",
                          }}
                        >
                          {d.establishment_name}
                        </div>
                      ),
                    },
                  ],
                },
              ]}
              innerProps={{
                pageSize: dataToShow.length,
                showPagination: false,
                sortable: false,
                filterable: false,
                filterAll: false,
                noDataText: "Nema dodanih termina",
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { termin_periods, offer_contract_document_type_id, language_id } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termins: termin_periods,
    create_subtermin,
    edit_subtermin,
    offer_contract_document_type_id,
    language_id,
  };
};

export default connect(
  mapStateToProps,
  { changeEditData, removeTermin },
)(withStyles(styles)(TerminsList));
