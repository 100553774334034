// libs
import _ from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";
// components
import EditIcon from "src/components/UI/EditIcon";
// constants
import {
  COLUMN_TITLE_EDIT,
  ANN_HOTEL_GUIDE_TITLE,
  ANN_HOTEL_GUIDE_INTRO,
  ANN_HOTEL_NO_GUIDE_TITLE,
  ANN_HOTEL_NO_GUIDE_INTRO,
  ANN_GUIDE_TITLE,
  ANN_GUIDE_INTRO,
  ANN_GUIDE_TOUR_TITLE,
  ANN_GUIDE_TOUR_INTRO,
  ANN_GUIDE_LOCAL_TITLE,
  ANN_GUIDE_LOCAL_INTRO,
  ANN_AGENCY_TITLE,
  ANN_AGENCY_INTRO,
  ANN_AGENCY_TOUR_TITLE,
  ANN_AGENCY_TOUR_INTRO,
  ANN_AGENCY_LOCAL_TITLE,
  ANN_AGENCY_LOCAL_INTRO,
  ANN_AGENCY_LOCAL_PROGRAM_TITLE,
  ANN_AGENCY_LOCAL_PROGRAM_INTRO,
  ANN_SUPPLIER_TITLE,
  ANN_SUPPLIER_INTRO,
  ANN_PHRASE_TITLE,
  ANN_PHRASE_INTRO,
  ANN_GROUP_LABEL,
  ANN_TERMIN_LABEL,
  ANN_HOTEL_LABEL,
  ANN_HOTEL_NAME_CATEGORY_LABEL,
  ANN_HOTEL_ADDRESS_LABEL,
  ANN_HOTEL_CHECK_IN_LABEL,
  ANN_HOTEL_CHECK_OUT_LABEL,
  ANN_HOTEL_BREAKFAST_LABEL,
  ANN_HOTEL_DINNER_LABEL,
  ANN_OLIVARI_GUIDE_GROUP_LABEL,
  ANN_VOUCHER_ACC_LABEL,
  ANN_GUIDE_ACC_LABEL,
  ANN_HOTEL_INCLUDES_LABEL,
  ANN_HOTEL_DINNER_DRINK_LABEL,
  ANN_BUS_PARKING_LABEL,
  ANN_IMPORTANT_NOTICE_LABEL,
  ANN_ROOMING_LIST_LABEL,
  ANN_ROOMING_LIST_TYPOLOGY_LABEL,
  ANN_ROOMING_LIST_CONTIGENT_LABEL,
  ANN_DRIVER_CONTACT_LABEL,
  ANN_PROGRAM_LABEL,
  ANN_INVOICE_WARRANT_LABEL,
  ANN_NR_OF_PERSONS_LABEL,
  ANN_GROUP_TREFFPUNKT_LABEL,
  ANN_SERVICE_FAVOR_LABEL,
  ANN_PAYMENT_LABEL,
  ANN_DATE_LABEL,
  ANN_TIME_LABEL,
  ANN_LANGUAGE_LABEL,
  ANN_PHRASE_TEXT_LABEL,
  ANN_FOOTER_MESSAGE_1_LABEL,
  ANN_FOOTER_MESSAGE_2_LABEL,
  COLUMN_TITLE_LANGUAGE,
} from "src/utility/labels";
import { tooltipCell } from "./TemplateAnnouncementCellValueHandler";

// components
import LanguageColumn from "./components/Language";

/**
 * annoucement template columns
 */
export default [
  {
    Header: COLUMN_TITLE_EDIT,
    id: "full",
    width: 65,
    filterable: false,
    accessor: template => (
      <div className="ReactTableFirstColumnDiv">
        <NavLink to={`/administracija/najava_predlozak/edit/${template.id}`} className="ReactTableFirstColumnDiv">
          <EditIcon />
        </NavLink>
      </div>
    ),
  },

  {
    Header: COLUMN_TITLE_LANGUAGE,
    id: "language",
    accessor: ({ language_id }) => <LanguageColumn languageId={language_id} />,
  },
  {
    Header: ANN_HOTEL_GUIDE_TITLE,
    id: "ann_hotel_guide_title",
    accessor: ({ ann_hotel_guide_title }) => tooltipCell(_.get(ann_hotel_guide_title, "translation")),
  },

  {
    Header: ANN_HOTEL_GUIDE_INTRO,
    accessor: ({ ann_hotel_guide_intro }) => tooltipCell(_.get(ann_hotel_guide_intro, "translation")),
    id: "ann_hotel_guide_intro",
  },

  {
    Header: ANN_HOTEL_NO_GUIDE_TITLE,
    accessor: ({ ann_hotel_no_guide_title }) => tooltipCell(_.get(ann_hotel_no_guide_title, "translation")),
    id: "ann_hotel_no_guide_title",
  },
  {
    Header: ANN_HOTEL_NO_GUIDE_INTRO,
    accessor: ({ ann_hotel_no_guide_intro }) => tooltipCell(_.get(ann_hotel_no_guide_intro, "translation")),
    id: "ann_hotel_no_guide_intro",
  },
  {
    Header: ANN_GUIDE_TITLE,
    accessor: ({ ann_guide_title }) => tooltipCell(_.get(ann_guide_title, "translation")),
    id: "ann_guide_title",
  },
  {
    Header: ANN_GUIDE_INTRO,
    accessor: ({ ann_guide_intro }) => tooltipCell(_.get(ann_guide_intro, "translation")),
    id: "ann_guide_intro",
  },
  {
    Header: ANN_GUIDE_TOUR_TITLE,
    accessor: ({ ann_guide_tour_title }) => tooltipCell(_.get(ann_guide_tour_title, "translation")),
    id: "ann_guide_tour_title",
  },
  {
    Header: ANN_GUIDE_TOUR_INTRO,
    accessor: ({ ann_guide_tour_intro }) => tooltipCell(_.get(ann_guide_tour_intro, "translation")),
    id: "ann_guide_tour_intro",
  },
  {
    Header: ANN_GUIDE_LOCAL_TITLE,
    accessor: ({ ann_guide_local_title }) => tooltipCell(_.get(ann_guide_local_title, "translation")),
    id: "ann_guide_local_title",
  },
  {
    Header: ANN_GUIDE_LOCAL_INTRO,
    accessor: ({ ann_guide_local_intro }) => tooltipCell(_.get(ann_guide_local_intro, "translation")),
    id: "ann_guide_local_intro",
  },
  {
    Header: ANN_AGENCY_TITLE,
    accessor: ({ ann_agency_title }) => tooltipCell(_.get(ann_agency_title, "translation")),
    id: "ann_agency_title",
  },
  {
    Header: ANN_AGENCY_INTRO,
    accessor: ({ ann_agency_intro }) => tooltipCell(_.get(ann_agency_intro, "translation")),
    id: "ann_agency_intro",
  },
  {
    Header: ANN_AGENCY_TOUR_TITLE,
    accessor: ({ ann_agency_tour_title }) => tooltipCell(_.get(ann_agency_tour_title, "translation")),
    id: "ann_agency_tour_title",
  },
  {
    Header: ANN_AGENCY_TOUR_INTRO,
    accessor: ({ ann_agency_tour_intro }) => tooltipCell(_.get(ann_agency_tour_intro, "translation")),
    id: "ann_agency_tour_intro",
  },
  {
    Header: ANN_AGENCY_LOCAL_TITLE,
    accessor: ({ ann_agency_local_title }) => tooltipCell(_.get(ann_agency_local_title, "translation")),
    id: "ann_agency_local_title",
  },
  {
    Header: ANN_AGENCY_LOCAL_INTRO,
    accessor: ({ ann_agency_local_intro }) => tooltipCell(_.get(ann_agency_local_intro, "translation")),
    id: "ann_agency_local_intro",
  },
  {
    Header: ANN_AGENCY_LOCAL_PROGRAM_TITLE,
    accessor: ({ ann_agency_local_program_title }) => tooltipCell(_.get(ann_agency_local_program_title, "translation")),
    id: "ann_agency_local_program_title",
  },
  {
    Header: ANN_AGENCY_LOCAL_PROGRAM_INTRO,
    accessor: ({ ann_agency_local_program_intro }) => tooltipCell(_.get(ann_agency_local_program_intro, "translation")),
    id: "ann_agency_local_program_intro",
  },
  {
    Header: ANN_SUPPLIER_TITLE,
    accessor: ({ ann_supplier_title }) => tooltipCell(_.get(ann_supplier_title, "translation")),
    id: "ann_supplier_title",
  },
  {
    Header: ANN_SUPPLIER_INTRO,
    accessor: ({ ann_supplier_intro }) => tooltipCell(_.get(ann_supplier_intro, "translation")),
    id: "ann_supplier_intro",
  },
  {
    Header: ANN_PHRASE_TITLE,
    accessor: ({ ann_phrase_title }) => tooltipCell(_.get(ann_phrase_title, "translation")),
    id: "ann_phrase_title",
  },
  {
    Header: ANN_PHRASE_INTRO,
    accessor: ({ ann_phrase_intro }) => tooltipCell(_.get(ann_phrase_intro, "translation")),
    id: "ann_phrase_intro",
  },
  {
    Header: ANN_GROUP_LABEL,
    accessor: ({ ann_group_label }) => tooltipCell(_.get(ann_group_label, "translation")),
    id: "ann_group_label",
  },
  {
    Header: ANN_TERMIN_LABEL,
    accessor: ({ ann_termin_label }) => tooltipCell(_.get(ann_termin_label, "translation")),
    id: "ann_termin_label",
  },
  {
    Header: ANN_HOTEL_LABEL,
    accessor: ({ ann_hotel_label }) => tooltipCell(_.get(ann_hotel_label, "translation")),
    id: "ann_hotel_label",
  },
  {
    Header: ANN_HOTEL_NAME_CATEGORY_LABEL,
    accessor: ({ ann_hotel_name_category_label }) => tooltipCell(_.get(ann_hotel_name_category_label, "translation")),
    id: "ann_hotel_name_category_label",
  },
  {
    Header: ANN_HOTEL_ADDRESS_LABEL,
    accessor: ({ ann_hotel_address_label }) => tooltipCell(_.get(ann_hotel_address_label, "translation")),
    id: "ann_hotel_address_label",
  },
  {
    Header: ANN_HOTEL_CHECK_IN_LABEL,
    accessor: ({ ann_hotel_check_in_label }) => tooltipCell(_.get(ann_hotel_check_in_label, "translation")),
    id: "ann_hotel_check_in_label",
  },
  {
    Header: ANN_HOTEL_CHECK_OUT_LABEL,
    accessor: ({ ann_hotel_check_out_label }) => tooltipCell(_.get(ann_hotel_check_out_label, "translation")),
    id: "ann_hotel_check_out_label",
  },
  {
    Header: ANN_HOTEL_BREAKFAST_LABEL,
    accessor: ({ ann_hotel_breakfast_label }) => tooltipCell(_.get(ann_hotel_breakfast_label, "translation")),
    id: "ann_hotel_breakfast_label",
  },
  {
    Header: ANN_HOTEL_DINNER_LABEL,
    accessor: ({ ann_hotel_dinner_label }) => tooltipCell(_.get(ann_hotel_dinner_label, "translation")),
    id: "ann_hotel_dinner_label",
  },
  {
    Header: ANN_OLIVARI_GUIDE_GROUP_LABEL,
    accessor: ({ ann_olivari_guide_group_label }) => tooltipCell(_.get(ann_olivari_guide_group_label, "translation")),
    id: "ann_olivari_guide_group_label",
  },
  {
    Header: ANN_VOUCHER_ACC_LABEL,
    accessor: ({ ann_voucher_acc_label }) => tooltipCell(_.get(ann_voucher_acc_label, "translation")),
    id: "ann_voucher_acc_label",
  },
  {
    Header: ANN_GUIDE_ACC_LABEL,
    accessor: ({ ann_guide_acc_label }) => tooltipCell(_.get(ann_guide_acc_label, "translation")),
    id: "ann_guide_acc_label",
  },
  {
    Header: ANN_HOTEL_INCLUDES_LABEL,
    accessor: ({ ann_hotel_includes_label }) => tooltipCell(_.get(ann_hotel_includes_label, "translation")),
    id: "ann_hotel_includes_label",
  },
  {
    Header: ANN_HOTEL_DINNER_DRINK_LABEL,
    accessor: ({ ann_hotel_dinner_drink_label }) => tooltipCell(_.get(ann_hotel_dinner_drink_label, "translation")),
    id: "ann_hotel_dinner_drink_label",
  },
  {
    Header: ANN_BUS_PARKING_LABEL,
    accessor: ({ ann_bus_parking_label }) => tooltipCell(_.get(ann_bus_parking_label, "translation")),
    id: "ann_bus_parking_label",
  },
  {
    Header: ANN_IMPORTANT_NOTICE_LABEL,
    accessor: ({ ann_important_notice_label }) => tooltipCell(_.get(ann_important_notice_label, "translation")),
    id: "ann_important_notice_label",
  },
  {
    Header: ANN_ROOMING_LIST_LABEL,
    accessor: ({ ann_rooming_list_label }) => tooltipCell(_.get(ann_rooming_list_label, "translation")),
    id: "ann_rooming_list_label",
  },
  {
    Header: ANN_ROOMING_LIST_TYPOLOGY_LABEL,
    accessor: ({ ann_rooming_list_typology_label }) =>
      tooltipCell(_.get(ann_rooming_list_typology_label, "translation")),
    id: "ann_rooming_list_typology_label",
  },
  {
    Header: ANN_ROOMING_LIST_CONTIGENT_LABEL,
    accessor: ({ ann_rooming_list_contigent_label }) =>
      tooltipCell(_.get(ann_rooming_list_contigent_label, "translation")),
    id: "ann_rooming_list_contigent_label",
  },
  {
    Header: ANN_DRIVER_CONTACT_LABEL,
    accessor: ({ ann_driver_contact_label }) => tooltipCell(_.get(ann_driver_contact_label, "translation")),
    id: "ann_driver_contact_label",
  },
  {
    Header: ANN_PROGRAM_LABEL,
    accessor: ({ ann_program_label }) => tooltipCell(_.get(ann_program_label, "translation")),
    id: "ann_program_label",
  },
  {
    Header: ANN_INVOICE_WARRANT_LABEL,
    accessor: ({ ann_invoice_warrant_label }) => tooltipCell(_.get(ann_invoice_warrant_label, "translation")),
    id: "ann_invoice_warrant_label",
  },
  {
    Header: ANN_NR_OF_PERSONS_LABEL,
    accessor: ({ ann_nr_of_persons_label }) => tooltipCell(_.get(ann_nr_of_persons_label, "translation")),
    id: "ann_nr_of_persons_label",
  },
  {
    Header: ANN_GROUP_TREFFPUNKT_LABEL,
    accessor: ({ ann_group_treffpunkt_label }) => tooltipCell(_.get(ann_group_treffpunkt_label, "translation")),
    id: "ann_group_treffpunkt_label",
  },
  {
    Header: ANN_SERVICE_FAVOR_LABEL,
    accessor: ({ ann_service_favor_label }) => tooltipCell(_.get(ann_service_favor_label, "translation")),
    id: "ann_service_favor_label",
  },
  {
    Header: ANN_PAYMENT_LABEL,
    accessor: ({ ann_payment_label }) => tooltipCell(_.get(ann_payment_label, "translation")),
    id: "ann_payment_label",
  },
  {
    Header: ANN_DATE_LABEL,
    accessor: ({ ann_date_label }) => tooltipCell(_.get(ann_date_label, "translation")),
    id: "ann_date_label",
  },
  {
    Header: ANN_TIME_LABEL,
    accessor: ({ ann_time_label }) => tooltipCell(_.get(ann_time_label, "translation")),
    id: "ann_time_label",
  },
  {
    Header: ANN_LANGUAGE_LABEL,
    accessor: ({ ann_language_label }) => tooltipCell(_.get(ann_language_label, "translation")),
    id: "ann_language_label",
  },
  {
    Header: ANN_PHRASE_TEXT_LABEL,
    accessor: ({ ann_phrase_text_label }) => tooltipCell(_.get(ann_phrase_text_label, "translation")),
    id: "ann_phrase_text_label",
  },
  {
    Header: ANN_FOOTER_MESSAGE_1_LABEL,
    accessor: ({ ann_footer_message_1_label }) => tooltipCell(_.get(ann_footer_message_1_label, "translation")),
    id: "ann_footer_message_1_label",
  },
  {
    Header: ANN_FOOTER_MESSAGE_2_LABEL,
    accessor: ({ ann_footer_message_2_label }) => tooltipCell(_.get(ann_footer_message_2_label, "translation")),
    id: "ann_footer_message_2_label",
  },
];
