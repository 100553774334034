import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import { getServiceType as fetchServiceType } from "../../../../graphql/query/administration";
import ServiceTypeForm from "./ServiceTypeForm";

import { updateServiceType } from "../../../../graphql/mutation/administration";

import { prepareForSending } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class EditServiceType extends Component {
  state = {};

  async componentDidMount() {
    const { serviceType } = this.props;

    const response = await this.props.client.query({
      query: fetchServiceType,
      variables: { id: serviceType.id },
    });

    if (response.data.getServiceType) {
      // set establishmentChain for editing
      this.setState({
        ...prepareForSending(response.data.getServiceType, ["__typename"]),
      });
    }
  }

  changeTranslationFieldData = language_id => ({ target: { value } }) => {
    this.setState(prev => ({
      ...prev,
      service_type_translations: prev.service_type_translations.map(translation => {
        if (translation.language_id === language_id) {
          return {
            ...translation,
            name: value,
          };
        }
        return translation;
      }),
    }));
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          id: this.props.serviceType.id,
          patch: {
            ...prepareForSending(prepareForSending(this.state, ["id"], ["service_type_translations"]), ["language"]),
          },
        },
      });

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const { serviceType } = this.props;

    return (
      <Fragment>
        <TableTitle title={`Ažuriranje tipa usluge ${serviceType.id}`} onBackPressed={this.props.closeEdit} />
        <ServiceTypeForm
          serviceType={this.state}
          changeTranslationField={this.changeTranslationFieldData}
          cancelToggle={this.props.closeEdit}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(updateServiceType)(withApollo(EditServiceType));
