/**
 * guide show columns
 */
export default [
  {
    Header: "Partner",
    accessor: "partner_client_name",
  },

  {
    Header: "Tip",
    accessor: "partner_client_type_desc",
  },

  {
    Header: "Ime",
    accessor: "guide_first_name",
  },

  {
    Header: "Prezime",
    accessor: "guide_last_name",
  },

  {
    Header: "Broj",
    accessor: "guide_phone",
  },

  {
    Header: "Email",
    accessor: "guide_email",
    width: 250,
  },
];
