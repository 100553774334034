import { config } from "../../../utility/globals";
import { alotmanTemplate, templateObject } from "./initialState";
import { prepareForSending } from "../../../utility/prepare";

export const updateOfferContractDocumentParam = (state, action) => {
  let newState = { ...state };

  // If changing offer type,delete all added termins
  if (action.param.key === "offer_contract_document_type_id") {
    if (newState.offer.offer_contract_document.offer_contract_document_type_id !== action.param.value) {
      // Change template fields based on offer type
      if (
        config.offer_type[action.param.value] === "Alotman" ||
        config.offer_type[newState.offer.offer_contract_document.offer_contract_document_type_id] === "Alotman"
      ) {
        let newOCD = { ...newState.offer.offer_contract_document };

        if (config.offer_type[action.param.value] === "Alotman") {
          newOCD = prepareForSending(newOCD, Object.keys(templateObject));
          newOCD = {
            ...newOCD,
            ...alotmanTemplate,
          };
        } else {
          newOCD = prepareForSending(newOCD, Object.keys(alotmanTemplate));
          newOCD = {
            ...newOCD,
            ...templateObject,
          };
        }

        newState = updateOfferContractDocumentObject(newState, {
          ...newOCD,
        });
      }
    }

    newState = updateOfferContractDocumentObject(newState, {
      ...newState.offer.offer_contract_document,
      termin_periods: [],
      offer_contract_document_services: [],
    });
  }

  return updateOfferContractDocumentObject(newState, {
    ...newState.offer.offer_contract_document,
    [action.param.key]: action.param.value,
  });
};

export const setupOfferDraft = (state, action) => {
  return updateOfferObject(state, {
    ...state.offer,
    ...action.param.offer,
    offer_contract_document: {
      ...state.offer.offer_contract_document,
      ...action.param.offer_contract_document,
    },
  });
};

export const updateOfferParam = (state, action) => {
  return updateOfferObject(state, {
    ...state.offer,
    [action.param.key]: action.param.value,
  });
};

const updateOfferContractDocumentObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    offer: {
      ...oldObject.offer,
      offer_contract_document: {
        ...updatedProps,
      },
    },
  };
};

const updateOfferObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    offer: {
      ...updatedProps,
    },
  };
};
