import gql from "graphql-tag";

export const listNotifications = gql`
  {
    listNotification {
      id
      notification_type_id
      notification_type {
        id
        code
      }
      offer_contract_document_id
      message
    }
  }
`;
