import React from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { get } from "lodash";
import OfferForm from "../OfferForm/OfferForm";
import { createOffer, createProgram, documentAttachmentUpload } from "../../../graphql/mutation/offers";
import { prepareForSending } from "../../../utility/prepare";
import { setupOfferDraftAsync, changeDocumentParam, changeOfferParameter } from "../../../store/actions";
import { getInquiry } from "../../../graphql/query/inquiry";
import { config } from "../../../utility/globals";
import { templateTranslationsQuery } from "../../../graphql/query/templateTranslation";
import { decimalNumbersTwoDigits } from "../../../utility/numbers";
import { CircularProgress } from "material-ui";

class OfferCreate extends React.Component {
  state = {
    selectedFiles: "",
  };

  deletePreparedFileToUpload = index => {
    if (this.state.selectedFiles && this.state.selectedFiles.length) {
      const selectedFiles = [...this.state.selectedFiles];

      selectedFiles.splice(index, 1);

      this.setState({
        selectedFiles,
      });
    }
  };

  async componentDidMount() {
    const { inquiry_id } = this.props.match.params;
    if (inquiry_id) {
      const response = await this.props.client.query({
        query: getInquiry,
        variables: { id: inquiry_id },
        fetchPolicy: "network-only",
      });

      const inquiry = get(response, "data.getInquiry");

      if (inquiry) {
        const language_id = get(inquiry, "partner_client.language_id");

        const templatePreparedForCreateOffer = await this.prepareTemplatesForCreateOffer(language_id);

        this.props.setupOfferDraftAsync({
          offer_data: {
            offer: {
              off_key: get(inquiry, "off_key"),
            },
            offer_contract_document: {
              partner_client_id: get(inquiry, "partner_client_id"),
              language_id,
              inquiry_external_id: get(inquiry, "external_id"),
              document_code: get(inquiry, "title"),
              ...templatePreparedForCreateOffer,
            },
          },
          callback: this.submit,
        });
      }

      /**
       * FIXME: Until now there was no need to handle changing offer type in edit mode, it was blocked.
       *  Now we need to delete previous termin (if switching between offer types and adding termins, every single one is still remained), add new one and handle validation
       */
    }
  }

  async prepareTemplatesForCreateOffer(language_id) {
    const templatesResponse = await this.props.client.query({
      query: templateTranslationsQuery,
      fetchPolicy: "network-only",
    });

    const listTemplateTranslations = get(templatesResponse, "data.listTemplateTranslation", []);

    const templates = this.retreiveLanguageTemplateContents(language_id, listTemplateTranslations);

    const templateValues = this.changeTemplateContent(language_id, listTemplateTranslations);

    const template = {
      ...templates,
      ...templateValues,
    };

    return Object.keys(template).reduce((obj, key) => {
      if (key === "id" || key === "__typename") {
        return obj;
      }

      switch (key) {
        case "vat":
          return { ...obj, vat_content: template[key] };
        case "sojourn_tax":
          return { ...obj, sojourn_tax_content: template[key] };
        case "free_place":
          return { ...obj, free_place_content: template[key] };
        case "price_validity":
          return { ...obj, price_validity_content: template[key] };
        case "surcharges":
          return { ...obj, surcharges_content: template[key] };
        case "payment":
          return { ...obj, payment_content: template[key] };
        case "storno":
          return { ...obj, storno_content: template[key] };
        case "program_changes":
          return { ...obj, program_changes_content: template[key] };
        case "content":
          return { ...obj, content_content: template[key] };
        case "program":
          return { ...obj, program_content: template[key] };
        case "room_list":
          return { ...obj, room_list_content: template[key] };
        case "booking_state":
          return { ...obj, booking_state_content: template[key] };
        case "comment_contigent":
          return { ...obj, comment_contigent: template[key] };
        case "document_date_validity_comment":
          return { ...obj, document_date_validity_comment: template[key] };
        default:
          return { ...obj, [key]: template[key] };
      }
    }, {});
  }

  retreiveLanguageTemplateContents(lang_id, listTemplateTranslations) {
    return lang_id ? listTemplateTranslations.find(({ language_id }) => language_id === lang_id) : null;
  }

  changeTemplateContent = (language_id, listTemplateTranslations) => {
    const template = this.retreiveLanguageTemplateContents(language_id, listTemplateTranslations);

    const {
      sojourn_tax_value,
      notification_price_validity,
      notification_booking_state,
      notification_room_list,
      notification_storno,
      notification_payment,
    } = this.props.offer_contract_document;

    const arr = [
      {
        name: "sojourn_tax_value",
        value: sojourn_tax_value,
      },
      {
        name: "notification_price_validity",
        value: notification_price_validity,
      },
      {
        name: "notification_booking_state",
        value: notification_booking_state,
      },
      {
        name: "notification_room_list",
        value: notification_room_list,
      },
      {
        name: "notification_storno",
        value: notification_storno,
      },
      {
        name: "notification_payment",
        value: notification_payment,
      },
    ];

    return arr.reduce((obj, { name, value }) => {
      switch (name) {
        case "sojourn_tax_value":
          if (template) {
            return {
              ...obj,
              [name]: decimalNumbersTwoDigits(value),
              sojourn_tax_content: template.sojourn_tax.replace(
                "${}",
                Number(decimalNumbersTwoDigits(value)).toFixed(2),
              ),
            };
          }
          return obj;
        case "notification_price_validity":
          if (template) {
            return {
              ...obj,
              [name]: value,
              price_validity_content: template.price_validity.replace("${}", value),
            };
          }
          return obj;
        case "notification_booking_state":
          if (template) {
            return {
              ...obj,
              [name]: value,
              booking_state_content: template.booking_state.replace("${}", value),
            };
          }
          return obj;
        case "notification_room_list":
          if (template) {
            return {
              ...obj,
              [name]: value,
              room_list_content: template.room_list.replace("${}", value),
            };
          }
          return obj;
        case "notification_storno":
          if (template) {
            return {
              ...obj,
              [name]: value,
              storno_content: template.storno.replace("${}", value),
            };
          }
          return obj;
        case "notification_payment":
          if (template) {
            return {
              ...obj,
              [name]: value,
              payment_content: template.payment.replace("${}", value),
            };
          }
          return obj;

        default:
          return { ...obj, [name]: value };
      }
    }, {});
  };

  onDrop = files => {
    this.setState({
      selectedFiles: files,
    });
  };

  uploadAttachments = async id => {
    const { selectedFiles } = this.state;

    if (selectedFiles && selectedFiles.length) {
      selectedFiles.forEach(async file => {
        await this.props.uploadDocument({
          variables: {
            offer_contract_document_id: id,
            file,
          },
        });
      });
    }
  };

  submit = async () => {
    const { program_days, offer, createOffer } = this.props;

    try {
      const response = await createOffer({
        variables: prepareForSending(offer, [
          "id",
          "services",
          "establishment",
          "typology",
          "referents",
          "referents_to_remove",
          "booking_option_description",
          "service_ids_to_delete",
          "service_type_ids_to_delete",
        ]),
      });

      const ocd_id = get(response, "data.createOffer.offer_contract_document.id");
      const offerId = get(response, "data.createOffer.id");
      const termin_periods = get(response, "data.createOffer.offer_contract_document.termin_periods");
      const offer_contract_document_type_id = get(
        response,
        "data.createOffer.offer_contract_document.offer_contract_document_type_id",
      );

      this.uploadAttachments(ocd_id);

      if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
        if (config.offer_type[offer_contract_document_type_id] !== "Alotman") {
          const insertedTermins = termin_periods;

          insertedTermins.forEach(async termin => {
            await this.props.createProgram({
              variables: {
                termin_period_id: termin.id,
                program_days: [...prepareForSending(program_days, ["id", "__typename"])],
              },
            });
          });
        }
      } else {
        this.props.createProgram({
          variables: {
            offer_contract_document_id: ocd_id,
            program_days: [...prepareForSending(program_days, ["id", "__typename"])],
          },
        });
      }

      this.props.history.push("/ponude/edit/" + offerId);
    } catch (error) {}
  };

  render() {
    const { inquiry_id } = this.props.match.params;
    // If comming from inquiry it will automatically create offer
    if (inquiry_id) {
      return <CircularProgress />;
    }

    return (
      <OfferForm
        title="Kreiranje ponude"
        onSave={this.submit.bind(this)}
        onDrop={this.onDrop}
        files={this.state.selectedFiles}
        deletePreparedFileToUpload={this.deletePreparedFileToUpload}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    offer,
    offer: { storno_comment, offer_status_id, offer_contract_document },
    program_days,
  } = state.offer;

  return {
    offer,
    storno_comment,
    offer_status_id,
    offer_contract_document,
    program_days,
  };
};

export default compose(
  graphql(createOffer, { name: "createOffer" }),
  graphql(documentAttachmentUpload, { name: "uploadDocument" }),
  graphql(createProgram, { name: "createProgram" }),
)(
  connect(
    mapStateToProps,
    { changeDocumentParam, changeOfferParameter, setupOfferDraftAsync },
  )(withApollo(OfferCreate)),
);
