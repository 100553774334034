import React from "react";

// ui
import { Tabs, Tab } from "material-ui/Tabs";
// components
import AnnouncementContentForm from "./components/ContentForm";

export default function EditAnnouncementTemplateContainer(props) {
  return (
    <Tabs>
      <Tab label="Ažuriranje predloška">
        <AnnouncementContentForm templateId={props.match.params.id} history={props.history} />
      </Tab>
    </Tabs>
  );
}
