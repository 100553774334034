// libs
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectProgramDayActivities } from "src/views/Guides/guidesSlice";

/**
 * use program Day hook for getting program by id from list
 */
export default function useProgramDay(programDayId) {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);

  const programDay = get(programDayActivities, "program_days", []).find(
    programDay => programDay.program_day_id === programDayId,
  );

  return {
    programDay,
  };
}
