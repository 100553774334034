import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import toastr from "toastr";
import ReactToPrint from "react-to-print";

import gql from "graphql-tag";

import { connect } from "react-redux";

import { DialogActions, DialogTitle, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import CalculatorHeader from "./CalculatorHeader";
import CalculatorServicesInAbsolutePrice from "./CalculatorServicesInAbsolutePrice";
import CalculatorServicesPerPerson from "./CalculatorServicesPerPerson";
import CalculatorEstablishment from "./CalculatorEstablishment";
import Calculator from "../Calculator";

import { changePriceStructure, changePriceStructurePrice } from "../../../../../store/actions";
import { priceStructureFragment } from "../../../../../graphql/fragments";
import { guidIdGenerator } from "../../../../../utility/numbers";

const styles = {
  dialogTitle: {
    padding: "12px !important",
  },
  dialogTitleStyling: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialogTitleText: {
    flex: "0 0 auto",
    margin: "8px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const updatePriceStructure = gql`
  mutation($id: ID!, $patch: UpdatePriceStructure!) {
    updatePriceStructure(input: { id: $id, patch: $patch }) {
      ...PriceStructure
    }
  }
  ${priceStructureFragment}
`;

class CalculatorUI extends Component {
  save = () => {
    // if in offer
    if (this.props.location.pathname.includes("/ponude")) {
      this.saveOfferCalculator();
    } else if (this.props.location.pathname.includes("/booking")) {
      this.saveBookingCalulator();
    }
  };

  saveOfferCalculator = async () => {
    if (this.props.match.params.id) {
      try {
        const {
          termin: { price_structure },
        } = this.props;
        const calculator = this.prepareCalculator();
        const price_typology = this.setPrices();

        const response = await this.props.mutate({
          variables: {
            id: price_structure.id,
            patch: {
              calculator,
              price_typology: JSON.stringify(price_typology),
            },
          },
        });
        if (response.data.updatePriceStructure) {
          this.storeCalculator(calculator);

          this.storePrices(price_typology);

          toastr.success("Uspješno unešen kalkulator i postavljene cijene");
        }
      } catch (error) {}
    } else {
      const calculator = this.prepareCalculator();
      const price_typology = this.setPrices();

      this.storeCalculator(calculator);

      this.storePrices(price_typology);
      toastr.success("Uspješno unešen kalkulator i postavljene cijene");
    }
  };

  saveBookingCalulator = async () => {
    try {
      const {
        termin: { price_structure },
      } = this.props;

      const calculator = this.prepareCalculator();

      const response = await this.props.mutate({
        variables: {
          id: price_structure.id,
          patch: {
            calculator,
          },
        },
        // update: () => this.props.refetchLastListBookingQuery()
        update: (store, { data: { updatePriceStructure } }) => {
          store.writeFragment({
            id: `PriceStructure:${updatePriceStructure.id}`,
            fragmentName: "PriceStructure",
            fragment: priceStructureFragment,
            data: updatePriceStructure,
          });
        },
      });

      const {
        data: { updatePriceStructure },
      } = response;

      this.props.savedBooking(updatePriceStructure);

      toastr.success("Uspješno unešen kalkulator ");
    } catch (error) {}
  };

  storeCalculator(calculator) {
    const { termin } = this.props;

    this.props.changePriceStructure({
      key: `calculator`,
      id: termin.id,
      value: calculator,
    });
  }

  storePrices(price_typology) {
    Object.keys(price_typology).forEach(key => {
      this.props.changePriceStructurePrice({
        key,
        id: this.props.termin.id,
        value: price_typology[key],
      });
    });
  }

  setPrices = () => {
    const { calculator } = this.props;
    let price_typology = {};

    Object.keys(calculator.totals).forEach(typology_group_key => {
      price_typology = {
        ...price_typology,
        [`${typology_group_key.split("total_price_for_").pop()}`]: calculator.totals[typology_group_key],
      };
    });

    return price_typology;
  };

  prepareCalculator() {
    const { termin } = this.props;
    // Checking if calculator is any already written
    if (termin.price_structure.calculator) {
      const { calculator: stringified_calc } = termin.price_structure;

      const calculator = JSON.parse(stringified_calc);

      if (this.props.location.pathname.includes("/ponude")) {
        calculator.forEach(calc => (calc.isCommercialActive = false));
      }

      if (this.props.location.pathname.includes("/booking")) {
        calculator.forEach(calc => (calc.isBookingActive = false));
      }

      calculator.push({
        ...this.props.calculator,
        id: guidIdGenerator(),
        isCommercialActive: !!this.props.location.pathname.includes("/ponude"),
        isBookingActive: !!this.props.location.pathname.includes("/booking"),
        booking: this.props.location.pathname.includes("/booking"),
      });

      return JSON.stringify(calculator);
    } else {
      const calculator = [
        {
          ...this.props.calculator,
          id: guidIdGenerator(),
          isCommercialActive: !!this.props.location.pathname.includes("/ponude"),
          isBookingActive: !!this.props.location.pathname.includes("/booking"),
        },
      ];

      return JSON.stringify(calculator);
    }
  }

  render() {
    const { handleCloseCalculator, classes, isNotDraft } = this.props;

    return (
      <Calculator.Consumer>
        {({
          calculator: { services_per_person, services_in_absolute_price, calculator_header, establishments },
          vat,
          getServicePerPerson,
          getAbsolutePriceService,
          getCalculatorHeader,
          getEstablishmentMethods,
        }) => (
          <Fragment>
            <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
              <div className={classes.dialogTitleStyling}>
                <div className={classes.dialogTitleText}>Kalkulator</div>
                <div>
                  <DialogActions>
                    <Button onClick={handleCloseCalculator} color="primary">
                      Odustani
                    </Button>

                    <ReactToPrint
                      pageStyle={`
                          @page {
                            size: A4 landscape;
                            margin: 1cm;
                          }
                                            
                          .content-print{
                            page-break-inside: avoid;
                          }

                          

                          .ReactTable .rt-table { overflow: hidden !important; }
                          .fix-width .ReactTable .rt-thead .rt-resizable-header { max-width: 80px !important }
                          .fix-width .ReactTable .-headerGroups .rt-th { max-width: 480px !important; }
                          .fix-width .ReactTable .rt-tbody .rt-td { max-width: 80px !important }
                          .fix-width .ReactTable .rt-resizer { display: none; }

                          .section-to-print{font-size: 8pt;}
                          .section-to-print input{font-size: 8pt;}
                          .section-to-print div{font-size: 8pt;}
                          .section-to-print span{font-size: 8pt;}
                          .section-to-print label{font-size: 12pt;}

                          .ReactTable .-pagination{
                            height:50px;
                          }
                          .section-to-print{
                            font-size: 12px !important;
                          }

                          .section-to-print button{
                            display: none
                          }

                          .booking-select {
                            display: none;
                          }

                          .print-span-container {
                            width: 100%;
                            text-align: center;
                          }

                          .select-print {
                            display: inline !important;
                            text-align: center;
                          }
                      `}
                      trigger={() => <Button color="primary">Ispis</Button>}
                      content={() => this.forPrint}
                    />
                  </DialogActions>
                  <DialogActions>
                    {!isNotDraft ? (
                      <Button onClick={this.save} color="primary">
                        Prihvati cijenu i postavi kalkulaciju kao aktivnu
                      </Button>
                    ) : null}
                  </DialogActions>
                </div>
              </div>
            </DialogTitle>

            <div ref={el => (this.forPrint = el)}>
              <div className="section-to-print">
                <CalculatorHeader calculator_header={calculator_header} getCalculatorHeader={getCalculatorHeader} />
                <CalculatorEstablishment
                  establishments={establishments}
                  getEstablishmentMethods={getEstablishmentMethods}
                />
                <CalculatorServicesInAbsolutePrice
                  getAbsolutePriceService={getAbsolutePriceService}
                  vat={vat}
                  services_in_absolute_price={services_in_absolute_price}
                />
                <CalculatorServicesPerPerson
                  services_per_person={services_per_person}
                  vat={vat}
                  getServicePerPerson={getServicePerPerson}
                />
              </div>
            </div>
          </Fragment>
        )}
      </Calculator.Consumer>
    );
  }
}

export default graphql(updatePriceStructure)(
  withStyles(styles)(
    connect(
      null,
      { changePriceStructure, changePriceStructurePrice },
    )(withRouter(CalculatorUI)),
  ),
);
