import React, { Component } from "react";
import { connect } from "react-redux";

import {
  addAdditionalService,
  deleteAdditionalService
} from "../../../../store/actions";
import ReactTableCustom from "../../../../components/UI/ReactTable";

class AdditionalOffers extends Component {
  render() {
    const { additional_services } = this.props;

    const dataToShow =
      additional_services && additional_services.length
        ? additional_services.map(activity => {
            // debugger;

            const act = this.props.listActivity.find(
              ({ id }) => id === activity.activity_id
            );
            const act_translated = act.activity_translations.find(
              ({ language_id }) => language_id === this.props.language_id
            );

            return {
              ...activity,
              name: act_translated.name
            };
          })
        : [];

    return (
      <div className="form-unit-double">
        <div className="field-label">
          <label className="label">Ostale usluge</label>
        </div>
        <ReactTableCustom
          data={dataToShow}
          columns={[
            {
              Header: "Ostale usluge",
              columns: [
                {
                  Header: "Usluga",
                  accessor: "name"
                },
                {
                  Header: "Cijena",
                  accessor: "actual_price"
                }
              ]
            }
          ]}
          innerProps={{
            pageSize: dataToShow.length,
            showPagination: false,
            sortable: false,
            filterable: false,
            filterAll: false
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    additional_services,
    language_id
  } = state.offer.offer.offer_contract_document;

  return {
    additional_services,
    language_id
  };
};

export default connect(
  mapStateToProps,
  { addAdditionalService, deleteAdditionalService }
)(AdditionalOffers);
