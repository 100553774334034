import * as actionTypes from "../actionTypes";

// all contracts filter value
export const setAllContractsInitialQueryInput = (limit, offset, selectedFilters, allContractsFilter) => {
  return {
    type: actionTypes.ALL_CONTRACTS_INITIAL_QUERY,
    limit: limit,
    offset: offset,
    selectedFilters: selectedFilters,
    allContractsFilter: allContractsFilter,
  };
};
