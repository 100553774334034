import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from "@material-ui/core";

const styles = {
  card: {
    width: "100%",
    marginBottom: 15,
  },
};

const PartnerClientContactShow = ({ contact, setContactToEdit, removeContact, classes }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid item container xs={12}>
          <Grid container spacing={24}>
            <Grid item sm={12} lg={2}>
              <FormControl fullWidth>
                <InputLabel shrink={true} htmlFor="first_name_partner_client_contact">
                  Ime
                </InputLabel>
                <Input
                  id="first_name_partner_client_contact"
                  name="first_name_partner_client_contact"
                  value={contact.first_name || ""}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} lg={2}>
              <FormControl fullWidth>
                <InputLabel shrink={true} htmlFor="last_name_partner_client_contact">
                  Prezime
                </InputLabel>
                <Input
                  id="last_name_partner_client_contact"
                  name="last_name_partner_client_contact"
                  value={contact.last_name || ""}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} lg={2}>
              <FormControl fullWidth>
                <InputLabel shrink={true} htmlFor="phone_partner_client_contact">
                  Telefon
                </InputLabel>
                <Input
                  id="phone_partner_client_contact"
                  name="phone_partner_client_contact"
                  value={contact.phone || ""}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} lg={2}>
              <FormControl fullWidth>
                <InputLabel shrink={true} htmlFor="email_client_contact">
                  Email
                </InputLabel>
                <Input id="email_client_contact" name="email_client_contact" value={contact.email || ""} disabled />
              </FormControl>
            </Grid>
            <Grid item sm={12} lg={4}>
              <Grid container spacing={24}>
                <Grid item>
                  <Button variant="contained" onClick={() => setContactToEdit(contact)}>
                    Ažuriraj
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => removeContact(contact.id)}>
                    Izbriši
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(PartnerClientContactShow);
