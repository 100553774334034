import React, { Fragment } from "react";

// ui
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "material-ui/CircularProgress/CircularProgress";
//hooks
import useAnnouncementContainer from "./hooks/useAnnouncementContainer";
// constants
import { LABEL_GUIDE_STATUS_PARTNER, LABEL_GUIDE_ANNOUNCEMENT_COMMENT } from "src/utility/labels";

// style
import "./style.css";

// components
import Suppliers from "../Suppliers";
import LayoutComment from "../LayoutComment";
import AnnouncementEdit from "../AnnouncementEdit";

/**
 * Container contains all components inside announcmentDrawer
 */
export default function Container() {
  const { loadingAnnouncementPartners } = useAnnouncementContainer();

  // loading view
  if (loadingAnnouncementPartners) {
    return (
      <Grid container spacing={8}>
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
        <Grid item>
          <Typography className="loadingLabel" variant="body2">
            Dohvaćam podatke
          </Typography>
        </Grid>
      </Grid>
    );
  }

  // return root
  return (
    <Fragment>
      <label className="label labelAnnouncement">{LABEL_GUIDE_STATUS_PARTNER}</label>
      <Suppliers />

      <label className="label labelAnnouncement">{LABEL_GUIDE_ANNOUNCEMENT_COMMENT}</label>
      <LayoutComment />

      <AnnouncementEdit />
    </Fragment>
  );
}
