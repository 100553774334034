import React from "react";

async function setState(newState, callback) {
  if (typeof newState === "function") {
    // newState will return new state
    this.state = newState(this.state);
    // set new state to all components which use initialAOState
    this.setStateListeners.forEach(listener => {
      listener(this.state);
    });
    if (callback) {
      await callback(this.state);
    }
    return;
  }
  this.state = { ...newState };
  this.setStateListeners.forEach(listener => {
    listener(this.state);
  });
  if (callback) {
    callback(this.state);
  }
}

function useCustom() {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    this.setStateListeners.push(newListener);
    return () => {
      this.setStateListeners = this.setStateListeners.filter(l => l !== newListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [this.state, this.setState];
}

const useToolbarHook = initialState => {
  const store = { state: initialState, setStateListeners: [] };
  store.setState = setState.bind(store);
  return useCustom.bind(store);
};

const initialState = { filtersOpen: false };

export const useToolbarState = useToolbarHook(initialState);
