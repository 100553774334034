import React, { Component } from "react";
import { connect } from "react-redux";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";

import { updateOffer } from "../../../../graphql/mutation/offers";

import Add from "../../../../components/UI/AddIcon";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { setActivitiesForAutocompleteSelect } from "../../../../utility/activities";
import { prepareForSending } from "../../../../utility/prepare";
import { addAdditionalService, deleteAdditionalService, copyUpdatedOffer } from "../../../../store/actions";
import { guidIdGenerator } from "../../../../store/reducers/offer/helpers";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { decimalNumbersTwoDigits } from "../../../../utility/numbers";
import FormValidator from "../../../../components/UI/FormValidator";
import { FormControl, FormHelperText } from "@material-ui/core";

// icon styles
const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class AdditionalOffers extends Component {
  static schema = {
    properties: {
      actual_price: {
        format: "numeric",
        isNotEmpty: true,
        errorMessage: "Cijena mora biti upisana u decimalnom obliku",
      },
      activity_id: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Potrebno je odabrati aktivnost",
      },
    },
  };

  state = {
    id: guidIdGenerator(),
    actual_price: "",
    activity_id: "",
  };

  setActualPrice = ({ target: { value } }) => this.setState({ actual_price: decimalNumbersTwoDigits(value) });

  selectActivity = selected => {
    if (!selected) {
      return this.setState({
        activity_id: "",
        actual_price: "",
      });
    }

    const activity = this.props.listActivity.find(act => act.id === selected);
    this.setState({
      activity_id: activity.id,
      actual_price: Number(activity.default_price).toFixed(2),
    });
  };

  addAdditionalServiceHandler = () => {
    // check if this is already created offer
    const { offer_id } = this.props;

    if (offer_id) {
      this.createAdditionalService();
    } else {
      const { id, actual_price, activity_id } = this.state;
      this.props.addAdditionalService({ id, actual_price, activity_id });
      this.setState({
        id: guidIdGenerator(),
        actual_price: "",
        activity_id: "",
      });
    }
  };

  deleteAdditionalService = id => () => {
    // check if this is already created offer
    const { offer_id } = this.props;

    if (offer_id) {
      this.deleteCreatedAdditionalService(id);
    } else {
      this.props.deleteAdditionalService(id);
    }
  };

  /**
   * create additional service with mutation
   */
  createAdditionalService = async () => {
    const { id, actual_price, activity_id } = this.state;
    const { offer_id } = this.props;

    const offer_diffs = {
      offer_contract_document: {
        additional_services_to_add: [
          {
            id: id,
            actual_price: actual_price,
            activity_id: activity_id,
          },
        ],
      },
    };

    let editOfferResponse = null;

    try {
      editOfferResponse = await this.props.mutate({
        variables: {
          id: offer_id,
          patch: offer_diffs,
        },
      });

      toastr.success("Uspješno dodana usluga");

      const { updateOffer } = editOfferResponse.data;

      this.props.copyUpdatedOffer(prepareForSending(updateOffer, ["__typename"]));

      this.setState({
        id: guidIdGenerator(),
        actual_price: "",
        activity_id: "",
      });
    } catch (error) {
      toastr.error("Greška prilikom dodavanja usluge");
    }
  };

  /**
   * delete created additional service
   */
  deleteCreatedAdditionalService = async additionalServiceId => {
    const { offer_id } = this.props;

    const offer_diffs = {
      offer_contract_document: {
        additional_services_ids_delete: [additionalServiceId],
      },
    };

    let editOfferResponse = null;

    try {
      editOfferResponse = await this.props.mutate({
        variables: {
          id: offer_id,
          patch: offer_diffs,
        },
      });

      const { updateOffer } = editOfferResponse.data;

      this.props.copyUpdatedOffer(prepareForSending(updateOffer, ["__typename"]));

      toastr.success("Uspješno izbrisana usluga");
    } catch (error) {
      toastr.error("Greška prilikom brisanja usluge");
    }
  };

  render() {
    let languageNotSelected = null;
    if (!this.props.language_id) {
      languageNotSelected = <div>Potrebno je odabrati jezik</div>;
    }

    const activities = setActivitiesForAutocompleteSelect(this.props.listActivity, this.props.language_id.toString());
    const { additional_services, isNotDraft } = this.props;

    const dataToShow =
      additional_services && additional_services.length
        ? additional_services.map(activity => {
            // debugger;

            const act = this.props.listActivity.find(({ id }) => id === activity.activity_id.toString());
            const act_translated = act.activity_translations.find(
              ({ language_id }) => language_id.toString() === this.props.language_id.toString(),
            );

            return {
              ...activity,
              actual_price: activity.actual_price + " €",
              name: act_translated.name,
            };
          })
        : [];

    return (
      <React.Fragment>
        <div className="form-unit-triple-icon">
          <div className="field-label is-autocomplete">
            <label className="label">Ostale usluge</label>
          </div>
          {languageNotSelected || (
            <FormValidator data={this.state} schema={AdditionalOffers.schema} submit={this.addAdditionalServiceHandler}>
              {({ error, errorMsg, submitAndRecreate }) => {
                return (
                  <React.Fragment>
                    <div className="cols-2">
                      <AutocompleteSelect
                        error={error("/activity_id")}
                        errorMsg={errorMsg("/activity_id")}
                        placeholder="Odabir aktivnosti"
                        dataSource={activities}
                        style={{ width: "auto" }}
                        autocompleteHandler={this.selectActivity}
                        defaultValue={this.state.activity_id}
                        disabled={isNotDraft}
                      />
                      <FormControl error={error("/actual_price")}>
                        <TextField
                          value={this.state.actual_price}
                          onChange={this.setActualPrice}
                          placeholder="Cijena"
                          disabled={isNotDraft}
                        />
                        {error("/actual_price") && <FormHelperText>{errorMsg("/actual_price")}</FormHelperText>}
                      </FormControl>
                    </div>
                    {!isNotDraft ? (
                      <Add
                        param1={this.state.actual_price}
                        param2={this.state.activity_id}
                        onClickHandler={submitAndRecreate}
                      />
                    ) : null}
                  </React.Fragment>
                );
              }}
            </FormValidator>
          )}
        </div>

        <div className="form-unit-double">
          <div />
          <ReactTableCustom
            data={dataToShow}
            columns={[
              {
                Header: "Ostale usluge",
                columns: [
                  {
                    Header: "Usluga",
                    accessor: "name",
                  },
                  {
                    Header: "Cijena",
                    id: "actual_price",
                    accessor: row => <div style={{ margin: "0 auto" }}>{row.actual_price}</div>,
                  },
                  {
                    Header: "Izbriši",
                    id: "delete_service",
                    accessor: activity =>
                      !isNotDraft ? (
                        <DeleteForeverIcon
                          className={this.props.classes.icon}
                          onClick={this.deleteAdditionalService(activity.id)}
                        />
                      ) : null,
                  },
                ],
              },
            ]}
            innerProps={{
              pageSize: dataToShow.length,
              showPagination: false,
              sortable: false,
              filterable: false,
              filterAll: false,
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      id: offer_id,
      offer_contract_document: { additional_services, language_id },
    },
  } = state.offer;

  return {
    offer_id,
    additional_services,
    language_id,
  };
};

export default compose(graphql(updateOffer))(
  connect(
    mapStateToProps,
    { addAdditionalService, deleteAdditionalService, copyUpdatedOffer },
  )(withApollo(withStyles(styles)(AdditionalOffers))),
);
