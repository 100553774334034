import React from "react";

// libs
import _ from "lodash";

// hooks
import useProgramDays from "./hooks/useProgramDays";

// constants
import { TITLE_GUIDE_TRIPS } from "src/utility/labels";

// components
import DayContainer from "./components/DayContainer";

// style
import "./style.css";

/**
 * used for rendering program days content
 */
export default function ProgramDays() {
  const { programDayActivities } = useProgramDays();

  const programDays = _.get(programDayActivities, "program_days");

  // return root
  return (
    <div>
      <label className="label labelProgramDays">{TITLE_GUIDE_TRIPS}</label>

      {programDays &&
        programDays.map((programDay, index) => {
          return (
            <DayContainer key={programDay.program_day_id} programDayId={programDay.program_day_id} dayNo={index + 1} />
          );
        })}
    </div>
  );
}
