import { useAnnouncementOrdersLocalStorage } from "src/views/AnnouncementOrders/useAnnouncementOrdersLocalStorage";

/**
 * useToolbar is entry point of data for announcement orders toolbar component
 */
export default function useToolbar() {
  // local storage
  const { tableState, setTableState } = useAnnouncementOrdersLocalStorage();

  const announcementOrdersFilters = tableState.announcementOrdersFiltersSelected;

  /**
   * handleOnFilterResetClick
   */
  const handleOnFilterResetClick = () => {
    setTableState({
      ...tableState,
      announcementOrdersFiltersSelected: [],
      announcementOrdersPageSelected: 0,
      announcementOrdersPageSizeSelected: 10,
    });
  };

  /**
   * get is reset disabled
   */
  const getIsResetEnabled = () => {
    if (announcementOrdersFilters) {
      return announcementOrdersFilters.length;
    } else {
      return false;
    }
  };

  return {
    // functions
    getIsResetEnabled,
    handleOnFilterResetClick,
  };
}
