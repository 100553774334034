// libs
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// redux state
import { setGuideDrawerPayload, setGuideDrawers } from "src/views/Guides/guidesSlice";

// constants
import { GUIDE_DRAWER_ANNOUNCEMENT, GUIDE_DRAWER_ANNOUNCEMENT_ORDER } from "src/utility/constants";

/**
 * useAdditionalFunctions handle buttons in row
 */
export default function useAdditionalFunctions() {
  // history
  let history = useHistory();

  // state
  const dispatch = useDispatch();

  // handle on announcement click
  const handleOnAnnouncementClick = programId => {
    dispatch(
      setGuideDrawerPayload({
        programId: programId,
      }),
    );

    dispatch(setGuideDrawers({ isOpen: true, drawerType: GUIDE_DRAWER_ANNOUNCEMENT }));
  };

  // handle on announcement order click
  const handleOnAnnouncementOrderClick = (
    programId,
    externalId,
    themeName,
    terminFrom,
    terminTo,
    hasDictate,
    dictateId,
  ) => {
    /**
     * check if announcement order has been already created,
     * navigate user to selected announcementOrder
     */
    if (hasDictate) {
      history.push(`/nalozi/edit/${dictateId}`);
    } else {
      dispatch(
        setGuideDrawerPayload({
          programId: programId,
          externalId: externalId,
          themeName: themeName,
          terminFrom: terminFrom,
          terminTo: terminTo,
          hasDictate: hasDictate,
          dictateId: dictateId,
        }),
      );

      dispatch(setGuideDrawers({ isOpen: true, drawerType: GUIDE_DRAWER_ANNOUNCEMENT_ORDER }));
    }
  };

  return { handleOnAnnouncementClick, handleOnAnnouncementOrderClick };
}
