// libs
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectGuideScheduleDrawers,
  selectGuideSchedules,
  selectGuideScheduleServiceTypes,
  setGuideDrawerPayload,
  setGuideDrawers,
  setGuideSchedules,
} from "src/views/Guides/guidesSlice";

// graphql

// constants
import { GUIDE_DRAWER_SERVICES } from "src/utility/constants";
import { useMutation } from "react-apollo";
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";
import { get } from "lodash-es";

/**
 * useGuideService is entry point of data for guide service drawer
 */
export default function useGuideService() {
  // redux state
  const guideServiceTypes = useSelector(selectGuideScheduleServiceTypes);
  const guideDrawers = useSelector(selectGuideScheduleDrawers);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // change guide schedule item
  const [updateGuideExtraFields, { loading }] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_SERVICES,
        isOpen: false,
      }),
    );
  };

  /**
   * handle on payment text change
   */
  const handleOnGuideServiceChange = async () => {
    //TODO: handle on guide services change
    const guideServiceEnum = guideServiceTypes.find(
      guideService => guideService.name === guidePayload.guideServiceStatusSelected,
    );

    if (!guideServiceEnum) {
      return toastr.error("Pogreška prilikom promjene status, pokušajte ponovo");
    }

    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: guidePayload.programId,
          guide_services: guideServiceEnum.name,
        },
      },
    });

    let guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran status");

      // update list guides
      let listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === guidePayload.programId) {
          return {
            ...guide,
            guide_services: get(guideUpdate, "guide_services"),
          };
        }
        return guide;
      });

      // update guide shedules list in state
      dispatch(setGuideSchedules(listGuidesUpdate));

      // close drawer
      onCloseDrawer();
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    // variables
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_SERVICES],
    loading,
    // functions
    handleOnGuideServiceChange,
    onCloseDrawer,
  };
}
