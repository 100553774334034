import React, { Component } from "react";

import DrawerWrapper from "../../../../components/UI/Drawer";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import CalculatorModal from "../../../Offers/OfferForm/Calculator/CalculatorModal";

import { withStyles } from "@material-ui/core";
import { withApollo } from "react-apollo";

const openInNewWindow = id => () => {
  const windowHeight = window.innerHeight;

  window.open(
    `/ugovor/pregled/${id}`,
    "_blank",
    `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=793.7007874,height=${windowHeight}`
  );
};

const style = {
  root: {
    paddingLeft: "0px !important"
  }
};

class CalculatorAndContractDrawer extends Component {
  state = {
    termin: "",
    calculations: [],
    openCalculator: false,
    calculator: false
  };

  handleClickOpenCalculator = calculator => () => {
    this.setState({ openCalculator: true, calculator });
  };

  handleCloseCalculator = () => {
    this.setState({ openCalculator: false, calculator: false });
  };

  saveCalculator = priceStructure => {
    this.setState(
      prev => ({
        termin: {
          ...prev.termin,
          price_structure: priceStructure
        }
      }),
      () => this.calculationsShow(this.state.termin)
    );
  };

  calculationsShow(termin) {
    this.setState({
      calculations: termin.price_structure.calculator
        ? JSON.parse(termin.price_structure.calculator)
        : []
    });
  }

  componentDidMount = () => {
    const { termin } = this.props;

    this.setState({ termin }, () => this.calculationsShow(this.state.termin));
  };

  render() {
    const { toggle, open, termin } = this.props;

    return (
      <DrawerWrapper
        toggle={toggle}
        open={open}
        title="Kalkulacije i pregled ugovora"
      >
        <List>
          <ListItem
            button
            // open with internal id
            onClick={openInNewWindow(this.props.termin.booking_id)}
          >
            <Avatar>
              <ImageIcon />
            </Avatar>
            <ListItemText
              // show external_id to user
              primary={`Pregled ugovora ${
                this.props.termin.booking_external_id
              }`}
            />
          </ListItem>
        </List>

        <CalculatorModal
          savedBooking={this.saveCalculator}
          refetchLastListBookingQuery={this.props.refetchLastListBookingQuery}
          termin={termin}
          document_code={termin.document_code}
        />
      </DrawerWrapper>
    );
  }
}

export default withStyles(style)(withApollo(CalculatorAndContractDrawer));
