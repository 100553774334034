import gql from "graphql-tag";

export const templateTranslationsQuery = gql`
  {
    listTemplateTranslation {
      id
      vat
      free_place
      price_validity
      surcharges
      sojourn_tax
      payment
      storno
      program_changes
      content
      program
      room_list
      booking_state
      language_id
      comment_contigent
      document_date_validity_comment
    }
  }
`;
