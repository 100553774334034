import { guidIdGenerator } from "../../../../utility/numbers";

export default {
  calculator: {
    calculator_header: {
      from: "",
      to: "",
      date_diff: "",
      group_name: "",
      grp_quantity: 20,
      grp_min_quantity: 18,
      ruc: 5,
      comment: "",
    },
    establishments: [],
    services_in_absolute_price: [],
    services_per_person: [],
    total_calculation: [],
    totals: {},
  },
  /****  For Header caluclations */
  total_calc: {
    id: guidIdGenerator(),
    establishment_info: "",
    typologies: [],
  },
  total_typology: {
    id: guidIdGenerator(),
    room_name: "",
    capacity: 0,
    typology_group_id: "",
  },
  /****
   * END
   * For Header caluclations */

  /****  For Services */

  service_per_person: {
    id: guidIdGenerator(),
    service: "",
    vat: "",
    quantity: "",
    import: "",
    ruc: "",
    pr_izl: 0,
    exit: 0,
  },
  service_in_absolute_price: {
    id: guidIdGenerator(),
    service: "",
    vat: "",
    price: 0,
    quantity: 0,
    total: 0,
    pr_izl: 0,
    exit: 0,
  },

  /****
   * END
   * For Services */

  establishment: {
    id: guidIdGenerator(),
    establishment_info: {
      service: "",
      vat: "",
      quantity: "",
    },
    rooms_calculations: [],
  },
  establishment_calculation: {
    // typology_id spremiti
    id: guidIdGenerator(),
    room_name: "",
    capacity: 0,
    typology_group_id: 0,
    import: "0",
    discount: "0",
    izn_discount: "0",
    commision: "0",
    izn_commision: "0",
    net: "0",
    pre_exit: "0",
    exit: "0",
    ruc: "0",
    total: "0",
    gratis: {
      gratis: "",
      gratis_price: 0,
      gratis_quantity: 1,
      gratis_exit: 0,
    },
  },
  gratis: ["Da", "Ne"],
  vat: [0, 10, 25],
};
