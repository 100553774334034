import React from "react";
// libs
import _ from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectGroupOperativeStatusTypes, selectGuideScheduleServiceTypes } from "src/views/Guides/guidesSlice";

// constants
import {
  COLUMN_TITLE_GUIDE_CONTRACT_NO,
  COLUMN_TITLE_GUIDE_BOOKING_STATUS,
  COLUMN_TITLE_GUIDE_PARTNER,
  COLUMN_TITLE_GUIDE_HOTEL,
  COLUMN_TITLE_GUIDE_ARRIVAL,
  COLUMN_TITLE_GUIDE_DEPARTURE,
  COLUMN_TITLE_GUIDE_BOOKING_STATE,
  COLUMN_TITLE_GUIDE_TRANSFER,
  COLUMN_TITLE_GUIDE_PROGRAM,
  COLUMN_TITLE_GUIDE_SERVICE_GUIDE,
  COLUMN_TITLE_GUIDE_TRIP_ACTIVITY,
  COLUMN_TITLE_GUIDE_NAMES,
  COLUMN_TITLE_GUIDE_GROUP_STATUS,
  COLUMN_TITLE_GUIDE_LAYOUT_COMMENT,
  COLUMN_TITLE_GUIDE_COMMENT_GUIDE,
  COLUMN_TITLE_GUIDE_ADDITIONAL_FUNCTIONS,
  COLUMN_TITLE_GUIDE_PAYMENT,
  COLUMN_TITLE_GUIDE_CATALOG,
} from "src/utility/labels";
import {
  ROW_ID_GUIDE_ADDITIONAL_FUNCTIONS,
  ROW_ID_GUIDE_CATALOG,
  ROW_ID_GUIDE_PAYMENT,
  ROW_ID_GUIDE_SERVICES,
} from "src/utility/constants";

// utils
import { formatDate } from "src/utility/dates";
import { formatFromFilterDate, formatToFilterDate } from "src/utility/globals";

// components
import AdditionalFunctions from "./components/AdditionalFunctions";
import FilterGuideService from "./components/FilterEnumTypes";
import Activities from "./components/Activities";
import SeasonFilter from "./components/SeasonFilter";
import GuideItem from "./components/Guide";
import Expander from "./components/Expander";
import {
  commentLayoutCell,
  guideNoticesCell,
  busTransferCell,
  groupStatusCell,
  catalogCell,
  paymentCell,
} from "./GuidesCellValueHandler";

/**
 * guides table columns
 */
export default [
  {
    // expander column
    id: "expander",
    Header: "Otvori",
    headerClassName: "hideOnPrint",
    className: "hideOnPrint",
    show: true,
    filterable: false,
    expander: true,
    width: 50,
    Expander: ({ row }) => {
      let programDays = _.get(row, "_original.program_days", []);
      let programId = _.get(row, "_original.program_id");

      return <Expander programId={programId} programDays={programDays} />;
    },
  },
  {
    // contract external id | Br. ugovora
    id: "external_id",
    Header: COLUMN_TITLE_GUIDE_CONTRACT_NO,
    width: 80,
    accessor: "external_id",
  },
  {
    // booking status | Status bookinga
    id: "booking_status",
    Header: COLUMN_TITLE_GUIDE_BOOKING_STATUS,
    width: 120,
    accessor: "booking_status",
    filterable: false,
  },
  {
    // Partner list | Partner
    id: "partner_name",
    Header: COLUMN_TITLE_GUIDE_PARTNER,
    width: 120,
    accessor: "partner_name",
  },
  {
    // hotel list | Hotel
    id: "establishment_name",
    Header: COLUMN_TITLE_GUIDE_HOTEL,
    width: 200,
    accessor: "establishment_names",
    Cell: row => {
      const establishment_names = _.get(row, "original.establishment_names", []);

      if (establishment_names) {
        return (
          <div>
            {establishment_names.map(establishment => (
              <div key={establishment.establishment_id}>{establishment.establishment_name}</div>
            ))}
          </div>
        );
      }
      return null;
    },
  },
  {
    // Arrival | Dolazak
    id: "from",
    Header: COLUMN_TITLE_GUIDE_ARRIVAL,
    width: 112,
    accessor: "from",
    Cell: row => {
      const from = _.get(row, "original.from", null);

      if (from) {
        return formatDate(from);
      }
      return null;
    },
    Filter: ({ filter, onChange }) => {
      const handleChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFromFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <SeasonFilter dateSelected={filter} handleOnDateChange={handleChange} />;
    },
  },
  {
    // Departure | Odlazak
    id: "to",
    Header: COLUMN_TITLE_GUIDE_DEPARTURE,
    width: 112,
    accessor: "to",
    Cell: row => {
      const to = _.get(row, "original.to", null);

      if (to) {
        return formatDate(to);
      }
      return null;
    },
    Filter: ({ filter, onChange }) => {
      const handleChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatToFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <SeasonFilter dateSelected={filter} handleOnDateChange={handleChange} />;
    },
  },
  {
    // Booking state | Stanje bookinga
    id: "booking_state",
    Header: COLUMN_TITLE_GUIDE_BOOKING_STATE,
    width: 200,
    accessor: "booking_state",
    filterable: false,
    Cell: ({ value }) => <div style={{ whiteSpace: "pre-line" }}>{value}</div>,
  },
  {
    // Transfer type | Olivar bus / Transfer
    id: "transport",
    Header: COLUMN_TITLE_GUIDE_TRANSFER,
    width: 140,
    accessor: data => busTransferCell(data),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => {
          // check and parse if transport is boolean
          let transportWhereCondition = event.target.value ? event.target.value === "true" : "";

          return onChange(transportWhereCondition);
        }}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
      >
        <option value="">Prikaži sve</option>
        <option value={true}>Ima</option>
        <option value={false}>Nema</option>
      </select>
    ),
  },
  {
    // Program label | Program ( Tema / ime putovanja )
    id: "theme_name",
    Header: COLUMN_TITLE_GUIDE_PROGRAM,
    width: 220,
    accessor: "theme_name",
  },
  {
    // Guide services / Usluge vodiča
    id: ROW_ID_GUIDE_SERVICES,
    Header: COLUMN_TITLE_GUIDE_SERVICE_GUIDE,
    width: 120,
    accessor: "guide_services",

    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%", cursor: "pointer" }}>{value}</div>,

    Filter: ({ filter, onChange }) => {
      const guideServiceTypes = useSelector(selectGuideScheduleServiceTypes);

      return <FilterGuideService filter={filter} onChange={onChange} enumTypes={guideServiceTypes} />;
    },
  },
  {
    // Trip activities list / Izleti/Aktivnosti
    id: "program_days",
    Header: COLUMN_TITLE_GUIDE_TRIP_ACTIVITY,
    width: 150,
    accessor: "program_days",
    Cell: row => {
      const programDays = _.get(row, "original.program_days", []);
      const programId = _.get(row, "original.program_id");

      return <Activities programId={programId} programDays={programDays} />;
    },
  },
  {
    // Guide names / Vodič
    id: "g_name",
    Header: COLUMN_TITLE_GUIDE_NAMES,
    width: 250,
    accessor: "guides",
    Cell: row => {
      const programId = _.get(row, "original.program_id");
      const guides = _.get(row, "original.guides", null);
      return <GuideItem programId={programId} guides={guides} />;
    },
  },
  {
    // Group status / Status grupe
    id: "group_operativa_status",
    Header: COLUMN_TITLE_GUIDE_GROUP_STATUS,
    width: 120,
    accessor: data => groupStatusCell(data),
    Filter: ({ filter, onChange }) => {
      const groupOperativeStatusTypes = useSelector(selectGroupOperativeStatusTypes);

      return <FilterGuideService filter={filter} onChange={onChange} enumTypes={groupOperativeStatusTypes} />;
    },
  },
  {
    // Guide comment / Opaska vodič
    id: "guide_layout_comment",
    Header: COLUMN_TITLE_GUIDE_LAYOUT_COMMENT,
    width: 120,
    accessor: data => commentLayoutCell(data),
  },
  {
    // Guide comment / Opaska vodič
    id: "guide_notices",
    Header: COLUMN_TITLE_GUIDE_COMMENT_GUIDE,
    width: 120,
    accessor: data => guideNoticesCell(data),
  },
  {
    // payment / naplata
    id: ROW_ID_GUIDE_PAYMENT,
    Header: COLUMN_TITLE_GUIDE_PAYMENT,
    width: 120,
    filterable: false,
    accessor: data => paymentCell(data),
  },
  {
    // catalog
    id: ROW_ID_GUIDE_CATALOG,
    Header: COLUMN_TITLE_GUIDE_CATALOG,
    width: 120,
    filterable: false,
    accessor: data => catalogCell(data),
  },
  {
    // Additional functions / Dodatne funkcije
    id: ROW_ID_GUIDE_ADDITIONAL_FUNCTIONS,
    Header: COLUMN_TITLE_GUIDE_ADDITIONAL_FUNCTIONS,
    headerClassName: "hideOnPrint",
    className: "hideOnPrint",
    width: 330,
    maxWidth: 330,
    filterable: false,
    Cell: row => {
      const programId = _.get(row, "original.program_id");
      const externalId = _.get(row, "original.external_id");
      const themeName = _.get(row, "original.theme_name");
      const terminFrom = _.get(row, "original.from");
      const terminTo = _.get(row, "original.to");

      const hasDictate = _.get(row, "original.has_dictate");
      const dictateId = _.get(row, "original.dictate_id");

      return (
        <AdditionalFunctions
          programId={programId}
          externalId={externalId}
          themeName={themeName}
          terminFrom={terminFrom}
          terminTo={terminTo}
          hasDictate={hasDictate}
          dictateId={dictateId}
        />
      );
    },
  },
];
