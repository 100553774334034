const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const isEmail = email => (emailPattern.test(email) ? false : "Not email");

export const isRequired = value => (/.*\S.*/.test(value) ? false : "Field is required");

export const isNaNumber = value => (isNaN(value) ? "Field has to be a number" : false);

export const boolIsNaN = value => !!isNaN(value);

export const terminsEmpty = termins => (termins.length < 1 ? "At least one termin has to be selected" : false);

export const contigentsEmpty = termins => (termins.length ? false : "At least one contigent has to be selected");

export const servicesEmpty = services => (services.length ? false : "At least one service has to be selected");

export const isDate = date => (new Date(date).toDateString() === "Invalid Date" ? "Must be set as date format" : false);
