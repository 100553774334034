import * as types from "../../actionTypes";

const initialState = {
  templateInt: {
    language: "",
    getLanguageDescriptions(language_id) {
      return this[Number(language_id) || this.language];
    },
    // 1. Croatian (in database)
    1: {
      day: "Dan",
      document_title_offer: "Ponuda",
      document_title_contract: "Ugovor",
      partner_client_phone: "Telefon",
      partner_client_fax: "Fax",
      hotel: "Hotel",
      hotels: "Hotels",
      contigent: "Kontigent",
      price: "Cijena",
      price_per_person: "Cijena po osobi u: ",
      price_per_day: "Cijena po danu u: ",
      tourist_tax_person: "Boravišna pristrojba po osobi i danu: ",
      tourist_tax_day: "Boravišna pristrojba po danu: ",
      additional_services: "Dodatne usluge",
      group: "Grupa",
      service_package: "Paket usluga",
      services: "Hotelske usluge",
      vat_content: "PDV",
      sojourn_tax_content: "Boravišna pristrojba",
      free_place_content: "Slobodno mjesto",
      other_conditions: "OSTALI UVJETI",
      price_validity: "Minimalni broj osoba",
      booking_state: "Stanje rezervacije",
      room_state: "Stanje soba",
      program_content: "Sadržaj programa",
      content_content: "Fotografije",
      program_changes_content: "Izmjene programa",
      storno: "Storno",
      payment: "Plaćanje",
      surcharges: "Dodatno",
      offer_validity: "Datum opcije",
      program_sequence: "slijed programa",
      children_discounts: "Popust na djecu",
      included_in_the_price: "Uključeno u cijenu",
      comment: "Komentar",
      first_booking_station: "Prvo stanje bookinga",
      rooms_are_bookable: "Raspoložive sobe",
      definitive_room_list: "Definitivna lista soba",
      pay: "Plaćanje",
      payment_rules: "Pravila plaćanja",
      cancellation_costs: "Troškovi otkazivanja",
    },
    // 2. English (in database)
    2: {
      day: "Day",
      document_title_offer: "Offer",
      document_title_contract: "Contract",
      partner_client_phone: "Phone",
      partner_client_fax: "Fax",
      hotel: "Hotel",
      hotels: "Hotels",
      contigent: "Contigent",
      price: "Price",
      price_per_person: "PACKAGE PRICE PER PERSON IN: ",
      price_per_day: "PACKAGE PRICE PER DAY IN:",
      tourist_tax_person: "Tourist tax per person: ",
      tourist_tax_day: "Tourist tax per day: ",
      additional_services: "Additional services",
      group: "Group",
      service_package: "Service package",
      services: "Hotel services",
      vat_content: "VAT",
      sojourn_tax_content: "tourist tax",
      free_place_content: "Free place",
      other_conditions: "OTHER CONDITIONS",
      price_validity: "PRICE VALIDITY",
      booking_state: "Booking state",
      room_state: "Room state",
      program_content: "Program content",
      content_content: "Photo",
      program_changes_content: "Program changes",
      storno: "Storno",
      payment: "Payment",
      surcharges: "Extra",
      offer_validity: "Validity",
      program_sequence: "program sequence",
      children_discounts: "Children discount",
      included_in_the_price: "Included in price",
      comment: "comment",
      first_booking_station: "First booking state",
      rooms_are_bookable: "Bookable rooms",
      definitive_room_list: "Definitive room list",
      pay: "Pay",
      payment_rules: "Payment rules",
      cancellation_costs: "Cancellation costs",
    },
    // 3. German (in database)
    3: {
      day: "Tag",
      document_title_offer: "Angebot",
      document_title_contract: "VERTRAG",
      partner_client_phone: "Telefon",
      partner_client_fax: "Fax",
      hotel: "Hotel",
      hotels: "Hotels",
      contigent: "Kontigent",
      price: "Preise",
      price_per_person: "PAKETPREIS PRO PERSON IM: ",
      price_per_day: "PREIS PRO PERSON UND TAG IM: ",
      tourist_tax_person: "Kurtaxe pro person und tag: ",
      tourist_tax_day: "Kurtaxe pro tag: ",
      additional_services: "Zuatzleistungen",
      group: "GRUPPE",
      service_package: "LEISTUNGSPAKET",
      services: "VERPFLEGUNG",
      vat_content: "MwSt",
      sojourn_tax_content: "Kurtaxe",
      free_place_content: "Freiplatz",
      other_conditions: "ANDERE BEDINGUNGEN",
      price_validity: "PREISGÜLTIGKEIT",
      booking_state: "BUCHUNGSTAND",
      room_state: "ZIMMERLISTE",
      program_content: "PROGRAMM INHALT",
      content_content: "BILDMATERIAL",
      program_changes_content: "PROGRAMM-ÄNDERUNGEN",
      storno: "Storno",
      payment: "BEZAHLUNG",
      surcharges: "ZUSATZKOSTEN",
      offer_validity: "OPtIOn",
      program_sequence: "programmablauf",
      children_discounts: "KINDERERMÄSSIGUNG",
      included_in_the_price: "IM PREIS INBEGRIFFEN",
      comment: "BEMERKUNG",
      first_booking_station: "ERSTE BUCHUNGSTAND",
      rooms_are_bookable: "ZIMMER SIND BUCHBAR",
      definitive_room_list: "DEFINITIVE ZIMMERLISTE",
      pay: "BEZAHLUNG",
      payment_rules: "ZAHLUNGSVORSCHRIFTEN",
      cancellation_costs: "STORNOKOSTEN",
    },
    // 4. Italian (in database)
    4: {
      day: "Giorno",
      document_title_offer: "offerta",
      document_title_contract: "accordo",
      partner_client_phone: "telefono",
      partner_client_fax: "Fax",
      hotel: "albergo",
      hotels: "alberghi",
      contigent: "contingente",
      price: "prezzi",
      price_per_person: "PREZZO DEL PACCHETTO PER PERSONA IM: ",
      price_per_day: "PREZZO DEL PACCHETTO PER GIORNO IM: ",
      tourist_tax_person: "Tassa di soggiorno a persona: ",
      tourist_tax_day: "Tassa di soggiorno al giorno: ",
      additional_services: "servizi aggiuntivi",
      group: "GRUPPO",
      service_package: "Performance Package",
      services: "ALIMENTARE",
      vat_content: "IVA",
      sojourn_tax_content: "tassa di soggiorno",
      free_place_content: "Posto libero",
      other_conditions: "ALTRE CONDIZIONI",
      price_validity: "PREZZO VALIDITA",
      booking_state: "PRENOTAZIONI SUPPORTO",
      room_state: "CAMERA LIST",
      program_content: "PROGRAMMA INDICE",
      content_content: "IMMAGINI",
      program_changes_content: "CAMBIAMENTI DEL PROGRAMMA",
      storno: "cancellazione",
      payment: "PAGAMENTO",
      surcharges: "EXTRA",
      offer_validity: "opzione",
      program_sequence: "sequenza di programma",
      children_discounts: "Riduzioni per bambini",
      included_in_the_price: "INCLUSO NEL PREZZO",
      comment: "Nota",
      first_booking_station: "PRIMA STAZIONE DI PRENOTAZIONE",
      rooms_are_bookable: "LE CAMERE SONO PRENOTABILI",
      definitive_room_list: "ELENCO AMBIENTI DEFINITIVI",
      pay: "PAGAMENTO",
      payment_rules: "REGOLE DI PAGAMENTO",
      cancellation_costs: "COSTI DI ANNULLAMENTO",
    },
  },
};

export default function(state = initialState, action) {
  if (action.type === types.SET_OFFER_CONTRACT_PREVIEW) {
    return {
      ...state,
      preview: action.preview,
      ...action.lists,
    };
  }

  if (action.type === types.RESET_PREVIEW) {
    return { ...initialState };
  }

  if (action.type === types.SET_PREVIEW_LANGUAGE) {
    return {
      ...state,
      templateInt: {
        ...state.templateInt,
        language: action.language_id,
      },
    };
  }

  return state;
}
