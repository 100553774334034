import gql from "graphql-tag";

// create announcement order
export const FETCH_CREATE_ANNOUNCEMENT_ORDER = gql`
  mutation($input: CreateDictateInput!) {
    createDictate(input: $input) {
      id
      program_id
      dictate_room_text
      dictate_extra_text
      dictate_gratis_text
      dictate_invoice_text
      date
      dictate_data {
        theme_name
        external_id
        pax
        from
        to
      }
    }
  }
`;

// update announcement order
export const FETCH_UPDATE_ANNOUNCEMENT_ORDER = gql`
  mutation($input: UpdateDictateInput!) {
    updateDictate(input: $input) {
      id
      program_id
      dictate_room_text
      dictate_extra_text
      dictate_gratis_text
      dictate_invoice_text
      date
      dictate_data {
        theme_name
        external_id
        pax
        from
        to
      }
    }
  }
`;
