import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import toastr from "toastr";

import { getEstablishmentChain } from "../../../../graphql/query/administration";
import EstablishmentChainForm from "./EstablishmentChainForm";
import { updateEstablishmentChain } from "../../../../graphql/mutation/administration";
import { removeAllEmptyKeys, prepareForSending } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class EditEstablishmentChain extends Component {
  state = {
    establishmentChain: {
      name: "",
      city_id: "",
    },
  };

  async componentDidMount() {
    const { establishmentChain } = this.props;

    const response = await this.props.client.query({
      query: getEstablishmentChain,
      variables: { id: establishmentChain.id },
    });

    if (response.data.getEstablishmentChain) {
      // set establishmentChain for editing
      this.setState({
        establishmentChain: { ...response.data.getEstablishmentChain },
      });
    }
  }

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        establishmentChain: {
          ...prevState.establishmentChain,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { establishmentChain } = this.state;

      await this.props.mutate({
        variables: {
          id: establishmentChain.id,
          ...removeAllEmptyKeys(prepareForSending(establishmentChain), ["__typename", "city", "id"]),
        },
      });
      toastr.success("Uspješno ažurirano");

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const { establishmentChain } = this.state;

    if (!establishmentChain.id) {
      return null;
    }

    return (
      <Fragment>
        <TableTitle title="Ažuriranje smještajnog lanca" onBackPressed={this.props.closeEdit} />
        <EstablishmentChainForm
          establishmentChain={establishmentChain}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.closeEdit}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(updateEstablishmentChain)(withApollo(EditEstablishmentChain));
