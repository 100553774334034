import { useCallback } from "react";

// libs
import { get } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectActivityPartners,
  selectGuideSchedules,
  selectProgramDayActivities,
  setGuideSchedules,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_PROGRAM_DAY_CONTENT } from "src/graphql/mutation/operative";

/**
 * useContact is entry point of data for contactItem component
 */
export default function useContact(partnerClientId) {
  // redux state
  const listGuides = useSelector(selectGuideSchedules);
  const programDayActivities = useSelector(selectProgramDayActivities);
  const activityPartnerList = useSelector(selectActivityPartners);

  const dispatch = useDispatch();

  // change program day activities mutation
  const [updateProgramDayActivities, { loading }] = useMutation(UPDATE_PROGRAM_DAY_CONTENT);

  let listPartnerClientContacts = [];

  if (partnerClientId) {
    let listPartnerClientSelected = activityPartnerList.find(partnerClient => partnerClient.id === partnerClientId);

    if (listPartnerClientSelected) {
      listPartnerClientContacts = listPartnerClientSelected.contacts;
    }
  }

  // handle on contact select
  const handleOnContactSelect = useCallback(
    async (programDayId, programDayContentId, partnerContactId) => {
      const updateProgramDayResponse = await updateProgramDayActivities({
        variables: {
          input: {
            program_day_content_id: programDayContentId,
            guide_id: partnerContactId,
          },
        },
      });

      const programUpdated = get(updateProgramDayResponse, "data.UpdateProgramDayContentAndGuideCalendarFields");

      if (programUpdated) {
        // todo handle on program update
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programUpdated.program_day_content_id) {
                  return {
                    ...programDayContent,
                    guide_id: programUpdated.guide_id,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });

        // update program day activities
        dispatch(
          setProgramDayActivities({
            ...programDayActivities,
            guides: get(programUpdated, "guides", []),
            program_days: program_days_updated,
          }),
        );

        // update list guides
        const listGuidesUpdate = listGuides.map(guide => {
          if (guide.program_id === programDayActivities.program_id) {
            return {
              ...guide,
              guides: get(programUpdated, "guides", []),
            };
          }
          return guide;
        });

        // update guide schedules
        dispatch(setGuideSchedules(listGuidesUpdate));
      } else {
        toastr.error("Greška prilikom unosa vodiča");
      }
    },
    [dispatch, listGuides, programDayActivities, updateProgramDayActivities],
  );

  return {
    listPartnerClientContacts,
    loading,
    handleOnContactSelect,
  };
}
