// libs
import _ from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleRowExpanded, setGuideScheduleRowExpanded } from "src/views/Guides/guidesSlice";

/**
 * useGuideItem is entry point of data for guide item column component
 */
export default function useGuideItem(programId) {
  // redux state
  const guidesRowExpanded = useSelector(selectGuideScheduleRowExpanded);

  const dispatch = useDispatch();

  // expanded value
  const expanded = !!guidesRowExpanded[programId];

  /**
   * onExpansionClick - expand, shrink
   */
  const onExpansionClick = () => {
    const diff = { [programId]: !guidesRowExpanded[programId] };

    dispatch(setGuideScheduleRowExpanded({ ...guidesRowExpanded, ...diff }));
  };

  const getSortedDatesWithGuides = guides => {
    let datesWithGuides = [];

    guides.forEach(guide => {
      guide.dates &&
        guide.dates.forEach(date => {
          datesWithGuides.push({ date: moment(date, "DD.MM.YYYY"), guide: guide });
        });
    });

    const sortedArray = _.orderBy(
      datesWithGuides,
      dateWithGuide => {
        return dateWithGuide.date;
      },
      ["asc"],
    );

    return sortedArray;
  };

  const isGuideSameAsPrevios = (previousGuides, currentGuide) => {
    return previousGuides === currentGuide;
  };

  const isGuideSameAsNext = (nextGuide, currentGuide) => {
    return nextGuide === currentGuide;
  };

  return {
    expanded,
    getSortedDatesWithGuides,
    isGuideSameAsNext,
    isGuideSameAsPrevios,
    onExpansionClick,
  };
}
