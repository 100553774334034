import React, { Fragment, Component } from "react";

// Shared components
import Comment from "../../../../components/UI/CommentTextArea";

import TerminsShow from "./TerminsShow";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import { config, autosize } from "../../../../utility/globals";

class TerminData extends Component {
  componentDidUpdate() {
    this.autoResize();
  }

  componentDidMount = () => {
    this.autoResize();
  };

  autoResize() {
    if (document.getElementsByName(`comment_term_period`).length) {
      autosize(document.getElementsByName(`comment_term_period`)[0]);
    }

    if (config.offer_type[Number(this.props.offer_contract_document_type_id)] === "Tour") {
      if (document.getElementsByName(`comment_establishment`).length) {
        autosize(document.getElementsByName(`comment_establishment`)[0]);
      }
      if (document.getElementsByName(`comment_contigent`).length) {
        autosize(document.getElementsByName(`comment_contigent`)[0]);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Selected termins */}
        <TerminsShow />

        {/* Termini komentar */}
        <div className="cols-2">
          <div className="form-section-one">
            {config.offer_type[Number(this.props.offer_contract_document_type_id)] === "Tour" && (
              <Fragment>
                <div className="form-unit-double">
                  <div />
                  <Comment
                    name="comment_establishment"
                    defaultValue={this.props.comment_establishment}
                    label="Komentar smještaja"
                    inputProps={{ disabled: true }}
                  />
                </div>
                <div className="form-unit-double">
                  <div />
                  <Comment
                    name="comment_contigent"
                    defaultValue={this.props.comment_contigent}
                    label="Komentar kontigenta"
                    inputProps={{ disabled: true }}
                  />
                </div>
              </Fragment>
            )}

            <div className="form-unit-double">
              <div />
              <Comment
                name="comment_term_period"
                defaultValue={this.props.comment_term_period}
                label="Komentar termina"
                inputProps={{ disabled: true }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    from,
    to,
    offer: {
      offer_contract_document: {
        comment_term_period,
        comment_establishment,
        comment_contigent,
        termin_periods,
        offer_contract_document_type_id,
      },
    },
  } = state.offer;
  return {
    from,
    to,
    termin_periods,
    comment_establishment,
    comment_contigent,
    comment_term_period,
    offer_contract_document_type_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddTermin: () => dispatch(actions.addTermin()),
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    createProgramDays: days => dispatch(actions.createProgramDays(days)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TerminData);
