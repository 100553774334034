import React, { Component } from "react";

import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import "../../../../../styles/calculator.css";
import CalculatorEstablishmentInfo from "./CalculatorEstablishmentInfo";
import CalculatorEstablishmentCalculation from "./CalculatorEstablishmentCalculation";
import Calculator from "../Calculator";
import { guidIdGenerator } from "../../../../../utility/numbers";

const style = { padding: "24px" };

class CalculatorEstablishment extends Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.establishments) !== JSON.stringify(this.props.establishments);
  }

  addEstablishment = (state, establishment_calculation_id) => {
    const {
      calculator: { establishments },
      establishment,
      establishment_calculation,
    } = state;

    const establishments_clone = [...establishments];

    // make copy of establishment caluclation and insert new one after it
    if (establishments_clone.length) {
      const est_index = establishments_clone.map(({ id }) => String(id)).indexOf(String(establishment_calculation_id));

      if (est_index !== -1) {
        establishments_clone.splice(est_index + 1, 0, { ...establishments_clone[est_index], id: guidIdGenerator() });
      } else {
        establishments_clone.push({ ...establishments_clone[0], id: guidIdGenerator() });
      }
    }
    // no establishemnts, insert empty establishment calculation
    else {
      establishments_clone.push({
        ...establishment,
        id: guidIdGenerator(),
        rooms_calculations: [...establishment.rooms_calculations, establishment_calculation],
      });
    }

    return {
      ...state,
      calculator: {
        ...state.calculator,
        establishments: establishments_clone,
      },
    };
  };

  static EstablishmentsList = class extends Component {
    shouldComponentUpdate(nextProps) {
      return JSON.stringify(nextProps.establishments) !== JSON.stringify(this.props.establishments);
    }

    render() {
      const { establishments, onAdding } = this.props;

      return (
        <DialogContent style={style} className="page-break content-print">
          {establishments.map((establishment, index) => (
            <Grid container spacing={16} key={`establishment_calc_${establishment.id}`}>
              <Grid item xs={12}>
                <Calculator.Consumer>
                  {({ vat, getEstablishmentMethods }) => (
                    <div className="ReactTable -striped -highlight">
                      <div className=" rt-table">
                        <div className=" rt-thead -headerGroups">
                          <div className=" rt-tr">
                            <div className=" rt-th">SMJEŠTAJ</div>
                          </div>
                        </div>
                        <div className="establishment-divider">
                          <CalculatorEstablishmentInfo
                            vat={vat}
                            getEstablishmentMethods={getEstablishmentMethods}
                            establishment={{ ...establishment, index }}
                          />
                          <div>
                            <CalculatorEstablishmentCalculation
                              establishment={{ ...establishment, index }}
                              getEstablishmentMethods={getEstablishmentMethods}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Calculator.Consumer>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" size="small" onClick={onAdding(establishment.id)}>
                  DODAJ SMJEŠTAJ
                </Button>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
      );
    }
  };
  static NoEstablishments = ({ onAdding }) => (
    <DialogContent style={style} className="page-break content-print">
      <Grid container spacing={32}>
        <Grid item xs={12}>
          <div className="ReactTable -striped -highlight">
            <div className=" rt-table">
              <div className=" rt-thead -headerGroups">
                <div className=" rt-tr">
                  <div className=" rt-th">SMJEŠTAJ</div>
                </div>
              </div>
              <div className="establishment-divider">
                <div>Nema podataka</div>
                <div>Nema podataka</div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" size="small" onClick={onAdding}>
            DODAJ SMJEŠTAJ
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );

  render() {
    const { establishments, getEstablishmentMethods } = this.props;
    const { onAdding } = getEstablishmentMethods({
      onAdding: this.addEstablishment,
    });

    if (establishments && establishments.length) {
      return <CalculatorEstablishment.EstablishmentsList establishments={establishments} onAdding={onAdding} />;
    }
    return <CalculatorEstablishment.NoEstablishments onAdding={onAdding} />;
  }
}

export default CalculatorEstablishment;
