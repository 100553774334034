import React, { Component } from "react";

import ReactTableCustom from "../../../../../components/UI/ReactTable";
import BootstrapLikeInput from "../../../../../components/UI/BootstrapLikeInput";
import { gratis } from "../../../../../utility/calculator";

class CalculatorEstablishmentInfo extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(nextProps.establishment.rooms_calculations) !==
        JSON.stringify(this.props.establishment.rooms_calculations) ||
      nextProps.establishment.quantity !== this.props.establishment.quantity ||
      nextProps.establishment.index !== this.props.establishment.index
    );
  }

  changeTypologyField = (prevState, key, index, establishment_id, typology_id, value) => {
    return {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        establishments: prevState.calculator.establishments.map((est, est_index) => {
          if (establishment_id === est.id) {
            return {
              ...est,
              rooms_calculations: est.rooms_calculations.map(typ => {
                if (typ.id === typology_id && index === est_index) {
                  return {
                    ...typ,
                    [key]: value,
                  };
                }
                return typ;
              }),
            };
          }
          return est;
        }),
      },
    };
  };

  changeTypologyGratisField = (prevState, key, index, establishment_id, typology_id, value) => {
    return {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        establishments: prevState.calculator.establishments.map((est, est_index) => {
          if (establishment_id === est.id && index === est_index) {
            return {
              ...est,
              rooms_calculations: est.rooms_calculations.map(typ => {
                if (typ.id === typology_id) {
                  return {
                    ...typ,
                    gratis: {
                      ...typ.gratis,
                      [key]: value,
                    },
                  };
                }
                return typ;
              }),
            };
          }
          return est;
        }),
      },
    };
  };

  render() {
    const { establishment, getEstablishmentMethods } = this.props;

    const { changeTypologyData, changeTypologyGratisData, changeTypologyGratisNumsData } = getEstablishmentMethods({
      changeTypologyField: this.changeTypologyField,
      changeTypologyGratisField: this.changeTypologyGratisField,
      changeTypologyGratisNumsField: this.changeTypologyGratisField,
    });

    return establishment.rooms_calculations.map(calculation => {
      const data = [{ ...calculation }];

      return (
        <div key={calculation.id} style={{ paddingBottom: "10px" }} className="content-print fix-width">
          <ReactTableCustom
            data={data}
            columns={[
              {
                Header: calculation.room_name,
                columns: [
                  {
                    Header: "ULAZ",
                    id: "import",
                    width: 85,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.import}
                        onChange={changeTypologyData("import", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                  {
                    Header: "POPUST",
                    id: "discount",
                    width: 100,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.discount}
                        onChange={changeTypologyData("discount", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                  {
                    Header: "PROV",
                    id: "commision",
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.commision}
                        onChange={changeTypologyData("commision", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                  {
                    Header: "PRED IZL",
                    id: "pre_exit",
                    width: 100,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.pre_exit}
                        onChange={changeTypologyData("pre_exit", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                  {
                    Header: "IZLAZ",
                    id: "exit",
                    width: 100,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.exit}
                        onChange={changeTypologyData("exit", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                  {
                    Header: "TOTAL",
                    id: "total",
                    width: 100,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.total}
                        onChange={changeTypologyData("total", establishment.index, establishment.id, d.id)}
                      />
                    ),
                  },
                ],
              },
              {
                Header: "Gratis",
                columns: [
                  {
                    Header: "GRATIS",
                    id: "gratis",
                    width: 65,
                    accessor: d => (
                      <div className="print-span-container">
                        <select
                          className="booking-select"
                          onChange={changeTypologyGratisData("gratis", establishment.index, establishment.id, d.id)}
                          value={d.gratis.gratis}
                        >
                          <option value="" />
                          <option value={true}>Da</option>
                          <option value={false}>Ne</option>
                        </select>
                        <span className="select-print" style={{ display: "none" }}>
                          {gratis(d)}
                        </span>
                      </div>
                    ),
                  },
                  {
                    Header: "KOL",
                    id: "gratis_quantity",
                    width: 65,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.gratis.gratis_quantity}
                        onChange={changeTypologyGratisNumsData(
                          "gratis_quantity",
                          establishment.index,
                          establishment.id,
                          d.id,
                        )}
                      />
                    ),
                  },
                  {
                    Header: "IZNOS",
                    id: "gratis_exit",
                    width: 65,
                    accessor: d => (
                      <BootstrapLikeInput
                        value={d.gratis.gratis_exit}
                        onChange={changeTypologyGratisNumsData(
                          "gratis_exit",
                          establishment.index,
                          establishment.id,
                          d.id,
                        )}
                      />
                    ),
                  },
                ],
              },
            ]}
            innerProps={{
              pageSize: data.length,
              showPagination: false,
              sortable: false,
              filterable: false,
              filterAll: false,
              noDataText: "Nema dodanih usluga",
            }}
          />
        </div>
      );
    });
  }
}

export default CalculatorEstablishmentInfo;
