import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import debounce from "lodash/debounce";

import RaisedButton from "material-ui/RaisedButton";

import { listCities } from "../../../../graphql/query/administration";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import EditIcon from "../../../../components/UI/EditIcon";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class CityList extends Component {
  state = {
    listCity: [],
    pages: null,
    loading: true,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: city => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(city)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Ime",
        accessor: "name",
      },
      {
        Header: "Poštanski broj",
        accessor: "postal_code",
      },
      {
        Header: "Pozivni broj",
        accessor: "area_code",
      },
      {
        Header: "Regija",
        accessor: "region",
      },
      {
        Header: "Država",
        accessor: "country",
      },
    ],
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareFilters(filtered) {
    let filtersToSend = {};

    filtered.forEach(obj => {
      switch (obj.id) {
        case "region":
          filtersToSend = {
            ...filtersToSend,
            region: {
              name: obj.value,
            },
          };
          break;
        case "country":
          filtersToSend = {
            ...filtersToSend,
            region: {
              country: { name: obj.value },
            },
          };
          break;
        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  }

  fetchData = async state => {
    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      let filtersToSend;
      let whereCondition;

      if (state.filtered.length) {
        filtersToSend = this.prepareFilters(state.filtered);
      }

      if (filtersToSend) {
        whereCondition = {
          where: JSON.stringify(filtersToSend),
        };
      }

      const response = await this.props.client.query({
        query: listCities,
        variables: {
          input: {
            paginationLimit: {
              limit: pageSizeSelected,
              offset: pageSelected * pageSizeSelected,
            },
            ...whereCondition,
          },
        },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        this.setStateAfterFetch(response, state);
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, 500);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response, state) {
    if (response.data.listCity && response.data.listCity[0]) {
      // when we get normal reponse, ceil up page number
      this.setState({
        listCity: response.data.listCity,
        pages: Math.ceil(response.data.listCity[0].count / state.pageSize),
        loading: false,
      });
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        listCity: [],
        pages: null,
        loading: false,
      });
    }
  }

  dataToShow() {
    const { listCity } = this.state;

    return listCity && listCity.length
      ? listCity.map(city => {
          return {
            ...city,
            country: city.region && city.region.country ? city.region.country.name : "",
            region: city.region ? city.region.name : "",
          };
        })
      : [];
  }

  render() {
    const { toggleCreate } = this.props;

    return (
      <Fragment>
        <TableTitle title="Lista gradova" />
        <ReactTableCustom
          data={this.dataToShow()}
          columns={this.state.columns}
          ref={el => (this.componentRefInquiry = el)}
          renderCustomPagination={true}
          innerProps={{
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: this.state.loading,
            pages: this.state.pages,
            manual: true,
            sortable: false,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(withApollo(CityList));
