import React, { Component } from "react";
import { withApollo } from "react-apollo";

import { Paper } from "@material-ui/core";
import ReactTableCustom from "../../../components/UI/ReactTable";
import debounce from "lodash/debounce";
import { DEBOUNCE_FETCH_DELAY, DEFAULT_PAGE_SIZE } from "../../../utility/reactTable";

import { listReservation } from "../../../graphql/query/alotman";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

class AlotmanReservationsList extends Component {
  state = {
    pageSize: DEFAULT_PAGE_SIZE,
    pages: null,
    loading: true,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.establishment_id !== this.props.establishment_id) {
      const { establishment_id } = this.props;

      this.setState({ loading: true }, async () => {
        this.variables = {
          input: {
            paginationLimit: {
              limit: PAGINATION_LIMIT,
              offset: PAGINATION_OFFSET,
            },
            filter: {
              establishment_id,
              ...(String(this.props.active).length && { active: this.props.active }),
            },
          },
        };

        // Send where condition as variable to fetch data
        const response = await this.props.client.query({
          query: listReservation,
          variables: this.variables,
          fetchPolicy: "network-only",
        });

        if (this._isMounted) {
          this.setStateAfterFetch(response);
        }
      });
    }

    if (prevProps.active !== this.props.active) {
      this.fetchAfterActiveChanged();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareTableFilters(filtered) {
    let filtersToSend = {};

    return filtersToSend;
  }

  fetchAfterActiveChanged = async () => {
    try {
      this.setState({ loading: true });

      const filters = Object.keys(this.variables.input.filter).reduce((obj, key) => {
        if (key === "active") {
          return obj;
        }

        return { ...obj, ...(this.variables.input.filter[key] && { [key]: this.variables.input.filter[key] }) };
      }, {});

      this.variables = {
        ...this.variables,
        input: {
          ...this.variables.input,
          paginationLimit: {
            limit: this.state.pageSize,
            offset: PAGINATION_OFFSET,
          },
          filter: {
            ...filters,
            ...(String(this.props.active).length && { active: this.props.active }),
          },
        },
      };

      // Send where condition as variable to fetch data
      const response = await this.props.client.query({
        query: listReservation,
        variables: this.variables,
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        // set new state when ReactTable changes and query with new changes
        this.setState(
          {
            loading: false,
            // if filtering is comming from toolbar
            currentPage: PAGINATION_OFFSET,
          },
          this.setStateAfterFetch(response),
        );
      }
    } catch (error) {}
  };

  variables = {};

  fetchData = async state => {
    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      const { establishment_id } = this.props;

      this.variables = {
        input: {
          paginationLimit: {
            limit: pageSizeSelected,
            offset: pageSelected * pageSizeSelected,
          },
          filter: {
            establishment_id,
            ...state.filtered.reduce((obj, curr) => {
              if (curr.value === null) {
                return obj;
              }

              obj = {
                ...obj,
                [curr.id]: curr.value,
              };

              return obj;
            }, {}),
            ...(String(this.props.active).length && { active: this.props.active }),
          },
        },
      };

      // Send where condition as variable to fetch data
      const response = await this.props.client.query({
        query: listReservation,
        variables: this.variables,
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        // set new state when ReactTable changes and query with new changes
        this.setState(
          {
            loading: false,
            // if filtering is comming from react table
            pageSize: pageSizeSelected,
            currentPage: pageSelected,
          },
          this.setStateAfterFetch(response),
        );
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });

      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response) {
    if (response.data.listReservation && response.data.listReservation[0]) {
      const pageSize = (this.state && this.state.pageSize) || 10;

      // when we get normal reponse, ceil up page number
      this.setState({
        pages: Math.ceil(response.data.listReservation[0].count / pageSize),
        loading: false,
      });

      this.props.setReservations(response.data.listReservation);
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        pages: null,
        loading: false,
      });
      this.props.setReservations([]);
    }
  }

  render() {
    const { checkboxProps, columns, reservations } = this.props;

    return (
      <Paper elevation={1}>
        <ReactTableCustom
          data={reservations || []}
          columns={columns}
          renderCustomPagination={true}
          innerProps={{
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: this.state.loading,
            pages: this.state.pages,
            manual: true,
            sortable: false,
            ...checkboxProps,
          }}
        />
      </Paper>
    );
  }
}

export default withLocalStorageHOC(withApollo(AlotmanReservationsList));
