import React from "react";

// components
import AnnouncementOrdersToolbarView from "./Toolbar";
import AnnouncementOrderList from "./ListView";

/**
 * AnnouncementOrdersContainer
 */
export default function AnnouncementOrdersContainer() {
  return (
    <div>
      <AnnouncementOrdersToolbarView />
      <AnnouncementOrderList />
    </div>
  );
}
