import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import AttachmentIcon from "@material-ui/icons/Attachment";

const AttachmentItem = ({ file, onDelete }) => {
  return (
    <Chip
      avatar={
        <Avatar>
          <AttachmentIcon />
        </Avatar>
      }
      className="attachmentItem min-width-80"
      label={file.filename}
      target="_blank"
      clickable={true}
      onClick={event => window.open(file.path)}
      onDelete={onDelete}
    />
  );
};

export default AttachmentItem;
