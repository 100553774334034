// libs
import { useSelector } from "react-redux";

// redux state
import { selectSupplierPartners } from "src/views/Guides/guidesSlice";

/**
 * useSuppliers is entry point of data for Suppliers list
 */
export default function useSuppliers() {
  // redux state
  const supplierPartners = useSelector(selectSupplierPartners);

  /**
   * table supplierPartners show handling
   */
  const getSuppliersShowTrProps = (state, rowInfo, col, instance) => {
    return {
      style: {
        background: "#ffffff",
      },
    };
  };

  return {
    getSuppliersShowTrProps,
    supplierPartners,
  };
}
