import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";

import Paper from "material-ui/Paper";

import { setAllContractsInitialQueryInput } from "../../../store/actions";
import { allContracts } from "../../../graphql/query/contract";

import EditIcon from "../../../components/UI/EditIcon";
import ReactTableCustom from "../../../components/UI/ReactTable";

import { DEBOUNCE_FETCH_DELAY } from "../../../utility/reactTable";
import { getNestedObject } from "../../../utility/globals";
import { formatDate } from "../../../utility/dates";

import AllContractsToolbar from "./Toolbar";
import { /* CustomDateRangePicker,*/ DEFAULT_EMPTY_DATE } from "../../../components/UI/CustomDateRangePicker";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

const CONTRACT_STATUS_STORNO = 3;

const mutationMsg = gql`
  subscription {
    mutationMsg(section: Contract)
  }
`;

const getPreparedFilters = (filtersSelected, all_contracts_filter) => {
  // prepare filters sent from react table (or empty object if there are none)
  let filtersPrepared =
    filtersSelected && filtersSelected.length
      ? Object.assign(...filtersSelected.map(entry => ({ [entry.id]: entry.value })))
      : {};

  if (filtersPrepared.document_date_validity && filtersPrepared.document_date_validity.includes(DEFAULT_EMPTY_DATE)) {
    delete filtersPrepared.document_date_validity;
  }

  /* FILTERS */
  switch (all_contracts_filter) {
    case "0":
      // aktivno - default
      filtersPrepared = {
        ...filtersPrepared,
        document_pipeline: "true",
      };
      break;
    case "1":
      // arhivirano
      filtersPrepared = {
        ...filtersPrepared,
        document_pipeline: "false",
      };
      break;
    case "2":
      //storno
      filtersPrepared = {
        ...filtersPrepared,
        contract_status_id: CONTRACT_STATUS_STORNO,
      };
      break;
    default:
      break;
  }

  let filtersToSend = {};

  // structure object as necessary
  for (let key in filtersPrepared) {
    switch (key) {
      case "status":
        filtersToSend = {
          ...filtersToSend,
          contract_status: { desc: filtersPrepared[key] },
        };
        break;
      case "user":
        filtersToSend = {
          ...filtersToSend,
          last_edit_by_user: { username: filtersPrepared[key] },
        };
        break;
      case "type":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            offer_contract_document_type: { desc: filtersPrepared[key] },
          },
        };
        break;
      case "partner_client":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            partner_client: { name: filtersPrepared[key] },
          },
        };
        break;
      //case "inquiry_id":
      case "document_date_validity":
        if (filtersPrepared[key].includes(DEFAULT_EMPTY_DATE)) {
          break;
        }
      // falls through (ESlint actually needs this comment too)
      case "document_code":
      case "inquiry_external_id":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            [key]: filtersPrepared[key],
          },
        };
        break;
      default:
        // default case, when key-value pair is added to outermost object
        filtersToSend = {
          ...filtersToSend,
          [key]: filtersPrepared[key],
        };
    }
  }

  return JSON.stringify(filtersToSend);
};

class AllContracts extends React.Component {
  state = {
    filtersPrepared: {},
    pages: null,
    loading: true,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        Cell: contract => (
          <Link className="ReactTableFirstColumnDiv" to={`/ugovor/edit/${contract.original.id}`}>
            <EditIcon />
          </Link>
        ),
      },
      {
        Header: "Upit",
        width: 70,
        //accessor: "inquiry_id"
        accessor: "inquiry_external_id",
      },
      {
        Header: "Ugovor",
        width: 70,
        accessor: "external_id",
      },
      {
        Header: "Agencija",
        accessor: "partner_client",
      },
      {
        Header: "Grupa",
        accessor: "document_code",
      },
      {
        Header: "Vrsta",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      // {
      //   Header: "Datum opcije ponude",
      //   accessor: "document_date_validity",
      //   width: 203,
      //   Filter: ({ filter, onChange }) => <CustomDateRangePicker filter={filter} onChange={onChange} />,
      // },
      {
        Header: "Zadnje ažurirao",
        accessor: "user",
      },
    ],
  };

  fetchMoreObject = {};
  _isMounted = false;

  subscribe = () => {
    if (this._isMounted && this.props.data) {
      this.props.data.subscribeToMore({
        document: mutationMsg,
        updateQuery: async (prev, { subscriptionData }) => {
          if (!subscriptionData) {
            return prev;
          }

          if (this._isMounted) {
            let response;

            // If we have changed filtering or pages ( this.fetchMoreObject is changed in fetchData)
            if (Object.keys(this.fetchMoreObject).length) {
              response = await this.props.data.fetchMore(this.fetchMoreObject);
            } else {
              response = await this.props.data.refetch();
            } // refetch default query
            return {
              ...prev,
              listContract: [...response.data.listContract],
            };
          }
          return prev;
        },
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;

    this.unsubscribe = this.subscribe();

    // set initial query params so our graphql HOC function does not get called every time on filtering
    const limit = this.props.tableState.pageSizeSelected;
    const offset = this.props.tableState.pageSelected * this.props.tableState.pageSizeSelected;

    this.props.setAllContractsInitialQueryInput(
      limit,
      offset,
      this.props.tableState.filtersSelected,
      this.props.tableState.allContractsFilter || "0",
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  // called when Aktivno/Arhivirano/Storno select changes
  selectDataToShow = value => {
    this.props.setTableState({ ...this.props.tableState, pageSelected: 0, all_contracts_filter: value });
    this.setState({ loading: true }, this.applyFiltersAndFetch);
  };

  // react table's function on a change
  fetchData = async state => {
    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    // set new state when ReactTable changes and query with new changes
    this.setState(
      {
        loading: true,
      },
      this.applyFiltersAndFetch,
    );
  };

  filtering = false;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  // our custom function, called whenever react table changes or any of filters not directly in react table
  applyFiltersAndFetch = async () => {
    const { pageSelected, pageSizeSelected } = this.props.tableState;

    const all_contracts_filter = this.props.tableState.all_contracts_filter || "0";

    /* end of filters */
    // Setting new object in fetchMore, so that we could reuse it in subscription
    this.fetchMoreObject = {
      variables: {
        input: {
          paginationLimit: {
            limit: pageSizeSelected,
            offset: pageSelected * pageSizeSelected,
          },
          where: getPreparedFilters(this.props.tableState.filtersSelected, all_contracts_filter),
        },
        alotman: false,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (this._isMounted) {
          if (fetchMoreResult && fetchMoreResult.listContract && fetchMoreResult.listContract.length) {
            // when we get normal reponse, ceil up page number
            this.setState({
              pages: Math.ceil(fetchMoreResult.listContract[0].count / pageSizeSelected),
              loading: false,
            });
          }
          // when query returns empty array (no results for those filters)
          else {
            this.setState({
              pages: null,
              loading: false,
            });
          }

          return {
            previousResult,
            listContract: [...fetchMoreResult.listContract],
          };
        }

        return previousResult;
      },
    };

    if (this._isMounted && this.props.data) {
      await this.props.data.fetchMore(this.fetchMoreObject);
    }
  };

  dataToShow = () => {
    if (this.props.data) {
      const { listContract } = this.props.data;

      return (listContract && listContract.map(contract => this.formatContractForReactTable(contract))) || [];
    }
  };

  formatContractForReactTable(contract) {
    return {
      ...contract,
      //inquiry_id: contract.offer_contract_document.inquiry_id,
      inquiry_external_id: contract.offer_contract_document.inquiry_external_id,
      partner_client: contract.offer_contract_document.partner_client.name,
      document_code: contract.offer_contract_document.document_code,
      type: contract.offer_contract_document.offer_contract_document_type.desc,
      status: contract.contract_status.desc,
      document_date_validity: formatDate(contract.offer_contract_document.document_date_validity),
      user:
        getNestedObject(contract, ["last_edit_by_user", "username"]) +
        ", " +
        formatDate(getNestedObject(contract, ["last_edit_by_user_time"])),
    };
  }

  render() {
    return (
      <React.Fragment>
        <AllContractsToolbar
          {...this.state}
          checked={this.props.tableState.all_contracts_filter}
          selectDataToShow={this.selectDataToShow}
          contentToPrint={() => this.componentRef}
        />
        <Paper zDepth={1}>
          <ReactTableCustom
            data={this.dataToShow()}
            columns={this.state.columns}
            ref={el => (this.componentRef = el)}
            renderCustomPagination={true}
            innerProps={{
              onFetchData: this.fetchStrategy,
              onFilteredChange: this.onFilteredChange,

              onPageChange: page => {
                this.props.setTableState({ ...this.props.tableState, pageSelected: page });
              },

              onPageSizeChange: (pageSize, pageIndex) => {
                this.props.setTableState({
                  ...this.props.tableState,
                  pageSelected: pageIndex,
                  pageSizeSelected: pageSize,
                });
              },

              page: this.props.tableState.pageSelected,
              pageSize: this.props.tableState.pageSizeSelected,
              defaultFiltered: this.props.tableState.filtersSelected,

              loading: this.state.loading,
              pages: this.state.pages,
              manual: true,
              sortable: false,
            }}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { limit, offset, selectedFilters, allContractsFilter } = state.contract;

  return {
    limit,
    offset,
    selectedFilters,
    allContractsFilter,
  };
};

export default withLocalStorageHOC(
  connect(mapStateToProps, { setAllContractsInitialQueryInput })(
    compose(
      graphql(allContracts, {
        skip: ({ limit, offset }) => {
          return !limit && !offset;
        },
        options: ({ limit, offset, selectedFilters, allContractsFilter }) => ({
          fetchPolicy: "network-only",
          variables: {
            // This is default query on component mount
            input: {
              paginationLimit: {
                limit: limit,
                offset: offset,
              },
              where: getPreparedFilters(selectedFilters, allContractsFilter),
            },
            alotman: false,
          },
        }),
      }),
    )(AllContracts),
  ),
);
