// libs
import { useSelector } from "react-redux";

// redux state
import { selectLanguages } from "src/views/Administration/TemplateAnnouncement/templateAnnouncementSlice";

/**
 * useLanguageColumn is entry point of data for languageColumn component
 */
export default function useLanguageColumn(languageId) {
  // redux state
  const listLanguage = useSelector(selectLanguages);

  const language = listLanguage.find(language => language.id === languageId);

  return { language };
}
