import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useGuideLocalAnnouncement from "./hooks/useGuideLocalAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import HotelDetailsList from "../HotelDetailsList";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * GuideLocalAnnouncement
 */
const GuideLocalAnnouncement = ({ classes }) => {
  // hooks
  const {
    guideLocalAnnouncement,
    handleOnUpdateGuideLocalAnnouncement,
    handleOnUpdateHotelDetails,
  } = useGuideLocalAnnouncement();

  const title = get(guideLocalAnnouncement, "announcement_title.value", "");
  const intro = get(guideLocalAnnouncement, "intro_message.placeholder", "");
  const footer = get(guideLocalAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* group */}
      <AnnouncementTextField announcementField={get(guideLocalAnnouncement, "group")} keyOfValue={"group"} />

      {/* date */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "date")}
        keyOfValue={"date"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* hotel table list */}
      <HotelDetailsList
        label={`${get(guideLocalAnnouncement, "hotel_label.value", "")} : `}
        hotelList={get(guideLocalAnnouncement, "hotel", "")}
        handleOnUpdateHotelDetails={handleOnUpdateHotelDetails}
      />

      {/* number of persons */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "nr_of_persons")}
        keyOfValue={"nr_of_persons"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* group treffpunkt */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "group_treffpunkt")}
        keyOfValue={"group_treffpunkt"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* bus driver contact */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "bus_driver_contact")}
        keyOfValue={"bus_driver_contact"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* service favour */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "service_favor")}
        keyOfValue={"service_favor"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* payment */}
      <AnnouncementTextField
        announcementField={get(guideLocalAnnouncement, "payment")}
        keyOfValue={"payment"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideLocalAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(GuideLocalAnnouncement);
