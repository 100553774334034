import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import Button from "@material-ui/core/Button";
import BootstrapInput from "../../../../components/UI/BootstrapLikeInput";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { listServiceType, listLanguage } from "../../../../graphql/query/administration";

import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import FormValidator from "../../../../components/UI/FormValidator";
import { findServiceTypeByCroLang } from "../../../../utility/services";

const form = {
  padding: "24px",
};

class ServiceForm extends Component {
  static schema = {
    properties: {
      service_type_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati tip aktivnosti",
      },
      service_translations: {
        type: "array",
        minItems: 1,
        errorMessage: "Potreban je barem jedan prijevod",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi jezici moraju imati naziv",
            },
          },
        },
      },
    },
  };
  render() {
    const {
      service,
      data: { loading, listServiceType },
      fetchLanguages: { listLanguage },
      onChangeField,
      changeTranslationField,
      onSubmit,
    } = this.props;
    if (loading) {
      return <div>Loading tipove usluga . . . </div>;
    }

    if (!listLanguage) {
      return "Dohvaćam jezike";
    }
    return (
      <FormValidator schema={ServiceForm.schema} submit={onSubmit} data={service}>
        {({ error, errorMsg, submit }) => (
          <div className="service-form" style={form}>
            <div className="form-control-grid">
              <AutocompleteSelect
                label="Tip usluge"
                error={error("/service_type_id")}
                errorMsg={errorMsg("/service_type_id")}
                defaultValue={service.service_type_id ? service.service_type_id : ""}
                autocompleteHandler={value => onChangeField({ key: "service_type_id", value })}
                dataSource={
                  listServiceType &&
                  listServiceType.map(serviceType => {
                    const st = findServiceTypeByCroLang(serviceType);

                    return {
                      value: serviceType.id,
                      label: (st && st.name) || "",
                    };
                  })
                }
                placeholder="Odabir role"
              />
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Jezik</TableCell>
                  <TableCell>Naziv</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listLanguage.map((language, index) => {
                  const translation = service.service_translations.find(
                    translation => translation.language_id === language.id,
                  );

                  if (!translation) {
                    return null;
                  }

                  const name = translation.name;

                  return (
                    <TableRow key={language.id} className="align-top">
                      <TableCell component="th" scope="row">
                        {language.desc}
                      </TableCell>
                      <TableCell>
                        <BootstrapInput
                          label=""
                          id="name"
                          error={error(`/service_translations/${index}/name`)}
                          errorMsg={errorMsg(`/service_translations/${index}/name`)}
                          value={name}
                          onChange={changeTranslationField(language.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <br />
            <Button variant="contained" onClick={submit}>
              Spremi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" onClick={this.props.cancelToggle}>
              Poništi
            </Button>
          </div>
        )}
      </FormValidator>
    );
  }
}

export default compose(
  graphql(listServiceType),
  graphql(listLanguage, {
    name: "fetchLanguages",
  }),
)(ServiceForm);
