import React from "react";

import Subheader from "material-ui/Subheader";
import Divider from "material-ui/Divider";

const DividerUI = props => (
  <div>
    <Subheader
      style={{
        color: "rgb(0, 188, 212)"
      }}
    >
      {props.subheaderText}
    </Subheader>
    <Divider
      style={{
        backgroundColor: "rgb(0, 188, 212)"
      }}
    />
  </div>
);

export default DividerUI;
