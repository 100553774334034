import { FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import Popover from "react-awesome-popover";
import { withRouter } from "react-router-dom";

class UserForm extends React.Component {
  render() {
    const { name, onChangeData, onSubmit, error, errorMsg, deleteTypologyGroup } = this.props;

    return (
      <form className="user-form" onSubmit={onSubmit}>
        <div className="form-control-grid">
          <FormControl fullWidth error={error("/name")}>
            <InputLabel shrink={true} htmlFor="name">
              Ime
            </InputLabel>
            <Input id="name" name="name" value={name || ""} onChange={onChangeData("name")} />
            {error("/name") && <FormHelperText>{errorMsg("/name")}</FormHelperText>}
          </FormControl>
        </div>
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Spremi
        </Button>
        &nbsp;&nbsp;&nbsp;
        {deleteTypologyGroup && (
          <Popover action="click" placement="bottom" contentClass="rap-popover-content">
            <Button variant="contained" color="secondary">
              Izbriši
            </Button>
            <div className="pad">
              <Button variant="contained" classes={{ root: "is-success" }} onClick={deleteTypologyGroup}>
                Potvrdi
              </Button>
            </div>
          </Popover>
        )}
        &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          onClick={e => {
            e.preventDefault();
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/grupa_tipologije/");
            } else {
              this.props.history.goBack();
            }
          }}
        >
          Poništi
        </Button>
      </form>
    );
  }
}

export default withRouter(UserForm);
