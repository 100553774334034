import React from "react";

// ui
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { grey900 } from "material-ui/styles/colors";

// hooks
import usePartnerSearchContainer from "./hooks/usePartnerSearchContainer";
// constants
import { LABEL_GUIDE_PARTNER_SEARCH } from "src/utility/labels";
// components
import ReactTableCustom from "src/components/UI/ReactTable";
import partnerColumns from "src/views/Guides/Container/components/Drawers/components/TripsActivities/components/ProgramDays/components/PartnerSearch/components/Columns/PartnerColumns";
import GuideSub from "./components/GuideSub";

// style
import styled from "styled-components";

const PartnerTable = styled.div`
  .ReactTable .rt-tr:hover .rt-td {
    cursor: pointer;
  }
`;
export default function PartnerSearchContainer() {
  // hooks
  const {
    expanded,
    listPartnerToShow,
    loading,
    fetchStrategy,
    getPartnerTdProps,
    getPartnerTrProps,
    onFilteredChange,
    pages,
  } = usePartnerSearchContainer();

  // return root
  return (
    <div>
      <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
        <ToolbarGroup>
          <ToolbarTitle
            text={LABEL_GUIDE_PARTNER_SEARCH}
            style={{
              color: grey900,
            }}
          />
        </ToolbarGroup>
      </Toolbar>
      <PartnerTable>
        <ReactTableCustom
          data={listPartnerToShow()}
          columns={partnerColumns}
          innerProps={{
            onFetchData: fetchStrategy,
            onFilteredChange: onFilteredChange,
            loading: loading,
            pages: pages,
            manual: true,
            sortable: false,
            expanded: expanded,
            getTdProps: getPartnerTdProps,
            getTrProps: getPartnerTrProps,
            SubComponent: row => {
              return <GuideSub row={row} />;
            },
          }}
        />
      </PartnerTable>
    </div>
  );
}
