import React from "react";
import _ from "lodash";
import { formatDate } from "../../../utility/dates";
import { BookingModalsContext } from "./BookingModalsHandler";
import { AllBookingsContext } from "./AllBookings";
import Add from "@material-ui/icons/AddCircleOutline";
import Subtract from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "./../../../components/UI/Tooltip";

export const contractIDCell = ({ booking_external_id }) => (
  <Tooltip title={booking_external_id || " "}>
    <div className="ReactTableFirstColumnDiv">{booking_external_id || ""}</div>
  </Tooltip>
);

export const contactedCell = ({ id, contacted }) => (
  <BookingModalsContext.Consumer>
    {({ changeContacted }) => (
      <Tooltip title={contacted || " "}>
        <div className="ReactTableFirstColumnDiv">
          <Subtract onClick={() => (contacted > 0 ? changeContacted(id, contacted - 1) : null)} />
          <span>{contacted}</span>
          <Add onClick={() => changeContacted(id, contacted + 1)} />
        </div>
      </Tooltip>
    )}
  </BookingModalsContext.Consumer>
);

export const statusCell = ({ booking_status }) => (
  <Tooltip title={booking_status || " "}>
    <div className="ReactTableFirstColumnDiv">{booking_status || ""}</div>
  </Tooltip>
);

export const segmentCell = ({ booking_id, offer_contract_document_segment_id }) => (
  <AllBookingsContext.Consumer>
    {({ listOfferContractDocumentSegment }) => (
      <BookingModalsContext.Consumer>
        {({ changeContractSegment }) => {
          const segment =
            listOfferContractDocumentSegment &&
            listOfferContractDocumentSegment.find(seg => seg.id === offer_contract_document_segment_id);

          return (
            <Tooltip
              title={
                Boolean(offer_contract_document_segment_id)
                  ? listOfferContractDocumentSegment[offer_contract_document_segment_id - 1].desc
                  : " "
              }
            >
              <div className="ReactTableFirstColumnDiv">
                <select
                  className="booking-select "
                  onChange={({ target: { value } }) => changeContractSegment(booking_id, value)}
                  value={offer_contract_document_segment_id}
                >
                  <option value="" />
                  {listOfferContractDocumentSegment &&
                    listOfferContractDocumentSegment.map(type => (
                      <option key={`key-${type.id}`} value={type.id}>
                        {type.desc}
                      </option>
                    ))}
                </select>

                <span className="select-print" style={{ display: "none" }}>
                  {segment ? segment.desc : ""}
                </span>
              </div>
            </Tooltip>
          );
        }}
      </BookingModalsContext.Consumer>
    )}
  </AllBookingsContext.Consumer>
);

export const agencyCell = ({ agency }) => (
  <Tooltip title={agency || " "}>
    <div className="ReactTableFirstColumnDiv">{agency || ""}</div>
  </Tooltip>
);

export const inquiryCell = ({ inquiry_external_id }) => (
  <Tooltip title={inquiry_external_id || " "}>
    <div className="ReactTableFirstColumnDiv">{inquiry_external_id || ""}</div>
  </Tooltip>
);

export const offKeyCell = ({ off_key }) => (
  <Tooltip title={off_key || " "}>
    <div className="ReactTableFirstColumnDiv">{off_key || ""}</div>
  </Tooltip>
);

export const code1Cell = ({ code1 }) => (
  <Tooltip title={code1 || " "}>
    <div className="ReactTableFirstColumnDiv">{code1 || ""}</div>
  </Tooltip>
);

export const documentCodeCell = ({ document_code }) => (
  <Tooltip title={document_code || " "}>
    <div className="ReactTableFirstColumnDiv">{document_code || ""}</div>
  </Tooltip>
);

export const establishmentChainCell = ({ establishment_chain }) => (
  <Tooltip title={establishment_chain || " "}>
    <div className="ReactTableFirstColumnDiv">{establishment_chain || ""}</div>
  </Tooltip>
);

export const establishmentCell = ({ establishment }) => (
  <Tooltip title={establishment || " "}>
    <div className="ReactTableFirstColumnDiv">{establishment || ""}</div>
  </Tooltip>
);

export const cityCell = ({ city }) => (
  <Tooltip title={city || " "}>
    <div className="ReactTableFirstColumnDiv">{city || ""}</div>
  </Tooltip>
);

export const contigentsListCell = ({ contigents_list }) => (
  <Tooltip title={contigents_list || " "}>
    <div className="ReactTableFirstColumnDiv">{contigents_list || ""}</div>
  </Tooltip>
);

export const guideDriverCommentCell = ({ driver_guide_comment }) => (
  <Tooltip title={driver_guide_comment || " "}>
    <div className="ReactTableFirstColumnDiv">{driver_guide_comment || ""}</div>
  </Tooltip>
);

export const personsCapacitySoldCell = ({ persons_capacity_sold }) => (
  <Tooltip title={persons_capacity_sold || " "}>
    <div className="ReactTableFirstColumnDiv">{persons_capacity_sold || ""}</div>
  </Tooltip>
);

export const deliveredStateCell = ({ delivered_state }) => (
  <Tooltip title={delivered_state || " "}>
    <div className="ReactTableFirstColumnDiv">{delivered_state || ""}</div>
  </Tooltip>
);

export const remarkCell = ({ remark }) => (
  <Tooltip title={remark || " "}>
    <div className="ReactTableFirstColumnDiv">{remark || ""}</div>
  </Tooltip>
);

export const notificationBookingStateDateCell = ({ notification_booking_state_date }) => (
  <Tooltip title={notification_booking_state_date ? formatDate(notification_booking_state_date) : " "}>
    <div className="ReactTableFirstColumnDiv">
      {notification_booking_state_date ? formatDate(notification_booking_state_date) : ""}
    </div>
  </Tooltip>
);

export const bookingOptionDescriptionIDCell = ({ id, booking_option_description_id }) => (
  <AllBookingsContext.Consumer>
    {({ listBookingOptionDescription }) => (
      <BookingModalsContext.Consumer>
        {({ changeBookingOptionDescription }) => {
          const option =
            listBookingOptionDescription &&
            listBookingOptionDescription.find(op => op.id === booking_option_description_id);

          return (
            <Tooltip
              title={
                Boolean(booking_option_description_id)
                  ? listBookingOptionDescription[booking_option_description_id - 1].desc
                  : " "
              }
            >
              <div className="ReactTableFirstColumnDiv">
                <select
                  className="booking-select "
                  onChange={({ target: { value } }) => changeBookingOptionDescription(id, value)}
                  value={booking_option_description_id || ""}
                >
                  <option value="" />
                  {listBookingOptionDescription &&
                    listBookingOptionDescription.map(type => (
                      <option key={`key-${type.id}`} value={type.id}>
                        {type.desc}
                      </option>
                    ))}
                </select>
                <span className="select-print" style={{ display: "none" }}>
                  {option ? option.desc : ""}
                </span>
              </div>
            </Tooltip>
          );
        }}
      </BookingModalsContext.Consumer>
    )}
  </AllBookingsContext.Consumer>
);

export const receiptCell = ({ receipt }) => (
  <Tooltip title={receipt || " "}>
    <div className="ReactTableFirstColumnDiv">{receipt || ""}</div>
  </Tooltip>
);

export const paxCell = ({ pax }) => (
  <Tooltip title={!_.isNil(pax) ? pax : " "}>
    <div className="ReactTableFirstColumnDiv">{!_.isNil(pax) ? pax : ""}</div>
  </Tooltip>
);

export const transportCell = ({ transport }) => {
  let toShow = "";
  if (transport === true) {
    toShow = "Da";
  } else if (transport === false) {
    toShow = "Ne";
  }

  return (
    <Tooltip title={toShow}>
      <div className="ReactTableFirstColumnDiv">{toShow}</div>
    </Tooltip>
  );
};

/*export const userCell = ({ user }) => (
  <Tooltip title={user || " "}>
    <div className="ReactTableFirstColumnDiv">{user || ""}</div>
  </Tooltip>
);*/

export const driverCell = ({ driver }) => {
  let toShow = "";
  if (driver === true) {
    toShow = "Da";
  } else if (driver === false) {
    toShow = "Ne";
  }
  return (
    <Tooltip title={toShow}>
      <div className="ReactTableFirstColumnDiv">{toShow}</div>
    </Tooltip>
  );
};

export const guideCell = ({ guide }) => {
  let toShow = "";

  if (guide === true) {
    toShow = "Da";
  } else if (guide === false) {
    toShow = "Ne";
  }

  return (
    <Tooltip title={toShow}>
      <div className="ReactTableFirstColumnDiv">{toShow}</div>
    </Tooltip>
  );
};

export const dateCell = ({ from, to }, isFromDate = true) => (
  <Tooltip title={formatDate(isFromDate ? from : to) || " "}>
    <div className="ReactTableFirstColumnDiv">{formatDate(isFromDate ? from : to) || ""}</div>
  </Tooltip>
);

export const numberOfBussesCell = ({ nr_of_buses }) => (
  <Tooltip title={!_.isNil(nr_of_buses) ? `Broj buseva: ${nr_of_buses}` : " "}>
    <div className="ReactTableFirstColumnDiv">{!_.isNil(nr_of_buses) ? nr_of_buses : ""}</div>
  </Tooltip>
);
