import React from "react";
// hooks
import useTemplateAnnouncementList from "../../hooks/useTemplateAnnoncementList";
// components
import ReactTableCustom from "src/components/UI/ReactTable";
import announcementTemplateColumns from "../columns/TemplateAnnouncementColumns";
import TableTitle from "src/components/UI/TableTitle";

export default function TemplateAnnouncementList() {
  const { templateAnnouncements, loading, tableState, setTableState } = useTemplateAnnouncementList();

  return (
    <div className="form">
      <TableTitle title="Lista predloška najave" />

      <ReactTableCustom
        data={templateAnnouncements}
        columns={announcementTemplateColumns}
        renderCustomPagination={true}
        innerProps={{
          loading: loading,
          filterable: false,
          manual: true,
          sortable: false,

          onFilteredChange: filters => {
            setTableState({ ...tableState, pageSelected: 0, filtersSelected: filters });
          },

          onPageChange: page => {
            setTableState({ ...tableState, pageSelected: page });
          },

          onPageSizeChange: (pageSize, pageIndex) => {
            setTableState({
              ...tableState,
              pageSelected: pageIndex,
              pageSizeSelected: pageSize,
            });
          },

          page: tableState.pageSelected,
          pageSize: tableState.pageSizeSelected,
          defaultFiltered: tableState.filtersSelected,
        }}
      />
    </div>
  );
}
