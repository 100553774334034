import { useEffect } from "react";

// libs
import { useQuery } from "react-apollo";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// context
import { selectAnnouncementVouchers, setAnnouncementVouchers, setVoucherSelected } from "../voucherSlice";

// graphql
import { FETCH_VOUCHER_LIST } from "src/graphql/query/voucher";

/**
 * useVoucherList is entry point of data for voucherList component
 */
export default function useVoucherList(announcementSelected) {
  // redux state
  const announcementVouchers = useSelector(selectAnnouncementVouchers);

  const dispatch = useDispatch();

  /**
   * fetch voucher list
   */
  const { data, loading } = useQuery(FETCH_VOUCHER_LIST, {
    variables: {
      announcement_id: get(announcementSelected, "id"),
    },
    skip: !get(announcementSelected, "id"),
  });

  useEffect(() => {
    if (data) {
      dispatch(setAnnouncementVouchers(get(data, "listAllVouchers", [])));
    }
  }, [data, dispatch]);

  /**
   * handle on voucher click
   */
  const handleOnVoucherClick = voucher => {
    dispatch(setVoucherSelected({ isVoucherViewerOpened: true, voucherSelected: voucher }));
  };

  return {
    announcementVouchers,
    handleOnVoucherClick,
    loading,
  };
}
