/**
 * Offer
 */

export const ALL_OFFERS_INITIAL_QUERY = "ALL_OFFERS_INITIAL_QUERY";

export const RESET_STORE = "RESET_STORE";
export const SETUP_DRAFT_OFFER_ASYNC = "SETUP_DRAFT_OFFER_ASYNC";
export const SETUP_INITIAL_DRAFT_OFFER = "SETUP_INITIAL_DRAFT_OFFER";
export const CHANGE_OFFER_PARAMETER = "CHANGE_OFFER_PARAMETER";
export const CHANGE_OFFER_CONTRACT_DOCUMENT_PARAMETER = "CHANGE_OFFER_CONTRACT_DOCUMENT_PARAMETER";

export const ADD_TERMIN = "ADD_TERMIN";
export const ADD_ALOTMAN_TERMIN = "ADD_ALOTMAN_TERMIN";
export const SAVE_EDITED_TERMIN = "SAVE_EDITED_TERMIN";
export const SET_ADDED_TERMIN = "SET_ADDED_TERMIN";
export const SET_ADDED_ALOTMAN_TERMIN = "SET_ADDED_ALOTMAN_TERMIN";

export const RESET_PROGRAM_DAYS = "RESET_PROGRAM_DAYS";
export const CREATE_PROGRAM_DAYS = "CREATE_PROGRAM_DAYS";
export const SET_PROGRAM_TO_SEND = "SET_PROGRAM_TO_SEND";
export const SET_PROGRAM_DAYS = "SET_PROGRAM_DAYS";
export const CHANGE_PROGRAM_DAY_CONTENT = "CHANGE_PROGRAM_DAY_CONTENT";
export const CHANGE_PROGRAM_DAY_DESCRIPTION = "CHANGE_PROGRAM_DAY_DESCRIPTION";
export const CHANGE_PROGRAM_DAY_TITLE = "CHANGE_PROGRAM_DAY_TITLE";
export const SAVE_CREATED_PROGRAM = "SAVE_CREATED_PROGRAM";
export const COPY_UPDATED_PROGRAM_DAYS = "COPY_UPDATED_PROGRAM_DAYS";

// services object to create
export const ADD_TO_CREATE_SERVICE_TYPE = "ADD_TO_CREATE_SERVICE_TYPE";
export const UPDATE_TO_CREATE_SERVICE_TYPE = "UPDATE_TO_CREATE_SERVICE_TYPE";
export const REMOVE_TO_CREATE_SERVICE_TYPE = "REMOVE_TO_CREATE_SERVICE_TYPE";
export const REMOVE_CREATE_SERVICE_TYPE = "REMOVE_CREATE_SERVICE_TYPE";

// documentServices
export const ADD_CREATED_SERVICE_TYPE = "ADD_CREATED_SERVICE_TYPE";
export const UPDATE_CREATED_SERVICE_TYPE = "UPDATE_CREATED_SERVICE_TYPE";
export const REMOVE_CREATED_SERVICE_TYPE = "REMOVE_CREATED_SERVICE_TYPE";

export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";

export const REMOVE_TERMIN = "REMOVE_TERMIN";
export const REMOVE_PROGRAM_DAYS = "REMOVE_PROGRAM_DAYS";

export const ADD_CONTIGENT = "ADD_CONTIGENT";
export const ADD_ALOTMAN_CONTIGENT = "ADD_ALOTMAN_CONTIGENT";
export const SAVE_EDITED_CONTIGENT_TYPOLOGY_SUM = "SAVE_EDITED_CONTIGENT_TYPOLOGY_SUM";
export const REMOVE_CONTIGENT = "REMOVE_CONTIGENT";

export const CHANGE_SUBTERMIN_FIELD = "CHANGE_SUBTERMIN_FIELD";

export const REMOVE_SUBTERMIN = "REMOVE_SUBTERMIN";
export const ADD_SUBTERMIN_CONTIGENT = "ADD_SUBTERMIN_CONTIGENT";
export const REMOVE_SUBTERMIN_CONTIGENT = "REMOVE_SUBTERMIN_CONTIGENT";

export const SAVE_NEW_SUBTERMIN = "SAVE_NEW_SUBTERMIN";
export const ADD_SUBTERMIN_PERIOD = "ADD_SUBTERMIN_PERIOD";
export const REMOVE_SUBTERMIN_PERIOD = "REMOVE_SUBTERMIN_PERIOD";

export const COPY_SUBTERMIN_TO_EDIT = "COPY_SUBTERMIN_TO_EDIT";
export const SAVE_EDITED_SUBTERMIN = "SAVE_EDITED_SUBTERMIN";

export const TOGGLE_CREATE_SUBTERMIN = "TOGGLE_CREATE_SUBTERMIN";
export const TOGGLE_EDIT_SUBTERMIN = "TOGGLE_EDIT_SUBTERMIN";

export const CANCEL_CREATE_SUBTERMIN = "CANCEL_CREATE_SUBTERMIN";
export const CANCEL_EDIT_SUBTERMIN = "CANCEL_EDIT_SUBTERMIN";

export const CHANGE_EDIT_DATA = "CHANGE_EDIT_DATA";
export const CHANGE_PRICE_STRUCTURE = "CHANGE_PRICE_STRUCTURE";
export const CHANGE_PRICE_STRUCTURE_PRICE = "CHANGE_PRICE_STRUCTURE_PRICE";
export const RESET_PRICE_STRUCTURE_CHANGES = "RESET_PRICE_STRUCTURE_CHANGES";

export const PICK_ESTABLISHMENT = "PICK_ESTABLISHMENT";
export const PICK_SUBTERMIN_ESTABLISHMENT = "PICK_SUBTERMIN_ESTABLISHMENT";

export const SUBMIT_CREATED_OFFER = "SUBMIT_CREATED_OFFER";

export const FETCHED_OFFER_TO_EDIT = "FETCHED_OFFER_TO_EDIT";
export const COPY_UPDATED_OFFER = "COPY_UPDATED_OFFER";
export const CHANGE_OFFER_COPY_FIELDS = "CHANGE_OFFER_COPY_FIELDS";

export const CHANGE_TEMPLATE_LANGUAGE = "CHANGE_TEMPLATE_LANGUAGE";

export const ADD_ADDITIONAL_SERVICE = "ADD_ADDITIONAL_SERVICE";
export const CHANGE_ADDITIONAL_SERVICE = "CHANGE_ADDITIONAL_SERVICE";
export const DELETE_ADDITIONAL_SERVICE = "DELETE_ADDITIONAL_SERVICE";

/**
 * CONTRACTS
 */
export const ALL_CONTRACTS_INITIAL_QUERY = "ALL_CONTRACTS_INITIAL_QUERY";

/**
 * BOOKING
 */
export const ALL_BOOKINGS_INITIAL_QUERY = "ALL_BOOKINGS_INITIAL_QUERY";

/**
 * ADMINISTRATION
 */

export const CHANGE_ESTABLISHMENT_DATA = "CHANGE_ESTABLISHMENT_DATA";
export const CHANGE_ESTABLISHMENT_CHAIN_DATA = "CHANGE_ESTABLISHMENT_CHAIN_DATA";

export const CHANGE_USER_ADMINISTRATION_DATA = "CHANGE_USER_ADMINISTRATION_DATA";

export const CHANGE_SERVICE_DATA = "CHANGE_SERVICE_DATA";
export const CHANGE_SERVICE_TYPE_DATA = "CHANGE_SERVICE_TYPE_DATA";

export const CHANGE_TYPOLOGY_DATA = "CHANGE_TYPOLOGY_DATA";
export const EDIT_TYPOLOGY_DATA = "EDIT_TYPOLOGY_DATA";
export const REMOVE_TYPOLOGY_FROM_ESTABLISHMENT = "REMOVE_TYPOLOGY_FROM_ESTABLISHMENT";
export const ADD_TYPOLOGY = "ADD_TYPOLOGY";
export const SAVE_EDITED_TYPOLOGY_DATA = "SAVE_EDITED_TYPOLOGY_DATA";
export const CHANGE_CREATING_TYPOLOGY_TRANSLATION_FIELD = "CHANGE_CREATING_TYPOLOGY_TRANSLATION_FIELD";

export const CHANGE_ACTIVITY_TYPE_DATA = "CHANGE_ACTIVITY_TYPE_DATA";
export const CHANGE_ACTIVITY_DATA = "CHANGE_ACTIVITY_DATA";

export const CHANGE_PARTNER_CLIENT_DATA = "CHANGE_TRANSPORT_DATA";
export const ADD_PARTNER_CLIENT_CONTACT_DATA = "ADD_PARTNER_CLIENT_CONTACT_DATA";

export const RESET_ADMINISTRATION_DATA = "RESET_ADMINISTRATION_DATA";
export const FETCHED_ADMINISTRATION_DATA_TO_EDIT = "FETCHED_ADMINISTRATION_DATA_TO_EDIT";

/**
 * ALOTMAN
 */
export const ALOTMAN_CONTRACTS_INITIAL_QUERY = "ALOTMAN_CONTRACTS_INITIAL_QUERY";

/**
 * User
 */

export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const FETCHED_USER_TO_EDIT = "FETCHED_USER_TO_EDIT";
export const ADD_ROLE_TO_USER = "ADD_ROLE_TO_USER";
export const REMOVE_ROLE_FROM_USER = "REMOVE_ROLE_FROM_USER";
export const RESET_USER_DATA = "RESET_USER_DATA";

// OFFER CONTRACT PREVIEW

export const SET_OFFER_CONTRACT_PREVIEW = "SET_OFFER_CONTRACT_PREVIEW";
export const RESET_PREVIEW = "RESET_PREVIEW";
export const SET_PREVIEW_LANGUAGE = "SET_PREVIEW_LANGUAGE";
