import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_TOP, FONT_SIZE_LABEL } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_TOP,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  text: {
    fontSize: FONT_SIZE_LABEL,
    fontFamily: "OpenSansBold",
  },
});

// DateLabel
export default function DateLabel({ value }) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{value}</Text>
    </View>
  );
}
