// libs
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectGuideScheduleServiceTypes,
  setGuideDrawerPayload,
} from "src/views/Guides/guidesSlice";

/**
 * useGuideServiceContainer is entry point of data for guideServiceContainer
 */
export default function useGuideServiceContainer() {
  const isGuideServiceCommentEnabled = false;

  // redux state
  const guideServiceTypes = useSelector(selectGuideScheduleServiceTypes);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  /**
   * on guide service status change
   */
  const onGuideServiceStatusChange = newGuideServiceStatus => {
    dispatch(setGuideDrawerPayload({ ...guidePayload, guideServiceStatusSelected: newGuideServiceStatus }));
  };

  /**
   * on guide service comment change
   */
  const onGuideServiceCommentChange = event => {
    const guideServiceComment = get(event, "target.value");
    dispatch(setGuideDrawerPayload({ ...guidePayload, guideServiceComment: guideServiceComment }));
  };

  return {
    // variables
    isGuideServiceCommentEnabled,
    guideServiceTypes,
    guideServiceStatusSelected: get(guidePayload, "guideServiceStatusSelected", ""),
    guideServiceComment: get(guidePayload, "guideServiceComment", ""),
    // functions
    onGuideServiceStatusChange,
    onGuideServiceCommentChange,
  };
}
