import React, { Component } from "react";

import PreviewGroup from "./PreviewGroup";
import PreviewServicesPackage from "./PreviewServicesPackage";
import PreviewServices from "./PreviewServices";
import PreviewMainTermin from "./PreviewMainTermin";
import PreviewHotels from "./PreviewHotels";
import PreviewContigents from "./PreviewContigents";
import PreviewPriceStructure from "./PreviewPriceStucture";
import PreviewAdditionalServices from "./PreviewAdditionalServices";
import PreviewTemplates from "./PreviewTemplates";
import PreviewOfferDateValidity from "./PreviewOfferDateValidity";
import PreviewSignature from "./PreviewSignature";
import PreviewDailyProgram from "./PreviewDailyProgram";
import PreviewAlotmanPriceStucture from "./PreviewAlotmanPriceStucture";
import { config } from "../../../utility/globals";
import PreviewAlotmanTemplates from "./PreviewAlotmanTemplates";

class PreviewContentRenderProps extends Component {
  render() {
    const { children, ...rest } = this.props;

    return React.Children.map(
      children,
      child => child && React.cloneElement(child, rest), //if child is not null
    );
  }
}

const PreviewContent = props => {
  const {
    preview: {
      offer_contract_document: { offer_contract_document_type_id },
    },
  } = props;

  const isNotProgramOnly = config.offer_type[offer_contract_document_type_id] !== "Program only";
  const isNotAlotman = config.offer_type[offer_contract_document_type_id] !== "Alotman";

  return (
    <div className="preview-content-container">
      <PreviewContentRenderProps {...props}>
        <PreviewGroup />
        <PreviewMainTermin />
        {isNotProgramOnly && <PreviewHotels />}
        {isNotProgramOnly && <PreviewContigents />}
        <PreviewServices />
        {isNotAlotman && <PreviewServicesPackage />}
        {isNotProgramOnly && isNotAlotman && <PreviewPriceStructure />}
        {!isNotAlotman && <PreviewAlotmanPriceStucture />}
        {isNotAlotman && <PreviewAdditionalServices />}
        {isNotAlotman && <PreviewTemplates />}
        {!isNotAlotman && <PreviewAlotmanTemplates />}
        <PreviewOfferDateValidity />
        {isNotAlotman && (
          <React.Fragment>
            <PreviewDailyProgram />
          </React.Fragment>
        )}
        <PreviewSignature />
      </PreviewContentRenderProps>
    </div>
  );
};

export default PreviewContent;
