import React, { Component } from "react";
import CreateService from "./Service/CreateService";
import EditService from "./Service/EditService";
import ServiceList from "./Service/ServiceList";
import CreateServiceType from "./ServiceType/CreateServiceType";
import EditServiceType from "./ServiceType/EditServiceType";
import ServiceTypeList from "./ServiceType/ServiceTypeList";

const ServiceTabsHOC = WrappedComponent => {
  return class extends Component {
    state = {
      serviceCreate: false,
      serviceEdit: false,
      service: "",
      serviceTypeCreate: false,
      serviceTypeEdit: false,
      serviceType: "",
    };

    serviceCreateToggle = () => {
      this.setState(prevState => {
        return {
          serviceCreate: !prevState.serviceCreate,
        };
      });
    };

    serviceEditOpen = service => {
      this.setState({
        service,
        serviceEdit: true,
      });
    };

    serviceEditClose = () => {
      this.setState({
        service: "",
        serviceEdit: false,
      });
    };

    serviceTypeCreateToggle = () => {
      this.setState(prevState => {
        return {
          serviceTypeCreate: !prevState.serviceTypeCreate,
        };
      });
    };

    serviceTypeEditOpen = serviceType => {
      this.setState({
        serviceType,
        serviceTypeEdit: true,
      });
    };

    serviceTypeEditClose = () => {
      this.setState({
        serviceType: "",
        serviceTypeEdit: false,
      });
    };

    showInTabService = () => {
      const { serviceCreate, serviceEdit, service } = this.state;

      if (serviceCreate) {
        return <CreateService cancelToggle={this.serviceCreateToggle} />;
      }
      if (serviceEdit) {
        return <EditService closeEdit={this.serviceEditClose} service={service} />;
      }
      return (
        <ServiceList
          tableName="administrationServices"
          toggleCreate={this.serviceCreateToggle}
          openEdit={this.serviceEditOpen}
        />
      );
    };

    showInTabServiceType = () => {
      const { serviceTypeCreate, serviceTypeEdit, serviceType } = this.state;

      if (serviceTypeCreate) {
        return <CreateServiceType cancelToggle={this.serviceTypeCreateToggle} />;
      }
      if (serviceTypeEdit) {
        return <EditServiceType serviceType={serviceType} closeEdit={this.serviceTypeEditClose} />;
      }
      return (
        <ServiceTypeList
          tableName="administrationServiceTypes"
          toggleCreate={this.serviceTypeCreateToggle}
          openEdit={this.serviceTypeEditOpen}
        />
      );
    };

    render() {
      return (
        <WrappedComponent showInTabService={this.showInTabService} showInTabServiceType={this.showInTabServiceType} />
      );
    }
  };
};

export default ServiceTabsHOC;
