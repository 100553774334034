// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import { setDrawerOpen, selectIsDrawerOpen, selectAnnouncementSelected } from "../../../announcementSlice";

/**
 * useAnnouncementView is entry point of data for announcementView drawer
 */
export default function useAnnouncementView() {
  // redux state
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const announcementSelected = useSelector(selectAnnouncementSelected);
  const dispatch = useDispatch();

  /**
   * onAnnouncement drawer close
   */
  const onCloseDrawer = () => {
    dispatch(setDrawerOpen(false));
  };

  return {
    announcementSelected,
    isDrawerOpen,
    onCloseDrawer,
  };
}
