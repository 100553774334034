// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectPhraseAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_PHRASE_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * usePhraseAnnouncement is entry point of data for phrase announcement form component
 */
export default function usePhraseAnnouncement() {
  // redux state
  const phraseAnnouncement = useSelector(selectPhraseAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updatePhraseAnnouncement, { loading }] = useMutation(UPDATE_PHRASE_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdatePhraseAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updatePhraseAnnouncement({
      variables: {
        input: {
          id: phraseAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updatePhraseAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ phraseAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    handleOnUpdatePhraseAnnouncement,
    phraseAnnouncement,
    loading,
  };
}
