import React from "react";

// hooks
import useGuideItem from "./hooks/useGuideItem";

// ui
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";

// stylea
const styles = {
  expansionPanel: {
    backgroundColor: "transparent",
    height: "100%",
    cursor: "pointer",
  },

  guideListContainer: {
    marginTop: 2,
    marginBottom: 2,
  },

  guideNamesContainer: {
    minWidth: 120,
  },

  dateItem: {
    marginBottom: 8,
  },
};

/**
 * GuideItem component shows list of all guides for this row
 */
const GuideItem = ({ classes, programId, guides }) => {
  const {
    expanded,
    getSortedDatesWithGuides,
    isGuideSameAsNext,
    isGuideSameAsPrevios,
    onExpansionClick,
  } = useGuideItem(programId);

  // check for null
  if (!guides) {
    return null;
  }

  // return root
  return (
    <ExpansionPanel className={classes.expansionPanel} onClick={onExpansionClick} elevation={0} expanded={expanded}>
      <ExpansionPanelSummary>Ukupno {guides.length} vodiča</ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          {getSortedDatesWithGuides(guides).map((dateWithGuide, index, array) => {
            return (
              <div
                key={`${_.get(dateWithGuide, "guide.guide_id", "")}- ${_.get(
                  dateWithGuide,
                  "guide.guide_id",
                  "",
                )}-${dateWithGuide.date.format("DD.MM.YYYY")}`}
              >
                <Grid className={classes.guideListContainer} container spacing={8}>
                  {!isGuideSameAsPrevios(
                    _.get(array[index - 1], "guide.guide_id", ""),
                    _.get(dateWithGuide, "guide.guide_id", ""),
                  ) ? (
                    <Grid className={classes.guideNamesContainer} item>
                      {/** guide full name */}
                      {`${dateWithGuide.guide.guide_first_name} ${dateWithGuide.guide.guide_last_name}`}
                    </Grid>
                  ) : (
                    <Grid className={classes.guideNamesContainer} item />
                  )}
                  <Grid item className={classes.dateItem} key={index}>
                    {dateWithGuide.date.format("DD.MM.YYYY")}
                  </Grid>
                </Grid>

                {/* add divider */}
                {!isGuideSameAsNext(
                  _.get(array[index + 1], "guide.guide_id", ""),
                  _.get(dateWithGuide, "guide.guide_id", ""),
                ) && <Divider />}
              </div>
            );
          })}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(GuideItem);
