import React, { Component, Fragment } from "react";

import { graphql, withApollo } from "react-apollo";
import { flowRight as compose, get } from "lodash";
import toastr from "toastr";

import PartnerClientForm from "./PartnerClientForm";

import { listLanguage, getPartnerClient as fetchPartnerClient } from "../../../../graphql/query/administration";
import { prepareForSending } from "../../../../utility/prepare";
import {
  createContact,
  deleteContact,
  createPartnerClientService,
  deletePartnerClientService,
  updatePartnerClient,
} from "../../../../graphql/mutation/administration";
import TableTitle from "src/components/UI/TableTitle";

const form = {
  padding: "24px",
};

class EditPartnerClient extends Component {
  state = {
    partner_client: {
      name: "",
      first_name: "",
      last_name: "",
      fax: "",
      address: "",
      url: "",
      oib: "",
      city_id: "",
      language_id: "",
      languages: "",
      partner_client_type_ids: [],
      partner_client_activities_inital: [],
      partner_client_activities: [],
      partner_client_activities_to_delete: [],
      contacts: [],
      services: [],
    },
    contact_data: {
      name: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      partner_client_id: "",
    },
    service_data: {
      price: "0",
      partner_client_service_type_id: "",
    },
  };

  async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: fetchPartnerClient,
        variables: {
          id: this.props.partner.id,
        },
      });

      const {
        data: { getPartnerClient },
      } = response;

      this.setState({
        partner_client: {
          ...getPartnerClient,
          partner_client_type_ids: getPartnerClient.partner_client_types.map(({ id }) => id),
          partner_client_activities_inital: getPartnerClient.partner_client_activities,
          partner_client_activities_to_delete: [],
        },
      });
    } catch (error) {}
  }

  changePartnerClientField = obj => {
    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          [obj.key]: obj.value,
        },
      };
    });
  };

  editedPartnerContact = contact => {
    const {
      partner_client,
      partner_client: { contacts },
    } = this.state;

    const contacts_clone = contacts.map(cont => {
      if (cont.id === contact.id) {
        return contact;
      }
      return cont;
    });

    this.setState({
      partner_client: {
        ...partner_client,
        contacts: contacts_clone,
      },
    });
  };

  changeCreateContact = obj =>
    this.setState(prevState => {
      return {
        contact_data: {
          ...prevState.contact_data,
          [obj.key]: obj.value,
        },
      };
    });

  addContact = async () => {
    try {
      const {
        partner_client,
        partner_client: { contacts },
        contact_data,
      } = this.state;

      const response = await this.props.creatingNewContact({
        variables: {
          ...contact_data,
          partner_client_id: partner_client.id,
        },
      });

      const {
        data: { createContact },
      } = response;

      const contacts_clone = [...contacts];
      contacts_clone.push({ ...createContact });

      this.setState({
        partner_client: {
          ...partner_client,
          contacts: contacts_clone,
        },
        contact_data: {
          name: "",
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
        },
      });

      toastr.success("Kontakt uspješno dodan");
    } catch (error) {}
  };

  removeContact = async contact_id => {
    try {
      const {
        partner_client,
        partner_client: { contacts },
      } = this.state;

      if (!contact_id.includes("-")) {
        await this.props.deleteContact({
          variables: {
            id: contact_id,
          },
        });
      }

      const contact_clone = contacts.filter(contact => contact.id !== contact_id);

      this.setState({
        partner_client: {
          ...partner_client,
          contacts: contact_clone,
        },
      });

      toastr.success("Kontakt uklonjen");
    } catch (error) {}
  };

  /**
   * Service
   */
  editedPartnerService = service => {
    const {
      partner_client,
      partner_client: { services },
    } = this.state;

    const services_clone = services.map(ser => {
      if (ser.id === service.id) {
        return service;
      }
      return ser;
    });

    this.setState({
      partner_client: {
        ...partner_client,
        services: services_clone,
      },
    });
  };

  setupCreateServiceData = partner_client_service_translations =>
    this.setState(prevState => {
      return {
        service_data: {
          ...prevState.service_data,
          partner_client_service_translations,
        },
      };
    });

  changeCreateService = obj =>
    this.setState(prevState => {
      return {
        service_data: {
          ...prevState.service_data,
          [obj.key]: obj.value,
        },
      };
    });

  changeCreateServiceTranslation = (obj, language_id) =>
    this.setState(prevState => {
      const translations = prevState.service_data.partner_client_service_translations.map(translation => {
        if (translation.language_id === language_id) {
          return {
            ...translation,
            [obj.key]: obj.value,
          };
        }
        return translation;
      });

      return {
        service_data: {
          ...prevState.service_data,
          partner_client_service_translations: translations,
        },
      };
    });

  addService = async () => {
    try {
      const {
        partner_client,
        partner_client: { services },
        service_data,
      } = this.state;

      const response = await this.props.createPartnerClientService({
        variables: {
          ...service_data,
          partner_client_id: partner_client.id,
        },
      });

      const {
        data: { createPartnerClientService },
      } = response;

      const services_clone = [...services];

      services_clone.push({
        ...service_data,
        id: createPartnerClientService.id,
      });
      const translations_copy = [...service_data.partner_client_service_translations];

      this.setState({
        partner_client: {
          ...partner_client,
          services: services_clone,
        },
        service_data: {
          price: "",
          partner_client_service_type_id: "",
          partner_client_service_translations: translations_copy.map(translation => ({
            ...translation,
            name: "",
            description: "",
            partner_client_service_id: "",
          })),
        },
      });
      toastr.success("Usluga uspješno dodana");
    } catch (error) {}
  };

  removeService = async service_id => {
    try {
      const {
        partner_client,
        partner_client: { services },
      } = this.state;

      if (!service_id.includes("-")) {
        await this.props.deletePartnerClientService({
          variables: {
            id: service_id,
          },
        });
      }

      const services_clone = services.filter(service => service.id !== service_id);

      this.setState({
        partner_client: {
          ...partner_client,
          services: services_clone,
        },
      });

      toastr.success("Usluga uklonjena");
    } catch (error) {}
  };

  /**
   * add partner activity
   */
  addPartnerActivity = activity => {
    const {
      partner_client: { partner_client_activities, partner_client_activities_to_delete },
    } = this.state;

    // return if partner already exists in list
    if (partner_client_activities.some(partnerActivity => partnerActivity.activity_id === activity.id)) {
      return;
    }

    const activitiesClone = [...partner_client_activities];
    // get name of activity
    const activityTranslation = get(activity, "activity_translations", []).find(
      translation => translation.language_id === "1",
    );
    const activityName = get(activityTranslation, "name", "");
    activitiesClone.push({
      activity_id: activity.id,
      name: activityName,
    });

    // handle list for delete
    const activitiesToDeleteClone = partner_client_activities_to_delete.filter(
      activityId => activityId !== activity.id,
    );

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
          partner_client_activities_to_delete: activitiesToDeleteClone,
        },
      };
    });
  };

  /**
   * delete partner activity
   */
  deletePartnerActivity = activity => {
    const {
      partner_client: {
        partner_client_activities,
        partner_client_activities_inital,
        partner_client_activities_to_delete,
      },
    } = this.state;

    // handle list for view
    const activitiesClone = partner_client_activities.filter(
      partnerActivity => partnerActivity.activity_id !== activity.activity_id,
    );
    // handle list for delete
    const activitiesToDeleteClone = [...partner_client_activities_to_delete];

    // handle list for deletion, check if activity was already set before for this partner
    if (
      partner_client_activities_inital.some(partnerActivity => partnerActivity.activity_id === activity.activity_id)
    ) {
      activitiesToDeleteClone.push(activity.activity_id);
    }

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
          partner_client_activities_to_delete: activitiesToDeleteClone,
        },
      };
    });
  };

  /**
   * update partner activity price
   */
  updatePartnerActivityPrice = (activityId, price) => {
    const {
      partner_client: { partner_client_activities },
    } = this.state;

    const activitiesClone = partner_client_activities.map(partnerActivity => {
      if (partnerActivity.activity_id === activityId) {
        return {
          ...partnerActivity,
          price: price,
        };
      } else {
        return partnerActivity;
      }
    });

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
        },
      };
    });
  };

  addPartnerClientType = id => {
    const {
      partner_client,
      partner_client: { partner_client_type_ids },
    } = this.state;

    if (partner_client_type_ids.includes(id)) {
      return;
    }

    const type_clone = [...partner_client_type_ids];
    type_clone.push(id);

    this.setState({
      partner_client: {
        ...partner_client,
        partner_client_type_ids: type_clone,
      },
    });
  };

  removePartnerClientType = partner_type_id => {
    const {
      partner_client,
      partner_client: { partner_client_type_ids },
    } = this.state;

    const type_clone = partner_client_type_ids.filter(id => id !== partner_type_id);

    this.setState({
      partner_client: {
        ...partner_client,
        partner_client_type_ids: type_clone,
      },
    });
  };

  submit = async () => {
    try {
      const partnerClientActivitiesToDelete = this.state.partner_client.partner_client_activities_to_delete;

      // prepare partner client activities
      const partnerClientActivities = this.state.partner_client.partner_client_activities.map(partnerActivity => {
        return { activity_id: partnerActivity.activity_id, price: partnerActivity.price };
      });

      // add activities for deletion
      partnerClientActivitiesToDelete.forEach(activityId =>
        partnerClientActivities.push({ activity_id: activityId, deleted: true }),
      );

      await this.props.updatePartnerClient({
        variables: {
          ...prepareForSending(this.state.partner_client, [
            "contacts",
            "__typename",
            "city",
            "language",
            "partner_client_types",
            "services",
            "partner_client_activities",
            "partner_client_activities_inital",
            "partner_client_activities_to_delete",
          ]),
          partner_client_activities: partnerClientActivities,
        },
      });

      toastr.success("Ažurirani podaci partnera");
      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const {
      data: { loading, listLanguage },
    } = this.props;

    if (loading) {
      return <div>Dohvaćam jezike . . .</div>;
    }

    if (!this.state.partner_client.id) {
      return null;
    }

    return (
      <Fragment>
        <TableTitle title="Ažuriranje partnera" onBackPressed={this.props.closeEdit} />
        <div style={form}>
          <PartnerClientForm
            partner_client={this.state.partner_client}
            onChangePartnerField={this.changePartnerClientField}
            contact_data={this.state.contact_data}
            service_data={this.state.service_data}
            addPartnerActivity={this.addPartnerActivity}
            deletePartnerActivity={this.deletePartnerActivity}
            updatePartnerActivityPrice={this.updatePartnerActivityPrice}
            addContact={this.addContact}
            removeContact={this.removeContact}
            editedPartnerContact={this.editedPartnerContact}
            editedPartnerService={this.editedPartnerService}
            removeService={this.removeService}
            changeCreateContact={this.changeCreateContact}
            listLanguage={listLanguage}
            setupCreateServiceData={this.setupCreateServiceData}
            changeCreateServiceTranslation={this.changeCreateServiceTranslation}
            changeCreateService={this.changeCreateService}
            addService={this.addService}
            addPartnerClientType={this.addPartnerClientType}
            removePartnerClientType={this.removePartnerClientType}
            save={this.submit}
            cancelToggle={this.props.closeEdit}
            submitTitle="Ažuriraj"
          />
        </div>
      </Fragment>
    );
  }
}
export default compose(
  graphql(listLanguage),
  graphql(createContact, { name: "creatingNewContact" }),
  graphql(deleteContact, { name: "deleteContact" }),
  graphql(createPartnerClientService, { name: "createPartnerClientService" }),
  graphql(deletePartnerClientService, { name: "deletePartnerClientService" }),
  graphql(updatePartnerClient, { name: "updatePartnerClient" }),
)(withApollo(EditPartnerClient));
