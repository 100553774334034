import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addTermin,
  changeEditData,
  createProgramDays,
  saveEditedTermin,
  setProgramDaysSum,
} from "../../../../store/actions";
import DateRange from "../../../../components/UI/DateRangePicker";
import { dateDiff, formatDate } from "../../../../utility/dates";
import Paper from "material-ui/Paper";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import moment from "moment";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { prepareForSending } from "../../../../utility/prepare";
import CommentTextArea from "../../../../components/UI/CommentTextArea";

class ProgramOnlyDailyProgramCreate extends Component {
  state = {
    terminToEdit: null,
    oldTermin: null,
  };

  componentDidUpdate() {
    const { from, to } = this.props;
    if (from && to) {
      // Kreiranje programa
      this.props.createProgramDays(dateDiff(this.props.from, this.props.to, "days") + 1);

      this.props.addTermin();
    }
  }

  openToEdit = (expanded, index) => {
    let termin = this.dataToShow()[index[0]];
    termin = {
      ...termin,
      from: moment(termin.from, "DD-MM-YYYY"),
      to: moment(termin.to, "DD-MM-YYYY"),
    };

    if (!this.state.expanded) {
      this.setState({
        expanded,
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
      });
    } else {
      this.setState({
        expanded: {
          [index[0]]: !this.state.expanded[index[0]],
        },
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
      });
    }
  };

  changeTerminFrom = from => {
    if (from) {
      this.setState(prev => ({
        terminToEdit: {
          ...prev.terminToEdit,
          from: moment(from, "DD-MM-YYYY"),
        },
      }));
    }
  };

  changeTerminTo = to => {
    if (to) {
      this.setState(prev => ({
        terminToEdit: {
          ...prev.terminToEdit,
          to: moment(to, "DD-MM-YYYY"),
        },
      }));
    }
  };

  saveChanges = async () => {
    const { terminToEdit, oldTermin } = this.state;
    const terminToEditCpy = prepareForSending(terminToEdit, ["diff", "estabishment_name", "contigents_list"]);
    const oldTerminCpy = prepareForSending(oldTermin, ["diff", "estabishment_name", "contigents_list"]);

    if (terminToEdit.id.includes("-")) {
      this.props.saveEditedTermin(terminToEditCpy, oldTerminCpy);

      this.props.setProgramDaysSum(dateDiff(terminToEdit.from, terminToEdit.to, "days") + 1);

      this.setState({
        expanded: {},
        terminToEdit: null,
        oldTermin: null,
      });
    }
  };

  dataToShow = () => {
    return this.props.termin_periods.map(termin => {
      return {
        ...termin,
        from: formatDate(termin.from),
        to: formatDate(termin.to),
        diff: dateDiff(termin.from, termin.to, "day") + 1,
      };
    });
  };

  render() {
    const { terminToEdit } = this.state;

    let CreateTermin =
      this.props.termin_periods && this.props.termin_periods.length < 1 ? (
        <div className="form-unit-double">
          <div
            className="field-label is-normal"
            style={{
              paddingTop: "0.2em",
            }}
          >
            <label className="label">Termin</label>
          </div>
          <DateRange
            from={this.props.from}
            to={this.props.to}
            handleFromChange={date =>
              this.props.changeEditData({
                key: "from",
                value: date,
              })
            }
            handleToChange={date =>
              this.props.changeEditData({
                key: "to",
                value: date,
              })
            }
          />
        </div>
      ) : null;

    let TerminsList =
      this.props.termin_periods && this.props.termin_periods.length > 0 ? (
        <div className="form-unit-double">
          <div
            className="field-label is-normal"
            style={{
              paddingTop: "0.2em",
            }}
          >
            <label className="label">Termin</label>
          </div>
          <Paper elevation={1}>
            <ReactTableCustom
              data={this.dataToShow()}
              columns={[
                {
                  Header: "Edit",
                  columns: [
                    {
                      expander: true,
                      Header: () => <strong>More</strong>,
                      width: 65,

                      Expander: ({ isExpanded, ...rest }) => {
                        return !this.props.match.params.id ? (
                          <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                        ) : null;
                      },
                      style: {
                        cursor: "pointer",
                        fontSize: 25,
                        padding: "0",
                        textAlign: "center",
                        userSelect: "none",
                      },
                    },
                  ],
                },
                {
                  Header: "TERMIN",
                  columns: [
                    {
                      Header: "Od",
                      accessor: "from",
                    },
                    {
                      Header: "Do",
                      accessor: "to",
                    },
                    {
                      Header: "Dana",
                      accessor: "diff",
                      width: 70,
                    },
                  ],
                },
              ]}
              innerProps={{
                pageSize: this.dataToShow().length,
                showPagination: false,
                sortable: false,
                filterable: false,
                filterAll: false,
                expanded: !this.props.match.params.id ? this.state.expanded : false,
                onExpandedChange: !this.props.match.params.id ? this.openToEdit : null,
                SubComponent: this.props.match.params.id
                  ? null
                  : () => {
                      if (!terminToEdit) {
                        return "";
                      }

                      return (
                        <div style={{ padding: "12px" }}>
                          <DateRange
                            from={terminToEdit.from}
                            to={terminToEdit.to}
                            handleFromChange={this.changeTerminFrom}
                            handleToChange={this.changeTerminTo}
                            appendToBody
                          />
                          <div className="subtermin-create-buttons">
                            <Button variant="contained" color="primary" onClick={this.saveChanges}>
                              Spremi
                            </Button>
                          </div>
                        </div>
                      );
                    },
              }}
            />
          </Paper>
        </div>
      ) : null;

    return (
      <div className="cols-2">
        <div className="form-section-one">
          {CreateTermin}
          {TerminsList}
          <div className="form-unit-double">
            <div />
            <CommentTextArea
              name="comment_term_period"
              defaultValue={this.props.comment_term_period}
              label="Komentar termina"
              inputProps={{ disabled: true }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    from,
    to,
    offer: {
      offer_contract_document: { id, termin_periods, offer_contract_document_type_id, comment_term_period },
    },
    program_days,
  } = state.offer;
  return {
    offer_contract_document_id: id,
    from,
    to,
    termin_periods,
    offer_contract_document_type_id,
    program_days,
    comment_term_period,
  };
};

export default connect(mapStateToProps, {
  addTermin,
  changeEditData,
  createProgramDays,
  saveEditedTermin,
  setProgramDaysSum,
})(withRouter(ProgramOnlyDailyProgramCreate));
