import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";

import DatePicker from "../../../../components/UI/DatePicker";

import * as actions from "../../../../store/actions";
import { config } from "../../../../utility/globals";
import { autosize } from "../../../../utility/globals";

class DateStatusType extends Component {
  state = {
    transport: false
  };

  componentDidMount = () => {
    let transport = false;

    const { offer_contract_document_type_id, termin_periods } = this.props;

    // to set transport
    if (config.offer_type[offer_contract_document_type_id] === "Tour") {
      transport = termin_periods[0].subtermin_periods[0].transport;
    } else {
      transport = termin_periods[0].transport;
    }

    this.setState({ transport });
  };

  render() {
    const { props } = this;

    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={3} xl={1}>
            <h3>Malinska, </h3>
          </Grid>
          <Grid item xs={9} xl={5}>
            <DatePicker value={props.contract_created_at || ""} disabled />
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              disabled
              select
              label="Tip Ponude"
              value={props.offer_contract_document_type_desc || ""}
            >
              <MenuItem
                key={`key-${props.offer_contract_document_type_desc}`}
                value={props.offer_contract_document_type_desc}
              >
                {props.offer_contract_document_type_desc}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Status"
              value={props.contract_status_id || ""}
              onChange={({ target: { value } }) =>
                props.changeOfferParameter({
                  key: "contract_status_id",
                  value
                })
              }
            >
              {props.listContractStatus
                ? props.listContractStatus.map(type => (
                    <MenuItem key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              disabled
              select
              label="Segment"
              value={props.offer_contract_document_segment_desc || ""}
            >
              <MenuItem
                key={`key-${props.offer_contract_document_segment_desc}`}
                value={props.offer_contract_document_segment_desc}
              >
                {props.offer_contract_document_segment_desc}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Jezik"
              disabled
              value={props.language_id || ""}
            >
              {props.listLanguage
                ? props.listLanguage.map(language => (
                    <MenuItem key={`key-${language.id}`} value={language.id}>
                      {language.code}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Transport"
              value={this.state.transport.toString()}
              disabled
            >
              <MenuItem value={"false"}>Ne</MenuItem>
              <MenuItem value={"true"}>Da</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              label="ID ugovora"
              InputLabelProps={{
                shrink: true
              }}
              value={props.external_id || ""}
              onChange={({ target: { value } }) =>
                props.changeOfferParameter({
                  key: "external_id",
                  value
                })
              }
            />
          </Grid>
        </Grid>
        {config.contract_status &&
        props.contract_status_id &&
        config.contract_status[props.contract_status_id.toString()] ===
          "Storno" ? (
          <Grid container spacing={24} justify="flex-end">
            <Grid item xs={12} xl={6}>
              <textarea
                onKeyUp={el => autosize(el.target)}
                className="preview-content-content preview-textarea textarea-stretch"
                placeholder="Komentar na storno dokumenta"
                onChange={e =>
                  props.changeOfferParameter({
                    key: "storno_comment",
                    value: e.target.value
                  })
                }
                value={props.storno_comment || ""}
              />
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    external_id,
    contract_status_id,
    storno_comment,
    contract_created_at,
    offer_contract_document: {
      termin_periods,
      offer_contract_document_type_id,
      language_id,
      offer_contract_document_type,
      offer_contract_document_segment
    }
  } = state.offer.offer;

  return {
    offer_contract_document_type_id,
    external_id,
    language_id,
    contract_status_id,
    termin_periods,
    storno_comment,
    offer_contract_document_type_desc: offer_contract_document_type.desc,
    contract_created_at,
    offer_contract_document_segment_desc: offer_contract_document_segment.desc
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOfferParameter: obj => dispatch(actions.changeOfferParameter(obj)),
    changeDocumentParam: obj => dispatch(actions.changeDocumentParam(obj)),
    changeEditData: obj => dispatch(actions.changeEditData(obj))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateStatusType);
