import React from "react";
import { Switch, Route } from "react-router-dom";

import PartnerTabs from "./PartnerTabs";

const PartnerClientRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/partner_klijent/"
        exact
        component={PartnerTabs}
      />
    </Switch>
  );
};

export default PartnerClientRouter;
