import React from "react";

// hooks
import useGridFilters from "./hooks/useGridFilters";

// ui
import Paper from "material-ui/Paper";
import { FormControlLabel, FormGroup, FormControl, withStyles, Checkbox, Typography } from "@material-ui/core";
import Popover, { PopoverAnimationVertical } from "material-ui/Popover";

const styles = theme => ({
  root: {
    display: "flex",
  },
  typo_root: {
    margin: "24px 24px 0px 24px",
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  headline: {
    fontSize: 24,
    padding: 16,
    marginBottom: 12,
    fontWeight: 400,
    marginTop: 0,
  },
});

function FiltersView({ classes }) {
  const { toolbarState, gridFilters, changeActiveGuidesColumns, handleOnCloseFilter } = useGridFilters();

  if (!gridFilters) {
    return null;
  }

  return (
    <Popover
      open={toolbarState.filtersOpen}
      anchorEl={toolbarState.anchors}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      targetOrigin={{ horizontal: "left", vertical: "top" }}
      onRequestClose={handleOnCloseFilter}
      animation={PopoverAnimationVertical}
    >
      <Paper style={{ width: "500px", height: "500px", overflow: "auto" }}>
        <div>
          <Typography variant="subheading" gutterBottom classes={{ root: classes.typo_root }}>
            Kolone
          </Typography>
          <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {gridFilters.map(col =>
                  col.column !== "Posljednji ažurirao" ? (
                    <FormControlLabel
                      key={col.id}
                      control={
                        <Checkbox
                          checked={col.active}
                          onChange={() => changeActiveGuidesColumns(col.id, !col.active)}
                          color="primary"
                        />
                      }
                      label={col.column}
                    />
                  ) : null,
                )}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </Paper>
    </Popover>
  );
}

export default withStyles(styles)(FiltersView);
