import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BootstrapInput from "../../../../components/UI/BootstrapLikeInput";
import { autosize } from "../../../../utility/globals";
import { FormControl, FormHelperText } from "@material-ui/core";

class ActivityTranslations extends Component {
  componentDidUpdate() {
    // Resizing
    Object.keys(this.refs).forEach(key => {
      autosize(this.refs[key]);
    });
  }

  render() {
    const { listLanguage, activity_translations, editTranslation, error, errorMsg } = this.props;

    const tableToShow = listLanguage.map((language, index) => {
      const activity_translation = activity_translations.find(translation => translation.language_id === language.id);

      if (!activity_translation) {
        return null;
      }

      return (
        <TableRow key={language.id} className="align-top">
          <TableCell component="th" scope="row">
            {language.desc}
          </TableCell>
          <TableCell>
            <BootstrapInput
              label=""
              id="name"
              error={error(`/activity_translations/${index}/name`)}
              errorMsg={errorMsg(`/activity_translations/${index}/name`)}
              value={activity_translation.name}
              onChange={({ target: { value } }) => editTranslation({ key: "name", value }, language.id)}
            />
          </TableCell>
          <TableCell padding="dense">
            <div>
              <FormControl fullWidth>
                <textarea
                  id="activity_translation"
                  ref={`activity_translation${activity_translation.id}`}
                  className={`preview-content-content preview-textarea textarea-stretch ${
                    error(`/activity_translations/${index}/desc`) ? "textarea-error" : ""
                  }`}
                  value={activity_translation.desc}
                  onChange={({ target: { value } }) => editTranslation({ key: "desc", value }, language.id)}
                />
                {error(`/activity_translations/${index}/desc`) && (
                  <FormHelperText>{errorMsg(`/activity_translations/${index}/desc`)}</FormHelperText>
                )}
              </FormControl>
            </div>
          </TableCell>
        </TableRow>
      );
    });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jezik</TableCell>
            <TableCell>Naziv</TableCell>
            <TableCell>Opis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableToShow ? tableToShow : <React.Fragment />}</TableBody>
      </Table>
    );
  }
}

export default ActivityTranslations;
