import { Button, FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import gql from "graphql-tag";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import FormValidator from "../../../../components/UI/FormValidator";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { removeContigent, saveEditedContigentTypologySum } from "../../../../store/actions";
import { sortContigentsByTypologyPriorityWithoutListEstablishment } from "../../../../utility/establishment";
import { connect } from "react-redux";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
  subterminContainer: {
    padding: "24px 10px",
  },
});

class ContigentsList extends Component {
  static schema = {
    properties: {
      typologies_sum: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Broj tipologija mora biti upisan i mora biti broj",
      },
    },
  };

  state = {
    contigentToEdit: null,
    oldContigent: null,
  };

  openToEdit = (expanded, index) => {
    if (!this.state.expanded) {
      this.setState({
        expanded,
        contigentToEdit: { ...this.dataToShow()[index[0]] },
        oldContigent: { ...this.dataToShow()[index[0]] },
      });
    } else {
      this.setState({
        expanded: {
          [index[0]]: !this.state.expanded[index[0]],
        },
        contigentToEdit: { ...this.dataToShow()[index[0]] },
        oldContigent: { ...this.dataToShow()[index[0]] },
      });
    }
  };

  changeTypologySum = ({ target: { value } }) =>
    this.setState(prev => ({
      contigentToEdit: {
        ...prev.contigentToEdit,
        typologies_sum: value,
      },
    }));

  removeContigent = async id => {
    // if we're in edit mode
    if (this.props.match.params.id) {
      try {
        const response = await this.props.deleteContigent({
          variables: {
            id,
          },
        });
        toastr.success("Uspješno izbrisan kontigent");

        (this.props.removeSubterminContigent && this.props.removeSubterminContigent(response.data.deleteContigent)) ||
          (this.props.removeContigent && this.props.removeContigent(response.data.deleteContigent));
      } catch (error) {}
    } else {
      (this.props.removeSubterminContigent && this.props.removeSubterminContigent(id)) ||
        (this.props.removeContigent && this.props.removeContigent(id));
    }
  };

  saveChanges = async () => {
    const { contigentToEdit, oldContigent } = this.state;

    if (contigentToEdit.id.includes("-")) {
      this.props.saveEditedContigentTypologySum(contigentToEdit, oldContigent);
      this.setState({
        expanded: {},
        contigentToEdit: null,
        oldContigent: null,
      });
    } else {
      try {
        this.props.mutate({
          variables: {
            id: contigentToEdit.id,
            typologies_sum: contigentToEdit.typologies_sum,
          },
        });

        this.props.saveEditedContigentTypologySum(contigentToEdit, oldContigent);

        this.setState({
          expanded: {},
          contigentToEdit: null,
          oldContigent: null,
        });

        toastr.success("Kontigent uspješno ažuriran");
      } catch (error) {}
    }
  };

  dataToShow = () => {
    if (!this.props.termin_periods || !this.props.termin_periods.length) {
      return [];
    }
    const { contigents } = this.props.termin_periods[0];

    return contigents && contigents.length && this.props.language_id
      ? sortContigentsByTypologyPriorityWithoutListEstablishment(contigents).map(contigent => {
          return {
            ...contigent,
            persons_capacity: `${contigent.typology.code} [${contigent.typology.persons_capacity}]`,
            establishment_name: `${contigent.typology.establishment.name} - ${contigent.typology.establishment.city.name}`,
          };
        })
      : [];
  };

  render() {
    if (!this.props.language_id) {
      return "Odaberite jezik prije prikaza kontigenata";
    }
    const { contigentToEdit } = this.state;
    const { isNotDraft } = this.props;

    return (
      <ReactTableCustom
        data={this.dataToShow()}
        columns={[
          {
            Header: "Edit",
            columns: [
              {
                expander: true,
                Header: () => <strong>More</strong>,
                width: 65,

                Expander: ({ isExpanded, ...rest }) => {
                  return !isNotDraft ? <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div> : null;
                },
                style: {
                  cursor: "pointer",
                  fontSize: 25,
                  padding: "0",
                  textAlign: "center",
                  userSelect: "none",
                },
              },
            ],
          },
          {
            Header: "Kontigenti",
            columns: [
              {
                Header: "Br_osoba",
                accessor: "typologies_sum",
              },
              {
                Header: "Kapacitet",
                accessor: "persons_capacity",
              },
              {
                Header: "Smještaj",
                accessor: "establishment_name",
              },
              {
                Header: "Brisanje",
                id: "delete",
                accessor: contigent =>
                  !isNotDraft ? (
                    <DeleteForeverIcon
                      className={this.props.classes.icon}
                      onClick={() => this.removeContigent(contigent.id)}
                    />
                  ) : null,
              },
            ],
          },
        ]}
        innerProps={{
          pageSize: this.dataToShow().length,
          showPagination: false,
          sortable: false,
          filterable: false,
          filterAll: false,
          expanded: this.state.expanded,
          onExpandedChange: this.openToEdit,
          SubComponent: row => {
            if (!contigentToEdit) {
              return "";
            }

            return (
              <FormValidator schema={ContigentsList.schema} data={contigentToEdit} submit={this.saveChanges}>
                {({ error, errorMsg, submit }) => (
                  <div style={{ padding: "12px" }}>
                    <Grid container spacing={24}>
                      <Grid item xs={6}>
                        <FormControl fullWidth error={error("/typologies_sum")}>
                          <InputLabel shrink={true} htmlFor="persons_capacity">
                            Br. tipologija
                          </InputLabel>
                          <Input
                            id="persons_capacity_edit"
                            name="persons_capacity"
                            value={contigentToEdit.typologies_sum}
                            onChange={this.changeTypologySum}
                          />
                          {error("/typologies_sum") && <FormHelperText>{errorMsg("/typologies_sum")}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel shrink={true} htmlFor="persons_capacity">
                            Tipologija
                          </InputLabel>
                          <Input
                            id="persons_capacity"
                            name="persons_capacity"
                            value={contigentToEdit.persons_capacity}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="subtermin-create-buttons">
                      <Button variant="contained" color="primary" onClick={submit}>
                        Spremi
                      </Button>
                    </div>
                  </div>
                )}
              </FormValidator>
            );
          },
        }}
      />
    );
  }
}

const updateContigent = gql`
  mutation($id: ID!, $typologies_sum: Int) {
    updateContigent(input: { id: $id, patch: { typologies_sum: $typologies_sum } }) {
      id
    }
  }
`;

const deleteContigent = gql`
  mutation($id: ID!) {
    deleteContigent(id: $id)
  }
`;

export default compose(
  graphql(updateContigent),
  graphql(deleteContigent, { name: "deleteContigent" }),
)(connect(null, { saveEditedContigentTypologySum, removeContigent })(withStyles(styles)(withRouter(ContigentsList))));
