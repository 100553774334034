import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import CountryForm from "./CountryForm";
import { createCountry } from "../../../../graphql/mutation/administration";
import TableTitle from "src/components/UI/TableTitle";

class CreateCountry extends Component {
  state = {
    name: "",
    iso_3166_code: "",
  };

  changeFieldData = obj => {
    this.setState({
      [obj.key]: obj.value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      this.props.cancelToggle();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title={`Dodavanje nove države`} onBackPressed={this.props.cancelToggle} />
        <CountryForm
          country={this.state}
          onChangeField={this.changeFieldData}
          onSubmit={this.submit}
          cancelToggle={this.props.cancelToggle}
        />
      </Fragment>
    );
  }
}

export default graphql(createCountry)(CreateCountry);
