import * as actionTypes from "../actionTypes";

// setAllBookingsInitialQueryInput
export const setAllBookingsInitialQueryInput = (limit, offset, selectedFilters, allBookingsFilter) => {
  return {
    type: actionTypes.ALL_BOOKINGS_INITIAL_QUERY,

    limit: limit,
    offset: offset,
    selectedFilters: selectedFilters,
    allBookingsFilter: allBookingsFilter,
  };
};
