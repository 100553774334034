import React from "react";
import { Switch, Route } from "react-router-dom";
import AllInquiries from "./AllInquiries/AllInquiries";
import EditInquiry from "./Edit/EditInquiry";
import CreateInquiry from "./Create/CreateInquiry";

const InquiryRouter = () => {
  return (
    <Switch>
      <Route path="/upit/edit/:id" component={EditInquiry} />
      <Route path="/upit/create" component={CreateInquiry} />
      <Route path="/" render={props => <AllInquiries {...props} tableName={"inquiryTable"} />} />
    </Switch>
  );
};

export default InquiryRouter;
