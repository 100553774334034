import React from "react";
import { Switch, Route } from "react-router-dom";

import ServiceTabs from "./ServiceTabs";

const ServiceRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/usluga_smjestaja/"
        exact
        component={ServiceTabs}
      />
    </Switch>
  );
};

export default ServiceRouter;
