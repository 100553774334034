import React, { Component, Fragment } from "react";
import toastr from "toastr";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import debounce from "lodash/debounce";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import {
  listCities,
  listPartnerClientServiceType,
  listPartnerClientType,
} from "../../../../graphql/query/administration";

import { cityInfo } from "../../../../utility/city";

import CreateParnerClientContact from "./Contacts/ParnerClientContactForm";
import EditParnerClientContact from "./Contacts/ParnerClientContactForm";

import PartnerClientContactShow from "./Contacts/ParnerClientContactShow";

import EditPartnerClientServiceForm from "./Services/EditPartnerClientServiceForm";
import PartnerClientServiceShow from "./Services/PartnerClientServiceShow";
import CreatePartnerClientServiceForm from "./Services/CreatePartnerClientServiceForm";
import { updateContact, updatePartnerClientService } from "../../../../graphql/mutation/administration";
import { prepareForSending } from "../../../../utility/prepare";
import { withRouter } from "react-router-dom";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import FormValidator from "../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";
import { removeDuplicateObjects } from "../../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";
import get from "lodash/get";
import PartnerActivities from "./PartnerActivities/PartnerActivities";

class PartnerClientForm extends Component {
  static schema = {
    properties: {
      partner_client_type_ids: {
        type: "array",
        minItems: 1,
        errorMessage: "Potrebano je odabrati barem jedan tip partnera",
        items: {
          type: "integer",
          minimum: 1,
        },
      },
      name: {
        type: "string",
        minLength: 1,
        errorMessage: "Ime grada ne smije biti prazno",
      },
      language_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati primarni jezik",
      },
      city_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati grad",
      },
    },
  };

  static EditPartnerType = ({
    partner_client,
    partner_client_type_ids,
    removePartnerClientType,
    listPartnerClientType,
  }) =>
    partner_client.id
      ? partner_client &&
        partner_client_type_ids.map(type_id => {
          const s = listPartnerClientType.find(({ id }) => id.toString() === type_id.toString());
          if (s) {
            return (
              <Grid item key={type_id}>
                <Chip
                  label={s.desc}
                  onDelete={partner_client_type_ids.length > 1 ? () => removePartnerClientType(type_id) : null}
                />
              </Grid>
            );
          } else {
            return "";
          }
        })
      : null;

  static CreatePartnerType = ({
    partner_client,
    partner_client_type_ids,
    removePartnerClientType,
    listPartnerClientType,
  }) =>
    !partner_client.id
      ? partner_client_type_ids &&
        partner_client_type_ids.map(type_id => {
          const s = listPartnerClientType.find(({ id }) => id.toString() === type_id.toString());
          if (s) {
            return (
              <Grid item key={type_id}>
                <Chip label={s.desc} onDelete={() => removePartnerClientType(type_id)} />
              </Grid>
            );
          } else {
            return "";
          }
        })
      : null;

  state = {
    contactToEdit: "",
    serviceToEdit: "",
    city: this.props.partner_client.city,
    fetchingMoreCities: false,
  };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  handleAutocompleteInputChange = value => {
    let where = this.variables.input.where ? JSON.parse(this.variables.input.where) : null;

    // checking if previous fetch name and current value are empty
    // skip fetch if same requests
    if (where && !where.name && !value) {
      return;
    }

    where = this.variables.input.where
      ? JSON.stringify({
          ...JSON.parse(this.variables.input.where),
          name: value,
        })
      : JSON.stringify({
          name: value,
        });

    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: where,
      },
    };

    this.props.listCitiesQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listCity.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listCity: [...fetchMoreResult.listCity],
        };
      },
    });
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 500);

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listCity = get(this.props, "listCitiesQuery.listCity", []);

    if (this.fetchingResults === false && this.hasMoreItems && listCity && listCity.length >= PAGINATION_LIMIT) {
      this.setState({ fetchingMoreCities: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listCitiesQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listCity.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listCity: [...previousResult.listCity, ...fetchMoreResult.listCity],
            };
          },
        });

        this.setState({ fetchingMoreCities: false });
      });
    }
  };

  setContactToEdit = contact =>
    this.setState({
      contactToEdit: { ...contact },
    });

  setServiceForEdit = service =>
    this.setState({
      serviceToEdit: { ...service },
    });

  cancelContactChanges = () =>
    this.setState({
      contactToEdit: "",
    });

  saveContactChanges = async () => {
    const { contactToEdit } = this.state;
    try {
      if (contactToEdit.id && !contactToEdit.id.includes("-")) {
        // Šalji serveru
        await this.props.updateContact({
          variables: {
            ...prepareForSending(contactToEdit, ["__typename"]),
          },
        });
      }

      this.props.editedPartnerContact(contactToEdit);

      this.setState({
        contactToEdit: "",
      });
      toastr.success("Kontakt ažuriran");
    } catch (error) {}
  };

  changePartnerContactField = obj => {
    this.setState(prevState => {
      return {
        contactToEdit: {
          ...prevState.contactToEdit,
          [obj.key]: obj.value,
        },
      };
    });
  };

  cancelServiceChanges = () =>
    this.setState({
      serviceToEdit: "",
    });

  saveServiceChanges = async () => {
    const { serviceToEdit } = this.state;
    try {
      if (serviceToEdit.id && !serviceToEdit.id.includes("-")) {
        // Šalji serveru
        await this.props.updatePartnerClientService({
          variables: {
            ...prepareForSending(serviceToEdit, ["__typename", "language_id"]),
          },
        });
      }

      this.props.editedPartnerService(serviceToEdit);

      this.setState({
        serviceToEdit: "",
      });
      toastr.success("Usluga ažurirana");
    } catch (error) {}
  };

  changePartnerServiceField = obj => {
    this.setState(prevState => {
      return {
        serviceToEdit: {
          ...prevState.serviceToEdit,
          [obj.key]: obj.value,
        },
      };
    });
  };

  changePartnerServiceTranslationField = (obj, language_id) =>
    this.setState(prevState => {
      const translations = prevState.serviceToEdit.partner_client_service_translations.map(translation => {
        if (translation.language_id === language_id) {
          return {
            ...translation,
            [obj.key]: obj.value,
          };
        }
        return translation;
      });

      return {
        serviceToEdit: {
          ...prevState.serviceToEdit,
          partner_client_service_translations: translations,
        },
      };
    });

  render() {
    const {
      onChangePartnerField,
      changeCreateContact,
      setupCreateServiceData,
      changeCreateService,
      changeCreateServiceTranslation,
      addService,
      partner_client,
      service_data,
      addPartnerActivity,
      deletePartnerActivity,
      updatePartnerActivityPrice,
      contact_data,
      addContact,
      removeContact,
      removeService,
      listLanguage,
      addPartnerClientType,
      removePartnerClientType,
      save,
      clientServiceTypes,
      clientServiceTypes: { listPartnerClientServiceType },
      listPartnerClientTypes,
      listPartnerClientTypes: { listPartnerClientType },
      submitTitle,
      cancelToggle,
    } = this.props;

    const { contactToEdit, serviceToEdit } = this.state;

    const listCity = get(this.props, "listCitiesQuery.listCity", []);
    const loadingCities = get(this.props, "listCitiesQuery.loading", []);

    if (loadingCities) {
      return <div>Dohvaćam gradove . . . </div>;
    }

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    if (clientServiceTypes.loading) {
      return <div>Dohvaćam tipove usluga klijenata . . . </div>;
    }

    if (listPartnerClientTypes.loading) {
      return <div>Dohvaćam tipove klijenata . . . </div>;
    }

    // Inserting picked city to top,
    // So it can be picked in init array  even if it's not fetched from first city list
    const citiesToShow = this.state.city
      ? removeDuplicateObjects([this.state.city, ...listCity]).map(city => ({
          value: city.id,
          label: cityInfo(city),
        }))
      : listCity.map(city => ({
          value: city.id,
          label: cityInfo(city),
        }));

    return (
      <FormValidator schema={PartnerClientForm.schema} submit={save} data={partner_client}>
        {({ error, errorMsg, submit }) => (
          <div className="partner-client-form">
            <div className="grid-column-1">
              <div className="form-control-grid">
                <AutocompleteSelect
                  error={error("/partner_client_type_ids")}
                  errorMsg={errorMsg("/partner_client_type_ids")}
                  label="Tip partnera"
                  placeholder="Odabir tipa partnera"
                  autocompleteHandler={addPartnerClientType}
                  dataSource={
                    listPartnerClientType &&
                    listPartnerClientType.map(type => ({
                      value: type.id,
                      label: type.desc,
                    }))
                  }
                />
              </div>
              <div className="form-control-grid">
                <Grid container spacing={8}>
                  <PartnerClientForm.CreatePartnerType
                    partner_client={partner_client}
                    partner_client_type_ids={partner_client.partner_client_type_ids}
                    removePartnerClientType={removePartnerClientType}
                    listPartnerClientType={listPartnerClientType}
                  />
                  <PartnerClientForm.EditPartnerType
                    partner_client={partner_client}
                    partner_client_type_ids={partner_client.partner_client_type_ids}
                    removePartnerClientType={removePartnerClientType}
                    listPartnerClientType={listPartnerClientType}
                  />
                </Grid>
              </div>
              <div className="form-control-grid">
                <FormControl fullWidth error={error("/name")}>
                  <InputLabel shrink={true} htmlFor="name">
                    Ime tvrtke
                  </InputLabel>
                  <Input
                    id="name"
                    name="name"
                    value={partner_client.name ? partner_client.name : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "name", value })}
                  />
                  <FormHelperText>{errorMsg("/name")}</FormHelperText>
                </FormControl>
              </div>

              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="first_name">
                    Ime
                  </InputLabel>
                  <Input
                    id="first_name"
                    name="first_name"
                    value={partner_client.first_name ? partner_client.first_name : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "first_name", value })}
                  />
                </FormControl>
              </div>

              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="last_name">
                    Prezime
                  </InputLabel>
                  <Input
                    id="last_name"
                    name="last_name"
                    value={partner_client.last_name ? partner_client.last_name : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "last_name", value })}
                  />
                </FormControl>
              </div>

              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="address">
                    Adresa
                  </InputLabel>
                  <Input
                    id="address"
                    name="address"
                    value={partner_client.address ? partner_client.address : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "address", value })}
                  />
                </FormControl>
              </div>

              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="url">
                    Url
                  </InputLabel>
                  <Input
                    id="url"
                    name="url"
                    value={partner_client.url ? partner_client.url : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "url", value })}
                  />
                </FormControl>
              </div>
            </div>
            <div className="grid-column-2">
              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="fax">
                    Fax
                  </InputLabel>
                  <Input
                    id="fax"
                    name="fax"
                    value={partner_client.fax ? partner_client.fax : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "fax", value })}
                  />
                </FormControl>
              </div>

              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="oib">
                    OIB
                  </InputLabel>
                  <Input
                    id="oib"
                    name="oib"
                    value={partner_client.oib ? partner_client.oib : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "oib", value })}
                  />
                </FormControl>
              </div>

              <div className="form-control-grid">
                <AutocompleteSelect
                  label="Grad"
                  error={error("/city_id")}
                  errorMsg={errorMsg("/city_id")}
                  defaultValue={partner_client.city_id || ""}
                  autocompleteHandler={value => {
                    this.setState({
                      city: listCity.find(city => city.id === value) || this.state.city,
                    });
                    return onChangePartnerField({ key: "city_id", value });
                  }}
                  onMenuScrollToBottom={this.handleScroller}
                  isLoading={this.state.fetchingMoreCities}
                  dataSource={citiesToShow}
                  placeholder="Odabir grada"
                  inputProps={{
                    onInputChange: this.handleAutocompleteInputChangeDebounced,
                  }}
                />
              </div>
              <div className="form-control-grid">
                <AutocompleteSelect
                  label="Jezik"
                  error={error("/language_id")}
                  errorMsg={errorMsg("/language_id")}
                  defaultValue={partner_client.language_id || ""}
                  autocompleteHandler={value => onChangePartnerField({ key: "language_id", value })}
                  dataSource={
                    listLanguage &&
                    listLanguage.map(language => ({
                      value: language.id,
                      label: language.desc,
                    }))
                  }
                  placeholder="Odabir jezika"
                />
              </div>
              <div className="form-control-grid">
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="languages">
                    Jezici vodiča
                  </InputLabel>
                  <Input
                    id="languages"
                    name="languages"
                    value={partner_client.languages ? partner_client.languages : ""}
                    onChange={({ target: { value } }) => onChangePartnerField({ key: "languages", value })}
                  />
                </FormControl>
              </div>

              {/* partner activities */}
              <Grid item xs={12}>
                <PartnerActivities
                  partner={partner_client}
                  addPartnerActivity={addPartnerActivity}
                  deletePartnerActivity={deletePartnerActivity}
                  updatePartnerActivityPrice={updatePartnerActivityPrice}
                />
              </Grid>

              <div>
                {partner_client.contacts.length ? (
                  <Fragment>
                    <br />
                    <h3>Lista kontakta</h3>
                    <br />
                  </Fragment>
                ) : null}
                <Grid container spacing={24}>
                  {partner_client.contacts &&
                    partner_client.contacts.map(contact => {
                      if (contact.id === contactToEdit.id) {
                        return (
                          <EditParnerClientContact
                            key={contact.id}
                            contact={contactToEdit}
                            removeContact={removeContact}
                            onChangeContact={this.changePartnerContactField}
                            cancelChanges={this.cancelContactChanges}
                            saveChanges={this.saveContactChanges}
                          />
                        );
                      }
                      return (
                        <PartnerClientContactShow
                          key={contact.id}
                          contact={contact}
                          setContactToEdit={this.setContactToEdit}
                          removeContact={removeContact}
                        />
                      );
                    })}
                </Grid>
                <Grid container spacing={24}>
                  <CreateParnerClientContact
                    contact={contact_data}
                    addContact={addContact}
                    onChangeContact={changeCreateContact}
                  />
                </Grid>
              </div>
            </div>
            <div>
              {partner_client.services.length ? (
                <Fragment>
                  <br />
                  <h3>Lista usluga klijenta</h3>
                  <br />
                </Fragment>
              ) : null}
              <Grid container spacing={24}>
                {partner_client.services &&
                  partner_client.services.map(service => {
                    if (service.id === serviceToEdit.id) {
                      return (
                        <EditPartnerClientServiceForm
                          key={service.id}
                          service={serviceToEdit}
                          listLanguage={listLanguage}
                          changePartnerServiceField={this.changePartnerServiceField}
                          changePartnerServiceTranslationField={this.changePartnerServiceTranslationField}
                          saveChanges={this.saveServiceChanges}
                          removeService={removeService}
                          cancelChanges={this.cancelServiceChanges}
                          listPartnerClientServiceType={listPartnerClientServiceType}
                        />
                      );
                    }
                    return (
                      <PartnerClientServiceShow
                        key={service.id}
                        service={service}
                        listLanguage={listLanguage}
                        setServiceForEdit={this.setServiceForEdit}
                        removeService={removeService}
                        listPartnerClientServiceType={listPartnerClientServiceType}
                      />
                    );
                  })}
              </Grid>
            </div>
            <div style={{ margin: "34px 0px" }}>
              <CreatePartnerClientServiceForm
                listLanguage={listLanguage}
                setupCreateServiceData={setupCreateServiceData}
                service={service_data}
                changeCreateServiceTranslation={changeCreateServiceTranslation}
                changeCreateService={changeCreateService}
                listPartnerClientServiceType={listPartnerClientServiceType}
                changeTranslationField={changeCreateServiceTranslation}
                addService={addService}
              />
            </div>
            <br />
            <Button variant="contained" onClick={submit}>
              {submitTitle}
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" onClick={cancelToggle}>
              Poništi
            </Button>
          </div>
        )}
      </FormValidator>
    );
  }
}

export default compose(
  graphql(listCities, {
    name: "listCitiesQuery",
    options: {
      fetchPolicy: "network-only",
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
        },
      },
    },
  }),
  graphql(listPartnerClientServiceType, {
    name: "clientServiceTypes",
    options: {
      fetchPolicy: "network-only",
    },
  }),
  graphql(listPartnerClientType, {
    name: "listPartnerClientTypes",
    options: {
      fetchPolicy: "network-only",
    },
  }),
  graphql(updateContact, { name: "updateContact" }),
  graphql(updatePartnerClientService, { name: "updatePartnerClientService" }),
)(withRouter(PartnerClientForm));
