import React from "react";

import { withRouter } from "react-router-dom";

import RaisedButton from "material-ui/RaisedButton";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import {
  blue500,
  grey800,
  grey900,
  fullWhite
} from "material-ui/styles/colors";
import { connect } from "react-redux";

class ContractToolbar extends React.Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openInNewWindow = () => {
    const windowHeight = window.innerHeight;

    localStorage.setItem("preview", JSON.stringify(this.props.preview));

    window.open(
      `/ugovor/pregled/${this.props.match.params.id}`,
      "_blank",
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=793.7007874,height=${windowHeight}`
    );
  };

  render() {
    return (
      <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
        <ToolbarGroup>
          <ToolbarTitle
            text={this.props.title}
            style={{
              color: grey900
            }}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <RaisedButton
            label="Pregled"
            backgroundColor={blue500}
            labelColor={fullWhite}
            onClick={this.openInNewWindow}
          />

          <RaisedButton
            onClick={this.props.onSave}
            label="Spremi"
            backgroundColor={grey800}
            labelColor={fullWhite}
          />
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

const mapStateToProps = ({ offer }) => ({ preview: offer.offer });

export default connect(mapStateToProps)(withRouter(ContractToolbar));
