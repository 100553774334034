import { useEffect } from "react";

// libs
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  setGuideScheduleAnnouncementPartners,
  setGuideScheduleConstants,
} from "src/views/Guides/guidesSlice";

// graphql
import { ANNOUNCEMENT_PARTNERS } from "src/graphql/query/announcement";

/**
 * useAnnouncementContainer is entry point of data for AnnouncementContainer
 */
export default function useAnnouncementContainer() {
  // context
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  // fetch announcement partners
  const { data, loading: loadingAnnouncementPartners } = useQuery(ANNOUNCEMENT_PARTNERS, {
    variables: {
      program_id: get(guidePayload, "programId"),
      announcementStatusName: "AnnouncementStatus",
      voucherStatusName: "VoucherStatus",
    },
    skip: !guidePayload,
  });

  useEffect(() => {
    if (data) {
      // set announcement partners
      dispatch(
        setGuideScheduleAnnouncementPartners({
          supplierPartners: get(data, "getAnnouncementPartnersAndHotels.suppliers_partners", []),
          allGuides: get(data, "getAnnouncementPartnersAndHotels.all_guides", []),
          guideLayoutComment: get(data, "getAnnouncementPartnersAndHotels.guide_layout_comment", ""),

          programId: guidePayload.programId,
        }),
      );

      // set announcement status type constants
      dispatch(
        setGuideScheduleConstants({
          announcementStatusTypes: get(data, "announcementStatusTypes.enumValues"),
          voucherStatusTypes: get(data, "voucherStatusTypes.enumValues"),
        }),
      );
    }
  }, [data, dispatch, guidePayload]);

  return { loadingAnnouncementPartners };
}
