import React, { Component } from "react";
import { FormControl, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateTax extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`sojourn_tax_content`).length) {
      autosize(document.getElementsByName(`sojourn_tax_content`)[0]);
    }
  }

  render() {
    const { isNotDraft } = this.props;

    return (
      <div className="form-unit-double">
        <div className="field-label ">
          <label className="label">Boravišna pristrojba</label>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="sojourn_tax_value">Boravišna pristrojba</InputLabel>
            <Input
              id="sojourn_tax_value"
              className="template-input"
              inputProps={{
                name: "sojourn_tax_value",
              }}
              value={this.props.sojourn_tax_value}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
          </FormControl>

          <div>
            <TextArea
              inputProps={{
                name: "sojourn_tax_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.sojourn_tax_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TemplateTax;
