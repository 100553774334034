// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectAgencyLocalProgramAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useAgencyLocalProgramAnnouncement is entry point of data for agency local program announcement form component
 */
export default function useAgencyLocalProgramAnnouncement() {
  // redux state
  const agencyLocalProgramAnnouncement = useSelector(selectAgencyLocalProgramAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updateAgencyLocalProgramAnnouncement, { loading }] = useMutation(UPDATE_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdateAgencyLocalProgramAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updateAgencyLocalProgramAnnouncement({
      variables: {
        input: {
          id: agencyLocalProgramAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateAgencyLocalProgramAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ agencyLocalProgramAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    agencyLocalProgramAnnouncement,
    handleOnUpdateAgencyLocalProgramAnnouncement,
    loading,
  };
}
