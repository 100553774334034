import gql from "graphql-tag";

// fragments
import { guidesFragment } from "../operativeFragments";

// update columns for guides
export const UPDATE_COLUMNS = gql`
  mutation($datagrid_filter_id: ID!, $active: Boolean) {
    setUserDatagridFilter(datagrid_filter_id: $datagrid_filter_id, active: $active) {
      id
      desc
      column
      active
      datagrid_filter_type {
        desc
      }
    }
  }
`;

// program day activities
export const DELETE_PROGRAM_DAY_ACTIVITY = gql`
  mutation($program_day_content_id: ID!) {
    deleteActivityFromGuideLayout(program_day_content_id: $program_day_content_id)
  }
`;

// update program day content
export const UPDATE_PROGRAM_DAY_CONTENT = gql`
  mutation($input: UpdateProgramDayContentAndGuideCalendar) {
    UpdateProgramDayContentAndGuideCalendarFields(input: $input) {
      program_day_content_id
      activity_id
      partner_client_id
      guide_id
      is_active

      guides {
        ...Guides
      }
    }
  }
  ${guidesFragment}
`;

// delete guide activity
export const DELETE_PARTNER_GUIDE_AND_ACTIVITY = gql`
  mutation($program_day_content_id: ID!) {
    deletePartnerGuideAndActivity(program_day_content_id: $program_day_content_id) {
      program_day_content_id
      guides {
        ...Guides
      }
    }
  }
  ${guidesFragment}
`;

// update program day title
export const UPDATE_PROGRAM_DAY_TITLE = gql`
  mutation($input: UpdateProgramDayTitle) {
    UpdateProgramDayTitleField(input: $input) {
      program_day_id
      title
    }
  }
`;

// update guide extra fields
export const UPDATE_GUIDE_EXTRA_FIELDS = gql`
  mutation($input: UpdateGuideLayoutExtraFields!) {
    updateGuideLayoutExtraFields(input: $input) {
      program_id
      guide_services
      guide_notices
      guide_layout_comment
      group_operativa_status
      transport
      catalog
      payment
    }
  }
`;

// update theme name
export const UPDATE_PROGRAM_THEME_NAME = gql`
  mutation($input: ThemeNameInputType) {
    updateThemeName(input: $input) {
      offer_contract_document_id
      theme_name
    }
  }
`;
