import React, { Component } from "react";

import { connect } from "react-redux";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { sortContigentsByTypologyPriorityWithoutListEstablishment } from "../../../../utility/establishment";

import { dateDiff, formatDate } from "../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import { config } from "../../../../utility/globals";
import { withRouter } from "react-router-dom";
import CommentTextArea from "../../../../components/UI/CommentTextArea";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class AlotmanTerminsList extends Component {
  state = {
    terminToEdit: null,
    oldTermin: null,
    contigentToEdit: null,
    blockedDaysMessage: null,
  };

  dataToShow = () => {
    return this.props.termins.map(termin => {
      const { contigents } = termin;
      let establishment_name;

      const contigents_list = !this.props.language_id
        ? "Odaberite jezik prije prikaza kontigenata"
        : sortContigentsByTypologyPriorityWithoutListEstablishment(contigents).map((contigent, index) => {
            establishment_name = contigent.typology.establishment.name;

            const typology_translated = contigent.typology.typology_translations.find(
              translation => translation.language_id === this.props.language_id,
            );

            return (
              <div key={index}>
                {contigent.typologies_sum +
                  " / " +
                  (contigent.typology ? `${typology_translated.name} [${contigent.typology.persons_capacity}]` : "")}
              </div>
            );
          });

      return {
        ...termin,
        from: formatDate(termin.from),
        to: formatDate(termin.to),
        diff: dateDiff(termin.from, termin.to, "day"),
        contigents_list,
        establishment_name,
      };
    });
  };

  render() {
    const { offer_contract_document_type_id } = this.props;

    if (config.offer_type[offer_contract_document_type_id.toString()] === "Tour") {
      return null;
    }

    return (
      <div className="form-unit-double">
        <div className="field-label is-autocomplete">
          <label className="label">Termin</label>
        </div>
        <div>
          <Paper elevation={1}>
            <ReactTableCustom
              data={this.dataToShow()}
              columns={[
                {
                  Header: "TERMINI",
                  columns: [
                    {
                      Header: "Od",
                      accessor: "from",
                    },
                    {
                      Header: "Do",
                      accessor: "to",
                    },
                    {
                      Header: "Dana",
                      accessor: "diff",
                      width: 70,
                    },
                    {
                      Header: "Kontigent",
                      id: "contigents_list",
                      accessor: d => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0 auto",
                          }}
                        >
                          {d.contigents_list}
                        </div>
                      ),
                      width: 150,
                    },
                    {
                      Header: "Smještaj",
                      accessor: "establishment_name",
                    },
                  ],
                },
              ]}
              innerProps={{
                pageSize: this.dataToShow().length,
                showPagination: false,
                sortable: false,
                filterable: false,
                filterAll: false,
                expanded: this.state.expanded,
                onExpandedChange: this.openToEdit,
              }}
            />
          </Paper>
          <br />
          <CommentTextArea name="release_day" defaultValue={this.props.release_day} label="Release day" />
          <br />

          <CommentTextArea
            inputProps={{ disabled: true }}
            name="comment_term_period"
            defaultValue={this.props.comment_term_period}
            label="Komentar termina"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    termin_periods,
    offer_contract_document_type_id,
    language_id,
    comment_term_period,
    release_day,
  } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termins: termin_periods,
    create_subtermin,
    edit_subtermin,
    language_id,
    offer_contract_document_type_id,
    comment_term_period,
    release_day,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(AlotmanTerminsList)));
