import React, { Component } from "react";
import int from "../intLang";

class PreviewGuest extends Component {
  render() {
    const {
      guest_name,
      contract: {
        offer_contract_document: { language_id },
      },
    } = this.props.voucher;

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int[language_id].guest}</h4>
        </div>
        <div disabled id="guest_name" className="preview-content-div preview-textarea">
          {guest_name}
        </div>
      </div>
    );
  }
}

export default PreviewGuest;
