import React from "react";

// hooks
import useGuideService from "./hooks/useGuideService";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import GuideServiceContainer from "./components/Container";

// constants
import { TITLE_GUIDE_SERVICE } from "src/utility/labels";

/**
 * Guide service drawer is used for showing data for guide service and its comment
 */
export default function GuideService() {
  const {
    // variables
    isDrawerOpen,
    loading,
    // functions
    handleOnGuideServiceChange,
    onCloseDrawer,
  } = useGuideService();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isSaveVisible={true}
      isLoading={loading}
      onSave={handleOnGuideServiceChange}
      title={TITLE_GUIDE_SERVICE}
      customWidth="85%"
    >
      <GuideServiceContainer />
    </DrawerWrapper>
  );
}
