import gql from "graphql-tag";

/**
 * administration fragments
 */
export const templateAnnouncementFragment = gql`
  fragment AnnouncementLabelTranslation on AnnouncementLabelTranslation {
    id
    ann_hotel_guide_title {
      initial_term
      translation
      placeholder
    }
    ann_hotel_guide_intro {
      initial_term
      translation
      placeholder
    }
    ann_hotel_no_guide_title {
      initial_term
      translation
      placeholder
    }
    ann_hotel_no_guide_intro {
      initial_term
      translation
      placeholder
    }
    ann_guide_title {
      initial_term
      translation
      placeholder
    }
    ann_guide_intro {
      initial_term
      translation
      placeholder
    }
    ann_guide_tour_title {
      initial_term
      translation
      placeholder
    }
    ann_guide_tour_intro {
      initial_term
      translation
      placeholder
    }
    ann_guide_local_title {
      initial_term
      translation
      placeholder
    }
    ann_guide_local_intro {
      initial_term
      translation
      placeholder
    }
    ann_agency_title {
      initial_term
      translation
      placeholder
    }
    ann_agency_intro {
      initial_term
      translation
      placeholder
    }
    ann_agency_tour_title {
      initial_term
      translation
      placeholder
    }
    ann_agency_tour_intro {
      initial_term
      translation
      placeholder
    }
    ann_agency_local_title {
      initial_term
      translation
      placeholder
    }
    ann_agency_local_intro {
      initial_term
      translation
      placeholder
    }
    ann_agency_local_program_title {
      initial_term
      translation
      placeholder
    }
    ann_agency_local_program_intro {
      initial_term
      translation
      placeholder
    }
    ann_supplier_title {
      initial_term
      translation
      placeholder
    }
    ann_supplier_intro {
      initial_term
      translation
      placeholder
    }
    ann_phrase_title {
      initial_term
      translation
      placeholder
    }
    ann_phrase_intro {
      initial_term
      translation
      placeholder
    }
    ann_group_label {
      initial_term
      translation
      placeholder
    }
    ann_termin_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_name_category_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_address_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_check_in_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_check_out_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_breakfast_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_dinner_label {
      initial_term
      translation
      placeholder
    }
    ann_olivari_guide_group_label {
      initial_term
      translation
      placeholder
    }
    ann_voucher_acc_label {
      initial_term
      translation
      placeholder
    }
    ann_guide_acc_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_includes_label {
      initial_term
      translation
      placeholder
    }
    ann_hotel_dinner_drink_label {
      initial_term
      translation
      placeholder
    }
    ann_bus_parking_label {
      initial_term
      translation
      placeholder
    }
    ann_important_notice_label {
      initial_term
      translation
      placeholder
    }
    ann_rooming_list_label {
      initial_term
      translation
      placeholder
    }
    ann_rooming_list_typology_label {
      initial_term
      translation
      placeholder
    }
    ann_rooming_list_contigent_label {
      initial_term
      translation
      placeholder
    }
    ann_driver_contact_label {
      initial_term
      translation
      placeholder
    }
    ann_program_label {
      initial_term
      translation
      placeholder
    }
    ann_invoice_warrant_label {
      initial_term
      translation
      placeholder
    }
    ann_nr_of_persons_label {
      initial_term
      translation
      placeholder
    }
    ann_group_treffpunkt_label {
      initial_term
      translation
      placeholder
    }
    ann_service_favor_label {
      initial_term
      translation
      placeholder
    }
    ann_payment_label {
      initial_term
      translation
      placeholder
    }
    ann_date_label {
      initial_term
      translation
      placeholder
    }
    ann_time_label {
      initial_term
      translation
      placeholder
    }
    ann_language_label {
      initial_term
      translation
      placeholder
    }
    ann_phrase_text_label {
      initial_term
      translation
      placeholder
    }
    ann_footer_message_1_label {
      initial_term
      translation
      placeholder
    }
    ann_footer_message_2_label {
      initial_term
      translation
      placeholder
    }
    language_id
    language_desc
    language_code
  }
`;
