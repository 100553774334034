import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";

import { createService } from "../../../../graphql/mutation/administration";

import ServiceForm from "./ServiceForm";
import { listLanguage as fetchLanguages } from "../../../../graphql/query/administration";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class CreateService extends React.Component {
  state = {
    service: {
      service_type_id: "",
      service_translations: [
        {
          name: "",
          language_id: "",
        },
      ],
    },
  };

  componentDidMount = async () => {
    const languages = await this.props.client.query({
      query: fetchLanguages,
    });
    const {
      data: { listLanguage },
    } = languages;

    this.setState(prev => ({
      ...prev,
      service: {
        ...prev.service,
        service_translations: listLanguage.map(language => ({
          name: "",
          language_id: language.id,
        })),
      },
    }));
  };

  changeFieldData = obj => {
    this.setState(prev => ({
      ...prev,
      service: {
        ...prev.service,
        [obj.key]: obj.value,
      },
    }));
  };

  changeTranslationFieldData = language_id => ({ target: { value } }) => {
    this.setState(prev => ({
      ...prev,
      service: {
        ...prev.service,
        service_translations: prev.service.service_translations.map(translation => {
          if (translation.language_id === language_id) {
            return {
              ...translation,
              name: value,
            };
          }
          return translation;
        }),
      },
    }));
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state.service,
        },
      });

      toastr.success("Usluga uspješno kreirana");
      this.props.cancelToggle();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title="Kreiranje nove uključene usluge" onBackPressed={this.props.cancelToggle} />
        <ServiceForm
          onSubmit={this.submit}
          service={this.state.service}
          cancelToggle={this.props.cancelToggle}
          onChangeField={this.changeFieldData}
          changeTranslationField={this.changeTranslationFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(createService)(withApollo(CreateService));
