import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config, removeDuplicateObjects } from "../../../../utility/globals";
import PriceStructureItem from "./PriceStructureItem";

class PriceStructureStandard extends Component {
  render() {
    const { document_code, isNotDraft } = this.props;

    return (
      config.offer_type[this.props.type_id] !== "Tour" &&
      this.props.termins &&
      this.props.termins.map((termin, index) => {
        const typologies = termin.contigents.map(({ typology }) => ({
          id: typology.typology_group_id,
          persons_capacity: typology.persons_capacity,
          code: `${typology.typology_group && typology.typology_group.name} [${typology.persons_capacity}]`,
        }));

        const noDuplicatesTypologies = removeDuplicateObjects(typologies);

        const { price_structure } = termin;
        const price_typology = JSON.parse(price_structure.price_typology);

        return (
          <PriceStructureItem
            key={termin.id}
            index={index}
            price_typology={price_typology}
            document_code={document_code}
            isNotDraft={isNotDraft}
            termin={termin}
            noDuplicatesTypologies={noDuplicatesTypologies}
            listPriceStructureType={this.props.listPriceStructureType}
          />
        );
      })
    );
  }
}

const mapStateToProps = state => {
  const {
    termin_periods,
    language_id,
    offer_contract_document_type_id,
    document_code,
  } = state.offer.offer.offer_contract_document;
  return {
    termins: termin_periods,
    language_id,
    type_id: offer_contract_document_type_id,
    document_code,
  };
};

export default connect(mapStateToProps)(withRouter(PriceStructureStandard));
