import React from "react";

// ui
import { Button, Paper } from "@material-ui/core";
import RaisedButton from "material-ui/RaisedButton";
import { blue500, fullWhite, grey800, grey900 } from "material-ui/styles/colors";
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import ClearIcon from "@material-ui/icons/Clear";

// libs
import ReactToPrint from "react-to-print";

// hooks
import useToolbar from "./hooks";
// constants
import {
  LABEL_COLUMNS,
  LABEL_PRINT,
  TITLE_TOOLBAR_GUIDE,
  LABEL_EXPORT_EXCEL,
  GUIDE_EXCEL_FILENAME,
  LABEL_RESET_FILTERS,
} from "src/utility/labels";
import { guidesSeasonSpring, guidesSeasonAutumn, guidesSeasonChristmassAndNy } from "src/utility/constants";

// components
import GridFilter from "./components/Filters/Grid";
import YearFilter from "./components/Filters/Year";

// excel files
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

// print guide schedule style
const printGuidesStyle = `
@media print{
  @page {
    size: landscape;
  }

  .ReactTable{
    width:270 mm
  }

  .ReactTable .-pagination{
    height:50px
  }

  .ReactTable .rt-tbody .rt-tr-group{
    height: fit-content;
  }

  .area-to-print{
    display: none !important;
  }                      
 

  .ReactTable {
    font-size: 6pt;
  }

  .print-span-container {
    width: 100%;
    text-align: center;
  }

  .guides-select {
    display: none;
  }

  .hideOnPrint {
    display: none !important;
  }

  .select-print {
    display: inline !important;
    text-align: center;
  }


}`;

export default function ToolbarView({ contentToPrint }) {
  const {
    currentDate,
    excelExportDataSet,
    guidesSeasonSelectedID,
    handleOnColumnsClick,
    handleOnFilterResetClick,
    handleOnSeasonSelect,
    isGuideSchedulesFiltered,
    seasonLabel,
  } = useToolbar();

  // show print area
  const showPrintArea = () => {
    const el = document.getElementById("area-to-print");
    el.style.display = "block";
  };

  // hide print area
  const hidePrintArea = () => {
    const el = document.getElementById("area-to-print");
    el.style.display = "none";
  };

  // return root
  return (
    <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
      <ToolbarGroup>
        <ToolbarTitle
          text={TITLE_TOOLBAR_GUIDE}
          style={{
            color: grey900,
          }}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <Button
          color="primary"
          style={{ marginRight: 16 }}
          variant="outlined"
          size="small"
          disabled={!isGuideSchedulesFiltered}
          onClick={handleOnFilterResetClick}
        >
          <ClearIcon size={16} />
          {LABEL_RESET_FILTERS}
        </Button>

        <div onMouseEnter={showPrintArea} onMouseLeave={hidePrintArea}>
          <ReactToPrint
            content={() => contentToPrint.current}
            pageStyle={printGuidesStyle}
            trigger={() => <RaisedButton label={LABEL_PRINT} backgroundColor={blue500} labelColor={fullWhite} />}
          />
        </div>

        <div style={{ marginLeft: 20 }}>
          <ExcelFile
            filename={`${GUIDE_EXCEL_FILENAME}_${currentDate}`}
            element={
              <RaisedButton
                label={LABEL_EXPORT_EXCEL}
                backgroundColor={grey800}
                labelColor={fullWhite}
                disabled={!excelExportDataSet}
              />
            }
          >
            <ExcelSheet dataSet={excelExportDataSet} name={seasonLabel} />
          </ExcelFile>
        </div>
        <RaisedButton
          label={LABEL_COLUMNS}
          backgroundColor={grey800}
          labelColor={fullWhite}
          onClick={handleOnColumnsClick}
        />

        <GridFilter />

        <YearFilter />

        <Paper style={{ marginLeft: "5px", height: "36px" }}>
          <select
            style={{ height: "inherit" }}
            className="booking-select "
            value={guidesSeasonSelectedID}
            onChange={event => {
              return handleOnSeasonSelect(event.target.value);
            }}
          >
            <option value={guidesSeasonSpring.id}>{guidesSeasonSpring.label}</option>
            <option value={guidesSeasonAutumn.id}>{guidesSeasonAutumn.label}</option>
            <option value={guidesSeasonChristmassAndNy.id}>{guidesSeasonChristmassAndNy.label}</option>
          </select>
        </Paper>
      </ToolbarGroup>
    </Toolbar>
  );
}
