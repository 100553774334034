import React from "react";
import { useTableLocalStorage } from "./useTableLocalStorage";

export const withLocalStorageHOC = Component => {
  return props => {
    const { tableState, setTableState } = useTableLocalStorage(props.tableName);

    return <Component tableState={tableState} setTableState={setTableState} {...props} />;
  };
};
