import gql from "graphql-tag";

/**
 * voucher mutations
 */

// create voucher
export const CREATE_VOUCHER = gql`
  mutation($input: CreateVoucher) {
    createVoucher(input: $input) {
      id
      voucher_title
      language_desc
      date
      announcement_type
      voucher_unique_nr
    }
  }
`;
