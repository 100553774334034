import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";

import { updateCity } from "../../../../graphql/mutation/administration";
import { fetchCity } from "../../../../graphql/query/administration";

import CityForm from "./CityForm";

import { prepareForSending } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class EditCity extends React.Component {
  state = {
    name: "",
    postal_code: "",
    area_code: "",
    region_id: "",
  };

  async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: fetchCity,
        variables: {
          id: this.props.city.id,
        },
      });

      const { getCity } = response.data;

      this.setState({
        ...prepareForSending(getCity, ["region", "__typename"]),
      });
    } catch (error) {}
  }

  changeFieldData = obj => {
    this.setState({
      [obj.key]: obj.value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      this.props.closeEdit();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title={`Ažuriranje grada ${this.props.city.id}`} onBackPressed={this.props.closeEdit} />
        <CityForm
          onSubmit={this.submit}
          city={this.state}
          cancelToggle={this.props.closeEdit}
          onChangeField={this.changeFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(updateCity)(withApollo(EditCity));
