import React, { Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import UserForm from "./UserForm";

import { getUser } from "../../../graphql/query/administration";
import { updateUser, deleteUserSignature } from "../../../graphql/mutation/administration";
import { prepareForSending } from "../../../utility/prepare";
import { AdministrationTabs } from "../AdministrationTabs";
import { removeRoleFromUser, appendRoleToUser, userSignatureUpload } from "../../../graphql/mutation/user";
import FormValidator from "../../../components/UI/FormValidator";

import toastr from "toastr";
import { difference } from "../../../utility/globals";
import TableTitle from "src/components/UI/TableTitle";

class EditUser extends React.Component {
  static schema = {
    properties: {
      first_name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      last_name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      username: {
        type: "string",
        isNotEmpty: true,
        minLength: 1,
        errorMessage: "Polje mora biti ispunjeno",
      },
      role_ids: {
        type: "array",
        minItems: 1,
        errorMessage: "Potrebno je odabrati barem jednu rolu",
      },
    },
  };

  state = {
    user: {
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      signature_url: "",
      role_ids: [],
    },
    user_clone: {},
    selectedFiles: "",
  };

  async componentDidMount() {
    this.fetchUser();
  }

  async fetchUser() {
    const response = await this.props.client.query({
      query: getUser,
      variables: { id: this.props.match.params.id },
      fetchPolicy: "network-only",
    });

    if (response.data.getUser) {
      // Doing this way because server expects array of strings in create, and returns array of objects in edit mode
      const role_ids = response.data.getUser.roles.map(role => ({
        id: role.id,
        role: role.id,
      }));

      this.setState({
        user: {
          ...response.data.getUser,
          role_ids,
        },
        user_clone: {
          ...response.data.getUser,
        },
      });
    }
  }

  onDrop = files => {
    this.setState(
      {
        selectedFiles: files,
      },
      () => this.uploadAttachment(this.state.selectedFiles),
    );
  };

  deleteSignature = async user_id => {
    try {
      await this.props.deleteUploadedDocument({
        variables: {
          user_id,
        },
      });

      this.fetchUser();
      toastr.success("Datoteka uspješno izbrisana");
    } catch (error) {}
  };

  uploadAttachment = async selectedFiles => {
    if (selectedFiles) {
      if (selectedFiles && selectedFiles.length) {
        try {
          selectedFiles.forEach(async file => {
            const { id } = this.state.user;

            const res = await this.props.uploadDocument({
              variables: {
                user_id: id,
                file,
              },
            });

            const { userSignatureUpload } = res.data;

            this.setState(oldState => ({
              user: {
                ...oldState.user,
                signature_id: userSignatureUpload.id,
                signature_filename: userSignatureUpload.filename,
                signature_url: userSignatureUpload.path,
              },
            }));
          });

          this.setState({ selectedFiles: "" });
          toastr.success("Datoteka uspješno uploadana");
        } catch (error) {}
      }
    }
  };

  changeUserData = obj => {
    this.setState(prevState => {
      return {
        user: {
          ...prevState.user,
          [obj.key]: obj.value,
        },
      };
    });
  };

  appendRoleToUser = async role_id => {
    try {
      const { user } = this.state;

      await this.props.appendRoleToUser({
        variables: {
          user_id: user.id,
          role_id: role_id,
        },
      });

      const user_role_ids = [...user.role_ids];

      user_role_ids.push({ id: role_id, role_id });

      this.setState(prevState => {
        return {
          user: {
            ...prevState.user,
            role_ids: user_role_ids,
          },
        };
      });

      toastr.success("Rola uspješno dodana");
    } catch (error) {}
  };

  removeRoleFromUser = async role_id => {
    try {
      const {
        user,
        user: { role_ids },
      } = this.state;

      await this.props.removeRoleFromUser({
        variables: {
          user_id: user.id,
          role_id,
        },
      });

      const role_ids_clone = role_ids.filter(role => role.id !== role_id);

      toastr.success("Rola uspješno uklonjena");

      this.setState(prevState => {
        return {
          user: {
            ...prevState.user,
            role_ids: [...role_ids_clone],
          },
        };
      });
    } catch (error) {}
  };

  submit = async () => {
    try {
      const userToSend = {
        ...prepareForSending(this.state.user, [
          "__typename",
          "id",
          "role_ids",
          "roles",
          "signature_id",
          "signature_filename",
          "signature_url",
          !this.state.user.password ? "" : this.state.user.password,
        ]),
      };

      const userClone = {
        ...prepareForSending(this.state.user_clone, [
          "__typename",
          "id",
          "role_ids",
          "roles",
          "signature_id",
          "signature_filename",
          "signature_url",
        ]),
      };

      const userDifference = difference(userToSend, userClone);

      if (Object.keys(userDifference).length) {
        await this.props.mutate({
          variables: {
            id: this.props.match.params.id,
            patch: userDifference,
          },
        });
      }

      this.props.history.push("/administracija/zaposlenik/");
    } catch (error) {}
  };

  render() {
    if (!this.state.user) {
      return <div>Dohvaćam zaposlenika . . . </div>;
    }
    return (
      <Fragment>
        <TableTitle
          title="Ažuriranje zaposlenika"
          onBackPressed={() => {
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/zaposlenik/");
            } else {
              this.props.history.goBack();
            }
          }}
        />
        <FormValidator schema={EditUser.schema} submit={this.submit} data={this.state.user}>
          {({ error, errorMsg, submit }) => (
            <UserForm
              user={this.state.user}
              onSubmit={submit}
              error={error}
              errorMsg={errorMsg}
              removeRole={this.removeRoleFromUser}
              addRole={this.appendRoleToUser}
              changeUserData={this.changeUserData}
              onDrop={this.onDrop}
              files={this.state.selectedFiles}
              deleteUploadedFile={this.deleteSignature}
              edit
            />
          )}
        </FormValidator>
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(
    graphql(updateUser),
    graphql(userSignatureUpload, { name: "uploadDocument" }),
    graphql(deleteUserSignature, { name: "deleteUploadedDocument" }),
    graphql(removeRoleFromUser, { name: "removeRoleFromUser" }),
    graphql(appendRoleToUser, { name: "appendRoleToUser" }),
  )(withApollo(EditUser)),
  "Ažuriranje zaposlenika",
);
