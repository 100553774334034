import React from "react";

import { connect } from "react-redux";

import PriceStructureStandard from "./PriceStructureStandard";
import PriceStructureAdvanced from "./PriceStructureAdvanced";

class PriceStructure extends React.Component {
  render() {
    if (!this.props.termins.length) {
      return null;
    }

    return (
      <React.Fragment>
        {/* Cjenovna struktura */}
        <div className="form-unit-double">
          <div className="field-label is-normal">
            <label className="label">Cjenovna struktura</label>
          </div>
          <div className="cols-2">
            <PriceStructureStandard listPriceStructureType={this.props.listPriceStructureType} isNotDraft />
            <PriceStructureAdvanced listPriceStructureType={this.props.listPriceStructureType} isNotDraft />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { termin_periods } = state.offer.offer.offer_contract_document;
  return {
    termins: termin_periods,
  };
};

export default connect(mapStateToProps)(PriceStructure);
