import gql from "graphql-tag";

// fragments
import { voucherFragment } from "../voucherFragments";

/**
 * voucher queries
 */

// fetch voucher list
export const FETCH_VOUCHER_LIST = gql`
  query($announcement_id: ID!) {
    listAllVouchers(announcement_id: $announcement_id) {
      id
      voucher_title
      language_desc
      date
      announcement_type
      voucher_unique_nr
    }
  }
`;

// get voucher hotel
export const FETCH_VOUCHER = gql`
  query($id: ID!) {
    getVoucher(id: $id) {
      ...VoucherDetails
      voucher_data {
        partner_client_name {
          label
          value
        }
        hotel_label {
          value
        }
        hotel {
          establishment_id
          hotel_name {
            value
          }
        }
        group {
          label
          value
        }
        termin {
          label
          value
        }
        rooming_label {
          value
        }
        rooming {
          contigent_value
          typology_value
        }
        guide_first_name
        guide_last_name
      }
    }
  }
  ${voucherFragment}
`;
