import initialState from "./initialState";

import * as types from "../../actionTypes";

import {
  fetchedDataToEdit,
  changeEstablishment,
  changeEstablishmentChain,
  changeService,
  changeServiceType,
  changeTypology,
  addTypology,
  editTypology,
  removeTypology,
  changeActivity,
  changeActivityType,
  changeUser,
  changePartnerClient,
  saveEditedTypology,
  changeTypologyTranslationField
} from "./administrationReducerfunctions";

export default function(state = initialState, action) {
  switch (action.type) {
    case types.RESET_ADMINISTRATION_DATA:
      return {
        ...initialState
      };

    case types.CHANGE_ESTABLISHMENT_DATA:
      return changeEstablishment(state, action);

    case types.CHANGE_PARTNER_CLIENT_DATA:
      return changePartnerClient(state, action);

    case types.CHANGE_ESTABLISHMENT_CHAIN_DATA:
      return changeEstablishmentChain(state, action);

    case types.ADD_TYPOLOGY:
      return addTypology(state);

    case types.SAVE_EDITED_TYPOLOGY_DATA:
      return saveEditedTypology(state, action.typology);

    case types.CHANGE_CREATING_TYPOLOGY_TRANSLATION_FIELD:
      return changeTypologyTranslationField(
        state,
        action.obj,
        action.language_id
      );

    case types.REMOVE_TYPOLOGY_FROM_ESTABLISHMENT:
      return removeTypology(state, action.id);

    case types.EDIT_TYPOLOGY_DATA:
      return editTypology(state, action.param, action.id);

    case types.CHANGE_TYPOLOGY_DATA:
      return changeTypology(state, action);

    case types.CHANGE_SERVICE_DATA:
      return changeService(state, action);

    case types.CHANGE_SERVICE_TYPE_DATA:
      return changeServiceType(state, action);

    case types.CHANGE_ACTIVITY_DATA:
      return changeActivity(state, action);

    case types.CHANGE_ACTIVITY_TYPE_DATA:
      return changeActivityType(state, action);

    case types.CHANGE_USER_ADMINISTRATION_DATA:
      return changeUser(state, action);

    case types.FETCHED_ADMINISTRATION_DATA_TO_EDIT:
      return fetchedDataToEdit(state, action);

    default:
      return state;
  }
}
