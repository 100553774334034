import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import gql from "graphql-tag";

import TextSmsIcon from "material-ui/svg-icons/communication/textsms";
import Paper from "material-ui/Paper";

import { setAllOffersInitialQueryInput } from "../../../store/actions";

import { allOffers } from "../../../graphql/query/offers";

import EditIcon from "../../../components/UI/EditIcon";
import ReactTableCustom from "../../../components/UI/ReactTable";

import { DEBOUNCE_FETCH_DELAY } from "../../../utility/reactTable";
import { formatDate } from "../../../utility/dates";
import { getNestedObject } from "../../../utility/globals";

import AllOffersToolbar from "./Toolbar";
import CustomDateRangePicker, { DEFAULT_EMPTY_DATE } from "../../../components/UI/CustomDateRangePicker";
import { Tooltip } from "@material-ui/core";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

const OFFER_STATUS_STORNO = 3;

const mutationMsg = gql`
  subscription {
    mutationMsg(section: Offer)
  }
`;

const getPreparedFilters = (filtersSelected, all_offers_filter) => {
  const filtersPrepared =
    filtersSelected && filtersSelected.length
      ? Object.assign(...filtersSelected.map(entry => ({ [entry.id]: entry.value })))
      : {};

  if (filtersPrepared.document_date_validity && filtersPrepared.document_date_validity.includes(DEFAULT_EMPTY_DATE)) {
    delete filtersPrepared.document_date_validity;
  }

  let filtersToSend = {};
  // structure object as necessary
  for (let key in filtersPrepared) {
    switch (key) {
      case "status":
        filtersToSend = {
          ...filtersToSend,
          offer_status: { desc: filtersPrepared[key] },
        };
        break;
      case "offer_type":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            offer_contract_document_type: { desc: filtersPrepared[key] },
          },
        };
        break;
      case "partner_client":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            partner_client: { name: filtersPrepared[key] },
          },
        };
        break;
      case "user":
        filtersToSend = {
          ...filtersToSend,
          last_edit_by_user: { username: filtersPrepared[key] },
        };
        break;
      //case "inquiry_id":
      case "document_date_validity":
        if (filtersPrepared[key].includes(DEFAULT_EMPTY_DATE)) {
          break;
        }
      // falls through (ESlint actually needs this comment too)
      case "document_code":
      case "inquiry_external_id":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            [key]: filtersPrepared[key],
          },
        };
        break;
      default:
        // default case, when key-value pair is added to outermost object
        filtersToSend = {
          ...filtersToSend,
          [key]: filtersPrepared[key],
        };
    }
  }

  // add type
  switch (all_offers_filter) {
    case "0":
      // aktivno - default
      filtersToSend = {
        ...filtersToSend,
        archived_status: "false",
      };
      break;
    case "1":
      // arhivirano
      filtersToSend = {
        ...filtersToSend,
        archived_status: "true",
      };
      break;
    case "2":
      //storno
      filtersToSend = {
        ...filtersToSend,
        offer_status_id: OFFER_STATUS_STORNO,
      };
      break;
    default:
      break;
  }

  return JSON.stringify(filtersToSend);
};

class AllOffers extends React.Component {
  state = {
    filtersPrepared: {},
    pages: null,
    loading: true,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        Cell: offer => (
          <Link
            className="ReactTableFirstColumnDiv"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            to={`/ponude/edit/${offer.original.id}`}
          >
            {!offer.original.lock_owner && <EditIcon style={{ cursor: "pointer" }} />}

            {offer.original.lock_owner && (
              <Tooltip
                title={`${offer.original.lock_owner.first_name} ${offer.original.lock_owner.last_name}`}
                placement="right"
              >
                <TextSmsIcon
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
              </Tooltip>
            )}
          </Link>
        ),
      },
      {
        Header: "Upit",
        width: 90,
        //accessor: "inquiry_id"
        accessor: "inquiry_external_id",
      },
      {
        Header: "Ponuda",
        width: 90,
        accessor: "external_id",
      },
      {
        Header: "Falš",
        width: 90,
        accessor: "off_key",
      },
      {
        Header: "Agencija",
        accessor: "partner_client",
      },
      {
        Header: "Grupa",
        accessor: "document_code",
      },
      {
        Header: "Vrsta",
        accessor: "offer_type",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Datum opcije ponude",
        accessor: "document_date_validity",
        width: 203,
        Filter: ({ filter, onChange }) => <CustomDateRangePicker filter={filter} onChange={onChange} />,
      },
      {
        Header: "Zadnje ažurirao",
        accessor: "user",
      },
    ],
  };

  _isMounted = false;
  fetchMoreObject = {};

  subscribe = () => {
    if (this._isMounted && this.props.data) {
      this.props.data.subscribeToMore({
        document: mutationMsg,
        updateQuery: async (prev, { subscriptionData }) => {
          if (!subscriptionData) {
            return prev;
          }

          if (this._isMounted) {
            let response;

            // If we have changed filtering or pages ( this.fetchMoreObject is changed in fetchData)
            if (Object.keys(this.fetchMoreObject).length) {
              response = await this.props.data.fetchMore(this.fetchMoreObject);
            } else {
              response = await this.props.data.refetch();
            } // refetch default query

            return {
              ...prev,
              listOffer: [...response.data.listOffer],
            };
          }

          return prev;
        },
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.unsubscribe = this.subscribe();

    // set initial query params so our graphql HOC function does not get called every time on filtering
    const limit = this.props.tableState.pageSizeSelected;
    const offset = this.props.tableState.pageSelected * this.props.tableState.pageSizeSelected;

    this.props.setAllOffersInitialQueryInput(
      limit,
      offset,
      this.props.tableState.filtersSelected,
      this.props.tableState.allOffersFilter || "0",
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  // called when Aktivno/Arhivirano/Storno select changes
  selectDataToShow = value => {
    this.props.setTableState({ ...this.props.tableState, pageSelected: 0, all_offers_filter: value });

    this.setState({ loading: true }, this.applyFiltersAndFetch);
  };

  // react table's function on a change
  fetchData = async state => {
    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    // set new state when ReactTable changes and query with new changes
    this.setState(
      {
        loading: true,
      },
      this.applyFiltersAndFetch,
    );
  };

  filtering = false;
  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });

      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  // our custom function, called whenever react table changes or any of filters not directly in react table
  applyFiltersAndFetch = async () => {
    const all_offers_filter = this.props.tableState.all_offers_filter || "0";
    let filtersToSend = getPreparedFilters(this.props.tableState.filtersSelected, all_offers_filter);

    /* end of filters */

    // Setting new object in fetchMore, so that we could reuse it in subscription
    this.fetchMoreObject = {
      variables: {
        input: {
          paginationLimit: {
            limit: this.props.tableState.pageSizeSelected,
            offset: this.props.tableState.pageSelected * this.props.tableState.pageSizeSelected,
          },
          where: filtersToSend,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (this._isMounted) {
          if (fetchMoreResult && fetchMoreResult.listOffer && fetchMoreResult.listOffer.length) {
            // when we get normal reponse, ceil up page number
            this.setState({
              pages: Math.ceil(fetchMoreResult.listOffer[0].count / this.props.tableState.pageSizeSelected),
              loading: false,
            });
          }
          // when query returns empty array (no results for those filters)
          else {
            this.setState({
              pages: null,
              loading: false,
            });
          }

          return {
            previousResult,
            listOffer: [...fetchMoreResult.listOffer],
          };
        }

        return previousResult;
      },
    };

    if (this._isMounted && this.props.data) {
      await this.props.data.fetchMore(this.fetchMoreObject);
    }
  };

  dataToShow = () => {
    if (this.props.data) {
      const { listOffer } = this.props.data;
      return (listOffer && listOffer.map(offer => this.formatOfferForReactTable(offer))) || [];
    }
  };

  formatOfferForReactTable(offer) {
    return {
      ...offer,
      //inquiry_id: offer.offer_contract_document.inquiry_id,
      inquiry_external_id: offer.offer_contract_document.inquiry_external_id,
      external_id: offer.external_id,
      status: offer.offer_status.desc,
      offer_type: offer.offer_contract_document.offer_contract_document_type.desc,
      partner_client: offer.offer_contract_document.partner_client.name,
      document_code: offer.offer_contract_document.document_code,
      document_date_validity: formatDate(offer.offer_contract_document.document_date_validity),
      user:
        getNestedObject(offer, ["offer_contract_document", "last_edit_by_user", "username"]) +
        ", " +
        formatDate(getNestedObject(offer, ["offer_contract_document", "last_edit_by_user_time"])),
    };
  }

  render() {
    const all_offers_filter = this.props.tableState.all_offers_filter || "0";
    return (
      <React.Fragment>
        <AllOffersToolbar
          {...this.state}
          checked={all_offers_filter}
          selectDataToShow={this.selectDataToShow}
          contentToPrint={() => this.componentRef}
        />

        <Paper zDepth={1}>
          <ReactTableCustom
            ref={el => (this.componentRef = el)}
            data={this.dataToShow()}
            columns={this.state.columns}
            renderCustomPagination={true}
            innerProps={{
              onFetchData: this.fetchStrategy,
              onFilteredChange: this.onFilteredChange,
              onPageChange: page => {
                this.props.setTableState({ ...this.props.tableState, pageSelected: page });
              },

              onPageSizeChange: (pageSize, pageIndex) => {
                this.props.setTableState({
                  ...this.props.tableState,
                  pageSelected: pageIndex,
                  pageSizeSelected: pageSize,
                });
              },

              page: this.props.tableState.pageSelected,
              pageSize: this.props.tableState.pageSizeSelected,
              defaultFiltered: this.props.tableState.filtersSelected,

              loading: this.state.loading,
              pages: this.state.pages,
              manual: true,
              sortable: false,
            }}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { limit, offset, selectedFilters, allOffersFilter } = state.offer;

  return {
    limit,
    offset,
    selectedFilters,
    allOffersFilter,
  };
};

export default withLocalStorageHOC(
  connect(mapStateToProps, { setAllOffersInitialQueryInput })(
    compose(
      graphql(allOffers, {
        skip: ({ limit, offset }) => {
          return !limit && !offset;
        },
        options: ({ limit, offset, selectedFilters, allOffersFilter }) => {
          return {
            fetchPolicy: "network-only",
            variables: {
              // This is default query on component mount
              input: {
                paginationLimit: {
                  limit: limit,
                  offset: offset,
                },
                where: getPreparedFilters(selectedFilters, allOffersFilter),
              },
            },
          };
        },
      }),
    )(AllOffers),
  ),
);
