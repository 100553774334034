import React, { Fragment } from "react";

// components
import TripActivities from "./components/TripsActivities";
import GroupStatus from "./components/GroupStatus";
import Announcement from "./components/Announcement";
import AnnouncementOrder from "./components/AnnouncementOrder";
import LayoutComment from "./components/LayoutComment";
import GuideNotice from "./components/GuideNotice";
import BusTransfer from "./components/BusTransfer";
import GuideService from "./components/GuideService";
import Catalog from "./components/Catalog";
import Payment from "./components/Payment";

export default function AllDrawers() {
  return (
    <Fragment>
      <TripActivities />
      <GroupStatus />
      <Announcement />
      <AnnouncementOrder />
      <LayoutComment />
      <GuideNotice />
      <BusTransfer />
      <GuideService />
      <Catalog />
      <Payment />
    </Fragment>
  );
}
