import { useCallback, useState } from "react";
// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";

// graphql
import { CREATE_PHRASE_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

// redux state
import {
  addPhraseAnnouncement,
  setPhraseCreateDialogOpen,
  selectIsPhraseCreateDialogOpen,
  selectLanguages,
} from "../../../announcementSlice";

/**
 * usePhraseAnnouncementCreate is entry point of data for PhraseAnnouncementCreate component
 */
export default function usePhraseAnnouncementCreate() {
  // redux state
  const isPhraseCreateOpen = useSelector(selectIsPhraseCreateDialogOpen);
  const listLanguage = useSelector(selectLanguages);

  const dispatch = useDispatch();

  // component state
  const [phraseTitle, setPhraseTitle] = useState("");
  const [languageSelected, setLanguageSelected] = useState("");
  const [phraseText, setPhraseText] = useState("");

  // mutations
  const [createPhraseAnnouncement] = useMutation(CREATE_PHRASE_ANNOUNCEMENT);

  const handleOnDialogClose = useCallback(() => {
    setPhraseText("");
    setPhraseTitle("");
    setLanguageSelected(null);

    dispatch(setPhraseCreateDialogOpen(false));
  }, [dispatch]);

  /**
   * on create announcement
   */
  const handleOnCreatePhraseAnnouncement = async () => {
    const createAnnouncementResponse = await createPhraseAnnouncement({
      variables: {
        input: {
          phrase_title: phraseTitle,
          language_id: languageSelected,
          phrase_text: phraseText,
        },
      },
    });

    const announcementUpdatedResponse = get(createAnnouncementResponse, "data.createPhraseAnnouncement");

    if (announcementUpdatedResponse) {
      toastr.success("Uspješno kreirana najava");

      // update phrase announcements in state
      dispatch(addPhraseAnnouncement(announcementUpdatedResponse));

      setLanguageSelected("");
      setPhraseText("");
      // close dialog
      handleOnDialogClose();
    } else {
      toastr.error("Greška prilikom kreiranja najave");
    }
  };

  const getPhraseCreateDisabled = () => {
    return !phraseTitle || !languageSelected;
  };

  return {
    // variables
    isPhraseCreateOpen,
    listLanguage,
    phraseTitle,
    languageSelected,
    phraseText,
    // functions
    getPhraseCreateDisabled,
    handleOnCreatePhraseAnnouncement,
    handleOnDialogClose,
    setPhraseTitle,
    setLanguageSelected,
    setPhraseText,
  };
}
