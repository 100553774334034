import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import { withApollo } from "react-apollo";
import { getOfferLog } from "../../../../graphql/query/offers";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import AuditLogHandler from "./AuditLogHandler";
import OfferForm from "../OfferForm";
//import TablePagination from "@material-ui/core/TablePagination";

class AuditLog extends Component {
  state = {
    log: [],
  };

  _isMounted = false;

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState.log, this.state.log) || this.props.offerId !== nextProps.offerId) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchAuditLog();
  }

  componentDidUpdate() {
    this.fetchAuditLog();
  }

  fetchAuditLog = async () => {
    const { offerId } = this.props;

    if (!offerId) {
      return null;
    }
    try {
      const response = await this.props.client.query({
        query: getOfferLog,
        // should be able to fetch by: table (offer) and offer id. possibly pagination too?
        variables: { id: offerId },
        fetchPolicy: "network-only",
      });

      if (!this._isMounted) {
        return;
      }

      const offerLog = response.data.getOfferLog;

      const mainKeys = [
        "offers",
        "termin_periods",
        "offer_contract_documents",
        "contigents",
        "services",
        "additional_services",
      ];

      // skip this for all
      const subKeysToSkip = ["id", "updated_at", "created_at", "sold", "log_user_id"];

      // only display this termin_periods keys
      const terminPeriodKeys = ["from", "to"];
      const serviceKeys = ["service_id"];

      let log = [];

      for (let key in offerLog) {
        // skip keys that aren't those we are looking for
        if (!mainKeys.includes(key)) {
          continue;
        }

        let counter = 0;
        for (let current of offerLog[key]) {
          // skip if there are no results in this object

          if (!current) {
            continue;
          }

          const before_state = JSON.parse(current.before_state);
          const after_state = JSON.parse(current.after_state);
          const deleted = current.deleted;
          //deleted?

          for (let subKey in before_state) {
            // skip irrelevant changes
            if (key === "termin_periods" && !terminPeriodKeys.includes(subKey)) {
              continue;
            }
            if (key === "services" && !serviceKeys.includes(subKey)) {
              continue;
            }

            // skip fields that didnt change
            if (subKeysToSkip.includes(subKey)) {
              continue;
            }
            if (
              (before_state[subKey] && after_state[subKey]) || typeof before_state[subKey] === "string"
                ? before_state[subKey].toString() === after_state[subKey].toString()
                : true
            ) {
              continue;
            }

            log.push({
              key,
              subKey,
              before: before_state[subKey],
              after: after_state[subKey],
              index: counter,
              deleted: deleted,
              changedField: subKey,
              time: current.created_at,
              user: current.user.username,
            });
          }
          counter++;
        }
      }

      this.setState({ log });
    } catch (error) {}
  };

  render() {
    const { log } = this.state;

    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">Prošlo stanje</TableCell>
              <TableCell padding="dense">Novo stanje</TableCell>
              <TableCell padding="dense">Polje</TableCell>
              <TableCell padding="dense">Korisnik</TableCell>
              <TableCell padding="none">Datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!log || log.length < 1 ? (
              <TableRow>
                <TableCell component="th" colSpan="4">
                  Prethodne izmjene nisu pronađene
                </TableCell>
              </TableRow>
            ) : null}
            {log &&
              log.map((entry, index) => (
                <OfferForm.Consumer key={index}>
                  {({
                    listOfferStatus,
                    listOfferContractDocumentType,
                    listOfferContractDocumentSegment,
                    listLanguage,
                    listServiceType,
                  }) => (
                    <AuditLogHandler
                      entry={entry}
                      listOfferStatus={listOfferStatus}
                      listOfferContractDocumentType={listOfferContractDocumentType}
                      listOfferContractDocumentSegment={listOfferContractDocumentSegment}
                      listLanguage={listLanguage}
                      listServiceType={listServiceType}
                    />
                  )}
                </OfferForm.Consumer>
              ))}
          </TableBody>
        </Table>
        {/*<TablePagination
          component="div"
          count={15}
          rowsPerPage={5}
          page={0}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={() => null}
          onChangeRowsPerPage={() => null}
        />*/}
      </React.Fragment>
    );
  }
}

export default withApollo(AuditLog);
