// general
export const LABEL_PRINT = "Print";
export const LABEL_SAVE = "Spremi";
export const LABEL_RESET_FILTERS = "Izbriši filtere";
export const LABEL_COLUMNS = "Kolone";
export const LABEL_EXPORT_EXCEL = "Excel izvoz";

// filters
export const FILTER_SPRING = "Proljeće";
export const FILTER_AUTUMN = "Jesen";
export const FILTER_CHRISTMASS_AND_NY = "Božić i NG";

// GUIDES
export const TITLE_TOOLBAR_GUIDE = "Rasporedi vodiča";
// columns
export const COLUMN_TITLE_GUIDE_CONTRACT_NO = "Br. ugovora";
export const COLUMN_TITLE_GUIDE_BOOKING_STATUS = "Status bookinga";
export const COLUMN_TITLE_GUIDE_PARTNER = "Partner";
export const COLUMN_TITLE_GUIDE_HOTEL = "Hotel";
export const COLUMN_TITLE_GUIDE_ARRIVAL = "Dolazak";
export const COLUMN_TITLE_GUIDE_DEPARTURE = "Odlazak";
export const COLUMN_TITLE_GUIDE_BOOKING_STATE = "Stanje bookinga";
export const COLUMN_TITLE_GUIDE_TRANSFER = "Olivari bus / Transfer";
export const COLUMN_TITLE_GUIDE_PROGRAM = "Program (Tema / Ime putovanja)";
export const COLUMN_TITLE_GUIDE_SERVICE_GUIDE = "Usluge Vodiča";
export const COLUMN_TITLE_GUIDE_TRIP_ACTIVITY = "Izleti / Aktivnosti";
export const COLUMN_TITLE_GUIDE_NAMES = "Vodič";
export const COLUMN_TITLE_GUIDE_GROUP_STATUS = "Status Grupe";
export const COLUMN_TITLE_GUIDE_LAYOUT_COMMENT = "Opaske / Komentari";
export const COLUMN_TITLE_GUIDE_COMMENT_GUIDE = "Opaska / Vodič";
export const COLUMN_TITLE_GUIDE_ADDITIONAL_FUNCTIONS = "Dodatne Funkcije";
export const COLUMN_TITLE_GUIDE_PAYMENT = "Naplata";
export const COLUMN_TITLE_GUIDE_CATALOG = "Katalog";

// titles
// drawers
export const TITLE_GUIDE_DRAWER_TRIPS = "Izleti / Aktivnosti Vodiča";
export const TITLE_GUIDE_ANNOUNCEMENT = "Najava";
export const TITLE_GUIDE_ANNOUNCEMENT_EDIT = "Ažuriranje najave";
export const TITLE_GUIDE_LAYOUT_COMMENT = "Opaske / komentari";
export const TITLE_GUIDE_GROUP_STATUS = "Status grupe";
export const TITLE_GUIDE_NOTICES = "Opaska vodiča";
export const TITLE_GUIDE_PARTNER_SEARCH = "Pretraga partnera";
export const TITLE_GUIDE_DRAWER_BUS_TRANSFER = "Olivari bus/Transfer";
export const TITLE_ANNOUNCEMENT_PHRASE_EDIT = "Najava Fraze";
export const TITLE_GUIDE_CATALOG = "Katalog";
export const TITLE_GUIDE_PAYMENT = "Naplata";
export const TITLE_GUIDE_SERVICE = "Usluge vodiča";
export const TITLE_GUIDE_ANNOUNCEMENT_ORDER_CREATE = "Kreiranje naloga";

// other
export const TITLE_GUIDE_SUBJECT = "Predmet (tema/ime putovanja)";
export const TITLE_GUIDE_COMMENT = "Komentar/opaska vodič";
export const TITLE_GUIDE_TRIPS = "Izleti / Aktivnosti";
export const TITLE_GUIDE_SHOW_LIST = "Kontakt podaci partnera/vodiča";

// labels
export const LABEL_GUIDE_PARTNER_SEARCH = "Odabir partnera";
export const LABEL_GUIDE_STATUS = "Status";
export const LABEL_GUIDE_STATUS_PARTNER = "Status dobavljača/partnera";
export const LABEL_GUIDE_ANNOUNCEMENT_COMMENT = "Komentar/opaska";
export const LABEL_GUIDE_SERVICES_COMMENT = "Komentar usluge vodiča";
export const LABEL_CATALOG = "Katalog tekst";
export const LABEL_PAYMENT = "Naplata tekst";

// description
export const WARNING_PARTNER_SEARCH_NOT_SELECTED = "Potrebno je odabrati partnera";
export const SUCCESS_PARTNER_SEARCH_SELECT = "Uspješno spremljen partner";

// HINTS
export const HINT_GUIDE_THEME_NAME = "Upišite ime predmeta";
export const HINT_GUIDE_COMMENT = "Upišite komentar vodiča";
// trip activities
export const HINT_GUIDE_PARTNER_SELECT = "Odaberite klijenta";
// guide layout comment
export const HINT_COMMENT = "Upišite komentar";
export const HINT_CATALOG = "Upišite tekst kataloga";
export const HINT_PAYMENT = "Upišite tekst naplate";

// excel export
export const GUIDE_EXCEL_FILENAME = "raspored_vodica";
export const BOOKING_EXCEL_FILENAME = "booking";

// ADMINISTRATION
// announcement template
export const COLUMN_TITLE_EDIT = "Ažuriraj";
export const COLUMN_TITLE_LANGUAGE = "Jezik";
export const ANN_HOTEL_GUIDE_TITLE = "Najava hotel sa vodičem";
export const ANN_HOTEL_GUIDE_INTRO = "Intro hotel sa vodičem";
export const ANN_HOTEL_NO_GUIDE_TITLE = "Najava hotel bez vodiča";
export const ANN_HOTEL_NO_GUIDE_INTRO = "Intro hotel bez vodiča";
export const ANN_GUIDE_TITLE = "Najava vodič";
export const ANN_GUIDE_INTRO = "Intro vodič";
export const ANN_GUIDE_TOUR_TITLE = "Najava vodič (tura)";
export const ANN_GUIDE_TOUR_INTRO = "Intro vodič (ture)";
export const ANN_GUIDE_LOCAL_TITLE = "Najava vodič lokalci";
export const ANN_GUIDE_LOCAL_INTRO = "Intro vodič lokalci";
export const ANN_AGENCY_TITLE = "Najava agencija";
export const ANN_AGENCY_INTRO = "Intro agencija";
export const ANN_AGENCY_TOUR_TITLE = "Najava agencija (Tura)";
export const ANN_AGENCY_TOUR_INTRO = "Intro agencija (Tura)";
export const ANN_AGENCY_LOCAL_TITLE = "Najava agencija lokalci";
export const ANN_AGENCY_LOCAL_INTRO = "Intro agencija lokalci";
export const ANN_AGENCY_LOCAL_PROGRAM_TITLE = "Najava agencija Lokalci (Program Only)";
export const ANN_AGENCY_LOCAL_PROGRAM_INTRO = "Intro agencija lokalci (Program Only)";
export const ANN_SUPPLIER_TITLE = "Najava dobavljači";
export const ANN_SUPPLIER_INTRO = "Intro dobavljači";

export const ANN_PHRASE_TITLE = "Fraze";
export const ANN_PHRASE_INTRO = "Intro fraze";

export const ANN_GROUP_LABEL = "Grupa";
export const ANN_TERMIN_LABEL = "Termin";
export const ANN_HOTEL_LABEL = "Hotel";
export const ANN_HOTEL_NAME_CATEGORY_LABEL = "Naziv i Kategorija";
export const ANN_HOTEL_ADDRESS_LABEL = "Adresa";
export const ANN_HOTEL_CHECK_IN_LABEL = "Check In";
export const ANN_HOTEL_CHECK_OUT_LABEL = "Check Out";
export const ANN_HOTEL_BREAKFAST_LABEL = "Doručak";
export const ANN_HOTEL_DINNER_LABEL = "Večera";

export const ANN_OLIVARI_GUIDE_GROUP_LABEL = "Olivari vodič sa grupom";

export const ANN_VOUCHER_ACC_LABEL = "Voucher za smještaj";
export const ANN_GUIDE_ACC_LABEL = "Smještaj za vodiča";
export const ANN_HOTEL_INCLUDES_LABEL = "Uključene usluge u hotelu";
export const ANN_HOTEL_DINNER_DRINK_LABEL = "Piće za vrijeme večere";
export const ANN_BUS_PARKING_LABEL = "Parking za autobus";
export const ANN_IMPORTANT_NOTICE_LABEL = "Važna napomena";
export const ANN_ROOMING_LIST_LABEL = "Rooming lista";
export const ANN_ROOMING_LIST_TYPOLOGY_LABEL = "Tipologija";
export const ANN_ROOMING_LIST_CONTIGENT_LABEL = "Kontigent";
export const ANN_DRIVER_CONTACT_LABEL = "Kontakt vozača";
export const ANN_PROGRAM_LABEL = "Program";
export const ANN_INVOICE_WARRANT_LABEL = "Nalog za fakturu";
export const ANN_NR_OF_PERSONS_LABEL = "Broj osoba";
export const ANN_GROUP_TREFFPUNKT_LABEL = "mmTreffpunkt sa grupomm";

export const ANN_SERVICE_FAVOR_LABEL = "Usluga";
export const ANN_PAYMENT_LABEL = "Plaćanje";
export const ANN_DATE_LABEL = "Datum";
export const ANN_TIME_LABEL = "Vrijeme";
export const ANN_LANGUAGE_LABEL = "Jezik";
export const ANN_PHRASE_TEXT_LABEL = "Text fraze";
export const ANN_FOOTER_MESSAGE_1_LABEL = "Footer poruka 1";
export const ANN_FOOTER_MESSAGE_2_LABEL = "Footer poruka 2";

// ANNOUNCEMENT
export const TITLE_TOOLBAR_ANNOUNCEMENTS = "Najave";

export const LABEL_NO_ANNOUNCEMENTS = "Nema kreiranih najava";
export const LABEL_NO_ANNOUNCEMENT_CREATED = "Kreirana najava će biti prikazana ovdje";
export const LABEL_NO_GUIDE = "Nije odabran vodič";

// vouchers
export const TITLE_VOUCHERS = "Kreirani voucher-i";
export const LABEL_NO_VOUCHERS = "Nema kreiranih voucher-a";

// columns
export const COLUMN_TITLE_ANNOUNCEMENT_EDIT = "Otvori";
export const COLUMN_TITLE_ANNOUNCEMENT_ID = "ID";
export const COLUMN_TITLE_ANNOUNCEMENT_HOTEL = "Hotel";
export const COLUMN_TITLE_ANNOUNCEMENT_HOTELS = "Hoteli";
export const COLUMN_TITLE_ANNOUNCEMENT_PARTNER = "Partner";
export const COLUMN_TITLE_ANNOUNCEMENT_CONTRACT = "Ugovor";
export const COLUMN_TITLE_ANNOUNCEMENT_GROUP = "Grupa";
export const COLUMN_TITLE_ANNOUNCEMENT_TERMIN = "Termin";
export const COLUMN_TITLE_ANNOUNCEMENT_FROM = "Dolazak";
export const COLUMN_TITLE_ANNOUNCEMENT_TO = "Odlazak";
export const COLUMN_TITLE_ANNOUNCEMENT_GUIDE = "Vodič";
export const COLUMN_TITLE_ANNOUNCEMENT_NAME = "Naslov";
export const COLUMN_TITLE_ANNOUNCEMENT_PHRASE = "Fraze";
export const COLUMN_TITLE_ANNOUNCEMENT_LANGUAGE = "Jezik";

// ANNOUNCEMENT ORDERS
export const TITLE_TOOLBAR_ANNOUNCEMENT_ORDERS = "Nalozi";
export const TITLE_TOOLBAR_ANNOUNCEMENT_ORDER_EDIT = "Ažuriranje naloga";

// columns
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_EDIT = "Otvori";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_ID = "ID";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_CONTRACT = "Ugovor";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_PARTNER = "Partner";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_DATE_CREATED = "Datum kreiranja";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_FROM = "Termin - Od";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_TO = "Termin - Do";
export const COLUMN_TITLE_ANNOUNCEMENT_ORDER_THEME_NAME = "Grupa";
