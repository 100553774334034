import React from "react";
import { connect } from "react-redux";

const PreviewGroup = ({ document_code, code1, int }) => {
  return (
    <div className="preview-content">
      <div className="preview-content-title ">
        <h4>{int.group}</h4>
      </div>
      <div className="preview-content-double bold-text preview-no-padding preview-no-margin">
        <p className="preview-content-div ">{code1}</p>
        <p className="preview-content-div ">{document_code}</p>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: { document_code, code1 },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    document_code,
    code1,
    int,
  };
};

export default connect(mapStateToProps)(PreviewGroup);
