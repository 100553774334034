import gql from "graphql-tag";

/**
 * voucher fragments
 */
export const voucherFragment = gql`
  fragment VoucherDetails on GeneratedVoucherRaw {
    id
    voucher_active
    announcement_id
    user_first_name
    user_last_name
    voucher_title
    date
    voucher_stamp_text
    voucher_payment_text
    voucher_created_text
    voucher_signature_text
    voucher_unique_nr
    voucher_type
  }
`;
