import React, { Component } from "react";
import debounce from "lodash/debounce";

import Paper from "material-ui/Paper/Paper";
import Upload from "../../../components/UI/Upload";
import { withRouter } from "react-router-dom";
import AutocompleteSelect from "../../../components/UI/AutocompleteSelect";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core/styles";

import Remark from "../../../components/UI/CommentTextArea";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import AttachmentItem from "../../../components/UI/AttachmentItem";
import FormValidator from "../../../components/UI/FormValidator";
import { where_agency_2, removeDuplicateObjects } from "../../../utility/globals";
import ConnectedContractsAndOffers from "./ConnectedContractsAndOffers";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";

const currentYear = new Date().getFullYear();
const yearsInSelect = Array.from(Array(20), (v, i) => currentYear + i);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 100,
  },

  card: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class InquiryForm extends Component {
  static schema = {
    type: "object",
    properties: {
      title: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Naslov ne smije biti prazan",
      },
      partner_client_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati klijenta",
      },
    },
  };

  state = {
    partnerClient: this.props.inquiry.partner_client,
    loadingPartnerClientData: false,
  };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
      where: where_agency_2,
    },
  };

  handleAutocompleteInputChange = value => {
    const where = JSON.parse(this.variables.input.where);

    // checking if previous fetch name and current value are empty
    // skip fetch if same requests
    if (!where.name && !value) {
      return;
    }

    this.variables = {
      ...this.variables,
      input: {
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: JSON.stringify({
          ...JSON.parse(this.variables.input.where),
          name: value,
        }),
      },
    };

    this.props.listPartnerClientQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listPartnerClient.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listPartnerClient: [...fetchMoreResult.listPartnerClient],
        };
      },
    });
  };

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = () => {
    const { listPartnerClient } = this.props;

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listPartnerClient &&
      listPartnerClient.length >= PAGINATION_LIMIT
    ) {
      this.setState({ loadingPartnerClientData: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listPartnerClientQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listPartnerClient.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listPartnerClient: [...previousResult.listPartnerClient, ...fetchMoreResult.listPartnerClient],
            };
          },
        });

        this.setState({ loadingPartnerClientData: false });
      });
    }
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  changeContentField = (name, event) => {
    this.props.changeInquiryFieldData(name)(event);
  };

  delayedCallback = debounce(this.changeContentField, 500);

  onChange = name => event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();

    this.delayedCallback(name, event);
  };

  onChangeYear = event => this.changeContentField("year", event);

  render() {
    const {
      title,
      inquiry,
      listPartnerClient,
      actionTitle,
      changeData,
      onDrop,
      deleteUploadedFile,
      files,
      history,
      submitData,
    } = this.props;

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    // Inserting picked partner client to top,
    // So it can be picked in init array  even if it's not fetched from first partners list
    const arrayToShow = this.state.partnerClient
      ? removeDuplicateObjects([this.state.partnerClient, ...listPartnerClient])
      : listPartnerClient;

    return (
      <FormValidator schema={InquiryForm.schema} submit={submitData} data={inquiry}>
        {({ error, errorMsg, submit }) => (
          <Paper className="form-inquiry">
            <form onSubmit={this.submit}>
              <div>
                <h3>{title}</h3>
              </div>

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right label-margin-block-start-8">Naslov</label>
                </Grid>
                <Grid item lg={4} md={6} container xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      defaultValue={inquiry.title || ""}
                      onChange={this.onChange("title")}
                      placeholder="Naslov"
                      error={error("/title")}
                    />

                    {error("/title") && <FormHelperText error={error("/title")}>{errorMsg("/title")}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right label-margin-block-start-8">Falš</label>
                </Grid>
                <Grid item lg={4} md={6} container xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      defaultValue={inquiry.off_key || ""}
                      onChange={this.onChange("off_key")}
                      placeholder="Falš"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right label-margin-block-start-8">Povijesni broj</label>
                </Grid>
                <Grid item lg={4} md={6} container xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      defaultValue={inquiry.external_id || ""}
                      onChange={this.onChange("external_id")}
                      placeholder="Legacy ID"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {!this.props.edit ? (
                <Grid container spacing={16}>
                  <Grid item>
                    <label className="form-label-right label-margin-block-start-8">Godina</label>
                  </Grid>
                  <Grid item lg={4} md={6} container xs={12}>
                    <select
                      value={parseInt(inquiry.year) || inquiry.year}
                      onChange={this.onChangeYear}
                      className="booking-select inquiry-year-select"
                      disabled={this.props.edit}
                    >
                      {["", ...yearsInSelect].map(entry => (
                        <option key={entry} value={entry}>
                          {entry}
                        </option>
                      ))}
                    </select>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right label-margin-block-start-8">Klijent</label>
                </Grid>
                <Grid item lg={4} md={6} container xs={12}>
                  <AutocompleteSelect
                    error={error("/partner_client_id")}
                    errorMsg={errorMsg("/partner_client_id")}
                    defaultValue={inquiry.partner_client_id || ""}
                    placeholder="Odaberite klijenta"
                    dataSource={arrayToShow.map(client => ({
                      value: client.id,
                      label: client.name,
                    }))}
                    onMenuScrollToBottom={this.handleScroller}
                    isLoading={this.state.loadingPartnerClientData}
                    autocompleteHandler={selected => {
                      this.setState({
                        partnerClient: arrayToShow.find(pc => pc.id === selected),
                      });
                      return changeData("partner_client_id")(selected);
                    }}
                    inputProps={{
                      onInputChange: this.handleAutocompleteInputChangeDebounced,
                    }}
                  />
                </Grid>
              </Grid>

              <ConnectedContractsAndOffers inquiry={inquiry} />

              <Grid container spacing={24} style={{ marginTop: 8 }}>
                <Grid item>
                  <label className="form-label-right label-margin-block-start-8">Uploadane datoteke</label>
                </Grid>
                <Grid item md container sm={12}>
                  {inquiry.attachments && inquiry.attachments.length
                    ? inquiry.attachments.map(file => (
                        <Grid key={file.file_id} container item>
                          <AttachmentItem file={file} onDelete={() => deleteUploadedFile(file.id)} />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Grid>

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right ">Datoteke za poslati</label>
                </Grid>
                <Grid item lg={4} md={6} container xs={12}>
                  <Upload
                    onDrop={onDrop}
                    files={files}
                    deletePreparedFileToUpload={this.props.deletePreparedFileToUpload}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={16}>
                <Grid item>
                  <label className="form-label-right ">Napomena</label>
                </Grid>
                <Grid item container md={6} xs={12}>
                  <Remark
                    defaultValue={inquiry.remark || ""}
                    customOnChange={this.onChange("remark")}
                    placeholder="Napomena"
                  />
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={16}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={submit}>
                    {actionTitle}
                  </Button>
                </Grid>

                {inquiry.id ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push(`/ponude/create/${inquiry.id}`)}
                    >
                      Stvori novu ponudu
                    </Button>
                  </Grid>
                ) : null}

                <Grid item>
                  <Button variant="outlined" onClick={history.goBack}>
                    Natrag
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </FormValidator>
    );
  }
}
export default withRouter(withStyles(styles)(InquiryForm));
