import React, { Fragment, Component } from "react";
import debounce from "lodash/debounce";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

import { where_agency_2, removeDuplicateObjects } from "../../../../utility/globals";
import OfferForm from "../OfferForm";
import { withApollo } from "react-apollo";
import { getPartnerClient } from "../../../../graphql/query/administration";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";
import get from "lodash/get";

class AgencyAndPrograms extends Component {
  document_code = null;
  inquiry_external_id = null;

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
      where: where_agency_2,
    },
  };

  state = {
    selectedAgency: {},
    fetchingMore: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.code1 !== this.props.code1 ||
      nextProps.offer_status_id !== this.props.offer_status_id ||
      nextProps.off_key !== this.props.off_key ||
      nextProps.document_code !== this.props.document_code ||
      nextProps.partner_client_id !== this.props.partner_client_id ||
      nextProps.inquiry_external_id !== this.props.inquiry_external_id ||
      nextProps.referents_to_add !== this.props.referents_to_add ||
      nextProps.referents_to_remove !== this.props.referents_to_remove ||
      this.checkForNewValidationErrors(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    ) {
      return true;
    }
    return false;
  }

  checkForNewValidationErrors(nextProps) {
    // to check if there are inquiry validation error
    const next_inq_id =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("inquiry_external_id");
      }).length;
    const prev_inq_id =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("inquiry_external_id");
      }).length;

    // to check if there are partner validation error
    const next_partner_id =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("partner_client_id");
      }).length;
    const prev_partner_id =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("partner_client_id");
      }).length;

    if (next_inq_id !== prev_inq_id || next_partner_id !== prev_partner_id) {
      return true;
    }
    return false;
  }

  fetchDataAfterInputChange = async () => {
    await this.props.listPartnerClientQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listPartnerClient.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listPartnerClient: [...fetchMoreResult.listPartnerClient],
        };
      },
    });

    this.setState({ fetchingMore: false });
  };

  handleAutocompleteInputChange = value => {
    const where = JSON.parse(this.variables.input.where);

    // checking if previous fetch name and current value are empty
    // skip fetch if same requests
    if (!where.name && !value) {
      return;
    }

    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: JSON.stringify({
          ...JSON.parse(this.variables.input.where),
          name: value,
        }),
      },
    };

    this.setState({ fetchingMore: true }, this.fetchDataAfterInputChange);
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listPartnerClient = get(this.props, "listPartnerClientQuery.listPartnerClient", []);

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listPartnerClient &&
      listPartnerClient.length >= PAGINATION_LIMIT
    ) {
      this.setState({ fetchingMore: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listPartnerClientQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listPartnerClient.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listPartnerClient: [...previousResult.listPartnerClient, ...fetchMoreResult.listPartnerClient],
            };
          },
        });

        this.setState({ fetchingMore: false });
      });
    }
  };

  setDocumentCodeRef = el => {
    this.document_code = el;
  };

  setInquiryExternalIdRef = el => {
    this.inquiry_external_id = el;
  };

  componentDidUpdate(prevProps) {
    if (this.props.document_code !== prevProps.document_code && this.props.document_code !== this.document_code.value) {
      this.document_code.value = this.props.document_code;
    }

    if (
      this.props.inquiry_external_id !== prevProps.inquiry_external_id &&
      this.props.inquiry_external_id !== this.inquiry_external_id.value
    ) {
      this.inquiry_external_id.value = this.props.inquiry_external_id;
    }

    if (this.props.partner_client_id !== prevProps.partner_client_id) {
      this.fetchPartnerClientFromInquiry();
    }

    if (prevProps.code1 !== this.props.code1 && this.props.code1 !== this.code1.value) {
      this.code1.value = this.props.code1;
    }

    if (prevProps.off_key !== this.props.off_key && this.props.off_key !== this.off_key.value) {
      this.off_key.value = this.props.off_key;
    }
  }

  componentDidMount() {
    this.fetchPartnerClientFromInquiry();
  }

  fetchPartnerClientFromInquiry = async () => {
    // used when creating offer directly from inquiry, as we get partner_client_id then
    const { partner_client_id } = this.props;

    if (!partner_client_id) {
      return null;
    }

    const response = await this.props.client.query({
      query: getPartnerClient,
      variables: {
        id: partner_client_id,
      },
    });

    this.setState({
      selectedAgency: response.data.getPartnerClient,
    });
  };

  changeContentField = (key, { target: { value } }) => {
    this.props.changeDocumentParam({
      key,
      value,
    });
  };

  delayedCallback = debounce(this.changeContentField, 500);

  changeOfferContentField = (key, { target: { value } }) => {
    this.props.changeOfferParameter({
      key,
      value,
    });
  };

  delayedOfferCallback = debounce(this.changeOfferContentField, 500);

  onChange = name => event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();

    if (name === "off_key") {
      this.delayedOfferCallback(name, event);
    } else {
      this.delayedCallback(name, event);
    }
  };

  removeReferent = id => {
    const { referents, referents_to_add, referents_to_remove, changeDocumentParam } = this.props;

    // remove if it's in this array
    changeDocumentParam({
      key: "referents_to_add",
      value: referents_to_add ? referents_to_add.filter(entry => entry !== id) : [],
    });

    // only set it as something that needs to be removed if it's already in DB
    if (referents && referents.find(entry => entry.id === id)) {
      changeDocumentParam({
        key: "referents_to_remove",
        value: referents_to_remove ? [...referents_to_remove, id] : [id],
      });
    }
  };

  addReferent = id => {
    const { referents, referents_to_add, referents_to_remove, changeDocumentParam } = this.props;

    // if it's something added for removal, prevent it
    changeDocumentParam({
      key: "referents_to_remove",
      value: referents_to_remove ? referents_to_remove.filter(entry => entry !== id) : [],
    });

    // set for adding only if it isn't in DB already
    if (referents && !referents.find(entry => entry.id === id)) {
      changeDocumentParam({
        key: "referents_to_add",
        value: referents_to_add ? [...referents_to_add, id] : [id],
      });
    }
  };

  render() {
    let { referents, referents_to_add, referents_to_remove } = this.props;
    const { selectedAgency } = this.state;
    const {
      partner_client_id,

      document_code,
      code1,
      off_key,
      changeDocumentParam,
      inquiry_external_id,
      isNotDraft,
      error,
      errorMsg,
      partner_client,
    } = this.props;

    const listPartnerClient = get(this.props, "listPartnerClientQuery.listPartnerClient", []);
    const loadingParners = get(this.props, "listPartnerClientQuery.loading");

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    const agencies = partner_client_id
      ? removeDuplicateObjects([partner_client ? partner_client : selectedAgency, ...listPartnerClient])
      : listPartnerClient;

    let agency = null;
    if (partner_client_id) {
      agency = agencies.find(({ id }) => id === partner_client_id);
    }

    referents = referents
      ? referents.filter(entry => partner_client_id === entry.partner_client_id).map(entry => entry.id)
      : [];
    referents_to_add = referents_to_add ? referents_to_add.map(id => id) : [];
    referents_to_remove = referents_to_remove ? referents_to_remove.map(id => id) : [];

    const selectedReferents = agency
      ? referents.concat(referents_to_add).filter(entry => !referents_to_remove.includes(entry))
      : [];

    return (
      <Fragment>
        {/* Agencije / klijenti */}
        <Grid container spacing={24}>
          <Grid item xs={4}>
            <label className="label pl-16px">Agencije / Klijenti</label>
          </Grid>
          <Grid item xs={8}>
            <AutocompleteSelect
              error={error("/offer_contract_document/partner_client_id")}
              errorMsg={errorMsg("/offer_contract_document/partner_client_id")}
              defaultValue={partner_client_id}
              placeholder="Odaberite klijenta"
              onMenuScrollToBottom={this.handleScroller}
              isLoading={loadingParners || this.state.fetchingMore}
              dataSource={agencies.map(agency => ({
                value: agency.id,
                label: !_.isEmpty(agency) ? `${agency.id}.  ${agency.name} | ${agency.city.name}` : "",
              }))}
              autocompleteHandler={selected => {
                if (selected === partner_client_id) {
                  return null;
                }

                changeDocumentParam({
                  key: "partner_client_id",
                  value: selected ? selected : "",
                });

                // if input is cleared (null), remove all referents previously staged for adding or removing and already gotten ones
                changeDocumentParam({
                  key: "referents_to_remove",
                  value: [...new Set(referents_to_remove.concat(referents))],
                });
                changeDocumentParam({
                  key: "referents_to_add",
                  value: [],
                });

                if (selected) {
                  const agency = listPartnerClient.find(({ id }) => id === selected);

                  // in create, keep selected agency in state. in edit, get it from props as partner_client
                  if (!partner_client) {
                    this.setState({ selectedAgency: agency });
                  }

                  changeDocumentParam({
                    key: "language_id",
                    value: agency ? agency.language_id : "",
                  });
                }
              }}
              disabled={isNotDraft}
              inputProps={{
                onInputChange: this.handleAutocompleteInputChangeDebounced,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <label className="label pl-16px">Kontakti</label>
          </Grid>
          <Grid item xs={8}>
            <AutocompleteSelect
              defaultValue={""}
              placeholder="Odaberite kontakte"
              dataSource={
                agency &&
                agency.contacts &&
                agency.contacts
                  .filter(
                    entry =>
                      // list/show this element if it wasn't found
                      selectedReferents.find(id => id === entry.id) === undefined,
                  )
                  .map(entry => ({
                    value: entry.id,
                    label: entry.first_name + " " + entry.last_name,
                  }))
              }
              autocompleteHandler={this.addReferent}
              disabled={isNotDraft || !agency}
            />
          </Grid>
          {selectedReferents && selectedReferents.length ? (
            <React.Fragment>
              <Grid item xs={4} />
              <Grid item xs={8}>
                <Grid container spacing={0}>
                  <Grid item xs={12} className="bgcolor-contact-header cst-list-box-shadow">
                    <span className="cursor-default label">Popis kontakata</span>
                  </Grid>
                  {selectedReferents.map(entry => {
                    const contact = agency.contacts.find(cont => cont.id === entry);
                    if (!contact) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        xs={12}
                        key={contact.id}
                        className="cst-flex-between cursor-default cst-list-box-shadow"
                      >
                        <div className="ver-center">
                          {`${contact.first_name || ""} ${contact.last_name || ""}, ${contact.phone ||
                            ""}, ${contact.email || ""}`}
                        </div>
                        <div className="ver-center">
                          <IconButton
                            aria-label="Delete"
                            href=""
                            onClick={() => this.removeReferent(entry)}
                            disabled={isNotDraft || !agency}
                          >
                            <ClearIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={4}>
            <label className="label pl-16px">Upit</label>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={error("/offer_contract_document/inquiry_external_id")}>
              <TextField
                id="inquiry_external_id"
                placeholder="Broj upita"
                error={error("/offer_contract_document/inquiry_external_id")}
                defaultValue={inquiry_external_id || ""}
                inputRef={this.setInquiryExternalIdRef}
                disabled={
                  // set field as disabled if inquiry_id is sent as parameter in url (but we show user external_id in form field)
                  (this.props.match && this.props.match.params && Boolean(this.props.match.params.inquiry_id)) ||
                  isNotDraft
                }
                fullWidth={true}
                onChange={this.onChange("inquiry_external_id")}
              />
              <FormHelperText>{errorMsg("/offer_contract_document/inquiry_external_id")}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="code1"
                placeholder="Šifra"
                fullWidth={true}
                defaultValue={code1 || ""}
                onChange={this.onChange("code1")}
                inputRef={el => (this.code1 = el)}
                disabled={isNotDraft}
              />
            </FormControl>
          </Grid>
          {/* Grupa */}
          <Grid item xs={4}>
            <label className="label pl-16px">Grupa</label>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="off_key"
                placeholder="Falš"
                defaultValue={off_key || ""}
                fullWidth={true}
                inputRef={el => (this.off_key = el)}
                onChange={this.onChange("off_key")}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="document_code"
                placeholder="Ime programa"
                defaultValue={document_code || ""}
                inputRef={this.setDocumentCodeRef}
                fullWidth={true}
                onChange={this.onChange("document_code")}
                disabled={isNotDraft}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      off_key,
      offer_status_id,
      offer_contract_document: {
        document_code,
        code1,
        partner_client_id,
        inquiry_external_id,
        partner_client,
        referents,
        referents_to_add,
        referents_to_remove,
      },
    },
  } = state.offer;

  return {
    code1,
    off_key,
    offer_status_id,
    document_code,
    partner_client_id,
    inquiry_external_id,
    partner_client,
    referents,
    referents_to_add,
    referents_to_remove,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOfferParameter: obj => dispatch(actions.changeOfferParameter(obj)),
    changeDocumentParam: obj => dispatch(actions.changeDocumentParam(obj)),
  };
};

const AgencyAndProgramsBase = props => {
  return (
    <OfferForm.Consumer>
      {({ error, errorMsg, errors }) => {
        return <AgencyAndPrograms error={error} errorMsg={errorMsg} errors={errors} {...props} />;
      }}
    </OfferForm.Consumer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withApollo(AgencyAndProgramsBase)));
