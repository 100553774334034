import { CircularProgress, FormHelperText, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React from "react";
import { graphql, withApollo } from "react-apollo";
import toastr from "toastr";
import DateRangePicker from "../../../../components/UI/DateRangePicker";
import FormValidator from "../../../../components/UI/FormValidator";
import { updateAlotmanCalendar } from "../../../../graphql/mutation/alotman";
import { listAlotmanDayByTypology } from "../../../../graphql/query/alotman";
import { formatDate } from "../../../../utility/dates";
import { findTypologyByCroLang, sortTypologies } from "../../../../utility/establishment";
import { formatFromFilterDate, formatToFilterDate } from "../../../../utility/globals";

const availableStateStyling = foundDay => {
  return (
    foundDay && {
      position: "absolute",
      fontSize: "12px",
      right: 1,
      bottom: -14,
      color: "white",
      padding: 3,
      background: foundDay.stop_booking ? "red" : "green",
      width: "auto",
      height: 12,
      borderRadius: "50%",
    }
  );
};

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
    width: "20px !important",
    height: "20px !important",
  },
});

class UpdateFixContigent extends React.Component {
  static schemaStopBooking = {
    type: "object",
    required: ["from", "to", "typology_id"],
    properties: {
      stop_booking: {
        type: "boolean",
        errorMessage: "Stop booking mora biti odabran",
      },
      from: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      to: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      typology_id: {
        type: ["string", "integer"],
        minimum: 1,
        minLength: 1,
        errorMessage: "Odaberite tipologiju",
      },
    },
  };
  static schemaAvailableState = {
    type: "object",
    required: ["from", "to", "typology_id"],
    anyOf: [
      // if one of field have positive value its ok, else error will be shown
      {
        properties: {
          subtract_from_state: {
            type: "integer",
            errorMessage: "Potrebno je dodati ili oduzeti sa stanja",
            minimum: 1,
          },
        },
      },
      {
        properties: {
          add_to_state: {
            type: "integer",
            errorMessage: "Potrebno je dodati ili oduzeti sa stanja",
            minimum: 1,
          },
        },
      },
    ],

    properties: {
      from: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      to: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      typology_id: {
        type: ["string", "integer"],
        minimum: 1,
        minLength: 1,
        errorMessage: "Odaberite tipologiju",
      },
      add_to_state: {
        type: "integer",
        errorMessage: "Upišite novo stanje",
      },
      subtract_from_state: {
        type: "integer",
        errorMessage: "Upišite novo stanje",
      },
    },
  };

  state = {
    from: null,
    to: null,
    typology_id: null,
    add_to_state: 0,
    subtract_from_state: 0,
    stop_booking: false,
    blockedDaysMessage: null,
    termins: [],
    loading: false,
  };

  resetTypology = () => {
    this.setState({
      typology_id: null,
    });
  };

  updateAlotmanCalendar = async () => {
    this.setState({ loading: true });
    try {
      const state = {
        establishment_id: this.props.establishment_id,
        from: this.state.from,
        to: this.state.to,
        typology_id: this.state.typology_id,
        ...(this.props.updateAvailableState && { add_to_state: this.state.add_to_state }),
        ...(this.props.updateAvailableState && { subtract_from_state: this.state.subtract_from_state }),
        ...(this.props.updateStopBooking && { stop_booking: this.state.stop_booking }),
      };

      // refetch fetchEstablishmentDayCalendar fetch calendar after update
      await this.props.mutate({
        variables: {
          ...state,
        },
      });

      await this.props.onUpdate();

      toastr.success("Uspješno ažurirano");
      this.props.toggle();
    } catch (error) {}
    this.setState({ loading: false });
  };

  renderDatePickerDay = day => {
    const { termins } = this.state;
    const foundDay = termins.find(termin => formatDate(termin.date) === formatDate(day));

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div>{day.format("D")}</div>
        <div style={availableStateStyling(foundDay)}>{foundDay && foundDay.available_state}</div>
      </div>
    );
  };

  isDayBlocked = day => {
    const { termins } = this.state;
    const terminFound = termins.find(termin => formatDate(termin.date) === formatDate(day));

    // termin inside inserted days
    if (terminFound) {
      return !terminFound;
    }

    // look if day is one after termin days ( it can be selected then because it will look only day until then )
    const dayBefore = moment(day)
      .clone()
      .subtract(1, "d");

    return !termins.find(termin => formatDate(termin.date) === formatDate(dayBefore));
  };

  async blockedDates(start, end) {
    try {
      const from = formatFromFilterDate(start).startOf("M");
      const to = formatToFilterDate(end).endOf("M");

      const { typology_id } = this.state;

      const response = await this.props.client.query({
        query: listAlotmanDayByTypology,
        variables: {
          typology_id,
          from,
          to,
        },
      });

      const terminsTOCheck = response.data.listAlotmanDayByTypology;

      const diff = moment(end).diff(start, "days");

      for (let i = 0; i < diff; i++) {
        const checkDate = formatDate(moment(start).add(i, "d"));

        if (!terminsTOCheck.find(day => formatDate(day.date) === checkDate)) {
          return true;
        }
      }
      return false;
    } catch (error) {}
  }

  datesChange = newDate => {
    // Fetch new all getAlotmanDays from first day of newDate to newDate + 1 month

    let start = null;
    let end = null;
    if (this.state.from) {
      const clonedDate = this.state.from.clone();
      const clonedNewDate = newDate.clone();

      start = clonedDate.clone().startOf("M");

      if (clonedNewDate.isAfter(this.state.from, "year") || clonedNewDate.isAfter(this.state.from, "month")) {
        end = formatToFilterDate(clonedNewDate)
          .add(1, "M")
          .endOf("M");
      } else {
        end = formatToFilterDate(clonedDate)
          .add(1, "M")
          .endOf("M");
      }
    } else {
      const clonedDate = newDate.clone();
      start = formatFromFilterDate(clonedDate).startOf("M");
      end = formatToFilterDate(clonedDate)
        .add(1, "M")
        .endOf("M");
    }

    this.fetchAlotmanDays(start, end);
  };

  async fetchAlotmanDays(
    from = formatFromFilterDate().startOf("M"),
    to = formatToFilterDate()
      .add(1, "M")
      .endOf("M"),
  ) {
    const { typology_id } = this.state;

    try {
      const response = await this.props.client.query({
        query: listAlotmanDayByTypology,
        variables: {
          typology_id,
          from,
          to,
        },
      });

      this.setState({ termins: response.data.listAlotmanDayByTypology });
    } catch (error) {}
  }

  fetchedOnCDU = false;
  terminPicked = false;

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.typology_id) {
      if (!this.fetchedOnCDU) {
        this.fetchAlotmanDays();
        this.fetchedOnCDU = true;
      }
    }

    if (prevState.typology_id !== this.state.typology_id && this.state.typology_id) {
      this.fetchAlotmanDays();
    }

    if (!this.state.typology_id) {
      if (this.fetchedOnCDU) {
        this.fetchedOnCDU = false;
      }
    }

    if (!this.state.from || !this.state.to || this.state.from !== prevState.from || this.state.to !== prevState.to) {
      this.terminPicked = false;
    }

    if (this.state.from && this.state.to && !this.terminPicked) {
      // there are blocked days in selected range
      if (await this.blockedDates(this.state.from, this.state.to)) {
        this.setState({
          from: null,
          to: null,
          blockedDaysMessage: "U odabranom razdoblju postoje dani koji su blokirani",
        });
        this.terminPicked = true;
      }
      // no blocked dates so store this termin
      else {
        this.setState({
          blockedDaysMessage: null,
        });

        this.terminPicked = true;
      }
    }
  }

  render() {
    // get only that one?
    const { establishment, establishment_typologies, updateAvailableState, updateStopBooking, classes } = this.props;
    const { from, to, typology_id, stop_booking, add_to_state, subtract_from_state } = this.state;

    return (
      <FormValidator
        schema={
          (updateStopBooking && UpdateFixContigent.schemaStopBooking) ||
          (updateAvailableState && UpdateFixContigent.schemaAvailableState)
        }
        submit={this.updateAlotmanCalendar}
        data={this.state}
      >
        {({ error, errorMsg, submit }) => {
          return (
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>Hotel</InputLabel>
                  <Input value={establishment.name} disabled />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DateRangePicker
                  from={from}
                  to={to}
                  error={error("/from") || error("/to")}
                  errorMsg={(error("/from") || error("/to")) && "Molim unijeti termin"}
                  handleFromChange={date => this.setState({ from: date })}
                  handleToChange={date => this.setState({ to: date })}
                  onPrevMonthClick={this.datesChange}
                  onNextMonthClick={this.datesChange}
                  onSelectMonthYearChange={this.datesChange}
                  renderDayContents={this.renderDatePickerDay}
                  isDayBlocked={date => this.isDayBlocked(date)}
                  vertical
                  showClearDates
                  daySize={50}
                />
                <br />
                {this.state.blockedDaysMessage && <div style={{ color: "red" }}>{this.state.blockedDaysMessage}</div>}
                {this.state.blockedDaysMessage && <br />}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/typology_id")}>
                  <TextField
                    fullWidth
                    id="kontigent_typology_id"
                    select
                    label="Tipologija"
                    className=""
                    value={typology_id || ""}
                    onChange={({ target: { value } }) => {
                      if (String(typology_id) !== String(value)) {
                        this.setState({ typology_id: value, from: null, to: null });
                      } else {
                        this.setState({ typology_id: value });
                      }
                    }}
                  >
                    {sortTypologies(establishment_typologies).map((typology, typo_index) => {
                      const typo = findTypologyByCroLang(typology);

                      return (
                        <MenuItem key={typo_index} value={typology.id}>
                          {typo.name} ( {typology.establishment_code} ) [{typology.persons_capacity}]
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {error("/typology_id") && <FormHelperText>{errorMsg("/typology_id")}</FormHelperText>}
                </FormControl>
              </Grid>
              {updateAvailableState && (
                <Grid item spacing={24} container>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={error("/add_to_state")}>
                      <TextField
                        id="fix_kontigent"
                        label="Dodaj na stanje"
                        type="text"
                        value={add_to_state}
                        onChange={({ target: { value } }) => this.setState({ add_to_state: value })}
                        error={error("/add_to_state")}
                      />
                      {error("/add_to_state") && <FormHelperText>{errorMsg("/add_to_state")}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth error={error("/subtract_from_state")}>
                      <TextField
                        id="fix_kontigent"
                        label="Oduzmi sa stanja"
                        type="text"
                        value={subtract_from_state}
                        onChange={({ target: { value } }) => this.setState({ subtract_from_state: value })}
                        error={error("/subtract_from_state")}
                      />
                      {error("/subtract_from_state") && (
                        <FormHelperText>{errorMsg("/subtract_from_state")}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {updateStopBooking && (
                <Grid item xs={6}>
                  <FormControl fullWidth error={error("/stop_booking")}>
                    <TextField
                      fullWidth
                      id="stop-booking"
                      select
                      label="Stop booking"
                      className=""
                      value={stop_booking ? 1 : 0}
                      onChange={({ target: { value } }) => this.setState({ stop_booking: value === 1 })}
                    >
                      <MenuItem key={0} value={0}>
                        Ne
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        Da
                      </MenuItem>
                    </TextField>
                    {error("/stop_booking") && <FormHelperText>{errorMsg("/stop_booking")}</FormHelperText>}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} className="ver-center">
                <Grid container spacing={24} justify="flex-end">
                  <Grid item>
                    <Button variant="contained" disabled={this.state.loading} onClick={submit}>
                      {this.state.loading && (
                        <CircularProgress
                          classes={{
                            root: `${classes.leftIcon} ${classes.iconSmall}`,
                          }}
                        />
                      )}
                      {updateStopBooking ? "Stopiraj" : "Ažuriraj"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </FormValidator>
    );
  }
}

export default graphql(updateAlotmanCalendar)(withApollo(withStyles(styles)(UpdateFixContigent)));
