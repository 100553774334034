import initialState from "./initialState";
import * as types from "../../actionTypes";

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ALOTMAN_CONTRACTS_INITIAL_QUERY:
      return {
        ...state,
        limit: action.limit,
        offset: action.offset,
        selectedFilters: action.selectedFilters,
        fromReservation: action.fromReservation,
        toReservation: action.toReservation,
        stopBooking: action.stopBooking,
      };

    default:
      return state;
  }
}
