import React, { Component } from "react";
import { graphql } from "react-apollo";
import ActivityTypeForm from "./ActivityTypeForm";
import { createActivityType } from "../../../../graphql/mutation/administration";
import { Fragment } from "react";
import TableTitle from "src/components/UI/TableTitle";

class CreateActivityType extends Component {
  state = {
    activityType: {
      desc: "",
    },
  };

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        activityType: {
          ...prevState.activityType,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state.activityType,
        },
      });

      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle title={`Stvaranje novog tipa aktivnosti`} onBackPressed={this.props.cancelToggle} />
        <ActivityTypeForm
          activityType={this.state.activityType}
          onChangeField={this.onChangeField}
          onSubmit={this.submit}
          cancelToggle={this.props.cancelToggle}
        />
      </Fragment>
    );
  }
}

export default graphql(createActivityType)(CreateActivityType);
