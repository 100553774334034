// libs
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";

// redux state
import {
  selectProgramId,
  selectSupplierPartners,
  setGuideScheduleAnnouncementDrawerOpen,
  setGuideScheduleAnnouncementDrawerPayload,
} from "src/views/Guides/guidesSlice";

/**
 * useAnnouncementActions handle buttons in row
 */
export default function useAnnouncementActions(guideId, establishmentId) {
  // redux state
  const supplierPartners = useSelector(selectSupplierPartners);
  const programId = useSelector(selectProgramId);

  const dispatch = useDispatch();

  /**
   * getSupplierPartner by guideId, or establishmentId
   */
  const getSupplierPartner = () => {
    // first check by guideId
    if (guideId) {
      return supplierPartners.find(announcementPartner => announcementPartner.guide_id === guideId);
    }

    if (establishmentId) {
      return supplierPartners.find(announcementPartner => announcementPartner.establishment_id === establishmentId);
    }
  };

  // handle on announcement click
  const handleOnAnnouncementClick = () => {
    // get selected / clicked supplierPartner
    const supplierPartnerToSelect = getSupplierPartner(guideId, establishmentId);

    if (!supplierPartnerToSelect) {
      toastr.warning("Partner nije podržan za kreiranje najava");
      return;
    }

    // set announcement drawer
    dispatch(setGuideScheduleAnnouncementDrawerOpen(true));

    // set announcement drawer payload
    dispatch(
      setGuideScheduleAnnouncementDrawerPayload({
        supplier: getSupplierPartner(guideId, establishmentId),
        programId: programId,
      }),
    );
  };

  const isDisabled = () => {
    // get selected / clicked supplierPartner
    const supplierPartnerToSelect = getSupplierPartner(guideId, establishmentId);

    return !Boolean(supplierPartnerToSelect);
  };

  return { isDisabled, handleOnAnnouncementClick };
}
