import React from "react";

// useImperativeHandle(

import { Paper } from "material-ui";
// hooks
import useSuppliers from "./hooks/useSuppliers";
// components
import ReactTableCustom from "src/components/UI/ReactTable";
import supplierColumns from "./components/Columns/SupplierColumns";

/**
 * Suppliers is used for showing data for list of connected partners
 */
export default function Suppliers() {
  const { supplierPartners } = useSuppliers();

  return (
    <Paper zDepth={1}>
      <ReactTableCustom
        data={supplierPartners}
        columns={supplierColumns}
        innerProps={{
          minRows: 0,
          showPagination: false,
          filterable: false,
          manual: true,
          sortable: false,
        }}
      />
    </Paper>
  );
}
