import React, { Fragment } from "react";

import SubterminList from "./Subtermin/SubterminList";
import TerminsList from "./TerminsList";

function TerminsShow(props) {
  return (
    <Fragment>
      <TerminsList {...props} />
      <SubterminList {...props} />
    </Fragment>
  );
}

export default TerminsShow;
