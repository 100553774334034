import React from "react";

// libs
import { get } from "lodash";

//ui
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Button,
  Input,
  InputAdornment,
  Grid,
  Typography,
} from "@material-ui/core";

// hooks
import useDayContainer from "./hooks/useDayContainer";

// components
import DayItem from "../DayItem";

// override style
const styles = {
  root: {
    marginTop: 8,
  },
  item: {
    marginTop: 8,
    marginBottom: 8,
  },
  card: {
    minWidth: 275,
    marginBottom: 16,
  },
  title: {
    marginTop: 8,
  },
};

/**
 * used for rendering program day item in list
 */
function DayContainer({ classes, programDayId, dayNo }) {
  const {
    getIfProgramDayContentDisabledExists,
    handleOnAddActivity,
    loadingUpdateProgramDayActivities,
    loadingUpdateProgramDayTitle,
    onTitleChange,
    programDay,
  } = useDayContainer(programDayId);

  // return root
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Typography className={classes.title} variant="body2">
              {get(programDay, "date", "")}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Input
              onChange={event => onTitleChange(event)}
              fullWidth={true}
              id="programDayTitle"
              defaultValue={get(programDay, "title", "")}
              endAdornment={
                <InputAdornment position="end">
                  {loadingUpdateProgramDayTitle ? <CircularProgress size={16} /> : <div />}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Typography className={classes.title} variant="caption">
          {`Dan ${dayNo}`}
        </Typography>

        {get(programDay, "program_day_contents", []).map((programDayContent, index) => (
          <DayItem
            key={programDayContent.program_day_content_id}
            index={index}
            programDayId={programDay.program_day_id}
            programDayContent={programDayContent}
          />
        ))}
      </CardContent>

      <CardActions className={classes.cardAction}>
        <Button
          size="small"
          color="primary"
          disabled={loadingUpdateProgramDayActivities || getIfProgramDayContentDisabledExists()}
          onClick={() => handleOnAddActivity(get(programDay, "program_day_id"))}
        >
          Dodaj aktivnost
        </Button>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(DayContainer);
