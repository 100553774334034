import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";

import "./styles/index.css";
import "./styles/dropzone.css";
import "./styles/administration.css";
import "./styles/alotman.css";
import "toastr/build/toastr.css";

import AppRoot from "./router";
import client from "./apollo/apollo";

import configureStore from "./store/configurationStore";
const store = configureStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <AppRoot />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
