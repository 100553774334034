import React, { PureComponent } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";

import { connect } from "react-redux";
import moment from "moment";

import _ from "lodash";
import toastr from "toastr";

import * as actions from "../../../../store/actions";
import { config } from "../../../../utility/globals";
import DatePicker from "../../../../components/UI/DatePicker";
import OfferForm from "../OfferForm";
import { autosize } from "../../../../utility/globals";
import {
  FormControl,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import { terminsFragment, contigentFragment, mainTerminFragment } from "../../../../graphql/fragments";
import {
  updateOffer,
  updateOfferArchiveStatus,
  changeOfferContractDocumentTypeMutation,
  updateOfferContractDocumentMutation,
} from "../../../../graphql/mutation/offers";
import { OFFER_STATUS_STORNO } from "../../../../utility/constants";
import { createContract } from "../../../../graphql/mutation/contract";

class DateStatusType extends PureComponent {
  state = {
    showTypeModal: false,
    tmpOfferType: null,
  };

  changeTransport = ({ target: { value } }) => {
    // if in edit mode
    if (this.props.match.params.id) {
      this.saveTransportToServer(value);
    } else {
      this.changeTransportField(value);

      this.props.termin_periods.forEach(termin => {
        if (termin.subtermin_periods && termin.subtermin_periods.length) {
          termin.subtermin_periods.forEach(subtermin =>
            this.props.saveEditedTermin({ ...subtermin, transport: value === "true" }, subtermin),
          );
        } else {
          this.props.saveEditedTermin({ ...termin, transport: value === "true" }, termin);
        }
      });
    }
  };

  saveTransportToServer = value => {
    try {
      let obj = "";

      // send request for every child and update transport field
      this.props.termin_periods.forEach(async termin => {
        // if advanced offer send for all subtermins
        if (termin.subtermin_periods && termin.subtermin_periods.length) {
          termin.subtermin_periods.forEach(subtermin => {
            // Preparing subtermins for batch
            obj += `
              t${subtermin.id}: updateTerminPeriod(input: { id: ${subtermin.id}, patch: { transport: ${value ===
              "true"} } }) {
                ...MainTermin
                termin_period_status_id
                termin_period_status {
                  id
                  desc
                }
                subtermin_periods {
                  ...Termin
                  termin_period_status_id
                  termin_period_status {
                    id
                    desc
                  }
                  contigents {
                    ...Contigent
                  }
                }
                contigents {
                  ...Contigent
                }
              }
              `;

            // Save change to redux store
            this.props.saveEditedTermin({ ...subtermin, transport: value === "true" }, subtermin);
          });
        } else {
          // Preparing termins for batch
          obj += `
            t${termin.id}: updateTerminPeriod(input: { id: ${termin.id}, patch: { transport: ${value === "true"} } }) {
              ...MainTermin
              termin_period_status_id
              termin_period_status {
                id
                desc
              }
              subtermin_periods {
                ...Termin
                termin_period_status_id
                termin_period_status {
                  id
                  desc
                }
                contigents {
                  ...Contigent
                }
              }
              contigents {
                ...Contigent
              }
            }
            `;

          // Save change to redux store
          this.props.saveEditedTermin({ ...termin, transport: value === "true" }, termin);
        }
      });

      // Send batched mutation
      this.props.client.mutate({
        mutation: gql`
          mutation {
            ${obj}
          }
          ${terminsFragment}
          ${contigentFragment}
          ${mainTerminFragment}
        `,
      });

      this.changeTransportField(value);
    } catch (error) {}
  };

  changeTransportField = value => {
    this.props.changeEditData({
      key: "transport",
      value: value === "true",
    });
  };

  changeOfferType = async ({ target: { value } }) => {
    const { id } = this.props.match.params;

    const termin_periods = get(this.props, "termin_periods", []);

    // if not edit mode
    if (!id) {
      this.props.changeDocumentParam({
        key: "offer_contract_document_type_id",
        value,
      });

      this.props.resetProgramDays();

      return;
    }

    // if edit mode
    if (termin_periods.length) {
      // when termins are set, notify user about deleting data
      this.setState({
        showTypeModal: true,
        tmpOfferType: value,
      });
    } else {
      try {
        const response = await this.props.client.mutate({
          mutation: updateOfferContractDocumentMutation,
          variables: {
            input: {
              id: this.props.offer_contract_document_id,
              patch: {
                offer_contract_document_type_id: value,
              },
            },
          },
        });

        const ocd_id = get(response, "data.updateOfferContractDocument.id");

        if (ocd_id) {
          toastr.success("Uspješno izmijenjen tip ponude");

          this.changeValuesAfterTypeChange(value);
        }
      } catch (error) {}
    }
  };

  handleCloseChangeType = () =>
    this.setState({
      showTypeModal: false,
      tmpOfferType: null,
    });

  changeValuesAfterTypeChange = value => {
    this.props.changeDocumentParam({
      key: "offer_contract_document_type_id",
      value,
    });

    this.props.changeOfferCopy({
      ...this.props.offer_copy,
      offer_contract_document: {
        ...this.props.offer_copy.offer_contract_document,
        offer_contract_document_type_id: value,
      },
    });

    this.props.resetProgramDays();
  };

  handleAcceptChangeType = async () => {
    // delete all termins, programs, document_services, price_structure, termin contigents from server
    try {
      const response = await this.props.client.mutate({
        mutation: changeOfferContractDocumentTypeMutation,
        variables: {
          offer_contract_document_type_id: this.state.tmpOfferType,
          offer_contract_document_id: this.props.offer_contract_document_id,
        },
      });

      const isTrue = get(response, "data.changeOfferContractDocumentType");

      if (isTrue) {
        toastr.success("Uspješno izmijenjen tip ponude");

        this.changeValuesAfterTypeChange(this.state.tmpOfferType);

        this.setState({
          showTypeModal: false,
          tmpOfferType: null,
        });
      }
    } catch (error) {}
  };

  /**
   * change offer archive status
   * @argument archivedStatus - is offer archived, get from mapStateToProps
   * */
  handleOfferArchiveStatus = async (offerId, archivedStatus) => {
    try {
      // fetch archive status response
      const responseOfferArchiveStatus = await this.props.mutationUpdateOfferArchiveStatus({
        variables: {
          input: {
            offer_id: offerId,
            archived_status: archivedStatus,
          },
        },
      });

      // get update archive status response
      const offerArchiveStatusUpdate = _.get(responseOfferArchiveStatus, "data.changeArchiveStatus", null);

      if (offerArchiveStatusUpdate) {
        const { archived_status } = offerArchiveStatusUpdate;

        this.props.changeOfferParameter({
          key: "archived_status",
          value: archived_status,
        });

        archived_status ? toastr.success("Ponuda arhivirana") : toastr.success("Ponuda uspješno vraćena iz arhive");
      }
    } catch {
      // handle error
      archivedStatus ? toastr.error("Greška prilikom vraćanja iz arhive") : toastr.error("Greška prilikom arhiviranja");
    }
  };

  render() {
    const {
      props,
      props: { error, errorMsg },
    } = this;

    let disableTypeSelect = false;

    // if (this.props.match.params.id) {
    //   disableTypeSelect = true;
    // }

    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={3} xl={1}>
            <h3>Malinska, </h3>
          </Grid>
          <Grid item xs={9} xl={5}>
            <DatePicker
              id="date_status_picker"
              error={error("/offer_contract_document/document_date_validity")}
              errorMsg={errorMsg("/offer_contract_document/document_date_validity")}
              value={props.offer_created_at || moment()}
              handleChange={date =>
                props.changeOfferParameter({
                  key: "offer_created_at",
                  value: date,
                })
              }
              disabled={props.isNotDraft}
            />
          </Grid>
          <Grid item xs={2} xl={1}>
            {this.state.showTypeModal ? (
              <div>
                <Dialog
                  open={this.state.showTypeModal}
                  onClose={this.handleCloseChangeType}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Izmjenom tipa ponude, izbrisati će se svi povezani termini, odabrane usluge smještaja i program
                    ponude.
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Želite li izmijeniti tip ponude?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCloseChangeType} color="primary">
                      Odustani
                    </Button>
                    <Button onClick={this.handleAcceptChangeType} color="primary" autoFocus>
                      Prihvati
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : null}
            <TextField
              fullWidth
              select
              label="Tip Ponude"
              value={String(props.offer_contract_document_type_id) || ""}
              onChange={this.changeOfferType}
              disabled={props.isNotDraft || disableTypeSelect}
            >
              {props.listOfferContractDocumentType
                ? props.listOfferContractDocumentType.map(type => {
                    if (
                      config.offer_type[Number(type.id)] === "Alotman" &&
                      process.env.REACT_APP_ENABLE_ALOTMAN !== "true"
                    ) {
                      return null;
                    }

                    return (
                      <MenuItem key={`key-${type.id}`} value={type.id}>
                        {type.desc}
                      </MenuItem>
                    );
                  })
                : null}
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Status"
              // disabled={_.parseInt(props.offer_status_id) === OFFER_STATUS_ACCEPTED}
              value={(props.offer_status_id && props.offer_status_id.toString()) || ""}
              onChange={({ target: { value } }) =>
                props.changeOfferParameter({
                  key: "offer_status_id",
                  value,
                })
              }
            >
              {props.listOfferStatus
                ? props.listOfferStatus.map(type => (
                    <MenuItem key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Aktivni status"
              disabled={!props.offer_id || _.parseInt(props.offer_status_id) === OFFER_STATUS_STORNO}
              value={props.archived_status ? props.archived_status : false}
              onChange={({ target: { value } }) => this.handleOfferArchiveStatus(props.offer_id, value)}
            >
              <MenuItem key="key-active" value={false}>
                Aktivan
              </MenuItem>

              <MenuItem key="key-archived" value={true}>
                Arhiviran
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Segment"
              value={props.offer_contract_document_segment_id.toString() || ""}
              onChange={({ target: { value } }) =>
                props.changeDocumentParam({
                  key: "offer_contract_document_segment_id",
                  value,
                })
              }
              disabled={props.isNotDraft}
            >
              {props.listOfferContractDocumentSegment
                ? props.listOfferContractDocumentSegment.map(type => (
                    <MenuItem key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          <Grid item xs={2} xl={1}>
            <FormControl fullWidth error={error("/offer_contract_document/language_id")}>
              <TextField
                fullWidth
                select
                error={error("/offer_contract_document/language_id")}
                label="Jezik"
                value={props.language_id || ""}
                onChange={({ target: { value } }) =>
                  props.changeDocumentParam({
                    key: "language_id",
                    value: value.toString(),
                  })
                }
                disabled={props.isNotDraft}
              >
                {props.listLanguage
                  ? props.listLanguage.map(language => (
                      <MenuItem key={`key-${language.id}`} value={language.id}>
                        {language.code}
                      </MenuItem>
                    ))
                  : null}
              </TextField>

              {error("/offer_contract_document/language_id") && (
                <FormHelperText>{errorMsg("/offer_contract_document/language_id")}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={2} xl={1}>
            <TextField
              fullWidth
              select
              label="Transport"
              value={props.transport.toString()}
              onChange={this.changeTransport}
              disabled={props.isNotDraft}
            >
              <MenuItem value={"false"}>Ne</MenuItem>
              <MenuItem value={"true"}>Da</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {config.contract_status &&
        props.offer_status_id &&
        config.contract_status[props.offer_status_id.toString()] === "Storno" ? (
          <Grid container spacing={24} justify="flex-end">
            <Grid item xs={12} xl={5}>
              <textarea
                onKeyUp={el => autosize(el.target)}
                className="preview-content-content preview-textarea textarea-stretch"
                placeholder="Komentar na storno dokumenta"
                onChange={e =>
                  props.changeOfferParameter({
                    key: "storno_comment",
                    value: e.target.value,
                  })
                }
                value={props.storno_comment || ""}
              />
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    id: offer_id,
    offer_status_id,
    storno_comment,
    offer_created_at,
    archived_status,
    offer_contract_document: {
      id: offer_contract_document_id,
      language_id,
      offer_contract_document_type_id,
      offer_contract_document_segment_id,
      termin_periods,
    },
  } = state.offer.offer;

  const { transport } = state.offer;

  return {
    offer_id,
    language_id,
    offer_status_id,
    archived_status,
    storno_comment,
    offer_contract_document_type_id,
    offer_created_at,
    offer_contract_document_segment_id,
    transport,
    offer_contract_document_id,
    termin_periods,
    offer_copy: state.offer.offer_copy,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOfferParameter: obj => dispatch(actions.changeOfferParameter(obj)),
    changeDocumentParam: obj => dispatch(actions.changeDocumentParam(obj)),
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    saveEditedTermin: (termin, old_termin) => dispatch(actions.saveEditedTermin(termin, old_termin)),
    resetProgramDays: () => dispatch(actions.resetProgramDays()),
    changeOfferCopy: offer_copy => dispatch(actions.changeOfferCopy(offer_copy)),
  };
};

const DateStatusTypeBase = props => {
  return (
    <OfferForm.Consumer>
      {({ error, errorMsg, errors }) => {
        return <DateStatusType error={error} errorMsg={errorMsg} errors={errors} {...props} />;
      }}
    </OfferForm.Consumer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  compose(
    graphql(updateTerminPeriod),
    graphql(updateOffer, { name: "mutationUpdateOffer" }),
    graphql(updateOfferArchiveStatus, { name: "mutationUpdateOfferArchiveStatus" }),
    graphql(createContract, { name: "createContract" }),
  )(withRouter(withApollo(DateStatusTypeBase))),
);
