import React, { Component } from "react";

// Shared components
import Comment from "../../../../components/UI/CommentTextArea";

import EstablishmentContigent from "./EstablishmentContigent";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import { autosize } from "../../../../utility/globals";

class EstablishmentData extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`comment_establishment`).length) {
      autosize(document.getElementsByName(`comment_establishment`)[0]);
    }
  }
  render() {
    // If document is advanced, dont show content
    if (this.props.offer_contract_document_type_id === "2") {
      return null;
    }

    const { termin_periods } = this.props;

    return (
      <React.Fragment>
        {/* Establishment */}

        <React.Fragment>
          {/* Komentar establishmenta  */}
          <div className="form-unit-double">
            <div />
            <Comment
              name="comment_establishment"
              defaultValue={this.props.comment_establishment}
              inputProps={{ disabled: true }}
              label="Komentar smještaja"
            />
          </div>
        </React.Fragment>

        <EstablishmentContigent termin_periods={termin_periods} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      offer_contract_document: { comment_establishment, offer_contract_document_type_id, termin_periods },
    },
  } = state.offer;
  return {
    establishment_id: state.offer.establishment_id,
    offer_contract_document_type_id,
    termin_periods,
    comment_establishment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPickedEstablishment: obj => dispatch(actions.pickEstablishment(obj)),
    removeContigent: id => dispatch(actions.removeContigent(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentData);
