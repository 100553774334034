const contigents = {
  contigents: {
    type: "array",
    minItems: 1,
    errorMessage: "Potrebno je unijeti minimalno jedan kontigent",
    items: {
      type: "object",
      properties: {
        typologies_sum: {
          type: "integer",
          minimum: 0,
          errorMessage: "Potrebno je količinu rezerviranih tipologija",
        },
        typology_id: {
          type: "integer",
          minimum: 1,
          errorMessage: "Odaberite tipologiju",
        },
      },
    },
  },
};

export const termin = {
  properties: {
    termin_periods: {
      type: "array",
      minItems: 1,
      errorMessage: "Potrebno unijeti minimalno jedan termin",
      items: {
        type: "object",
        properties: {
          from: {
            format: "date-time",
            errorMessage: "Termin od mora biti datum",
          },
          to: {
            format: "date-time",
            errorMessage: "Termin do mora biti datum",
          },
          price_structure: {
            type: "object",
            properties: {
              tourist_tax: {
                format: "numeric",
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati boravišnu pristrojbu",
              },
              price_structure_type_id: {
                type: "integer",
                minimum: 1,
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati odabrati tip cjenovne strukture",
              },
              price_typology: {
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati cijenu za odabranu tipologiju",
              },
            },
          },
          ...contigents,
        },
      },
    },
  },
};

export const subtermin = {
  properties: {
    termin_periods: {
      type: "array",
      minItems: 1,
      maxItems: 1,
      errorMessage: "Potrebno unijeti točno jedan glavni termin",
      items: {
        type: "object",
        properties: {
          from: {
            format: "date-time",
            errorMessage: "Termin od mora biti datum",
          },
          to: {
            format: "date-time",
            errorMessage: "Termin do mora biti datum",
          },
          price_structure: {
            type: "object",
            properties: {
              tourist_tax: {
                format: "numeric",
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati boravišnu pristrojbu",
              },
              price_structure_type_id: {
                type: "integer",
                minimum: 1,
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati odabrati tip cjenovne strukture",
              },
              price_typology: {
                isNotEmpty: true,
                errorMessage: "Potrebno je upisati cijenu za odabranu tipologiju",
              },
            },
          },
          subtermin_periods: {
            type: "array",
            minItems: 1,
            errorMessage: "Potrebno unijeti minimalno podtermin",
            items: {
              type: "object",
              properties: {
                from: {
                  format: "date-time",
                  errorMessage: "Termin od mora biti datum",
                },
                to: {
                  format: "date-time",
                  errorMessage: "Termin do mora biti datum",
                },
                ...contigents,
              },
            },
          },
        },
      },
    },
  },
};

export const template = {
  sojourn_tax_value: {
    format: "numeric",
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno i upisano kao broj",
  },
  notification_price_validity: {
    type: "integer",
    isNotEmpty: true,
    minimum: 0,
    errorMessage: "Polje mora biti ispunjeno kao broj",
  },
  notification_booking_state: {
    type: "integer",
    isNotEmpty: true,
    minimum: 0,
    errorMessage: "Polje mora biti ispunjeno kao broj",
  },
  notification_room_list: {
    type: "integer",
    isNotEmpty: true,
    minimum: 0,
    errorMessage: "Polje mora biti ispunjeno kao broj",
  },
  notification_storno: {
    type: "integer",
    isNotEmpty: true,
    minimum: 0,
    errorMessage: "Polje mora biti ispunjeno kao broj",
  },
  notification_payment: {
    type: "integer",
    isNotEmpty: true,
    minimum: 0,
    errorMessage: "Polje mora biti ispunjeno kao broj",
  },
  vat_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  sojourn_tax_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  free_place_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  price_validity_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  surcharges_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  payment_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  storno_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  program_changes_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  content_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  program_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  room_list_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
  booking_state_content: {
    isNotEmpty: true,
    errorMessage: "Polje mora biti ispunjeno",
  },
};

export const offer_schema = {
  type: "object",
  properties: {
    offer_status_id: {
      type: "integer",
      minimum: 1,
      errorMessage: "Potrebno je odabrati status ponude",
    },
    offer_created_at: {
      format: "date-time",
      errorMessage: "Treba biti datum",
    },
    offer_contract_document: {
      type: "object",
      properties: {
        document_date_validity: {
          format: "date-time",
          errorMessage: "Treba biti datum",
        },
        inquiry_external_id: {
          type: "string",
          minimum: 1,
          isNotEmpty: true,
          errorMessage: "Polje ne može biti prazno",
        },
        partner_client_id: {
          type: "integer",
          minimum: 1,
          errorMessage: "Potrebno je izabrati agenciju",
        },
        language_id: {
          type: "integer",
          minimum: 1,
          errorMessage: "Potrebno je izabrati jezik",
        },
        offer_contract_document_type_id: {
          isNotEmpty: true,
          errorMessage: "Potrebno je odabrati tip ponude",
        },
        ...template,
      },
      select: {
        $data: "0/offer_contract_document_type_id",
      },
      selectCases: {
        1: termin,
        // 2 -advanced offer ( tura)
        2: subtermin,
        3: {
          anyOf: [
            {
              properties: {
                offer_contract_document_services: {
                  // isNotEmpty: true,
                  type: "array",
                  minItems: 1,
                  errorMessage: "Potrebno je dodati minimalno jednu uslugu hotela",
                },
              },
            },
            {
              properties: {
                service_objects_to_create: {
                  // isNotEmpty: true,
                  type: "array",
                  minItems: 1,
                  errorMessage: "Potrebno je dodati minimalno jednu uslugu hotela",
                },
              },
            },
          ],
          properties: {
            ...termin.properties,
          },
        }, // Service types must have atleast one service type in service_types or service_type_id_to_create
      },
      selectDefault: true,
    },
  },
};
