import { useCallback, useState, useEffect } from "react";

// libs
import { get } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideIdSelected,
  selectProgramDayActivities,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { DELETE_PARTNER_GUIDE_AND_ACTIVITY } from "src/graphql/mutation/operative";

/**
 * useDay is entry point of data for dayItem component
 */
export default function useDayItem(programDayId) {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);
  const guideIdSelected = useSelector(selectGuideIdSelected);

  const dispatch = useDispatch();

  // change program day activities mutation
  const [deletePartnerGuideAndActivity, { loading: loadingDelete }] = useMutation(DELETE_PARTNER_GUIDE_AND_ACTIVITY);

  /**
   * handle on activity row delete, and set loading for only this element which has focus
   */
  const [programDayContentdIdToDelete, setProgramDayContentdIdToDelete] = useState(null);

  useEffect(() => {
    if (!loadingDelete) {
      setProgramDayContentdIdToDelete(null);
    }
  }, [loadingDelete]);

  const handleOnRowDelete = useCallback(
    async programDayContentId => {
      setProgramDayContentdIdToDelete(programDayContentId);

      const deletePartnerGuideAndActivityResponse = await deletePartnerGuideAndActivity({
        variables: {
          program_day_content_id: programDayContentId,
        },
      });

      const guideActivityDelete = get(deletePartnerGuideAndActivityResponse, "data.deletePartnerGuideAndActivity");

      if (guideActivityDelete) {
        toastr.success("Uspješno izbrisano");

        //todo handle on program update
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programDayContentId) {
                  return {
                    ...programDayContent,
                    activity_id: null,
                    partner_client_id: null,
                    guide_id: null,
                    is_active: false,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });

        dispatch(
          setProgramDayActivities({
            ...programDayActivities,
            program_days: program_days_updated,
            guides: get(guideActivityDelete, "guides", []),
          }),
        );
      } else {
        toastr.error("Greška prilikom brisanja");
      }
    },
    [deletePartnerGuideAndActivity, dispatch, programDayActivities, programDayId],
  );

  return {
    guideIdSelected,
    handleOnRowDelete,
    loadingDelete,
    programDayContentdIdToDelete,
  };
}
