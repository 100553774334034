import React, { Component } from "react";
import InquiryForm from "../Form/InquiryForm";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";

import { createInquiry, inquiryAttachmentUpload } from "../../../graphql/mutation/inquiry";
import { listPartnerClient } from "../../../graphql/query/administration";
import { where_agency_2 } from "../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";

class CreateInquiry extends Component {
  state = {
    inquiry: {
      partner_client_id: "",
      title: "",
      remark: "",
      external_id: "",
      off_key: "",
      year: "",
    },
    selectedFiles: "",
  };

  deletePreparedFileToUpload = index => {
    if (this.state.selectedFiles && this.state.selectedFiles.length) {
      const selectedFiles = [...this.state.selectedFiles];

      selectedFiles.splice(index, 1);

      this.setState({
        selectedFiles,
      });
    }
  };

  changeData = name => selected =>
    this.setState(oldState => {
      return {
        inquiry: { ...oldState.inquiry, [name]: selected },
      };
    });

  changeInquiryFieldData = name => ({ target: { value } }) =>
    this.setState(oldState => {
      return {
        inquiry: { ...oldState.inquiry, [name]: value },
      };
    });

  onDrop = files => {
    this.setState({
      selectedFiles: files,
    });
  };

  uploadAttachments = async id => {
    const { selectedFiles } = this.state;

    if (selectedFiles && selectedFiles.length) {
      selectedFiles.forEach(async file => {
        await this.props.uploadDocument({
          variables: {
            inquiry_id: id,
            file,
          },
        });
      });
    }
  };

  submitData = async () => {
    if (!this.state.inquiry.year || !this.state.inquiry.external_id) {
      toastr.warning("Potrebno je odabrati godinu i upisati povijesni broj");
      return;
    }

    if (!/^\d+$/.test(this.state.inquiry.external_id)) {
      toastr.warning("Povijesni broj mora biti zapisan kao broj");
      return;
    }

    if (this.state.inquiry.external_id.length > 4) {
      toastr.warning("Povijesni broj može imati maksimalno 4 znamenke");
      return;
    }

    try {
      const res = await this.props.mutate({
        variables: {
          input: {
            ...this.state.inquiry,
            external_id: this.state.inquiry.external_id,
            year: parseInt(this.state.inquiry.year),
          },
        },
      });

      const {
        createInquiry: { id },
      } = res.data;

      this.uploadAttachments(id);

      this.props.history.push("/upit");
    } catch (error) {}
  };

  render() {
    const { inquiry } = this.state;

    const {
      listPartnerClient: { loading, listPartnerClient },
    } = this.props;

    if (loading || !listPartnerClient) {
      return <div> Waiting for data from server . . . </div>;
    }

    return (
      <React.Fragment>
        <InquiryForm
          title={`Stvaranje novog upita`}
          actionTitle="Stvori"
          submitData={this.submitData}
          changeData={this.changeData}
          inquiry={inquiry}
          listPartnerClient={listPartnerClient}
          onDrop={this.onDrop}
          files={this.state.selectedFiles}
          changeInquiryFieldData={this.changeInquiryFieldData}
          listPartnerClientQuery={this.props.listPartnerClient}
          deletePreparedFileToUpload={this.deletePreparedFileToUpload}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  graphql(createInquiry),
  graphql(inquiryAttachmentUpload, { name: "uploadDocument" }),
  graphql(listPartnerClient, {
    name: "listPartnerClient",
    options: {
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
          where: where_agency_2,
        },
      },
    },
  }),
)(CreateInquiry);
