import React from "react";

/**
 * Filter for enum types received from backend
 */
export default function FilterLanguage({ filter, onChange, languages }) {
  return languages ? (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : ""}
    >
      <option value="">Prikaži sve</option>
      {languages.map(language => (
        <option key={language.id} value={language.id}>
          {language.desc}
        </option>
      ))}
    </select>
  ) : null;
}
