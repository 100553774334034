import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";

import RaisedButton from "material-ui/RaisedButton";
import { listPartnerClientType } from "../../../../graphql/query/administration";
import EditIcon from "../../../../components/UI/EditIcon";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class PartnerClientTypeList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: partnerClientType => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(partnerClientType)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Opis",
        accessor: "desc",
      },
    ],
  };

  render() {
    const {
      toggleCreate,
      data: { loading, listPartnerClientType },
    } = this.props;

    if (loading) {
      return <div> Loading . . . </div>;
    }

    return (
      <Fragment>
        <TableTitle title="Lista tipa partnera" />

        <ReactTableCustom
          data={listPartnerClientType || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  graphql(listPartnerClientType, {
    options: {
      fetchPolicy: "network-only",
    },
  })(PartnerClientTypeList),
);
