// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideLocalAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_GUIDE_LOCAL_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useGuideLocalAnnouncement is entry point of data for guide local announcement form component
 */
export default function useGuideLocalAnnouncement() {
  // redux state
  const guideLocalAnnouncement = useSelector(selectGuideLocalAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updateGuideLocalAnnouncement, { loading }] = useMutation(UPDATE_GUIDE_LOCAL_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdateGuideLocalAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updateGuideLocalAnnouncement({
      variables: {
        input: {
          id: guideLocalAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateGuideLocalAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ guideLocalAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  /**
   * handle on update hotel details
   */
  const handleOnUpdateHotelDetails = async (establishmentId, keyOfValue, value) => {
    const updateAnnouncement = await updateGuideLocalAnnouncement({
      variables: {
        input: {
          id: guideLocalAnnouncement.id,
          hotel_extra_fields: [
            {
              establishment_id: establishmentId,
              [keyOfValue]: value,
            },
          ],
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateGuideLocalAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ guideLocalAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    guideLocalAnnouncement,
    handleOnUpdateGuideLocalAnnouncement,
    handleOnUpdateHotelDetails,
    loading,
  };
}
