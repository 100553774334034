import { useState } from "react";

// libs
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import { get } from "lodash";

// redux state
import { selectGuideScheduleDrawerPayload, setGuideDrawerPayload, setGuideDrawers } from "src/views/Guides/guidesSlice";

// graphql
import { FETCH_CREATE_ANNOUNCEMENT_ORDER } from "src/graphql/mutation/announcementOrders";
import { GUIDE_DRAWER_ANNOUNCEMENT_ORDER } from "src/utility/constants";

/**
 * useAnnouncementOrderContainer is entry point of data for AnnouncementOrderContainer
 */
export default function useAnnouncementOrderContainer() {
  // history
  let history = useHistory();

  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  // component state
  const [invoiceText, setInvoiceText] = useState("");
  const [roomText, setRoomText] = useState("");
  const [gratisText, setGratisText] = useState("");
  const [extraText, setExtraText] = useState("");

  // mutation
  const [fetchCreateAnnouncementOrder, { loading }] = useMutation(FETCH_CREATE_ANNOUNCEMENT_ORDER);

  /**
   * handle on announcement order create click
   */
  const handleOnAnnouncementOrderCreateClick = async () => {
    const announcementOrderCreateResponse = await fetchCreateAnnouncementOrder({
      variables: {
        input: {
          program_id: guidePayload.programId,
          dictate_invoice_text: guidePayload.invoiceText,
          dictate_room_text: guidePayload.roomText,
          dictate_gratis_text: guidePayload.gratisText,
          dictate_extra_text: guidePayload.extraText,
        },
      },
    });

    const announcementOrderCreated = get(announcementOrderCreateResponse, "data.createDictate");

    if (announcementOrderCreated) {
      toastr.success("Uspješno kreiran nalog");

      // close drawer and reset guide drawer payload
      dispatch(setGuideDrawerPayload({}));

      dispatch(
        setGuideDrawers({
          drawerType: GUIDE_DRAWER_ANNOUNCEMENT_ORDER,
          isOpen: false,
        }),
      );

      // navigate to created announcement order
      history.push(`/nalozi/edit/${announcementOrderCreated.id}`);
    } else {
      toastr.error("Greška prilikom kreiranja, pokušajte ponovo");
    }
  };

  return {
    programId: guidePayload.programId,
    externalId: guidePayload.externalId,
    themeName: guidePayload.themeName,
    terminFrom: guidePayload.terminFrom,
    terminTo: guidePayload.terminTo,
    loading,
    invoiceText,
    setInvoiceText,
    roomText,
    setRoomText,
    gratisText,
    setGratisText,
    extraText,
    setExtraText,
    handleOnAnnouncementOrderCreateClick,
  };
}
