import * as Yup from "yup";

// validation schema
export const templateAnnouncementSchema = Yup.object().shape({
  ann_hotel_guide_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_guide_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_no_guide_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_no_guide_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_tour_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_tour_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_local_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_local_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_tour_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_tour_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_local_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_local_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_local_program_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_agency_local_program_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_supplier_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_supplier_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_phrase_title: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_phrase_intro: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_group_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_termin_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_name_category_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_address_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_check_in_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_check_out_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_breakfast_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_dinner_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_olivari_guide_group_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_voucher_acc_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_guide_acc_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_includes_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_hotel_dinner_drink_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_bus_parking_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_important_notice_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_rooming_list_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_rooming_list_typology_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_rooming_list_contigent_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_driver_contact_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_program_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_invoice_warrant_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_nr_of_persons_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_group_treffpunkt_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_service_favor_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_payment_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_date_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_time_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_language_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_phrase_text_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_footer_message_1_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),

  ann_footer_message_2_label: Yup.object().shape({
    translation: Yup.string().required("Tekst ne može biti prazan"),
  }),
});
