// libs
import { useCallback } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideIdSelected, selectProgramDayActivities, setGuideSelected } from "src/views/Guides/guidesSlice";

/**
 * useGuideList is entry point of data for guideList component
 */
export default function useGuideList() {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);
  const guideIdSelected = useSelector(selectGuideIdSelected);

  const dispatch = useDispatch();

  /**
   * table guide show handling
   */
  const getGuideShowTrProps = useCallback(
    (state, rowInfo, col, instance) => {
      const guideId = get(rowInfo, "original.guide_id");

      return {
        style: {
          background: guideIdSelected === guideId ? "#81D4FA" : "#ffffff",
          cursor: "pointer",
        },
      };
    },
    [guideIdSelected],
  );

  // table tr props, used for styling background row based on group operativa status
  const getGuideShowTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        // get selected guide
        const guideIdToSelect = get(rowInfo, "original.guide_id");

        // save in state
        dispatch(setGuideSelected(guideIdToSelect));
      },
    };
  };

  // handle on clear guide id selected click
  const handleOnGuideFilterClear = () => {
    // save in state
    dispatch(setGuideSelected(null));
  };

  return {
    listGuides: get(programDayActivities, "guides", []),
    getGuideShowTrProps,
    getGuideShowTdProps,
    guideIdSelected,
    handleOnGuideFilterClear,
  };
}
