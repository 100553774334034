import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_TITLE, MARGIN_CONTAINER_DOUBLE_TOP, MARGIN_CONTAINER_DOUBLE_BOTTOM } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // header container
  container: {
    marginTop: MARGIN_CONTAINER_DOUBLE_TOP,
    marginBottom: MARGIN_CONTAINER_DOUBLE_BOTTOM,
    width: "100%",
    textAlign: "center",
  },

  title: {
    textTransform: "uppercase",
    letterSpacing: 2,
    fontSize: FONT_SIZE_TITLE,
    fontFamily: "OpenSansBold",
  },
});

// VoucherTitle
export default function VoucherTitle({ title }) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}
