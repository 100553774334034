import React from "react";

// hooks
import useAnnouncementOrder from "./hooks/useAnnouncementOrder";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import AnnouncementOrderContainer from "./components/Container";

// constants
import { TITLE_GUIDE_ANNOUNCEMENT_ORDER_CREATE } from "src/utility/labels";

/**
 * AnnouncementOrder drawer is used for creating announcement order
 */
export default function AnnouncementOrder() {
  const { isDrawerOpen, loading, onCloseDrawer } = useAnnouncementOrder();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      title={TITLE_GUIDE_ANNOUNCEMENT_ORDER_CREATE}
      customWidth="85%"
    >
      <AnnouncementOrderContainer />
    </DrawerWrapper>
  );
}
