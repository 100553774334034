import React from "react";
import FormValidator from "../../../../components/UI/FormValidator";
import { Grid, FormControl, InputLabel, Input, FormHelperText, Button } from "@material-ui/core";
import { findTypologyByCroLang } from "../../../../utility/establishment";

export const AlotmanContigentEdit = ({ contigentToEdit, saveUpdatedContigent, changeTypologySum }) => {
  return (
    <FormValidator schema={AlotmanContigentEdit.schema} data={contigentToEdit} submit={saveUpdatedContigent}>
      {({ error, errorMsg, submit }) => (
        <div style={{ padding: "12px" }}>
          <Grid container spacing={24}>
            <Grid item xs={5}>
              <FormControl fullWidth error={error("/typologies_sum")}>
                <InputLabel shrink={true} htmlFor="persons_capacity">
                  Br. tipologija
                </InputLabel>
                <Input
                  id="persons_capacity_edit"
                  name="persons_capacity"
                  value={contigentToEdit.typologies_sum}
                  onChange={changeTypologySum}
                />
                {error("/typologies_sum") && <FormHelperText>{errorMsg("/typologies_sum")}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel shrink={true} htmlFor="persons_capacity">
                  Tipologija
                </InputLabel>
                <Input
                  id="persons_capacity"
                  name="persons_capacity"
                  value={contigentToEdit.persons_capacity}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <div className="subtermin-create-buttons">
                <Button variant="contained" color="primary" onClick={submit}>
                  Spremi
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </FormValidator>
  );
};

AlotmanContigentEdit.schema = {
  properties: {
    typologies_sum: {
      type: "integer",
      minimum: 1,
      isNotEmpty: true,
      errorMessage: "Broj tipologija mora biti upisan i mora biti broj",
    },
  },
};

export const AlotmanContigentShow = ({ contigent, prepareContigentToEdit, typologyName }) => {
  const typology = findTypologyByCroLang(contigent.typology);

  return (
    <Grid container spacing={24} key={contigent.id}>
      <Grid item xs={5}>
        <InputLabel shrink={true} htmlFor="persons_capacity">
          Br. tipologija
        </InputLabel>
        <Input id="persons_capacity_edit" name="persons_capacity" value={contigent.typologies_sum} disabled />
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="persons_capacity">
            Tipologija
          </InputLabel>
          <Input
            id="persons_capacity"
            name="persons_capacity"
            value={`${typology.name} ( ${contigent.typology.establishment_code} ) [${
              contigent.typology.persons_capacity
            }]`}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <div className="subtermin-create-buttons">
          <Button variant="contained" color="primary" onClick={prepareContigentToEdit(contigent)}>
            Izmijeni
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
