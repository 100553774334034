import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, TextField } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";
import AutocompleteSelect from "../../../components/UI/AutocompleteSelect";
import FreeText from "../../../components/UI/CommentTextArea";
import DateRangePicker from "../../../components/UI/DateRangePicker";
import FormValidator from "../../../components/UI/FormValidator";
import { createReservationMutation, updateReservationMutation } from "../../../graphql/mutation/alotman";
import { listAlotmanDayByTypology, listReservationStatus } from "../../../graphql/query/alotman";
import { formatDate } from "../../../utility/dates";
import { sortTypologies } from "../../../utility/establishment";
import { difference, formatFromFilterDate, formatToFilterDate } from "../../../utility/globals";
import { prepareForSending } from "../../../utility/prepare";
import get from "lodash/get";

const dayStateStyling = foundDay => {
  if (foundDay) {
    return {
      width: "50%",
      backgroundColor: foundDay.stop_booking ? "red" : "green",
      color: "white",
      borderRadius: "50%",
      margin: "0px auto",
      fontWeight: "bold",
      padding: "2px",
    };
  }

  return {};
};

export default compose(
  graphql(listReservationStatus, {
    skip: ({ create }) => create,
    name: "listStatus",
  }),
  graphql(createReservationMutation, { name: "createReservation" }),
  graphql(updateReservationMutation, { name: "updateReservation" }),
)(
  withApollo(
    class ReservationForm extends Component {
      static schema = {
        type: "object",
        required: ["from", "to", "guest_name", "typology_id", "starting_service_id", "ending_service_id"],
        properties: {
          from: {
            format: "date-time",
            errorMessage: "Termin od mora biti datum",
            not: { type: "null" },
          },
          to: {
            format: "date-time",
            errorMessage: "Termin do mora biti datum",
            not: { type: "null" },
          },
          guest_name: {
            type: "string",
            errorMessage: "Potrebno je upisati gosta",
            minLength: 1,
          },
          typology_id: {
            type: ["string", "integer"],
            minimum: 1,
            minLength: 1,
            errorMessage: "Odaberite tipologiju",
          },
          service_type_id: {
            type: "integer",
            minimum: 1,
            errorMessage: "Odaberite uslugu",
          },
          starting_service_id: {
            type: ["string", "integer"],
            minLength: 1,
            minimum: 1,
            errorMessage: "Odaberite početnu uslugu",
          },
          ending_service_id: {
            type: ["string", "integer"],
            minimum: 1,
            minLength: 1,
            errorMessage: "Odaberite završnu uslugu",
          },
        },
      };

      state = {
        reservation: {
          from: null,
          to: null,
          guest_name: "",
          typology_id: null,
          remark: "",
          service_type_id: null,
          starting_service_id: null,
          ending_service_id: null,
          check_in: "",
          check_out: "",
        },
        termins: [],
        blockedDaysMessage: null,
      };

      submitCreateReservation = async () => {
        try {
          await this.props.createReservation({
            variables: {
              ...this.state.reservation,
              contract_id: this.props.alotman_contract.id,
            },
          });

          toastr.success("Rezervacija uspješno kreirana");
          this.props.toggle();
        } catch (error) {}
      };

      submitUpdateReservation = async () => {
        try {
          const stateReservation = prepareForSending(this.state.reservation, [
            "__typename",
            "contract",
            "service_type",
            "typology",
            "typology_name",
          ]);

          const propsReservation = prepareForSending(this.props.reservation, [
            "__typename",
            "contract",
            "service_type",
            "typology",
            "typology_name",
          ]);

          const reservationDiff = difference(stateReservation, propsReservation);

          if (Object.keys(reservationDiff).length) {
            const response = await this.props.updateReservation({
              variables: {
                id: stateReservation.id,
                patch: {
                  ...reservationDiff,
                },
              },
            });

            this.props.updateReservationInReservations(response.data.updateReservation);

            toastr.success("Rezervacija uspješno ažurirana");
            this.props.toggle();
          }
        } catch (error) {}
      };

      handleFieldChange = key => value => {
        this.setState(prev => ({
          ...prev,
          reservation: { ...prev.reservation, [key]: value },
        }));

        if (key === "typology_id") {
          this.handleFieldChange("from")(null);
          this.handleFieldChange("to")(null);
        }
      };

      handleTextFieldChange = key => ({ target: { value } }) =>
        this.setState(prev => ({
          ...prev,
          reservation: { ...prev.reservation, [key]: value },
        }));

      submitMutation = () => {};

      isDayBlocked = day => {
        const {
          offer_contract_document: { termin_periods },
        } = this.props.alotman_contract;

        for (let i = 0; i < termin_periods.length; ++i) {
          const { from, to } = termin_periods[i];

          const compareDate = moment(formatDate(day), "DD.MM.YYYY");
          const startDate = moment(formatDate(from), "DD.MM.YYYY");
          const endDate = moment(formatDate(moment(to).add(1, "d")), "DD.MM.YYYY");

          if (compareDate.isSame(startDate)) {
            return false;
          }
          if (compareDate.isSame(endDate)) {
            return false;
          }
          if (compareDate.isBetween(startDate, endDate)) {
            return false;
          }
        }

        return true;
      };

      renderDatePickerDay = day => {
        const { termins } = this.state;
        const foundDay = termins.find(termin => formatDate(termin.date) === formatDate(day));

        return (
          <div style={dayStateStyling(foundDay)}>
            <div>{day.format("D")}</div>
          </div>
        );
      };

      async blockedDates(start, end) {
        try {
          const from = formatFromFilterDate(start).startOf("M");
          const to = formatToFilterDate(end).endOf("M");

          const { typology_id } = this.state.reservation;

          const response = await this.props.client.query({
            query: listAlotmanDayByTypology,
            variables: {
              typology_id,
              from,
              to,
            },
          });

          const terminsTOCheck = response.data.listAlotmanDayByTypology;

          const diff = moment(end).diff(start, "days");

          for (let i = 0; i < diff; i++) {
            const checkDate = formatDate(moment(start).add(i, "d"));

            if (
              !terminsTOCheck.find(day => {
                return formatDate(day.date) === checkDate;
              })
            ) {
              return true;
            }
          }
          return false;
        } catch (error) {}
      }

      async fetchAlotmanDays(
        from = formatFromFilterDate().startOf("M"),
        to = formatToFilterDate()
          .add(1, "M")
          .endOf("M"),
      ) {
        let { typology_id } = this.state.reservation;

        try {
          const response = await this.props.client.query({
            query: listAlotmanDayByTypology,
            variables: {
              typology_id,
              from,
              to,
            },
          });

          this.setState({ termins: [...this.state.termins, ...response.data.listAlotmanDayByTypology] });
        } catch (error) {}
      }

      teminPeriodsFetchDays(termin_periods) {
        this.setState({ termins: [] }, () => {
          termin_periods.forEach(({ from, to }) => {
            from = from && formatFromFilterDate(moment(from));
            to = to && formatToFilterDate(moment(to)).subtract(1, "d");

            if (from && to) {
              this.fetchAlotmanDays(from, to);
            } else {
              this.fetchAlotmanDays();
            }
          });
        });
      }

      termins = [];
      terminPicked = false;

      async componentDidUpdate(_, prevState) {
        if (
          prevState.reservation.typology_id !== this.state.reservation.typology_id &&
          this.state.reservation.typology_id
        ) {
          this.setAvailableTerminsForSelectedTypology();

          if (this.props.update) {
            const {
              contract: {
                offer_contract_document: { termin_periods },
              },
            } = this.props.reservation;
            this.teminPeriodsFetchDays(termin_periods);
          }

          if (this.props.create) {
            const {
              offer_contract_document: { termin_periods },
            } = this.props.alotman_contract;

            this.teminPeriodsFetchDays(termin_periods);
          }
        }

        if (!this.state.reservation.typology_id && this.termins.length) {
          this.termins = [];
        }

        if (
          !this.state.reservation.from ||
          !this.state.reservation.to ||
          this.state.reservation.from !== prevState.reservation.from ||
          this.state.reservation.to !== prevState.reservation.to
        ) {
          this.terminPicked = false;
        }

        if (
          this.state.reservation.from &&
          this.state.reservation.to &&
          this.state.reservation.typology_id &&
          !this.terminPicked
        ) {
          // there are blocked days in selected range
          if (await this.blockedDates(this.state.reservation.from, this.state.reservation.to)) {
            this.setState(prev => ({
              ...prev,
              reservation: {
                ...prev.reservation,
                from: null,
                to: null,
              },
              blockedDaysMessage: "U odabranom razdoblju postoje dani koji su blokirani",
            }));
            this.terminPicked = true;
          }
          // no blocked dates so store this termin
          else {
            this.setState({
              blockedDaysMessage: null,
            });
            this.terminPicked = true;
          }
        }
      }

      setAvailableTerminsForSelectedTypology() {
        // to show available dates in calendar
        this.termins = [];

        if (this.props.create) {
          this.props.alotman_contract &&
            this.props.alotman_contract.offer_contract_document &&
            this.props.alotman_contract.offer_contract_document.termin_periods &&
            this.props.alotman_contract.offer_contract_document.termin_periods.forEach(({ from, to, contigents }) => {
              contigents.forEach(({ typology_id }) => {
                if (String(typology_id) === String(this.state.reservation.typology_id)) {
                  let start = moment(from);
                  const end = moment(to).clone();

                  do {
                    this.termins = [...this.termins, moment(start.format())];
                    start.add(1, "d");
                  } while (start.isSameOrBefore(end, "day"));
                }
              });
            });
        } else if (this.props.update) {
          this.props.reservation.contract &&
            this.props.reservation.contract.offer_contract_document &&
            this.props.reservation.contract.offer_contract_document.termin_periods &&
            this.props.reservation.contract.offer_contract_document.termin_periods.forEach(
              ({ from, to, contigents }) => {
                contigents.forEach(({ typology_id }) => {
                  if (String(typology_id) === String(this.state.reservation.typology_id)) {
                    let start = moment(from);
                    const end = moment(to).clone();

                    do {
                      this.termins = [...this.termins, moment(start.format())];
                      start.add(1, "d");
                      // console.log(start, end, start.isSameOrBefore(end, "day"));
                    } while (start.isSameOrBefore(end, "day"));
                  }
                });
              },
            );
        }
      }

      componentDidMount = () => {
        if (this.props.create) {
          this.submitMutation = this.submitCreateReservation;
          get(this.props, "serviceTypes[0].id", "");
          this.handleFieldChange("service_type_id")(get(this.props, "serviceTypes[0].id", ""));
        } else if (this.props.update) {
          this.submitMutation = this.submitUpdateReservation;
          this.setState({
            reservation: {
              ...this.props.reservation,
            },
          });

          const {
            contract: {
              offer_contract_document: { termin_periods },
            },
          } = this.props.reservation;
          this.teminPeriodsFetchDays(termin_periods);
        }
      };

      isDayBlocked = day => {
        return !this.termins.find(termin => formatDate(termin) === formatDate(day));
      };

      render() {
        const { reservation } = this.state;
        const { listStatus } = this.props;
        let listReservationStatus = [];

        if (listStatus) {
          listReservationStatus = listStatus.listReservationStatus;
        }

        let release_day = "";

        if (this.props.update) {
          if (
            this.props.reservation &&
            this.props.reservation.contract &&
            this.props.reservation.contract.offer_contract_document
          ) {
            const {
              contract: {
                offer_contract_document: { release_day: rd },
              },
            } = this.props.reservation;

            release_day = rd;
          }
        }

        if (this.props.create) {
          if (this.props.alotman_contract && this.props.alotman_contract.offer_contract_document) {
            const {
              offer_contract_document: { release_day: rd },
            } = this.props.alotman_contract;

            release_day = rd;
          }
        }

        return (
          <FormValidator schema={ReservationForm.schema} submit={this.submitMutation} data={this.state.reservation}>
            {({ error, errorMsg, submit }) => {
              return (
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <AutocompleteSelect
                      error={error("/typology_id")}
                      errorMsg={errorMsg("/typology_id")}
                      defaultValue={reservation.typology_id || ""}
                      placeholder="Odaberite tipologiju hotela"
                      dataSource={sortTypologies(this.props.typologies)}
                      autocompleteHandler={this.handleFieldChange("typology_id")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateRangePicker
                      showClearDates
                      startDatePlaceholderText="Od"
                      endDatePlaceholderText="Do"
                      from={reservation.from}
                      to={reservation.to}
                      handleFromChange={this.handleFieldChange("from")}
                      handleToChange={this.handleFieldChange("to")}
                      error={error("/from") || error("/to")}
                      errorMsg={(error("/from") || error("/to")) && "Molim unijeti termin"}
                      isDayBlocked={day => this.isDayBlocked(day)}
                      renderDayContents={this.renderDatePickerDay}
                      vertical
                    />
                    <br />
                    {this.state.blockedDaysMessage && (
                      <div style={{ color: "red" }}>{this.state.blockedDaysMessage}</div>
                    )}
                    {this.state.blockedDaysMessage && <br />}

                    {
                      <div
                        disabled
                        style={{ color: "orange" }}
                        id="release_day"
                        className="preview-content-div preview-textarea"
                      >
                        {release_day && release_day.trim()}
                      </div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={error("/guest_name")}>
                      <InputLabel shrink={true} htmlFor="postal_code">
                        Gost
                      </InputLabel>
                      <Input
                        value={reservation.guest_name}
                        onChange={this.handleTextFieldChange("guest_name")}
                        inputProps={{
                          autoComplete: "off",
                        }}
                      />
                      {error("/guest_name") && <FormHelperText>{errorMsg("/guest_name")}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FreeText
                      label="Slobodan tekst"
                      defaultValue={reservation.remark || ""}
                      customOnChange={this.handleTextFieldChange("remark")}
                      placeholder="Slobodan tekst (primjer: „Early booking 20%“)"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteSelect
                      error={error("/service_type_id")}
                      errorMsg={errorMsg("/service_type_id")}
                      defaultValue={reservation.service_type_id || ""}
                      placeholder="Usluga (polupansion, puni pansion, noćenje s doručkom)"
                      dataSource={this.props.serviceTypes}
                      autocompleteHandler={this.handleFieldChange("service_type_id")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteSelect
                      error={error("/starting_service_id")}
                      errorMsg={errorMsg("/starting_service_id")}
                      defaultValue={reservation.starting_service_id || ""}
                      placeholder="Početna usluga (ručak, večera, noćenje)"
                      dataSource={this.props.services}
                      autocompleteHandler={this.handleFieldChange("starting_service_id")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AutocompleteSelect
                      error={error("/ending_service_id")}
                      errorMsg={errorMsg("/ending_service_id")}
                      defaultValue={reservation.ending_service_id || ""}
                      placeholder="Završna usluga (noćenje, doručak ili ručak) "
                      dataSource={this.props.services}
                      autocompleteHandler={this.handleFieldChange("ending_service_id")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FreeText
                      label="Check in"
                      defaultValue={reservation.check_in || ""}
                      customOnChange={this.handleTextFieldChange("check_in")}
                      placeholder="Slobodno polje za tekst „check in“ i „check out“"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FreeText
                      label="Check out"
                      defaultValue={reservation.check_out || ""}
                      customOnChange={this.handleTextFieldChange("check_out")}
                      placeholder="Slobodno polje za tekst „check in“ i „check out“"
                    />
                  </Grid>

                  {this.props.update && (
                    <Grid item xs={12} lg={6}>
                      {listReservationStatus && (
                        <TextField
                          fullWidth
                          select
                          label="Status"
                          value={this.state.reservation.reservation_status_id || ""}
                          onChange={this.handleTextFieldChange("reservation_status_id")}
                        >
                          {listReservationStatus.map(status => {
                            return (
                              <MenuItem key={`status_${status.id}`} value={status.id}>
                                {status.desc}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={submit}>
                      Spremi
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          </FormValidator>
        );
      }
    },
  ),
);
