import { useLocalStorage } from "@rehooks/local-storage";
import { LOCAL_STORAGE_ANNOUNCEMENT_ORDERS_TABLE } from "src/utility/constants";

/**
 * useTableLocalStorage is used for class based components, to use hook
 */
export function useAnnouncementOrdersLocalStorage() {
  const [tableState, setTableState] = useLocalStorage(LOCAL_STORAGE_ANNOUNCEMENT_ORDERS_TABLE, {
    announcementOrdersPageSelected: 0,
    announcementOrdersPageSizeSelected: 10,
    announcementOrdersFiltersSelected: [],
  });

  return { tableState, setTableState };
}
