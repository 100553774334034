import React from "react";

// ui
import Paper from "material-ui/Paper";

// hooks
import useHotelNoGuideAnnouncementList from "./hooks/useHotelNoGuideAnnouncementList";
import useAnnouncements from "src/views/Announcements/hooks/useAnnouncements";

// components
import ReactTableCustom from "src/components/UI/ReactTable";
import AnnouncementView from "../AnnouncementView";

/**
 * HotelNoGuideAnnouncementList is used for showing list of hotelNoGuideAnnouncements
 */

export default function HotelNoGuideAnnouncementList() {
  // hooks
  const {
    // variables
    announcementFilters,
    announcementPageSelected,
    announcementPageSizeSelected,
    canPrevious,
    canNext,
    hotelNoGuideAnnouncementColumns,
    listHotelNoGuideAnnouncement,
    loading,
    pages,

    // functions
    getAnnouncementTableTdProps,
    onAnnouncementsFilterChange,
    onAnnouncementTablePageChange,
    onAnnouncementTablePageSizeChange,
  } = useHotelNoGuideAnnouncementList();

  const {
    // variables
    pageState,
    // functions
    applyPage,
    changePage,
    getSafePage,
    onPageJumpEnterPress,
    setPageState,
  } = useAnnouncements(announcementPageSelected, pages, onAnnouncementTablePageChange);

  // return root
  return (
    <div>
      <Paper zDepth={1}>
        <ReactTableCustom
          columns={hotelNoGuideAnnouncementColumns}
          data={listHotelNoGuideAnnouncement || []}
          page={announcementPageSelected}
          innerProps={{
            defaultFiltered: announcementFilters,
            loading: loading,
            pages: pages,
            pageSize: announcementPageSizeSelected,
            manual: true,
            sortable: false,
            getTdProps: getAnnouncementTableTdProps,
            onFilteredChange: onAnnouncementsFilterChange,
            onPageChange: page => {
              onAnnouncementTablePageChange(page);
            },
            onPageSizeChange: pageSize => {
              onAnnouncementTablePageSizeChange(pageSize);
            },
            renderPageJump: ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => {
              return (
                <div className="-pageJump">
                  <input
                    aria-label={pageJumpText}
                    type={inputType}
                    onChange={e => {
                      const val = e.target.value;
                      const page = val - 1;
                      if (val === "") {
                        return setPageState(val);
                      }
                      return setPageState(getSafePage(page));
                    }}
                    value={pageState === "" ? "" : pageState + 1}
                    onBlur={applyPage}
                    onKeyPress={onPageJumpEnterPress}
                  />
                </div>
              );
            },
            NextComponent: props => {
              return (
                <button
                  disabled={!canNext}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState + 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
            PreviousComponent: props => {
              return (
                <button
                  disabled={!canPrevious}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState - 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
          }}
        />
      </Paper>

      <AnnouncementView />
    </div>
  );
}
