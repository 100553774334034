import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";
import TableTitle from "src/components/UI/TableTitle";

import { createCity } from "../../../../graphql/mutation/administration";

import CityForm from "./CityForm";

class CreateCity extends React.Component {
  state = {
    name: "",
    postal_code: "",
    area_code: "",
    region_id: "",
  };

  changeFieldData = obj => {
    this.setState({
      [obj.key]: obj.value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      this.props.cancelToggle();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title="Unos novog grada" onBackPressed={this.props.cancelToggle} />
        <CityForm
          onSubmit={this.submit}
          city={this.state}
          cancelToggle={this.props.cancelToggle}
          onChangeField={this.changeFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(createCity)(withApollo(CreateCity));
