import React from "react";

// hooks
import usePartnerSearch from "./hooks/usePartnerSearch";
import { TITLE_GUIDE_PARTNER_SEARCH } from "src/utility/labels";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import PartnerSearchContainer from "./components/Container";

export default function PartnerSearch() {
  // hooks
  const {
    isDialogPartnerSearchOpen,
    handleOnDialogClose,
    handleOnPartnerSearchSave,
    loadingUpdatePartnerClient,
  } = usePartnerSearch();

  return (
    <DrawerWrapper
      open={isDialogPartnerSearchOpen}
      toggle={handleOnDialogClose}
      isSaveVisible={true}
      isLoading={loadingUpdatePartnerClient}
      onSave={handleOnPartnerSearchSave}
      title={TITLE_GUIDE_PARTNER_SEARCH}
      customWidth="80%"
    >
      <PartnerSearchContainer />
    </DrawerWrapper>
  );
}
