import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import "../../../styles/offerForm.css";

import { graphql } from "react-apollo";

// GRAPHQL
import { contractData } from "../../../graphql/query/contract";

import Paper from "material-ui/Paper";
import CircularProgress from "material-ui/CircularProgress";

// Shared components
import DividerUI from "../../../components/UI/Divider";

// connected inquiry parts
import InquiryData from "../../Inquiry/InquiryData/InquiryData";

// CreateOffer parts
import OfferDateValidity from "./OfferDateValidity/OfferDateValidity";
import AgencyAndPrograms from "./AgencyAndPrograms/AgencyAndPrograms";
import EstablishmentData from "./EstablishmentData/EstablishmentData";
import TerminData from "./TerminData/TerminData";
import PriceStructure from "./PriceStructure/PriceStructure";

import DateStatusType from "./DateStatusType/DateStatusType";
import EstablishmentService from "./Service/EstablishmentService";
import Templates from "./Templates/Templates";
import AdditionalOffers from "./AdditionalOffers/AdditionalOffers";

import { resetStore } from "../../../store/actions";

import DocumentsUpload from "./DocumentsUpload/Documents";
import OfferField from "./OfferField/OfferField";
import ContractToolbar from "./ContractToolbar";
import ProgramOnlyDailyProgramCreate from "../../Offers/OfferForm/DailyProgram/ProgramOnlyDailyProgramCreate";
import { config } from "../../../utility/globals";
import DailyProgram from "../../Offers/OfferForm/DailyProgram/DailyProgram";
import DailyProgramUsage from "../../Offers/OfferForm/DailyProgram/DailyProgramUsage";
import ShowDailyProgram from "../../Offers/OfferForm/DailyProgram/ShowDailyProgram";
import { Grid } from "@material-ui/core";
import AlotmanTerminsList from "./Alotman/AlotmanTerminsList";
import AlotmanTemplates from "../../Offers/OfferForm/Templates/AlotmanTemplates";

class ContractForm extends Component {
  componentWillUnmount() {
    this.props.resetStore();
  }

  render() {
    const { loading, listContractStatus, listLanguage, listActivity, listPartnerClientService } = this.props.data;

    if (loading || !listContractStatus || !listLanguage || !listActivity || !listPartnerClientService) {
      return <CircularProgress />;
    }

    const {
      offer: {
        offer_contract_document: { offer_contract_document_type_id },
      },
    } = this.props;
    const isAlotman = config.offer_type[offer_contract_document_type_id] === "Alotman";

    const programOnlyTermin = config.offer_type[offer_contract_document_type_id] === "Program only" && (
      <Fragment>
        <ProgramOnlyDailyProgramCreate />
      </Fragment>
    );

    const terminStandardAndTour = config.offer_type[offer_contract_document_type_id] !== "Program only" &&
      config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
        <Fragment>
          <TerminData />
        </Fragment>
      );
    let establishmentData = null;
    if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
      establishmentData = <EstablishmentData isNotDraft={true} />;
    }

    let priceStructure = null;
    if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
      priceStructure = <PriceStructure />;
    }

    return (
      <React.Fragment>
        <ContractToolbar title={this.props.title} onSave={this.props.onSave} />
        <Paper>
          <form className="form">
            <div style={{ paddingBottom: "16px" }}>
              {/* STATUS & OFFER TYPE PICK */}
              <DateStatusType listContractStatus={listContractStatus} listLanguage={listLanguage} />
            </div>

            <Grid container spacing={32}>
              <Grid item xs={12}>
                <OfferField />
              </Grid>
              <Grid item xs={12}>
                <DocumentsUpload
                  deleteUploadedFile={this.props.deleteUploadedFile}
                  onDrop={this.props.onDrop}
                  files={this.props.files}
                />
              </Grid>

              <Grid item xs={12}>
                <InquiryData />
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <DividerUI subheaderText="Info o klijentu i programu" />
                  </Grid>
                  <Grid item xs={12}>
                    <AgencyAndPrograms />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className="second-wrapper">
              <DividerUI subheaderText="Odabir smještaja i termina" />
              {programOnlyTermin}
              {terminStandardAndTour}
            </div>

            <div className="cols-2">
              <div className="form-section-one">
                {establishmentData}

                {/* Alotman Termin when contigent is picked */}
                {config.offer_type[offer_contract_document_type_id] === "Alotman" && <AlotmanTerminsList />}

                <EstablishmentService />
                {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
                  <AdditionalOffers listActivity={listActivity} />
                )}
              </div>
            </div>

            <div className="second-wrapper">
              {/* SHOWING AFTER TERMIN IS PICKED */}

              {priceStructure}

              {isAlotman && <AlotmanTemplates isNotDraft />}
              {!isAlotman && <Templates />}

              <OfferDateValidity />

              {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
                <React.Fragment>
                  {/* Program po danima */}
                  <DividerUI subheaderText="Program po danu i aktivnostima" />
                  <DailyProgramUsage listActivity={listActivity} listPartnerClientService={listPartnerClientService}>
                    <ShowDailyProgram isNotDraft />
                    <DailyProgram isNotDraft />
                  </DailyProgramUsage>
                </React.Fragment>
              )}
            </div>
          </form>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { offer } = state.offer;

  return {
    offer,
  };
};

export default connect(
  mapStateToProps,
  { resetStore },
)(graphql(contractData)(ContractForm));
