import React from "react";

// libs
import { get } from "lodash";
import moment from "moment";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_VALUE, MARGIN_PAGE } from "../utils/constants";

// components
import HeaderVoucher from "../Header";
import VoucherSubjectMultiline from "../SubjectMultiline";
import VoucherTitle from "../Title";
import DateLabel from "../Date";
import VoucherTextField from "../TextField";
import VoucherListField from "../ListField";
import VoucherUserDetails from "../UserDetails";
import VoucherSignature from "../Signature";
import VoucherFooter from "../Footer";
import VoucherFullWidthText from "../FullWidthText";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: MARGIN_PAGE,
    flexGrow: 1,
  },
});

/**
 * VoucherHotelForeignAgency
 */
export default function VoucherHotelForeignAgency({ voucher }) {
  // vocuher data from announcement
  const voucherData = get(voucher, "voucher_data", {});

  // voucher title
  const voucherTitle = get(voucher, "voucher_title", "");

  // hotel
  const hotelValues = [
    get(voucherData, "hotel.hotel_name.value", ""),
    get(voucherData, "hotel.hotel_address_1.value", ""),
    get(voucherData, "hotel.hotel_address_2.value", ""),
  ];

  const hotelCheckInLabel = get(voucherData, "hotel.hotel_check_in.label", "");
  const hotelCheckInValue = get(voucherData, "hotel.hotel_check_in.value", "");

  const hotelCheckOutLabel = get(voucherData, "hotel.hotel_check_out.label", "");
  const hotelCheckOutValue = get(voucherData, "hotel.hotel_check_out.value", "");

  // date created
  const dateCreated = moment(get(voucher, "date", "")).format("DD.MM.YYYY");

  // group
  const groupLabel = get(voucherData, "group.label", "");
  const groupValue = get(voucherData, "group.value", "");

  // termin
  const terminLabel = get(voucherData, "termin.label", "");
  const terminValue = get(voucherData, "termin.value", "");

  // rooming
  const roomingLabel = get(voucherData, "rooming_label.value", "");
  const roomingList = get(voucherData, "rooming", []).map(
    rooming => `${get(rooming, "contigent_value", "")} ${get(rooming, "typology_value", "")}`,
  );

  // payment text
  const voucherPaymentText = get(voucher, "voucher_payment_text", "");

  // voucher created text
  const voucherCreatedByText = get(voucher, "voucher_created_text", "");

  // voucher user details
  const voucherUserFirstName = get(voucher, "user_first_name", "");
  const voucherUserLastName = get(voucher, "user_last_name", "");

  // voucher signature text
  const voucherSignatureText = get(voucher, "voucher_signature_text", "");

  // guide
  const guideFirstName = get(voucherData, "guide_first_name", "");
  const guideLastName = get(voucherData, "guide_last_name", "");

  const guideFullName = `${guideFirstName && guideFirstName} ${guideLastName && guideLastName}`;

  // footer
  const voucherFooter = get(voucher, "voucher_footer_text", "");

  // return root
  return (
    <Document title={`${voucherTitle}-${dateCreated}`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/** header */}
          <HeaderVoucher />

          {/** subject */}
          <VoucherSubjectMultiline subjectTextValues={hotelValues} />

          {/** created date */}
          <DateLabel value={dateCreated} />

          {/** title */}
          <VoucherTitle title={voucherTitle} />

          {/** group name */}
          <VoucherTextField label={groupLabel} value={groupValue} />

          {/** termin */}
          <VoucherTextField label={terminLabel} value={terminValue} />

          {/** rooming */}
          <VoucherListField label={roomingLabel} values={roomingList} />

          {/** services */}
          <VoucherTextField label={"usluga"} value="HP(večera+doručak)" />

          {/** number of persons */}
          <VoucherTextField label={"broj osoba"} value="30" />

          {/** dinner drink */}
          <VoucherFullWidthText
            text={"Getränke zum Abendessen NICHT inkludiert. / Piće uz večeru NIJE uključeno"}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSans" }}
          />

          {/** check in */}
          <VoucherTextField label={hotelCheckInLabel} value={hotelCheckInValue} />

          {/** check out */}
          <VoucherTextField label={hotelCheckOutLabel} value={hotelCheckOutValue} />

          {/** status change */}
          <VoucherFullWidthText
            text="Sollte sich der Zimmerstand oder Personenzahl ändern, bitte unbedingt vom Busfahrer korrigieren und unterschreiben lassen."
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSansBold" }}
          />

          {/** payment text */}
          <VoucherFullWidthText
            text={voucherPaymentText}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSansBold", textDecoration: "underline" }}
          />

          {/** user details */}
          <VoucherUserDetails
            createdByText={voucherCreatedByText}
            firstName={voucherUserFirstName}
            lastName={voucherUserLastName}
          />

          {/** signature */}
          <VoucherSignature signatureText={voucherSignatureText} guideName={guideFullName} />

          <VoucherFooter footerText={voucherFooter} />
        </View>
      </Page>
    </Document>
  );
}
