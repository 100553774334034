import { useCallback } from "react";

// libs
import { useSelector, useDispatch } from "react-redux";

// context
import {
  selectAnnouncementEditInputs,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

/**
 * useLanguageInput is entry point of data for language input component
 */
export default function useLanguageInput(keyOfValue) {
  // redux state
  const announcementEditInputs = useSelector(selectAnnouncementEditInputs);

  const dispatch = useDispatch();

  const handleEditMode = useCallback(
    isEdit => {
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: isEdit }));
    },
    [dispatch, keyOfValue],
  );

  let isEdit = announcementEditInputs && announcementEditInputs[keyOfValue];

  return {
    handleEditMode,
    isEdit,
  };
}
