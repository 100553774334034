import gql from "graphql-tag";
import {
  cityFragment,
  establishmentFragment,
  establishmentLegalEntityFragment,
  partnerClientFragment,
  serviceTypeFragment,
  typologyFragment,
} from "../fragments";

// fragments
import { templateAnnouncementFragment } from "../administrationFragments";

export const listRegion = gql`
  query($input: Condition) {
    listRegion(input: $input) {
      id
      name
      count
      country {
        id
        name
      }
    }
  }
`;

export const fetchRegion = gql`
  query($id: ID!) {
    getRegion(id: $id) {
      id
      name
      country_id
    }
  }
`;

export const fetchCountry = gql`
  query($id: ID!) {
    getCountry(id: $id) {
      id
      name
      iso_3166_code
    }
  }
`;

export const establishmentsFormQuery = gql`
  {
    listLanguage {
      id
      code
      desc
    }

    listEstablishmentChain {
      id
      name
      city_id
      city {
        ...City
      }
    }

    listEstablishmentLegalEntity {
      ...EstablishmentLegalEntity
    }

    listTypologyGroup {
      id
      name
    }
  }

  ${cityFragment}
  ${establishmentLegalEntityFragment}
`;

export const listLanguage = gql`
  {
    listLanguage {
      id
      code
      desc
    }
  }
`;

export const listCountryRegionsCities = gql`
  {
    listCountry {
      id
      name
      iso_3166_code
      regions {
        id
        name
        cities {
          id
          name
          postal_code
          area_code
          region_id
        }
      }
    }
  }
`;

export const listEstablishmentChain = gql`
  query($input: Condition) {
    listEstablishmentChain(input: $input) {
      id
      name
      count
      city_id
      city {
        ...City
      }
    }
  }
  ${cityFragment}
`;

export const getEstablishmentChain = gql`
  query($id: ID!) {
    getEstablishmentChain(id: $id) {
      id
      name
      city_id
      city {
        ...City
      }
    }
  }
  ${cityFragment}
`;

export const getEstablishmentLegalEntity = gql`
  query($id: ID!) {
    getEstablishmentLegalEntity(id: $id) {
      ...EstablishmentLegalEntity
    }
  }
  ${cityFragment}
  ${establishmentLegalEntityFragment}
`;

export const getEstablishment = gql`
  query($id: ID!) {
    getEstablishment(id: $id) {
      ...Establishment
      city {
        ...City
      }
      typologies {
        ...Typology
      }
    }
  }
  ${cityFragment}
  ${typologyFragment}
  ${establishmentFragment}
`;

export const listEstablishmentLegalEntity = gql`
  query($input: Condition) {
    listEstablishmentLegalEntity(input: $input) {
      ...EstablishmentLegalEntity
      count
    }
  }
  ${establishmentLegalEntityFragment}
  ${cityFragment}
`;

export const listCountries = gql`
  query($input: Condition) {
    listCountry(input: $input) {
      id
      name
      count
      iso_3166_code
      __typename
    }
  }
`;

export const listCountryCities = gql`
  {
    listCountry {
      id
      name
      iso_3166_code
      __typename
    }
    listCity {
      ...City
    }
  }
  ${cityFragment}
`;

export const listCities = gql`
  query($input: Condition) {
    listCity(input: $input) {
      ...City
    }
  }
  ${cityFragment}
`;

export const fetchCity = gql`
  query($id: ID!) {
    getCity(id: $id) {
      ...City
    }
  }
  ${cityFragment}
`;

export const listEstablishment = gql`
  query($input: Condition) {
    listEstablishment(input: $input) {
      count
      ...Establishment
      city {
        ...City
      }
      typologies {
        ...Typology
      }
    }
  }
  ${establishmentFragment}
  ${typologyFragment}
  ${cityFragment}
`;

export const listService = gql`
  {
    listService {
      id
      service_translations {
        id
        name
        language_id
        language {
          id
          desc
        }
      }
      service_type {
        ...ServiceType
      }
    }
  }
  ${serviceTypeFragment}
`;

export const listServiceType = gql`
  {
    listServiceType {
      ...ServiceType
    }
  }
  ${serviceTypeFragment}
`;

export const getService = gql`
  query($id: ID!) {
    getService(id: $id) {
      id
      service_type_id
      service_translations {
        id
        name
        service_id
        language_id
        language {
          id
          code
          desc
        }
      }
    }
  }
`;

export const getServiceType = gql`
  query($id: ID!) {
    getServiceType(id: $id) {
      id
      service_type_translations {
        id
        name
        service_type_id
        language_id
        language {
          id
          code
          desc
        }
      }
    }
  }
`;

export const listRole = gql`
  {
    listRole {
      id
      desc
      code
    }
  }
`;

export const listUser = gql`
  {
    listUser {
      id
      first_name
      last_name
      username
      signature_url
      company_id
      roles {
        id
        desc
        code
      }
    }
  }
`;

export const getUser = gql`
  query($id: ID!) {
    getUser(id: $id) {
      id
      first_name
      last_name
      username
      signature_id
      signature_filename
      signature_url
      roles {
        id
        desc
        code
      }
    }
  }
`;

// export const listServiceType = gql`
//   {
//     listServiceType {
//       id
//       desc
//       code
//     }
//   }
// `;

// export const getServiceType = gql`
//   query($id: ID!) {
//     getServiceType(id: $id) {
//       id
//       desc
//       code
//     }
//   }
// `;

export const listActivityType = gql`
  {
    listActivityType {
      id
      desc
      code
    }
  }
`;

export const getActivityType = gql`
  query($id: ID!) {
    getActivityType(id: $id) {
      id
      desc
      code
    }
  }
`;

export const listActivity = gql`
  query($input: Condition) {
    listActivity(input: $input) {
      id
      default_price
      count
      activity_type_id
      activity_type {
        id
        desc
      }
      activity_translations {
        id
        name
        desc
        language_id
        language {
          id
          desc
        }
      }
    }
  }
`;

export const getActivity = gql`
  query($id: ID!) {
    getActivity(id: $id) {
      id
      default_price
      activity_type_id
      activity_translations {
        id
        name
        desc
        language_id
      }
    }
  }
`;

export const listPartnerClient = gql`
  query($input: Condition) {
    listPartnerClient(input: $input) {
      ...Partner
    }
  }
  ${partnerClientFragment}
`;

export const listPartnerClientPagination = gql`
  query($input: Condition) {
    listPartnerClient(input: $input) {
      ...Partner
    }
  }
  ${partnerClientFragment}
`;

export const allListPartnerClients = gql`
  {
    listPartnerClient {
      ...Partner
    }
  }
  ${partnerClientFragment}
`;

export const getPartnerClient = gql`
  query($id: ID!) {
    getPartnerClient(id: $id) {
      ...Partner
      partner_client_activities {
        id
        activity_id
        name
        price
      }
    }
  }
  ${partnerClientFragment}
`;

export const listPartnerClientServiceType = gql`
  {
    listPartnerClientServiceType {
      id
      code
      desc
    }
  }
`;

export const getPartnerClientServiceType = gql`
  query($id: ID!) {
    getPartnerClientServiceType(id: $id) {
      id
      desc
    }
  }
`;

export const listPartnerClientType = gql`
  {
    listPartnerClientType {
      id
      code
      desc
    }
  }
`;

export const getPartnerClientType = gql`
  query($id: ID!) {
    getPartnerClientType(id: $id) {
      id
      desc
    }
  }
`;

export const listTemplateTranslation = gql`
  {
    listTemplateTranslation {
      id
      vat
      free_place
      sojourn_tax
      price_validity
      surcharges
      payment
      storno
      program_changes
      content
      program
      room_list
      booking_state
      language_id
      comment_contigent
      document_date_validity_comment
    }
  }
`;

export const getTemplateTranslation = gql`
  query($id: ID!) {
    getTemplateTranslation(id: $id) {
      id
      vat
      free_place
      price_validity
      surcharges
      sojourn_tax
      payment
      storno
      program_changes
      content
      program
      room_list
      booking_state
      comment_contigent
      document_date_validity_comment
      language_id
    }
  }
`;

export const getTypology = gql`
  query($id: ID!) {
    getTypology(id: $id) {
      ...Typology
    }
  }
  ${typologyFragment}
`;

export const getTypologyGroup = gql`
  query($id: ID!) {
    getTypologyGroup(id: $id) {
      id
      name
      # typologies {
      #   ...Typology
      # }
    }
  }
`;

export const listTypologyGroup = gql`
  query {
    listTypologyGroup {
      id
      name
      typologies {
        ...Typology
      }
    }
  }
  ${typologyFragment}
`;

export const typologyGroups = gql`
  {
    listTypologyGroup {
      id
      name
    }
  }
`;

// template for guides announcement
export const TEMPLATE_LIST_ANNOUNCEMENT = gql`
  query {
    listAnnouncementLabelTranslations {
      ...AnnouncementLabelTranslation
    }
    listLanguage {
      id
      code
      desc
      __typename
    }
  }
  ${templateAnnouncementFragment}
`;
