// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectSupplierAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_SUPPLIER_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useSupplierAnnouncement is entry point of data for supplier announcement form component
 */
export default function useSupplierAnnouncement() {
  // redux state
  const supplierAnnouncement = useSelector(selectSupplierAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updateSupplierAnnouncement, { loading }] = useMutation(UPDATE_SUPPLIER_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdateSupplierAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updateSupplierAnnouncement({
      variables: {
        input: {
          id: supplierAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateSupplierAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ supplierAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    handleOnUpdateSupplierAnnouncement,
    supplierAnnouncement,
    loading,
  };
}
