// libs
import { createSlice } from "@reduxjs/toolkit";
// constants
import { HOTEL_GUIDE_ANNOUNCEMENT } from "src/utility/constants";

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    announcementTypeSelected: HOTEL_GUIDE_ANNOUNCEMENT,
    announcementsPageSelected: {
      HOTEL_GUIDE_ANNOUNCEMENT: 0,
      HOTEL_NO_GUIDE_ANNOUNCEMENT: 0,
      GUIDE_ANNOUNCEMENT: 0,
      GUIDE_TOUR_ANNOUNCEMENT: 0,
      GUIDE_LOCAL_ANNOUNCEMENT: 0,
      AGENCY_ANNOUNCEMENT: 0,
      AGENCY_TOUR_ANNOUNCEMENT: 0,
      AGENCY_LOCAL_ANNOUNCEMENT: 0,
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: 0,
      SUPPLIER_ANNOUNCEMENT: 0,
      PHRASE_ANNOUNCEMENT: 0,
    },
    announcementsPageSizeSelected: {
      HOTEL_GUIDE_ANNOUNCEMENT: 10,
      HOTEL_NO_GUIDE_ANNOUNCEMENT: 10,
      GUIDE_ANNOUNCEMENT: 10,
      GUIDE_TOUR_ANNOUNCEMENT: 10,
      GUIDE_LOCAL_ANNOUNCEMENT: 10,
      AGENCY_ANNOUNCEMENT: 10,
      AGENCY_TOUR_ANNOUNCEMENT: 10,
      AGENCY_LOCAL_ANNOUNCEMENT: 10,
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: 10,
      SUPPLIER_ANNOUNCEMENT: 10,
      PHRASE_ANNOUNCEMENT: 10,
    },
    announcementsFilters: {
      HOTEL_GUIDE_ANNOUNCEMENT: [],
      HOTEL_NO_GUIDE_ANNOUNCEMENT: [],
      GUIDE_ANNOUNCEMENT: [],
      GUIDE_TOUR_ANNOUNCEMENT: [],
      GUIDE_LOCAL_ANNOUNCEMENT: [],
      AGENCY_ANNOUNCEMENT: [],
      AGENCY_TOUR_ANNOUNCEMENT: [],
      AGENCY_LOCAL_ANNOUNCEMENT: [],
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: [],
      SUPPLIER_ANNOUNCEMENT: [],
      PHRASE_ANNOUNCEMENT: [],
    },
  },
  reducers: {
    setAnnouncementTypeSelected: (state, action) => {
      state.announcementTypeSelected = action.payload;
    },

    // announcements table page selected
    setAnnouncementsPageSelected: (state, action) => {
      state.announcementsPageSelected = {
        ...state.announcementsPageSelected,
        [action.payload.announcementType]: action.payload.pageSelected,
      };
    },

    // announcements table page size selected
    setAnnouncementsPageSizeSelected: (state, action) => {
      state.announcementsPageSizeSelected = {
        ...state.announcementsPageSizeSelected,
        [action.payload.announcementType]: action.payload.pageSizeSelected,
      };
    },

    // announcements table filters selected
    setAnnouncementsFilters: (state, action) => {
      state.announcementsFilters = {
        ...state.announcementsFilters,
        [action.payload.announcementType]: action.payload.filters,
      };
    },

    setHotelGuideAnnouncements: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.hotelGuideAnnouncements = action.payload;
    },

    setHotelNoGuideAnnouncements: (state, action) => {
      state.hotelNoGuideAnnouncements = action.payload;
    },

    setGuideAnnouncements: (state, action) => {
      state.guideAnnouncements = action.payload;
    },

    setGuideTourAnnouncements: (state, action) => {
      state.guideTourAnnouncements = action.payload;
    },

    setGuideLocalAnnouncements: (state, action) => {
      state.guideLocalAnnouncements = action.payload;
    },

    setAgencyAnnouncements: (state, action) => {
      state.agencyAnnouncements = action.payload;
    },

    setAgencyTourAnnouncements: (state, action) => {
      state.agencyTourAnnouncements = action.payload;
    },

    setAgencyLocalAnnouncements: (state, action) => {
      state.agencyLocalAnnouncements = action.payload;
    },

    setAgencyLocalProgramAnnouncements: (state, action) => {
      state.agencyLocalProgramAnnouncements = action.payload;
    },

    setSupplierAnnouncements: (state, action) => {
      state.supplierAnnouncements = action.payload;
    },

    setPhraseAnnouncements: (state, action) => {
      state.phraseAnnouncements = action.payload;
    },

    addPhraseAnnouncement: (state, action) => {
      state.phraseAnnouncements = [...state.phraseAnnouncements, action.payload];
    },

    updatePhraseAnnouncements: (state, action) => {
      state.phraseAnnouncements = state.phraseAnnouncements.map(phraseAnnouncement =>
        phraseAnnouncement.id === action.phraseAnnouncement.id ? action.phraseAnnouncement : phraseAnnouncement,
      );
    },

    setDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    },

    setAnnouncementSelected: (state, action) => {
      state.announcementSelected = action.payload;
    },

    setPhraseCreateDialogOpen: (state, action) => {
      state.isPhraseCreateOpen = action.payload;
    },

    setAnnouncementConstants: (state, action) => {
      // list language
      if (action.payload.listLanguage) {
        state.listLanguage = action.payload.listLanguage;
      }

      // announcement status types
      if (action.payload.announcementStatusTypes) {
        state.announcementStatusTypes = action.payload.announcementStatusTypes;
      }

      // voucher status types
      if (action.payload.voucherStatusTypes) {
        state.voucherStatusTypes = action.payload.voucherStatusTypes;
      }
    },
  },
});

export const {
  setAnnouncementTypeSelected,
  setAnnouncementsPageSelected,
  setAnnouncementsPageSizeSelected,
  setAnnouncementsFilters,
  setHotelGuideAnnouncements,
  setHotelNoGuideAnnouncements,
  setGuideAnnouncements,
  setGuideTourAnnouncements,
  setGuideLocalAnnouncements,
  setAgencyAnnouncements,
  setAgencyTourAnnouncements,
  setAgencyLocalAnnouncements,
  setAgencyLocalProgramAnnouncements,
  setSupplierAnnouncements,
  setPhraseAnnouncements,
  addPhraseAnnouncement,
  updatePhraseAnnouncements,
  setDrawerOpen,
  setAnnouncementSelected,
  setPhraseCreateDialogOpen,
  setAnnouncementConstants,
} = announcementSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`
export const selectAnnouncementTypeSelected = state => state.announcement.announcementTypeSelected;

// announcements table page selected
export const selectAnnouncementsPageSelected = state => state.announcement.announcementsPageSelected;

// announcements table page size selected
export const selectAnnouncementsPageSizeSelected = state => state.announcement.announcementsPageSizeSelected;

// announcements table filters selected
export const selectAnnouncementsFilters = state => state.announcement.announcementsFilters;

// announcements
export const selectHotelGuideAnnouncements = state => state.announcement.hotelGuideAnnouncements;
export const selectHotelNoGuideAnnouncements = state => state.announcement.hotelNoGuideAnnouncements;
export const selectGuideAnnouncements = state => state.announcement.guideAnnouncements;
export const selectGuideTourAnnouncements = state => state.announcement.guideTourAnnouncements;
export const selectGuideLocalAnnouncements = state => state.announcement.guideLocalAnnouncements;
export const selectAgencyAnnouncements = state => state.announcement.agencyAnnouncements;
export const selectAgencyTourAnnouncements = state => state.announcement.agencyTourAnnouncements;
export const selectAgencyLocalAnnouncements = state => state.announcement.agencyLocalAnnouncements;
export const selectAgencyLocalProgramAnnouncements = state => state.announcement.agencyLocalProgramAnnouncements;
export const selectSupplierAnnouncements = state => state.announcement.supplierAnnouncements;
export const selectPhraseAnnouncements = state => state.announcement.phraseAnnouncements;

// is drawer announcement view open
export const selectIsDrawerOpen = state => state.announcement.isDrawerOpen;

// announcement selected
export const selectAnnouncementSelected = state => state.announcement.announcementSelected;

// phrase create dialog
export const selectIsPhraseCreateDialogOpen = state => state.announcement.isPhraseCreateOpen;

// languages
export const selectLanguages = state => state.announcement.listLanguage;

export default announcementSlice.reducer;
