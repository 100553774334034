import { all, put, takeEvery } from "redux-saga/effects";
import { setupInitialOfferDraft } from "../actions";
import { SETUP_DRAFT_OFFER_ASYNC } from "../actionTypes";

function* setupDraft(action) {
  yield put(setupInitialOfferDraft(action.param.offer_data));

  yield action.param.callback();
}

function* watchSetupDraft() {
  yield takeEvery(SETUP_DRAFT_OFFER_ASYNC, setupDraft);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchSetupDraft()]);
}
