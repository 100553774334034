import React, { Component } from "react";

import Comment from "../../../../components/UI/CommentTextArea";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";

import ContigentsList from "./ContigentsList";
import { config, autosize } from "../../../../utility/globals";

class EstablishmentContigent extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`comment_contigent`).length) {
      autosize(document.getElementsByName(`comment_contigent`)[0]);
    }
  }

  render() {
    const { termin_periods, offer_contract_document_type_id } = this.props;

    let contigentsList = null;

    const isAlotman = config.offer_type[offer_contract_document_type_id] === "Alotman";

    if (termin_periods && termin_periods.length && termin_periods[0].contigents.length && !isAlotman) {
      contigentsList = (
        <ContigentsList
          language_id={this.props.language_id}
          termin_periods={this.props.termin_periods}
          removeContigent={this.props.removeContigent}
        />
      );
    }

    return (
      <React.Fragment>
        {/* Komentar kontigenta */}
        {!isAlotman && (
          <div className="form-unit-double">
            <div />
            {contigentsList}
          </div>
        )}
        {/* Komentar kontigenta */}
        <div className="form-unit-double">
          <div />
          <Comment
            name="comment_contigent"
            inputProps={{ disabled: true }}
            defaultValue={this.props.comment_contigent}
            label="Komentar kontigenta"
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    typologies_sum,
    typology_id,
    typologies,
    offer: {
      offer_contract_document: { termin_periods, language_id, comment_contigent, offer_contract_document_type_id },
    },
  } = state.offer;
  return {
    typologies_sum,
    language_id,
    typology_id,
    typologies,
    termin_periods,
    comment_contigent,
    offer_contract_document_type_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    onAddContigent: () => dispatch(actions.addContigent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentContigent);
