import { useCallback } from "react";

// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectContactIdSelected, setPartnerContactSelected } from "src/views/Guides/guidesSlice";

/**
 * useContactSubItem is entry point of data for contactSubItem component
 */
export default function useContactSubItem() {
  // redux state
  const contactIdSelected = useSelector(selectContactIdSelected);

  const dispatch = useDispatch();

  /**
   * handle on contact selct
   */
  const handleOnContactSelect = useCallback(
    contactId => {
      // check if user has clicked on same contact, so we can deselect it
      const contactIdToSelect = contactIdSelected === contactId ? null : contactId;

      dispatch(setPartnerContactSelected(contactIdToSelect));
    },
    [contactIdSelected, dispatch],
  );

  return {
    contactIdSelected,
    handleOnContactSelect,
  };
}
