import { useLocalStorage } from "@rehooks/local-storage";

/**
 * useTableLocalStorage is used for class based components, to use hook
 */
export function useTableLocalStorage(tableName) {
  const [tableState, setTableState] = useLocalStorage(tableName, {
    pageSelected: 0,
    pageSizeSelected: 10,
    filtersSelected: [],
  });

  return { tableState, setTableState };
}
