import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import debounce from "lodash/debounce";

import RaisedButton from "material-ui/RaisedButton";
import { cityInfo } from "../../../../utility/city";
import { listEstablishmentLegalEntity } from "../../../../graphql/query/administration";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import EditIcon from "../../../../components/UI/EditIcon";
import { DEFAULT_PAGE_SIZE, DEBOUNCE_FETCH_DELAY } from "../../../../utility/reactTable";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import _ from "lodash";
import TableTitle from "src/components/UI/TableTitle";

const getPreparedFilters = filtersSelected => {
  // prepare filters sent from react table (or empty object if there are none)
  const filtersPrepared =
    filtersSelected && filtersSelected.length
      ? Object.assign(...filtersSelected.map(entry => ({ [entry.id]: entry.value })))
      : {};

  let filtersToSend = {};
  for (let key in filtersPrepared) {
    switch (key) {
      case "city":
        filtersToSend = {
          ...filtersToSend,
          city: {
            name: filtersPrepared[key],
          },
        };
        break;
      default:
        filtersToSend = {
          ...filtersToSend,
          [key]: filtersPrepared[key],
        };
    }
  }

  if (_.isEmpty(filtersToSend)) {
    return null;
  }

  return JSON.stringify(filtersToSend);
};

class EstablishmentLegalEntityList extends Component {
  state = {
    listEstablishmentLegalEntity: [],
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: establishment => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(establishment)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Ime",
        accessor: "name",
      },
      {
        Header: "OIB",
        accessor: "oib",
      },
      {
        Header: "Web adresa",
        accessor: "web",
      },
      {
        Header: "Adresa 1",
        accessor: "address1",
      },
      {
        Header: "Adresa 2",
        accessor: "address2",
      },
      {
        Header: "Grad",
        accessor: "city",
      },
      {
        Header: "Kontakt osoba 1",
        accessor: "contact_person1",
      },
      {
        Header: "Kontakt osoba 2",
        accessor: "contact_person2",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telefon",
        accessor: "phone",
      },
      {
        Header: "Fax",
        accessor: "fax",
      },
    ],
    filtersPrepared: {},
    pages: null,
    currentPage: null,
    pageSize: DEFAULT_PAGE_SIZE,
    loading: true,
  };

  fetchMoreObject = {};
  _isMounted = false;
  firstMount = true;
  firstSetPages = true;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // react table's function on a change
  fetchData = async state => {
    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      const response = await this.props.client.query({
        query: listEstablishmentLegalEntity,
        variables: {
          input: {
            paginationLimit: {
              limit: pageSizeSelected,
              offset: pageSelected * pageSizeSelected,
            },
            ...(getPreparedFilters(this.props.tableState.filtersSelected) && {
              where: getPreparedFilters(this.props.tableState.filtersSelected),
            }),
          },
        },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        this.setStateAfterFetch(response, state);
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response, state) {
    if (response.data.listEstablishmentLegalEntity && response.data.listEstablishmentLegalEntity[0]) {
      // when we get normal reponse, ceil up page number
      this.setState({
        listEstablishmentLegalEntity: response.data.listEstablishmentLegalEntity,
        pages: Math.ceil(response.data.listEstablishmentLegalEntity[0].count / state.pageSize),
        loading: false,
      });
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        listEstablishmentLegalEntity: [],
        pages: null,
        loading: false,
      });
    }
  }

  dataToShow = () => {
    const { listEstablishmentLegalEntity } = this.state;

    return (
      (listEstablishmentLegalEntity &&
        listEstablishmentLegalEntity.map(est => {
          return {
            ...est,
            city: cityInfo(est.city),
          };
        })) ||
      []
    );
  };

  render() {
    const { toggleCreate } = this.props;

    return (
      <Fragment>
        <TableTitle title="Lista firmi" />

        <ReactTableCustom
          data={this.dataToShow() || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: this.state.loading,
            pages: this.state.pages,
            manual: true,
            sortable: false,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(withApollo(EstablishmentLegalEntityList));
