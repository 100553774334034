import React from "react";
// ui
import { TextField, MenuItem, Grid } from "@material-ui/core";
// hooks
import useBusTransferContainer from "./hooks/useBusTransferContainer";
/**
 * BusTransferContainer contains all components inside BusTransferDrawer
 */
export default function BusTransferContainer() {
  const { handleOnBusTransferStatusChange, transportStatus } = useBusTransferContainer();

  // return root
  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <TextField
            fullWidth
            select
            label="Status"
            value={transportStatus}
            onChange={event => handleOnBusTransferStatusChange(event.target.value)}
          >
            <MenuItem key={`key-status-bus-true`} value={true}>
              Da
            </MenuItem>

            <MenuItem key={`key-status-bus-false`} value={false}>
              Ne
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}
