import React from "react";
import debounce from "lodash/debounce";
// Shared components
import AutocompleteSelect from "../../../../../components/UI/AutocompleteSelect";

import EstablishmentContigent from "./EstablishmentContigent";

import { connect } from "react-redux";

import * as actions from "../../../../../store/actions";
import { config, removeDuplicateObjects } from "../../../../../utility/globals";
import get from "lodash/get";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../../utility/constants";

class EstablishmentSubterminData extends React.Component {
  state = { fetchingMore: false };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  where = {};

  fetchDataAfterInputChange = async () => {
    await this.props.listEstablishmentQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listEstablishment.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listEstablishment: [...fetchMoreResult.listEstablishment],
        };
      },
    });

    this.setState({ fetchingMore: false });
  };

  handleAutocompleteInputChange = async value => {
    if (this.where.name === value) {
      return;
    }

    this.where = { name: value };
    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: JSON.stringify(this.where),
      },
    };

    this.setState({ fetchingMore: true }, this.fetchDataAfterInputChange);
  };

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listEstablishment &&
      listEstablishment.length >= PAGINATION_LIMIT
    ) {
      this.setState({ fetchingMore: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listEstablishmentQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listEstablishment.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listEstablishment: [...previousResult.listEstablishment, ...fetchMoreResult.listEstablishment],
            };
          },
        });

        this.setState({ fetchingMore: false });
      });
    }
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  render() {
    // If document is advanced, show content
    if (config.offer_type[this.props.offer_contract_document_type_id] !== "Tour") {
      return null;
    }

    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);
    const loadingEstablishments = get(this.props, "listEstablishmentQuery.loading");

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    const { create, establishment } = this.props;

    const combinedEstablishments = establishment
      ? removeDuplicateObjects(listEstablishment.concat(establishment))
      : listEstablishment;

    return (
      <React.Fragment>
        {/* Establishment */}

        <React.Fragment>
          <div className="form-unit-double">
            <div className="field-label is-normal">
              <label className="label">Smještaj</label>
            </div>
            <AutocompleteSelect
              defaultValue={this.props.subtermin_establishment_id}
              autocompleteHandler={selected => {
                this.props.onPickedSubterminEstablishment({
                  key: "subtermin_establishment_id",
                  value: selected,
                  listEstablishment: combinedEstablishments,
                });
              }}
              dataSource={combinedEstablishments.map(est => ({
                label: `${est.name} - ${est.city ? est.city.name : ""}`,
                value: est.id,
              }))}
              inputProps={{
                onInputChange: this.handleAutocompleteInputChangeDebounced,
              }}
              onMenuScrollToBottom={this.handleScroller}
              isLoading={loadingEstablishments || this.state.fetchingMore}
            />
          </div>
        </React.Fragment>

        <EstablishmentContigent create={create} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    establishment,
    offer: {
      offer_contract_document: { offer_contract_document_type_id },
    },
    subtermin_establishment_id,
  } = state.offer;
  return {
    establishment,
    subtermin_establishment_id,
    offer_contract_document_type_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPickedSubterminEstablishment: obj => dispatch(actions.pickSubterminEstablishment(obj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentSubterminData);
