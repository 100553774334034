// libs
import { useQuery } from "react-apollo";

// graphql
import { GUIDES_GRID_FILTERS } from "src/graphql/query/operative";

/**
 * useGridFilters is used for fetching available grid filters
 */
export const useFetchGridFilters = variables => {
  const { data, loading, error } = useQuery(GUIDES_GRID_FILTERS, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
  });

  return {
    dataGridFilters: data,
    loadingGridFilters: loading,
    errorGridFilters: error,
  };
};
