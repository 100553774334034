import React from "react";

// hooks
import useYearFilter from "./hooks/useYearFilter";
// libs
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./style.css";
import { Paper, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "material-ui/svg-icons/navigation/expand-more";

const InputComponent = ({ year, onClick }) => {
  return (
    <Paper className="paperContainer" onClick={onClick}>
      <Grid container className="gridContainer">
        <Grid item xs={9}>
          <Typography className="viewItem" variant="body2">
            {year}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <ExpandMoreIcon className="expandIcon" />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function YearFilter() {
  const { filterDateSelected, handleYearChange } = useYearFilter();

  // return root
  return (
    <Datetime
      value={filterDateSelected}
      renderInput={props => <InputComponent year={filterDateSelected.year()} onClick={props.onClick} />}
      dateFormat="YYYY"
      closeOnSelect={true}
      onChange={date => {
        return handleYearChange(date);
      }}
    />
  );
}
