// libs
import { useMutation } from "react-apollo";
import toastr from "toastr";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// graphql
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectGuideScheduleDrawers,
  selectGuideSchedules,
  setGuideDrawerPayload,
  setGuideDrawers,
  setGuideSchedules,
} from "src/views/Guides/guidesSlice";

// constants
import { GUIDE_DRAWER_BUS_TRANSFER } from "src/utility/constants";

/**
 * useBusTransfer is entry point of data for busTransferDrawer
 */
export default function useBusTransfer() {
  // redux state
  const guideDrawers = useSelector(selectGuideScheduleDrawers);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_BUS_TRANSFER,
        isOpen: false,
      }),
    );
  };

  // updateGuideExtraFields mutation
  const [updateGuideExtraFields, { loading }] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  /**
   * handle on bus transfer change - mutation
   */
  const handleOnBusTransferStatusChange = async () => {
    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: guidePayload.programId,
          transport: guidePayload.transportStatus,
        },
      },
    });

    let guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran status");

      // update list guides
      let listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === guidePayload.programId) {
          return {
            ...guide,
            transport: get(guideUpdate, "transport"),
          };
        }
        return guide;
      });

      // update guide shedules list in state
      dispatch(setGuideSchedules(listGuidesUpdate));

      // close drawer
      onCloseDrawer();
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    handleOnBusTransferStatusChange,
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_BUS_TRANSFER],
    loading,
    onCloseDrawer,
  };
}
