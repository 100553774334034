// libs
import { useDispatch } from "react-redux";
import { useAnnouncementsLocalStorage } from "src/views/Announcements/hooks/useAnnouncementsLocalStorage";

// redux state
import { setPhraseCreateDialogOpen } from "../../../announcementSlice";

/**
 * useToolbar is entry point of data for toolbar component
 */
export default function useToolbar() {
  // local storage
  const { tableState, setTableState } = useAnnouncementsLocalStorage();

  const announcementTypeSelected = tableState.announcementTypeSelected;
  const announcementFilters = tableState.announcementsFiltersSelected[announcementTypeSelected];

  // redux state
  const dispatch = useDispatch();

  /**
   * handle on announcement type select
   */
  const handleOnAnnouncementTypeSelect = announcementTypeSelected => {
    setTableState({ ...tableState, announcementTypeSelected: announcementTypeSelected });
  };

  /**
   * handle on phrase announcement create click
   */
  const handleOnAnnouncementPhraseCreateClick = () => {
    dispatch(setPhraseCreateDialogOpen(true));
  };

  /**
   * handleOnFilterResetClick
   */
  const handleOnFilterResetClick = () => {
    setTableState({
      ...tableState,
      announcementsPageSelected: { ...tableState.announcementsPageSelected, [announcementTypeSelected]: 0 },
      announcementsPageSizeSelected: { ...tableState.announcementsPageSizeSelected, [announcementTypeSelected]: 10 },
      announcementsFiltersSelected: { ...tableState.announcementsFiltersSelected, [announcementTypeSelected]: [] },
    });
  };

  /**
   * get is reset disabled
   */
  const getIsResetEnabled = () => {
    if (announcementFilters) {
      return announcementFilters.length;
    } else {
      return false;
    }
  };

  return {
    announcementTypeSelected,
    getIsResetEnabled,
    handleOnAnnouncementPhraseCreateClick,
    handleOnAnnouncementTypeSelect,
    handleOnFilterResetClick,
  };
}
