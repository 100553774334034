import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";

import { removeDuplicateObjects } from "../../../utility/globals";
import { round } from "../../../utility/numbers";
import { formatDate } from "../../../utility/dates";

import { getTypology } from "../../../graphql/query/administration";
import { Paper, Table, TableHead, TableRow, withStyles, TableCell, TableBody } from "@material-ui/core";
import { connect } from "react-redux";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "slategrey",
    color: theme.palette.common.white,
    fontSize: "0.87rem",
  },
  body: {
    fontSize: 12,
  },
  "@global": {
    "div table th:first-of-type": {
      padding: "4px 56px 4px 24px !important",
    },
  },
}))(TableCell);

class PriceStructureAlotmanTermins extends Component {
  state = {
    ui: null,
    typologiesFiltered: null,
  };

  async filterTypologies() {
    const { termin_periods, client: serverQuery } = this.props;

    let typologies = [];

    await Promise.all(
      termin_periods &&
        termin_periods.map(async termin => {
          const { contigents } = termin;

          // getting typology data
          const contigentTypologies =
            (await Promise.all(
              contigents &&
                contigents.map(async ({ typology_id }) => {
                  const response = await serverQuery.query({
                    query: getTypology,
                    variables: {
                      id: typology_id,
                    },
                  });

                  const typology = response.data.getTypology;

                  return typology;
                }),
            )) || [];

          typologies = [...typologies, ...contigentTypologies];
        }),
    );

    const typologiesFiltered = removeDuplicateObjects(typologies).sort(
      (a, b) => a.persons_capacity - b.persons_capacity,
    );

    this.setState({
      typologiesFiltered,
    });
  }

  async componentDidMount() {
    this.filterTypologies();
  }

  getTerminTypologyPrice = termin => {
    let priceToShow = [];

    priceToShow = this.state.typologiesFiltered.map((typology, index) => {
      const contigentTypologyFound = termin.contigents.find(contigent => {
        const { typology_id } = contigent;
        return typology_id === typology.id;
      });

      if (!contigentTypologyFound) {
        return <CustomTableCell key={index} />;
      } else {
        return (
          <CustomTableCell key={index} component="th" scope="row">
            {`${round(termin.price_structure[`price_typology_${typology.persons_capacity}`]).toFixed(2)} €`}
          </CustomTableCell>
        );
      }
    });

    return priceToShow;
  };

  render() {
    const { int, language_id, termin_periods } = this.props;
    const { typologiesFiltered } = this.state;

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.price}</h4>
        </div>
        <div id="alotmanPriceStructure">
          {typologiesFiltered && (
            <Paper elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Datum</CustomTableCell>
                    {typologiesFiltered.map(typology => {
                      const typology_translated = typology.typology_translations.find(
                        translation => translation.language_id === language_id,
                      );

                      return (
                        <CustomTableCell
                          key={`typology_translated_${typology_translated.name}_${typology.id}`}
                        >{`${typology_translated.name}[${typology.persons_capacity}]`}</CustomTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {termin_periods.map(termin => {
                    return (
                      <TableRow key={termin.id}>
                        <CustomTableCell component="th" scope="row">
                          {formatDate(termin.from)} - {formatDate(termin.to)}
                        </CustomTableCell>
                        {this.getTerminTypologyPrice(termin)}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    templateInt,
    listTypology,
    preview: {
      offer_contract_document: { termin_periods, language_id },
    },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();
  return {
    int,
    termin_periods,
    listTypology,
    language_id,
  };
};

const PriceStructureAlotmanTerminsPreview = connect(mapStateToProps)(withApollo(PriceStructureAlotmanTermins));

function PriceStructureUsage() {
  return (
    <Fragment>
      <PriceStructureAlotmanTerminsPreview />
    </Fragment>
  );
}

export default PriceStructureUsage;
