import React from "react";
import { graphql } from "react-apollo";

import Paper from "material-ui/Paper";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import PersonIcon from "material-ui/svg-icons/social/person";

import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";

import PersonDropdown from "../UI/PersonDropdown";
import HistoryLog from "./HistoryLog";

import { listNotifications } from "../../graphql/query/notification";

export const NotificationContext = React.createContext();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 3,
      openHistory: false,
    };
  }

  handleClickAway = () => {
    this.setState({
      openHistory: false,
    });
  };

  toggleHistoryLog = () =>
    this.setState(prevState => {
      return {
        openHistory: !prevState.openHistory,
      };
    });

  render() {
    const { listNotification } = this.props.data;

    return (
      <NotificationContext.Provider value={{ listNotification }}>
        <Paper zDepth={1} rounded={false}>
          <Toolbar className="toolbar-background">
            <Grid container justify="flex-end">
              <ToolbarGroup>
                <Badge
                  badgeContent={listNotification ? listNotification.length : 0}
                  color={listNotification && listNotification.length ? "secondary" : "default"}
                  onClick={this.toggleHistoryLog}
                >
                  <MailIcon
                    style={{
                      cursor: "pointer",
                      color: listNotification && listNotification.length ? "" : "grey",
                    }}
                  />
                </Badge>
                <div
                  style={{
                    margin: "0 10px",
                  }}
                >
                  <PersonDropdown history={this.props.history} />
                </div>
                <PersonIcon />
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <HistoryLog data={this.props.data} historyLog={this.state.openHistory} />
                </ClickAwayListener>
              </ToolbarGroup>
            </Grid>
          </Toolbar>
        </Paper>
      </NotificationContext.Provider>
    );
  }
}

export default graphql(listNotifications)(Header);
