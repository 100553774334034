import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useGuideAnnouncement from "./hooks/useGuideAnnouncement";

// components
import ProgramDetails from "../ProgramDetails";
import HotelDetailsList from "../HotelDetailsList";
import AnnouncementTextField from "../AnnouncementTextField";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * GuideAnnouncement
 */
const GuideAnnouncement = ({ classes }) => {
  // hooks
  const { handleOnUpdateGuideAnnouncement, handleOnUpdateHotelDetails, guideAnnouncement } = useGuideAnnouncement();

  const title = get(guideAnnouncement, "announcement_title.value", "");
  const intro = get(guideAnnouncement, "intro_message.placeholder", "");
  const footer = get(guideAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* group */}
      <AnnouncementTextField announcementField={get(guideAnnouncement, "group")} keyOfValue={"group"} />

      {/* termin */}
      <AnnouncementTextField announcementField={get(guideAnnouncement, "termin")} keyOfValue={"termin"} />

      {/* hotel table list */}
      <HotelDetailsList
        label={`${get(guideAnnouncement, "hotel_label.value", "")} : `}
        hotelList={get(guideAnnouncement, "hotel", "")}
        handleOnUpdateHotelDetails={handleOnUpdateHotelDetails}
      />

      {/* bus driver contact */}
      <AnnouncementTextField
        announcementField={get(guideAnnouncement, "bus_driver_contact")}
        keyOfValue={"bus_driver_contact"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideAnnouncement}
      />

      {/* voucher accommodation */}
      <AnnouncementTextField
        announcementField={get(guideAnnouncement, "voucher_accommodation")}
        keyOfValue={"voucher_accommodation"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideAnnouncement}
      />

      {/* dinner drink */}
      <AnnouncementTextField
        announcementField={get(guideAnnouncement, "dinner_drink")}
        keyOfValue={"dinner_drink"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideAnnouncement}
      />

      {/* important notice */}
      <AnnouncementTextField
        announcementField={get(guideAnnouncement, "important_notice")}
        keyOfValue={"important_notice"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideAnnouncement}
      />

      {/* program */}
      <ProgramDetails
        label={`${get(guideAnnouncement, "program_label.value", "")} : `}
        programList={get(guideAnnouncement, "program", "")}
      />

      {/* invoice */}
      <AnnouncementTextField
        announcementField={get(guideAnnouncement, "invoice_warrant")}
        keyOfValue={"invoice_warrant"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(GuideAnnouncement);
