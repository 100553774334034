import React from "react";
import { isInteger } from "./numbers";

export const defaultFilterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .includes(filter.value.toLowerCase())
    : true;
};

export const isIntegerFilterValidation = ({ filter, onChange }) => (
  <input
    value={filter ? filter.value : ""}
    onChange={event =>
      // only allow integers or empty value
      isInteger(event.target.value) || event.target.value === ""
        ? onChange(event.target.value)
        : ""
    }
    style={{ width: "100%" }}
    type="text"
  />
);

export const DEFAULT_PAGE_SIZE = 10;

export const DEBOUNCE_FETCH_DELAY = 400;
