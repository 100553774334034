import React from "react";

// hooks

// ui
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "material-ui/CircularProgress/CircularProgress";

// components
import Subject from "../Subject";
import ProgramDays from "../ProgramDays";
import GuideComment from "../GuideComment";
import PartnerSearch from "../ProgramDays/components/PartnerSearch";

// style
import "./style.css";
import useTripContainer from "./hooks/useTripContainer";
import GuideList from "../GuideList";

/**
 * TripActivitiesContainer contains all components inside tripActivitiesDrawer
 */
export default function TripContainer() {
  const { loading, partnerInitLoading, programDayActivities } = useTripContainer();

  // return root
  return (
    <div>
      {loading || partnerInitLoading || !programDayActivities ? (
        <Grid container spacing={8}>
          <Grid item>
            <CircularProgress size={24} />
          </Grid>
          <Grid item>
            <Typography className="loadingLabel" variant="body2">
              Dohvaćam podatke
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={16}>
          <Grid item xs={8}>
            <Subject />
            <ProgramDays />
          </Grid>
          <Grid item xs={4}>
            <GuideComment />
            <GuideList />
          </Grid>
        </Grid>
      )}

      <PartnerSearch />
    </div>
  );
}
