import React from "react";
import { Tabs, Tab } from "material-ui/Tabs";

export const AdministrationTabs = (WrappedComponent, title) => {
  return class extends React.Component {
    render() {
      return (
        <Tabs>
          <Tab label={title}>
            <div className="form">
              <WrappedComponent {...this.props} />
            </div>
          </Tab>
        </Tabs>
      );
    }
  };
};
