import React from "react";

// hooks
import usePaymentContainer from "./hooks/usePaymentContainer";

// constants
import { HINT_PAYMENT, LABEL_PAYMENT } from "src/utility/labels.js";

// components
import TextFieldMultilineOutline from "src/components/UI/TextFieldMultilineOutline";

/**
 * PaymentContainer contains all components inside PaymentDrawer
 */
export default function PaymentContainer() {
  const { onPaymentTextChange, payment } = usePaymentContainer();

  // return root
  return (
    <TextFieldMultilineOutline
      id="paymentInput"
      fullWidth={true}
      rows={5}
      rowsMax={5}
      value={payment || ""}
      label={LABEL_PAYMENT}
      placeholder={HINT_PAYMENT}
      onChange={event => onPaymentTextChange(event)}
    />
  );
}
