import React from "react";
import { Switch, Route } from "react-router-dom";

import ActivityTabs from "./ActivityTabs";

const ActivityRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/aktivnosti/"
        exact
        component={ActivityTabs}
      />
    </Switch>
  );
};

export default ActivityRouter;
