import React from "react";

// hooks
import useTripActivities from "./hooks/useTripActivities";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import TripContainer from "./components/TripContainer";

// constants
import { TITLE_GUIDE_DRAWER_TRIPS } from "src/utility/labels";

/**
 * TripActivities drawer is used for showin data for activities of trips and its guides
 */
export default function TripActivities() {
  const { isDrawerOpen, onCloseDrawer } = useTripActivities();

  return (
    <DrawerWrapper open={isDrawerOpen} toggle={onCloseDrawer} title={TITLE_GUIDE_DRAWER_TRIPS} customWidth="85%">
      <TripContainer />
    </DrawerWrapper>
  );
}
