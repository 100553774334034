import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import {
  Card,
  Divider,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";

// hooks
import useVoucherList from "./hooks/useVoucherList";

// constants
import { TITLE_VOUCHERS, LABEL_NO_VOUCHERS } from "src/utility/labels";
import { getDateForView } from "src/utility/dates";

// components
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";
import VoucherViewer from "../VoucherViewer";

const styles = theme => ({
  root: {
    marginTop: 18,
    marginBottom: 20,
  },

  list: {
    position: "relative",
    overflow: "auto",
    maxHeight: 280,
    marginTop: 8,
  },

  label: {
    marginTop: theme.spacing.unit,
  },

  item: {},
  itemSelected: {
    backgroundColor: "#EEEEEE",
  },
});

/**
 * VoucherList
 */
const VoucherList = ({ classes, announcementSelected }) => {
  // hook
  const { announcementVouchers, handleOnVoucherClick, loading } = useVoucherList(announcementSelected);

  // loading component
  const loadingView = () => {
    return (
      <Grid style={{ marginTop: 18 }} container spacing={8} alignItems="center">
        <Grid item xs={2}>
          <FixedCircularProgress color="inherit" size={16} thickness={6} />
        </Grid>

        <Grid item xs={10}>
          <Typography variant="body1">Dohvaćam podatke</Typography>
        </Grid>
      </Grid>
    );
  };

  // return root
  return (
    <Card style={{ marginBottom: 20 }}>
      {loading ? (
        <CardContent>{loadingView()}</CardContent>
      ) : (
        <CardContent>
          <Typography variant="headline">{TITLE_VOUCHERS}</Typography>

          {announcementVouchers && announcementVouchers.length ? (
            <List className={classes.list}>
              {announcementVouchers.map(voucher => {
                return (
                  <Fragment key={voucher.id}>
                    <ListItem button disableGutters onClick={() => handleOnVoucherClick(voucher)}>
                      <ListItemText
                        primary={`${voucher.voucher_title} ( ${get(voucher, "voucher_unique_nr", "")} )`}
                        secondary={`${voucher.language_desc} - ${getDateForView(voucher.date)}`}
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
          ) : (
            <Typography className={classes.label} variant="body1">
              {LABEL_NO_VOUCHERS}
            </Typography>
          )}
        </CardContent>
      )}

      {/** voucher viewer */}
      <VoucherViewer />
    </Card>
  );
};

export default withStyles(styles)(VoucherList);
