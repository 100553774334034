import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateContigent, updateFixContigent } from "../../../../graphql/mutation/booking";
import { contigentFragment } from "../../../../graphql/fragments";
import { config } from "../../../../utility/globals";
import { sortContigentsByTypologyPriorityWithoutListEstablishment } from "../../../../utility/establishment";
import { FormHelperText } from "@material-ui/core";
import FormValidator from "../../../../components/UI/FormValidator";
import toastr from "toastr";

class SellingsDrawer extends Component {
  static schema = {
    type: "array",
    items: {
      type: "object",
      properties: {
        sold: {
          type: "integer",
          minimum: 0,
          errorMessage: {
            type: "Prodano mora biti broj",
            minimum: "Ne može biti u minusu",
          },
        },
      },
    },
  };

  state = {
    contigents: "",
  };

  componentDidMount = () => {
    this.setState({ contigents: this.props.contigents });
  };

  changeContigentSoldData = (sold, id) => {
    const { contigents } = this.state;
    const contigents_clone = contigents.map(contigent => {
      if (contigent.id === id) {
        return { ...contigent, sold };
      }
      return contigent;
    });
    this.setState({ contigents: contigents_clone });
  };

  overbooking = (sum, sold) => sold > sum;

  updateData = () => {
    const { contigents } = this.state;
    const { type_id } = this.props;

    try {
      contigents.forEach(async (contigent, index) => {
        // Check if contigent sold state is changed
        if (contigent.sold !== this.props.contigents[index].sold) {
          if (config.offer_type[type_id] === "Alotman") {
            await this.updateFixContigent(contigent);
          } else {
            await this.updateContigent(contigent);
          }
        }
      });

      this.props.toggle();
    } catch (error) {}
  };

  updateContigent(contigent) {
    try {
      this.props.mutate({
        variables: {
          id: contigent.id,
          patch: { sold: contigent.sold },
        },
        update: (store, { data: { updateContigent } }) => {
          store.writeFragment({
            id: `Contigent:${updateContigent.id}`,
            fragmentName: "Contigent",
            fragment: contigentFragment,
            data: updateContigent,
          });
        },
      });

      return toastr.success("Uspješno ažurirano");
    } catch (error) {}
  }

  updateFixContigent(contigent) {
    try {
      this.props.updateFixContigent({
        variables: {
          id: contigent.id,
          sold: contigent.sold,
        },
      });
      toastr.success("Uspješno ažurirano");
    } catch (error) {}
  }

  render() {
    const { open, toggle } = this.props;
    const { contigents } = this.state;

    if (!contigents) {
      return null;
    }

    let establishmentsShown = [];
    let sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

    return (
      <FormValidator schema={SellingsDrawer.schema} submit={this.updateData} data={contigents}>
        {({ error, errorMsg, submit, validator }) => {
          return (
            <DrawerWrapper toggle={toggle} update={submit} open={open} title="Stanje prodaje">
              <Grid container spacing={24}>
                {sortedContigents.map((contigent, index) => {
                  if (
                    establishmentsShown.find(curr => {
                      return curr === contigent.typology.establishment_id;
                    })
                  ) {
                    return null;
                  }

                  if (contigent.typology.establishment_id) {
                    establishmentsShown.push(contigent.typology.establishment_id);
                  }

                  return (
                    <Grid item xs={12} key={`main${contigent.id}`}>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Input
                              id="hotel_name"
                              name="hotel_name"
                              value={contigent.typology.establishment.name || ""}
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          Ugovoreno
                        </Grid>
                        <Grid item xs={3}>
                          Prodano
                        </Grid>
                        <Grid item xs={6}>
                          Tip sobe
                        </Grid>
                        {sortedContigents.map(
                          cont =>
                            // iterate through all contigents, and display others that are in the same hotel
                            cont.typology.establishment_id === contigent.typology.establishment_id ? (
                              <React.Fragment key={`sub${cont.id}`}>
                                <Grid item xs={3}>
                                  <FormControl fullWidth>
                                    <Input id="sum" name="sum" value={cont.typologies_sum || ""} disabled />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControl fullWidth error={error(`/${index}/sold`)}>
                                    <Input
                                      id="sold"
                                      name="sold"
                                      value={cont.sold !== undefined ? cont.sold : ""}
                                      classes={{
                                        root: this.overbooking(cont.typologies_sum, cont.sold) ? "text-warning" : "",
                                      }}
                                      onChange={e => this.changeContigentSoldData(e.target.value, cont.id)}
                                    />
                                    {error(`/${index}/sold`) || this.overbooking(cont.typologies_sum, cont.sold) ? (
                                      <FormHelperText
                                        classes={{
                                          root: this.overbooking(cont.typologies_sum, cont.sold) ? "text-warning" : "",
                                        }}
                                      >
                                        {error(`/${index}/sold`)
                                          ? errorMsg(`/${index}/sold`)
                                          : "Prodano je više soba od ugovorenih"}
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Input
                                      id="capacity"
                                      name="capacity"
                                      value={`${cont.typology.code} [${cont.typology.persons_capacity}]` || ""}
                                      disabled
                                    />
                                  </FormControl>
                                </Grid>
                              </React.Fragment>
                            ) : null,
                        )}
                        <Grid item xs={12} />
                        {/* bit of vertical space*/}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </DrawerWrapper>
          );
        }}
      </FormValidator>
    );
  }
}

export default compose(
  graphql(updateContigent),
  graphql(updateFixContigent, { name: "updateFixContigent" }),
)(SellingsDrawer);
