import { config } from "../../../utility/globals";
import {
  addingAlotmanTerminHelper,
  addingContigentHelper,
  addingTerminHelper,
  resetContigentFields,
  terminClone,
} from "./helpers";
import { updateOfferContractDocumentParam } from "./utility";

export const addTerminHandler = state => {
  const termins_clone = addingTerminHelper(state);

  state = {
    ...state,
    from: undefined,
    to: undefined,
  };

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const addAlotmanTerminHandler = state => {
  const termins_clone = addingAlotmanTerminHelper(state);

  state = {
    ...state,
    contigents: [],
    from: undefined,
    to: undefined,
    typologies_sum: "",
    typology_id: "",
  };

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const setAddedTerminandler = (state, addedTermin) => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const termins_clone = termin_periods.map((termin, index) => {
    if (termin_periods.length === index + 1) {
      return addedTermin;
    }
    return termin;
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const setAddedAlotmanTerminHandler = (state, addedTermin) => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const termins_clone = [...termin_periods, addedTermin];

  state = {
    ...state,
    typology_id: "",
    typologies_sum: "",
  };

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const removeTerminHandler = (state, id) => {
  const termins_clone = terminClone(state).filter(termin => termin.id !== id);

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const changePriceStructurePrice = (state, { key, id, value }) => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const termins_clone = termin_periods.map(termin => {
    if (termin.id === id) {
      return {
        ...termin,
        price_structure: {
          ...termin.price_structure,
          price_typology: JSON.stringify({
            ...JSON.parse(termin.price_structure.price_typology),
            [key]: value,
          }),
        },
      };
    }
    return termin;
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const changePriceStructure = (state, { key, id, value }) => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const termins_clone = termin_periods.map(termin => {
    if (termin.id === id) {
      return {
        ...termin,
        price_structure: {
          ...termin.price_structure,
          [key]: value,
        },
      };
    }
    return termin;
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const resetPriceStructureChangesHandler = (state, price_structure, termin_id) => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;

  const termins_clone = termin_periods.map(termin => {
    if (termin.id === termin_id) {
      return {
        ...termin,
        price_structure: {
          ...price_structure,
        },
      };
    }
    return termin;
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone,
    },
  });
};

export const establishmentPicked = (oldState, action) => {
  const { listEstablishment, value, key } = action.data;

  // If none is picked, reset fields
  if (!value) {
    return {
      ...oldState,
      establishment_id: "",
      typologies_sum: "",
      typology_id: "",
      typologies: [],
    };
  }

  const index = listEstablishment.findIndex(el => el.id === value);

  const typologies = listEstablishment[index].typologies;

  return {
    ...oldState,
    [key]: value,
    establishment: listEstablishment.find(entry => entry.id === value),
    typologies,
  };
};

export const addContigentHandler = (state, id, dontResetFields) => {
  const contigents_clone = addingContigentHelper(state, id);

  let termins_clone = terminClone(state);

  const typology_group_ids = contigents_clone.map(({ typology }) => typology.typology_group_id);
  const typology_group_unique_ids = typology_group_ids.filter(
    (typology_group_id, i) =>
      typology_group_ids.findIndex(typology_group_id2 => typology_group_id === typology_group_id2) === i,
  );

  const termins_clone_added_contigent = termins_clone.map(termin => {
    let {
      contigents,
      price_structure: { price_typology },
    } = termin;

    if (contigents && contigents.length) {
      contigents = contigents.concat(contigents_clone);
    } else {
      contigents = contigents_clone;
    }

    // Insert new price typology into price structure
    const pt = { ...JSON.parse(price_typology) };

    typology_group_unique_ids.forEach(typology_group_id => {
      if (!pt[typology_group_id]) {
        pt[typology_group_id] = "0";
      }
    });

    return {
      ...termin,
      contigents,
      price_structure: {
        ...termin.price_structure,
        price_typology: JSON.stringify(pt),
      },
    };
  });

  return updateOfferContractDocumentParam(dontResetFields ? state : resetContigentFields(state), {
    param: {
      key: "termin_periods",
      value: termins_clone_added_contigent,
    },
  });
};

export const addAlotmanContigentHandler = (state, added_contigent_termin) => {
  let termins_clone = terminClone(state);

  const termins_clone_added_contigent = termins_clone.map(termin => {
    if (termin.id === added_contigent_termin.id) {
      return added_contigent_termin;
    }

    return termin;
  });

  state = {
    ...state,
    typologies_sum: "",
    typology_id: "",
  };

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termins_clone_added_contigent,
    },
  });
};

export const saveEditedContigentTypologySumHandler = (state, new_contigent, old_contigent) => {
  const {
    subtermin,
    offer: {
      offer_contract_document: { termin_periods, offer_contract_document_type_id },
    },
  } = state;

  let termins_clone_edited_contigent = [];

  if (config.offer_type[offer_contract_document_type_id] !== "Tour") {
    termins_clone_edited_contigent = termin_periods.map(termin => {
      return {
        ...termin,
        contigents: termin.contigents.map(contigent => {
          if (contigent.id === old_contigent.id) {
            return {
              ...contigent,
              typologies_sum: new_contigent.typologies_sum,
            };
          }
          return contigent;
        }),
      };
    });

    return updateOfferContractDocumentParam(resetContigentFields(state), {
      param: {
        key: "termin_periods",
        value: termins_clone_edited_contigent,
      },
    });
  } else {
    termins_clone_edited_contigent = termin_periods.map(termin => {
      return {
        ...termin,
        subtermin_periods: termin.subtermin_periods.map(termin => {
          return {
            ...termin,
            contigents: termin.contigents.map(contigent => {
              if (contigent.id === old_contigent.id) {
                return {
                  ...contigent,
                  typologies_sum: new_contigent.typologies_sum,
                };
              }
              return contigent;
            }),
          };
        }),
      };
    });

    const subtermin_clone = {
      ...subtermin,
      contigents: subtermin.contigents.map(contigent => {
        if (contigent.id === old_contigent.id) {
          return {
            ...contigent,
            typologies_sum: new_contigent.typologies_sum,
          };
        }
        return contigent;
      }),
    };

    return {
      ...updateOfferContractDocumentParam(resetContigentFields(state), {
        param: {
          key: "termin_periods",
          value: termins_clone_edited_contigent,
        },
      }),
      subtermin: subtermin_clone,
    };
  }
};

export const saveEditedTerminHandler = (state, new_termin, old_termin) => {
  const {
    offer: {
      offer_contract_document: { termin_periods, offer_contract_document_type_id },
    },
  } = state;

  let termins_clone_edited_termin = [];

  if (config.offer_type[offer_contract_document_type_id] !== "Tour") {
    termins_clone_edited_termin = termin_periods.map(termin => {
      if (termin.id === old_termin.id) {
        return {
          ...new_termin,
        };
      }
      return termin;
    });

    return updateOfferContractDocumentParam(resetContigentFields(state), {
      param: {
        key: "termin_periods",
        value: termins_clone_edited_termin,
      },
    });
  } else {
    termins_clone_edited_termin = termin_periods.map(termin => {
      return {
        ...termin,
        subtermin_periods: termin.subtermin_periods.map(termin => {
          if (termin.id === old_termin.id) {
            return {
              ...new_termin,
            };
          }

          return termin;
        }),
      };
    });

    return {
      ...updateOfferContractDocumentParam(resetContigentFields(state), {
        param: {
          key: "termin_periods",
          value: termins_clone_edited_termin,
        },
      }),
    };
  }
};

export const removeContigentHandler = (state, contigent_id) => {
  const { termin_periods } = state.offer.offer_contract_document;

  const termin_periods_clone = termin_periods.map(termin => {
    const contigent_to_delete = termin.contigents.find(({ id }) => id === contigent_id);

    const contigents_after_delete = termin.contigents.filter(({ id }) => id !== contigent_id);

    const contigents_same_group_id = contigents_after_delete.filter(
      ({ typology }) => typology.typology_group_id === contigent_to_delete.typology.typology_group_id,
    );

    if (!contigents_same_group_id.length) {
      const { price_structure } = termin;

      const price_typology = { ...JSON.parse(price_structure.price_typology) };

      delete price_typology[contigent_to_delete.typology.typology_group_id];

      return {
        ...termin,
        contigents: contigents_after_delete,
        price_structure: {
          ...termin.price_structure,
          price_typology: JSON.stringify(price_typology),
        },
      };
    }

    return {
      ...termin,
      contigents: contigents_after_delete,
    };
  });

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "termin_periods",
      value: termin_periods_clone,
    },
  });
};
