import initialState from "./initialState";
import * as types from "../../actionTypes";

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ALL_BOOKINGS_INITIAL_QUERY:
      return {
        ...state,

        limit: action.limit,
        offset: action.offset,
        selectedFilters: action.selectedFilters,
        allBookingsFilter: action.allBookingsFilter,
      };

    default:
      return state;
  }
}
