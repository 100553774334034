// libs
import { get } from "lodash";
import { useSelector } from "react-redux";

// hooks
import { useToolbarState } from "./useToolbarState";

// redux state
import { selectGuideScheduleGridFilters, selectGuideSchedules } from "src/views/Guides/guidesSlice";

// constants
import {
  guidesSeasonList,
  BACKGROUND_COLOR_PENDING,
  BACKGROUND_COLOR_FIX,
  BACKGROUND_COLOR_STORNO,
  GROUP_OPERATIVA_STATUS_PENDING,
  GROUP_OPERATIVA_STATUS_FIX,
  GROUP_OPERATIVA_STATUS_STORNO,
  GUIDES_SEASON_SPRING_ID,
  LOCAL_STORAGE_GUIDES_TABLE,
} from "src/utility/constants";
// labels
import {
  COLUMN_TITLE_GUIDE_CONTRACT_NO,
  COLUMN_TITLE_GUIDE_BOOKING_STATUS,
  COLUMN_TITLE_GUIDE_PARTNER,
  COLUMN_TITLE_GUIDE_HOTEL,
  COLUMN_TITLE_GUIDE_ARRIVAL,
  COLUMN_TITLE_GUIDE_DEPARTURE,
  COLUMN_TITLE_GUIDE_BOOKING_STATE,
  COLUMN_TITLE_GUIDE_TRANSFER,
  COLUMN_TITLE_GUIDE_PROGRAM,
  COLUMN_TITLE_GUIDE_SERVICE_GUIDE,
  COLUMN_TITLE_GUIDE_TRIP_ACTIVITY,
  COLUMN_TITLE_GUIDE_NAMES,
  COLUMN_TITLE_GUIDE_GROUP_STATUS,
  COLUMN_TITLE_GUIDE_LAYOUT_COMMENT,
  COLUMN_TITLE_GUIDE_COMMENT_GUIDE,
  COLUMN_TITLE_GUIDE_ADDITIONAL_FUNCTIONS,
  COLUMN_TITLE_GUIDE_PAYMENT,
  COLUMN_TITLE_GUIDE_CATALOG,
} from "src/utility/labels";
// utils
import { formatDate, getCurrentDate } from "src/utility/dates";
import { useTableLocalStorage } from "src/hooks/useTableLocalStorage";

/**
 * useToolbar is entry point of data for toolbar component
 */
export default function useToolbar() {
  // local storage
  const { tableState, setTableState } = useTableLocalStorage(LOCAL_STORAGE_GUIDES_TABLE);

  // component state
  const [, setState] = useToolbarState();

  // redux state
  const guidesSeasonSelectedID = tableState.guidesSeasonSelectedID || GUIDES_SEASON_SPRING_ID;
  const listGuides = useSelector(selectGuideSchedules);
  const gridFilters = useSelector(selectGuideScheduleGridFilters);

  /**
   * handle on reset filters click
   */
  const handleOnFilterResetClick = () => {
    setTableState({ ...tableState, pageSelected: 0, filtersSelected: [] });
  };

  // on columns button click
  const handleOnColumnsClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    setState({ filtersOpen: true, anchors: event.currentTarget });
  };

  // handle on date range filter select
  const handleOnSeasonSelect = guidesSeasonSelectedID => {
    setTableState({ ...tableState, guidesSeasonSelectedID: guidesSeasonSelectedID });
  };

  /**
   * handle data for excel export
   */
  /**
   * get header list of active data grid filters for guides list
   */
  const headerList =
    gridFilters &&
    gridFilters
      .filter(header => header.active === true && header.desc !== COLUMN_TITLE_GUIDE_ADDITIONAL_FUNCTIONS)
      .map(header => {
        return { title: header.desc, width: { wpx: 120 }, style: { font: { bold: true } } };
      });
  /**
   * get row list of guides list data, make everything as string, with new lines for specific columns
   */
  const rowList =
    listGuides &&
    listGuides.map(guide => {
      const row =
        headerList &&
        headerList.map(header => {
          switch (header.title) {
            case COLUMN_TITLE_GUIDE_CONTRACT_NO:
              return {
                value: guide.external_id || "",
                style: { alignment: { wrapText: true, vertical: "bottom", horizontal: "right" } },
              };

            case COLUMN_TITLE_GUIDE_BOOKING_STATUS:
              return { value: guide.booking_status || "" };

            case COLUMN_TITLE_GUIDE_PARTNER:
              return { value: guide.partner_name || "" };

            case COLUMN_TITLE_GUIDE_HOTEL: {
              const establishmentNameList = get(guide, "establishment_names");
              let establishmentNamesText = "";

              if (establishmentNameList) {
                establishmentNameList.forEach(
                  establishment =>
                    (establishmentNamesText = establishmentNamesText + "\n" + establishment.establishment_name),
                );
              }

              return { value: establishmentNamesText };
            }

            case COLUMN_TITLE_GUIDE_ARRIVAL:
              return {
                value: formatDate(guide.from) || "",
                style: { alignment: { wrapText: true, vertical: "bottom", horizontal: "right" } },
              };

            case COLUMN_TITLE_GUIDE_DEPARTURE:
              return {
                value: formatDate(guide.to) || "",
                style: { alignment: { wrapText: true, vertical: "bottom", horizontal: "right" } },
              };

            case COLUMN_TITLE_GUIDE_BOOKING_STATE:
              return { value: guide.pax || "" };

            case COLUMN_TITLE_GUIDE_TRANSFER: {
              const transferText = guide.transport ? "Ima" : "Nema";
              return { value: transferText };
            }

            case COLUMN_TITLE_GUIDE_PROGRAM:
              return { value: guide.theme_name || "" };

            case COLUMN_TITLE_GUIDE_SERVICE_GUIDE:
              return { value: guide.guide_services || "" };

            case COLUMN_TITLE_GUIDE_TRIP_ACTIVITY: {
              const programDayList = get(guide, "program_days");
              let programDaysText = "";

              if (programDayList) {
                programDayList.forEach(
                  programDay => (programDaysText = programDaysText + `\n${programDay.prefix} ${programDay.title}`),
                );
              }

              return {
                value: programDaysText,
              };
            }

            case COLUMN_TITLE_GUIDE_NAMES: {
              const guideList = get(guide, "guides");
              let guideText = "";

              if (guideList) {
                guideList.forEach(
                  guide => (guideText = guideText + `\n${guide.guide_first_name} ${guide.guide_last_name}`),
                );
              }

              return { value: guideText };
            }

            case COLUMN_TITLE_GUIDE_GROUP_STATUS:
              return { value: guide.group_operativa_status || "" };

            case COLUMN_TITLE_GUIDE_LAYOUT_COMMENT:
              return { value: guide.guide_layout_comment || "" };

            case COLUMN_TITLE_GUIDE_COMMENT_GUIDE:
              return { value: guide.guide_notices || "" };

            case COLUMN_TITLE_GUIDE_PAYMENT:
              return { value: guide.payment || "" };

            case COLUMN_TITLE_GUIDE_CATALOG:
              return { value: guide.catalog || "" };
            default:
              return { value: "" };
          }
        });

      // add style for column, background color based on groupOperativaStatus type
      let backgroundColor = "";

      switch (get(guide, "group_operativa_status", "")) {
        case GROUP_OPERATIVA_STATUS_PENDING:
          backgroundColor = BACKGROUND_COLOR_PENDING;
          break;
        case GROUP_OPERATIVA_STATUS_FIX:
          backgroundColor = BACKGROUND_COLOR_FIX;
          break;
        case GROUP_OPERATIVA_STATUS_STORNO:
          backgroundColor = BACKGROUND_COLOR_STORNO;
          break;
        default:
          backgroundColor = "";
      }

      // add style background color, remove # from backgroundColor since this is required for excelExport lib
      // add default alignment if not previous set for column

      return (
        row &&
        row.map(column => {
          return {
            ...column,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: backgroundColor.substring(1) } },
              ...(!get(column, "style.alignment", null)
                ? {
                    alignment: { wrapText: true, vertical: "bottom", horizontal: "left" },
                  }
                : {
                    alignment: get(column, "style.alignment"),
                  }),
              border: {
                top: { style: "thin", color: { rgb: "212121" } },
                bottom: { style: "thin", color: { rgb: "212121" } },
                left: { style: "thin", color: { rgb: "212121" } },
                right: { style: "thin", color: { rgb: "212121" } },
              },
            },
          };
        })
      );
    });

  const excelExportDataSet = [
    {
      columns: headerList,
      data: rowList,
    },
  ];

  /**
   * get season selected
   */
  const seasonSelected = guidesSeasonList.find(season => season.id === guidesSeasonSelectedID);

  /**
   * current date
   */
  const currentDate = getCurrentDate();

  return {
    currentDate,
    seasonLabel: get(seasonSelected, "label"),
    excelExportDataSet,
    guidesSeasonSelectedID,
    isGuideSchedulesFiltered: tableState.filtersSelected.length,
    handleOnColumnsClick,
    handleOnFilterResetClick,
    handleOnSeasonSelect,
  };
}
