import cloneDeep from "lodash/cloneDeep";
import { formatDate } from "../../../utility/dates";

/**
 * HELPER FUNCTIONS
 */

export function guidIdGenerator() {
  let S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

export const resetContigentFields = state => {
  return {
    ...state,
    typologies_sum: "",
    typology_id: "",
    contigents: [],
  };
};

export const serviceCloneHelper = (state, service_id) => {
  const {
    offer: {
      offer_contract_document: { services },
    },
  } = state;

  const services_clone = cloneDeep(services);

  services_clone.push(service_id);

  return services_clone;
};

export const addingContigentHelper = (state, id) => {
  const { typologies_sum, typology_id, typologies, contigents } = state;
  const contigents_clone = cloneDeep(contigents);

  contigents_clone.push({
    id: id || guidIdGenerator(),
    typologies_sum,
    typology_id,
    typology: typologies.find(entry => entry.id === typology_id),
  });

  return contigents_clone;
};

export const addingSubterminContigentHelper = (state, id) => {
  const { subtermin_typologies_sum, subtermin_typology_id, subtermin_typologies, subtermin } = state;

  const subtermin_clone = cloneDeep(subtermin);

  if (!subtermin_clone.contigents) {
    subtermin_clone["contigents"] = [
      {
        id: id || guidIdGenerator(),
        typologies_sum: subtermin_typologies_sum,
        typology_id: subtermin_typology_id,
        typology: subtermin_typologies.find(entry => entry.id === subtermin_typology_id),
      },
    ];
  } else {
    subtermin_clone.contigents.push({
      id: id || guidIdGenerator(),
      typologies_sum: subtermin_typologies_sum,
      typology_id: subtermin_typology_id,
      typology: subtermin_typologies.find(entry => entry.id === subtermin_typology_id),
    });
  }

  return subtermin_clone;
};

export const terminClone = state => {
  const {
    offer: {
      offer_contract_document: { termin_periods },
    },
  } = state;
  return [...termin_periods];
};

export const addingTerminHelper = state => {
  const { to, from, contigents, transport } = state;

  const termins_clone = terminClone(state);

  const added_contigents = termins_clone && termins_clone.length ? termins_clone[0].contigents : null;

  const price_typology = {};

  if (added_contigents) {
    added_contigents.forEach(({ typology }) => {
      if (!price_typology[typology.typology_group_id]) {
        price_typology[typology.typology_group_id] = "0";
      }
    });
  } else {
    contigents.forEach(({ typology }) => {
      if (!price_typology[typology.typology_group_id]) {
        price_typology[typology.typology_group_id] = "0";
      }
    });
  }

  termins_clone.push({
    id: guidIdGenerator(),
    to,
    from,
    transport,
    price_structure: {
      id: guidIdGenerator(),
      tourist_tax: "0",
      comment_price_structure: "",
      price_structure_type_id: "1",
      price_typology: JSON.stringify(price_typology),
    },
    contigents: added_contigents || contigents,
    subtermin_periods: [],
  });

  return termins_clone;
};

export const addingAlotmanTerminHelper = state => {
  // TODO: Add price_typology to alotman too
  const contigents = addingContigentHelper(state);
  const { to, from, transport } = state;

  const termins_clone = terminClone(state);

  const terminIndex = termins_clone.findIndex(
    termin => formatDate(termin.from) === formatDate(from) && formatDate(termin.to) === formatDate(to),
  );

  if (terminIndex !== -1) {
    termins_clone[terminIndex] = {
      ...termins_clone[terminIndex],
      contigents: termins_clone[terminIndex].contigents.concat(contigents),
    };
  } else {
    const price_typology = {};

    contigents.forEach(({ typology }) => {
      if (!price_typology[typology.typology_group_id]) {
        price_typology[typology.typology_group_id] = "0";
      }
    });

    termins_clone.push({
      id: guidIdGenerator(),
      to,
      from,
      transport,
      price_structure: {
        id: guidIdGenerator(),
        tourist_tax: "0",
        comment_price_structure: "",
        price_structure_type_id: "1",
        price_typology_1: "0",
        price_typology_2: "0",
        price_typology_3: "0",
        price_typology_4: "0",
        price_typology_5: "0",
        price_typology_6: "0",
        price_typology_7: "0",
        price_typology_8: "0",
        price_typology_9: "0",
        price_typology_10: "0",
        price_typology: JSON.stringify(price_typology),
      },
      contigents,
      subtermin_periods: [],
    });
  }

  return termins_clone;
};

export const addingSubterminHelper = (state, newSubtermin) => {
  const { subtermin } = state;

  const termins = terminClone(state);

  if (termins[0].id) {
    subtermin["termin_period_id"] = termins[0].id;
  }

  let { subtermin_periods } = termins[0];

  let subtermin_periods_clone = [...subtermin_periods];

  if (!subtermin_periods_clone || !subtermin_periods_clone.length) {
    subtermin_periods_clone = [];
  }

  subtermin_periods_clone.push({ ...(newSubtermin || subtermin) });

  let typology_group_ids = [];
  let typology_group_unique_ids = [];

  if (newSubtermin) {
    typology_group_ids = newSubtermin.contigents.map(({ typology }) => typology.typology_group_id);
  } else if (subtermin) {
    typology_group_ids = subtermin.contigents.map(({ typology }) => typology.typology_group_id);
  }

  typology_group_unique_ids = typology_group_ids.filter(
    (typology_group_id, i) =>
      typology_group_ids.findIndex(typology_group_id2 => typology_group_id === typology_group_id2) === i,
  );
  const termins_clone = termins.map((termin, index) => {
    if (index === 0) {
      const pt = { ...JSON.parse(termin.price_structure.price_typology) };

      typology_group_unique_ids.forEach(typology_group_id => {
        if (!pt[typology_group_id]) {
          pt[typology_group_id] = "0";
        }
      });

      return {
        ...termin,
        subtermin_periods: subtermin_periods_clone,
        price_structure: {
          ...termin.price_structure,
          price_typology: JSON.stringify(pt),
        },
      };
    }
    return termin;
  });

  return termins_clone;
};
