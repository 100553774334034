import React from "react";

// libs
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectLanguages } from "../../../../announcementSlice";

// constants
import {
  COLUMN_TITLE_ANNOUNCEMENT_ID,
  COLUMN_TITLE_ANNOUNCEMENT_EDIT,
  COLUMN_TITLE_ANNOUNCEMENT_PHRASE,
  COLUMN_TITLE_ANNOUNCEMENT_NAME,
  COLUMN_TITLE_ANNOUNCEMENT_LANGUAGE,
} from "src/utility/labels";

import { ANNOUNCEMENT_ROW_ID_EDIT } from "src/utility/constants";

// components
import EditIcon from "src/components/UI/EditIcon";
import FilterLanguage from "../../../FilterLanguage";

/**
 * phrase announcement columns
 */
export default [
  {
    Header: COLUMN_TITLE_ANNOUNCEMENT_EDIT,
    id: ANNOUNCEMENT_ROW_ID_EDIT,
    accessor: "id",
    width: 65,
    filterable: false,
    Cell: ({ value }) => (
      <div style={{ textAlign: "center", width: "100%" }}>
        <EditIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    // announcement id
    Header: COLUMN_TITLE_ANNOUNCEMENT_ID,
    width: 80,
    accessor: "id",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
    Filter: ({ filter, onChange }) => {
      return (
        <input
          style={{ width: "100%" }}
          type="number"
          value={filter ? filter.value : ""}
          onChange={event => {
            const value = event.target.value;
            // check for int range
            if (value.length <= 10) {
              return onChange(value);
            }
          }}
        />
      );
    },
  },

  {
    // phrase title
    id: "phrase_title",
    Header: COLUMN_TITLE_ANNOUNCEMENT_NAME,
    width: 170,
    accessor: "phrase_title",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{get(value, "value", "")}</div>,
  },

  {
    // phrase text
    id: "phrase_text",
    Header: COLUMN_TITLE_ANNOUNCEMENT_PHRASE,
    width: 170,
    accessor: "phrase_text",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{get(value, "value", "")}</div>,
  },
  {
    // language
    id: "language_id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_LANGUAGE,
    accessor: "language_desc",
    width: 110,
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
    Filter: ({ filter, onChange }) => {
      const listLanguage = useSelector(selectLanguages);

      return <FilterLanguage filter={filter} onChange={onChange} languages={listLanguage} />;
    },
  },
];
