import React from "react";
import debounce from "lodash/debounce";
import Tooltip from "@material-ui/core/Tooltip";

class TooltipWrapper extends React.Component {
  state = {
    showTooltip: false,
  };

  mouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  mouseEnter = this.mouseEnter.bind(this);
  mouseEnterDebounced = debounce(this.mouseEnter, 250);

  mouseLeave = () => {
    this.mouseEnterDebounced.cancel();
    this.state.showTooltip && this.setState({ showTooltip: false });
  };

  shouldComponentUpdate() {
    const { children } = this.props;
    const { showTooltip } = this.state;

    // opening up select's dropdown triggers onMouseEnter and onMouseLeave events, component would update
    // and instantly close the dropdown
    if (showTooltip && children.props.children.type === "select") {
      return false;
    }
    return true;
  }

  render() {
    const { title, children } = this.props;
    const { showTooltip } = this.state;

    return (
      <div
        className="ReactTableTooltipWrapperDiv"
        onMouseEnter={this.mouseEnterDebounced}
        onMouseLeave={this.mouseLeave}
      >
        {showTooltip ? (
          <Tooltip title={title || " "} classes={{ tooltip: "TooltipMaxWidth" }}>
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </div>
    );
  }
}
export default TooltipWrapper;
