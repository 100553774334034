import gql from "graphql-tag";
import {
  contigentFragment,
  userFragment,
  attachmentFragment,
  mainTerminFragment,
  terminsFragment,
  partnerClientFragment,
  serviceTypeFragment,
  serviceFragment,
} from "../fragments";

export const updateContract = gql`
  mutation($id: ID!, $patch: UpdateContract!) {
    updateContract(input: { id: $id, patch: $patch }) {
      id
      contract_status_id
      storno_comment
    }
  }
`;

export const updateAlotmanContract = gql`
  mutation($id: ID!, $patch: UpdateContract!) {
    updateContract(input: { id: $id, patch: $patch }) {
      id
      external_id
      name
      count
      created_at
      alotman_contacted
      contract_created_at
      last_edit_by_user_time
      last_edit_by_user {
        id
        username
      }
      created_by_user {
        ...User
      }
      offer_contract_document {
        id
        inquiry_id
        inquiry_external_id
        partner_client {
          ...Partner
        }
        attachments {
          ...Attachment
        }
        offer_contract_document_services {
          service_types {
            ...ServiceType
            services {
              ...Service
            }
          }
        }
        termin_periods {
          ...MainTermin
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
        }
      }
    }
  }
  ${attachmentFragment}
  ${userFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${contigentFragment}
  ${partnerClientFragment}
  ${serviceTypeFragment}
  ${serviceFragment}
`;

export const createContract = gql`
  mutation($offer_contract_document_id: ID!) {
    createContract(input: { offer_contract_document_id: $offer_contract_document_id }) {
      id
    }
  }
`;
