import React from "react";
import { withStyles } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
      cursor: "pointer"
    }
  }
});

function AddIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </SvgIcon>
  );
}

function SvgIcons(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AddIcon
        className={props.param1 && props.param2 ? classes.iconHover : null}
        color={props.param1 && props.param2 ? "primary" : "disabled"}
        onClick={() => {
          if (props.param1 && props.param2) {
            props.onClickHandler();
          }
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SvgIcons);
