import { useLocalStorage } from "@rehooks/local-storage";
import { HOTEL_GUIDE_ANNOUNCEMENT, LOCAL_STORAGE_ANNOUNCEMENTS_TABLE } from "src/utility/constants";

/**
 * useTableLocalStorage is used for class based components, to use hook
 */
export function useAnnouncementsLocalStorage() {
  const [tableState, setTableState] = useLocalStorage(LOCAL_STORAGE_ANNOUNCEMENTS_TABLE, {
    announcementTypeSelected: HOTEL_GUIDE_ANNOUNCEMENT,
    announcementsPageSelected: {
      HOTEL_GUIDE_ANNOUNCEMENT: 0,
      HOTEL_NO_GUIDE_ANNOUNCEMENT: 0,
      GUIDE_ANNOUNCEMENT: 0,
      GUIDE_TOUR_ANNOUNCEMENT: 0,
      GUIDE_LOCAL_ANNOUNCEMENT: 0,
      AGENCY_ANNOUNCEMENT: 0,
      AGENCY_TOUR_ANNOUNCEMENT: 0,
      AGENCY_LOCAL_ANNOUNCEMENT: 0,
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: 0,
      SUPPLIER_ANNOUNCEMENT: 0,
      PHRASE_ANNOUNCEMENT: 0,
    },
    announcementsPageSizeSelected: {
      HOTEL_GUIDE_ANNOUNCEMENT: 10,
      HOTEL_NO_GUIDE_ANNOUNCEMENT: 10,
      GUIDE_ANNOUNCEMENT: 10,
      GUIDE_TOUR_ANNOUNCEMENT: 10,
      GUIDE_LOCAL_ANNOUNCEMENT: 10,
      AGENCY_ANNOUNCEMENT: 10,
      AGENCY_TOUR_ANNOUNCEMENT: 10,
      AGENCY_LOCAL_ANNOUNCEMENT: 10,
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: 10,
      SUPPLIER_ANNOUNCEMENT: 10,
      PHRASE_ANNOUNCEMENT: 10,
    },
    announcementsFiltersSelected: {
      HOTEL_GUIDE_ANNOUNCEMENT: [],
      HOTEL_NO_GUIDE_ANNOUNCEMENT: [],
      GUIDE_ANNOUNCEMENT: [],
      GUIDE_TOUR_ANNOUNCEMENT: [],
      GUIDE_LOCAL_ANNOUNCEMENT: [],
      AGENCY_ANNOUNCEMENT: [],
      AGENCY_TOUR_ANNOUNCEMENT: [],
      AGENCY_LOCAL_ANNOUNCEMENT: [],
      AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT: [],
      SUPPLIER_ANNOUNCEMENT: [],
      PHRASE_ANNOUNCEMENT: [],
    },
  });

  return { tableState, setTableState };
}
