import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import PartnerServiceTypesForm from "./PartnerServiceTypeForm";
import { AdministrationTabs } from "../AdministrationTabs";
import { createPartnerClientServiceType } from "../../../graphql/mutation/administration";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class CreatePartnerServiceType extends React.Component {
  state = {
    desc: "",
  };

  changeField = ({ target: { name, value } }) =>
    this.setState({
      [name]: value,
    });

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      toastr.success("Tip usluge partnera uspješno kreiran");

      this.props.history.push("/administracija/tip_usluge_partnera");
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle
          title="Kreiranje novog tipa usluge partnera"
          onBackPressed={() => {
            this.props.history.push("/administracija/tip_usluge_partnera");
          }}
        />
        <PartnerServiceTypesForm
          serviceType={{ ...this.state }}
          onChangeField={this.changeField}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(graphql(createPartnerClientServiceType))(CreatePartnerServiceType),
  "Kreiranje tipa usluge partnera",
);
