import React, { Component, Fragment } from "react";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateContent extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`content_content`).length) {
      autosize(document.getElementsByName(`content_content`)[0]);
    }
  }
  render() {
    const { isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Sadržaj</label>
        </div>
        <div>
          <TextArea
            inputProps={{
              name: "content_content",
              disabled: isNotDraft,
            }}
            defaultValue={this.props.content_content}
            customOnChange={this.props.changeContent}
            rows="3"
          />
        </div>
      </Fragment>
    );
  }
}

export default TemplateContent;
