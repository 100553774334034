import React, { Component } from "react";

import DrawerWrapper from "../../../components/UI/Drawer";
import ReservationForm from "./ReservationForm";
import { findServiceTypeByCroLang } from "../../../utility/services";
import { config } from "../../../utility/globals";
import { findTypologyByCroLang } from "../../../utility/establishment";
import get from "lodash/get";

export default class ReservationDrawer extends Component {
  render() {
    const { toggle, open, title } = this.props;
    return (
      <DrawerWrapper toggle={toggle} open={open} title={title}>
        <ReservationFormPrepare {...this.props} />
      </DrawerWrapper>
    );
  }
}

class ReservationFormPrepare extends Component {
  state = {
    serviceTypes: [],
    services: [],
    typologies: [],
  };

  componentDidMount = () => {
    if (this.props.create) {
      this.prepareDataForReservationCreate();
    } else if (this.props.update) {
      this.prepareDataForReservationUpdate();
    }
  };

  prepareDataForReservationUpdate = () => {
    // prepare service type to be picked in autoselect
    const serviceTypes = this.props.reservation &&
      this.props.reservation.service_type && [
        {
          ...this.props.reservation.service_type,
          value: this.props.reservation.service_type.id,
          label: `${(findServiceTypeByCroLang(this.props.reservation.service_type) &&
            findServiceTypeByCroLang(this.props.reservation.service_type).name) ||
            ""}`,
        },
      ];

    // for selecting start and end service
    const services =
      this.props.reservation &&
      this.props.reservation.service_type &&
      this.props.reservation.service_type.services &&
      this.props.reservation.service_type.services.map(service => {
        const s = service.service_translations.find(
          ({ language_id }) => config.language[String(language_id)] === "Croatian",
        );

        return {
          ...service,
          value: service.id,
          label: `${(s && s.name) || ""}`,
        };
      });

    // Take only typologies from hotel and list them in autoselect
    const typologies =
      this.props.reservation &&
      this.props.reservation.contract.typologies &&
      this.props.reservation.contract.typologies.map(typology => {
        const t = findTypologyByCroLang(typology);

        return {
          value: typology.id,
          label: (t && t.name && `${t.name} ( ${typology.establishment_code} ) [${typology.persons_capacity}]`) || "",
        };
      });

    this.setState({ serviceTypes, services, typologies });
  };

  prepareDataForReservationCreate = () => {
    this.submitMutation = this.submitCreateReservation;

    // prepare service type to be picked in autoselect
    let serviceTypes = [];
    let services = [];

    const offer_contract_document_services = get(
      this.props,
      "alotman_contract.offer_contract_document.offer_contract_document_services",
      [],
    );

    function setServices(serviceTypeServices) {
      return serviceTypeServices.map(service => {
        const service_translations = get(service, "service_translations", []);
        const s = service_translations.find(({ language_id }) => config.language[String(language_id)] === "Croatian");

        return {
          ...service,
          value: service.id,
          label: `${(s && s.name) || ""}`,
        };
      });
    }

    offer_contract_document_services.forEach(({ service_types }) => {
      serviceTypes = [
        ...serviceTypes,
        ...service_types.map((serviceType, index) => {
          // set services
          if (index === 0) {
            const { services: serviceTypeServices } = serviceType;
            services = setServices(serviceTypeServices);
          }

          const st = findServiceTypeByCroLang(serviceType);

          return {
            ...serviceType,
            value: serviceType.id,
            label: `${(st && st.name) || ""}`,
          };
        }),
      ];
    });

    const alotman_typologies = get(this.props, "alotman_contract.typologies", []);
    // Take only typologies from hotel and list them in autoselect
    const typologies = alotman_typologies.map(typology => {
      const t = findTypologyByCroLang(typology);

      return {
        value: typology.id,
        label: (t && t.name && `${t.name} ( ${typology.establishment_code} ) [${typology.persons_capacity}]`) || "",
      };
    });

    this.setState({ serviceTypes, services, typologies });
  };

  render() {
    const { toggle, ...rest } = this.props;

    return <ReservationForm {...rest} toggle={toggle} {...this.state} />;
  }
}
