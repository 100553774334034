import React, { Component } from "react";

import Preview from "../../../../components/Preview/Preview";
import { setPreview } from "../../../../store/actions/previewActions";

import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { createOfferData } from "../../../../graphql/query/offers";
import { fetchContract } from "../../../../graphql/query/contract";

class PreviewContract extends Component {
  async componentDidMount() {
    try {
      const contract = await this.props.client.query({
        query: fetchContract,
        variables: {
          id: this.props.match.params.id
        }
      });

      const {
        data: { getContract }
      } = contract;

      const response = await this.props.client.query({
        query: createOfferData
      });

      this.props.setPreview(getContract, response.data);
    } catch (error) {}
  }

  render() {
    return this.props.preview ? <Preview /> : null;
  }
}

const mapStateToProps = state => {
  const { preview } = state.preview;

  return {
    preview
  };
};

export default connect(
  mapStateToProps,
  { setPreview }
)(withApollo(PreviewContract));
