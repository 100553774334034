import React, { Component } from "react";
import PreviewFreeText from "./PreviewFreeText";
import PreviewTermin from "./PreviewTermin";
import PreviewRoom from "./PreviewRoom";
import PreviewGuest from "./PreviewGuest";
import PreviewService from "./PreviewService";
import PreviewCheckInOut from "./PreviewCheckInOut";
import PreviewMessages from "./PreviewMessages";
import PreviewSignature from "./PreviewSignature";

class PreviewContentRenderProps extends Component {
  render() {
    return React.Children.map(
      this.props.children,
      child =>
        child && //if child is not null
        React.cloneElement(child, {
          ...this.props
        })
    );
  }
}

const PreviewContent = props => {
  return (
    <div className="preview-content-container">
      <PreviewContentRenderProps {...props}>
        <PreviewFreeText />
        <PreviewTermin />
        <PreviewRoom />
        <PreviewGuest />
        <PreviewService />
        <PreviewCheckInOut />
        <PreviewMessages />
        <PreviewSignature />
      </PreviewContentRenderProps>
    </div>
  );
};

export default PreviewContent;
