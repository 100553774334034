import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import int from "./intLang";

function ContractTitle({ int, external_id }) {
  return (
    <h3>
      {int.document_title_contract} {external_id}
    </h3>
  );
}

function OfferTitle({ int }) {
  return <h3>{int.document_title_offer}</h3>;
}

function VoucherTitle(props) {
  const {
    voucher: {
      contract: {
        offer_contract_document: { language_id },
      },
    },
  } = props;

  return !props.match.path.includes("voucher") ? null : <h3>{int[language_id].title}</h3>;
}

const mapStateToProps = state => {
  if (!state.preview.preview) {
    return {};
  }

  const {
    templateInt,
    preview: { external_id },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    int,
    external_id,
  };
};

const ContractTitlePreview = connect(mapStateToProps)(withRouter(ContractTitle));
const OfferTitlePreview = connect(mapStateToProps)(withRouter(OfferTitle));
const VoucherTitlePreview = withRouter(VoucherTitle);

function PreviewTitle(props) {
  return (
    <div className="preview-content-title preview-title ">
      {props.match.path.includes("ugovor") && <ContractTitlePreview />}
      {props.match.path.includes("ponude") && <OfferTitlePreview />}
      {props.match.path.includes("voucher") && <VoucherTitlePreview {...props} />}
    </div>
  );
}

export default withRouter(PreviewTitle);
