import React, { Component } from "react";

import gql from "graphql-tag";
import Paper from "material-ui/Paper";

import { FormControlLabel, FormGroup, FormControl, withStyles, Checkbox, Typography } from "@material-ui/core";
import { COLUMN_SIFRA_2_NAME, COLUMN_OFF_KEY_NAME } from "../../../utility/constants";
import client from "../../../apollo/apollo";

const styles = theme => ({
  root: {
    display: "flex",
  },
  typo_root: {
    margin: "24px 24px 0px 24px",
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  headline: {
    fontSize: 24,
    padding: 16,
    marginBottom: 12,
    fontWeight: 400,
    marginTop: 0,
  },
});

class FilterOfferColumns extends Component {
  toggleColumn = (datagrid_filter_id, active) => () => {
    try {
      client.mutate({
        mutation: updateColumns,
        variables: {
          datagrid_filter_id,
          active,
        },
      });
    } catch (error) {}
  };

  /**
   * swap elements in array list columns - change "Sifra 2" with "Fals" which is client demand
   * and previous logic was that frontend has showed all elements from backend already sorted
   * */

  swapColumnElements = (arr, indexSifraColumn, indexFalsColumn) => {
    if (indexFalsColumn !== -1) {
      return arr.map((val, idx) => {
        if (idx === indexSifraColumn) {
          return arr[indexFalsColumn];
        }

        if (idx === indexFalsColumn) {
          return arr[indexSifraColumn];
        }

        return val;
      });
    } else {
      return arr;
    }
  };

  render() {
    const { columns = [], classes } = this.props;

    // sort elements, swap "Šifra 2" with "Falš" column name
    let indexSifraColumn = columns.findIndex(column => column.column === COLUMN_SIFRA_2_NAME);
    let indexFalsColumn = columns.findIndex(column => column.column === COLUMN_OFF_KEY_NAME);

    let sortedColumns = this.swapColumnElements(columns, indexSifraColumn, indexFalsColumn).filter(
      column => column.column !== COLUMN_SIFRA_2_NAME,
    );

    return (
      <Paper style={{ width: "500px", height: "500px", overflow: "auto" }}>
        <div>
          <Typography variant="subheading" gutterBottom classes={{ root: classes.typo_root }}>
            Kolone
          </Typography>
          <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {sortedColumns.map(
                  col =>
                    col.column !== "Posljednji ažurirao" ? (
                      <FormControlLabel
                        key={col.id}
                        control={
                          <Checkbox
                            checked={col.active}
                            onChange={this.toggleColumn(col.id, !col.active)}
                            color="primary"
                          />
                        }
                        label={col.column}
                      />
                    ) : null,
                )}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </Paper>
    );
  }
}

const updateColumns = gql`
  mutation($datagrid_filter_id: ID!, $active: Boolean) {
    setUserDatagridFilter(datagrid_filter_id: $datagrid_filter_id, active: $active) {
      id
      desc
      column
      active
      datagrid_filter_type {
        desc
      }
    }
  }
`;

export default withStyles(styles)(FilterOfferColumns);
