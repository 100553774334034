import { useEffect } from "react";

// libs
import { useQuery } from "react-apollo";
import { get } from "lodash"; // libs
import { useDispatch } from "react-redux";

// graphql
import { listLanguage as FETCH_LIST_LANGUAGE } from "src/graphql/query/administration";

// redux state
import { setAnnouncementConstants } from "../../../announcementSlice";
import { useAnnouncementsLocalStorage } from "src/views/Announcements/hooks/useAnnouncementsLocalStorage";

/**
 * useListContainer is entry point of data for listContainer component
 */
export default function useListContainer() {
  // local storage
  const { tableState } = useAnnouncementsLocalStorage();

  const announcementTypeSelected = tableState.announcementTypeSelected;

  // redux state
  const dispatch = useDispatch();

  // query
  const { data: dataLanguages } = useQuery(FETCH_LIST_LANGUAGE);

  // save list language
  useEffect(() => {
    if (dataLanguages) {
      const listLanguageResponse = get(dataLanguages, "listLanguage");
      dispatch(setAnnouncementConstants({ listLanguage: listLanguageResponse }));
    }
  }, [dataLanguages, dispatch]);

  return {
    announcementTypeSelected,
  };
}
