import { useCallback, useState, useEffect } from "react";

// libs
import { get } from "lodash";
import toastr from "toastr";
import { useMutation, useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectProgramDayActivities, setProgramDayActivities } from "src/views/Guides/guidesSlice";

// graphql
import { listActivity } from "src/graphql/query/administration";
import { UPDATE_PROGRAM_DAY_CONTENT, DELETE_PROGRAM_DAY_ACTIVITY } from "src/graphql/mutation/operative";

// utils
import { setActivitiesForAutocomplete } from "src/utility/activities";

/**
 * useActivity is entry point of data for activityitem component
 */
export default function useActivity() {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);

  const dispatch = useDispatch();

  // state
  const [listActivities, setListActivities] = useState([]);

  /**
   * fetch activities query
   */
  const { data, loading: loadingFetch } = useQuery(listActivity, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  // change program day activities mutation
  const [updateProgramDayActivities, { loading: loadingUpdateActivity }] = useMutation(UPDATE_PROGRAM_DAY_CONTENT);
  // delete program day activity mutation
  const [deleteProgramDayActivities, { loading: loadingDeleteActivity }] = useMutation(DELETE_PROGRAM_DAY_ACTIVITY);

  /**
   * set activity list when somethig changes
   */
  useEffect(() => {
    const listActivityResponse = get(data, "listActivity", []);

    if (listActivityResponse.length) {
      setListActivities(listActivityResponse);
    }
  }, [data]);

  // prepare activities for autocomplete
  const setActivitiesIdForAutocomplete = activities =>
    activities &&
    activities.map(activity => {
      return {
        ...activity,
        value: `activity_id-${activity.id}`,
      };
    });

  /**
   * get activities to show
   */
  const getActivitiesForView = useCallback(() => {
    const activitiesForView = setActivitiesIdForAutocomplete(setActivitiesForAutocomplete(listActivities, 1));

    return activitiesForView;
  }, [listActivities]);

  // handle on activity select
  const handleOnActivitySelect = useCallback(
    async (selected, programDayId, programDayContentId) => {
      const [, id] = selected.split("-");

      const updateProgramDayResponse = await updateProgramDayActivities({
        variables: {
          input: {
            program_day_content_id: programDayContentId,
            activity_id: id,
          },
        },
      });

      const programUpdated = get(updateProgramDayResponse, "data.UpdateProgramDayContentAndGuideCalendarFields");

      if (programUpdated) {
        //todo handle on program update
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programUpdated.program_day_content_id) {
                  return {
                    ...programDayContent,
                    activity_id: programUpdated.activity_id,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });
        dispatch(setProgramDayActivities({ ...programDayActivities, program_days: program_days_updated }));
      } else {
        toastr.error("Greška prilikom unosa aktivnosti");
      }
    },
    [dispatch, programDayActivities, updateProgramDayActivities],
  );

  // handle on delete activity
  const handleOnDeleteActivity = useCallback(
    async (programDayId, programDayContentId) => {
      const deleteActivityResponse = await deleteProgramDayActivities({
        variables: {
          program_day_content_id: programDayContentId,
        },
      });

      const isActivityDeleted = get(deleteActivityResponse, "data.deleteActivityFromGuideLayout", false);

      if (isActivityDeleted) {
        // handle on activity delete
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programDayContentId) {
                  return {
                    ...programDayContent,
                    activity_id: null,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });
        dispatch(setProgramDayActivities({ ...programDayActivities, program_days: program_days_updated }));
      }
    },
    [deleteProgramDayActivities, dispatch, programDayActivities],
  );

  return {
    getActivitiesForView,
    handleOnActivitySelect,
    loadingFetch,
    loadingUpdateActivity,
    loadingDeleteActivity,
    handleOnDeleteActivity,
  };
}
