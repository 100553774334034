import React, { Component } from "react";

import ServiceList from "./ServiceList";

import Comment from "../../../../components/UI/CommentTextArea";

import { connect } from "react-redux";

import { changeEditData, addToCreateServiceType } from "../../../../store/actions";
import { config, autosize } from "../../../../utility/globals";

class EstablishmentService extends Component {
  state = {
    service_type_id: "",
  };

  selectService = id => this.setState({ service_type_id: id });

  addService = () => {
    this.props.addToCreateServiceType(this.state.service_type_id);

    this.setState({ service_type_id: "" });
  };

  componentDidUpdate() {
    this.autoResize();
  }

  componentDidMount() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName("service_package").length) {
      autosize(document.getElementsByName("service_package")[0]);
    }
    if (document.getElementsByName("comment_service").length) {
      autosize(document.getElementsByName("comment_service")[0]);
    }
  }

  render() {
    const {
      offer_contract_document_services,
      service_objects_to_create,
      service_package,
      offer_contract_document_type_id,
    } = this.props;

    return (
      <React.Fragment>
        {/* Usluga */}
        <div className="form-unit-double">
          <div className="field-label ">
            <label className="label">Osnovne usluge</label>
          </div>
          <div>
            <ServiceList
              service_types={offer_contract_document_services}
              service_objects_to_create={service_objects_to_create}
              language_id={this.props.language_id}
            />
          </div>
        </div>

        {/* Komentar usluge */}
        <div className="form-unit-double">
          <div />
          <Comment
            defaultValue={this.props.comment_service}
            name="comment_service"
            inputProps={{ disabled: true }}
            label="Komentar usluga"
          />
        </div>

        {/* Komentar usluge */}
        {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Paket usluga</label>
            </div>
            <Comment
              name="service_package"
              defaultValue={service_package}
              inputProps={{ disabled: true }}
              label="Paket usluga"
              placeholder="Paket usluga"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    service,
    offer: {
      documentServices,
      offer_contract_document: {
        offer_contract_document_services,
        comment_service,
        service_objects_to_create,
        service_package,
        language_id,
        offer_contract_document_type_id,
      },
    },
  } = state.offer;

  return {
    documentServices,
    service,
    service_package,
    offer_contract_document_services,
    service_objects_to_create,
    comment_service,
    language_id,
    offer_contract_document_type_id,
  };
};

export default connect(
  mapStateToProps,
  { changeEditData, addToCreateServiceType },
)(EstablishmentService);
