import React from "react";

// hooks
import useExpander from "./hooks/useExpander";

/**
 * Expander column
 */
export default function Expander({ programId, programDays }) {
  const { expanded } = useExpander(programId);

  if (!programDays.length) {
    return "";
  }

  if (expanded) {
    return <div className="rt-expander -open" />;
  } else {
    return <div className="rt-expander" />;
  }
}
