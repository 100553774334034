import React from "react";

// ui
import { Grid, MenuItem, TextField } from "@material-ui/core";

// hooks
import useGuideServiceContainer from "./hooks/useGuideServiceContainer";

// constants
import { HINT_COMMENT, LABEL_GUIDE_SERVICES_COMMENT } from "src/utility/labels.js";

// components
import TextFieldMultilineOutline from "src/components/UI/TextFieldMultilineOutline";

/**
 * GuideServiceContainer contains all components inside GuideServiceDrawer
 */
export default function GuideServiceContainer() {
  const {
    // variables
    isGuideServiceCommentEnabled,
    guideServiceTypes,
    guideServiceStatusSelected,
    guideServiceComment,
    // functions
    onGuideServiceStatusChange,
    onGuideServiceCommentChange,
  } = useGuideServiceContainer();

  // return root
  return (
    <Grid container spacing={16} direction="column">
      {/** guide service status*/}
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          label="Status"
          value={guideServiceStatusSelected || ""}
          onChange={event => onGuideServiceStatusChange(event.target.value)}
        >
          {guideServiceTypes
            ? guideServiceTypes.map(guideServiceType => (
                <MenuItem key={`key-${guideServiceType.name}`} value={guideServiceType.name}>
                  {guideServiceType.description}
                </MenuItem>
              ))
            : null}
        </TextField>
      </Grid>

      {isGuideServiceCommentEnabled && (
        <Grid item xs={12}>
          {/** guide service comment*/}
          <TextFieldMultilineOutline
            id="guideServiceComment"
            fullWidth={true}
            rows={5}
            rowsMax={5}
            value={guideServiceComment || ""}
            label={LABEL_GUIDE_SERVICES_COMMENT}
            placeholder={HINT_COMMENT}
            onChange={event => onGuideServiceCommentChange(event)}
          />
        </Grid>
      )}
    </Grid>
  );
}
