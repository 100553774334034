import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplatePaying extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_v =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("notification_payment");
      }).length;

    const prev_v =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("notification_payment");
      }).length;

    const next_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("payment_content");
      }).length;

    const prev_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("payment_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.notification_payment !== this.props.notification_payment ||
      nextProps.payment_content !== this.props.payment_content ||
      next_content !== prev_content ||
      next_v !== prev_v
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`payment_content`).length) {
      autosize(document.getElementsByName(`payment_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Plaćanje</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/notification_payment")}>
            <InputLabel htmlFor="notification_payment">Podsjetnik za plaćanje</InputLabel>
            <Input
              id="notification_payment"
              className="template-input"
              inputProps={{
                name: "notification_payment",
              }}
              value={this.props.notification_payment}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/notification_payment")}</FormHelperText>
          </FormControl>
          <div>
            <TextArea
              error={error("/offer_contract_document/payment_content")}
              inputProps={{
                name: "payment_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.payment_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/payment_content")}>
              {errorMsg("/offer_contract_document/payment_content")}
            </FormHelperText>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TemplatePayingHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplatePaying />
  </OfferForm.ConsumerHOC>
);

export default TemplatePayingHOC;
