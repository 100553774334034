import { useCallback, useState } from "react";

// libs
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-apollo";
import toastr from "toastr";

// redux state
import {
  selectAnnouncementSelected,
  selectIsVoucherCreateDialogOpen,
  setIsVoucherCreateDialogOpen,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { CREATE_VOUCHER } from "src/graphql/mutation/voucher";

// constants
import {
  HOTEL_GUIDE_ANNOUNCEMENT,
  HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GUIDE_ANNOUNCEMENT,
  GUIDE_TOUR_ANNOUNCEMENT,
  GUIDE_LOCAL_ANNOUNCEMENT,
  AGENCY_ANNOUNCEMENT,
  AGENCY_TOUR_ANNOUNCEMENT,
  AGENCY_LOCAL_ANNOUNCEMENT,
  AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  SUPPLIER_ANNOUNCEMENT,
  VOUCHER_HOTEL,
  VOUCHER_PROGRAM,
  VOUCHER_HOTEL_FOREIGN_AGENCY,
  VOUCHER_TICKETS_AND_RESTAURANTS,
  VOUCHER_GUIDES_FOREIGN_AGENCY,
} from "src/utility/constants";
import { addAnnouncementVoucher } from "../../VoucherList/voucherSlice";

/**
 * useVoucherCreate is entry point of data for voucher type select component
 */
export default function useVoucherCreate() {
  const announcementSelected = useSelector(selectAnnouncementSelected);
  const isVoucherCreateDialogOpen = useSelector(selectIsVoucherCreateDialogOpen);

  const dispatch = useDispatch();

  // component state
  const [voucherTypeSelected, setVoucherTypeSelected] = useState();

  // mutations
  const [createVoucher] = useMutation(CREATE_VOUCHER);

  /**
   * handle on type select dialog close
   */
  const handleOnDialogClose = () => {
    // set voucher dialog closed
    dispatch(setIsVoucherCreateDialogOpen(false));

    // set component state
    setVoucherTypeSelected(null);
  };

  /**
   * handle on voucher type select
   */
  const handleOnVoucherTypeSelect = voucherType => {
    setVoucherTypeSelected(voucherType);
  };

  /**
   * handle on create voucher click
   */
  const handleOnCreateVoucher = async () => {
    const responseCreateVoucher = await createVoucher({
      variables: {
        input: {
          announcement_id: announcementSelected.id,
          voucher_type: voucherTypeSelected,
        },
      },
    });

    if (responseCreateVoucher) {
      const voucherCreated = get(responseCreateVoucher, "data.createVoucher");

      // set vouchers
      dispatch(addAnnouncementVoucher(voucherCreated));
      // close voucher create dialog
      handleOnDialogClose();
      toastr.success("Uspješno kreiran voucher");
    } else {
      toastr.success("Greška prilikom kreiranja, pokušajte ponovo");
    }
  };

  /**
   * get voucher types for selected announcement type
   */
  const getVoucherTypes = useCallback(() => {
    let voucherTypes = [];

    const announcementType = get(announcementSelected, "announcement_type");

    switch (announcementType) {
      case HOTEL_GUIDE_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL);
        break;

      case HOTEL_NO_GUIDE_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL);
        break;

      case GUIDE_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL, VOUCHER_PROGRAM, VOUCHER_TICKETS_AND_RESTAURANTS);
        break;

      case GUIDE_TOUR_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL, VOUCHER_PROGRAM, VOUCHER_TICKETS_AND_RESTAURANTS);
        break;

      case GUIDE_LOCAL_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_GUIDES_FOREIGN_AGENCY, VOUCHER_TICKETS_AND_RESTAURANTS);
        break;

      case AGENCY_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL_FOREIGN_AGENCY, VOUCHER_TICKETS_AND_RESTAURANTS, VOUCHER_GUIDES_FOREIGN_AGENCY);
        break;

      case AGENCY_TOUR_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL_FOREIGN_AGENCY, VOUCHER_TICKETS_AND_RESTAURANTS, VOUCHER_GUIDES_FOREIGN_AGENCY);
        break;

      case AGENCY_LOCAL_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_HOTEL_FOREIGN_AGENCY, VOUCHER_TICKETS_AND_RESTAURANTS, VOUCHER_GUIDES_FOREIGN_AGENCY);
        break;

      case AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_GUIDES_FOREIGN_AGENCY, VOUCHER_TICKETS_AND_RESTAURANTS);
        break;

      case SUPPLIER_ANNOUNCEMENT:
        voucherTypes.push(VOUCHER_TICKETS_AND_RESTAURANTS);
        break;

      default:
    }
    return voucherTypes;
  }, [announcementSelected]);

  return {
    // variables
    isVoucherCreateDialogOpen,
    voucherTypeSelected,
    // functions
    getVoucherTypes,
    handleOnCreateVoucher,
    handleOnDialogClose,
    handleOnVoucherTypeSelect,
  };
}
