import React from "react";

// libs
import { get } from "lodash";

// ui
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, withStyles } from "@material-ui/core";

// hooks
import useActivities from "./hooks/useActivities";

// stylea
const styles = {
  expansionPanel: {
    backgroundColor: "transparent",
    height: "100%",
  },

  activitiesContainer: {
    marginTop: 2,
    marginBottom: 2,
  },

  activityItemContainer: {
    marginTop: 4,
    marginBottom: 4,
  },
};

/**
 * Activities column
 */
const Activities = ({ classes, programId, programDays }) => {
  const { expanded } = useActivities(programId);

  if (!programDays && !programDays.length) {
    return null;
  }

  return (
    <ExpansionPanel className={classes.expansionPanel} elevation={0} expanded={expanded}>
      <ExpansionPanelSummary>Ukupno {programDays.length} dan/a</ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.activitiesContainer}>
          {programDays.map((programDay, index) => {
            const title = get(programDay, "title", "");
            const date = get(programDay, "date", "");

            return (
              <div className={classes.activityItemContainer} key={programDay.program_day_id}>
                {`${date} - ${title || ""}`}
              </div>
            );
          })}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(Activities);
