import React from "react";

// libs
import { Link } from "react-router-dom";
import { get } from "lodash";

// constants
import {
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_ID,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_EDIT,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_DATE_CREATED,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_FROM,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_TO,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_THEME_NAME,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_CONTRACT,
  COLUMN_TITLE_ANNOUNCEMENT_ORDER_PARTNER,
} from "src/utility/labels";

import { ANNOUNCEMENT_ORDER_ROW_ID_EDIT } from "src/utility/constants";

// utils
import { formatFilterDate } from "src/utility/globals";

// components
import EditIcon from "src/components/UI/EditIcon";
import FilterDate from "src/components/UI/FilterDate";
import { formatDate, getDateForView } from "src/utility/dates";

/**
 * agency announcement columns
 */
export default [
  {
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_EDIT,
    id: ANNOUNCEMENT_ORDER_ROW_ID_EDIT,
    accessor: "id",
    width: 65,
    filterable: false,
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Link to={`/nalozi/edit/${value}`}>
            <EditIcon style={{ cursor: "pointer" }} />
          </Link>
        </div>
      );
    },
  },
  {
    // announcement order id
    id: "id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_ID,
    width: 80,
    accessor: "id",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
    Filter: ({ filter, onChange }) => {
      return (
        <input
          style={{ width: "100%" }}
          type="number"
          value={filter ? filter.value : ""}
          onChange={event => {
            const value = event.target.value;
            // check for int range
            if (value.length <= 10) {
              return onChange(value);
            }
          }}
        />
      );
    },
  },

  {
    // contract NO
    id: "external_id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_CONTRACT,
    accessor: "external_id",
    width: 80,
    Cell: ({ value }) => <div style={{ textAlign: "left", width: "100%" }}>{value}</div>,
  },

  {
    // partner_client
    id: "partner_client",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_PARTNER,
    accessor: "partner_client",
    width: 150,
    Cell: ({ value }) => <div style={{ textAlign: "left", width: "100%" }}>{value}</div>,
  },

  {
    // date created
    id: "date",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_DATE_CREATED,
    width: 170,
    accessor: "date",
    filterable: false,
    Cell: ({ value }) => {
      return <div style={{ textAlign: "center", width: "100%" }}>{getDateForView(value)}</div>;
    },
    Filter: ({ filter, onChange }) => {
      const handleOnDateChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <FilterDate dateSelected={filter} handleOnDateChange={handleOnDateChange} />;
    },
  },

  {
    // termin from
    id: "from",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_FROM,
    accessor: "dictate_data",
    width: 120,
    Cell: ({ value }) => {
      const dateFrom = get(value, "from", "");

      return <div style={{ textAlign: "center", width: "100%" }}>{formatDate(dateFrom)}</div>;
    },
    Filter: ({ filter, onChange }) => {
      const handleOnDateChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <FilterDate dateSelected={filter} handleOnDateChange={handleOnDateChange} />;
    },
  },
  {
    // termin date to
    id: "to",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_TERMIN_TO,
    accessor: "dictate_data",
    width: 120,
    Cell: ({ value }) => {
      const dateTo = get(value, "to", "");

      return <div style={{ textAlign: "center", width: "100%" }}>{formatDate(dateTo)}</div>;
    },
    Filter: ({ filter, onChange }) => {
      const handleOnDateChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <FilterDate dateSelected={filter} handleOnDateChange={handleOnDateChange} />;
    },
  },

  {
    // theme name
    id: "theme_name",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ORDER_THEME_NAME,
    width: 170,
    accessor: "dictate_data",
    Cell: ({ value }) => {
      return <div style={{ textAlign: "center", width: "100%" }}>{get(value, "theme_name")}</div>;
    },
  },
];
