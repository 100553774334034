// libs
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGroupOperativeStatusTypes,
  selectGuideScheduleDrawerPayload,
  setGuideDrawerPayload,
} from "src/views/Guides/guidesSlice";

// constants
import { useCallback } from "react";

/**
 * useGroupStatusContainer is entry point of data for Group status component
 */
export default function useGroupStatusContainer() {
  // redux state
  const groupOperativeStatusTypes = useSelector(selectGroupOperativeStatusTypes);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  /**
   * handle on group status change
   */
  const onGroupStatusChange = useCallback(
    groupStatus => {
      dispatch(setGuideDrawerPayload({ ...guidePayload, groupStatus: groupStatus }));
    },
    [dispatch, guidePayload],
  );

  /**
   * return groupStatus as upperCase since enumValues are uppercase received from
   */
  return {
    groupOperativeStatusTypes,
    onGroupStatusChange,
    groupStatus: get(guidePayload, "groupStatus", ""),
  };
}
