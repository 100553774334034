// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_AGENCY_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateAgencyAnnouncement is used for CreateAgencyAnnouncement mutation
 */
export default function useCreateAgencyAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createAgencyAnnouncement] = useMutation(CREATE_AGENCY_ANNOUNCEMENT);

  const handleCreateAgencyAnnouncement = async languageSelected => {
    const createAnnouncementResponse = await createAgencyAnnouncement({
      variables: {
        input: {
          program_id: programId,
          guide_id: supplier.guide_id,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createAgencyAnnouncement");
  };
  return {
    handleCreateAgencyAnnouncement,
  };
}
