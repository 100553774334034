import React from "react";

import { findServiceTypeByLangId } from "../../../../utility/services";
import { formatDate } from "../../../../utility/dates";
import ReactTableCustom from "../../../../components/UI/ReactTable";

class ServiceList extends React.Component {
  static AddedServices = ({ service_types, language_id }) => {
    const dataToShow =
      (service_types &&
        service_types.map(serviceType => {
          const serviceTypeWithTranslation = {
            id: serviceType.id,
            service_type_translations: serviceType.service_types[0].service_type_translations,
          };

          const st = findServiceTypeByLangId(serviceTypeWithTranslation, language_id);

          const name = (st && st.name) || "";

          const service_from = formatDate(serviceType.service_from);
          const service_to = formatDate(serviceType.service_to);

          if (serviceType !== -1) {
            return { ...serviceType, name, key: "created", service_from, service_to };
          }
          return {};
        })) ||
      [];

    return (
      <ReactTableCustom
        data={dataToShow}
        columns={[
          {
            Header: "Dodane usluge",
            columns: [
              {
                Header: "Usluga",
                accessor: "name",
                Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
              },
              {
                Header: "Datum od",
                accessor: "service_from",
                Cell: ({ value }) => (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {value !== "Invalid date" ? value : "Nema datuma"}
                  </div>
                ),
              },
              {
                Header: "Datum do",
                accessor: "service_to",
                Cell: ({ value }) => (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {value !== "Invalid date" ? value : "Nema datuma"}
                  </div>
                ),
              },
            ],
          },
        ]}
        innerProps={{
          pageSize: dataToShow.length,
          showPagination: false,
          sortable: false,
          filterable: false,
          filterAll: false,
        }}
      />
    );
  };

  render() {
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        removeCreateServiceType: this.props.removeCreateServiceType,
        service_types: this.props.service_types,
        removeService: this.props.removeService,
        language_id: this.props.language_id,
      }),
    );
  }
}

const ServiceListUsage = props => (
  <ServiceList {...props}>
    <ServiceList.AddedServices />
  </ServiceList>
);

export default ServiceListUsage;
