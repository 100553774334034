import React from "react";

// context
import useContact from "./hooks/useContact";
// components
import AutocompleteSelect from "src/components/UI/AutocompleteSelect";

/**
 * used for rendering contact item in list
 */
export default function ContactItem({ partnerClientId, programDayId, programDayContent }) {
  const { listPartnerClientContacts, loading, handleOnContactSelect } = useContact(partnerClientId);

  // return root
  return (
    <AutocompleteSelect
      defaultValue={programDayContent.guide_id || ""}
      placeholder="Odaberite vodiča"
      isLoading={loading}
      disabled={!listPartnerClientContacts.length}
      dataSource={listPartnerClientContacts.map(partnerContact => ({
        value: partnerContact.id,
        label: partnerContact.first_name,
      }))}
      autocompleteHandler={partnerContactId => {
        handleOnContactSelect(programDayId, programDayContent.program_day_content_id, partnerContactId);
      }}
    />
  );
}
