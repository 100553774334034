import React from "react";

import { Tabs, Tab } from "material-ui/Tabs";
import ServiceTabsHOC from "./ServiceTabsHOC";

class ServiceTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = value => {
    this.setState({ value: value });
  };

  render() {
    const { showInTabService, showInTabServiceType } = this.props;

    return (
      <Tabs value={this.state.value} onChange={this.handleChange}>
        <Tab label="Uključene usluge" value={0}>
          <div className="form">{showInTabService()}</div>
        </Tab>
        <Tab label="Osnovna usluga" value={1}>
          <div className="form">{showInTabServiceType()}</div>
        </Tab>
      </Tabs>
    );
  }
}

export default ServiceTabsHOC(ServiceTabs);
