import React, { Component } from "react";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Button from "@material-ui/core/Button";
import PartnerClientServiceTranslationFormShow from "./PartnerClientServiceTranslationFormShow";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const fitCard = { width: "fit-content" };

class PartnerClientServiceShow extends Component {
  render() {
    const { service, listLanguage, listPartnerClientServiceType, setServiceForEdit, removeService } = this.props;

    let serviceType = null;

    if (service && service.partner_client_service_type_id && listPartnerClientServiceType) {
      const serviceTypeFound = listPartnerClientServiceType.find(
        type => type.id.toString() === service.partner_client_service_type_id.toString(),
      );
      serviceType = serviceTypeFound ? serviceTypeFound.desc : serviceType;
    }

    return (
      <Grid xl={6} item>
        <Card style={fitCard}>
          <CardHeader title="Usluga" />
          <CardText>
            <Grid container spacing={24}>
              <Grid item sm={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="price">
                    Cijena
                  </InputLabel>
                  <Input id="price" name="price" disabled value={service.price ? service.price : ""} />
                </FormControl>
              </Grid>
              <Grid item sm={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="price">
                    Tip usluge
                  </InputLabel>
                  <Input id="select-currency" label="Tip usluge" disabled value={serviceType || ""} />
                </FormControl>
              </Grid>
            </Grid>
            <PartnerClientServiceTranslationFormShow
              service_translations={service.partner_client_service_translations}
              listLanguage={listLanguage}
            />
          </CardText>
          <CardActions className="cardactions-button-padding">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setServiceForEdit(service);
              }}
            >
              Izmjeni
            </Button>
            <Button variant="outlined" size="small" onClick={() => removeService(service.id)}>
              Izbriši uslugu
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}
export default PartnerClientServiceShow;
