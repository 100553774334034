import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import RegionForm from "./RegionForm";
import { createRegion } from "../../../../graphql/mutation/administration";
import TableTitle from "src/components/UI/TableTitle";

class CreateRegion extends Component {
  state = {
    name: "",
    country_id: "",
  };

  changeFieldData = obj => {
    this.setState({
      [obj.key]: obj.value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      this.props.cancelToggle();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title="Stvaranje nove regije" onBackPressed={this.props.cancelToggle} />
        <RegionForm
          title={`Stvaranje nove regije`}
          onSubmit={this.submit}
          region={this.state}
          cancelToggle={this.props.cancelToggle}
          onChangeField={this.changeFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(createRegion)(CreateRegion);
