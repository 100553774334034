import React from "react";
import { Switch, Route } from "react-router-dom";

import AllContracts from "./AllContracts/AllContracts";
import EditContract from "./EditContract/EditContract";

const ContractRouter = () => {
  return (
    <Switch>
      <Route path="/ugovor/edit/:id" component={EditContract} />
      <Route path="/" render={props => <AllContracts {...props} tableName={"contractsTable"} />} />
    </Switch>
  );
};

export default ContractRouter;
