import React from "react";

// hooks
import useLayoutComment from "./hooks/useLayoutComment";

// constants
import { TITLE_GUIDE_LAYOUT_COMMENT } from "src/utility/labels";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import LayoutCommentContainer from "./components/Container";

/**
 * LayoutComments drawer is used for showing data for layout comment guide
 */
export default function LayoutComment() {
  const { handleOnGuideCommentSave, isDrawerOpen, loading, onCloseDrawer } = useLayoutComment();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnGuideCommentSave}
      title={TITLE_GUIDE_LAYOUT_COMMENT}
      customWidth="85%"
    >
      <LayoutCommentContainer />
    </DrawerWrapper>
  );
}
