import React from "react";

// hooks
import usePayment from "./hooks/usePayment";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import PaymentContainer from "./components/Container";

// constants
import { TITLE_GUIDE_PAYMENT } from "src/utility/labels";

/**
 * Payment drawer is used for showing data of Payment
 */
export default function Payment() {
  const { handleOnPaymentTextChange, isDrawerOpen, loading, onCloseDrawer } = usePayment();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnPaymentTextChange}
      title={TITLE_GUIDE_PAYMENT}
      customWidth="85%"
    >
      <PaymentContainer />
    </DrawerWrapper>
  );
}
