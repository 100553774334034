import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import toastr from "toastr";

import EstablishmentChainForm from "./EstablishmentChainForm";
import { createEstablishmentChain } from "../../../../graphql/mutation/administration";
import TableTitle from "src/components/UI/TableTitle";

class CreateEstablishmentChain extends Component {
  state = {
    establishmentChain: {
      name: "",
      city_id: "",
    },
  };

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        establishmentChain: {
          ...prevState.establishmentChain,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { establishmentChain } = this.state;

      await this.props.mutate({
        variables: {
          ...establishmentChain,
        },
      });
      toastr.success("Uspješno kreirano");

      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    const { establishmentChain } = this.state;

    return (
      <Fragment>
        <TableTitle title="Kreiranje smještajnog lanca" onBackPressed={this.props.cancelToggle} />

        <EstablishmentChainForm
          title={`Kreiranje novog smještajnog lanca`}
          establishmentChain={establishmentChain}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.cancelToggle}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(createEstablishmentChain)(CreateEstablishmentChain);
