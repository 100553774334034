import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { config } from "../../../utility/globals";

const PROGRAM_ID = "program-";

class PreviewDailyProgram extends Component {
  programTitle = program => {
    const description = program.description ? program.description : "";

    if (program.title) {
      return (
        <Fragment>
          <strong>{program.title} </strong>

          <br />
          <br />

          {description}
        </Fragment>
      );
    }

    return description;
  };

  render() {
    const { termin_periods, int, program_visible, programs, offer_contract_document_type_id, templateInt } = this.props;

    if (!program_visible) {
      return null;
    }

    let termin, program_days;
    if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
      termin = termin_periods && termin_periods[0] ? termin_periods[0] : null;

      if (!termin) {
        return null;
      }
      if (!termin.programs || !termin.programs[0]) {
        return null;
      }

      program_days = termin.programs[0].program_days;

      this.program_days = program_days;
    } else {
      program_days = programs && programs[0] ? programs[0].program_days : [];

      this.program_days = program_days;
    }

    return (
      <div>
        <div className="preview-content-title ">
          <h4>{int.program_sequence}</h4>
        </div>
        {this.program_days.map((program, index) => {
          const content = this.programTitle(program);

          return (
            <div
              key={`program-key-${program.id}`}
              className={`preview-content preview-content-program${(config.language[String(templateInt.language)] ===
                "Italian" &&
                "-italian") ||
                ""}`}
            >
              <div className="preview-content-title ">
                <h4>{`${int.day}. ${++index}`}</h4>
              </div>
              <div disabled id={`${PROGRAM_ID}${program.id}`} className="preview-content-div preview-textarea">
                {content}
              </div>
              <br />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: { termin_periods, program_visible, programs, offer_contract_document_type_id },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    termin_periods,
    program_visible,
    int,
    programs,
    offer_contract_document_type_id,
    templateInt,
  };
};

export default connect(mapStateToProps)(PreviewDailyProgram);
