import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormValidator from "../../../../../components/UI/FormValidator";
import { FormHelperText, Card, CardContent } from "@material-ui/core";

const styles = {
  card: {
    width: "100%",
    marginBottom: 15,
    background: "#f9fafc",
  },
};

const ParnerClientContactForm = ({
  contact,
  onChangeContact,
  removeContact,
  saveChanges,
  cancelChanges,
  addContact,
  classes,
}) => {
  return (
    <FormValidator
      schema={ParnerClientContactForm.schema}
      submit={contact.id ? saveChanges : addContact}
      data={contact}
    >
      {({ error, errorMsg, submitAndRecreate }) => {
        return (
          <React.Fragment>
            {!contact.id ? (
              <Grid item xs={12}>
                <br />
                <h3>Dodaj novi kontakt</h3>
                <br />
              </Grid>
            ) : null}
            <Card className={classes.card}>
              <CardContent>
                <Grid item container xs={12}>
                  <Grid container spacing={24}>
                    <Grid item sm={12} lg={2}>
                      <FormControl fullWidth error={error("/first_name")}>
                        <InputLabel shrink={true} htmlFor="first_name_partner_client_contact">
                          Ime
                        </InputLabel>
                        <Input
                          id="first_name_partner_client_contact"
                          name="first_name_partner_client_contact"
                          value={contact.first_name || ""}
                          onChange={({ target: { value } }) =>
                            contact.id
                              ? onChangeContact({ key: "first_name", value }, contact.id)
                              : onChangeContact({ key: "first_name", value })
                          }
                        />
                        {error("/first_name") && <FormHelperText>{errorMsg("/first_name")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                      <FormControl fullWidth error={error("/last_name")}>
                        <InputLabel shrink={true} htmlFor="last_name_partner_client_contact">
                          Prezime
                        </InputLabel>
                        <Input
                          id="last_name_partner_client_contact"
                          name="last_name_partner_client_contact"
                          value={contact.last_name || ""}
                          onChange={({ target: { value } }) =>
                            contact.id
                              ? onChangeContact({ key: "last_name", value }, contact.id)
                              : onChangeContact({ key: "last_name", value })
                          }
                        />
                        {error("/last_name") && <FormHelperText>{errorMsg("/last_name")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                      <FormControl fullWidth error={error("/phone")}>
                        <InputLabel shrink={true} htmlFor="phone_partner_client_contact">
                          Telefon
                        </InputLabel>
                        <Input
                          id="phone_partner_client_contact"
                          name="phone_partner_client_contact"
                          value={contact.phone || ""}
                          onChange={({ target: { value } }) =>
                            contact.id
                              ? onChangeContact({ key: "phone", value }, contact.id)
                              : onChangeContact({ key: "phone", value })
                          }
                        />
                        {error("/phone") && <FormHelperText>{errorMsg("/phone")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                      <FormControl fullWidth error={error("/email")}>
                        <InputLabel shrink={true} htmlFor="email_client_contact">
                          Email
                        </InputLabel>
                        <Input
                          id="email_client_contact"
                          name="email_client_contact"
                          value={contact.email || ""}
                          onChange={({ target: { value } }) =>
                            contact.id
                              ? onChangeContact({ key: "email", value }, contact.id)
                              : onChangeContact({ key: "email", value })
                          }
                        />
                        {error("/email") && <FormHelperText>{errorMsg("/email")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    {/* // Check if contact exists */}
                    <Grid item sm={12} lg={4}>
                      {contact.id ? (
                        <Grid container spacing={24}>
                          <Grid item>
                            <Button variant="contained" onClick={submitAndRecreate}>
                              Spremi
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button variant="outlined" onClick={() => removeContact(contact.id)}>
                              Izbriši
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button variant="outlined" onClick={cancelChanges}>
                              Odustani
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Button variant="contained" onClick={submitAndRecreate}>
                          Dodaj
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      }}
    </FormValidator>
  );
};

ParnerClientContactForm.schema = {
  anyOf: [
    {
      properties: {
        first_name: {
          type: "string",
          minLength: 1,
          isNotEmpty: true,
          errorMessage: "Potrebno je unijeti ime",
        },
      },
    },
    {
      properties: {
        last_name: {
          type: "string",
          minLength: 1,
          isNotEmpty: true,
          errorMessage: "Potrebno je unijeti prezime",
        },
      },
    },
    {
      properties: {
        email: {
          type: "string",
          format: "isEmail",
          minLength: 1,
          isNotEmpty: true,
          errorMessage: {
            minLength: "Potrebno je unijeti email",
            isNotEmpty: "Potrebno je unijeti email",
            format: "Nije email",
          },
        },
      },
    },
    {
      properties: {
        phone: {
          type: "string",
          minLength: 1,
          isNotEmpty: true,
          errorMessage: "Potrebno je unijeti telefon",
        },
      },
    },
  ],
};

export default withStyles(styles)(ParnerClientContactForm);
