// libs
import { useQuery } from "react-apollo";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectVoucherViewerOpened, selectVoucherSelected, setVoucherSelected } from "../../VoucherList/voucherSlice";

// graphql
import { FETCH_VOUCHER } from "src/graphql/query/voucher";

// constants
import { formatDate } from "src/utility/dates";

/**
 * useVoucherViewer is entry point of data for voucherViewer component
 */
export default function useVoucherViewer() {
  // redux state
  const isVoucherViewerOpened = useSelector(selectVoucherViewerOpened);
  const voucherSelected = useSelector(selectVoucherSelected);

  const dispatch = useDispatch();

  /**
   * fetch voucher
   */
  const { data, loading } = useQuery(FETCH_VOUCHER, {
    variables: { id: voucherSelected.id },
    skip: !voucherSelected.id,
  });

  const voucher = get(data, "getVoucher");
  const voucherType = get(voucher, "voucher_type");

  /**
   * onVoucherViewerClose handling
   */
  const onVoucherViewerClose = () => {
    dispatch(setVoucherSelected({ isVoucherViewerOpened: false, voucherSelected: {} }));
  };

  /**
   * get voucher fileName for PDF save
   */
  const getRenderedDocumentFileName = () => {
    if (voucherType) {
      const voucherTitle = get(voucher, "voucher_title", "");
      const voucherUniqueNr = get(voucher, "voucher_unique_nr", "");
      const voucherCreatedDate = formatDate(get(voucher, "date", ""));

      return `${voucherTitle}-${voucherUniqueNr}-${voucherCreatedDate}.pdf`;
    } else {
      return "voucher.pdf";
    }
  };

  return {
    // variables
    voucher,
    voucherType,
    loading,
    isVoucherViewerOpened,
    isVoucherFetched: Boolean(voucher),

    // functions
    getRenderedDocumentFileName,
    onVoucherViewerClose,
  };
}
