import React from "react";

import { Tabs, Tab } from "material-ui/Tabs";
import ActivityTabsHOC from "./ActivityTabsHOC";

class EstablishmentTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = value => {
    this.setState({ value: value });
  };

  render() {
    const { showInTabActivity, showInTabActivityType } = this.props;

    return (
      <Tabs value={this.state.value} onChange={this.handleChange}>
        <Tab label="Aktivnosti" value={0}>
          <div className="form">{showInTabActivity()}</div>
        </Tab>
        <Tab label="Tipovi aktivnosti" value={1}>
          <div className="form">{showInTabActivityType()}</div>
        </Tab>
      </Tabs>
    );
  }
}

export default ActivityTabsHOC(EstablishmentTabs);
