import gql from "graphql-tag";
import {
  cityFragment,
  establishmentFragment,
  establishmentLegalEntityFragment,
  partnerClientFragment,
  serviceTypeFragment,
  typologyFragment,
} from "../fragments";
import { templateAnnouncementFragment } from "../administrationFragments";

export const createCountry = gql`
  mutation($name: String!, $iso_3166_code: String, $dialling_code: String) {
    createCountry(input: { name: $name, iso_3166_code: $iso_3166_code, dialling_code: $dialling_code }) {
      id
      name
      iso_3166_code
      dialling_code
    }
  }
`;

export const updateCountry = gql`
  mutation($id: ID!, $name: String!, $iso_3166_code: String, $dialling_code: String) {
    updateCountry(
      input: { id: $id, patch: { name: $name, iso_3166_code: $iso_3166_code, dialling_code: $dialling_code } }
    ) {
      id
      name
      iso_3166_code
      dialling_code
    }
  }
`;

export const createRegion = gql`
  mutation($name: String!, $country_id: ID!) {
    createRegion(input: { name: $name, country_id: $country_id }) {
      id
      name
      country {
        id
        name
        iso_3166_code
      }
    }
  }
`;

export const updateRegion = gql`
  mutation($id: ID!, $name: String!, $country_id: ID!) {
    updateRegion(input: { id: $id, patch: { name: $name, country_id: $country_id } }) {
      id
      name
      country {
        id
        name
        iso_3166_code
      }
    }
  }
`;

export const createCity = gql`
  mutation($name: String!, $postal_code: String, $area_code: String!, $region_id: ID!) {
    createCity(input: { name: $name, postal_code: $postal_code, area_code: $area_code, region_id: $region_id }) {
      ...City
    }
  }
  ${cityFragment}
`;

export const updateCity = gql`
  mutation($id: ID!, $name: String!, $postal_code: String, $area_code: String!, $region_id: ID!) {
    updateCity(
      input: {
        id: $id
        patch: { name: $name, postal_code: $postal_code, area_code: $area_code, region_id: $region_id }
      }
    ) {
      ...City
    }
  }
  ${cityFragment}
`;

export const createTypology = gql`
  mutation(
    $code: String
    $establishment_code: String
    $persons_capacity: Int
    $establishment_id: ID!
    $typology_group_id: ID!
    $priority: Int
    $typology_translations: [CreateTypologyTranslationInput!]!
  ) {
    createTypology(
      input: {
        code: $code
        establishment_code: $establishment_code
        persons_capacity: $persons_capacity
        priority: $priority
        establishment_id: $establishment_id
        typology_group_id: $typology_group_id
        typology_translations: $typology_translations
      }
    ) {
      ...Typology
    }
  }
  ${typologyFragment}
  ${establishmentFragment}
`;

export const updateTypologyMutation = gql`
  mutation($id: ID!, $patch: UpdateTypology!) {
    updateTypology(input: { id: $id, patch: $patch }) {
      ...Typology
    }
  }
  ${typologyFragment}
  ${establishmentFragment}
`;

export const deleteTypology = gql`
  mutation($id: ID!) {
    deleteTypology(id: $id)
  }
`;

export const createEstablishmentChain = gql`
  mutation($name: String!, $city_id: ID!) {
    createEstablishmentChain(input: { name: $name, city_id: $city_id }) {
      id
      name
      city_id
      city {
        ...City
      }
    }
  }
  ${cityFragment}
`;

export const updateEstablishmentChain = gql`
  mutation($id: ID!, $name: String, $city_id: ID) {
    updateEstablishmentChain(input: { id: $id, patch: { name: $name, city_id: $city_id } }) {
      id
      name
      city_id
      city {
        ...City
      }
    }
  }
  ${cityFragment}
`;

export const createEstablishmentLegalEntity = gql`
  mutation(
    $name: String!
    $web: String
    $address1: String
    $address2: String
    $email: String
    $phone: String
    $fax: String
    $contact_person1: String
    $contact_person2: String
    $oib: String
    $city_id: ID
  ) {
    createEstablishmentLegalEntity(
      input: {
        name: $name
        web: $web
        address1: $address1
        address2: $address2
        email: $email
        phone: $phone
        fax: $fax
        contact_person1: $contact_person1
        contact_person2: $contact_person2
        oib: $oib
        city_id: $city_id
      }
    ) {
      ...EstablishmentLegalEntity
    }
  }
  ${cityFragment}
  ${establishmentLegalEntityFragment}
`;

export const updateEstablishmentLegalEntity = gql`
  mutation(
    $id: ID!
    $name: String!
    $web: String
    $address1: String
    $address2: String
    $email: String
    $phone: String
    $fax: String
    $contact_person1: String
    $contact_person2: String
    $oib: String
    $city_id: ID
  ) {
    updateEstablishmentLegalEntity(
      input: {
        id: $id
        patch: {
          name: $name
          web: $web
          address1: $address1
          address2: $address2
          email: $email
          phone: $phone
          fax: $fax
          contact_person1: $contact_person1
          contact_person2: $contact_person2
          oib: $oib
          city_id: $city_id
        }
      }
    ) {
      ...EstablishmentLegalEntity
    }
  }
  ${cityFragment}
  ${establishmentLegalEntityFragment}
`;

export const createEstablishment = gql`
  mutation(
    $name: String!
    $web: String
    $address1: String
    $address2: String
    $email: String
    $phone: String
    $fax: String
    $contact_person1: String
    $contact_person2: String
    $rating: String
    $breakfast_and_dinner_times: String
    $breakfast_and_dinner_buffet_or_menu: String
    $currency_exchange: Boolean
    $currency_exchange_comment: String
    $parking: Boolean
    $parking_comment: String
    $wellnes: Boolean
    $wellnes_comment: String
    $pools: Boolean
    $pools_comment: String
    $typologies: [CreateTypologyEstablishmentInput]
    $city_id: ID
    $establishment_legal_entity_id: ID
    $establishment_chain_id: ID
  ) {
    createEstablishment(
      input: {
        name: $name
        web: $web
        address1: $address1
        address2: $address2
        email: $email
        phone: $phone
        fax: $fax
        contact_person1: $contact_person1
        contact_person2: $contact_person2
        rating: $rating
        breakfast_and_dinner_times: $breakfast_and_dinner_times
        breakfast_and_dinner_buffet_or_menu: $breakfast_and_dinner_buffet_or_menu
        currency_exchange: $currency_exchange
        currency_exchange_comment: $currency_exchange_comment
        parking: $parking
        parking_comment: $parking_comment
        wellnes: $wellnes
        wellnes_comment: $wellnes_comment
        pools: $pools
        pools_comment: $pools_comment
        typologies: $typologies
        city_id: $city_id
        establishment_legal_entity_id: $establishment_legal_entity_id
        establishment_chain_id: $establishment_chain_id
      }
    ) {
      ...Establishment
      city {
        ...City
      }

      typologies {
        ...Typology
      }
    }
  }
  ${establishmentFragment}
  ${typologyFragment}
  ${cityFragment}
`;

export const updateEstablishment = gql`
  mutation($id: ID!, $patch: UpdateEstablishment!) {
    updateEstablishment(input: { id: $id, patch: $patch }) {
      ...Establishment
      city {
        ...City
      }

      typologies {
        ...Typology
      }
    }
  }
  ${establishmentFragment}
  ${typologyFragment}
  ${cityFragment}
`;

export const deleteEstablishment = gql`
  mutation($id: ID!) {
    deleteEstablishment(id: $id)
  }
`;

export const createService = gql`
  mutation($service_type_id: ID, $service_translations: [CreateServiceTranslationInput!]!) {
    createService(input: { service_type_id: $service_type_id, service_translations: $service_translations }) {
      id
      service_translations {
        id
        name
        service_id
        language_id
        language {
          id
          code
          desc
        }
      }
    }
  }
`;

export const updateService = gql`
  mutation($id: ID!, $patch: UpdateService!) {
    updateService(input: { id: $id, patch: $patch }) {
      id
      service_translations {
        id
        name
        service_id
        language_id
        language {
          id
          code
          desc
        }
      }
    }
  }
`;

export const createServiceType = gql`
  mutation($service_type_translations: [CreateServiceTypeTranslationInput!]!) {
    createServiceType(input: { service_type_translations: $service_type_translations }) {
      ...ServiceType
    }
  }
  ${serviceTypeFragment}
`;

export const updateServiceType = gql`
  mutation($id: ID!, $patch: UpdateServiceType!) {
    updateServiceType(input: { id: $id, patch: $patch }) {
      ...ServiceType
    }
  }
  ${serviceTypeFragment}
`;

export const createPartnerClientServiceType = gql`
  mutation($desc: String, $code: String) {
    createPartnerClientServiceType(input: { desc: $desc, code: $code }) {
      id
      desc
      code
    }
  }
`;

export const updatePartnerClientServiceType = gql`
  mutation($id: ID!, $desc: String, $code: String) {
    updatePartnerClientServiceType(input: { id: $id, patch: { desc: $desc, code: $code } }) {
      id
      desc
    }
  }
`;

export const updateUser = gql`
  mutation($id: ID!, $patch: UpdateUser!) {
    updateUser(input: { id: $id, patch: $patch }) {
      id
      first_name
      last_name
      username
      signature_url
      company_id
      roles {
        id
        desc
        code
      }
    }
  }
`;

export const createActivityType = gql`
  mutation($desc: String, $code: String) {
    createActivityType(input: { desc: $desc, code: $code }) {
      id
      desc
      code
    }
  }
`;

export const updateActivityType = gql`
  mutation($id: ID!, $patch: UpdateActivityType!) {
    updateActivityType(input: { id: $id, patch: $patch }) {
      id
      desc
      code
    }
  }
`;

export const createActivity = gql`
  mutation(
    $activity_type_id: ID!
    $default_price: Float!
    $activity_translations: [CreateActivityTranslationInputOnActivity!]!
    $partner_client_activities: [ActivityPartnerClient]
  ) {
    createActivity(
      input: {
        default_price: $default_price
        activity_type_id: $activity_type_id
        activity_translations: $activity_translations
        partner_client_activities: $partner_client_activities
      }
    ) {
      id
      default_price
      activity_type_id
    }
  }
`;

export const updateActivity = gql`
  mutation($id: ID!, $patch: UpdateActivity!) {
    updateActivity(input: { id: $id, patch: $patch }) {
      id
      default_price
      activity_type_id
    }
  }
`;

export const createPartnerClient = gql`
  mutation(
    $name: String!
    $first_name: String
    $last_name: String
    $phones: [String]
    $fax: String
    $address: String
    $emails: [String]
    $url: String
    $services: [CreatePartnerClientServicePCInput]
    $comments: String
    $oib: String
    $partner_client_type_ids: [ID!]!
    $partner_client_activities: [PartnerClientActivity]
    $city_id: ID!
    $city_ids: [ID]
    $language_id: ID
    $languages: String
    $contacts: [CreateContactInput]
  ) {
    createPartnerClient(
      input: {
        name: $name
        first_name: $first_name
        last_name: $last_name
        contacts: $contacts
        phones: $phones
        fax: $fax
        address: $address
        emails: $emails
        url: $url
        services: $services
        comments: $comments
        oib: $oib
        partner_client_type_ids: $partner_client_type_ids
        partner_client_activities: $partner_client_activities
        city_ids: $city_ids
        city_id: $city_id
        language_id: $language_id
        languages: $languages
      }
    ) {
      id
    }
  }
`;

export const updatePartnerClient = gql`
  mutation(
    $id: ID!
    $name: String
    $first_name: String
    $last_name: String
    $phones: [String]
    $fax: String
    $address: String
    $emails: [String]
    $url: String
    $comments: String
    $oib: String
    $partner_client_type_ids: [ID!]!
    $partner_client_activities: [PartnerClientActivity]
    $city_id: ID!
    $city_ids: [ID]
    $language_id: ID
    $languages: String
  ) {
    updatePartnerClient(
      input: {
        id: $id
        patch: {
          name: $name
          first_name: $first_name
          last_name: $last_name
          phones: $phones
          fax: $fax
          address: $address
          emails: $emails
          url: $url
          comments: $comments
          oib: $oib
          city_ids: $city_ids
          city_id: $city_id
          language_id: $language_id
          partner_client_type_ids: $partner_client_type_ids
          partner_client_activities: $partner_client_activities
          languages: $languages
        }
      }
    ) {
      ...Partner
      partner_client_activities {
        id
        activity_id
        name
        price
      }
    }
  }
  ${partnerClientFragment}
`;

export const createContact = gql`
  mutation(
    $name: String
    $first_name: String
    $last_name: String
    $phone: String
    $email: String
    $partner_client_id: ID!
  ) {
    createContact(
      input: {
        name: $name
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
        partner_client_id: $partner_client_id
      }
    ) {
      id
      name
      first_name
      last_name
      phone
      email
      partner_client_id
    }
  }
`;

export const deleteContact = gql`
  mutation($id: ID!) {
    deleteContact(id: $id)
  }
`;

export const updateContact = gql`
  mutation($id: ID!, $name: String, $first_name: String, $last_name: String, $phone: String, $email: String) {
    updateContact(
      input: {
        id: $id
        patch: { name: $name, first_name: $first_name, last_name: $last_name, phone: $phone, email: $email }
      }
    ) {
      id
      name
      name
      first_name
      last_name
      phone
      email
    }
  }
`;

export const createPartnerClientService = gql`
  mutation(
    $price: Float!
    $partner_client_id: ID!
    $partner_client_service_type_id: ID!
    $partner_client_service_translations: [CreatePartnerClientServiceTranslationInput!]!
  ) {
    createPartnerClientService(
      input: {
        price: $price
        partner_client_id: $partner_client_id
        partner_client_service_type_id: $partner_client_service_type_id
        partner_client_service_translations: $partner_client_service_translations
      }
    ) {
      id
    }
  }
`;

export const deletePartnerClientService = gql`
  mutation($id: ID!) {
    deletePartnerClientService(id: $id)
  }
`;

export const updatePartnerClientService = gql`
  mutation(
    $id: ID!
    $price: Float
    $partner_client_service_type_id: ID
    $partner_client_service_translations: [UpdatePartnerClientServiceTranslation]
  ) {
    updatePartnerClientService(
      input: {
        id: $id
        patch: {
          price: $price
          partner_client_service_type_id: $partner_client_service_type_id
          partner_client_service_translations: $partner_client_service_translations
        }
      }
    ) {
      id
    }
  }
`;

export const updatePartnerClientType = gql`
  mutation($id: ID!, $desc: String) {
    updatePartnerClientType(input: { id: $id, patch: { desc: $desc } }) {
      id
      code
      desc
    }
  }
`;

export const createPartnerClientType = gql`
  mutation($desc: String) {
    createPartnerClientType(input: { desc: $desc }) {
      id
      code
      desc
    }
  }
`;

export const updateTemplateTranslation = gql`
  mutation($id: ID!, $patch: UpdateTemplateTranslation!) {
    updateTemplateTranslation(input: { id: $id, patch: $patch }) {
      id
      vat
      free_place
      price_validity
      surcharges
      payment
      storno
      program_changes
      content
      program
      room_list
      booking_state
      language_id
    }
  }
`;

export const deleteUserSignature = gql`
  mutation($user_id: ID) {
    deleteUserSignature(user_id: $user_id)
  }
`;

export const createTypologyGroup = gql`
  mutation($name: String!) {
    createTypologyGroup(input: { name: $name }) {
      id
      name
    }
  }
`;

export const updateTypologyGroup = gql`
  mutation($id: ID!, $name: String!) {
    updateTypologyGroup(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

export const deleteTypologyGroup = gql`
  mutation($id: ID!) {
    deleteTypologyGroup(id: $id)
  }
`;

// update announcement template
export const UPDATE_ANNOUNCEMENT_TEMPLATE = gql`
  mutation($input: AnnouncementLabelTranslationInput) {
    updateAnnouncementLabelTranslations(input: $input) {
      ...AnnouncementLabelTranslation
    }
  }

  ${templateAnnouncementFragment}
`;
