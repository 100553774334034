import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

/**
 * TableTitle
 */
export default function TableTitle({ title, onBackPressed }) {
  return (
    <Grid container alignItems="center" style={{ marginBottom: 24, minHeight: 50 }}>
      {onBackPressed && (
        <Grid item>
          <IconButton onClick={onBackPressed}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item>
        <Typography variant="title">{title}</Typography>
      </Grid>
    </Grid>
  );
}
