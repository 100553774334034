// libs
import { get } from "lodash";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawerPayload, setGuideDrawerPayload } from "src/views/Guides/guidesSlice";

/**
 * useCatalogContainer is entry point of data for catalogContainer
 */
export default function useCatalogContainer() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  /**
   * handle on catalog text change
   */
  const onCatalogTextChange = useCallback(
    event => {
      const catalog = get(event, "target.value");
      dispatch(setGuideDrawerPayload({ ...guidePayload, catalog: catalog }));
    },
    [dispatch, guidePayload],
  );

  return {
    catalog: get(guidePayload, "catalog", ""),
    onCatalogTextChange,
  };
}
