import gql from "graphql-tag";

export const signin = gql`
  query($username: String!, $password: String!) {
    signin(username: $username, password: $password) {
      id
      jwt
    }
  }
`;

export const listUser = gql`
  {
    listUser {
      id
      first_name
      last_name
      username
      signature_url
      roles {
        id
        desc
        code
      }
    }
  }
`;

export const getUser = gql`
  query($id: ID!) {
    getUser(id: $id) {
      id
      first_name
      last_name
      username
      signature_url
      role_id
    }
  }
`;
