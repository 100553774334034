import React, { Component } from "react";

import Ajv from "ajv";
import ajvWithErrors from "ajv-errors";
import ajvKeywords from "ajv-keywords";
import { isDecimal, isInteger } from "../../utility/numbers";

class FormValidation {
  constructor(schema) {
    this.ajv = new Ajv({
      allErrors: true,
      jsonPointers: true,
      coerceTypes: true,
      $data: true,
    });

    // Package that we can set error messages,
    // singleError is set to false so that object off errorMessages doesn't return empty message on fields which are not error (strange thing)
    ajvWithErrors(this.ajv, { singleError: false });

    ajvKeywords(this.ajv, "select");

    this.addFormat();
    this.addKeyword();

    this.validator = this.ajv.compile(schema);
  }

  addFormat() {
    // for email validation
    /*eslint-disable */
    const regex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    /*eslint-enable */

    this.ajv.addFormat("isEmail", regex);
    this.ajv.addFormat("numeric", num => !(!isDecimal(num) && !isInteger(num)));
  }

  addKeyword() {
    this.ajv.addKeyword("isNotEmpty", {
      type: "string",
      validate: function(schema, data) {
        return typeof data === "string" && data.trim() !== "";
      },
      errors: false,
    });
  }

  validate(state) {
    const valid = this.validator(state);

    if (valid) {
      return true;
    } else {
      return false;
    }
  }

  validatedErrorMessage(path) {
    const validated =
      this.validator.errors &&
      this.validator.errors.find(error => {
        return error.dataPath === path;
      });
    return validated ? validated.message : false;
  }

  validateError(path) {
    const validated =
      this.validator.errors &&
      this.validator.errors.find(error => {
        return error.dataPath === path;
      });

    return !!validated;
  }

  validateRequiredError(path) {
    path = path.replace("/", "");
    const validated =
      this.validator.errors &&
      this.validator.errors.filter(err => err.keyword === "required").find(error => {
        return error.params.missingProperty === path;
      });

    return !!validated;
  }
}

class FormValidator extends Component {
  constructor(props) {
    super(props);

    // Need to send schema through based on the Ajv: Another JSON Schema Validator
    this.validator = new FormValidation(this.props.schema);

    this.state = {
      validation: false,
    };

    this.submitted = false;
  }

  error = path => this.validator.validateError(path) || this.validator.validateRequiredError(path);

  errorMsg = path => this.validator.validatedErrorMessage(path);

  recreateSchema = () => (this.validator = new FormValidation(this.props.schema));

  checkValid = e => {
    e && e.preventDefault && e.preventDefault();

    const { data } = this.props;

    const valid = this.validator.validate(data);
    this.setState({ validation: valid });
    this.submitted = true;

    return valid;
  };

  submit = async e => {
    if (this.checkValid(e)) {
      await this.props.submit();
    }
  };

  submitAndRecreate = e => {
    if (this.checkValid(e)) {
      this.submitted = false;

      this.recreateSchema();
      this.props.submit();
    }
  };

  getStateAndHelpers() {
    return {
      validator: this.validator,
      error: this.error,
      errorMsg: this.errorMsg,
      submit: this.submit,
      submitAndRecreate: this.submitAndRecreate,
    };
  }

  render() {
    // if the form has been submitted at least once
    // then check validity every time we render
    if (this.submitted) {
      this.validator.validate(this.props.data);
    }

    const { children } = this.props;
    const ui = typeof children === "function" ? children(this.getStateAndHelpers()) : children;

    // Using React.cloneElement so that linter doesn't gives warning if we're using this.props.children()
    return React.cloneElement(ui);
  }
}

export default FormValidator;
