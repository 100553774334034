import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateMinPersons extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_npv =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("notification_price_validity");
      }).length;

    const prev_npv =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("notification_price_validity");
      }).length;

    const next_pv_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("price_validity_content");
      }).length;

    const prev_pv_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("price_validity_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.notification_price_validity !== this.props.notification_price_validity ||
      nextProps.price_validity_content !== this.props.price_validity_content ||
      next_pv_content !== prev_pv_content ||
      next_npv !== prev_npv
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`price_validity_content`).length) {
      autosize(document.getElementsByName(`price_validity_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Minimalni broj osoba</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/notification_price_validity")}>
            <InputLabel htmlFor="notification_price_validity">Minimalni broj osoba</InputLabel>
            <Input
              id="notification_price_validity"
              className="template-input"
              inputProps={{
                name: "notification_price_validity",
              }}
              value={this.props.notification_price_validity}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/notification_price_validity")}</FormHelperText>
          </FormControl>
          <div>
            <TextArea
              error={error("/offer_contract_document/price_validity_content")}
              inputProps={{
                name: "price_validity_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.price_validity_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/price_validity_content")}>
              {errorMsg("/offer_contract_document/price_validity_content")}
            </FormHelperText>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TemplateMinPersonsHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateMinPersons />
  </OfferForm.ConsumerHOC>
);

export default TemplateMinPersonsHOC;
