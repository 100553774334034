import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import BootstrapInput from "../../../../../components/UI/BootstrapLikeInput";
import { autosize } from "../../../../../utility/globals";

class PartnerClientServiceTranslationFormShow extends Component {
  componentDidMount = () => {
    // Resizing
    Object.keys(this.refs).forEach(key => {
      autosize(this.refs[key]);
    });
  };

  render() {
    const { listLanguage, service_translations } = this.props;

    const tableToShow =
      service_translations &&
      service_translations.length &&
      listLanguage &&
      listLanguage.map(language => {
        const service_translation = service_translations.find(translation => translation.language_id === language.id);

        if (!service_translation) {
          return null;
        }

        return (
          <TableRow key={language.id} className="align-top">
            <TableCell component="th" scope="row">
              {language.desc}
            </TableCell>
            <TableCell>
              <BootstrapInput label="" id="name" disabled value={service_translation.name || ""} />
            </TableCell>
            <TableCell padding="dense">
              <textarea
                ref={`service_translation${service_translation.id}`}
                className="preview-content-content preview-textarea-disabled"
                value={service_translation.description || ""}
                disabled
              />
            </TableCell>
          </TableRow>
        );
      });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jezik</TableCell>
            <TableCell>Naziv</TableCell>
            <TableCell>Opis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableToShow ? tableToShow : <React.Fragment />}</TableBody>
      </Table>
    );
  }
}

export default PartnerClientServiceTranslationFormShow;
