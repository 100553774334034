import React from "react";
import ReactToPrint from "react-to-print";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { blue500, grey900, fullWhite } from "material-ui/styles/colors";
import RaisedButton from "material-ui/RaisedButton";
import { Paper } from "@material-ui/core";

class AlotmanContractsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  printVouchers = () => {
    const { selectedVouchers, reservations } = this.props;
    const selectedReservations = reservations.filter(reservation => selectedVouchers[reservation.id]);

    localStorage.setItem("vouchers", JSON.stringify(selectedReservations));

    const windowHeight = window.innerHeight;

    window.open(
      `/voucher`,
      "_blank",
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=793.7007874,height=${windowHeight}`,
    );
  };

  render() {
    const { selectedVouchers } = this.props;
    const showPrintVoucher = Object.values(selectedVouchers).includes(true);

    return (
      <Toolbar>
        <ToolbarGroup>
          <ToolbarTitle
            text="Popis rezervacija"
            style={{
              color: grey900,
            }}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ReactToPrint
            pageStyle={`
              @media print{
                          @page {
                            size: landscape;
                          }
                          .ReactTable .-pagination{
                            height:50px
                          }
                        }`}
            trigger={() => <RaisedButton label="Print" backgroundColor={blue500} labelColor={fullWhite} />}
            content={() => this.props.contentToPrint()}
          />
          <RaisedButton
            label="Print voucher"
            backgroundColor={blue500}
            labelColor={fullWhite}
            disabled={!showPrintVoucher}
            onClick={this.printVouchers}
          />
          <Paper style={{ marginLeft: "5px", height: "36px" }}>
            <select
              style={{ height: "inherit" }}
              className="booking-select "
              onChange={this.props.changeStatus}
              value={this.props.checked}
            >
              <option value="">Sve</option>
              <option value={true}>Aktivno</option>
              <option value={false}>Arhivirano</option>
            </select>
          </Paper>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

export default AlotmanContractsToolbar;
