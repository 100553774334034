import React from "react";

// labels
import { LABEL_GUIDE_ANNOUNCEMENT_COMMENT, HINT_COMMENT } from "src/utility/labels";

// hooks
import useLayoutCommentsContainer from "./hooks/useLayoutCommentContainer";

// components
import TextFieldMultilineOutline from "src/components/UI/TextFieldMultilineOutline";

/**
 * LayoutCommentContainer contains all components inside LayoutCommentDrawer
 */
export default function LayoutCommentContainer() {
  const { guideLayoutComment, onGuideLayoutCommentChange } = useLayoutCommentsContainer();

  // return root
  return (
    <TextFieldMultilineOutline
      id="layoutCommentInput"
      fullWidth={true}
      rows={5}
      rowsMax={5}
      value={guideLayoutComment || ""}
      label={LABEL_GUIDE_ANNOUNCEMENT_COMMENT}
      placeholder={HINT_COMMENT}
      onChange={event => onGuideLayoutCommentChange(event)}
    />
  );
}
