import gql from "graphql-tag";

import {
  offerFragment,
  attachmentFragment,
  offerContractDocumentFragment,
  inquiryFragment,
  mainTerminFragment,
  terminsFragment,
  templateFragment,
  dailyTerminPrograms,
  contigentFragment,
  typologyFragment,
} from "../fragments";

export const createOffer = gql`
  mutation createOffer(
    $offer_status_id: ID!
    $storno_comment: String
    $off_key: String
    $offer_created_at: DateTime
    $offer_contract_document: CreateOfferContractDocumentInput!
  ) {
    createOffer(
      input: {
        offer_status_id: $offer_status_id
        storno_comment: $storno_comment
        off_key: $off_key
        offer_created_at: $offer_created_at
        offer_contract_document: $offer_contract_document
      }
    ) {
      ...OfferContent
      offer_contract_document {
        ...OfferContractDocument
        ...Inquiry
        attachments {
          ...Attachment
        }
        termin_periods {
          ...MainTermin
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
          programs {
            ...DailyProgram
          }
        }
        ...Template
      }
    }
  }
  ${offerFragment}
  ${offerContractDocumentFragment}
  ${inquiryFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${templateFragment}
  ${dailyTerminPrograms}
  ${contigentFragment}
  ${attachmentFragment}
`;

export const updateOffer = gql`
  mutation($id: ID!, $patch: UpdateOffer!) {
    updateOffer(input: { id: $id, patch: $patch }) {
      ...OfferContent
      offer_contract_document {
        ...OfferContractDocument
        attachments {
          ...Attachment
        }
        termin_periods {
          ...MainTermin
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
          programs {
            ...DailyProgram
          }
        }
        ...Template
      }
    }
  }
  ${offerFragment}
  ${offerContractDocumentFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${templateFragment}
  ${dailyTerminPrograms}
  ${contigentFragment}
  ${attachmentFragment}
`;

export const createProgram = gql`
  mutation($termin_period_id: ID, $offer_contract_document_id: ID, $program_days: [CreateProgramDayInput!]!) {
    createProgram(
      input: {
        termin_period_id: $termin_period_id
        program_days: $program_days
        offer_contract_document_id: $offer_contract_document_id
      }
    ) {
      id
      program_days {
        id
        title
        description
        program_id
        program_day_contents {
          id
          program_day_id
          activity_id
          partner_client_service_id
        }
      }
      termin_period_id
    }
  }
`;

export const documentAttachmentUpload = gql`
  mutation($offer_contract_document_id: ID!, $file: Upload!) {
    documentAttachmentUpload(offer_contract_document_id: $offer_contract_document_id, file: $file) {
      ...Attachment
    }
  }
  ${attachmentFragment}
`;

export const deleteDocumentFile = gql`
  mutation($file_id: ID!) {
    deleteDocumentFile(file_id: $file_id)
  }
`;

export const updateProgram = gql`
  mutation($id: ID!, $patch: UpdateProgram!) {
    updateProgram(input: { id: $id, patch: $patch }) {
      id
    }
  }
`;

export const createTermin = gql`
  mutation(
    $from: DateTime!
    $to: DateTime!
    $offer_contract_document_id: ID
    $price_structure: CreatePriceStructureInput!
    $contigents: [CreateContigentInput]
    $subtermin_periods: [CreateSubTerminPeriodInput]
  ) {
    createTerminPeriod(
      input: {
        from: $from
        to: $to
        offer_contract_document_id: $offer_contract_document_id
        price_structure: $price_structure
        contigents: $contigents
        subtermin_periods: $subtermin_periods
      }
    ) {
      ...MainTermin
      contigents {
        ...Contigent
      }
    }
  }
  ${mainTerminFragment}
  ${contigentFragment}
`;

export const createContigentOffer = gql`
  mutation($typologies_sum: Int!, $termin_period_id: ID, $typology_id: ID!) {
    createContigent(
      input: { typologies_sum: $typologies_sum, termin_period_id: $termin_period_id, typology_id: $typology_id }
    ) {
      id
      typologies_sum
      typology_id
      typology {
        ...Typology
      }
    }
  }
  ${typologyFragment}
`;

/**
 * create offer contract document service type, i.e. "osnovna usluga"
 */
export const createOfferContractDocumentServiceType = gql`
  mutation(
    $offer_contract_document_id: Int!
    $service_type_id: Int!
    $service_from: DateTime!
    $service_to: DateTime!
  ) {
    createOfferContractDocumentServiceType(
      input: {
        offer_contract_document_id: $offer_contract_document_id
        service_type_id: $service_type_id
        service_from: $service_from
        service_to: $service_to
      }
    ) {
      id
      offer_contract_document_id
      service_id
      service_to
      service_from
      service_type_id
    }
  }
`;

/**
 * update offer contract document service type, i.e. "osnovna usluga"
 */
export const updateOfferContractDocumentServiceType = gql`
  mutation(
    $id: ID!
    $offer_contract_document_id: Int!
    $service_type_id: Int!
    $service_from: DateTime!
    $service_to: DateTime!
  ) {
    updateOfferContractDocumentServiceType(
      input: {
        id: $id
        offer_contract_document_id: $offer_contract_document_id
        service_type_id: $service_type_id
        service_from: $service_from
        service_to: $service_to
      }
    ) {
      id
      offer_contract_document_id
      service_id
      service_to
      service_from
      service_type_id
    }
  }
`;

/**
 * delete offer contract document service type, i.e. "osnovna usluga"
 */
export const deleteOfferContractDocumentServiceType = gql`
  mutation($id: Int!) {
    deleteOfferContractDocumentServiceType(input: { id: $id })
  }
`;

export const changeOfferContractDocumentTypeMutation = gql`
  mutation($offer_contract_document_id: ID!, $offer_contract_document_type_id: ID!) {
    changeOfferContractDocumentType(
      offer_contract_document_id: $offer_contract_document_id
      offer_contract_document_type_id: $offer_contract_document_type_id
    )
  }
`;

export const updateOfferContractDocumentMutation = gql`
  mutation($input: UpdateOfferContractDocumentInput!) {
    updateOfferContractDocument(input: $input) {
      id
    }
  }
`;

/**
 * update offer archive status
 */
export const updateOfferArchiveStatus = gql`
  mutation($input: UpdateArchiveStatusInput!) {
    changeArchiveStatus(input: $input) {
      id
      archived_status
    }
  }
`;
