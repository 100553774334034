export const round = value => {
  return Number(Math.round(value + "e2") + "e-2");
};

export function guidIdGenerator() {
  let S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

export const numberFormat = number => (String(number).length < 2 ? `0${number}` : number);

export const isDecimal = num => /^[0-9]\d*(\.\d+)?$/.test(num);

export const isInteger = num => /^[0-9]\d*$/.test(num);

export const decimalNumbersTwoDigits = num => {
  if (num.length > 1) {
    switch (true) {
      // If typed is 01
      case num.length === 2 && (num[0] === "0" && !isNaN(num[1])):
        return num[1];
      // If user is typed , or .
      // case num.length === 2 && (num[1] === "." || num[1] === ","):
      //   return `${num[0]}.`;
      // use case: 125.5, || 125.5.
      case (num[num.length - 1] === "." || num[num.length - 1] === ",") && num.slice(0, -1).includes("."):
        return num.slice(0, -1);
      // 22,
      case num[num.length - 1] === ",":
        return num.toString().replace(",", ".");
      // 22.
      case num[num.length - 1] === ".":
        return num;
      // 12.5W
      case isNaN(num):
        return num.toString().replace(/[^\d.]/g, "");
      // 100.000
      case num.includes("."):
        const startIndex = num.toString().indexOf(".") + 1;
        const endIndex = num.length;

        const lastTwo = num.substring(startIndex, endIndex).length > 2;
        // 100.808 === true
        if (lastTwo) {
          return num.toString().slice(0, -1);
        }
        break;
      default:
        return num;
    }
  }
  if (isNaN(num)) {
    return "0";
  }
  return num.toString().replace(/[^\d.]/g, "");
};
