import React from "react";

// ui
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { grey900 } from "material-ui/styles/colors";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

// hooks
import useToolbarView from "./hooks/useToolbarView";

// constants
import { LABEL_RESET_FILTERS, TITLE_TOOLBAR_ANNOUNCEMENT_ORDERS } from "src/utility/labels";

/**
 * ToolbarView
 */
export default function AnnouncementOrdersToolbarView({ contentToPrint }) {
  const {
    // functions
    getIsResetEnabled,
    handleOnFilterResetClick,
  } = useToolbarView();

  // return root
  return (
    <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
      <ToolbarGroup>
        <ToolbarTitle
          text={TITLE_TOOLBAR_ANNOUNCEMENT_ORDERS}
          style={{
            color: grey900,
          }}
        />
      </ToolbarGroup>

      <ToolbarGroup>
        <Button
          id="announcements-delete-filters"
          disabled={!getIsResetEnabled()}
          color="primary"
          style={{ marginRight: 16 }}
          variant="outlined"
          size="small"
          onClick={handleOnFilterResetClick}
        >
          <ClearIcon size={16} />
          {LABEL_RESET_FILTERS}
        </Button>
      </ToolbarGroup>
    </Toolbar>
  );
}
