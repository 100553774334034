import React from "react";

export default [
  {
    Header: "ID",
    width: 55,
    accessor: "id",
  },
  {
    Header: "Tip",
    accessor: "partner_client_types",
    Cell: ({ row }) => {
      return row.partner_client_types.map((type, index) => {
        if (index === 0) {
          return type.desc;
        }
        return `, ${type.desc}`;
      });
    },
  },
  {
    Header: "Ime tvrtke",
    accessor: "name",
  },
  {
    Header: "Kontakt",
    id: "fullname",
    filterable: false,
    accessor: ({ contacts }) => (
      <div>
        {contacts.slice(0, 3).map((contact, index) => {
          if (!contact) {
            return "";
          }

          if (index + 1 === 3) {
            return <div key={contact.id}>. . .</div>;
          } else {
            return (
              <div key={contact.id}>
                {`
                    ${contact.first_name || ""}
                    ${contact.last_name || ""}
                    ${contact.phone || ""}
                    ${contact.email || ""}
                  `}
              </div>
            );
          }
        })}
      </div>
    ),
  },
  {
    Header: "OIB",
    accessor: "oib",
  },
  {
    Header: "Grad",
    accessor: "city",
  },
  {
    Header: "Država",
    accessor: "country",
  },
  {
    Header: "Web stranica",
    accessor: "url",
  },
  {
    Header: "Fax",
    accessor: "fax",
  },
  {
    Header: "Jezik",
    accessor: "language",
  },
  {
    Header: "Jezici",
    accessor: "languages",
  },
  {
    Header: "Usluge",
    accessor: "num_services",
    filterable: false,
  },
];
