// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_HOTEL_GUIDE_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateHotelGuideAnnouncement is used for createHotelGuideAnnouncement mutation
 */
export default function useCreateHotelGuideAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createHotelGuideAnnouncement] = useMutation(CREATE_HOTEL_GUIDE_ANNOUNCEMENT);

  const handleCreateHotelGuideAnnouncement = async (languageSelected, guideSelected) => {
    const createAnnouncementResponse = await createHotelGuideAnnouncement({
      variables: {
        input: {
          program_id: programId,
          establishment_id: supplier.establishment_id,
          guide_id: guideSelected,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createHotelGuideAnnouncement");
  };

  return {
    handleCreateHotelGuideAnnouncement,
  };
}
