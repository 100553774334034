import React, { Component } from "react";

import PartnerClientList from "./Partner/PartnerClientList";
import CreatePartnerClient from "./Partner/CreatePartnerClient";
import EditPartnerClient from "./Partner/EditPartnerClient";

import PartnerClientTypeList from "./PartnerType/PartnerClientTypeList";
import CreatePartnerClientType from "./PartnerType/CreatePartnerClientType";
import EditPartnerClientType from "./PartnerType/EditPartnerClientType";

const PartnerTabsHOC = WrappedComponent => {
  return class extends Component {
    state = {
      partnerCreate: false,
      partnerEdit: false,
      partner: "",
      partnerTypeCreate: false,
      partnerTypeEdit: false,
      partnerType: "",
    };

    partnerCreateToggle = () => {
      this.setState(prevState => {
        return {
          partnerCreate: !prevState.partnerCreate,
        };
      });
    };

    partnerEditOpen = partner => {
      this.setState({
        partner,
        partnerEdit: true,
      });
    };

    partnerEditClose = () => {
      this.setState({
        partner: "",
        partnerEdit: false,
      });
    };

    partnerTypeCreateToggle = () => {
      this.setState(prevState => {
        return {
          partnerTypeCreate: !prevState.partnerTypeCreate,
        };
      });
    };

    partnerTypeEditOpen = partnerType => {
      this.setState({
        partnerType,
        partnerTypeEdit: true,
      });
    };

    partnerTypeEditClose = () => {
      this.setState({
        partnerType: "",
        partnerTypeEdit: false,
      });
    };

    showInTabPartner = () => {
      const { partnerCreate, partnerEdit, partner } = this.state;

      if (partnerCreate) {
        return <CreatePartnerClient cancelToggle={this.partnerCreateToggle} />;
      }
      if (partnerEdit) {
        return <EditPartnerClient closeEdit={this.partnerEditClose} establishment_id={partner.id} partner={partner} />;
      }
      return (
        <PartnerClientList
          tableName="administrationPartnerClients"
          toggleCreate={this.partnerCreateToggle}
          openEdit={this.partnerEditOpen}
        />
      );
    };

    showInTabPartnerType = listPartnerClientType => {
      const { partnerTypeCreate, partnerTypeEdit, partnerType } = this.state;

      if (partnerTypeCreate) {
        return <CreatePartnerClientType cancelToggle={this.partnerTypeCreateToggle} />;
      }
      if (partnerTypeEdit) {
        return <EditPartnerClientType partnerClientType={partnerType} closeEdit={this.partnerTypeEditClose} />;
      }
      return (
        <PartnerClientTypeList
          tableName="administrationPartnerClientTypes"
          toggleCreate={this.partnerTypeCreateToggle}
          openEdit={this.partnerTypeEditOpen}
          listPartnerClientType={listPartnerClientType}
        />
      );
    };

    render() {
      return (
        <WrappedComponent showInTabPartner={this.showInTabPartner} showInTabPartnerType={this.showInTabPartnerType} />
      );
    }
  };
};

export default PartnerTabsHOC;
