import React, { Fragment, Component } from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";

import { listUser } from "../../../graphql/query/administration";
import { AdministrationTabs } from "../AdministrationTabs";
import ReactTableCustom from "../../../components/UI/ReactTable";
import EditIcon from "../../../components/UI/EditIcon";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class UserList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: user => (
          <Link className="ReactTableFirstColumnDiv" to={`/administracija/zaposlenik/edit/${user.id}`}>
            <EditIcon />
          </Link>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Ime i prezime",
        accessor: "fullname",
      },
      {
        Header: "Korisničko ime",
        accessor: "username",
      },
      {
        Header: "Potpis",
        accessor: "signature_url",
      },
      {
        Header: "Privilegije",
        accessor: "roles",
      },
    ],
  };

  render() {
    const {
      data: { loading, listUser },
    } = this.props;

    if (loading) {
      return <div> Loading . . . </div>;
    }

    const dataToShow =
      listUser &&
      listUser.map(user => {
        return {
          ...user,
          fullname: user.first_name + " " + user.last_name,
          roles: user.roles.map((role, index) => role.desc + (index === user.roles.length - 1 ? "" : ", ")),
        };
      });

    return (
      <Fragment>
        <TableTitle title="Lista zaposlenika" />
        <ReactTableCustom
          data={dataToShow || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
        <RaisedButton
          className="administration-btn-add-new"
          label="Dodaj novi"
          primary={true}
          onClick={() => this.props.history.push("/administracija/zaposlenik/create")}
        />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  AdministrationTabs(
    graphql(listUser, {
      options: {
        fetchPolicy: "network-only",
      },
    })(UserList),
    "Zaposlenici",
  ),
);
