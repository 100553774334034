import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_FOOTER } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },

  containerLine: {
    width: "100%",
    borderBottom: "1px solid #757575",
    marginLeft: 16,
    marginRight: 16,
  },

  text: {
    marginTop: 4,
    fontSize: FONT_SIZE_FOOTER,
    fontFamily: "OpenSans",
  },
});

// VoucherFooter
export default function VoucherFooter({ footerText }) {
  return (
    <View style={styles.container}>
      <View style={styles.containerLine} />
      <Text style={styles.text}>{footerText}</Text>
    </View>
  );
}
