import React from "react";

// ui
import { Button, Grid, TextField, Typography, withStyles } from "@material-ui/core";

// hooks
import useAnnouncementOrderContainer from "./hooks/useAnnouncementOrderContainer";

// utils
import { formatDate } from "src/utility/dates";

// components
import ShowField from "../ShowField";
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";

// style
const styles = () => ({
  container: {
    padding: 16,
  },

  containerLoading: {
    width: "100%",
    height: 300,
    padding: 48,
    textAlign: "center",
  },

  textField: {
    marginTop: 24,
  },
  button: {
    marginTop: 24,
  },
});

/**
 * AnnouncementOrderContainer contains all components inside AnnouncementOrderDrawer
 */
const AnnouncementOrderContainer = ({ classes }) => {
  const {
    externalId,
    themeName,
    terminFrom,
    terminTo,
    loading,
    invoiceText,
    setInvoiceText,
    roomText,
    setRoomText,
    gratisText,
    setGratisText,
    extraText,
    setExtraText,
    handleOnAnnouncementOrderCreateClick,
  } = useAnnouncementOrderContainer();

  // loading component
  const renderLoadingView = () => {
    return (
      <Grid className={classes.containerLoading} container spacing={8} alignItems="center" justify="center">
        <Grid item>
          <FixedCircularProgress color="inherit" size={16} thickness={6} />
        </Grid>

        <Grid item>
          <Typography variant="body1">Kreiranje naloga...</Typography>
        </Grid>
      </Grid>
    );
  };

  // return root
  return (
    <div className={classes.container}>
      {/** contract no */}
      <ShowField label={"Ugovor"} textValue={externalId} />
      {/** theme / group name */}
      <ShowField label={"Grupa"} textValue={themeName} />
      {/** termin */}
      <ShowField label={"Termin"} textValue={`${formatDate(terminFrom)} - ${formatDate(terminTo)}`} />

      {/** invoice */}
      <TextField
        id="invoice-text"
        className={classes.textField}
        disabled={loading}
        label="Tekst fakture"
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Molimo unesite tekst fakture"
        fullWidth
        margin="normal"
        value={invoiceText}
        onChange={event => setInvoiceText(event.target.value)}
      />

      {/** room text */}
      <TextField
        id="room-text"
        className={classes.textField}
        disabled={loading}
        label="Tekst soba"
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Molimo unesite tekst soba"
        fullWidth
        margin="normal"
        value={roomText}
        onChange={event => setRoomText(event.target.value)}
      />

      {/** gratis text */}
      <TextField
        id="gratis-text"
        className={classes.textField}
        disabled={loading}
        label="Tekst gratis"
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Molimo unesite gratis tekst"
        fullWidth
        margin="normal"
        value={gratisText}
        onChange={event => setGratisText(event.target.value)}
      />

      {/** extra text */}
      <TextField
        id="gratis-text"
        className={classes.textField}
        disabled={loading}
        label="Tekst gratis"
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Molimo unesite dodatan tekst"
        fullWidth
        margin="normal"
        value={extraText}
        onChange={event => setExtraText(event.target.value)}
      />

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleOnAnnouncementOrderCreateClick}
        disabled={loading}
      >
        Kreiraj nalog
      </Button>

      {/** loading view */}
      {loading && renderLoadingView()}
    </div>
  );
};

export default withStyles(styles)(AnnouncementOrderContainer);
