import React from "react";
import { withRouter } from "react-router-dom";

import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import AttachmentItem from "../../../components/UI/AttachmentItem";
import ConnectedSubjectItem from "../../../components/UI/ConnectedSubjectItem";

const styles = () => ({
  root: {
    width: "auto",
    tableLayout: "auto",
    marginTop: 8,
  },

  tableCell: {
    borderBottom: 0,
    marginTop: 4,
  },
});

class ConnectedContractsAndOffers extends React.Component {
  /**
   * render connected offers cells
   */
  renderOffersCells = (offers = []) => {
    const { classes, history } = this.props;

    return offers.map(offer => (
      <TableCell key={offer.id} className={classes.tableCell}>
        <ConnectedSubjectItem
          label={`Ponuda ${offer.external_id}`}
          onClick={() => history.push(`/ponude/edit/${offer.id}`)}
        />
      </TableCell>
    ));
  };

  /**
   * render connected contracts cells
   */
  renderContractsCells = (contracts = []) => {
    const { classes, history } = this.props;

    return contracts.map(contract => {
      if (contract) {
        return (
          <TableCell key={contract.id} className={classes.tableCell}>
            <ConnectedSubjectItem
              label={`Ugovor ${contract.external_id}`}
              onClick={() => history.push(`/ugovor/edit/${contract.id}`)}
            />
          </TableCell>
        );
      } else {
        return null;
      }
    });
  };

  /**
   * render connected attachments
   */
  renderAttachments = (offers = []) => {
    const { classes } = this.props;

    return offers.map(offer => {
      let attachments = _.get(offer, "offer_contract_document.attachments");

      if (attachments) {
        return (
          <TableCell key={offer.id} className={classes.tableCell} style={{ verticalAlign: "top" }}>
            {attachments.map(file => (
              <div key={file.id}>
                <AttachmentItem file={file} />
              </div>
            ))}
          </TableCell>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { classes, inquiry } = this.props;
    return (
      <React.Fragment>
        <Table className={classes.root}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} component="th" scope="row">
                <label className="form-label-right label-margin-block-start-8">Povezane ponude</label>
              </TableCell>
              {this.renderOffersCells(inquiry.offers)}
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell} component="th" scope="row">
                <label className="form-label-right label-margin-block-start-8">Povezani ugovori</label>
              </TableCell>
              {this.renderContractsCells(inquiry.contracts)}
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCell} component="th" scope="row" style={{ verticalAlign: "top" }}>
                <label className="form-label-right label-margin-block-start-8">Povezane datoteke</label>
              </TableCell>

              {this.renderAttachments(inquiry.offers)}
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ConnectedContractsAndOffers));
