import { FormControl, FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import gql from "graphql-tag";
import React, { Component } from "react";
import { graphql, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import toastr from "toastr";
import Add from "../../../../components/UI/AddIcon";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import Comment from "../../../../components/UI/CommentTextArea";
import FormValidator from "../../../../components/UI/FormValidator";
import { boolIsNaN } from "../../../../errorHandling";
import * as actions from "../../../../store/actions";
import { getTranslatedTypology, sortTypologies } from "../../../../utility/establishment";
import { autosize, config } from "../../../../utility/globals";
import OfferForm from "../OfferForm";
import ContigentsList from "./ContigentsList";

const createContigent = gql`
  mutation($typologies_sum: Int!, $termin_period_ids: [ID], $typology_id: ID!) {
    createContigentAndAppendToTerminPeriod(
      input: { typologies_sum: $typologies_sum, termin_period_ids: $termin_period_ids, typology_id: $typology_id }
    ) {
      id
      typologies_sum
      typology_id
    }
  }
`;

class EstablishmentContigent extends Component {
  static schema = {
    properties: {
      typologies_sum: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Broj tipologija mora biti upisan i mora biti broj",
      },
      typology_id: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Potrebno je odabrati broj tipologija",
      },
    },
  };

  addContigentHandler = async () => {
    try {
      // if we're in edit mode
      if (this.props.match.params.id) {
        const { typologies_sum, typology_id } = this.props;

        const { termin_periods } = this.props;

        const termin_array = termin_periods.map(({ id }) => id);

        const response = await this.props.mutate({
          variables: {
            typologies_sum,
            typology_id,
            termin_period_ids: termin_array,
          },
        });

        this.props.onAddContigent(response.data.createContigentAndAppendToTerminPeriod.id);

        toastr.success("Kontigent uspješno unešen");
      } else {
        this.props.onAddContigent();
      }
    } catch (error) {}
  };

  componentDidMount = () => {
    this.autoResize();
  };

  async componentDidUpdate(prevProps) {
    this.autoResize();

    let contigentsCurrent = _.get(this.props, "termin_periods[0].contigents", []);
    let contigentsPrew = _.get(prevProps, "termin_periods[0].contigents", []);

    // If we're in edit mode, update price structure with newest price_typology changes
    if (this.props.match.params.id && contigentsCurrent.lenght) {
      if (contigentsCurrent.length !== contigentsPrew.length) {
        // Send batched mutation
        let obj = "";

        this.props.termin_periods.forEach(({ price_structure }) => {
          obj += `
          ps${price_structure.id}:updatePriceStructure(input: { id: ${
            price_structure.id
          }, patch: {price_typology:${JSON.stringify(price_structure.price_typology)}} }) {
           id
           }
          `;
        });

        try {
          await this.props.client.mutate({
            mutation: gql`
             mutation {
               ${obj}
             }
           `,
          });

          toastr.success("Uspješno ažurirane cjenovne strukture");
        } catch (error) {
          toastr.warning("Greška prilikom ažuriranja cjenovne strukture");
        }
      }
    }
  }

  cdu;

  autoResize() {
    if (document.getElementsByName(`comment_contigent`).length) {
      autosize(document.getElementsByName(`comment_contigent`)[0]);
    }
  }

  render() {
    const { listEstablishment, offer_contract_document_type_id, isNotDraft } = this.props;

    const isAlotman = config.offer_type[offer_contract_document_type_id] === "Alotman";

    const typologiesToPick = getTranslatedTypology(sortTypologies(this.props.typologies), 1).map(typo => {
      return {
        value: typo.id,
        label: `${
          config.offer_type[offer_contract_document_type_id] !== "Alotman"
            ? typo.name
            : `${typo.name} ( ${typo.establishment_code} )`
        } [${typo.persons_capacity}]`,
      };
    });

    return (
      <React.Fragment>
        {/* Kontigent */}
        <FormValidator
          data={{
            typologies_sum: this.props.typologies_sum,
            typology_id: this.props.typology_id,
          }}
          schema={EstablishmentContigent.schema}
          submit={() => {
            if (!this.props.termin_periods.length) {
              return alert("Insert termin before adding contigent");
            }
            this.addContigentHandler();
          }}
        >
          {({ error, errorMsg, submitAndRecreate }) => (
            <div className={isAlotman ? "form-unit-double" : "form-unit-triple-icon"}>
              <div className="field-label is-normal">
                <label className="label">Kontigent</label>
              </div>

              <div className="cols-2">
                <FormControl error={error("/typologies_sum")}>
                  <TextField
                    name="typologies_no"
                    placeholder="Br. tipologija"
                    onChange={({ target: { value } }) =>
                      this.props.changeEditData({
                        key: "typologies_sum",
                        value,
                      })
                    }
                    value={this.props.typologies_sum}
                    fullWidth={true}
                    error={boolIsNaN(this.props.typologies_sum)}
                    disabled={isNotDraft}
                  />
                </FormControl>
                <AutocompleteSelect
                  error={error("/typology_id")}
                  errorMsg={errorMsg("/typology_id")}
                  placeholder="Odaberite tipologiju"
                  defaultValue={this.props.typology_id}
                  dataSource={typologiesToPick}
                  autocompleteHandler={selected =>
                    this.props.changeEditData({
                      key: "typology_id",
                      value: selected,
                    })
                  }
                  labelKey="desc" // To show text from object key
                  disabled={isNotDraft}
                />
              </div>
              {!isNotDraft && !isAlotman ? (
                <Add
                  param1={this.props.typology_id}
                  param2={this.props.typologies_sum}
                  onClickHandler={submitAndRecreate}
                />
              ) : null}
            </div>
          )}
        </FormValidator>

        {!isAlotman && (
          <div className="form-unit-double">
            <div />
            <OfferForm.Consumer>
              {({ error, errorMsg }) => (
                <div>
                  {error("/termin_periods/0/contigents") && (
                    <FormHelperText error={error("/termin_periods/0/contigents")}>
                      {errorMsg("/termin_periods/0/contigents")}
                    </FormHelperText>
                  )}
                  <div className={error("/termin_periods/0/contigents") ? "textarea-error" : ""}>
                    <ContigentsList
                      language_id={this.props.language_id}
                      termin_periods={this.props.termin_periods}
                      listEstablishment={listEstablishment}
                      removeContigent={this.props.removeContigent}
                      isNotDraft={isNotDraft}
                    />
                  </div>
                </div>
              )}
            </OfferForm.Consumer>
          </div>
        )}

        {/* Komentar kontigenta */}
        <div className="form-unit-double">
          <div />
          <Comment
            name="comment_contigent"
            defaultValue={this.props.comment_contigent}
            label="Komentar na kontigent"
            inputProps={{ disabled: isNotDraft }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    typologies_sum,
    typology_id,
    typologies,
    offer: {
      offer_contract_document: { termin_periods, language_id, comment_contigent, offer_contract_document_type_id },
    },
  } = state.offer;
  return {
    typologies_sum,
    language_id,
    typology_id,
    typologies,
    termin_periods,
    comment_contigent,
    offer_contract_document_type_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    onAddContigent: (id, dontResetFields) => dispatch(actions.addContigent(id, dontResetFields)),
  };
};

export default graphql(createContigent)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(withApollo(EstablishmentContigent))),
);
