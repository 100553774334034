import React, { Component } from "react";

import "./preview.css";

import PreviewHeader from "./PreviewHeader";
import PreviewHeaderDBData from "./PreviewVoucherHeaderDBData";
import PreviewTitle from "./PreviewTitle";
import PreviewContent from "./PreviewVoucherContent/PreviewContent";

import { Grid } from "@material-ui/core";

class Preview extends Component {
  state = {
    vouchers: null,
  };

  componentDidMount() {
    this.setState({
      vouchers: JSON.parse(localStorage.getItem("vouchers")),
    });

    // Change scroll area based on window resize
    window.addEventListener("resize", () => {
      document.getElementById("root").style.cssText = "height:" + window.innerHeight + "px";
    });
  }

  componentDidUpdate = () => {
    // Setting height for scroll to work
    document.getElementById("root").style.cssText = "height:" + window.innerHeight + "px";
  };

  render() {
    if (!this.state.vouchers) {
      return null;
    }

    return (
      <div className="voucher-container">
        {this.state.vouchers.map(voucher => {
          // Sending props into PreviewWithProps childrens
          const ui = React.Children.map(this.props.children, child => React.cloneElement(child, { voucher }));

          return (
            //<div className="preview-container">
            <div key={voucher.id} className="preview-voucher-page">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="preview preview-voucher">{ui}</div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div>
                        <div className="fake-footer" />
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="preview-footer">
                <div className="preview-footer-text">
                  <div>
                    Trgovački sud u Rijeci, MBS 040143504, temeljni kapitali 9.966.000,00 Kn, član uprave Vibor Olivari
                  </div>
                  <div>Vibor Olivari, voditelj poslovanja/direktor</div>
                </div>
                <Grid container>
                  <Grid item xs={3}>
                    <div className="preview-footer-header-text">RDA Member Nr. A1 822</div>
                    <img src="/images/rda.jpg" alt="RDA" />
                  </Grid>
                  <Grid item xs={3}>
                    <div className="preview-footer-header-text">GPN Member for Croatia</div>
                    <img src="/images/gpn.png" alt="gpn" />
                  </Grid>
                  <Grid item xs={3}>
                    <div className="preview-footer-header-text">Member of UHPA</div>
                    <img src="/images/upha.png" alt="upha" />
                  </Grid>
                  <Grid item xs={3}>
                    <div className="preview-footer-header-text">ISO 9001:2015</div>
                    <img src="/images/r.jpg" alt="r" />
                  </Grid>
                </Grid>
              </div>
              <hr />
            </div>
            //</div>
          );
        })}
      </div>
    );
  }
}

function PreviewUsage() {
  return (
    <Preview>
      <PreviewHeader />
      <PreviewHeaderDBData />
      <PreviewTitle />
      <PreviewContent />
    </Preview>
  );
}

export default PreviewUsage;
