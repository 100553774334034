import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";

import { connect } from "react-redux";

import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";

class AgencyAndPrograms extends Component {
  render() {
    const { partner_client, referents, document_code, code1, off_key, inquiry_external_id } = this.props;

    return (
      <Fragment>
        {/* Agencije / klijenti */}
        <Grid container spacing={24}>
          <Grid item xs={4}>
            <label className="label pl-16px">Agencije / Klijenti</label>
          </Grid>
          <Grid item xs={8}>
            <AutocompleteSelect
              defaultValue={partner_client.id}
              placeholder="Odaberite klijenta"
              dataSource={[
                {
                  ...partner_client,
                  value: partner_client.id,
                  label: partner_client.name,
                },
              ]}
              disabled
            />
          </Grid>
          {referents && referents.length ? (
            <React.Fragment>
              <Grid item xs={4} />
              <Grid item xs={8}>
                <Grid container spacing={0}>
                  <Grid item xs={12} className="bgcolor-contact-header cst-list-box-shadow">
                    <span className="cursor-default label">Popis kontakata</span>
                  </Grid>
                  {referents.map(entry => {
                    const contact = partner_client.contacts.find(cont => cont.id === entry.id);
                    if (!contact) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        xs={12}
                        key={contact.id}
                        className="cst-flex-between cursor-default cst-list-box-shadow"
                      >
                        <div className="ver-center">
                          {contact.first_name + " " + contact.last_name + " " + contact.phone + " " + contact.email}
                        </div>
                        <div className="ver-center">
                          <IconButton aria-label="Delete" href="" disabled>
                            <ClearIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={4}>
            <label className="label pl-16px">Upit</label>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="inquiry_external_id"
                placeholder="Broj upita"
                defaultValue={inquiry_external_id || ""}
                disabled
                fullWidth={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField id="code1" placeholder="Šifra" fullWidth={true} defaultValue={code1 || ""} disabled />
            </FormControl>
          </Grid>
          {/* Grupa */}
          <Grid item xs={4}>
            <label className="label pl-16px">Grupa</label>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField id="off_key" placeholder="Falš" defaultValue={off_key || ""} fullWidth={true} disabled />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="document_code"
                placeholder="Ime programa"
                defaultValue={document_code || ""}
                fullWidth={true}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      offer_contract_document: {
        document_code,
        code1,
        partner_client,
        partner_client: { name },
        referents,
        inquiry_id,
        inquiry_external_id,
        offer: { off_key },
      },
    },
  } = state.offer;
  return {
    document_code,
    code1,
    name,
    partner_client,
    referents,
    inquiry_id,
    inquiry_external_id,
    off_key,
  };
};

export default connect(mapStateToProps)(withRouter(AgencyAndPrograms));
