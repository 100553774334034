import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import CommentTextArea from "../../../../components/UI/CommentTextArea";
import { formatDate } from "../../../../utility/dates";
import { autosize } from "../../../../utility/globals";
import CalculatorModal from "../Calculator/CalculatorModal";

class PriceStructureItem extends Component {
  state = {
    editPriceStructure: false,
    terminId: null,
  };

  price_typology_input = {};
  tourist_tax_ref = React.createRef();

  makePriceStructureCopy = price_structure => terminId => () =>
    this.setState({
      editPriceStructure: price_structure,
      terminId,
    });

  componentDidUpdate() {
    this.autoResize();
  }

  componentDidMount = () => {
    this.autoResize();
  };

  autoResize() {
    const { termin } = this.props;

    if (document.getElementsByName(`comment_price_structure_${termin.id}`).length) {
      autosize(document.getElementsByName(`comment_price_structure_${termin.id}`)[0]);
    }
  }

  render() {
    const { termin, document_code, isNotDraft, noDuplicatesTypologies, price_typology } = this.props;

    const priceStructureIsNotEdited =
      termin.id === this.state.terminId
        ? JSON.stringify(termin.price_structure) === JSON.stringify(this.state.editPriceStructure)
        : null;

    return (
      <Card className="price-structure" key={termin.id}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="headline" component="h4">
                {`${formatDate(termin.from)} - ${formatDate(termin.to)}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CalculatorModal termin={termin} document_code={document_code} isNotDraft={isNotDraft} />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteSelect
                label="Tip cjenovne strukture"
                inputProps={{ disabled: isNotDraft }}
                defaultValue={termin.price_structure ? termin.price_structure.price_structure_type_id : ""}
                placeholder="Tip cj. strukture"
                dataSource={[
                  {
                    label: termin.price_structure.price_structure_type.desc,
                    value: termin.price_structure.price_structure_type_id,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              {noDuplicatesTypologies.map(typology => (
                <TextField
                  disabled={isNotDraft}
                  key={typology.id}
                  label={`Cijena za: ${typology.code}`}
                  inputRef={el => (this.price_typology_input[typology.id] = el)}
                  defaultValue={price_typology && price_typology[typology.id]}
                  fullWidth={true}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  disabled={isNotDraft}
                  label="Boravišna pristrojba"
                  inputRef={this.tourist_tax_ref}
                  defaultValue={termin.price_structure ? termin.price_structure.tourist_tax : ""}
                  fullWidth={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CommentTextArea
                inputProps={{ disabled: isNotDraft }}
                name={`comment_price_structure_${termin.id}`}
                label="Komentar"
                defaultValue={
                  termin && termin.price_structure && termin.price_structure.comment_price_structure
                    ? termin.price_structure.comment_price_structure
                    : ""
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        {!isNotDraft ? (
          <CardActions disableActionSpacing className="subtermin-create-buttons">
            {this.props.match.params.id && !this.state.editPriceStructure ? (
              <Button variant="outlined" onClick={this.makePriceStructureCopy(termin.price_structure)(termin.id)}>
                Izmijeni
              </Button>
            ) : null}
            {this.props.match.params.id &&
            termin.price_structure &&
            this.state.editPriceStructure.id === termin.price_structure.id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={priceStructureIsNotEdited}
                onClick={() => (priceStructureIsNotEdited ? null : this.saveEdited())}
              >
                Spremi
              </Button>
            ) : null}
            {this.props.match.params.id &&
            termin.price_structure &&
            this.state.editPriceStructure.id === termin.price_structure.id ? (
              <Button variant="outlined" onClick={this.resetMadeChanges}>
                Odustani
              </Button>
            ) : null}
          </CardActions>
        ) : null}
      </Card>
    );
  }
}

export default withRouter(PriceStructureItem);
