import React from "react";

// libs
import { StyleSheet, Text, View, Canvas } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_DOUBLE_TOP, FONT_SIZE_VALUE } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_DOUBLE_TOP,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  subContainer: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  text: {
    fontSize: FONT_SIZE_VALUE,
    fontFamily: "OpenSans",
  },

  canvas: {
    width: 200,
    height: 50,
    borderBottom: "2px solid #757575",
  },
});

// VoucherSignature
export default function VoucherGuideSignature({ signatureText }) {
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={styles.text}>{signatureText}</Text>
        <Canvas style={styles.canvas} />
      </View>
    </View>
  );
}
