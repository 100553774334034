import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";
import debounce from "lodash/debounce";

import RaisedButton from "material-ui/RaisedButton";

import { listEstablishment } from "../../../../graphql/query/administration";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import EditIcon from "../../../../components/UI/EditIcon";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class EstablishmentList extends Component {
  state = {
    listEstablishment: [],
    pages: null,
    loading: true,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: establishment => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(establishment)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Ime",
        accessor: "name",
      },
      {
        Header: "Web adresa",
        accessor: "web",
      },
      {
        Header: "Adresa 1",
        accessor: "address1",
      },
      {
        Header: "Regija - interno",
        accessor: "address2",
      },
      {
        Header: "Grad",
        accessor: "city",
      },
      {
        Header: "Regija",
        accessor: "region",
      },
      {
        Header: "Država",
        accessor: "country",
      },
      {
        Header: "Kontakt osoba 1",
        accessor: "contact_person1",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telefon",
        accessor: "phone",
      },
      {
        Header: "Tipologije",
        id: "typologies",
        accessor: d => {
          return d.typologies.map((ty, index) => {
            if (index === 0) {
              return ty.establishment_code;
            } else if (ty.establishment_code) {
              return `, ${ty.establishment_code}`;
            } else {
              return "";
            }
          });
        },
      },
      {
        Header: "Zvjezdice",
        accessor: "rating",
      },
    ],
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareFilters(filtered) {
    let filtersToSend = {};

    filtered.forEach(obj => {
      switch (obj.id) {
        case "city":
          filtersToSend = {
            ...filtersToSend,
            city: {
              name: obj.value,
            },
          };
          break;
        case "region":
          filtersToSend = {
            ...filtersToSend,
            region: {
              name: obj.value,
            },
          };
          break;
        case "country":
          filtersToSend = {
            ...filtersToSend,
            country: {
              name: obj.value,
            },
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  }

  fetchData = async state => {
    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      let filtersToSend;
      let whereCondition;

      if (state.filtered.length) {
        filtersToSend = this.prepareFilters(state.filtered);
      }

      if (filtersToSend) {
        whereCondition = {
          where: JSON.stringify(filtersToSend),
        };
      }

      const response = await this.props.client.query({
        query: listEstablishment,
        variables: {
          input: {
            paginationLimit: {
              limit: pageSizeSelected,
              offset: pageSelected * pageSizeSelected,
            },
            ...whereCondition,
          },
        },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        this.setStateAfterFetch(response, state);
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, 500);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response, state) {
    if (response.data.listEstablishment && response.data.listEstablishment[0]) {
      // when we get normal reponse, ceil up page number
      this.setState({
        listEstablishment: response.data.listEstablishment,
        pages: Math.ceil(response.data.listEstablishment[0].count / state.pageSize),
        loading: false,
      });
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        listEstablishment: [],
        pages: null,
        loading: false,
      });
    }
  }

  dataToShow() {
    const { listEstablishment } = this.state;

    return listEstablishment && listEstablishment.length
      ? listEstablishment.map(est => {
          const { city } = est;

          return {
            ...est,
            city: (city && city.name) || "",
            region: (city && city.region && city.region.name) || "",
            country: (city && city.region && city.region.country && city.region.country.name) || "",
          };
        })
      : [];
  }

  render() {
    const { toggleCreate } = this.props;

    return (
      <Fragment>
        <TableTitle title="Lista smještaja" />

        <ReactTableCustom
          data={this.dataToShow()}
          columns={this.state.columns}
          ref={el => (this.componentRefInquiry = el)}
          renderCustomPagination={true}
          innerProps={{
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: this.state.loading,
            pages: this.state.pages,
            manual: true,
            sortable: false,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(withApollo(EstablishmentList));
