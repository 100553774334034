import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";
import debounce from "lodash/debounce";

import RaisedButton from "material-ui/RaisedButton";

import { listPartnerClientPagination } from "../../../../graphql/query/administration";

import EditIcon from "../../../../components/UI/EditIcon";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class PartnerClientList extends Component {
  state = {
    filterBy: "",
    listPartnerClient: [],
    loading: true,
    pages: null,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 60,
        filterable: false,
        accessor: pc => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(pc)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Tip",
        accessor: "partner_client_types",
        Cell: ({ row }) => {
          return row.partner_client_types.map((type, index) => {
            if (index === 0) {
              return type.desc;
            }
            return `, ${type.desc}`;
          });
        },
      },
      {
        Header: "Ime tvrtke",
        accessor: "name",
      },
      {
        Header: "Kontakt",
        id: "fullname",
        filterable: false,
        accessor: ({ contacts }) => (
          <div>
            {contacts.slice(0, 3).map((contact, index) => {
              if (!contact) {
                return "";
              }

              if (index + 1 === 3) {
                return <div key={contact.id}>. . .</div>;
              } else {
                return (
                  <div key={contact.id}>
                    {`
                        ${contact.first_name || ""}
                        ${contact.last_name || ""}
                        ${contact.phone || ""}
                        ${contact.email || ""}
                      `}
                  </div>
                );
              }
            })}
          </div>
        ),
      },
      {
        Header: "OIB",
        accessor: "oib",
      },
      {
        Header: "Grad",
        accessor: "city",
      },
      {
        Header: "Država",
        accessor: "country",
      },
      {
        Header: "Web stranica",
        accessor: "url",
      },
      {
        Header: "Fax",
        accessor: "fax",
      },
      {
        Header: "Jezik",
        accessor: "language",
      },
      {
        Header: "Jezici",
        accessor: "languages",
      },
      {
        Header: "Usluge",
        accessor: "num_services",
        filterable: false,
      },
    ],
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareFilters(filtered) {
    let filtersToSend = {};

    filtered.forEach(obj => {
      switch (obj.id) {
        case "city":
          filtersToSend = {
            ...filtersToSend,
            city: {
              name: obj.value,
            },
          };
          break;
        case "country":
          filtersToSend = {
            ...filtersToSend,
            country: {
              name: obj.value,
            },
          };
          break;
        case "language":
          filtersToSend = {
            ...filtersToSend,
            language: {
              desc: obj.value,
            },
          };
          break;
        case "languages":
          filtersToSend = {
            ...filtersToSend,
            languages: obj.value,
          };
          break;
        case "partner_client_types":
          filtersToSend = {
            ...filtersToSend,
            partner_client_type: {
              desc: obj.value,
            },
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  }

  fetchData = async state => {
    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      let filtersToSend;
      let whereCondition;

      if (this.props.tableState.filtersSelected.length) {
        filtersToSend = this.prepareFilters(this.props.tableState.filtersSelected);
      }

      if (filtersToSend) {
        whereCondition = {
          where: JSON.stringify(filtersToSend),
        };
      }

      const response = await this.props.client.query({
        query: listPartnerClientPagination,
        variables: {
          input: {
            paginationLimit: {
              limit: pageSizeSelected,
              offset: pageSelected * pageSizeSelected,
            },
            ...whereCondition,
          },
        },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        this.setStateAfterFetch(response, state);
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, 500);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });

      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response, state) {
    if (response.data.listPartnerClient && response.data.listPartnerClient[0]) {
      // when we get normal reponse, ceil up page number
      this.setState({
        listPartnerClient: response.data.listPartnerClient,
        pages: Math.ceil(response.data.listPartnerClient[0].count / state.pageSize),
        loading: false,
      });
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        listPartnerClient: [],
        pages: null,
        loading: false,
      });
    }
  }

  dataToShow = () => {
    const { listPartnerClient } = this.state;

    return listPartnerClient
      ? listPartnerClient.map(pc => {
          return {
            ...pc,
            language: pc.language ? pc.language.desc : "",
            languages: pc.languages ? pc.languages : "",
            city: `${pc && pc.city && pc.city.name}, ${pc && pc.city && pc.city.region && pc.city.region.name}`,
            country: `${pc && pc.city && pc.city.region && pc.city.region.country && pc.city.region.country.name}`,
            num_services: pc.services.reduce((total, key) => total + 1, 0),
          };
        })
      : [];
  };

  render() {
    const { toggleCreate } = this.props;

    return (
      <Fragment>
        <TableTitle title="Lista partnera" />

        <ReactTableCustom
          data={this.dataToShow()}
          columns={this.state.columns}
          ref={el => (this.componentRefInquiry = el)}
          renderCustomPagination={true}
          innerProps={{
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: this.state.loading,
            pages: this.state.pages,
            manual: true,
            sortable: false,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(withApollo(PartnerClientList));
