import React, { Component } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { withApollo } from "react-apollo";

import Paper from "material-ui/Paper";

import { inquiryList } from "../../../graphql/query/inquiry";

import EditIcon from "../../../components/UI/EditIcon";
import ReactTableCustom from "../../../components/UI/ReactTable";

import { formatDate } from "../../../utility/dates";
import { DEBOUNCE_FETCH_DELAY } from "../../../utility/reactTable";
import { getNestedObject } from "../../../utility/globals";

import AllInquiriesToolbar from "./Toolbar";

import CustomDateRangePicker, { DEFAULT_EMPTY_DATE } from "../../../components/UI/CustomDateRangePicker";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

class AllInquiries extends Component {
  state = {
    pageSelected: this.props.tableState.pageSelected,
    listInquiry: [],
    pages: null,
    loading: true,
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        Cell: inquiry => {
          return (
            <Link className="ReactTableFirstColumnDiv" to={`/upit/edit/${inquiry.original.id}`}>
              <EditIcon style={{ cursor: "pointer" }} />
            </Link>
          );
        },
      },
      {
        Header: "Upit",
        width: 90,
        accessor: "external_id",
      },
      {
        Header: "Klijent",
        accessor: "partner_client",
      },
      {
        Header: "Naslov",
        accessor: "title",
      },
      {
        Header: "Kreirano",
        accessor: "created_at",
        width: 203,
        Filter: ({ filter, onChange }) => <CustomDateRangePicker filter={filter} onChange={onChange} />,
      },
      {
        Header: "Zadnje ažurirao",
        accessor: "user",
      },
    ],
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareFilters(filtered) {
    let filtersToSend = {};

    filtered.forEach(obj => {
      switch (obj.id) {
        case "partner_client":
          filtersToSend = {
            ...filtersToSend,
            partner_client: {
              name: obj.value,
            },
          };
          break;
        case "user":
          filtersToSend = {
            ...filtersToSend,
            last_edit_by_user: {
              username: obj.value,
            },
          };
          break;
        case "created_at":
          if (obj.value.includes(DEFAULT_EMPTY_DATE)) {
            break;
          }
        // falls through (ESlint actually needs this comment too)
        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  }

  fetchData = async state => {
    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    const pageSelected = this.firstTimeFetch ? this.props.tableState.pageSelected : state.page;
    const pageSizeSelected = this.firstTimeFetch ? this.props.tableState.pageSizeSelected : state.pageSize;

    if (this.firstTimeFetch) {
      this.firstTimeFetch = false;
    }

    try {
      this.setState({ loading: true });

      let filtersToSend;
      let whereCondition;

      if (state.filtered.length) {
        filtersToSend = this.prepareFilters(state.filtered);
      }

      if (filtersToSend) {
        whereCondition = {
          where: JSON.stringify(filtersToSend),
        };
      }

      const response = await this.props.client.query({
        query: inquiryList,
        variables: {
          input: {
            paginationLimit: {
              limit: pageSizeSelected,
              offset: pageSelected * pageSizeSelected,
            },
            ...whereCondition,
          },
        },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        this.setStateAfterFetch(response, state);
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  filtering = false;
  firstTimeFetch = true;

  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      // save filters
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  setStateAfterFetch(response, state) {
    if (response.data.listInquiry && response.data.listInquiry[0]) {
      // when we get normal reponse, ceil up page number
      this.setState({
        listInquiry: response.data.listInquiry,
        pages: Math.ceil(response.data.listInquiry[0].count / state.pageSize),
        loading: false,
      });
    }
    // when query returns empty array (no results for those filters)
    else {
      this.setState({
        listInquiry: [],
        pages: null,
        loading: false,
      });
    }
  }

  updateCheck = key => {
    this.setState(oldState => {
      return {
        ...oldState,
        filterOptions: {
          ...oldState.filterOptions,
          [key]: !oldState.filterOptions[key],
        },
      };
    });
  };

  dataToShow() {
    const { listInquiry } = this.state;

    return listInquiry && listInquiry.length
      ? listInquiry.map(inquiry => {
          const { name } = inquiry.partner_client;

          return {
            ...inquiry,
            partner_client: `${name} `,
            created_at: formatDate(inquiry.created_at),
            user:
              getNestedObject(inquiry, ["last_edit_by_user", "username"]) +
              ", " +
              formatDate(getNestedObject(inquiry, ["last_edit_by_user_time"])),
          };
        })
      : [];
  }

  changePage = () => {};

  render() {
    return (
      <React.Fragment>
        <AllInquiriesToolbar
          {...this.state}
          updateCheck={this.updateCheck}
          contentToPrint={() => this.componentRefInquiry}
        />
        <Paper zDepth={1}>
          <ReactTableCustom
            data={this.dataToShow()}
            columns={this.state.columns}
            ref={el => (this.componentRefInquiry = el)}
            renderCustomPagination={true}
            innerProps={{
              onFetchData: this.fetchStrategy,
              onFilteredChange: this.onFilteredChange,
              onPageChange: page => {
                this.props.setTableState({ ...this.props.tableState, pageSelected: page });
              },

              onPageSizeChange: (pageSize, pageIndex) => {
                this.props.setTableState({
                  ...this.props.tableState,
                  pageSelected: pageIndex,
                  pageSizeSelected: pageSize,
                });
              },

              page: this.props.tableState.pageSelected,
              pageSize: this.props.tableState.pageSizeSelected,
              defaultFiltered: this.props.tableState.filtersSelected,
              loading: this.state.loading,
              pages: this.state.pages,
              manual: true,
              sortable: false,
            }}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

export default withApollo(withLocalStorageHOC(AllInquiries));
