import React, { Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import TemplateForm from "./TemplateForm";

import { getTemplateTranslation } from "../../../graphql/query/administration";
import { updateTemplateTranslation } from "../../../graphql/mutation/administration";
import { prepareForSending } from "../../../utility/prepare";
import { AdministrationTabs } from "../AdministrationTabs";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class EditTemplate extends React.Component {
  state = {};

  async componentDidMount() {
    const response = await this.props.client.query({
      query: getTemplateTranslation,
      variables: { id: this.props.match.params.id },
    });

    if (response.data.getTemplateTranslation) {
      this.setState({
        translations: response.data.getTemplateTranslation,
      });
    }
  }

  changeTemplateField = name => ({ target: { value } }) => {
    this.setState(prev => ({
      translations: {
        ...prev.translations,
        [name]: value.trim(),
      },
    }));
  };

  submit = async () => {
    if (!this.state.translations) {
      return;
    }
    try {
      await this.props.mutate({
        variables: {
          id: this.props.match.params.id,
          patch: {
            ...prepareForSending(this.state.translations, ["__typename", "id"]),
          },
        },
      });
      toastr.success("Predložci uspješno ažurirani");
      this.props.history.push("/administracija/predlozak/");
    } catch (error) {}
  };

  render() {
    if (!this.state.translations) {
      return <div>Dohvaćam zaposlenika . . . </div>;
    }
    return (
      <Fragment>
        <TableTitle
          title="Ažuriranje predloška"
          onBackPressed={() => {
            this.props.history.push("/administracija/predlozak/");
          }}
        />
        <TemplateForm
          template={this.state.translations}
          onSubmit={this.submit}
          changeTemplateField={this.changeTemplateField}
        />
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(graphql(updateTemplateTranslation))(withApollo(EditTemplate)),
  "Ažuriranje predloška",
);
