import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_LABEL, LABEL_MIN_WIDTH, FONT_SIZE_VALUE, MARGIN_CONTAINER_DOUBLE_TOP } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_DOUBLE_TOP,
    display: "flex",
    flexDirection: "row",
  },

  label: {
    textTransform: "uppercase",
    fontSize: FONT_SIZE_LABEL,
    fontFamily: "OpenSansBold",
    minWidth: LABEL_MIN_WIDTH,
  },

  value: {
    fontSize: FONT_SIZE_VALUE,
    fontFamily: "OpenSans",
  },
});

// VoucherTextField
export default function VoucherTextField({ label, value }) {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{`${label}:`}</Text>
      <Text style={styles.value}>{`${value}`}</Text>
    </View>
  );
}
