import React from "react";
import { Switch, Route } from "react-router-dom";

import AllBookings from "./AllBookings/AllBookings";

const BookingsRouter = () => {
  return (
    <Switch>
      <Route path="/" render={props => <AllBookings {...props} tableName={"bookingsTable"} />} />
    </Switch>
  );
};

export default BookingsRouter;
