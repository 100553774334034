import { useState, useEffect } from "react";

// libs
import { debounce, get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// redux state
import {
  selectGuideScheduleGridFilters,
  selectGuideScheduleRowExpanded,
  selectGuideSchedules,
  selectGuideScheduleServiceTypes,
  setGuideDrawerPayload,
  setGuideDrawers,
  setGuideScheduleConstants,
  setGuideScheduleGridFilters,
  setGuideScheduleRowExpanded,
  setGuideSchedules,
  setGuideScheduleServiceTypes,
} from "../../../../guidesSlice";

// constants
import {
  BACKGROUND_COLOR_PENDING,
  BACKGROUND_COLOR_FIX,
  BACKGROUND_COLOR_STORNO,
  BACKGROUND_COLOR_DEFAULT,
  GROUP_OPERATIVA_STATUS_STORNO,
  GROUP_OPERATIVA_STATUS_PENDING,
  GROUP_OPERATIVA_STATUS_FIX,
  ROW_ID_PROGRAM_DAYS,
  GUIDE_DRAWER_PROGRAM_DAYS,
  GUIDE_DRAWER_OPERATIVE_GROUP_STATUS,
  GUIDE_DRAWER_COMMENT,
  GUIDE_DRAWER_SERVICES,
  guidesSeasonSpring,
  GUIDES_SEASON_SPRING_ID,
  GUIDES_SEASON_AUTUMN_ID,
  guidesSeasonAutumn,
  GUIDES_SEASON_CHRISTMASS_NY_ID,
  guidesSeasonChristmassAndNy,
  ROW_ID_GUIDE_LAYOUT_COMMENT,
  ROW_ID_GUIDE_NOTICES,
  GUIDE_DRAWER_NOTICES,
  ROW_ID_GUIDE_BUS_TRANSFER,
  GUIDE_DRAWER_BUS_TRANSFER,
  ROW_ID_OPERATIVE_GROUP_STATUS,
  ROW_ID_GUIDE_CATALOG,
  ROW_ID_GUIDE_PAYMENT,
  GUIDE_DRAWER_CATALOG,
  GUIDE_DRAWER_PAYMENT,
  ROW_ID_GUIDE_SERVICES,
  LOCAL_STORAGE_GUIDES_TABLE,
} from "src/utility/constants";

import guidesColumns from "src/views/Guides/Container/components/Columns/GuidesColumns";
import { DEBOUNCE_FETCH_DELAY } from "../../../../../../utility/reactTable";
// hooks
import { useFetchGuides } from "./useFetchGuides";
import { useFetchGridFilters } from "./useFetchGridFilters";
import { useFetchGuideEnums } from "./useFetchGuideEnums";
import { useTableLocalStorage } from "src/hooks/useTableLocalStorage";

/**
 * useGudies is entry point of data for listGuides component
 */
export default function useGuides() {
  // local storage
  const { tableState, setTableState } = useTableLocalStorage(LOCAL_STORAGE_GUIDES_TABLE);

  // redux state
  const filterDateSelected = moment(tableState.dateYearFilterSelected) || moment();
  const guidesSeasonSelectedID = tableState.guidesSeasonSelectedID || GUIDES_SEASON_SPRING_ID;
  const listGuides = useSelector(selectGuideSchedules);
  const guidesRowExpanded = useSelector(selectGuideScheduleRowExpanded);

  const gridFilters = useSelector(selectGuideScheduleGridFilters);

  // constants
  const guideServiceTypes = useSelector(selectGuideScheduleServiceTypes);

  const dispatch = useDispatch();

  // component level state
  const [pageState, setPageState] = useState(tableState.pageSelected);
  const [pages, setPages] = useState(null);

  // temp variables
  const canPrevious = tableState.pageSelected > 0;
  const canNext = tableState.pageSelected + 1 < pages;

  // get season
  const getSelectedSeason = () => {
    let seasonSelected = guidesSeasonSpring;
    switch (guidesSeasonSelectedID) {
      case GUIDES_SEASON_SPRING_ID:
        seasonSelected = guidesSeasonSpring;
        break;
      case GUIDES_SEASON_AUTUMN_ID:
        seasonSelected = guidesSeasonAutumn;
        break;
      case GUIDES_SEASON_CHRISTMASS_NY_ID:
        seasonSelected = guidesSeasonChristmassAndNy;
        break;
      default:
        seasonSelected = guidesSeasonSpring;
    }

    return seasonSelected;
  };

  // prepare filters for where condition
  const prepareFilters = filtered => {
    let filtersToSend = {};

    filtered.forEach(obj => {
      // check ofr different sending key/values then ones received for table list
      switch (obj.id) {
        case "program_days":
          filtersToSend = {
            ...filtersToSend,
            program_days_title: obj.value,
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  };

  // fetch initial guides
  const { dataFetchGuides, loadingFetchGudies, errorFetchGuides } = useFetchGuides({
    input: {
      pagination_limit: {
        limit: tableState.pageSizeSelected,
        offset: tableState.pageSelected * tableState.pageSizeSelected,
      },
      where: {
        year: filterDateSelected.year().toString(),
        season: getSelectedSeason().enum,
        ...(tableState.filtersSelected.length && prepareFilters(tableState.filtersSelected)),
      },
    },
  });

  // set guides in context
  useEffect(() => {
    const listGuides = get(dataFetchGuides, "listGuideLayoutsByContracts");
    if (listGuides) {
      dispatch(setGuideSchedules(listGuides));

      const totalTableCount = get(listGuides, "[0].total_table_count");

      const pages = totalTableCount ? Math.ceil(totalTableCount / tableState.pageSizeSelected) : 1;

      setPages(pages);
    }
  }, [dataFetchGuides, dispatch, tableState.pageSizeSelected]);

  // fetch grid filters
  const { dataGridFilters } = useFetchGridFilters();

  // set grid filters in context
  useEffect(() => {
    const gridFilters = get(dataGridFilters, "listDatagridFilter");

    if (gridFilters) {
      dispatch(setGuideScheduleGridFilters(gridFilters));
    }
  }, [dataGridFilters, dispatch]);

  // fetch guideServiceTypes enums
  const { dataEnumTypes: dataGuideServicesTypes } = useFetchGuideEnums({ name: "GuideServices" });

  // set guideServiceTypes in context
  useEffect(() => {
    const guideServiceTypes = get(dataGuideServicesTypes, "__type.enumValues");

    if (guideServiceTypes) {
      dispatch(setGuideScheduleServiceTypes(guideServiceTypes));
    }
  }, [dataGuideServicesTypes, dispatch]);

  // fetch guideServiceTypes enums
  const { dataEnumTypes: dataOperativaStatus } = useFetchGuideEnums({ name: "GroupOperativaStatus" });

  // set guideServiceTypes in context
  useEffect(() => {
    const groupOperativeStatusTypes = get(dataOperativaStatus, "__type.enumValues");

    if (groupOperativeStatusTypes) {
      dispatch(setGuideScheduleConstants({ groupOperativeStatusTypes: groupOperativeStatusTypes }));
    }
  }, [dataOperativaStatus, dispatch]);

  /**
   * override functions page jump and next/previous buttons in reactTable
   */
  useEffect(() => {
    setPageState(tableState.pageSelected);
  }, [tableState.pageSelected]);

  // get safe page
  const getSafePage = pageToSelect => {
    if (Number.isNaN(pageToSelect)) {
      pageToSelect = tableState.pageSelected;
    }
    return Math.min(Math.max(pageToSelect, 0), pages - 1);
  };

  // apply page
  const applyPage = e => {
    if (e) {
      e.preventDefault();
    }
    const page = pageState;
    setPageState(page === "" ? tableState.pageSelected : page);
    onGuideTablePageChange(page === "" ? tableState.pageSelected : page);
  };

  // on enter press
  const onPageJumpEnterPress = e => {
    if (e.which === 13 || e.keyCode === 13) {
      applyPage();
    }
  };

  // change page
  const changePage = page => {
    page = getSafePage(page);
    setPageState(page);
    if (tableState.pageSelected !== page) {
      onGuideTablePageChange(page);
    }
  };

  /**
   * on guide list table page change
   */
  const onGuideTablePageChange = page => {
    setTableState({ ...tableState, pageSelected: page });
  };

  /**
   * on guide list table page size change
   */
  const onGuideTablePageSizeChange = pageSize => {
    setTableState({ ...tableState, pageSizeSelected: pageSize });
  };

  const setFiltered = filtered => {
    setTableState({ ...tableState, pageSelected: 0, filtersSelected: filtered });
  };

  const onFilteredChange = debounce(setFiltered, DEBOUNCE_FETCH_DELAY);

  // set columns for frontend
  const guidesColumnsShow = guidesColumns.map(column => {
    if (column.id === "expander") {
      return column;
    } else {
      const col = gridFilters
        ? gridFilters.find(obj => {
            // standard case
            /*
             * Check between active booking columns (backend data) and booking table column name (frontend)
             */
            return obj.column === column.Header;
          })
        : null;

      return {
        ...column,
        show: !!(col && col.active),
      };
    }
  });

  /**
   * keep track of expanded columns
   */
  // table tr props, used for styling background row based on group operativa status
  const getGuideTrProps = (state, rowInfo, instance) => {
    let groupOperativaStatus = get(rowInfo, "original.group_operativa_status");

    if (groupOperativaStatus) {
      let backgroundColor = "";

      switch (groupOperativaStatus) {
        case GROUP_OPERATIVA_STATUS_PENDING:
          backgroundColor = BACKGROUND_COLOR_PENDING;
          break;
        case GROUP_OPERATIVA_STATUS_FIX:
          backgroundColor = BACKGROUND_COLOR_FIX;
          break;
        case GROUP_OPERATIVA_STATUS_STORNO:
          backgroundColor = BACKGROUND_COLOR_STORNO;
          break;
        default:
          backgroundColor = BACKGROUND_COLOR_DEFAULT;
      }

      return {
        style: {
          background: backgroundColor,
          borderBottom: "solid 1px #BDBDBD",
        },
      };
    }

    return {};
  };

  // table tr props, used for styling background row based on group operativa status
  const getGuideTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        // open expander
        if (column.id === "expander") {
          const path = rowInfo.original.program_id;
          const diff = { [path]: !guidesRowExpanded[path] };

          dispatch(setGuideScheduleRowExpanded({ ...guidesRowExpanded, ...diff }));
        }

        // guide drawers action
        let action = {
          isOpen: true,
        };

        // Depending on which row is clicked, open drawer
        switch (column.id) {
          case ROW_ID_PROGRAM_DAYS:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_PROGRAM_DAYS,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
              }),
            );

            break;

          case ROW_ID_OPERATIVE_GROUP_STATUS:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_OPERATIVE_GROUP_STATUS,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                groupStatus: get(rowInfo, "original.group_operativa_status", ""),
              }),
            );

            break;

          case ROW_ID_GUIDE_LAYOUT_COMMENT:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_COMMENT,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                guideLayoutComment: get(rowInfo, "original.guide_layout_comment", ""),
              }),
            );

            break;

          case ROW_ID_GUIDE_NOTICES:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_NOTICES,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                guideNotices: get(rowInfo, "original.guide_notices", ""),
              }),
            );

            break;

          case ROW_ID_GUIDE_BUS_TRANSFER:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_BUS_TRANSFER,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                transportStatus: get(rowInfo, "original.transport", ""),
              }),
            );

            break;

          // guide services
          case ROW_ID_GUIDE_SERVICES:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_SERVICES,
            };

            const guideServiceStatusDescription = get(rowInfo, "original.guide_services", "");

            if (!guideServiceStatusDescription) {
              return;
            }
            const guideServiceEnum = guideServiceTypes.find(
              guideService => guideService.description.toUpperCase() === guideServiceStatusDescription.toUpperCase(),
            );

            const guideServiceStatusSelected = guideServiceEnum ? guideServiceEnum.name : "";

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                guideServiceStatusSelected: guideServiceStatusSelected,
              }),
            );

            break;

          // payment
          case ROW_ID_GUIDE_PAYMENT:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_PAYMENT,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                payment: get(rowInfo, "original.payment", ""),
              }),
            );

            break;

          // catalog
          case ROW_ID_GUIDE_CATALOG:
            action = {
              ...action,
              drawerType: GUIDE_DRAWER_CATALOG,
            };

            dispatch(
              setGuideDrawerPayload({
                programId: get(rowInfo, "original.program_id", ""),
                catalog: get(rowInfo, "original.catalog", ""),
              }),
            );

            break;

          default:
        }

        if (action.drawerType) {
          dispatch(setGuideDrawers(action));
        }
      },
    };
  };

  return {
    loadingFetchGudies,
    errorFetchGuides,
    guidesColumnsShow,
    guideSchedulesPageSelected: tableState.pageSelected,
    guideSchedulesPageSizeSelected: tableState.pageSizeSelected,
    guideSchedulesFilters: tableState.filtersSelected,
    listGuides,
    getGuideTrProps,
    getGuideTdProps,
    onFilteredChange,
    onGuideTablePageChange,
    onGuideTablePageSizeChange,
    pages,
    canPrevious,
    canNext,
    pageState,
    applyPage,
    setPageState,
    getSafePage,
    onPageJumpEnterPress,
    changePage,
  };
}
