export const findServiceTranslationLanguageIndex = (
  partner_client_service_translations,
  language_id
) =>
  partner_client_service_translations.findIndex(
    ser => ser.language_id.toString() === language_id.toString()
  );

export const findPartnerServiceById = (listPartnerClientService, id) =>
  listPartnerClientService.find(service => service.id === id);

export const setPartnerServicesForAutocomplete = (
  listPartnerClientService,
  language_id
) => {
  return (
    listPartnerClientService &&
    listPartnerClientService.map(service => {
      const { partner_client_service_translations } = service;

      const translationIndex = findServiceTranslationLanguageIndex(
        partner_client_service_translations,
        language_id
      );

      return {
        ...service,
        label:
          translationIndex !== -1
            ? partner_client_service_translations[translationIndex].name
            : "No service language name"
      };
    })
  );
};
