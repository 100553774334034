// libs
import { get } from "lodash";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawerPayload, setGuideDrawerPayload } from "src/views/Guides/guidesSlice";

/**
 * usePaymentContainer is entry point of data for paymentContainer
 */
export default function usePaymentContainer() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  const onPaymentTextChange = useCallback(
    event => {
      let payment = get(event, "target.value");
      dispatch(setGuideDrawerPayload({ ...guidePayload, payment: payment }));
    },
    [dispatch, guidePayload],
  );

  return {
    payment: get(guidePayload, "payment", ""),
    onPaymentTextChange,
  };
}
