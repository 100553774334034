import React from "react";

// ui
import Paper from "material-ui/Paper";

// hooks
import useAnnouncementOrderList from "./hooks/useAnnouncementOrderList";
import useAnnouncementOrderTable from "./hooks/useAnnouncementOrderTable";

// components
import ReactTableCustom from "src/components/UI/ReactTable";

/**
 * AnnouncementOrderList is used for showing list of announcement orders
 */
export default function AnnouncementOrderList() {
  // hooks
  const {
    // variables
    announcementOrderColumns,
    announcementOrders,
    announcementOrdersFilters,
    announcementOrdersPageSelected,
    announcementOrdersPageSizeSelected,
    canPrevious,
    canNext,
    loading,
    pages,
    // functions
    getAnnouncementTableTdProps,
    onAnnouncementOrdersFilterChange,
    onAnnouncementOrdersTablePageChange,
    onAnnouncementOrdersTablePageSizeChange,
  } = useAnnouncementOrderList();

  const {
    // variables
    pageState,
    // functions
    applyPage,
    changePage,
    getSafePage,
    onPageJumpEnterPress,
    setPageState,
  } = useAnnouncementOrderTable(announcementOrdersPageSelected, pages, onAnnouncementOrdersTablePageChange);

  // return root
  return (
    <div>
      <Paper zDepth={1}>
        <ReactTableCustom
          columns={announcementOrderColumns}
          data={announcementOrders || []}
          page={announcementOrdersPageSelected}
          innerProps={{
            loading: loading,
            pages: pages,
            pageSize: announcementOrdersPageSizeSelected,
            manual: true,
            sortable: false,
            defaultFiltered: announcementOrdersFilters,
            getTdProps: getAnnouncementTableTdProps,
            onFilteredChange: onAnnouncementOrdersFilterChange,
            onPageChange: page => {
              onAnnouncementOrdersTablePageChange(page);
            },
            onPageSizeChange: pageSize => {
              onAnnouncementOrdersTablePageSizeChange(pageSize);
            },
            renderPageJump: ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => {
              return (
                <div className="-pageJump">
                  <input
                    aria-label={pageJumpText}
                    type={inputType}
                    onChange={e => {
                      const val = e.target.value;
                      const page = val - 1;
                      if (val === "") {
                        return setPageState(val);
                      }
                      return setPageState(getSafePage(page));
                    }}
                    value={pageState === "" ? "" : pageState + 1}
                    onBlur={applyPage}
                    onKeyPress={onPageJumpEnterPress}
                  />
                </div>
              );
            },
            NextComponent: props => {
              return (
                <button
                  disabled={!canNext}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState + 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
            PreviousComponent: props => {
              return (
                <button
                  disabled={!canPrevious}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState - 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
          }}
        />
      </Paper>
    </div>
  );
}
