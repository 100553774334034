import React, { Fragment } from "react";

import {
  Button,
  Card,
  Divider,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";

// hooks
import useAnnouncementList from "./hooks/useAnnouncementList";

// component
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";

// constants
import { LABEL_NO_ANNOUNCEMENTS } from "src/utility/labels";
import { HOTEL_GUIDE_ANNOUNCEMENT } from "src/utility/constants";
import { get } from "lodash";

const styles = theme => ({
  root: {
    marginTop: 18,
  },

  list: {
    position: "relative",
    overflow: "auto",
    maxHeight: 280,
    marginTop: 8,
  },

  label: {
    marginTop: theme.spacing.unit,
  },

  item: {},
  itemSelected: {
    backgroundColor: "#EEEEEE",
  },
});

/**
 * AnnouncementList
 */
const AnnouncementList = ({ classes }) => {
  // hook
  const {
    announcementSelected,
    getAnnouncementTypeDescription,
    handleOnCreateClick,
    handleOnAnnouncementSelect,
    loadingAnnouncements,
    supplierAnnouncements,
  } = useAnnouncementList();

  const announcementSelectedId = get(announcementSelected, "id", "");

  // loading component
  const loading = () => {
    return (
      <Grid style={{ marginTop: 18 }} container spacing={8} alignItems="center">
        <Grid item xs={2}>
          <FixedCircularProgress color="inherit" size={16} thickness={6} />
        </Grid>

        <Grid item xs={10}>
          <Typography variant="body1">Dohvaćam podatke</Typography>
        </Grid>
      </Grid>
    );
  };

  // announcement list component
  return (
    <Card style={{ marginBottom: 20 }}>
      {loadingAnnouncements ? (
        <CardContent>{loading()}</CardContent>
      ) : (
        <CardContent>
          <Typography variant="headline">Kreirane najave</Typography>

          {supplierAnnouncements.length ? (
            <List className={classes.list}>
              {supplierAnnouncements.map(announcement => {
                return (
                  <Fragment key={announcement.id}>
                    <ListItem
                      button
                      disableGutters
                      onClick={() => handleOnAnnouncementSelect(announcement)}
                      className={announcement.id === announcementSelectedId ? classes.itemSelected : classes.item}
                    >
                      <ListItemText
                        primary={getAnnouncementTypeDescription(announcement.announcement_type)}
                        secondary={
                          announcement.announcement_type !== HOTEL_GUIDE_ANNOUNCEMENT
                            ? `${announcement.language_desc}`
                            : `${announcement.language_desc} (${announcement.guide_first_name} ${announcement.guide_last_name})`
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
          ) : (
            <Typography className={classes.label} variant="body1">
              {LABEL_NO_ANNOUNCEMENTS}
            </Typography>
          )}
        </CardContent>
      )}

      <CardActions>
        <Button onClick={handleOnCreateClick} style={{ margin: 8 }} size="small" variant="contained" color="primary">
          Kreiraj najavu
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(AnnouncementList);
