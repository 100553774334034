import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_TOP, FONT_SIZE_LABEL } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_TOP,
    display: "flex",
    flexDirection: "row",
  },

  text: {
    fontSize: FONT_SIZE_LABEL,
    fontFamily: "OpenSansBold",
  },
});

// VoucherSubject
export default function VoucherSubject({ subjectText }) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{subjectText}</Text>
    </View>
  );
}
