import React, { Component, Fragment } from "react";

class PreviewService extends Component {
  render() {
    return (
      <Fragment>
        <br />
        <div
          style={{
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "1.5em",
            }}
          >
            RAČUN NA OLIVARI D.O.O. MALINSKA
          </h2>
        </div>
        <br />
        <div>
          <h3
            style={{
              fontSize: "1.17em",
            }}
          >
            MOLIMO UZ RAČUN POSLATI KOPIJU VOUCHERA
          </h3>
        </div>
      </Fragment>
    );
  }
}

export default PreviewService;
