import React, { Component } from "react";
import { graphql } from "react-apollo";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import { terminsFragment } from "../../../../graphql/fragments";
import TrueFalseSelect from "../../../../components/UI/TrueFalseSelectNewMaterial";

import toastr from "toastr";

class TransportDrawer extends Component {
  state = {
    transport: ""
  };

  updateData = () => {
    const { transport } = this.state;
    if (transport !== this.props.transport) {
      try {
        this.props.mutate({
          variables: {
            id: this.props.id,
            patch: {
              transport
            }
          },
          update: (store, { data: { updateTerminPeriod } }) => {
            store.writeFragment({
              id: `Termin:${updateTerminPeriod.id}`,
              fragmentName: "Termin",
              fragment: terminsFragment,
              data: updateTerminPeriod
            });
          }
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  componentDidMount = () => {
    const { transport } = this.props;

    this.setState({ transport });
  };

  render() {
    const { toggle, open } = this.props;
    const { transport } = this.state;

    return (
      <DrawerWrapper
        toggle={toggle}
        update={this.updateData}
        open={open}
        title="Bus"
      >
        <div className="form-control-grid">
          <TrueFalseSelect
            label="Bus"
            returnBoolean
            value={transport}
            changed={({ value }) => this.setState({ transport: value })}
          />
        </div>
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(TransportDrawer);
