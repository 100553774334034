import React, { Fragment } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import { VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "moment/locale/hr";
import { FormHelperText } from "@material-ui/core";

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    let focused = null;

    this.state = {
      focused,
      date: moment(),
    };
  }

  handleDateChange = date => {
    this.props.handleChange(date);
  };

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  yearArray = Array.from(Array(10));

  renderPreviousYears = () =>
    this.yearArray.map((year, index) => (
      <option
        value={moment().year() - (this.yearArray.length - index)}
        key={moment().year() - (this.yearArray.length - index)}
      >
        {moment().year() - (this.yearArray.length - index)}
      </option>
    ));

  renderNextYears = () =>
    this.yearArray.map((year, index) => (
      <option value={moment().year() + index + 1} key={moment().year() + index + 1}>
        {moment().year() + index + 1}
      </option>
    ));

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className="month-year">
      <div>
        <select className="booking-select" value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select className="booking-select" value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
          {this.renderPreviousYears()}
          <option value={moment().year()} key={moment().year()}>
            {moment().year()}
          </option>
          {this.renderNextYears()}
        </select>
      </div>
    </div>
  );

  render() {
    const { value, disabled, error, errorMsg, vertical, small, handleChange, ...rest } = this.props;

    const props = {
      onDateChange: date => this.props.handleChange(date),
      focused: this.state.focused,
      onFocusChange: ({ focused }) => this.setState({ focused }),
      firstDayOfWeek: 1,
      isOutsideRange: day => false,
    };

    return (
      <Fragment>
        <div className={error ? "SingleDatePicker-error" : ""}>
          <SingleDatePicker
            numberOfMonths={1}
            renderMonthElement={this.renderMonthElement}
            id={this.props.id ? this.props.id : "date_picker"}
            date={value ? moment(value) : null}
            disabled={disabled}
            orientation={vertical ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
            small={small ? true : undefined}
            {...props}
            {...rest}
          />
        </div>
        {error && <FormHelperText error={error}>{errorMsg}</FormHelperText>}
      </Fragment>
    );
  }
}
