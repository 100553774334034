import React from "react";
import { Grid } from "@material-ui/core";

export default function() {
  return (
    <div className="preview-header">
      <Grid container spacing={16}>
        <Grid item xs={4}>
          <div>
            <img
              src="https://www.olivari-travel.com/images/logo.png"
              alt="Olivari logo"
            />
          </div>
          <small>Complete tourist service Croatia & Southeast Europe</small>
        </Grid>
        <Grid item xs={4}>
          <h4>OLIVARI d.o.o.</h4>
          <p>DUBAŠLJANSKA 111</p>
          <p> HR-51511 Malinska</p>
          <p>Tel: +385 51 850 000</p>
          <p>Fax +385 51 859 000</p>
          <div className="preview-header-cols-2 ">
            <p>Emergency</p>
            <div>
              <p> +385 91 654 8274</p>
              <p> +385 91 O L I V A R I</p>
            </div>
          </div>
          <p>E-mail: groups@volivari.com</p>
          <p>WEB: www.olivari-travel.com</p>
        </Grid>
        <Grid item xs={4}>
          <p>RDA Member Nr. A1 8221</p>
          <p>Global Passenger Network</p>
          <p>Member for Croatia</p>
          <p>Member of UHPA – Association of Croatian travel agencies</p>
          <p>ISO 9001:2015</p>
          <p>ID COD: HR-AB-51-040143504</p>
          <p>OIB: 19778110320</p>
        </Grid>
      </Grid>
    </div>
  );
}
