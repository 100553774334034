import React from "react";
import { withStyles } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
      cursor: "pointer",
    },
  },
});

function CheckIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m -2 15 l -5 -5 l 1.41 -1.41 L 10 14.17 l 7.59 -7.59 L 19 8 l -9 9 Z" />
    </SvgIcon>
  );
}

function SvgIcons(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CheckIcon
        className={props.param ? classes.iconHover : null}
        color={props.param ? "primary" : "disabled"}
        onClick={() => {
          if (props.param) {
            props.onClickHandler();
          }
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SvgIcons);
