/**
 * voucher constants
 */

// font sizes
export const FONT_SIZE_TITLE = 16;
export const FONT_SIZE_SUBHEADING = 15;
export const FONT_SIZE_LABEL = 11;
export const FONT_SIZE_VALUE = 11;
export const FONT_SIZE_SUB_VALUE = 10;

export const FONT_SIZE_FOOTER = 8;

// margin
export const MARGIN_PAGE = 18;
export const MARGIN_CONTAINER_TOP = 6;
export const MARGIN_CONTAINER_BOTTOM = 6;
export const MARGIN_CONTAINER_DOUBLE_TOP = 12;
export const MARGIN_CONTAINER_DOUBLE_BOTTOM = 12;

// width
export const LABEL_MIN_WIDTH = 140;
