import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateBookingState extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_v =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("notification_booking_state");
      }).length;

    const prev_v =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("notification_booking_state");
      }).length;

    const next_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("booking_state_content");
      }).length;

    const prev_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("booking_state_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.notification_booking_state !== this.props.notification_booking_state ||
      nextProps.booking_state_content !== this.props.booking_state_content ||
      next_content !== prev_content ||
      next_v !== prev_v
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`booking_state_content`).length) {
      autosize(document.getElementsByName(`booking_state_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Stanje bookinga</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/notification_booking_state")}>
            <InputLabel htmlFor="notification_booking_state">Podsjetnik za booking</InputLabel>
            <Input
              id="notification_booking_state"
              className="template-input"
              inputProps={{
                name: "notification_booking_state",
              }}
              value={this.props.notification_booking_state}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/notification_booking_state")}</FormHelperText>
          </FormControl>
          <div>
            <TextArea
              error={error("/offer_contract_document/booking_state_content")}
              className="template-input"
              inputProps={{
                name: "booking_state_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.booking_state_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/booking_state_content")}>
              {errorMsg("/offer_contract_document/booking_state_content")}
            </FormHelperText>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TemplateBookingStateHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateBookingState />
  </OfferForm.ConsumerHOC>
);

export default TemplateBookingStateHOC;
