import * as actionTypes from "../actionTypes";

export const setPreview = (preview, lists) => {
  return {
    type: actionTypes.SET_OFFER_CONTRACT_PREVIEW,
    preview,
    lists
  };
};

export const resetPreview = () => {
  return {
    type: actionTypes.RESET_PREVIEW
  };
};

export const setLanguage = language_id => {
  return {
    type: actionTypes.SET_PREVIEW_LANGUAGE,
    language_id
  };
};
