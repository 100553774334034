import { useEffect } from "react";

// libs
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import { useMutation, useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";

// graphql
import { FETCH_ANNOUNCEMENT_ORDER } from "src/graphql/query/announcementOrders";
import { FETCH_UPDATE_ANNOUNCEMENT_ORDER } from "src/graphql/mutation/announcementOrders";

// redux state
import {
  selectAnnouncementOrder,
  setAnnouncementOrder,
  setAnnouncementOrderCopyState,
  setAnnouncementOrderEditInputs,
  setAnnouncementOrderUpdateLoading,
} from "src/views/AnnouncementOrders/announcementOrdersSlice";

/**
 * useAnnouncementOrderEditForm is entry point of data for announcement order edit component
 */
export default function useAnnouncementOrderEditForm(announcementOrderId) {
  // react router hitory
  let history = useHistory();

  // redux state
  const announcementOrder = useSelector(selectAnnouncementOrder);

  const dispatch = useDispatch();

  // queries
  const { data, loading } = useQuery(FETCH_ANNOUNCEMENT_ORDER, {
    variables: {
      id: announcementOrderId,
    },
    notifyOnNetworkStatusChange: true,
  });

  // mutations
  // TODO: handle loading update
  const [updateAnnouncementOrder, { loading: loadingUpdate }] = useMutation(FETCH_UPDATE_ANNOUNCEMENT_ORDER);

  // set announcement order loading in state
  useEffect(() => {
    dispatch(setAnnouncementOrderUpdateLoading(loadingUpdate));
  }, [loadingUpdate, dispatch]);

  // set announcement order in state
  useEffect(() => {
    const announcementOrder = get(data, "getDictate");

    if (announcementOrder) {
      dispatch(setAnnouncementOrder(announcementOrder));
    }
  }, [data, dispatch]);

  /**
   * on back click
   */
  const onBackClick = () => {
    history.goBack();
  };

  /**
   * handle on update announcement order
   */
  const handleOnUpdateAnnouncementOrder = async (keyOfValue, value) => {
    const updateAnnouncementOrderResponse = await updateAnnouncementOrder({
      variables: {
        input: {
          id: announcementOrder.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementOrderUpdated = get(updateAnnouncementOrderResponse, "data.updateDictate");

    if (announcementOrderUpdated) {
      toastr.success("Uspješno ažuriran nalog");

      // update announcement
      dispatch(setAnnouncementOrder(announcementOrderUpdated));

      // close edit input
      dispatch(setAnnouncementOrderEditInputs({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  /**
   * handle copy announcement order in rtf for pasting in emails
   */
  const copyAnnouncementOrderToClipboard = async element => {
    // remove all edit buttons before copying to rtf
    await dispatch(setAnnouncementOrderCopyState(true));

    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(typeof element === "string" ? document.getElementById(element) : element);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    dispatch(setAnnouncementOrderCopyState(false));
    toastr.success("Najava kopirana za slanje mailom");
  };

  return {
    // variables
    announcementOrder,
    loading,
    // functions
    copyAnnouncementOrderToClipboard,
    handleOnUpdateAnnouncementOrder,
    onBackClick,
  };
}
