import React, { Component, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BootstrapInput from "../../../../components/UI/BootstrapLikeInput";
import { autosize } from "../../../../utility/globals";
import { FormControl, FormHelperText } from "@material-ui/core";

class TypologyTranslations extends Component {
  static ShowTypologyTranslations = class extends Component {
    componentDidMount() {
      // Resizing

      Object.keys(this.refs).forEach(key => {
        autosize(this.refs[key]);
      });
    }

    render() {
      const {
        language,
        typology_translation,
        createMode,
        editTypology
      } = this.props;
      return !createMode && !editTypology ? (
        <TableRow key={language.id} className="align-top">
          <TableCell component="th" scope="row">
            {language.desc}
          </TableCell>
          <TableCell>
            <BootstrapInput
              disabled={true}
              label=""
              id="name"
              value={typology_translation.name ? typology_translation.name : ""}
            />
          </TableCell>
          <TableCell padding="dense">
            <FormControl fullWidth>
              <textarea
                disabled={true}
                id="typology_translation"
                ref={`show${language.id}`}
                onKeyUp={el => autosize(el.target)}
                className={`preview-content-content textarea-stretch preview-textarea-disabled `}
                value={
                  typology_translation.desc ? typology_translation.desc : ""
                }
              />
            </FormControl>
          </TableCell>
        </TableRow>
      ) : null;
    }
  };

  static EditCreateTypologyTranslations = class extends Component {
    componentDidMount() {
      // Resizing
      Object.keys(this.refs).forEach(key => {
        autosize(this.refs[key]);
      });
    }

    render() {
      const {
        index,
        createMode,
        editTypology,
        language,
        typology_translation,
        changeLanguageTextField,
        error,
        errorMsg
      } = this.props;

      return createMode || editTypology ? (
        <TableRow key={language.id} className="align-top">
          <TableCell component="th" scope="row">
            {language.desc}
          </TableCell>
          <TableCell>
            <BootstrapInput
              label=""
              id="name"
              error={error(`/typology_translations/${index}/name`)}
              errorMsg={errorMsg(`/typology_translations/${index}/name`)}
              value={typology_translation.name ? typology_translation.name : ""}
              onChange={({ target: { value } }) =>
                changeLanguageTextField({ key: "name", value }, language.id)
              }
            />
          </TableCell>
          <TableCell padding="dense">
            <FormControl
              fullWidth
              error={error(`/typology_translations/${index}/desc`)}
            >
              <textarea
                id="typology_translation"
                ref={createMode ? `create${language.id}` : `edit${language.id}`}
                onKeyUp={el => autosize(el.target)}
                className={`preview-content-content textarea-stretch preview-textarea ${
                  error(`/typology_translations/${index}/desc`)
                    ? "textarea-error"
                    : ""
                }
                  `}
                value={
                  typology_translation.desc ? typology_translation.desc : ""
                }
                onChange={({ target: { value } }) => {
                  changeLanguageTextField({ key: "desc", value }, language.id);
                }}
              />
              {error(`/typology_translations/${index}/desc`) && (
                <FormHelperText>
                  {errorMsg(`/typology_translations/${index}/desc`)}
                </FormHelperText>
              )}
            </FormControl>
          </TableCell>
        </TableRow>
      ) : null;
    }
  };

  render() {
    const {
      listLanguage,
      typology_translations,
      editTypology,
      createMode,
      changeLanguageTextField,
      error,
      errorMsg
    } = this.props;

    const tableToShow =
      typology_translations &&
      typology_translations.length &&
      listLanguage &&
      listLanguage.map((language, index) => {
        let typology_translation = typology_translations.find(
          translation => translation.language_id === language.id
        );

        if (!typology_translation) {
          typology_translation = {};
          typology_translation.name = "";
          typology_translation.desc = "";
        }

        return (
          <Fragment key={language.id}>
            <TypologyTranslations.ShowTypologyTranslations
              language={language}
              typology_translation={typology_translation}
              editTypology={editTypology}
              createMode={createMode}
            />
            <TypologyTranslations.EditCreateTypologyTranslations
              index={index}
              typology_translation={typology_translation}
              language={language}
              editTypology={editTypology}
              createMode={createMode}
              changeLanguageTextField={changeLanguageTextField}
              error={error}
              errorMsg={errorMsg}
            />
          </Fragment>
        );
      });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jezik</TableCell>
            <TableCell>Naziv</TableCell>
            <TableCell>Opis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableToShow ? tableToShow : <React.Fragment />}</TableBody>
      </Table>
    );
  }
}

export default TypologyTranslations;
