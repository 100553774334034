import React from "react";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import { defaultFilterMethod, DEFAULT_PAGE_SIZE } from "../../utility/reactTable";

class ReactTableCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageInputFocused: false,
    };
  }

  render() {
    return (
      <ReactTable
        data={this.props.data}
        columns={this.props.columns}
        filterable
        filterAll={true}
        defaultFilterMethod={defaultFilterMethod}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        className="-striped -highlight"
        collapseOnSortingChange={false}
        previousText="Prethodno"
        nextText="Sljedeće"
        loadingText="Dohvaćam..."
        noDataText="Nema rezultata"
        pageText="Stranica"
        ofText="od"
        rowsText="redova"
        {...this.props.innerProps}
        {...(this.props.renderCustomPagination && {
          renderPageJump: ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <input
                ref={this.refPageJumpInput}
                onFocus={() => this.setState({ isPageInputFocused: true })}
                aria-label={pageJumpText}
                type={inputType}
                onChange={onChange}
                value={this.state.isPageInputFocused ? value : this.props.innerProps.page + 1}
                onBlur={() => {
                  this.setState({ isPageInputFocused: false });
                  return onBlur;
                }}
                onKeyPress={onKeyPress}
              />
            </div>
          ),
        })}
      />
    );
  }
}

export default ReactTableCustom;
