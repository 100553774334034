import React, { Component } from "react";
import { connect } from "react-redux";
import TextArea from "../../../../components/UI/CommentTextArea";
import { changeDocumentParam } from "../../../../store/actions";
import { autosize } from "../../../../utility/globals";

const mapStateToProps = state => {
  const {
    children_discounts,
    included_in_the_price,
    comment,
    first_booking_station,
    rooms_are_bookable,
    definitive_room_list,
    pay,
    payment_rules,
    cancellation_costs,
  } = state.offer.offer.offer_contract_document;

  return {
    children_discounts,
    included_in_the_price,
    comment,
    first_booking_station,
    rooms_are_bookable,
    definitive_room_list,
    pay,
    payment_rules,
    cancellation_costs,
  };
};

export default connect(mapStateToProps, { changeDocumentParam })(
  class AlotmanTemplates extends Component {
    changeContent = key => ({ target: { value } }) => {
      this.props.changeDocumentParam({
        key,
        value,
      });
    };

    componentDidMount = () => {
      this.autoResize();
    };

    componentDidUpdate() {
      this.autoResize();
    }

    autoResize() {
      if (document.getElementsByName(`children_discounts`).length) {
        autosize(document.getElementsByName(`children_discounts`)[0]);
      }
      if (document.getElementsByName(`included_in_the_price`).length) {
        autosize(document.getElementsByName(`included_in_the_price`)[0]);
      }
      if (document.getElementsByName(`comment`).length) {
        autosize(document.getElementsByName(`comment`)[0]);
      }
      if (document.getElementsByName(`first_booking_station`).length) {
        autosize(document.getElementsByName(`first_booking_station`)[0]);
      }
      if (document.getElementsByName(`rooms_are_bookable`).length) {
        autosize(document.getElementsByName(`rooms_are_bookable`)[0]);
      }
      if (document.getElementsByName(`definitive_room_list`).length) {
        autosize(document.getElementsByName(`definitive_room_list`)[0]);
      }
      if (document.getElementsByName(`pay`).length) {
        autosize(document.getElementsByName(`pay`)[0]);
      }
      if (document.getElementsByName(`payment_rules`).length) {
        autosize(document.getElementsByName(`payment_rules`)[0]);
      }
      if (document.getElementsByName(`cancellation_costs`).length) {
        autosize(document.getElementsByName(`cancellation_costs`)[0]);
      }
    }

    render() {
      const {
        isNotDraft,
        children_discounts,
        included_in_the_price,
        comment,
        first_booking_station,
        rooms_are_bookable,
        definitive_room_list,
        pay,
        payment_rules,
        cancellation_costs,
      } = this.props;

      return (
        <React.Fragment>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Popust na djecu</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="children_discounts"
                defaultValue={children_discounts}
                customOnChange={this.changeContent("children_discounts")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Uključeno u cijenu</label>
            </div>
            <div>
              <TextArea
                name="included_in_the_price"
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                defaultValue={included_in_the_price}
                customOnChange={this.changeContent("included_in_the_price")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Komentar</label>
            </div>
            <div>
              <TextArea
                name="comment"
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                defaultValue={comment}
                customOnChange={this.changeContent("comment")}
                rows="3"
              />
            </div>
          </div>

          <div>
            <h2>Ostale usluge</h2>
          </div>

          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Prvo stanje bookinga</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="first_booking_station"
                defaultValue={first_booking_station}
                customOnChange={this.changeContent("first_booking_station")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Raspoložive sobe</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="rooms_are_bookable"
                defaultValue={rooms_are_bookable}
                customOnChange={this.changeContent("rooms_are_bookable")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Definitivna lista soba</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="definitive_room_list"
                defaultValue={definitive_room_list}
                customOnChange={this.changeContent("definitive_room_list")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Plaćanje</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="pay"
                defaultValue={pay}
                customOnChange={this.changeContent("pay")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Pravila plaćanja</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="payment_rules"
                defaultValue={payment_rules}
                customOnChange={this.changeContent("payment_rules")}
                rows="3"
              />
            </div>
          </div>
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Troškovi otkaza</label>
            </div>
            <div>
              <TextArea
                inputProps={{
                  disabled: !!isNotDraft,
                }}
                name="cancellation_costs"
                defaultValue={cancellation_costs}
                customOnChange={this.changeContent("cancellation_costs")}
                rows="3"
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  },
);
