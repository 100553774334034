import React from "react";

// components
import ToolbarView from "src/views/Guides/Container/components/Toolbar";
import GuidesListView from "src/views/Guides/Container/components/List";
import AllDrawers from "./components/Drawers";

export default function GudiesContainer() {
  const ref = React.createRef();

  return (
    <div>
      <ToolbarView contentToPrint={ref} />
      <GuidesListView ref={ref} />
      <AllDrawers />
    </div>
  );
}
