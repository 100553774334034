import { FormHelperText, CircularProgress, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { graphql } from "react-apollo";
import toastr from "toastr";
import DateRangePicker from "../../../../components/UI/DateRangePicker";
import FormValidator from "../../../../components/UI/FormValidator";
import { createAlotmanTermin } from "../../../../graphql/mutation/alotman";
import { findTypologyByCroLang, sortTypologies } from "../../../../utility/establishment";

const initialState = {
  from: null,
  to: null,
  typology_id: null,
  sum: 0,
};

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
    width: "20px !important",
    height: "20px !important",
  },
});

class AddTermin extends React.Component {
  static schema = {
    type: "object",
    // required: ["from", "to", "sum", "typology_id"],
    properties: {
      from: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      to: {
        format: "date-time",
        errorMessage: "Termin od mora biti datum",
        not: { type: "null" },
      },
      typology_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Odaberite tipologiju",
      },
      sum: {
        type: "integer",
        minimum: 1,
        errorMessage: "Stanje mora biti upisan kao prosti broj",
      },
    },
  };

  state = {
    loading: false,
    ...initialState,
  };

  resetTypology = () => {
    this.setState({
      typology_id: null,
      sum: null,
    });
  };

  sendNewFixContigent = () => {
    this.setState({ loading: true }, async () => {
      try {
        await this.props.mutate({
          variables: {
            ...this.state,
            establishment_id: this.props.establishment_id,
          },
        });

        this.props.onAdd();

        toastr.success("Uspješno kreirano");
        this.setState({
          loading: false,
          ...initialState,
        });
      } catch (error) {}
      this.resetTypology();
      /*} catch (error) {
      }*/
    });
  };

  render() {
    // get only that one?
    const { establishment, establishment_typologies } = this.props;
    const { from, to, typology_id, sum } = this.state;

    return (
      <FormValidator schema={AddTermin.schema} submit={this.sendNewFixContigent} data={this.state}>
        {({ error, errorMsg, submitAndRecreate }) => {
          return (
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="first_name_partner_client_contact">
                    Hotel
                  </InputLabel>
                  <Input
                    id="first_name_partner_client_contact"
                    name="first_name_partner_client_contact"
                    value={establishment.name}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DateRangePicker
                  from={from}
                  to={to}
                  error={error("/from") || error("/to")}
                  errorMsg={(error("/from") || error("/to")) && "Molim unijeti termin"}
                  handleFromChange={date => this.setState({ from: date })}
                  handleToChange={date => this.setState({ to: date })}
                  vertical
                  small
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/typology_id")}>
                  <TextField
                    fullWidth
                    id="kontigent_typology_id"
                    select
                    label="Tipologija"
                    className=""
                    value={String(typology_id) || ""}
                    onChange={({ target: { value } }) => this.setState({ typology_id: value })}
                    error={error("/typology_id")}
                  >
                    {sortTypologies(establishment_typologies).map((typology, typo_index) => {
                      const typo = findTypologyByCroLang(typology);

                      return (
                        <MenuItem key={typo_index} value={typology.id}>
                          {typo.name} ( {typology.establishment_code} ) [{typology.persons_capacity}]
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {error("/typology_id") && <FormHelperText>{errorMsg("/typology_id")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/sum")}>
                  <TextField
                    id="fix_kontigent"
                    label="Raspoloživo stanje"
                    type="text"
                    value={sum || ""}
                    onChange={({ target: { value } }) => this.setState({ sum: value })}
                    error={error("/sum")}
                  />
                  {error("/sum") && <FormHelperText>{errorMsg("/sum")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} className="ver-center">
                <Grid container spacing={24} justify="flex-end">
                  <Grid item>
                    <Button variant="contained" disabled={this.state.loading} onClick={submitAndRecreate}>
                      {this.state.loading && (
                        <CircularProgress
                          classes={{
                            root: `${this.props.classes.leftIcon} ${this.props.classes.iconSmall}`,
                          }}
                        />
                      )}
                      Spremi
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </FormValidator>
    );
  }
}

export default graphql(createAlotmanTermin)(withStyles(styles)(AddTermin));
