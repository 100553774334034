import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateRoomState extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_v =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("notification_room_list");
      }).length;

    const prev_v =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("notification_room_list");
      }).length;

    const next_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("room_list_content");
      }).length;

    const prev_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("room_list_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.notification_room_list !== this.props.notification_room_list ||
      nextProps.room_list_content !== this.props.room_list_content ||
      next_content !== prev_content ||
      next_v !== prev_v
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`room_list_content`).length) {
      autosize(document.getElementsByName(`room_list_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Lista gostiju</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/notification_room_list")}>
            <InputLabel htmlFor="notification_room_list">Podsjetnik za sobe</InputLabel>
            <Input
              id="notification_room_list"
              className="template-input"
              inputProps={{
                name: "notification_room_list",
              }}
              value={this.props.notification_room_list}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/notification_room_list")}</FormHelperText>
          </FormControl>
          <div>
            <TextArea
              error={error("/offer_contract_document/room_list_content")}
              className="template-input"
              inputProps={{
                name: "room_list_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.room_list_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/room_list_content")}>
              {errorMsg("/offer_contract_document/room_list_content")}
            </FormHelperText>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TemplateRoomStateHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateRoomState />
  </OfferForm.ConsumerHOC>
);

export default TemplateRoomStateHOC;
