export default {
  // 1. Croatian (in database)
  1: {
    title: "Vaučer",
    room: "Soba",
    guest: "Klijenti",
    service: "Usluga",
    service_start: "Početna usluga",
    service_end: "Završna usluga",
    message: "ŽELIMO VAM PREKRASAN ODMOR"
  },
  // 2. English (in database)
  2: {
    title: "Voucher",
    room: "Room",
    guest: "Clients",
    service: "Service",
    service_start: "Start service",
    service_end: "End service",
    message: "WE WISH YOU A BEAUTIFUL STAY"
  },
  // 3. German (in database)
  3: {
    title: "Voucher",
    room: "Zimmer",
    guest: "Kunden",
    service: "Leistung",
    service_start: "Erste leistung",
    service_end: "End leistung",
    message: "WIR WÜNSCHEN EINEN SCHÖNEN AUFENTHALT"
  },
  // 4. Italian (in database)
  4: {
    title: "Voucher",
    room: "Camera",
    guest: "Clienti",
    service: "Servizio",
    service_start: "Primo servizio",
    service_end: "Servizio finale",
    message: "VI AUGURIAMO UN BELLO SOGGIORNO"
  }
};
