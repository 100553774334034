// libs
import { createSlice } from "@reduxjs/toolkit";

/**
 * announcement template slice
 */
export const announcementTemplateSlice = createSlice({
  name: "announcementTemplate",
  initialState: {
    templateAnnouncements: [],
    listLanguage: [],
  },
  reducers: {
    setTemplateAnnouncements: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.templateAnnouncements = action.payload;
    },

    setTemplateAnnouncementConstants: (state, action) => {
      if (action.payload.listLanguage) {
        state.listLanguage = action.payload.listLanguage;
      }
    },
  },
});

export const { setTemplateAnnouncements, setTemplateAnnouncementConstants } = announcementTemplateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`
export const selectTemplateAnnouncements = state => state.announcementTemplate.templateAnnouncements;

// languages
export const selectLanguages = state => state.announcementTemplate.listLanguage;

export default announcementTemplateSlice.reducer;
