import React, { Component } from "react";
import { connect } from "react-redux";

class PreviewTemplates extends Component {
  render() {
    const {
      vat_content,
      free_place_content,
      price_validity_content,
      booking_state_content,
      room_list_content,
      program_content,
      content_content,
      program_changes_content,
      payment_content,
      storno_content,
      surcharges_content,
      sojourn_tax_content,
      int,
    } = this.props;

    return (
      <div>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.vat_content}</h4>
          </div>

          <div disabled id="vat_content" className="preview-content-div preview-textarea">
            {vat_content && vat_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.sojourn_tax_content}</h4>
          </div>
          <div disabled id="sojourn_tax_content" className="preview-content-div preview-textarea">
            {sojourn_tax_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.free_place_content}</h4>
          </div>
          <div disabled id="free_place_content" className="preview-content-div preview-textarea">
            {free_place_content.trim()}
          </div>
        </div>

        <div className="preview-content-title preview-content-title-breakpoint">
          <h4>{int.other_conditions}</h4>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.price_validity}</h4>
          </div>
          <div disabled id="price_validity_content" className="preview-content-div preview-textarea">
            {price_validity_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.booking_state}</h4>
          </div>

          <div disabled id="booking_state_content" className="preview-content-div preview-textarea">
            {booking_state_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.room_state}</h4>
          </div>
          <div disabled id="room_list_content" className="preview-content-div preview-textarea">
            {room_list_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.program_content}</h4>
          </div>

          <div disabled id="program_content" className="preview-content-div preview-textarea">
            {program_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.content_content}</h4>
          </div>
          <div disabled id="content_content" className="preview-content-div preview-textarea">
            {content_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.program_changes_content}</h4>
          </div>
          <div disabled id="program_changes_content" className="preview-content-div preview-textarea">
            {program_changes_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.storno}</h4>
          </div>
          <div disabled id="storno_content" className="preview-content-div preview-textarea">
            {storno_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.payment}</h4>
          </div>
          <div disabled id="payment_content" className="preview-content-div preview-textarea">
            {payment_content.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.surcharges.trim()}</h4>
          </div>
          <div disabled id="surcharges_content" className="preview-content-div preview-textarea">
            {surcharges_content.trim()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: {
        vat_content,
        sojourn_tax_content,
        free_place_content,
        price_validity_content,
        surcharges_content,
        payment_content,
        storno_content,
        program_changes_content,
        content_content,
        program_content,
        room_list_content,
        booking_state_content,
      },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    vat_content,
    sojourn_tax_content,
    free_place_content,
    price_validity_content,
    surcharges_content,
    payment_content,
    storno_content,
    program_changes_content,
    content_content,
    program_content,
    room_list_content,
    booking_state_content,
    int,
  };
};

export default connect(mapStateToProps)(PreviewTemplates);
