import { useCallback } from "react";

// libs
import { useSelector, useDispatch } from "react-redux";

// context
import {
  selectAnnouncementOrderCopyState,
  selectAnnouncementOrderEditInputs,
  setAnnouncementOrderEditInputs,
} from "src/views/AnnouncementOrders/announcementOrdersSlice";

/**
 * useEditInput is entry point of data for hotel edit input component
 */
export default function useEditInput(keyOfValue) {
  // redux state
  const announcementOrderCopyState = useSelector(selectAnnouncementOrderCopyState);
  const announcementOrderEditInputs = useSelector(selectAnnouncementOrderEditInputs);

  const dispatch = useDispatch();

  const handleEditMode = useCallback(
    isEdit => {
      dispatch(setAnnouncementOrderEditInputs({ editInputKey: keyOfValue, isEdit: isEdit }));
    },
    [dispatch, keyOfValue],
  );

  let isEdit = announcementOrderEditInputs && announcementOrderEditInputs[keyOfValue];

  return {
    announcementOrderCopyState,
    handleEditMode,
    isEdit,
  };
}
