import React, { Component } from "react";
import { connect } from "react-redux";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import { withRouter } from "react-router-dom";

import AlotmanToolbar from "./Toolbar";
import ReactTableCustom from "../../../components/UI/ReactTable";

import debounce from "lodash/debounce";

import Paper from "material-ui/Paper";
import Button from "@material-ui/core/Button";

import { setAlotmanContractsInitialQueryInput } from "../../../store/actions";
import { DEBOUNCE_FETCH_DELAY } from "../../../utility/reactTable";
import ReservationDrawer from "../AlotmanReservations/ReservationDrawer";
import { listAlotman } from "../../../graphql/query/contract";
import {
  getNestedObject,
  removeDuplicateObjects,
  formatFromFilterDate,
  formatToFilterDate,
} from "../../../utility/globals";
import { formatDate } from "../../../utility/dates";
import { updateAlotmanContract } from "../../../graphql/mutation/contract";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

const getPreparedFilters = (filtersSelected, from_reservation, to_reservation, stop_booking) => {
  const filtersPrepared =
    filtersSelected && filtersSelected.length
      ? Object.assign(...filtersSelected.map(entry => ({ [entry.id]: entry.value })))
      : {};

  let filtersToSend = {};

  // structure object as necessary
  for (let key in filtersPrepared) {
    switch (key) {
      case "partner_client":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            partner_client: { name: filtersPrepared[key] },
          },
        };
        break;
      case "inquiry_external_id":
        filtersToSend = {
          ...filtersToSend,
          offer_contract_document: {
            ...filtersToSend["offer_contract_document"],
            [key]: filtersPrepared[key],
          },
        };
        break;
      default:
        // default case, when key-value pair is added to outermost object
        filtersToSend = {
          ...filtersToSend,
          [key]: filtersPrepared[key],
        };
    }
  }

  filtersToSend = {
    ...filtersToSend,
    ...(typeof stop_booking === "boolean" && { stop_booking }),
    ...(from_reservation && { from_reservation }),
    ...(to_reservation && { to_reservation }),
  };

  return JSON.stringify(filtersToSend);
};

const mapStateToProps = state => {
  const { limit, offset, selectedFilters, fromReservation, toReservation, stopBooking } = state.alotman;

  return {
    limit,
    offset,
    selectedFilters,
    fromReservation,
    toReservation,
    stopBooking,
  };
};

export default withLocalStorageHOC(
  connect(mapStateToProps, { setAlotmanContractsInitialQueryInput })(
    compose(
      graphql(listAlotman, {
        skip: ({ limit, offset }) => {
          return !limit && !offset;
        },
        options: ({ limit, offset, selectedFilters, fromReservation, toReservation, stopBooking }) => ({
          fetchPolicy: "network-only",
          variables: {
            // This is default query on component mount
            input: {
              paginationLimit: {
                limit: limit,
                offset: offset,
              },
              where: getPreparedFilters(selectedFilters, fromReservation, toReservation, stopBooking),
            },
            alotman: true,
          },
        }),
      }),
      graphql(updateAlotmanContract),
    )(
      withRouter(
        class AlotmanContracts extends Component {
          state = {
            pages: null,
            loading: true,
            from_reservation: this.props.tableState.from_reservation || null,
            to_reservation: this.props.tableState.to_reservation || null,
            stop_booking:
              typeof this.props.tableState.stop_booking === "boolean" ? this.props.tableState.stop_booking : "",
            drawer: false,
            alotman_contract: null,
            columns: [
              {
                Header: "Broj ugovora",
                width: 90,
                accessor: "external_id",
                Cell: row => {
                  return (
                    <div
                      className="ReactTableFirstColumnDiv"
                      onClick={() => this.props.history.push(`/ugovor/edit/${row.original.id}`)}
                    >
                      {row.original.external_id || ""}
                    </div>
                  );
                },
              },
              {
                Header: "Agencija",
                accessor: "partner_client",
              },
              {
                Header: "Hotel",
                accessor: "establishment",
              },
              {
                Header: "Kontaktirano",
                accessor: "alotman_contacted",
                filterable: false,
                Cell: (row, onChange) => {
                  return (
                    <div className="print-span-container">
                      <select
                        className="booking-select "
                        onChange={event =>
                          this.props.mutate({
                            variables: {
                              id: row.original.id,
                              patch: {
                                alotman_contacted: event.target.value === "true",
                              },
                            },
                          })
                        }
                        value={row && row.value ? row.value : false}
                      >
                        <option value={true}>Da</option>
                        <option value={false}>Ne</option>
                      </select>
                      <span className="select-print" style={{ display: "none" }}>
                        {row.value ? "Da" : "Ne"}
                      </span>
                    </div>
                  );
                },
                width: 203,
              },
              {
                Cell: row => {
                  return (
                    <Button variant="outlined" size="small" onClick={() => this.toggleDrawer(row.original)}>
                      Rezervirati
                    </Button>
                  );
                },
                filterable: false,
              },
            ],
          };

          prepareDataForReservation = contract => {};

          toggleDrawer = (alotman_contract = null) => {
            this.setState(prevState => {
              return {
                drawer: !prevState.drawer,
                alotman_contract,
              };
            });
          };

          _isMounted = false;

          componentDidMount() {
            this._isMounted = true;

            // set initial query params so our graphql HOC function does not get called every time on filtering
            const limit = this.props.tableState.pageSizeSelected;
            const offset = this.props.tableState.pageSelected * this.props.tableState.pageSizeSelected;

            this.props.setAlotmanContractsInitialQueryInput(
              limit,
              offset,
              this.props.tableState.filtersSelected,
              this.props.tableState.from_reservation,
              this.props.tableState.to_reservation,
              this.props.tableState.stop_booking,
            );
          }

          componentWillUnmount() {
            this._isMounted = false;
          }

          // called when Stop Booking - DA / NE select changes
          // or on Date from to change
          selectBookingChange = value => {
            this.props.setTableState({ ...this.props.tableState, stop_booking: value });

            this.setState({ stop_booking: value, loading: true }, this.applyFiltersAndFetch);
          };

          changeFilterDate = key => value => {
            let date;

            if (!value) {
              date = value;
            } else {
              date = key === "from_reservation" ? formatFromFilterDate(value) : formatToFilterDate(value);
            }

            this.props.setTableState({ ...this.props.tableState, [key]: date });
            this.setState({ [key]: date, loading: true }, this.applyFiltersAndFetch);
          };

          // react table's function on a change
          fetchData = async state => {
            // we've arrived either debounced or not, so filtering can be reset
            this.filtering = false;

            // set new state when ReactTable changes and query with new changes
            this.setState(
              {
                loading: true,
              },
              this.applyFiltersAndFetch,
            );
          };

          filtering = false;

          fetchData = this.fetchData.bind(this);
          // ^ debounced version of "fetchData"
          fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

          fetchStrategy = state => {
            if (this.filtering) {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
              return this.fetchDataWithDebounce(state);
            } else {
              return this.fetchData(state);
            }
          };

          onFilteredChange = () => {
            this.filtering = true; // when the filter changes, that means someone is typing
          };

          // our custom function, called whenever react table changes or any of filters not directly in react table
          applyFiltersAndFetch = async () => {
            const {
              pageSelected,
              pageSizeSelected,
              filtersSelected,
              stop_booking,
              from_reservation,
              to_reservation,
            } = this.props.tableState;

            /* end of filters */
            // Setting new object in fetchMore, so that we could reuse it in subscription
            this.fetchMoreObject = {
              variables: {
                input: {
                  paginationLimit: {
                    limit: pageSizeSelected,
                    offset: pageSelected * pageSizeSelected,
                  },
                  ...((filtersSelected || from_reservation || to_reservation || stop_booking) && {
                    where: getPreparedFilters(filtersSelected, from_reservation, to_reservation, stop_booking),
                  }),
                },
                alotman: true,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult;
                }

                if (this._isMounted) {
                  if (fetchMoreResult && fetchMoreResult.listContract && fetchMoreResult.listContract.length) {
                    // when we get normal reponse, ceil up page number
                    this.setState({
                      pages: Math.ceil(fetchMoreResult.listContract[0].count / pageSizeSelected),
                      loading: false,
                    });
                  }
                  // when query returns empty array (no results for those filters)
                  else {
                    this.setState({
                      pages: null,
                      loading: false,
                    });
                  }

                  return {
                    previousResult,
                    listContract: [...fetchMoreResult.listContract],
                  };
                }

                return previousResult;
              },
            };

            if (this._isMounted) {
              await this.props.data.fetchMore(this.fetchMoreObject);
            }
          };

          updateCheck = key => {
            this.setState(oldState => {
              return {
                ...oldState,
                filterOptions: {
                  ...oldState.filterOptions,
                  [key]: !oldState.filterOptions[key],
                },
              };
            });
          };

          dataToShow = () => {
            if (!this.props.data) {
              return [];
            }
            const { listContract } = this.props.data;

            return (listContract && listContract.map(contract => this.formatContractForReactTable(contract))) || [];
          };

          formatContractForReactTable(contract) {
            const { termin_periods } = contract.offer_contract_document;

            let typologies = [];

            termin_periods.forEach(({ contigents }) => {
              contigents.forEach(({ typology }) => {
                typologies.push(typology);
              });
            });

            typologies = removeDuplicateObjects(typologies);

            let hotels = typologies.map(({ establishment }) => establishment);

            hotels = removeDuplicateObjects(hotels);

            let establishment = "";
            hotels.forEach(({ name }) => {
              establishment += name;
            });

            return {
              ...contract,
              //inquiry_id: contract.offer_contract_document.inquiry_id,
              // inquiry_external_id: contract.offer_contract_document.inquiry_external_id,
              partner_client: contract.offer_contract_document.partner_client.name,
              typologies,
              establishment,
              user:
                getNestedObject(contract, ["last_edit_by_user", "username"]) +
                ", " +
                formatDate(getNestedObject(contract, ["last_edit_by_user_time"])),
            };
          }

          render() {
            return (
              <div ref={el => (this.componentRefInquiry = el)}>
                <AlotmanToolbar
                  {...this.state}
                  updateCheck={this.updateCheck}
                  changeFilterDate={this.changeFilterDate}
                  selectBookingChange={this.selectBookingChange}
                  contentToPrint={() => this.componentRefInquiry}
                />
                <Paper zDepth={1}>
                  <ReactTableCustom
                    data={this.dataToShow()}
                    columns={this.state.columns}
                    renderCustomPagination={true}
                    innerProps={{
                      onFetchData: this.fetchStrategy,
                      onFilteredChange: this.onFilteredChange,

                      onPageChange: page => {
                        this.props.setTableState({ ...this.props.tableState, pageSelected: page });
                      },

                      onPageSizeChange: (pageSize, pageIndex) => {
                        this.props.setTableState({
                          ...this.props.tableState,
                          pageSelected: pageIndex,
                          pageSizeSelected: pageSize,
                        });
                      },

                      page: this.props.tableState.pageSelected,
                      pageSize: this.props.tableState.pageSizeSelected,
                      defaultFiltered: this.props.tableState.filtersSelected,

                      loading: this.state.loading,
                      pages: this.state.pages,
                      manual: true,
                      sortable: false,
                    }}
                  />
                </Paper>
                <ReservationDrawer
                  open={this.state.drawer}
                  toggle={this.toggleDrawer}
                  create
                  alotman_contract={this.state.alotman_contract}
                  title="Nova rezervacija"
                />
              </div>
            );
          }
        },
      ),
    ),
  ),
);
