import React, { Component } from "react";

import { connect } from "react-redux";
import { config } from "../../../../utility/globals";

class DailyProgramUsage extends Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { termin_periods, program_days, offer_contract_document_type_id } = this.props;

    if (
      config.offer_type[offer_contract_document_type_id] !== "Program only" &&
      (!termin_periods || !termin_periods.length)
    ) {
      return null;
    }

    if (!program_days) {
      return null;
    }

    const { children } = this.props;
    const ui =
      typeof children === "function"
        ? children(this.props)
        : React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
              ...this.props,
            }),
          );

    return ui;
  }
}

const mapStateToProps = state => {
  const {
    program_days,
    offer,
    offer: {
      offer_contract_document: { language_id, termin_periods, offer_contract_document_type_id },
    },
  } = state.offer;

  return {
    offer_id: offer.id,
    program_days,
    language_id,
    termin_periods,
    offer_contract_document_type_id,
  };
};

export default connect(mapStateToProps)(DailyProgramUsage);
