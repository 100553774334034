import React from "react";

// hooks
import useGuideComment from "./hooks/useGuideComment";
// components
import TextFieldMultiline from "src/components/UI/TextFieldMultilineUncontrolled";
// style
import "./style.css";
// constants
import { TITLE_GUIDE_COMMENT, HINT_GUIDE_COMMENT } from "src/utility/labels";

/**
 * used for rendering GuideComment input value
 */
export default function GuideComment() {
  // hook
  const { guideNotices, onGuideNoticesChange } = useGuideComment();

  // return root
  return (
    <div>
      <label className="label">{TITLE_GUIDE_COMMENT}</label>

      <TextFieldMultiline
        rows={4}
        defaultValue={guideNotices}
        placeholder={HINT_GUIDE_COMMENT}
        onChange={event => onGuideNoticesChange(event)}
      />
    </div>
  );
}
