import React, { Component, Fragment } from "react";
import { FormControl, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateBookingState extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`booking_state_content`).length) {
      autosize(document.getElementsByName(`booking_state_content`)[0]);
    }
  }

  render() {
    const { isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Stanje bookinga</label>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="notification_booking_state">Podsjetnik za booking</InputLabel>
            <Input
              id="notification_booking_state"
              className="template-input"
              inputProps={{
                name: "notification_booking_state",
              }}
              value={this.props.notification_booking_state}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
          </FormControl>
          <div>
            <TextArea
              className="template-input"
              inputProps={{
                name: "booking_state_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.booking_state_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TemplateBookingState;
