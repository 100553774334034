import React from "react";

// libs
import { get } from "lodash";
import moment from "moment";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_VALUE, MARGIN_PAGE } from "../utils/constants";

// components
import HeaderVoucher from "../Header";
import VoucherSubjectMultiline from "../SubjectMultiline";
import VoucherTitle from "../Title";
import DateLabel from "../Date";
import VoucherTextField from "../TextField";
import VoucherUserDetails from "../UserDetails";
import VoucherSignature from "../Signature";
import VoucherFooter from "../Footer";
import VoucherFullWidthText from "../FullWidthText";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: MARGIN_PAGE,
    flexGrow: 1,
  },
});

/**
 * VoucherGuideForeignAgency
 */
export default function VoucherGuideForeignAgency({ voucher }) {
  // vocuher data from announcement
  const voucherData = get(voucher, "voucher_data", {});

  // voucher title
  const voucherTitle = get(voucher, "voucher_title", "");

  // date created
  const dateCreated = moment(get(voucher, "date", "")).format("DD.MM.YYYY");

  // group
  const groupLabel = get(voucherData, "group.label", "");
  const groupValue = get(voucherData, "group.value", "");

  // termin
  const terminLabel = get(voucherData, "termin.label", "");
  const terminValue = get(voucherData, "termin.value", "");

  // termin
  const numberOfPersonsLabel = get(voucherData, "nr_of_persons.label", "");
  const numberOfPersonsValue = get(voucherData, "nr_of_persons.value", "");

  // stamp
  const voucherStamp = get(voucher, "voucher_signature_text", "");

  // payment text
  const voucherPaymentText = get(voucher, "voucher_payment_text", "");

  // voucher created text
  const voucherCreatedByText = get(voucher, "voucher_created_text", "");

  // voucher user details
  const voucherUserFirstName = get(voucher, "user_first_name", "");
  const voucherUserLastName = get(voucher, "user_last_name", "");

  // voucher signature text
  const voucherSignatureText = get(voucher, "voucher_signature_text", "");

  // footer
  const voucherFooter = get(voucher, "voucher_footer_text", "");

  // return root
  return (
    <Document title={`${voucherTitle}-${dateCreated}`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/** header */}
          <HeaderVoucher />

          {/** subject */}
          <VoucherSubjectMultiline
            subjectTextValues={["Stadtführung in ZAGREB", "Kontakt: Frau IDA / 00385 – 91 – 243 – 4128"]}
          />

          {/** created date */}
          <DateLabel value={dateCreated} />

          {/** title */}
          <VoucherTitle title={voucherTitle} />

          {/** group name */}
          <VoucherTextField label={groupLabel} value={groupValue} />

          {/** termin */}
          <VoucherTextField label={terminLabel} value={terminValue} />

          {/** leistung */}
          <VoucherTextField
            label={"leistung"}
            value={
              "Stadtrundfahrt und Stadtführung in Zagreb für max. 2.5 Stunden Alle eventuellen Eintrittsgelder sind VOR ORT zu entrichten"
            }
          />

          {/** number of persons */}
          <VoucherTextField label={numberOfPersonsLabel} value={numberOfPersonsValue} />

          {/** voucher validation */}
          <VoucherFullWidthText
            text={voucherStamp}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSans" }}
          />

          {/** voucher payment */}
          <VoucherFullWidthText
            text={voucherPaymentText}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSansBold", textDecoration: "underline" }}
          />

          {/** user details */}
          <VoucherUserDetails
            createdByText={voucherCreatedByText}
            firstName={voucherUserFirstName}
            lastName={voucherUserLastName}
          />

          {/** signature */}
          <VoucherSignature signatureText={voucherSignatureText} />

          <VoucherFooter footerText={voucherFooter} />
        </View>
      </Page>
    </Document>
  );
}
