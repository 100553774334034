import React, { Fragment, useCallback } from "react";

// libs
import { get } from "lodash";

// ui
import classNames from "classnames";
import { Button, Card, CardActions, CardContent, Grid, Typography, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CopyIcon from "@material-ui/icons/ContentCopy";

// hooks
import useAnnouncementForm from "./hooks/useAnnouncementForm";

// constants
import {
  HOTEL_GUIDE_ANNOUNCEMENT,
  HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GUIDE_ANNOUNCEMENT,
  GUIDE_TOUR_ANNOUNCEMENT,
  AGENCY_ANNOUNCEMENT,
  AGENCY_TOUR_ANNOUNCEMENT,
  AGENCY_LOCAL_ANNOUNCEMENT,
  AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  SUPPLIER_ANNOUNCEMENT,
  PHRASE_ANNOUNCEMENT,
  GUIDE_LOCAL_ANNOUNCEMENT,
} from "src/utility/constants";
import { LABEL_NO_ANNOUNCEMENT_CREATED } from "src/utility/labels";

// component
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";
import HotelGuideAnnouncement from "./components/HotelGuideAnnouncement";
import HotelNoGuideAnnouncement from "./components/HotelNoGuideAnnouncement";
import GuideAnnouncement from "./components/GuideAnnouncement";
import GuideTourAnnouncement from "./components/GuideTourAnnouncement";
import GuideLocalAnnouncement from "./components/GuideLocalAnnouncement";
import AgencyAnnouncement from "./components/AgencyAnnouncement";
import AgencyTourAnnouncement from "./components/AgencyTourAnnouncement";
import AgencyLocalAnnouncement from "./components/AgencyLocalAnnouncement";
import AgencyLocalProgramAnnouncement from "./components/AgencyLocalProgramAnnouncement";
import SupplierAnnouncement from "./components/SupplierAnnouncement";
import PhraseAnnouncement from "./components/PhraseAnnouncement";

// style
const styles = theme => ({
  content: {
    maxWidth: 1080,
    padding: 32,
  },

  containerLoading: {
    marginTop: 18,
  },

  intro: {
    marginTop: 24,
  },

  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

/**
 * AnnouncementForm
 */
const AnnouncementForm = ({ classes, announcementSelected, isVoucherCreateVisible }) => {
  const { copyElementRtfToClipboard, loading, onCreateVoucherClick } = useAnnouncementForm(announcementSelected);

  // loading component
  const renderLoadingView = useCallback(() => {
    return (
      <Grid className={classes.containerLoading} container spacing={8} alignItems="center" justify="flex-start">
        <Grid item>
          <FixedCircularProgress color="inherit" size={16} thickness={6} />
        </Grid>

        <Grid item>
          <Typography variant="body1">Dohvaćam podatke</Typography>
        </Grid>
      </Grid>
    );
  }, [classes.containerLoading]);

  // render announcement form

  // announcementView
  const renderAnnouncementView = useCallback(() => {
    switch (get(announcementSelected, "announcement_type")) {
      case HOTEL_GUIDE_ANNOUNCEMENT:
        return (
          <Fragment>
            <HotelGuideAnnouncement />
          </Fragment>
        );

      case HOTEL_NO_GUIDE_ANNOUNCEMENT:
        return (
          <Fragment>
            <HotelNoGuideAnnouncement />
          </Fragment>
        );

      case GUIDE_ANNOUNCEMENT:
        return (
          <Fragment>
            <GuideAnnouncement />
          </Fragment>
        );

      case GUIDE_TOUR_ANNOUNCEMENT:
        return (
          <Fragment>
            <GuideTourAnnouncement />
          </Fragment>
        );

      case GUIDE_LOCAL_ANNOUNCEMENT:
        return (
          <Fragment>
            <GuideLocalAnnouncement />
          </Fragment>
        );

      case AGENCY_ANNOUNCEMENT:
        return (
          <Fragment>
            <AgencyAnnouncement />
          </Fragment>
        );

      case AGENCY_TOUR_ANNOUNCEMENT:
        return (
          <Fragment>
            <AgencyTourAnnouncement />
          </Fragment>
        );

      case AGENCY_LOCAL_ANNOUNCEMENT:
        return (
          <Fragment>
            <AgencyLocalAnnouncement />
          </Fragment>
        );
      case AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT:
        return (
          <Fragment>
            <AgencyLocalProgramAnnouncement />
          </Fragment>
        );

      case SUPPLIER_ANNOUNCEMENT:
        return (
          <Fragment>
            <SupplierAnnouncement />
          </Fragment>
        );

      case PHRASE_ANNOUNCEMENT:
        return (
          <Fragment>
            <PhraseAnnouncement />
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <Typography variant="body1">{LABEL_NO_ANNOUNCEMENT_CREATED}</Typography>
          </Fragment>
        );
    }
  }, [announcementSelected]);

  // root
  return (
    <Card>
      {loading ? (
        <CardContent>{renderLoadingView()}</CardContent>
      ) : (
        <Fragment>
          <CardContent id="announcement" className={classes.content}>
            <div>{renderAnnouncementView()}</div>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              size="small"
              className={classes.button}
              disabled={!announcementSelected}
              onClick={() => {
                copyElementRtfToClipboard("announcement");
              }}
            >
              <CopyIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
              Kopiraj najavu
            </Button>

            {isVoucherCreateVisible && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
                disabled={!announcementSelected}
                onClick={onCreateVoucherClick}
              >
                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Kreiraj voucher
              </Button>
            )}
          </CardActions>
        </Fragment>
      )}
    </Card>
  );
};

export default withStyles(styles)(AnnouncementForm);
