import React, { Component } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import debounce from "lodash/debounce";

import moment from "moment";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import AutocompleteSelect from "../../../components/UI/AutocompleteSelect";
import MonthlyTable from "./components/MonthlyTable";

import AddTermin from "./components/AddTermin";
import Drawer from "../../../components/UI/Drawer";

import { listEstablishment, getEstablishmentCalendar } from "../../../graphql/query/alotman";
import UpdateBooking from "./components/UpdateBooking";
import { formatFromFilterDate, formatToFilterDate } from "../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";
import get from "lodash/get";

class Alotman extends Component {
  state = {
    firstTableDate: formatFromFilterDate().startOf("M"),
    secondTableDate: formatToFilterDate()
      .add(1, "months")
      .endOf("M"),
    from: undefined,
    to: undefined,
    date: undefined,
    establishment_id: null,
    establishment: null,
    typology_id: null,
    establishment_typologies: [],
    offer_contract_document_id: "2",
    isTerminDialogOpen: false,
    isStopBookingOpen: false,
    isAvailableStateOpen: false,
    loading: false,
    fetchingMore: false,
  };

  openAddTerminDialog = () => {
    this.setState({ isTerminDialogOpen: true }, this.handleStopBookingClose);
  };

  handleTerminDialogClose = () => {
    this.setState({ isTerminDialogOpen: false });
  };

  openStopBookingDialog = () => {
    this.setState({ isStopBookingOpen: true }, this.handleTerminDialogClose);
  };

  handleStopBookingClose = () => {
    this.setState({ isStopBookingOpen: false });
  };

  openAvailableStateDialog = () => {
    this.setState({ isAvailableStateOpen: true }, this.handleTerminDialogClose);
  };

  handleAvailableStateClose = () => {
    this.setState({ isAvailableStateOpen: false });
  };

  establishmentPick = value => {
    const { listEstablishment } = this.props.listEstablishmentQuery;

    this.setState(
      {
        establishment_id: value,
        loading: true,
        // check for when user clears value with 'x' icon on AutocompleteSelect, set it to empty array
        establishment_typologies: value ? listEstablishment.find(entry => entry.id === value).typologies : [],
        establishment: value ? listEstablishment.find(entry => entry.id === value) : null,
      },
      this.fetchEstablishmentDayCalendar,
    );
  };

  selectMonthChange = (notUsedButDontDeleteBecauseItHasToBeHere, selectedYear, selectedMonth) => {
    const date = moment().set({
      year: selectedYear,
      month: selectedMonth,
    });

    this.setState(
      {
        firstTableDate: formatFromFilterDate(date).startOf("M"),
        secondTableDate: formatToFilterDate(date)
          .add(1, "months")
          .endOf("M"),
      },
      this.fetchEstablishmentDayCalendar,
    );
  };

  fetchEstablishmentDayCalendar = async () => {
    try {
      if (this.state.establishment_id) {
        const response = await this.props.client.query({
          query: getEstablishmentCalendar,
          variables: {
            establishment_id: this.state.establishment_id,
            from: this.state.firstTableDate,
            to: this.state.secondTableDate,
          },
          fetchPolicy: "network-only",
        });

        const establishmentDays = response.data.getAlotmanCalendar;

        this.setState({ alotman_days: establishmentDays && establishmentDays.alotman_days, loading: false });
      } else {
        this.setState({ alotman_days: null, loading: false });
      }
    } catch (error) {}
  };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  where = {};

  handleAutocompleteInputChange = value => {
    if (this.where.name === value) {
      return;
    }

    this.where = { name: value };

    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: JSON.stringify(this.where),
      },
    };

    this.props.listEstablishmentQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listEstablishment.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listEstablishment: [...fetchMoreResult.listEstablishment],
        };
      },
    });
  };

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listEstablishment &&
      listEstablishment.length >= PAGINATION_LIMIT
    ) {
      this.setState({ fetchingMore: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listEstablishmentQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listEstablishment.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listEstablishment: [...previousResult.listEstablishment, ...fetchMoreResult.listEstablishment],
            };
          },
        });

        this.setState({ fetchingMore: false });
      });
    }
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  render() {
    const {
      establishment,
      establishment_id,
      establishment_typologies,
      firstTableDate,
      secondTableDate,
      alotman_days,
    } = this.state;

    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);
    const loading = get(this.props, "listEstablishmentQuery.loading");

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    if (loading && !listEstablishment) {
      return <div>Loading ...</div>;
    }

    return (
      <Paper style={{ padding: "2em" }}>
        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <AutocompleteSelect
              label="Smještaj"
              defaultValue={establishment_id || ""}
              autocompleteHandler={this.establishmentPick}
              dataSource={
                listEstablishment &&
                listEstablishment.map(type => ({
                  value: type.id,
                  label: `${type.name} - ${type.city ? type.city.name : ""}`,
                }))
              }
              onMenuScrollToBottom={this.handleScroller}
              isLoading={this.state.fetchingMore}
              placeholder="Odabir smještaja"
              inputProps={{
                onInputChange: this.handleAutocompleteInputChangeDebounced,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={24}>
              {establishment_id ? (
                <React.Fragment>
                  <Grid item>
                    <Button variant="contained" onClick={this.openAddTerminDialog}>
                      Fix kontigent termini
                    </Button>
                    <Drawer title="Termini" open={this.state.isTerminDialogOpen} toggle={this.handleTerminDialogClose}>
                      <AddTermin
                        establishment={establishment}
                        establishment_id={establishment_id}
                        establishment_typologies={establishment_typologies}
                        onAdd={this.fetchEstablishmentDayCalendar}
                      />
                    </Drawer>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={this.openStopBookingDialog}>
                      Stop booking
                    </Button>
                    <Drawer
                      title="Stop boking"
                      open={this.state.isStopBookingOpen}
                      toggle={this.handleStopBookingClose}
                    >
                      <UpdateBooking
                        updateStopBooking
                        toggle={this.handleStopBookingClose}
                        establishment={establishment}
                        establishment_id={establishment_id}
                        establishment_typologies={establishment_typologies}
                        onUpdate={this.fetchEstablishmentDayCalendar}
                      />
                    </Drawer>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={this.openAvailableStateDialog}>
                      Ažuriraj stanje
                    </Button>
                    <Drawer
                      title="Ažuriraj stanje"
                      open={this.state.isAvailableStateOpen}
                      toggle={this.handleAvailableStateClose}
                    >
                      <UpdateBooking
                        updateAvailableState
                        toggle={this.handleAvailableStateClose}
                        establishment={establishment}
                        establishment_id={establishment_id}
                        establishment_typologies={establishment_typologies}
                        onUpdate={this.fetchEstablishmentDayCalendar}
                      />
                    </Drawer>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>

          {this.state.loading && (
            <div
              style={{
                flexGrow: 1,
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "30px",
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}

          {establishment_id && !this.state.loading ? (
            <React.Fragment>
              <Grid item xs={12}>
                <MonthPickerInput
                  year={moment(firstTableDate).get("year")}
                  month={moment(firstTableDate).get("month")}
                  closeOnSelect={true}
                  inputProps={{ id: "alotman_month_picker", autoComplete: "off" }}
                  onChange={this.selectMonthChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MonthlyTable
                  establishment_id={establishment_id}
                  alotman_days={alotman_days}
                  typologies={establishment_typologies}
                  month={firstTableDate.format("MM/YYYY")}
                />
              </Grid>
              <Grid item xs={12}>
                <MonthlyTable
                  establishment_id={establishment_id}
                  alotman_days={alotman_days}
                  typologies={establishment_typologies}
                  month={secondTableDate.format("MM/YYYY")}
                />
              </Grid>
            </React.Fragment>
          ) : (
            !this.state.loading && (
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginTop: "50px",
                    marginBottom: "30px",
                    textAlign: "center",
                  }}
                  variant="headline"
                  gutterBottom
                >
                  Odaberite smještaj
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </Paper>
    );
  }
}

export default compose(
  graphql(listEstablishment, {
    name: "listEstablishmentQuery",
    options: {
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
        },
      },
    },
  }),
)(withApollo(Alotman));
