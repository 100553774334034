import React from "react";
import ReactToPrint from "react-to-print";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { blue500, grey900, fullWhite } from "material-ui/styles/colors";

import RaisedButton from "material-ui/RaisedButton";

import AddIcon from "material-ui/svg-icons/content/add-circle";
import { Link } from "react-router-dom";

class AllInquiriesToolbar extends React.Component {
  render() {
    return (
      <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
        <ToolbarGroup>
          <ToolbarTitle
            text="Upiti"
            style={{
              color: grey900
            }}
          />
          <Link to="/upit/create">
            <AddIcon color={blue500} style={{ cursor: "pointer" }} />
          </Link>
        </ToolbarGroup>
        <ToolbarGroup>
          <ReactToPrint
            pageStyle={`
            @media print{
                        @page {
                          size: landscape;
                        }
                        .ReactTable .-pagination{
                          height:50px
                        }
                      }`}
            trigger={() => (
              <RaisedButton
                label="Print"
                backgroundColor={blue500}
                labelColor={fullWhite}
              />
            )}
            content={() => this.props.contentToPrint()}
          />
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

export default AllInquiriesToolbar;
