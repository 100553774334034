import React from "react";

// ui
import { Grid, withStyles } from "@material-ui/core";

// hooks
import useAnnouncementEditContainer from "./hooks/useAnnouncementEditContainer";

// components
import AnnouncementList from "../List";
import AnnouncementForm from "../EditForm/AnnouncementForm";
import SupplierDetails from "../SupplierDetails";
import TypeSelect from "../TypeSelect";
import VoucherList from "../VoucherList";
import VoucherCreate from "../VoucherCreate";

const styles = {
  root: {
    padding: 15,
  },
};

/**
 * AnnouncementEditContainer
 */
const AnnouncementEditContainer = ({ classes }) => {
  // hooks
  const { announcementSelected } = useAnnouncementEditContainer();

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <SupplierDetails />
          <AnnouncementList />
          <VoucherList announcementSelected={announcementSelected} />
        </Grid>

        <Grid item xs={8}>
          <AnnouncementForm announcementSelected={announcementSelected} isVoucherCreateVisible={true} />
        </Grid>
      </Grid>
      <VoucherCreate />
      <TypeSelect />
    </div>
  );
};

export default withStyles(styles)(AnnouncementEditContainer);
