import gql from "graphql-tag";

// fragments
import { guidesFragment } from "../operativeFragments";

// get columns for guides layout
export const GUIDES_GRID_FILTERS = gql`
  query {
    listDatagridFilter(datagrid_filter_type_id: 6, on_user: true) {
      #list for booking
      id
      desc
      column
      active
      datagrid_filter_type {
        desc
      }
    }
  }
`;

export const GUIDE_ENUM_VALUES = gql`
  query($name: String!) {
    __type(name: $name) {
      enumValues {
        name
        description
      }
    }
  }
`;

// all guides query, fetching list of all guides
export const ALL_GUIDES = gql`
  query($input: GuideLayoutConditionInput) {
    listGuideLayoutsByContracts(input: $input) {
      total_table_count
      program_id
      contract_status
      external_id
      partner_name
      from
      to
      pax
      guide
      driver
      transport
      nr_of_buses
      theme_name
      guide_notices
      guide_layout_comment
      guide_services
      group_status
      group_operativa_status
      booking_status
      booking_state
      guides {
        guide_id
        guide_first_name
        guide_last_name
        dates
      }
      establishment_names {
        establishment_id
        establishment_name
      }
      termin_period_contigents {
        from
        to
        contigents {
          typologies_sum
          sold
          persons_capacity
          establishment_name
        }
      }
      program_days {
        program_day_id
        title
        date
        prefix
      }
      payment
      catalog
      dictate_id
      has_dictate
    }
  }
`;

export const PROGRAM_DAY_ACTIVITIES = gql`
  query($program_id: ID!) {
    getProgramDayActivityPartnerGuideLayout(program_id: $program_id) {
      program_id
      offer_contract_document_id
      theme_name
      guide_layout_comment
      guide_notices
      termin_period_from
      termin_period_to
      program_days {
        program_day_id
        title
        date
        prefix
        program_day_contents {
          program_day_content_id
          activity_id
          activity_desc
          partner_client_id
          partner_client_name
          partner_client_address
          guide_id
          guide_first_name
          guide_last_name
          guide_phone
          guide_email
          is_active
        }
      }
      guides {
        ...Guides
      }
    }
  }
  ${guidesFragment}
`;

export const GET_PARTNER_CLIENT = gql`
  query($id: ID!) {
    getPartnerClient(id: $id) {
      id
      name
      last_name

      partner_client_types {
        desc
      }

      contacts {
        id
        name
        first_name
        last_name
        phone
        email
        partner_client_id
      }
    }
  }
`;

export const LIST_PARTNER_CLIENT = gql`
  query($input: Condition) {
    listPartnerClient(input: $input) {
      id
      name
      last_name

      partner_client_types {
        desc
      }

      contacts {
        id
        name
        first_name
        last_name
        phone
        email
        partner_client_id
      }
    }
  }
`;

export const LIST_ACTIVITIES = gql`
  query {
    listActivity {
      id
      default_price
      activity_type {
        id
        desc
        code
      }
      activity_translations {
        id
        name
        desc
        language_id
      }
    }
  }
`;
