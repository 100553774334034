import React from "react";

// hooks
import useCatalog from "./hooks/useCatalog";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import CatalogContainer from "./components/Container";

// constants
import { TITLE_GUIDE_CATALOG } from "src/utility/labels";

/**
 * Catalog drawer is used for showing data of catalog
 */
export default function Catalog() {
  const { handleOnCatalogTextChange, isDrawerOpen, loading, onCloseDrawer } = useCatalog();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnCatalogTextChange}
      title={TITLE_GUIDE_CATALOG}
      customWidth="85%"
    >
      <CatalogContainer />
    </DrawerWrapper>
  );
}
