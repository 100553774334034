import React from "react";
// hooks
import useGuideList from "./hooks/useGuideList";
// ui
import { Grid, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
// constants
import { TITLE_GUIDE_SHOW_LIST } from "src/utility/labels";
// components
import ReactTableCustom from "src/components/UI/ReactTable";
import guideShowColumns from "./components/Columns/GuideShowColumns";

// style
import "./style.css";

/**
 * GuideList shows program connected guides
 */
export default function GuideList() {
  const {
    listGuides,
    getGuideShowTdProps,
    getGuideShowTrProps,
    guideIdSelected,
    handleOnGuideFilterClear,
  } = useGuideList();

  // return root
  return (
    <div>
      <Grid container alignContent="center" alignItems="center">
        <Grid item xs={11}>
          <label className="label labelGuideShow">{TITLE_GUIDE_SHOW_LIST}</label>
        </Grid>

        <Grid item xs={1}>
          <IconButton
            color="primary"
            disabled={!guideIdSelected}
            onClick={handleOnGuideFilterClear}
            aria-label="Izbriši filter"
          >
            <ClearIcon size={16} />
          </IconButton>
        </Grid>
      </Grid>

      <ReactTableCustom
        data={listGuides}
        columns={guideShowColumns}
        innerProps={{
          minRows: 0,
          showPagination: false,
          filterable: false,
          manual: true,
          sortable: false,
          getTdProps: getGuideShowTdProps,
          getTrProps: getGuideShowTrProps,
        }}
      />
    </div>
  );
}
