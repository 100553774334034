import React from "react";

// libs
import { get } from "lodash";

// ui
import { Card, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// hooks
import useSupplierDetails from "./hooks/useSupplierDetails";

// style
const styles = {
  card: {
    marginBottom: 16,
  },

  caption: {
    marginTop: 16,
  },
};

/**
 * SupplierDetails
 */
const SupplierDetails = ({ classes }) => {
  const { supplier } = useSupplierDetails();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="headline">Dobavljač</Typography>

        <Typography className={classes.caption} variant="caption">
          Partner / hotel
        </Typography>

        <Typography variant="subheading">{get(supplier, "partner", "")}</Typography>

        <Typography className={classes.caption} variant="caption">
          Tip partnera
        </Typography>

        <Typography variant="subheading">{get(supplier, "partner_type", "")}</Typography>

        <Typography className={classes.caption} variant="caption">
          Vodič
        </Typography>

        <Typography variant="subheading">
          {supplier.guide_first_name || supplier.guide_last_name
            ? `${get(supplier, "guide_first_name", "")} ${get(supplier, "guide_last_name", "")}`
            : "-"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SupplierDetails);
