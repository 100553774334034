import gql from "graphql-tag";
import { attachmentFragment } from "../fragments";

export const createUser = gql`
  mutation(
    $first_name: String!
    $last_name: String!
    $username: String!
    $password: String!
    $signature_url: String
    $role_ids: [ID!]!
  ) {
    createUser(
      input: {
        first_name: $first_name
        last_name: $last_name
        username: $username
        password: $password
        signature_url: $signature_url
        role_ids: $role_ids
      }
    ) {
      id
      first_name
      last_name
      username
      signature_url
      roles {
        id
        desc
        code
      }
    }
  }
`;

export const updateUser = gql`
  mutation($id: ID!, $patch: UpdateUser!) {
    updateUser(input: { id: $id, patch: $patch }) {
      id
      first_name
      last_name
      username
      signature_url
      role_id
    }
  }
`;

export const appendRoleToUser = gql`
  mutation($user_id: ID!, $role_id: ID!) {
    appendRoleToUser(user_id: $user_id, role_id: $role_id) {
      id
      first_name
    }
  }
`;

export const removeRoleFromUser = gql`
  mutation($user_id: ID!, $role_id: ID!) {
    removeRoleFromUser(user_id: $user_id, role_id: $role_id) {
      id
      first_name
    }
  }
`;

export const userSignatureUpload = gql`
  mutation($user_id: ID!, $file: Upload!) {
    userSignatureUpload(user_id: $user_id, file: $file) {
      ...Attachment
    }
  }
  ${attachmentFragment}
`;
