import React from "react";

// ui
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import { get } from "lodash";

// style
const styles = () => ({
  container: {
    marginTop: 16,
  },

  subContainer: {
    marginTop: 4,
  },

  divider: {
    marginTop: 8,
    marginBottom: 8,
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  itemValue: {
    marginLeft: 8,
  },

  value: {},
});

/**
 * RoomingDetails
 */
const RoomingDetails = ({ classes, label, roomingList }) => {
  // return root
  return (
    <Grid container className={classes.container} justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      </Grid>

      <Grid item xs className={classes.itemValue}>
        {roomingList &&
          roomingList.map((rooming, index) => {
            return (
              <div key={index}>
                <Typography className={classes.label} variant="body1">
                  {get(rooming, "hotel_name", "")}
                </Typography>

                {/* typology */}
                <Grid container className={classes.subContainer}>
                  <Grid item>
                    <Typography className={classes.label} variant="body1">
                      {`${get(rooming, "typology_label", "")} :`}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.itemValue}>
                    <Typography className={classes.value} variant="body1">
                      {rooming.typology_value}
                    </Typography>
                  </Grid>
                </Grid>

                {/* typology */}
                <Grid container className={classes.subContainer}>
                  <Grid item>
                    <Typography className={classes.label} variant="body1">
                      {`${get(rooming, "contigent_label", "")} :`}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.itemValue}>
                    <Typography className={classes.value} variant="body1">
                      {rooming.contigent_value}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </div>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(RoomingDetails);
