import React from "react";

// ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";

// hooks
import useVoucherCreate from "./hooks/useVoucherCreate";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

/**
 * VoucherCreate component used for selecting voucher type to create
 */
const VoucherCreate = ({ classes }) => {
  // hook
  const {
    // variables
    isVoucherCreateDialogOpen,
    voucherTypeSelected,
    // functions
    getVoucherTypes,
    handleOnDialogClose,
    handleOnVoucherTypeSelect,
    handleOnCreateVoucher,
  } = useVoucherCreate();

  // return root
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={isVoucherCreateDialogOpen || false} onClose={handleOnDialogClose}>
      <DialogTitle id="confirmation-dialog-title">Kreiranje voucher-a</DialogTitle>
      <DialogContent>
        <Grid container alignItems="flex-start">
          {/* announcement type select */}
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Tip voucher-a</FormLabel>
              <RadioGroup
                aria-label="Tip najave"
                name="tip-najave"
                className={classes.group}
                value={voucherTypeSelected}
                onChange={event => handleOnVoucherTypeSelect(event.target.value)}
              >
                {getVoucherTypes().map(voucherType => (
                  <FormControlLabel
                    value={voucherType.type_name}
                    key={voucherType.id}
                    control={<Radio />}
                    label={voucherType.description}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleOnDialogClose}>
          Prekid
        </Button>
        <Button disabled={!voucherTypeSelected} color="primary" variant="contained" onClick={handleOnCreateVoucher}>
          Kreiraj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(VoucherCreate);
