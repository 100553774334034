import React, { Component } from "react";
import { withApollo } from "react-apollo";
import decode from "jwt-decode";
import { getUser } from "../../../graphql/query/administration";

class PreviewSignature extends Component {
  state = { user: null };
  componentDidMount = async () => {
    const token = localStorage.getItem("token");
    const { id } = decode(token);

    const response = await this.props.client.query({
      query: getUser,
      variables: {
        id,
      },
    });

    const user = response && response.data && response.data.getUser;

    if (user) {
      this.setState({
        user,
      });
    }
  };

  render() {
    const { user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <div className="preview-item-price preview-signature-container">
        <div>
          <div>{user && user.signature_url ? <img src={user.signature_url} alt="Potpis zaposlenika" /> : null}</div>
          <p />
          <p>{user ? `${user.first_name} ${user.last_name}` : null}</p>
        </div>
      </div>
    );
  }
}

export default withApollo(PreviewSignature);
