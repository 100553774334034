import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import toastr from "toastr";

import { getEstablishmentLegalEntity } from "../../../../graphql/query/administration";
import EstablishmentLegalEntityForm from "./EstablishmentLegalEntityForm";
import { updateEstablishmentLegalEntity } from "../../../../graphql/mutation/administration";
import { prepareForSending, removeAllEmptyKeys } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class EditEstablishmentLegalEntity extends Component {
  state = {
    establishmentLegalEntity: {
      name: "",
      web: "",
      email: "",
      oib: "",
      address1: "",
      address2: "",
      phone: "",
      fax: "",
      contact_person1: "",
      contact_person2: "",
      city_id: "",
    },
  };

  async componentDidMount() {
    const { establishmentLegalEntity } = this.props;

    const response = await this.props.client.query({
      query: getEstablishmentLegalEntity,
      variables: { id: establishmentLegalEntity.id },
    });

    if (response.data.getEstablishmentLegalEntity) {
      // set establishmentLegalEntity for editing
      this.setState({
        establishmentLegalEntity: {
          ...response.data.getEstablishmentLegalEntity,
        },
      });
    }
  }

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        establishmentLegalEntity: {
          ...prevState.establishmentLegalEntity,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { establishmentLegalEntity } = this.state;

      await this.props.mutate({
        variables: {
          id: establishmentLegalEntity.id,
          ...removeAllEmptyKeys(prepareForSending(establishmentLegalEntity, ["city", "__typename", "id"])),
        },
      });
      toastr.success("Uspješno ažurirano");

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const { establishmentLegalEntity } = this.state;

    if (!establishmentLegalEntity.id) {
      return null;
    }

    return (
      <Fragment>
        <TableTitle
          title={`Ažuriranje pravnog objekta ${establishmentLegalEntity.id}`}
          onBackPressed={this.props.closeEdit}
        />

        <EstablishmentLegalEntityForm
          establishmentLegalEntity={establishmentLegalEntity}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.closeEdit}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(updateEstablishmentLegalEntity)(withApollo(EditEstablishmentLegalEntity));
