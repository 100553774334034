import React, { Component } from "react";

import ReactTableCustom from "../../../components/UI/ReactTable";
import { AllBookingsContext } from "./AllBookings";
import { BookingModalsContext } from "./BookingModalsHandler";

export const Bookings = class extends Component {
  render() {
    return (
      // Using AllBookingsContext to show columns and data in react table
      <AllBookingsContext.Consumer>
        {({ dataToShow, columns, listDatagridFilter, innerTableProps }) => (
          // Using BookingModalsContext to handle drawer openings
          <BookingModalsContext.Consumer>
            {({ reducerCheck, expanded, changeExpandedRows }) => {
              const columns_copy = columns.map(column => {
                if (column.id === "counter") {
                  return column;
                } else {
                  const col = listDatagridFilter
                    ? listDatagridFilter.find(obj => {
                        if (obj.column === "booking_id" && column.Header === "booking_external_id") {
                          // case when booking_id is selected as shown, since we display its value in booking_external_id field
                          return true;
                        }

                        // standard case
                        /*
                         * Check between active booking columns (backend data) and booking table column name (frontend)
                         */
                        return obj.column === column.Header;
                      })
                    : null;

                  return {
                    ...column,
                    show: !!(col && col.active),
                  };
                }
              });

              const activeColumns = columns_copy.filter(column => column.show);

              return (
                <div>
                  <div className="area-to-print" id="area-to-print" />
                  <ReactTableCustom
                    data={dataToShow() || []}
                    columns={activeColumns} // Columns are set in AllBookings state which is then set in Provider
                    renderCustomPagination={true}
                    innerProps={{
                      ...innerTableProps,
                      pivotBy: ["counter"],
                      expanded: expanded,
                      ref: el => this.props.getData(el),
                      onExpandedChange: changeExpandedRows,
                      // Retreiving cell id, so we could know which drawer to open
                      getTdProps: (state, rowInfo, column, instance) => {
                        return {
                          onClick: (e, handleOriginal) => {
                            const toCheck = ["counter", "booking_external_id"];

                            if (
                              !rowInfo || // empty row
                              (rowInfo.level !== 0 && // when in subrows of a open contract, do nothing when clicking on booking_external_id (contract's id is displayed here)
                                toCheck.includes(column.id)) ||
                              (rowInfo.level === 0 && // for default behaviour when row isnt expanded (or has single termin_period)
                                (!rowInfo.subRows ||
                                  // when there are multiple subRows, do nothing when any field except counter or booking_external_id is clicked
                                  (rowInfo.subRows.length !== 1 && !toCheck.includes(column.id)) ||
                                  // when there's only 1 row (termin_period), user can click on anything except the expander (counter column)
                                  (rowInfo.subRows.length === 1 && column.id === "counter")))
                            ) {
                              return;
                            }

                            let original = rowInfo.original;
                            if (!original) {
                              original = rowInfo.subRows[0]._original;
                            }

                            // open expander
                            if (column.id === "counter") {
                              return handleOriginal();
                            }

                            // Depending on which row is clicked, open drawer
                            reducerCheck(column.id, original);

                            return handleOriginal();
                          },
                        };
                      },
                    }}
                  />
                </div>
              );
            }}
          </BookingModalsContext.Consumer>
        )}
      </AllBookingsContext.Consumer>
    );
  }
};
