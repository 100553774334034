import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";

// Material-UI components
import ListIcon from "material-ui/svg-icons/action/list";
import InquiryIcon from "material-ui/svg-icons/action/markunread-mailbox";
import GroupWorkIcon from "material-ui/svg-icons/action/group-work";
import FilterNoneIcon from "material-ui/svg-icons/image/filter-none";
import EventNoteIcon from "material-ui/svg-icons/notification/event-note";
import AlotmanContractsIcon from "material-ui/svg-icons/av/featured-play-list";
import AlotmanReservationsIcon from "material-ui/svg-icons/action/bookmark-border";
import AlotmanCalendarIcon from "material-ui/svg-icons/action/date-range";
import GuidesIcon from "material-ui/svg-icons/notification/event-available";
import AnnouncementsIcon from "material-ui/svg-icons/action/announcement";
import AnnouncementOrdersIcon from "material-ui/svg-icons/av/featured-play-list";

// utils
import { isRoleGranted } from "../utility/user";

// Layout
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar/Sidebar";

// views
import OffersRouter from "../views/Offers/OffersRouter";
import AlotmanRouter from "../views/Alotman/AlotmanRouter";
import BookingsRouter from "../views/Bookings/BookingsRouter";
import Administration from "../views/Administration/Administration";
import InquiryRouter from "../views/Inquiry/InquiryRouter";
import ContractRouter from "../views/Contract/ContractRouter";
import Guides from "../views/Guides";
import Announcements from "../views/Announcements";
import RouteMiddleware from "./RouteMiddleware";
import { Route } from "react-router-dom";
import UserSettings from "../views/Settings/User/UserSettings";
import AnnouncementOrdersRouter from "src/views/AnnouncementOrders/AnnouncementOrdersRouter";

const roles = {
  commercial: 2,
  booking: 3,
};

class Dashboard extends Component {
  state = {
    navList: {
      komercijala: {
        permission: [roles.commercial, roles.booking],
        nested: {
          upit: {
            name: "upit",
            icon: <InquiryIcon />,
            to: "upit",
            minimizedTooltip: "Upit",
          },
          ponude: {
            name: "ponude",
            icon: <FilterNoneIcon />,
            to: "ponude",
            minimizedTooltip: "Ponude",
          },
          ugovor: {
            name: "ugovor",
            icon: <ListIcon />,
            to: "ugovor",
            minimizedTooltip: "Ugovor",
          },
          booking: {
            name: "booking",
            icon: <EventNoteIcon />,
            to: "booking",
            minimizedTooltip: "Booking",
          },
        },
      },
      administracija: {
        permission: [],
        nested: {
          administracija: {
            name: "administracija",
            icon: <GroupWorkIcon />,
            to: "administracija",
            minimizedTooltip: "Administracija",
          },
        },
      },
      ...(process.env.REACT_APP_ENABLE_ALOTMAN === "true"
        ? {
            alotman: {
              permission: [],
              // icon: <AlotmanIcon />,
              nested: {
                kalendar: {
                  name: "kalendar",
                  icon: <AlotmanCalendarIcon />,
                  to: "alotman",
                  minimizedTooltip: "Alotmanski kalendar",
                },
                ugovori: {
                  name: "ugovori",
                  icon: <AlotmanContractsIcon />,
                  to: "alotman/contracts",
                  minimizedTooltip: "Alotmanski ugovori",
                },
                rezervacije: {
                  name: "rezervacije",
                  icon: <AlotmanReservationsIcon />,
                  to: "alotman/reservations",
                  minimizedTooltip: "Alotmanske rezervacije",
                },
              },
            },
          }
        : null),
      // operative tab
      ...(process.env.REACT_APP_ENABLE_OPERATIVE === "true"
        ? {
            operativa: {
              permission: [],
              nested: {
                rasporedVodica: {
                  name: "Raspored vodiča",
                  icon: <GuidesIcon />,
                  to: "raspored-vodica",
                  minimizedTooltip: "Raspored vodiča",
                },
                announcements: {
                  name: "Najave",
                  icon: <AnnouncementsIcon />,
                  to: "najave",
                  minimizedTooltip: "Najave",
                },
                nalozi: {
                  name: "Nalozi",
                  icon: <AnnouncementOrdersIcon />,
                  to: "nalozi",
                  minimizedTooltip: "Nalozi",
                },
              },
            },
          }
        : null),
    },
    minimizedDrawerHovered: "",
    minimizeDrawer: false,
    active: "ponude",
  };

  changeActiveLink = to => {
    //to refresh route
    if (this.state.active === to) {
      let path = this.props.location.pathname;
      path = path.slice(1, path.length);

      // if same route
      if (path.includes(to) && path === to) {
        this.props.history.replace(`/reload`);
        setTimeout(() => {
          this.props.history.replace(to);
        });
        return;
      }

      // if subroute of route,  keep router stack intact
      if (path.includes(to)) {
        this.props.history.push(`/reload`);
        setTimeout(() => {
          this.props.history.replace(to);
        });
        return;
      }
    }

    this.setState({
      active: to,
    });

    this.props.history.push(`/${to}`);
  };

  minimizeDrawerHandler = () => {
    this.setState(prevState => {
      return {
        ...this.state,
        minimizeDrawer: !prevState.minimizeDrawer,
      };
    });
  };

  minimizedDrawerHoveredHandler = element => {
    this.setState({
      minimizedDrawerHovered: element,
    });
  };

  componentDidMount() {
    const path = this.pathHelp();

    this.setState({
      active: path,
    });
  }

  componentDidUpdate() {
    const path = this.pathHelp();

    if (this.state.active !== path) {
      this.setState({
        active: path,
      });
    }
  }

  pathHelp() {
    const path = this.props.location.pathname;

    switch (true) {
      case path.includes("alotman/contracts"):
        return "alotman/contracts";
      case path.includes("alotman/reservations"):
        return "alotman/reservations";
      case path.includes("alotman"):
        return "alotman";
      case path.includes("upit"):
        return "upit";
      case path.includes("ponude"):
        return "ponude";
      case path.includes("ugovor"):
        return "ugovor";
      case path.includes("booking"):
        return "booking";
      case path.includes("administracija"):
        return "administracija";
      case path.includes("raspored-vodica"):
        return "raspored-vodica";
      case path.includes("najave"):
        return "najave";
      case path.includes("nalozi"):
        return "nalozi";
      default:
        return "ponude";
    }
  }

  redirect = () => {
    switch (true) {
      case isRoleGranted([roles.commercial]):
        return "/ponude";
      case isRoleGranted([roles.booking]):
        return "/booking";

      default:
        return "/ponude";
    }
  };

  render() {
    const mainContainerCss = "right-container";
    return (
      <div>
        <Sidebar
          {...this.state}
          changeActiveLink={this.changeActiveLink}
          minimizeDrawerHandler={this.minimizeDrawerHandler}
          minimizedDrawerHoveredHandler={this.minimizedDrawerHoveredHandler}
        />

        <div
          className={!this.state.minimizeDrawer ? mainContainerCss : `${mainContainerCss} right-container-minimized`}
        >
          <Header history={this.props.history} />
          <div className="container-area">
            <Switch>
              <RouteMiddleware permissions={[roles.commercial]} path="/upit" component={InquiryRouter} />
              <RouteMiddleware path="/ponude" permissions={[roles.commercial]} component={OffersRouter} />
              <RouteMiddleware path="/korisnik/postavke" component={UserSettings} />
              <RouteMiddleware path="/ugovor" permissions={[roles.commercial]} component={ContractRouter} />
              <RouteMiddleware path="/administracija" permissions={[]} component={Administration} />
              <RouteMiddleware path="/booking" permissions={[roles.booking]} component={BookingsRouter} />
              <Route path="/reload" component={null} key="reload" />
              {process.env.REACT_APP_ENABLE_ALOTMAN === "true" ? (
                <RouteMiddleware path="/alotman" permissions={[roles.commercial]} component={AlotmanRouter} />
              ) : null}
              {process.env.REACT_APP_ENABLE_OPERATIVE === "true" ? (
                <RouteMiddleware path="/raspored-vodica" permissions={[]} component={Guides} />
              ) : null}
              {process.env.REACT_APP_ENABLE_OPERATIVE === "true" ? (
                <RouteMiddleware path="/najave" permissions={[]} component={Announcements} />
              ) : null}
              {process.env.REACT_APP_ENABLE_OPERATIVE === "true" ? (
                <RouteMiddleware path="/nalozi" permissions={[]} component={AnnouncementOrdersRouter} />
              ) : null}
              <Redirect to={this.redirect()} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
