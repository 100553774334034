import React from "react";
import { Switch, Route } from "react-router-dom";

import AllOffers from "./AllOffers/AllOffers";
import CreateOffer from "./Create/CreateOffer";
import EditOffer from "./EditOffer/EditOffer";

const Offers = () => {
  return (
    <Switch>
      <Route path="/ponude/edit/:id" component={EditOffer} />
      <Route path="/ponude/create/:inquiry_id" component={CreateOffer} />
      <Route path="/ponude/create" component={CreateOffer} />
      <Route path="/" render={props => <AllOffers {...props} tableName={"offersTable"} />} />
    </Switch>
  );
};

export default Offers;
