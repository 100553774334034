import React from "react";

// labels
import { LABEL_GUIDE_ANNOUNCEMENT_COMMENT, HINT_COMMENT } from "src/utility/labels";

// hooks
import useLayoutCommentsContainer from "./hooks/useGuideNoticeContainer";

// components
import TextFieldMultilineOutline from "src/components/UI/TextFieldMultilineOutline";

/**
 * GuideNoticeContainer contains all components inside GuideNotice drawer
 */
export default function GuideNoticeContainer() {
  const { guideNotices, onGuideNoticesChange } = useLayoutCommentsContainer();

  // return root
  return (
    <TextFieldMultilineOutline
      id="guideNoticesInput"
      fullWidth={true}
      rows={5}
      rowsMax={5}
      value={guideNotices || ""}
      label={LABEL_GUIDE_ANNOUNCEMENT_COMMENT}
      placeholder={HINT_COMMENT}
      onChange={event => onGuideNoticesChange(event)}
    />
  );
}
