import React from "react";

// libs
import moment from "moment";
import { useSelector } from "react-redux";

// redux state
import {
  selectGuideScheduleDateYearFilter,
  selectGuideScheduleSeasonSelectedFilter,
} from "src/views/Guides/guidesSlice";

// constants
import {
  GUIDES_SEASON_SPRING_ID,
  GUIDES_SEASON_AUTUMN_ID,
  GUIDES_SEASON_CHRISTMASS_NY_ID,
  GUIDE_SPRING_DATE_FROM,
  GUIDE_SPRING_DATE_TO,
  GUIDE_AUTUMN_DATE_FROM,
  GUIDE_AUTUMN_DATE_TO,
  GUIDE_CHRISTMASS_NY_DATE_FROM,
  GUIDE_CHRISTMASS_NY_DATE_TO,
} from "src/utility/constants";

// components
import CustomDatePicker from "src/components/UI/CustomDatePicker";
/**
 * Filter for selecting date from / to
 */
export default function SeasonFilter({ dateSelected, handleOnDateChange }) {
  const guidesSeasonSelectedID = useSelector(selectGuideScheduleSeasonSelectedFilter);
  const filterDateSelected = useSelector(selectGuideScheduleDateYearFilter);

  const isDayBlocked = dateCalendar => {
    let blocked = true;

    let dateRangeStart;
    let dateRangeEnd;

    switch (guidesSeasonSelectedID) {
      case GUIDES_SEASON_SPRING_ID:
        dateRangeStart = moment(GUIDE_SPRING_DATE_FROM, "DD-MM-YYYY").year(filterDateSelected.year());
        dateRangeEnd = moment(GUIDE_SPRING_DATE_TO, "DD-MM-YYYY").year(filterDateSelected.year());
        break;

      case GUIDES_SEASON_AUTUMN_ID:
        dateRangeStart = moment(GUIDE_AUTUMN_DATE_FROM, "DD-MM-YYYY").year(filterDateSelected.year());
        dateRangeEnd = moment(GUIDE_AUTUMN_DATE_TO, "DD-MM-YYYY").year(filterDateSelected.year());
        break;

      case GUIDES_SEASON_CHRISTMASS_NY_ID:
        dateRangeStart = moment(GUIDE_CHRISTMASS_NY_DATE_FROM, "DD-MM-YYYY").year(filterDateSelected.year());
        dateRangeEnd = moment(GUIDE_CHRISTMASS_NY_DATE_TO, "DD-MM-YYYY").year(filterDateSelected.year());
        break;

      default:
    }

    if (dateCalendar.isSameOrAfter(dateRangeStart, "day") && dateCalendar.isSameOrBefore(dateRangeEnd, "day")) {
      blocked = false;
    }

    return blocked;
  };

  return (
    <CustomDatePicker
      value={dateSelected}
      onChange={handleOnDateChange}
      isDayBlocked={dateCalendar => isDayBlocked(dateCalendar)}
    />
  );
}
