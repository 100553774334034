import React, { PureComponent } from "react";

import { DialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import CalculatorHeaderCalculation from "./CalculatorHeaderCalculation";
import BootstrapLikeInput from "../../../../../components/UI/BootstrapLikeInput";
import { round } from "../../../../../utility/numbers";

const styles = theme => ({
  calculator_header: {
    backgroundColor: grey[100],
  },
  bootstrapInput: {
    display: "block",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  textField: {
    display: "block",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  containerText: {
    display: "flex",
  },
  calculated: {
    paddingTop: "16px",
  },
});

class CalculatorHeader extends PureComponent {
  changeCalculatorHeaderField = (prevState, key, value) => {
    const calculator_header = {
      ...prevState.calculator.calculator_header,
      [key]: value,
    };

    return {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        calculator_header: this.stateReducer(calculator_header, key),
      },
    };
  };

  stateReducer = (header, key) => {
    if (key === "grp_quantity") {
      return {
        ...header,
        grp_min_quantity: round(round(header.grp_quantity) - 2),
      };
    } else if (key === "grp_min_quantity") {
      return {
        ...header,
        grp_quantity: round(round(header.grp_min_quantity) + 2),
      };
    } else {
      return header;
    }
  };

  render() {
    const { calculator_header, getCalculatorHeader, classes } = this.props;
    const { changeCalculatorHeader } = getCalculatorHeader({
      changeCalculatorHeaderField: this.changeCalculatorHeaderField,
    });

    return (
      <DialogContent className={`${classes.calculator_header} page-break content-print`}>
        {/* Header data */}
        <div className="cols-2 content-print">
          <div>
            <BootstrapLikeInput
              id="group"
              label="Grupa"
              value={calculator_header.group_name}
              disabled={true}
              fullWidth={false}
              classProps={classes.bootstrapInput}
            />
            <BootstrapLikeInput
              id="from_to"
              label="Od - Do"
              disabled={true}
              value={`${calculator_header.from} - ${calculator_header.to}`}
              fullWidth={false}
              classProps={classes.bootstrapInput}
            />

            <div className={classes.containerText}>
              <BootstrapLikeInput
                id="kalk"
                label="Kalk"
                value={calculator_header.grp_quantity}
                onChange={changeCalculatorHeader("grp_quantity")}
                fullWidth={false}
                classProps={classes.bootstrapInput}
              />
              <BootstrapLikeInput
                id="min_group"
                label="Minimalna grupa"
                value={calculator_header.grp_min_quantity}
                onChange={changeCalculatorHeader("grp_min_quantity")}
                fullWidth={false}
                classProps={classes.bootstrapInput}
              />
            </div>

            <BootstrapLikeInput
              id="ruc"
              label="Ruc"
              value={calculator_header.ruc}
              onChange={changeCalculatorHeader("ruc")}
              fullWidth={false}
              classProps={classes.bootstrapInput}
            />
          </div>
          {/* Header calculations */}
          <div className={classes.calculated}>
            <CalculatorHeaderCalculation />
          </div>
        </div>
      </DialogContent>
    );
  }
}

export default withStyles(styles)(CalculatorHeader);
