import React, { Component } from "react";
import { connect } from "react-redux";
import { formatDate } from "../../../utility/dates";
import { autosize } from "../../../utility/globals";

class PreviewMainTermin extends Component {
  componentDidMount = () => {
    const comment_term_period = document.getElementById("comment_term_period");

    if (comment_term_period) {
      autosize(comment_term_period);

      window.addEventListener("resize", () => {
        autosize(comment_term_period);
      });
    }
  };

  render() {
    const { termin_periods, comment_term_period } = this.props;

    let termins;

    if (!termin_periods || !termin_periods.length) {
      termins = "Nema odabranih termina";
    } else {
      termins =
        termin_periods &&
        [...termin_periods]
          .sort(function(a, b) {
            return new Date(a.from) - new Date(b.from);
          })
          .map(termin => (
            <p key={termin.id}>
              {formatDate(termin.from)} - {formatDate(termin.to)}
            </p>
          ));
    }

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>TERMIN</h4>
        </div>
        <div className="preview-no-padding preview-no-margin">
          <div className="preview-content-div  preview-no-padding bold-text">{termins}</div>

          {comment_term_period.trim().length ? (
            <div disabled id="comment_term_period" className="preview-content-div preview-textarea">
              {comment_term_period.trim()}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: { termin_periods, comment_term_period },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    termin_periods,
    comment_term_period,
    int,
  };
};

export default connect(mapStateToProps)(PreviewMainTermin);
