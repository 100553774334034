import React from "react";
import debounce from "lodash/debounce";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import purple from "@material-ui/core/colors/purple";

import * as actions from "../../store/actions";
import { FormControl, FormHelperText } from "@material-ui/core";

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    "&$cssFocused": {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: purple[500],
    },
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  borderError: { border: "1px solid red" },
});

class CustomTextArea extends React.Component {
  input = null;

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.defaultValue !== this.props.defaultValue ||
      nextProps.error !== this.props.error ||
      nextProps.errorMsg !== this.props.errorMsg ||
      (nextProps.inputProps && nextProps.inputProps.disabled !== this.props.inputProps.disabled)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue && this.props.defaultValue !== this.input.value) {
      this.input.value = this.props.defaultValue;
    }
  }

  changeContentField = ({ target: { value } }) => {
    this.props.changeDocumentParam({
      key: this.props.name,
      value,
    });
  };

  delayedCallback = debounce(this.changeContentField, 500);
  delayedPropsCallback = this.props.customOnChange ? debounce(this.props.customOnChange, 500) : null;

  onChange = event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();

    // If onChange is send from parent component
    if (this.props.customOnChange) {
      this.delayedPropsCallback(event);
    } else {
      this.delayedCallback(event);
    }
  };

  render() {
    const { classes, name, defaultValue, rows, label, placeholder, inputProps, error, errorMsg } = this.props;

    return (
      <div className={classes.container}>
        <FormControl fullWidth error={error}>
          <TextField
            defaultValue={defaultValue || ""}
            name={name}
            onChange={this.onChange}
            multiline
            error={error}
            rows={rows || "4"}
            fullWidth={true}
            label={label}
            placeholder={placeholder}
            inputRef={el => (this.input = el)}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: `${classes.bootstrapInput} ${error ? classes.borderError : ""}`,
              },
              ...inputProps,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.bootstrapFormLabel,
            }}
          />
          {error && <FormHelperText>{errorMsg}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeDocumentParam: obj => dispatch(actions.changeDocumentParam(obj)),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(CustomTextArea));
