import React from "react";
import { Switch, Route } from "react-router-dom";

import EstablishmentTabs from "./EstablishmentTabs";

const HotelRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/smjestaj/"
        exact
        component={EstablishmentTabs}
      />
    </Switch>
  );
};

export default HotelRouter;
