// libs
import { useSelector } from "react-redux";

// redux state
import { selectSupplier } from "src/views/Guides/guidesSlice";

/**
 * useSupplierDetails is entry point of data for supplierDetails component
 */
export default function useSupplierDetails() {
  // redux state
  const supplier = useSelector(selectSupplier);

  return { supplier };
}
