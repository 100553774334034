import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormValidator from "../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

const form = {
  padding: "24px"
};

const CountryForm = ({
  title,
  country,
  onChangeField,
  onSubmit,
  cancelToggle
}) => {
  return (
    <FormValidator schema={CountryForm.schema} submit={onSubmit} data={country}>
      {({ error, errorMsg, submit }) => (
        <div style={form}>
          <h3>{title}</h3>
          <div className="form-control-grid">
            <FormControl fullWidth /* className={classes.margin} */>
              <InputLabel shrink={true} htmlFor="name" error={error("/name")}>
                Država
              </InputLabel>
              <Input
                id="name"
                name="name"
                error={error("/name")}
                value={country.name ? country.name : ""}
                onChange={({ target: { value } }) =>
                  onChangeField({ key: "name", value })
                }
              />
              {error("/name") && (
                <FormHelperText error={error("/name")}>
                  {errorMsg("/name")}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="form-control-grid">
            <FormControl fullWidth /* className={classes.margin} */>
              <InputLabel shrink={true} htmlFor="iso_3166_code">
                Iso 3166 kod:
              </InputLabel>
              <Input
                id="iso_3166_code"
                name="iso_3166_code"
                value={country.iso_3166_code ? country.iso_3166_code : ""}
                onChange={({ target: { value } }) =>
                  onChangeField({ key: "iso_3166_code", value })
                }
              />
            </FormControl>
          </div>
          <Button variant="contained" onClick={submit}>
            Spremi
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={cancelToggle}>
            Poništi
          </Button>
        </div>
      )}
    </FormValidator>
  );
};

CountryForm.schema = {
  properties: {
    name: {
      type: "string",
      minLength: 1,
      isNotEmpty: true,
      errorMessage: "Potrebno je unijeti ime zemlje"
    }
  }
};

export default CountryForm;
