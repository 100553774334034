import * as actionTypes from "../actionTypes";

// setAlotmanContractsInitialQueryInput
export const setAlotmanContractsInitialQueryInput = (
  limit,
  offset,
  selectedFilters,
  fromReservation,
  toReservation,
  stopBooking,
) => {
  return {
    type: actionTypes.ALOTMAN_CONTRACTS_INITIAL_QUERY,

    limit: limit,
    offset: offset,
    selectedFilters: selectedFilters,
    fromReservation: fromReservation,
    toReservation: toReservation,
    stopBooking: stopBooking,
  };
};
