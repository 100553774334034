import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Calculator from "./Calculator";
import CalculatorUI from "./CalculatorUI/CalculatorUI";
import { ListItem, List, ListItemText, ListItemSecondaryAction, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { calclulatorActive } from "../../../../utility/calculator";

const styles = {
  root: {
    minWidth: " 500px",
  },
  paperWidthSm: {
    minWidth: "1200px !important",
  },
  paperWidthXs: {
    minWidth: "1200px !important",
  },
  rootList: {
    paddingLeft: "0px !important",
  },
};

class CalculatorModal extends React.Component {
  state = {
    openCalculator: false,
    editPriceStructure: false,
    terminId: null,
    calculator: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.openCalculator !== this.state.openCalculator ||
      nextState.editPriceStructure !== this.state.editPriceStructure ||
      nextState.terminId !== this.state.terminId ||
      nextState.calculator !== this.state.calculator ||
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.termin.price_structure.calculator !== this.props.termin.price_structure.calculator
    );
  }

  handleClickOpenCalculator = calculator => () => {
    this.setState({ openCalculator: true, calculator });
  };

  handleCloseCalculator = () => {
    this.setState({ openCalculator: false, calculator: false });
  };

  render() {
    const { isNotDraft, termin } = this.props;

    const calculator =
      termin && termin.price_structure && termin.price_structure.calculator
        ? JSON.parse(termin.price_structure.calculator)
        : [];

    return (
      <div>
        {
          <List>
            {calculator &&
              calculator.map((calc, index) => {
                return (
                  <ListItem
                    key={calc.id}
                    role={undefined}
                    classes={{ root: this.props.classes.rootList }}
                    dense
                    button
                    onClick={this.handleClickOpenCalculator(calc)}
                  >
                    <ListItemText primary={`Kalkulacija ${index + 1}`} />
                    <ListItemSecondaryAction>{calclulatorActive(calc)}</ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        }

        <Button variant="outlined" color="primary" disabled={isNotDraft} onClick={this.handleClickOpenCalculator()}>
          Nova kalkulacija
        </Button>
        <Dialog
          open={this.state.openCalculator}
          onClose={this.handleCloseCalculator}
          style={{ padding: "20px", minWidth: "500px" }}
          classes={{
            root: this.props.classes.root,
            paperWidthSm: this.props.classes.paperWidthSm,
            paperWidthXs: this.props.classes.paperWidthXs,
          }}
          scroll="body"
          aria-labelledby="scroll-dialog-title"
        >
          <Calculator
            termin={this.props.termin}
            document_code={this.props.document_code}
            calculator={this.state.calculator}
          >
            <CalculatorUI
              savedBooking={this.props.savedBooking}
              handleCloseCalculator={this.handleCloseCalculator}
              isNotDraft
            />
          </Calculator>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(CalculatorModal));
