import React, { Component } from "react";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import AutocompleteSelect from "../../../../../components/UI/AutocompleteSelect";
import toastr from "toastr";

import Add from "../../../../../components/UI/AddIcon";

import { connect } from "react-redux";

import * as actions from "../../../../../store/actions";

import { boolIsNaN } from "../../../../../errorHandling";
import { TextField, FormControl } from "@material-ui/core";
import { getTranslatedTypology, sortTypologies } from "../../../../../utility/establishment";
import FormValidator from "../../../../../components/UI/FormValidator";

class EstablishmentContigent extends Component {
  static schema = {
    properties: {
      subtermin_typologies_sum: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Broj tipologija mora biti upisan i mora biti broj",
      },
      subtermin_typology_id: {
        type: "integer",
        minimum: 1,
        isNotEmpty: true,
        errorMessage: "Potrebno je odabrati broj tipologija",
      },
    },
  };

  addContigentHandler = async () => {
    try {
      // if we're in edit mode
      if (this.props.match.params.id && !this.props.create) {
        const { subtermin_typologies_sum: typologies_sum, subtermin_typology_id: typology_id, subtermin } = this.props;

        const response = await this.props.mutate({
          variables: {
            typologies_sum,
            typology_id,
            termin_period_id: subtermin.id,
          },
        });

        this.props.onAddSubterminContigent(response.data.createContigent.id);
        toastr.success("Uspješno dodan kontigent");
      } else {
        this.props.onAddSubterminContigent();
      }
    } catch (error) {}
  };

  render() {
    return (
      <React.Fragment>
        {/* Kontigent */}
        <FormValidator
          data={{
            subtermin_typologies_sum: this.props.subtermin_typologies_sum,
            subtermin_typology_id: this.props.subtermin_typology_id,
          }}
          schema={EstablishmentContigent.schema}
          submit={() => {
            if (!this.props.termin_periods.length) {
              return alert("Insert terminst before adding contigent");
            }
            return this.addContigentHandler();
          }}
        >
          {({ error, errorMsg, submitAndRecreate }) => (
            <div className="form-unit-triple-icon">
              <div className="field-label is-normal">
                <label className="label">Kontigent</label>
              </div>
              <div className="cols-2">
                <FormControl error={error("/subtermin_typologies_sum")}>
                  <TextField
                    name="typologies_no"
                    placeholder="Br. tipologija"
                    onChange={({ target: { value } }) =>
                      this.props.changeEditData({
                        key: "subtermin_typologies_sum",
                        value,
                      })
                    }
                    value={this.props.subtermin_typologies_sum}
                    fullWidth={true}
                    error={boolIsNaN(this.props.subtermin_typologies_sum)}
                  />
                </FormControl>

                <AutocompleteSelect
                  error={error("/subtermin_typology_id")}
                  errorMsg={errorMsg("/subtermin_typology_id")}
                  placeholder="Odaberite tipologiju"
                  defaultValue={this.props.subtermin_typology_id}
                  dataSource={getTranslatedTypology(sortTypologies(this.props.subtermin_typologies)).map(typo => ({
                    value: typo.id,
                    label: `${typo.name} [${typo.persons_capacity}]`,
                  }))}
                  autocompleteHandler={selected =>
                    this.props.changeEditData({
                      key: "subtermin_typology_id",
                      value: selected,
                    })
                  }
                  labelKey="desc" // To show text from object key
                />
              </div>

              <Add
                param1={this.props.subtermin_typology_id}
                param2={boolIsNaN(this.props.subtermin_typologies_sum) ? false : this.props.subtermin_typologies_sum}
                onClickHandler={submitAndRecreate}
              />
            </div>
          )}
        </FormValidator>
      </React.Fragment>
    );
  }
}

const createContigent = gql`
  mutation($typologies_sum: Int!, $termin_period_id: ID, $typology_id: ID!) {
    createContigent(
      input: { typologies_sum: $typologies_sum, termin_period_id: $termin_period_id, typology_id: $typology_id }
    ) {
      id
      typologies_sum
      typology_id
    }
  }
`;

const mapStateToProps = state => {
  const {
    subtermin_typologies_sum,
    subtermin,
    subtermin_typologies,
    subtermin_typology_id,
    offer: {
      offer_contract_document: { termin_periods, language_id },
    },
  } = state.offer;
  return {
    subtermin_typologies_sum,
    subtermin_typology_id,
    subtermin_typologies,
    termin_periods,
    subtermin,
    language_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    onAddSubterminContigent: id => dispatch(actions.addSubterminContigent(id)),
  };
};

export default graphql(createContigent)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(EstablishmentContigent)),
);
