import React from "react";
import { Switch, Route } from "react-router-dom";
import GudiesContainer from "./Container";

const GudiesRouter = () => {
  return (
    <Switch>
      <Route path="/" component={GudiesContainer} />
    </Switch>
  );
};

export default GudiesRouter;
