import React from "react";
import { connect } from "react-redux";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import TypologyForm from "./TypologyForm";
import FormValidator from "../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";
import TypologyTranslations from "./TypologyTranslations";

import { changeAdministrationTypologyData } from "../../../../store/actions";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";

const CreateTypologyForm = ({
  createMode,
  typology,
  addNewTypologyToEstablishment,
  changeTypologyTranslationData,
  changeAdministrationTypologyData,
  listLanguage,
  listTypologyGroup,
}) =>
  createMode && (
    <FormValidator schema={TypologyForm.schema} submit={addNewTypologyToEstablishment} data={typology}>
      {({ error, errorMsg, submitAndRecreate }) => (
        <Card className="card-create">
          <CardHeader title="Kreiranje tipologije" />
          <CardText>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/establishment_code")}>
                  <InputLabel shrink={true} htmlFor="establishment_code">
                    Alotmanski kod
                  </InputLabel>
                  <Input
                    id="establishment_code"
                    name="establishment_code"
                    value={typology.establishment_code ? typology.establishment_code : ""}
                    onChange={({ target: { value } }) =>
                      changeAdministrationTypologyData({
                        key: "establishment_code",
                        value,
                      })
                    }
                  />
                  {error("/establishment_code") && <FormHelperText>{errorMsg("/establishment_code")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/code")}>
                  <InputLabel shrink={true} htmlFor="code">
                    Kod
                  </InputLabel>
                  <Input
                    id="code"
                    name="code"
                    value={typology.code ? typology.code : ""}
                    onChange={({ target: { value } }) =>
                      changeAdministrationTypologyData({
                        key: "code",
                        value,
                      })
                    }
                  />
                  {error("/code") && <FormHelperText>{errorMsg("/code")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth error={error("/persons_capacity")}>
                  <InputLabel shrink={true} htmlFor="persons_capacity">
                    Broj osoba
                  </InputLabel>
                  <Input
                    id="persons_capacity"
                    name="persons_capacity"
                    className=""
                    value={typology.persons_capacity ? typology.persons_capacity : ""}
                    onChange={({ target: { value } }) =>
                      changeAdministrationTypologyData({
                        key: "persons_capacity",
                        value,
                      })
                    }
                  />
                  {error("/persons_capacity") && <FormHelperText>{errorMsg("/persons_capacity")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth error={error("/priority")}>
                  <InputLabel shrink={true} htmlFor="priority">
                    Prioritet
                  </InputLabel>
                  <Input
                    id="priority"
                    name="priority"
                    className=""
                    value={typology.priority.toString() ? typology.priority.toString() : ""}
                    onChange={({ target: { value } }) =>
                      changeAdministrationTypologyData({
                        key: "priority",
                        value,
                      })
                    }
                  />
                  {error("/priority") && <FormHelperText>{errorMsg("/priority")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <AutocompleteSelect
                  label="Grupa tipologije"
                  error={error("/typology_group_id")}
                  errorMsg={errorMsg("/typology_group_id")}
                  defaultValue={typology.typology_group_id || ""}
                  autocompleteHandler={value =>
                    changeAdministrationTypologyData({
                      key: "typology_group_id",
                      value,
                    })
                  }
                  dataSource={listTypologyGroup && listTypologyGroup.map(obj => ({ value: obj.id, label: obj.name }))}
                  placeholder="Odabir grupe tipologije"
                />
              </Grid>
            </Grid>
            <TypologyTranslations
              error={error}
              errorMsg={errorMsg}
              changeLanguageTextField={changeTypologyTranslationData}
              createMode={createMode}
              typology_translations={typology.typology_translations}
              listLanguage={listLanguage}
            />
          </CardText>
          <CardActions className="cardactions-button-padding">
            <Button variant="contained" size="small" onClick={submitAndRecreate}>
              Dodaj tipologiju
            </Button>
          </CardActions>
        </Card>
      )}
    </FormValidator>
  );

export default connect(null, { changeAdministrationTypologyData })(CreateTypologyForm);
