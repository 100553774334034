import React, { Component } from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import _ from "lodash";

import { formatDate } from "../../../utility/dates";
import { getServiceType } from "../../../graphql/query/administration";

class PreviewServices extends Component {
  state = { service_types_to_create: null };

  async componentDidMount() {
    const { service_objects_to_create, client } = this.props;

    if (service_objects_to_create && service_objects_to_create.length) {
      const service_types_to_create = await Promise.all(
        service_objects_to_create.map(async serviceType => {
          const response = await client.query({
            query: getServiceType,
            variables: {
              id: serviceType.service_type_id,
            },
          });

          const { service_type_translations } = response.data.getServiceType;

          const service = service_type_translations.find(service => service.language_id === this.props.language_id);

          return (
            <p key={response.data.getServiceType.id}>
              {(service &&
                `${service.name}  ${formatDate(serviceType.service_from)} -  ${formatDate(serviceType.service_to)}`) ||
                null}
            </p>
          );
        }),
      );

      this.setState({
        service_types_to_create,
      });
    }
  }

  showServices() {
    const { offer_contract_document_services } = this.props;

    return (
      offer_contract_document_services &&
      offer_contract_document_services.map(service => {
        const service_type_translations = _.get(service, "service_types[0].service_type_translations", []);

        const ser =
          service_type_translations &&
          service_type_translations.find(service => service.language_id === this.props.language_id);

        return (
          <p key={service.id}>
            {ser ? `${ser.name}  ${formatDate(service.service_from)} -  ${formatDate(service.service_to)}` : null}
          </p>
        );
      })
    );
  }

  render() {
    const { int, comment_service, service_objects_to_create, offer_contract_document_services } = this.props;

    if (
      !comment_service &&
      service_objects_to_create &&
      !service_objects_to_create.length &&
      offer_contract_document_services &&
      !offer_contract_document_services.length
    ) {
      return null;
    }

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.services}</h4>
        </div>
        <div className="preview-no-padding preview-no-margin">
          <div className="preview-content-div preview-no-padding">
            {this.showServices()}
            {this.state.service_types_to_create}
          </div>
          {comment_service.trim().length ? (
            <div disabled id="comment_service" className="preview-content-div preview-textarea">
              {comment_service.trim()}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: {
        offer_contract_document_services,
        service_objects_to_create,
        language_id,
        comment_service,
      },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    offer_contract_document_services,
    service_objects_to_create,
    language_id,
    comment_service,
    int,
  };
};

export default connect(mapStateToProps)(withApollo(PreviewServices));
