import React from "react";

// libs
import { Formik, Form } from "formik";
import { get, isEqual } from "lodash";
// ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextFieldMultiline from "src/components/UI/TextFieldMultilineControlled";
// hooks
import useAnnouncementContent from "./hooks/useAnnouncementContent";
// validation schema
import { templateAnnouncementSchema } from "./validationSchema";
import TableTitle from "src/components/UI/TableTitle";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
});

const AnnouncementContentForm = ({ templateId, history, classes }) => {
  const { handleOnTemplateAnnouncementUpdate, templateTranslation } = useAnnouncementContent(templateId);

  return (
    <div className="form">
      <TableTitle
        title="Ažuriranje predloška najave"
        onBackPressed={() => {
          history.push("/administracija/najava_predlozak/");
        }}
      />

      <Formik
        enableReinitialize={true}
        initialValues={templateTranslation}
        validationSchema={templateAnnouncementSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleOnTemplateAnnouncementUpdate(values, history);
        }}
      >
        {({
          values,
          initialValues,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        }) => {
          return (
            <Form>
              {/* ann_hotel_guide_title */}
              <TextFieldMultiline
                label={get(templateTranslation, "ann_hotel_guide_title.initial_term", "")}
                name="ann_hotel_guide_title.translation"
                value={get(values, "ann_hotel_guide_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_guide_title.translation") && get(touched, "ann_hotel_guide_title.translation")
                }
                helperText={
                  get(errors, "ann_hotel_guide_title.translation") &&
                  get(touched, "ann_hotel_guide_title.translation") &&
                  get(errors, "ann_hotel_guide_title.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_guide_intro */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_guide_intro.initial_term", "")}
                name="ann_hotel_guide_intro.translation"
                value={get(values, "ann_hotel_guide_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_guide_intro.translation") && get(touched, "ann_hotel_guide_intro.translation")
                }
                helperText={
                  get(errors, "ann_hotel_guide_intro.translation") &&
                  get(touched, "ann_hotel_guide_intro.translation") &&
                  get(errors, "ann_hotel_guide_intro.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_no_guide_title */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_no_guide_title.initial_term", "")}
                name="ann_hotel_no_guide_title.translation"
                value={get(values, "ann_hotel_no_guide_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_no_guide_title.translation") &&
                  get(touched, "ann_hotel_no_guide_title.translation")
                }
                helperText={
                  get(errors, "ann_hotel_no_guide_title.translation") &&
                  get(touched, "ann_hotel_no_guide_title.translation") &&
                  get(errors, "ann_hotel_no_guide_title.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_no_guide_intro */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_no_guide_intro.initial_term", "")}
                name="ann_hotel_no_guide_intro.translation"
                value={get(values, "ann_hotel_no_guide_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_no_guide_intro.translation") &&
                  get(touched, "ann_hotel_no_guide_intro.translation")
                }
                helperText={
                  get(errors, "ann_hotel_no_guide_intro.translation") &&
                  get(touched, "ann_hotel_no_guide_intro.translation") &&
                  get(errors, "ann_hotel_no_guide_intro.translation")
                }
                margin="normal"
              />

              {/* ann_guide_title */}
              <TextFieldMultiline
                label={get(values, "ann_guide_title.initial_term", "")}
                name="ann_guide_title.translation"
                value={get(values, "ann_guide_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_guide_title.translation") && get(touched, "ann_guide_title.translation")}
                helperText={
                  get(errors, "ann_guide_title.translation") &&
                  get(touched, "ann_guide_title.translation") &&
                  get(errors, "ann_guide_title.translation")
                }
                margin="normal"
              />

              {/* ann_guide_intro */}
              <TextFieldMultiline
                label={get(values, "ann_guide_intro.initial_term", "")}
                name="ann_guide_intro.translation"
                value={get(values, "ann_guide_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_guide_intro.translation") && get(touched, "ann_guide_intro.translation")}
                helperText={
                  get(errors, "ann_guide_intro.translation") &&
                  get(touched, "ann_guide_intro.translation") &&
                  get(errors, "ann_guide_intro.translation")
                }
                margin="normal"
              />

              {/* ann_guide_tour_title */}
              <TextFieldMultiline
                label={get(values, "ann_guide_tour_title.initial_term", "")}
                name="ann_guide_tour_title.translation"
                value={get(values, "ann_guide_tour_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_guide_tour_title.translation") && get(touched, "ann_guide_tour_title.translation")
                }
                helperText={
                  get(errors, "ann_guide_tour_title.translation") &&
                  get(touched, "ann_guide_tour_title.translation") &&
                  get(errors, "ann_guide_tour_title.translation")
                }
                margin="normal"
              />

              {/* ann_guide_tour_intro */}
              <TextFieldMultiline
                label={get(values, "ann_guide_tour_intro.initial_term", "")}
                name="ann_guide_tour_intro.translation"
                value={get(values, "ann_guide_tour_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_guide_tour_intro.translation") && get(touched, "ann_guide_tour_intro.translation")
                }
                helperText={
                  get(errors, "ann_guide_tour_intro.translation") &&
                  get(touched, "ann_guide_tour_intro.translation") &&
                  get(errors, "ann_guide_tour_intro.translation")
                }
                margin="normal"
              />

              {/* ann_guide_local_title */}
              <TextFieldMultiline
                label={get(values, "ann_guide_local_title".initial_term, "")}
                name="ann_guide_local_title.translation"
                value={get(values, "ann_guide_local_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_guide_local_title.translation") && get(touched, "ann_guide_local_title.translation")
                }
                helperText={
                  get(errors, "ann_guide_local_title.translation") &&
                  get(touched, "ann_guide_local_title.translation") &&
                  get(errors, "ann_guide_local_title.translation")
                }
                margin="normal"
              />

              {/* ann_guide_local_intro */}
              <TextFieldMultiline
                label={get(values, "ann_guide_local_intro.initial_term", "")}
                name="ann_guide_local_intro.translation"
                value={get(values, "ann_guide_local_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_guide_local_intro.translation") && get(touched, "ann_guide_local_intro.translation")
                }
                helperText={
                  get(errors, "ann_guide_local_intro.translation") &&
                  get(touched, "ann_guide_local_intro.translation") &&
                  get(errors, "ann_guide_local_intro.translation")
                }
                margin="normal"
              />

              {/* ann_agency_title */}
              <TextFieldMultiline
                label={get(values, "ann_agency_title.initial_term", "")}
                name="ann_agency_title.translation"
                value={get(values, "ann_agency_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_agency_title.translation") && get(touched, "ann_agency_title.translation")}
                helperText={
                  get(errors, "ann_agency_title.translation") &&
                  get(touched, "ann_agency_title.translation") &&
                  get(errors, "ann_agency_title.translation")
                }
                margin="normal"
              />

              {/* mmmmmm */}
              <TextFieldMultiline
                label={get(values, "ann_agency_intro.initial_term", "")}
                name="ann_agency_intro.translation"
                value={get(values, "ann_agency_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_agency_intro.translation") && get(touched, "ann_agency_intro.translation")}
                helperText={
                  get(errors, "ann_agency_intro.translation") &&
                  get(touched, "ann_agency_intro.translation") &&
                  get(errors, "ann_agency_intro.translation")
                }
                margin="normal"
              />

              {/* ann_agency_tour_title */}
              <TextFieldMultiline
                label={get(values, "ann_agency_tour_title.initial_term", "")}
                name="ann_agency_tour_title.translation"
                value={get(values, "ann_agency_tour_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_tour_title.translation") && get(touched, "ann_agency_tour_title.translation")
                }
                helperText={
                  get(errors, "ann_agency_tour_title.translation") &&
                  get(touched, "ann_agency_tour_title.translation") &&
                  get(errors, "ann_agency_tour_title.translation")
                }
                margin="normal"
              />

              {/* ann_agency_tour_intro */}
              <TextFieldMultiline
                label={get(values, "ann_agency_tour_intro.initial_term", "")}
                name="ann_agency_tour_intro.translation"
                value={get(values, "ann_agency_tour_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_tour_intro.translation") && get(touched, "ann_agency_tour_intro.translation")
                }
                helperText={
                  get(errors, "ann_agency_tour_intro.translation") &&
                  get(touched, "ann_agency_tour_intro.translation") &&
                  get(errors, "ann_agency_tour_intro.translation")
                }
                margin="normal"
              />

              {/* ann_agency_local_title */}
              <TextFieldMultiline
                label={get(values, "ann_agency_local_title.initial_term", "")}
                name="ann_agency_local_title.translation"
                value={get(values, "ann_agency_local_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_local_title.translation") &&
                  get(touched, "ann_agency_local_title.translation")
                }
                helperText={
                  get(errors, "ann_agency_local_title.translation") &&
                  get(touched, "ann_agency_local_title.translation") &&
                  get(errors, "ann_agency_local_title.translation")
                }
                margin="normal"
              />

              {/* ann_agency_local_intro */}
              <TextFieldMultiline
                label={get(values, "ann_agency_local_intro.initial_term", "")}
                name="ann_agency_local_intro.translation"
                value={get(values, "ann_agency_local_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_local_intro.translation") &&
                  get(touched, "ann_agency_local_intro.translation")
                }
                helperText={
                  get(errors, "ann_agency_local_intro.translation") &&
                  get(touched, "ann_agency_local_intro.translation") &&
                  get(errors, "ann_agency_local_intro.translation")
                }
                margin="normal"
              />

              {/* ann_agency_local_program_title */}
              <TextFieldMultiline
                label={get(values, "ann_agency_local_program_title.initial_term", "")}
                name="ann_agency_local_program_title.translation"
                value={get(values, "ann_agency_local_program_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_local_program_title.translation") &&
                  get(touched, "ann_agency_local_program_title.translation")
                }
                helperText={
                  get(errors, "ann_agency_local_program_title.translation") &&
                  get(touched, "ann_agency_local_program_title.translation") &&
                  get(errors, "ann_agency_local_program_title.translation")
                }
                margin="normal"
              />

              {/* ann_agency_local_program_intro */}
              <TextFieldMultiline
                label={get(values, "ann_agency_local_program_intro.initial_term", "")}
                name="ann_agency_local_program_intro.translation"
                value={get(values, "ann_agency_local_program_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_agency_local_program_intro.translation") &&
                  get(touched, "ann_agency_local_program_intro.translation")
                }
                helperText={
                  get(errors, "ann_agency_local_program_intro.translation") &&
                  get(touched, "ann_agency_local_program_intro.translation") &&
                  get(errors, "ann_agency_local_program_intro.translation")
                }
                margin="normal"
              />

              {/* ann_supplier_title */}
              <TextFieldMultiline
                label={get(values, "ann_supplier_title.initial_term", "")}
                name="ann_supplier_title.translation"
                value={get(values, "ann_supplier_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_supplier_title.translation") && get(touched, "ann_supplier_title.translation")}
                helperText={
                  get(errors, "ann_supplier_title.translation") &&
                  get(touched, "ann_supplier_title.translation") &&
                  get(errors, "ann_supplier_title.translation")
                }
                margin="normal"
              />

              {/* ann_supplier_intro */}
              <TextFieldMultiline
                label={get(values, "ann_supplier_intro.initial_term", "")}
                name="ann_supplier_intro.translation"
                value={get(values, "ann_supplier_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_supplier_intro.translation") && get(touched, "ann_supplier_intro.translation")}
                helperText={
                  get(errors, "ann_supplier_intro.translation") &&
                  get(touched, "ann_supplier_intro.translation") &&
                  get(errors, "ann_supplier_intro.translation")
                }
                margin="normal"
              />

              {/* ann_phrase_title */}
              <TextFieldMultiline
                label={get(values, "ann_phrase_title.initial_term", "")}
                name="ann_phrase_title.translation"
                value={get(values, "ann_phrase_title.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_phrase_title.translation") && get(touched, "ann_phrase_title.translation")}
                helperText={
                  get(errors, "ann_phrase_title.translation") &&
                  get(touched, "ann_phrase_title.translation") &&
                  get(errors, "ann_phrase_title.translation")
                }
                margin="normal"
              />

              {/* ann_phrase_intro */}
              <TextFieldMultiline
                label={get(values, "ann_phrase_intro.initial_term", "")}
                name="ann_phrase_intro.translation"
                value={get(values, "ann_phrase_intro.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_phrase_intro.translation") && get(touched, "ann_phrase_intro.translation")}
                helperText={
                  get(errors, "ann_phrase_intro.translation") &&
                  get(touched, "ann_phrase_intro.translation") &&
                  get(errors, "ann_phrase_intro.translation")
                }
                margin="normal"
              />

              {/* ann_group_label */}
              <TextFieldMultiline
                label={get(values, "ann_group_label.initial_term", "")}
                name="ann_group_label.translation"
                value={get(values, "ann_group_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_group_label.translation") && get(touched, "ann_group_label.translation")}
                helperText={
                  get(errors, "ann_group_label.translation") &&
                  get(touched, "ann_group_label.translation") &&
                  get(errors, "ann_group_label.translation")
                }
                margin="normal"
              />

              {/* ann_termin_label */}
              <TextFieldMultiline
                label={get(values, "ann_termin_label.initial_term", "")}
                name="ann_termin_label.translation"
                value={get(values, "ann_termin_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_termin_label.translation") && get(touched, "ann_termin_label.translation")}
                helperText={
                  get(errors, "ann_termin_label.translation") &&
                  get(touched, "ann_termin_label.translation") &&
                  get(errors, "ann_termin_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_label.initial_term", "")}
                name="ann_hotel_label.translation"
                value={get(values, "ann_hotel_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_hotel_label.translation") && get(touched, "ann_hotel_label.translation")}
                helperText={
                  get(errors, "ann_hotel_label.translation") &&
                  get(touched, "ann_hotel_label.translation") &&
                  get(errors, "ann_hotel_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_name_category_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_name_category_label.initial_term", "")}
                name="ann_hotel_name_category_label.translation"
                value={get(values, "ann_hotel_name_category_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_name_category_label.translation") &&
                  get(touched, "ann_hotel_name_category_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_name_category_label.translation") &&
                  get(touched, "ann_hotel_name_category_label.translation") &&
                  get(errors, "ann_hotel_name_category_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_address_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_address_label.initial_term", "")}
                name="ann_hotel_address_label.translation"
                value={get(values, "ann_hotel_address_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_address_label.translation") &&
                  get(touched, "ann_hotel_address_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_address_label.translation") &&
                  get(touched, "ann_hotel_address_label.translation") &&
                  get(errors, "ann_hotel_address_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_check_in_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_check_in_label.initial_term", "")}
                name="ann_hotel_check_in_label.translation"
                value={get(values, "ann_hotel_check_in_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_check_in_label.translation") &&
                  get(touched, "ann_hotel_check_in_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_check_in_label.translation") &&
                  get(touched, "ann_hotel_check_in_label.translation") &&
                  get(errors, "ann_hotel_check_in_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_check_out_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_check_out_label.initial_term", "")}
                name="ann_hotel_check_out_label.translation"
                value={get(values, "ann_hotel_check_out_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_check_out_label.translation") &&
                  get(touched, "ann_hotel_check_out_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_check_out_label.translation") &&
                  get(touched, "ann_hotel_check_out_label.translation") &&
                  get(errors, "ann_hotel_check_out_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_breakfast_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_breakfast_label.initial_term", "")}
                name="ann_hotel_breakfast_label.translation"
                value={get(values, "ann_hotel_breakfast_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_breakfast_label.translation") &&
                  get(touched, "ann_hotel_breakfast_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_breakfast_label.translation") &&
                  get(touched, "ann_hotel_breakfast_label.translation") &&
                  get(errors, "ann_hotel_breakfast_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_dinner_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_dinner_label.initial_term", "")}
                name="ann_hotel_dinner_label.translation"
                value={get(values, "ann_hotel_dinner_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_dinner_label.translation") &&
                  get(touched, "ann_hotel_dinner_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_dinner_label.translation") &&
                  get(touched, "ann_hotel_dinner_label.translation") &&
                  get(errors, "ann_hotel_dinner_label.translation")
                }
                margin="normal"
              />

              {/* ann_olivari_guide_group_label */}
              <TextFieldMultiline
                label={get(values, "ann_olivari_guide_group_label.initial_term", "")}
                name="ann_olivari_guide_group_label.translation"
                value={get(values, "ann_olivari_guide_group_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_olivari_guide_group_label.translation") &&
                  get(touched, "ann_olivari_guide_group_label.translation")
                }
                helperText={
                  get(errors, "ann_olivari_guide_group_label.translation") &&
                  get(touched, "ann_olivari_guide_group_label.translation") &&
                  get(errors, "ann_olivari_guide_group_label.translation")
                }
                margin="normal"
              />

              {/* ann_voucher_acc_label */}
              <TextFieldMultiline
                label={get(values, "ann_voucher_acc_label.initial_term", "")}
                name="ann_voucher_acc_label.translation"
                value={get(values, "ann_voucher_acc_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_voucher_acc_label.translation") && get(touched, "ann_voucher_acc_label.translation")
                }
                helperText={
                  get(errors, "ann_voucher_acc_label.translation") &&
                  get(touched, "ann_voucher_acc_label.translation") &&
                  get(errors, "ann_voucher_acc_label.translation")
                }
                margin="normal"
              />

              {/* ann_guide_acc_label */}
              <TextFieldMultiline
                label={get(values, "ann_guide_acc_label.initial_term", "")}
                name="ann_guide_acc_label.translation"
                value={get(values, "ann_guide_acc_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_guide_acc_label.translation") && get(touched, "ann_guide_acc_label.translation")
                }
                helperText={
                  get(errors, "ann_guide_acc_label.translation") &&
                  get(touched, "ann_guide_acc_label.translation") &&
                  get(errors, "ann_guide_acc_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_includes_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_includes_label.initial_term", "")}
                name="ann_hotel_includes_label.translation"
                value={get(values, "ann_hotel_includes_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_includes_label.translation") &&
                  get(touched, "ann_hotel_includes_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_includes_label.translation") &&
                  get(touched, "ann_hotel_includes_label.translation") &&
                  get(errors, "ann_hotel_includes_label.translation")
                }
                margin="normal"
              />

              {/* ann_hotel_dinner_drink_label */}
              <TextFieldMultiline
                label={get(values, "ann_hotel_dinner_drink_label.initial_term", "")}
                name="ann_hotel_dinner_drink_label.translation"
                value={get(values, "ann_hotel_dinner_drink_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_hotel_dinner_drink_label.translation") &&
                  get(touched, "ann_hotel_dinner_drink_label.translation")
                }
                helperText={
                  get(errors, "ann_hotel_dinner_drink_label.translation") &&
                  get(touched, "ann_hotel_dinner_drink_label.translation") &&
                  get(errors, "ann_hotel_dinner_drink_label.translation")
                }
                margin="normal"
              />

              {/* ann_bus_parking_label */}
              <TextFieldMultiline
                label={get(values, "ann_bus_parking_label.initial_term", "")}
                name="ann_bus_parking_label.translation"
                value={get(values, "ann_bus_parking_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_bus_parking_label.translation") && get(touched, "ann_bus_parking_label.translation")
                }
                helperText={
                  get(errors, "ann_bus_parking_label.translation") &&
                  get(touched, "ann_bus_parking_label.translation") &&
                  get(errors, "ann_bus_parking_label.translation")
                }
                margin="normal"
              />

              {/* ann_important_notice_label */}
              <TextFieldMultiline
                label={get(values, "ann_important_notice_label.initial_term", "")}
                name="ann_important_notice_label.translation"
                value={get(values, "ann_important_notice_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_important_notice_label.translation") &&
                  get(touched, "ann_important_notice_label.translation")
                }
                helperText={
                  get(errors, "ann_important_notice_label.translation") &&
                  get(touched, "ann_important_notice_label.translation") &&
                  get(errors, "ann_important_notice_label.translation")
                }
                margin="normal"
              />

              {/* ann_rooming_list_label */}
              <TextFieldMultiline
                label={get(values, "ann_rooming_list_label.initial_term", "")}
                name="ann_rooming_list_label.translation"
                value={get(values, "ann_rooming_list_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_rooming_list_label.translation") &&
                  get(touched, "ann_rooming_list_label.translation")
                }
                helperText={
                  get(errors, "ann_rooming_list_label.translation") &&
                  get(touched, "ann_rooming_list_label.translation") &&
                  get(errors, "ann_rooming_list_label.translation")
                }
                margin="normal"
              />

              {/* ann_rooming_list_typology_label */}
              <TextFieldMultiline
                label={get(values, "ann_rooming_list_typology_label.initial_term", "")}
                name="ann_rooming_list_typology_label.translation"
                value={get(values, "ann_rooming_list_typology_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_rooming_list_typology_label.translation") &&
                  get(touched, "ann_rooming_list_typology_label.translation")
                }
                helperText={
                  get(errors, "ann_rooming_list_typology_label.translation") &&
                  get(touched, "ann_rooming_list_typology_label.translation") &&
                  get(errors, "ann_rooming_list_typology_label.translation")
                }
                margin="normal"
              />

              {/* ann_rooming_list_contigent_label */}
              <TextFieldMultiline
                label={get(values, "ann_rooming_list_contigent_label.initial_term", "")}
                name="ann_rooming_list_contigent_label.translation"
                value={get(values, "ann_rooming_list_contigent_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_rooming_list_contigent_label.translation") &&
                  get(touched, "ann_rooming_list_contigent_label.translation")
                }
                helperText={
                  get(errors, "ann_rooming_list_contigent_label.translation") &&
                  get(touched, "ann_rooming_list_contigent_label.translation") &&
                  get(errors, "ann_rooming_list_contigent_label.translation")
                }
                margin="normal"
              />

              {/* ann_driver_contact_label */}
              <TextFieldMultiline
                label={get(values, "ann_driver_contact_label.initial_term", "")}
                name="ann_driver_contact_label.translation"
                value={get(values, "ann_driver_contact_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_driver_contact_label.translation") &&
                  get(touched, "ann_driver_contact_label.translation")
                }
                helperText={
                  get(errors, "ann_driver_contact_label.translation") &&
                  get(touched, "ann_driver_contact_label.translation") &&
                  get(errors, "ann_driver_contact_label.translation")
                }
                margin="normal"
              />

              {/* ann_program_label */}
              <TextFieldMultiline
                label={get(values, "ann_program_label.initial_term", "")}
                name="ann_program_label.translation"
                value={get(values, "ann_program_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_program_label.translation") && get(touched, "ann_program_label.translation")}
                helperText={
                  get(errors, "ann_program_label.translation") &&
                  get(touched, "ann_program_label.translation") &&
                  get(errors, "ann_program_label.translation")
                }
                margin="normal"
              />

              {/* ann_invoice_warrant_label */}
              <TextFieldMultiline
                label={get(values, "ann_invoice_warrant_label.initial_term", "")}
                name="ann_invoice_warrant_label.translation"
                value={get(values, "ann_invoice_warrant_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_invoice_warrant_label.translation") &&
                  get(touched, "ann_invoice_warrant_label.translation")
                }
                helperText={
                  get(errors, "ann_invoice_warrant_label.translation") &&
                  get(touched, "ann_invoice_warrant_label.translation") &&
                  get(errors, "ann_invoice_warrant_label.translation")
                }
                margin="normal"
              />

              {/* ann_nr_of_persons_label */}
              <TextFieldMultiline
                label={get(values, "ann_nr_of_persons_label.initial_term", "")}
                name="ann_nr_of_persons_label.translation"
                value={get(values, "ann_nr_of_persons_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_nr_of_persons_label.translation") &&
                  get(touched, "ann_nr_of_persons_label.translation")
                }
                helperText={
                  get(errors, "ann_nr_of_persons_label.translation") &&
                  get(touched, "ann_nr_of_persons_label.translation") &&
                  get(errors, "ann_nr_of_persons_label.translation")
                }
                margin="normal"
              />

              {/* ann_group_treffpunkt_label */}
              <TextFieldMultiline
                label={get(values, "ann_group_treffpunkt_label.initial_term", "")}
                name="ann_group_treffpunkt_label.translation"
                value={get(values, "ann_group_treffpunkt_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_group_treffpunkt_label.translation") &&
                  get(touched, "ann_group_treffpunkt_label.translation")
                }
                helperText={
                  get(errors, "ann_group_treffpunkt_label.translation") &&
                  get(touched, "ann_group_treffpunkt_label.translation") &&
                  get(errors, "ann_group_treffpunkt_label.translation")
                }
                margin="normal"
              />

              {/* ann_service_favor_label */}
              <TextFieldMultiline
                label={get(values, "ann_service_favor_label.initial_term", "")}
                name="ann_service_favor_label.translation"
                value={get(values, "ann_service_favor_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_service_favor_label.translation") &&
                  get(touched, "ann_service_favor_label.translation")
                }
                helperText={
                  get(errors, "ann_service_favor_label.translation") &&
                  get(touched, "ann_service_favor_label.translation") &&
                  get(errors, "ann_service_favor_label.translation")
                }
                margin="normal"
              />

              {/* ann_payment_label */}
              <TextFieldMultiline
                label={get(values, "ann_payment_label.initial_term", "")}
                name="ann_payment_label.translation"
                value={get(values, "ann_payment_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_payment_label.translation") && get(touched, "ann_payment_label.translation")}
                helperText={
                  get(errors, "ann_payment_label.translation") &&
                  get(touched, "ann_payment_label.translation") &&
                  get(errors, "ann_payment_label.translation")
                }
                margin="normal"
              />

              {/* ann_date_label */}
              <TextFieldMultiline
                label={get(values, "ann_date_label.initial_term", "")}
                name="ann_date_label.translation"
                value={get(values, "ann_date_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_date_label.translation") && get(touched, "ann_date_label.translation")}
                helperText={
                  get(errors, "ann_date_label.translation") &&
                  get(touched, "ann_date_label.translation") &&
                  get(errors, "ann_date_label.translation")
                }
                margin="normal"
              />

              {/* ann_time_label */}
              <TextFieldMultiline
                label={get(values, "ann_time_label.initial_term", "")}
                name="ann_time_label.translation"
                value={get(values, "ann_time_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_time_label.translation") && get(touched, "ann_time_label.translation")}
                helperText={
                  get(errors, "ann_time_label.translation") &&
                  get(touched, "ann_time_label.translation") &&
                  get(errors, "ann_time_label.translation")
                }
                margin="normal"
              />

              {/* ann_language_label */}
              <TextFieldMultiline
                label={get(values, "ann_language_label.initial_term", "")}
                name="ann_language_label.translation"
                value={get(values, "ann_language_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get(errors, "ann_language_label.translation") && get(touched, "ann_language_label.translation")}
                helperText={
                  get(errors, "ann_language_label.translation") &&
                  get(touched, "ann_language_label.translation") &&
                  get(errors, "ann_language_label.translation")
                }
                margin="normal"
              />

              {/* ann_phrase_text_label */}
              <TextFieldMultiline
                label={get(values, "ann_phrase_text_label.initial_term", "")}
                name="ann_phrase_text_label.translation"
                value={get(values, "ann_phrase_text_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_phrase_text_label.translation") && get(touched, "ann_phrase_text_label.translation")
                }
                helperText={
                  get(errors, "ann_phrase_text_label.translation") &&
                  get(touched, "ann_phrase_text_label.translation") &&
                  get(errors, "ann_phrase_text_label.translation")
                }
                margin="normal"
              />

              {/* ann_footer_message_1_label */}
              <TextFieldMultiline
                label={get(values, "ann_footer_message_1_label.initial_term", "")}
                name="ann_footer_message_1_label.translation"
                value={get(values, "ann_footer_message_1_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_footer_message_1_label.translation") &&
                  get(touched, "ann_footer_message_1_label.translation")
                }
                helperText={
                  get(errors, "ann_footer_message_1_label.translation") &&
                  get(touched, "ann_footer_message_1_label.translation") &&
                  get(errors, "ann_footer_message_1_label.translation")
                }
                margin="normal"
              />

              {/* ann_footer_message_2_label */}
              <TextFieldMultiline
                label={get(values, "ann_footer_message_2_label.initial_term", "")}
                name="ann_footer_message_2_label.translation"
                value={get(values, "ann_footer_message_2_label.translation", "")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get(errors, "ann_footer_message_2_label.translation") &&
                  get(touched, "ann_footer_message_2_label.translation")
                }
                helperText={
                  get(errors, "ann_footer_message_2_label.translation") &&
                  get(touched, "ann_footer_message_2_label.translation") &&
                  get(errors, "ann_footer_message_2_label.translation")
                }
                margin="normal"
              />

              <Button
                className={classes.button}
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  history.push("/administracija/najava_predlozak/");
                }}
              >
                Poništi
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                type="submit"
                disabled={isSubmitting || isEqual(initialValues, values)}
              >
                Spremi
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(AnnouncementContentForm);
