import React, { Component, Fragment } from "react";
import { FormControl, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateMinPersons extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`price_validity_content`).length) {
      autosize(document.getElementsByName(`price_validity_content`)[0]);
    }
  }

  render() {
    const { isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Minimalni broj osoba</label>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="notification_price_validity">Minimalni broj osoba</InputLabel>
            <Input
              id="notification_price_validity"
              className="template-input"
              inputProps={{
                name: "notification_price_validity",
              }}
              value={this.props.notification_price_validity}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
          </FormControl>
          <div>
            <TextArea
              inputProps={{
                name: "price_validity_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.price_validity_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TemplateMinPersons;
