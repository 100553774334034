// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawers, setGuideDrawerPayload, setGuideDrawers } from "src/views/Guides/guidesSlice";

// constants
import { GUIDE_DRAWER_ANNOUNCEMENT_ORDER } from "src/utility/constants";

/**
 * useAnnouncementOrder is entry point of data for announcement order drawer
 */
export default function useAnnouncementOrder() {
  // redux state
  const guideDrawers = useSelector(selectGuideScheduleDrawers);

  const dispatch = useDispatch();

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload({}));

    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_ANNOUNCEMENT_ORDER,
        isOpen: false,
      }),
    );
  };

  return {
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_ANNOUNCEMENT_ORDER],

    onCloseDrawer,
  };
}
