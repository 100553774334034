export const findActivityTranslationLanguageIndex = (activity_translations, language_id) =>
  activity_translations.map(transl => transl.language_id).indexOf(language_id.toString());

export const findActivityById = (listActivity, id) => listActivity.find(activity => activity.id === id);

export const setActivitiesForAutocomplete = (listActivity, language_id) => {
  return (
    listActivity &&
    listActivity.map(activity => {
      const { activity_translations } = activity;

      const translationIndex = findActivityTranslationLanguageIndex(activity_translations, language_id);

      return {
        ...activity,
        label: translationIndex !== -1 ? activity_translations[translationIndex].name : "No activity language name",
      };
    })
  );
};

export const setActivitiesForAutocompleteSelect = (listActivity, language_id = 1) => {
  return (
    listActivity &&
    listActivity.map(activity => {
      const { activity_translations } = activity;

      const translationIndex = findActivityTranslationLanguageIndex(activity_translations, language_id);

      return {
        ...activity,
        value: activity.id,
        label: translationIndex !== -1 ? activity_translations[translationIndex].name : "No activity language name",
        default_price: Number(activity.default_price).toFixed(2),
      };
    })
  );
};
