import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Drawer from "../../../../components/UI/Drawer";
import { formatDate } from "../../../../utility/dates";
import { sortTypologies } from "../../../../utility/establishment";
import AlotmanDay from "./AlotmanDay";
import "./MonthlyTable.css";

class MonthlyTable extends Component {
  state = {
    daysInMonth: 0,
    showFixContigentsDrawer: false,
    drawerData: {
      selectedDate: null,
      typology_id: null,
      typology_code: null,
      title: null,
      total: null,
      sold: null,
    },
    monthDayCollection: [],
  };

  componentDidMount = () => {
    this.prepareDays();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.month !== this.props.month) {
      this.prepareDays();
    }
  };

  prepareDays = () => {
    const { month } = this.props;

    const daysInMonth = moment(month, "MM-YYYY").daysInMonth(); // picked month days

    const tableDataColumns = _.range(1, 32); // 31

    const monthDayCollection = tableDataColumns.map(dayNum => {
      if (daysInMonth < dayNum) {
        return null;
      }
      return moment(month, "MM-YYYY").set("date", dayNum);
    });

    this.setState({
      monthDayCollection,
      daysInMonth,
    });
  };

  openFixContigentsDrawer = (typology_id, day, alotman_day_id) => () => {
    const selectedDate = formatDate(day);
    const title = "Fix kontigentigent za " + selectedDate;

    this.setState({
      showFixContigentsDrawer: true,
      drawerData: {
        typology_id,
        selectedDate,
        alotman_day_id,
        title,
      },
    });
  };

  closeFixContigentsDrawer = () => {
    this.setState({ showFixContigentsDrawer: false });
  };

  typologyDayStyle = (date, typology_id) => {
    if (!date) {
      return date;
    }

    const { alotman_days } = this.props;

    return (
      alotman_days &&
      alotman_days.find(day => day.typology_id === typology_id && formatDate(day.date) === formatDate(date))
    );
  };

  render() {
    const { typologies } = this.props;
    const { drawerData, monthDayCollection } = this.state;

    return (
      <div className="alotman-container">
        <Table className="alotman-container--alotman-table">
          <TableHead>
            <TableRow>
              <TableCell />
              {monthDayCollection.map((date, index) => {
                // date.date() moment function to get day of month
                const day = date ? date.date() : date;
                let day_color = "";

                if (date) {
                  const day_name = moment(date).format("dddd");

                  if (day_name === "subota" || day_name === "nedjelja") {
                    day_color = "lightgray";
                  }
                }

                if (!day && !day_color) {
                  day_color = "gray";
                }

                return (
                  <TableCell className={day_color} key={index + date + this.props.month}>
                    {!day ? "" : day}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortTypologies(typologies).map(typology => {
              return (
                <TableRow key={typology.id + this.props.month}>
                  <TableCell component="th" scope="row">
                    {typology.code}
                  </TableCell>
                  {monthDayCollection.map((date, index) => {
                    let customKey = index + typology.id + this.props.month;

                    // example:  month has 28 days, this is cell for day 30
                    if (!date) {
                      return <TableCell key={customKey} className="gray" />;
                    }

                    const typology_day_found = this.typologyDayStyle(date, typology.id);

                    // no alotman day found for this typology and date
                    if (!typology_day_found) {
                      return <TableCell key={customKey} />;
                    }

                    const { available_state, date: day_found_date, stop_booking } = typology_day_found;

                    return (
                      <TableCell
                        className={`cursor-pointer green-cell ${stop_booking && "stop-booking"}`}
                        key={customKey}
                        onClick={this.openFixContigentsDrawer(typology.id, day_found_date, typology_day_found.id)}
                      >
                        {available_state}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Drawer
          title={drawerData.title}
          open={this.state.showFixContigentsDrawer}
          toggle={this.closeFixContigentsDrawer}
        >
          <AlotmanDay
            typology_id={drawerData.typology_id}
            selectedDate={drawerData.selectedDate}
            alotman_day_id={drawerData.alotman_day_id}
          />
        </Drawer>
      </div>
    );
  }
}

export default MonthlyTable;
