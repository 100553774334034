import React, { Fragment } from "react";
import moment from "moment";

import { AllBookingsContext } from "./AllBookings";
import {
  contactedCell,
  statusCell,
  segmentCell,
  agencyCell,
  inquiryCell,
  offKeyCell,
  documentCodeCell,
  establishmentCell,
  contigentsListCell,
  cityCell,
  establishmentChainCell,
  personsCapacitySoldCell,
  guideDriverCommentCell,
  deliveredStateCell,
  remarkCell,
  notificationBookingStateDateCell,
  bookingOptionDescriptionIDCell,
  receiptCell,
  paxCell,
  transportCell,
  //userCell,
  driverCell,
  guideCell,
  dateCell,
  contractIDCell,
  numberOfBussesCell,
} from "./BookingCellValueHandler";
import CustomDateRangePicker from "../../../components/UI/CustomDateRangePicker";
import CustomDatePicker from "../../../components/UI/CustomDatePicker";
import { formatFromFilterDate, formatToFilterDate } from "../../../utility/globals";

export default [
  {
    // expander column, group/pivots by counter
    Header: "Otvori",
    show: true,
    filterable: false,
    id: "counter",
    width: 20,
    accessor: "counter",
    PivotValue: () => "",
    Expander: ({ isExpanded, subRows }) => {
      if (!(subRows.length > 1)) {
        return "";
      }

      if (isExpanded) {
        return <div className="rt-expander -open" />;
      } else {
        return <div className="rt-expander" />;
      }
    },
  },
  {
    // this shows booking_external_id and opens calculator contract drawer on click
    Header: "Ugovor",
    width: 66,
    show: true,
    id: "booking_external_id",
    Aggregated: props =>
      props && props.subRows && props.subRows[0]._original ? contractIDCell(props.subRows[0]._original) : "",
    accessor: "booking_external_id",
    Cell: () => "",
  },
  {
    Header: "Kontaktirani",
    show: true,
    filterable: false,
    id: "contacted",
    width: 64,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return contactedCell(_original);
      }
      return "";
    },
    accessor: data => contactedCell(data),
  },
  {
    Header: "Status",
    show: true,
    id: "booking_status",
    width: 50,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return statusCell(_original);
      }
      return "";
    },
    accessor: data => statusCell(data),

    Filter: ({ filter, onChange }) => (
      <AllBookingsContext.Consumer>
        {({ listTerminPeriodStatus }) => {
          const segment =
            filter && listTerminPeriodStatus && listTerminPeriodStatus.find(status => status.id === filter.value);

          return (
            <div className="print-span-container">
              <select
                className="booking-select "
                onChange={event => onChange(event.target.value)}
                value={filter ? filter.value : ""}
              >
                <option value="" />
                {listTerminPeriodStatus &&
                  listTerminPeriodStatus.map(type => (
                    <option key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </option>
                  ))}
              </select>
              <span className="select-print" style={{ display: "none" }}>
                {segment ? segment.desc : ""}
              </span>
            </div>
          );
        }}
      </AllBookingsContext.Consumer>
    ),
  },
  {
    Header: "Segment",
    show: true,
    id: "offer_contract_document_segment_id",
    width: 55,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return segmentCell(_original);
      }
      return "";
    },
    accessor: data => segmentCell(data),
    Filter: ({ filter, onChange }) => (
      <AllBookingsContext.Consumer>
        {({ listOfferContractDocumentSegment }) => {
          const segment =
            filter &&
            listOfferContractDocumentSegment &&
            listOfferContractDocumentSegment.find(type => type.id === filter.value);

          return (
            <div className="print-span-container">
              <select
                className="booking-select "
                onChange={event => onChange(event.target.value)}
                value={filter ? filter.value : ""}
              >
                <option value="" />
                {listOfferContractDocumentSegment &&
                  listOfferContractDocumentSegment.map(type => (
                    <option key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </option>
                  ))}
              </select>
              <span className="select-print" style={{ display: "none" }}>
                {segment ? segment.desc : ""}
              </span>
            </div>
          );
        }}
      </AllBookingsContext.Consumer>
    ),
  },
  {
    Header: "Agencija",
    show: true,
    id: "agency",
    width: 84,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return agencyCell(_original);
      }
      return "";
    },
    accessor: data => agencyCell(data),
  },
  {
    Header: "Šifra",
    show: true,
    //id: "inquiry_id",
    id: "inquiry_external_id",
    width: 51,
    aggregate: (values, rows) => {
      if (rows && rows.length) {
        // this value is same for all subRows of this contract, display it
        const { _original } = rows[0];
        return inquiryCell(_original);
      }
      return "";
    },
    accessor: data => inquiryCell(data),
  },
  {
    Header: "Falš",
    show: true,
    id: "off_key",
    width: 50,
    aggregate: (values, rows) => {
      if (rows && rows.length) {
        // this value is same for all subRows of this contract, display it
        const { _original } = rows[0];
        return offKeyCell(_original);
      }
      return "";
    },
    accessor: data => offKeyCell(data),
  },
  {
    Header: "Grupa",
    show: true,
    id: "document_code",
    width: 50,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return documentCodeCell(_original);
      }
      return "";
    },
    accessor: data => documentCodeCell(data),
  },
  {
    Header: "Dolazak",
    show: true,
    id: "from",
    width: 112,
    aggregate: (value, rows) => {
      let lowestDate = null;
      rows &&
        rows.forEach(({ _original: { from } }) => {
          if (!moment(lowestDate).isValid()) {
            lowestDate = moment(from);
            return;
          }

          if (moment(lowestDate).isAfter(moment(from))) {
            lowestDate = moment(from);
          }
        });
      return dateCell(
        {
          from: moment(lowestDate),
        },
        true,
      );
    },
    accessor: data => dateCell(data, true),
    Filter: ({ filter, onChange }) => {
      const handleChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFromFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <CustomDatePicker value={filter} onChange={handleChange} />;
    },
  },
  {
    Header: "Odlazak",
    show: true,
    id: "to",
    width: 112,
    aggregate: (value, rows) => {
      let highestDate = null;
      rows &&
        rows.forEach(({ _original: { to } }) => {
          if (!moment(highestDate).isValid()) {
            highestDate = moment(to);
            return;
          }

          if (moment(highestDate).isBefore(moment(to))) {
            highestDate = moment(to);
          }
        });
      return dateCell(
        {
          to: moment(highestDate),
        },
        false,
      );
    },
    accessor: data => dateCell(data, false),
    Filter: ({ filter, onChange }) => {
      const handleChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatToFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <CustomDatePicker value={filter} onChange={handleChange} />;
    },
  },
  {
    Header: "Hot. lanac",
    show: true,
    id: "establishment_chain",
    width: 60,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return establishmentChainCell(_original);
      }
      return "";
    },
    accessor: data => establishmentChainCell(data),
  },
  {
    Header: "Objekt",
    show: true,
    id: "establishment",
    width: 70,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return establishmentCell(_original);
      }
      return "";
    },
    accessor: data => establishmentCell(data),
  },
  {
    Header: "Grad",
    show: true,
    id: "city",
    width: 70,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return cityCell(_original);
      }
      return "";
    },
    accessor: data => cityCell(data),
  },
  {
    Header: "Ugovoreno",
    show: true,
    id: "contigents_list",
    width: 76,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return contigentsListCell(_original);
      }
      return "";
    },
    accessor: data => contigentsListCell(data),
  },
  {
    Header: "Vodič",
    show: true,
    id: "guide",
    width: 44,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return guideCell(_original);
      }
      return "";
    },
    accessor: data => guideCell(data),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
      >
        <option value="">Prikaži sve</option>
        <option value="true">Ima vodiča</option>
        <option value="false">Nema vodiča</option>
      </select>
    ),
  },
  {
    Header: "Vozač",
    show: true,
    id: "driver",
    width: 44,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return driverCell(_original);
      }
      return "";
    },
    accessor: data => driverCell(data),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
      >
        <option value="">Prikaži sve</option>
        <option value="true">Ima vozača</option>
        <option value="false">Nema vozača</option>
      </select>
    ),
  },
  {
    Header: "Vodič / Vozač napomena",
    show: true,
    id: "driver_guide_comment",
    width: 30,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return guideDriverCommentCell(_original);
      }
      return "";
    },
    accessor: data => guideDriverCommentCell(data),
  },
  {
    Header: "Stanje prodaje",
    show: true,
    filterable: false,
    id: "persons_capacity_sold",
    width: 60,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return personsCapacitySoldCell(_original);
      }
      return "";
    },
    accessor: data => personsCapacitySoldCell(data),
  },
  {
    Header: "Dostavljeno stanje",
    show: true,
    filterable: false,
    id: "delivered_state",
    width: 50,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return deliveredStateCell(_original);
      }
      return "";
    },
    accessor: data => deliveredStateCell(data),
  },
  {
    Header: "Napomena",
    show: true,
    id: "remark",
    width: 50,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return remarkCell(_original);
      }
      return "";
    },
    accessor: data => remarkCell(data),
  },
  {
    Header: "Datum dostave bookinga",
    show: true,
    id: "notification_booking_state_date",
    width: 203,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return notificationBookingStateDateCell(_original);
      }
      return "";
    },
    accessor: data => notificationBookingStateDateCell(data),
    Filter: ({ filter, onChange }) => <CustomDateRangePicker filter={filter} onChange={onChange} />,
  },
  {
    Header: "Opis opcije",
    show: true,
    id: "booking_option_description_id",
    width: 55,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return bookingOptionDescriptionIDCell(_original);
      }
      return "";
    },
    accessor: data => bookingOptionDescriptionIDCell(data),
    Filter: ({ filter, onChange }) => (
      <AllBookingsContext.Consumer>
        {({ listBookingOptionDescription }) => {
          const option =
            filter && listBookingOptionDescription && listBookingOptionDescription.find(op => op.id === filter.value);

          return (
            <Fragment>
              <select
                className="booking-select "
                onChange={event => onChange(event.target.value)}
                value={filter ? filter.value : ""}
              >
                <option value="" />
                {listBookingOptionDescription &&
                  listBookingOptionDescription.map(type => (
                    <option key={`key-${type.id}`} value={type.id}>
                      {type.desc}
                    </option>
                  ))}
              </select>
              <span className="select-print" style={{ display: "none" }}>
                {option ? option.desc : ""}
              </span>
            </Fragment>
          );
        }}
      </AllBookingsContext.Consumer>
    ),
  },
  {
    Header: "Račun",
    show: true,
    id: "receipt",
    width: 40,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return receiptCell(_original);
      }
      return "";
    },
    accessor: data => receiptCell(data),
  },
  {
    Header: "Pax",
    show: true,
    id: "pax",
    filterable: false,
    width: 33,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return paxCell(_original);
      }
      return "";
    },
    accessor: data => paxCell(data),
  },
  {
    Header: "Bus",
    show: true,
    id: "transport",
    width: 33,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return transportCell(_original);
      }
      return "";
    },
    accessor: data => transportCell(data),
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
      >
        <option value="">Prikaži sve</option>
        <option value={true}>Ima</option>
        <option value={false}>Nema</option>
      </select>
    ),
  },
  {
    Header: "Broj Buseva",
    show: true,
    id: "nr_of_buses",
    width: 100,
    filterable: false,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return numberOfBussesCell(_original);
      }
      return "";
    },
    accessor: data => numberOfBussesCell(data),
  },
  /*,
  {
    // not supported for Booking
    Header: "Posljednji ažurirao",
    show: true,
    id: "user",
    width: 60,
    aggregate: (values, rows) => {
      if (rows && rows.length === 1) {
        const { _original } = rows[0];
        return userCell(_original);
      }
      return "";
    },
    accessor: data => userCell(data)
  }*/
];
