import { guidIdGenerator } from "./helpers";
import { changeEditData } from "./offerReducerFunctions";
import { cloneDeep } from "lodash";

export const setProgramDays = (state, program_days_sum) => {
  const { program_days } = state;
  const newState = changeEditData(state, {
    param: { key: "program_days_sum", value: program_days_sum },
  });

  if (program_days && program_days.length) {
    // TO shrink array
    if (program_days.length > program_days_sum) {
      return {
        ...newState,
        program_days: program_days.splice(0, program_days_sum),
      };
    } else {
      // extend array
      const diff = program_days_sum - program_days.length;

      const newItems = Array.from(Array(Number(diff)), () =>
        Object.assign(
          {},
          {
            id: guidIdGenerator(),
            title: "",
            description: "",
          },
          {
            program_day_contents: Array.from(Array(10), () =>
              Object.assign(
                {},
                {
                  id: guidIdGenerator(),
                  partner_client_service_id: "",
                  activity_id: "",
                },
              ),
            ),
          },
        ),
      );

      return {
        ...newState,
        program_days: [...program_days, ...newItems],
      };
    }
  }

  return createProgramDaysHandler(newState, program_days_sum);
};

export const resetProgramDays = state => {
  return {
    ...state,
    program_days: [],
  };
};

export const createProgramDaysHandler = (state, num_days) => {
  if (isNaN(num_days)) {
    return state;
  }
  return {
    ...state,
    program_days: Array.from(Array(Number(num_days)), () =>
      Object.assign(
        {},
        {
          id: guidIdGenerator(),
          title: "",
          description: "",
        },
        {
          program_day_contents: Array.from(Array(10), () =>
            Object.assign(
              {},
              {
                id: guidIdGenerator(),
                partner_client_service_id: "",
                activity_id: "",
              },
            ),
          ),
        },
      ),
    ),
  };
};

export const changeProgramDayDescription = (state, dayId, value) => {
  const { program_days } = state;

  const program_days_clone = program_days.map(program => {
    if (program.id === dayId) {
      return {
        ...program,
        description: value,
      };
    }
    return program;
  });

  return {
    ...state,
    program_days: program_days_clone,
  };
};

export const changeProgramDayTitleHandler = (state, dayId, value) => {
  const { program_days } = state;

  const program_days_clone = program_days.map(program => {
    if (program.id === dayId) {
      return {
        ...program,
        title: value,
      };
    }
    return program;
  });

  return {
    ...state,
    program_days: program_days_clone,
  };
};

export const changeProgramDayContentHandler = (state, programDayId, programDayContentId, key, id, value) => {
  const { program_days } = state;

  const program_days_clone = program_days.map(program => {
    if (program.id === programDayId) {
      return {
        ...program,
        program_day_contents: program.program_day_contents.map(content => {
          if (content.id === programDayContentId) {
            return {
              ...content,
              activity_id: key !== "activity_id" ? "" : id,
              partner_client_service_id: key !== "partner_client_service_id" ? "" : id,
            };
          }
          return content;
        }),
        description: program.description ? program.description + ". " + value : value,
      };
    }
    return program;
  });

  return {
    ...state,
    program_days: program_days_clone,
  };
};

export const saveCreatedProgram = (state, program) => {
  return changeEditData(state, {
    param: { key: "program_days", value: program.program_days },
  });
};

export const copyUpdatedProgramDays = (state, program) => {
  return {
    ...state,
    program_days_copy: cloneDeep(state.program_days),
  };
};

export const setProgramToSend = (state, value) => {
  return changeEditData(state, {
    param: { key: "program_to_send", value: value },
  });
};
