import { guidIdGenerator } from "./helpers";
import moment from "moment";

export const alotmanTemplate = {
  children_discounts: "",
  included_in_the_price: "",
  comment: "",
  first_booking_station: "",
  rooms_are_bookable: "",
  definitive_room_list: "",
  pay: "",
  payment_rules: "",
  cancellation_costs: "",
  release_day: "",
};

export const templateObject = {
  sojourn_tax_value: 1.5,
  notification_price_validity: 20,
  notification_booking_state: 90,
  notification_room_list: 21,
  notification_storno: 45,
  notification_payment: 7,
  vat_content: "",
  sojourn_tax_content: "",
  free_place_content: "",
  price_validity_content: "",
  surcharges_content: "",
  payment_content: "",
  storno_content: "",
  program_changes_content: "",
  content_content: "",
  program_content: "",
  room_list_content: "",
  booking_state_content: "",
  comment_contigent: "",
  document_date_validity_comment: "",
};

export default {
  offer: {
    offer_status_id: "1",
    offer_created_at: moment(),
    off_key: "",
    offer_contract_document: {
      inquiry_external_id: "",
      document_date_validity: new Date(),
      document_date_validity_comment: "",
      document_code: "",
      code1: "",
      language_id: "",
      comment_term_period: "",
      comment_establishment: "",
      comment_contigent: "",
      comment_service: "",
      partner_client_id: "",
      offer_contract_document_type_id: "1",
      offer_contract_document_segment_id: "1",
      termin_periods: [],
      services: [],
      service_ids_to_create: [],
      service_ids_to_delete: [],
      service_types: [],
      service_objects_to_create: [],
      service_type_ids_to_delete: [],
      service_package: "",
      additional_services: [],
      program_visible: true,
      referents: [],
      referents_to_add: [],
      referents_to_remove: [],
      ...templateObject,
    },
  },
  all_offers_filter: "0",
  transport: false,
  establishment_id: "",
  // For contigents
  typologies_sum: "",
  typology_id: "",
  typologies: [],
  // For termins
  to: undefined,
  from: undefined,
  // For subtermins
  create_subtermin: true,
  edit_subtermin_id: "",
  subtermin_to: undefined,
  subtermin_from: undefined,
  subtermin_establishment_id: "",
  // For contigents
  subtermin_typologies_sum: "",
  subtermin_typology_id: "",
  subtermin_typologies: [],
  service: "",
  service_type: "",
  error: {},
  contigents: [],
  edit_subtermin: false,
  subtermin: {
    id: guidIdGenerator(),
    contigents: [],
  },
};
