import React, { Component } from "react";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose, get } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";

import _ from "lodash";
import toastr from "toastr";
import { v1 as uuidv1 } from "uuid";

import { createOfferContractDocumentServiceType } from "../../../../graphql/mutation/offers";

import DateRangePicker from "../../../../components/UI/DateRangePicker";

import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import Add from "../../../../components/UI/AddIcon";
import Comment from "../../../../components/UI/CommentTextArea";
import { addToCreateServiceType, addCreatedServiceType } from "../../../../store/actions";
import { autosize, config } from "../../../../utility/globals";
import { findServiceTypeByLangId } from "../../../../utility/services";
import ServiceList from "./ServiceList";

import { isDatesBlocked, getMomentFromString } from "../../../../utility/dates";
import CheckIcon from "../../../../components/UI/CheckIcon";
import Tooltip from "@material-ui/core/Tooltip";

// extend with moment-range
const moment = extendMoment(Moment);

// initial state of component
const initialState = {
  id: null,
  service_type_id: null,
  service_from: null,
  service_to: null,
  blockedDaysMessage: "",
};

class EstablishmentService extends Component {
  state = {
    ...initialState,
  };

  componentDidUpdate() {
    this.autoResize();
  }

  componentDidMount() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName("service_package").length) {
      autosize(document.getElementsByName("service_package")[0]);
    }
    if (document.getElementsByName("comment_service").length) {
      autosize(document.getElementsByName("comment_service")[0]);
    }
  }

  selectServiceType = service_type_id => {
    // check if user has deselected service type and deselect previously dates in state
    if (service_type_id == null) {
      this.setState({ service_to: null, service_from: null });
    }

    this.setState({ service_type_id: service_type_id });
  };

  /**
   * create service type with mutation || on backend
   */
  async createServiceType(service_type_id, service_from, service_to) {
    try {
      const { offer_contract_document_id } = this.props;

      const response = await this.props.createServiceType({
        variables: {
          offer_contract_document_id,
          service_type_id,
          service_from,
          service_to,
        },
      });

      // get service type from response
      const serviceTypeResponse = _.get(response, "data.createOfferContractDocumentServiceType", null);

      if (serviceTypeResponse) {
        toastr.success("Usluga uspješno kreirana");
        const { id, service_type_id, service_from, service_to } = serviceTypeResponse;

        this.props.addCreatedServiceType({ id, service_type_id, service_from, service_to });

        this.setState({
          ...initialState,
        });
      }
    } catch (error) {
      // handle error
      alert("Greška prilikom kreiranja usluge");
    }
  }

  /**
   * add service type, either to redux state, or call mutation createServiceType
   */
  addServiceType = () => {
    // check if dates are blockes in selected range
    if (isDatesBlocked(this.state.service_from, this.state.service_to, this.getPossibleDates())) {
      this.setState({
        service_from: null,
        service_to: null,
        blockedDaysMessage: "U odabranom razdoblju postoje dani koji su blokirani",
      });
    } else {
      // check if this offer is already created if it has id, since if is already created we'll create service directly with single mutation
      // and if is not created then we'll add this service type in redux state and bulk insert on mutation "createOffer"
      const { offer_id } = this.props;
      const { service_type_id, service_from, service_to } = this.state;
      if (offer_id) {
        this.createServiceType(service_type_id, service_from, service_to);
      } else {
        let id = uuidv1();
        this.props.addToCreateServiceType({
          id,
          ..._.pick(this.state, ["service_type_id", "service_from", "service_to"]),
        });

        this.setState({
          ...initialState,
        });
      }
    }
  };

  /**
   * populate default dates for service when there's no service in list
   */
  populateDefaultDateRange = () => {
    const { service_type_id } = this.state;
    const { offer_id, termin_periods } = this.props;

    // get all termins range
    let allTerminsRange = [];
    let terminRangeWithoutOverlaping = [];

    termin_periods.forEach(termin_period => {
      const { from, to } = termin_period;
      const rangeTermin = moment.range(getMomentFromString(from), getMomentFromString(to));
      allTerminsRange.push(rangeTermin);
    });

    // get overlaping ranges and combine it into single range, so we don't overlap range in serviceType
    let rangeOverlaping = allTerminsRange[0];

    allTerminsRange.forEach(terminRange => {
      if (rangeOverlaping.overlaps(terminRange, { adjacent: true })) {
        rangeOverlaping = rangeOverlaping.add(terminRange, { adjacent: true });
      } else {
        terminRangeWithoutOverlaping.push(terminRange);
      }
    });

    // add rangeOverlaping to list
    terminRangeWithoutOverlaping.push(rangeOverlaping);
    // sort ranges ascending
    let terminRangeWithoutOverlapingSorted = _.orderBy(terminRangeWithoutOverlaping, ["start"], ["asc"]);

    terminRangeWithoutOverlapingSorted.forEach(terminRange => {
      let from = terminRange.start.toISOString();
      let to = terminRange.end.toISOString();

      if (offer_id) {
        this.createServiceType(service_type_id, from, to);
      } else {
        // set temp id first
        let id = uuidv1();

        this.props.addToCreateServiceType({
          id,
          service_type_id,
          service_from: from,
          service_to: to,
        });
      }
    });

    this.setState({ ...initialState });
  };

  /**
   * get array of posible dates from termin periods
   */
  getPossibleDates() {
    let dateArray = [];

    for (let i = 0; i < this.props.termin_periods.length; i++) {
      let currentDate = moment(this.props.termin_periods[i].from);
      let stopDate = moment(this.props.termin_periods[i].to);

      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate));
        currentDate = moment(currentDate).add(1, "days");
      }
    }

    return dateArray;
  }

  /**
   * find out which days are not in termin from to period
   */
  isDayBlocked = day => {
    const { termin_periods, service_objects_to_create, offer_contract_document_services } = this.props;
    let blocked = true;

    // check for enabled dates from termin periods
    for (let i = 0; i < termin_periods.length; i++) {
      if (
        moment(day).isSameOrAfter(termin_periods[i].from, "day") &&
        moment(day).isSameOrBefore(termin_periods[i].to, "day")
      ) {
        blocked = false;
        break;
      }
    }

    // check for enabled dates from service type checked periods
    if (!_.isNil(service_objects_to_create) && service_objects_to_create.length > 0) {
      for (let i = 0; i < service_objects_to_create.length; i++) {
        if (
          moment(day).isSameOrAfter(service_objects_to_create[i].service_from, "day") &&
          moment(day).isSameOrBefore(service_objects_to_create[i].service_to, "day")
        ) {
          blocked = true;
          break;
        }
      }
    }

    // check for enabled dates from created service type checked periods
    if (!_.isNil(offer_contract_document_services) && offer_contract_document_services.length > 0) {
      for (let i = 0; i < offer_contract_document_services.length; i++) {
        if (
          moment(day).isSameOrAfter(offer_contract_document_services[i].service_from, "day") &&
          moment(day).isSameOrBefore(offer_contract_document_services[i].service_to, "day")
        ) {
          blocked = true;
          break;
        }
      }
    }

    return blocked;
  };

  /**
   * handle date from change
   */
  handleFromChange = date => {
    this.setState({
      service_from: date,
    });
  };
  /**
   * handle date to change
   */
  handleToChange = date => {
    this.setState({
      service_to: date,
    });
  };

  /**
   * check if serviceType exists in this offer
   */
  isThereServiceTypeInList = () => {
    const { offer_contract_document_services, service_objects_to_create } = this.props;

    let serviceTypesInList = [];

    // check if there's created serviceTypes
    if (!_.isNil(offer_contract_document_services)) {
      serviceTypesInList = [...serviceTypesInList, ...offer_contract_document_services];
    }

    // check if there's to create serviceTypes
    if (!_.isNil(service_objects_to_create)) {
      serviceTypesInList = [...serviceTypesInList, ...service_objects_to_create];
    }

    return serviceTypesInList.length > 0;
  };

  renderDateForm = () => {
    const { service_type_id, service_from, service_to } = this.state;

    if (service_type_id !== null) {
      const { termin_periods } = this.props;

      // set initial visible month
      const terminPeriodFirstFrom = moment(get(termin_periods, "[0].from"));
      const initialVisibleMonth = terminPeriodFirstFrom ? terminPeriodFirstFrom : moment();

      return (
        <div className="form-unit-tripple-two-icons">
          <div />
          <DateRangePicker
            from={service_from}
            to={service_to}
            handleFromChange={this.handleFromChange}
            handleToChange={this.handleToChange}
            // Rerender function to update styling
            isDayBlocked={this.isDayBlocked}
            initialVisibleMonth={() => initialVisibleMonth}
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput, blockedDaysMessage: null })} // PropTypes.func.isRequired,
          />
          <Tooltip title="Popuni s defaultnim datumima">
            <div>
              <CheckIcon onClickHandler={this.populateDefaultDateRange} param={!this.isThereServiceTypeInList()} />
            </div>
          </Tooltip>
          <Tooltip title="Dodaj osnovnu uslugu">
            <div>
              <Add onClickHandler={this.addServiceType} param1={service_from} param2={service_to} />
            </div>
          </Tooltip>
          <br />
          {this.state.blockedDaysMessage && <div style={{ color: "red" }}>{this.state.blockedDaysMessage}</div>}
          {this.state.blockedDaysMessage && <br />}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      termin_periods,
      service_types,
      service_objects_to_create,
      listServiceType,
      service_package,
      isNotDraft,
      offer_contract_document_type_id,
    } = this.props;

    const { service_type_id } = this.state;

    return (
      <React.Fragment>
        {/* Usluga */}
        {/* FIXME: On alotman, this service is important to add!!! */}

        <div className="form-unit-double">
          <div className="field-label ">
            <label className="label">Osnovna usluga</label>
          </div>

          <AutocompleteSelect
            placeholder="Odaberite osnovnu uslugu"
            defaultValue={service_type_id}
            autocompleteHandler={this.selectServiceType}
            dataSource={listServiceType.map(service => {
              const st = findServiceTypeByLangId(service, String(this.props.language_id));

              return {
                value: service.id,
                label: (st && st.name) || "",
              };
            })}
            disabled={!termin_periods.length > 0 || isNotDraft}
          />
        </div>

        {this.renderDateForm()}

        <div className="form-unit-double">
          <div />
          <div style={{ display: "grid", gridRowGap: "10px" }}>
            <ServiceList
              service_types={service_types}
              service_objects_to_create={service_objects_to_create}
              listServiceType={listServiceType}
              language_id={this.props.language_id}
              isNotDraft={isNotDraft}
            />
          </div>
        </div>

        {/* Komentar usluge */}
        <div className="form-unit-double">
          <div />
          <Comment
            name="comment_service"
            defaultValue={this.props.comment_service}
            label="Komentar usluge"
            inputProps={{ disabled: isNotDraft }}
          />
        </div>

        {/* Komentar usluge */}
        {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
          <div className="form-unit-double">
            <div className="field-label ">
              <label className="label">Paket usluga</label>
            </div>
            <Comment
              name="service_package"
              defaultValue={service_package}
              placeholder="Paket usluga"
              inputProps={{ disabled: isNotDraft }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    service_type,
    offer: {
      id: offer_id,
      offer_contract_document: {
        id: offer_contract_document_id,
        termin_periods,
        service_types,
        comment_service,
        service_objects_to_create,
        service_package,
        language_id,
        offer_contract_document_type_id,
        offer_contract_document_services,
      },
    },
  } = state.offer;

  return {
    offer_id,
    offer_contract_document_id,
    termin_periods,
    offer_contract_document_services,
    service_type,
    service_package,
    service_types,
    offer_contract_document_type_id,
    service_objects_to_create,
    comment_service,
    language_id: language_id || "1",
  };
};

export default compose(graphql(createOfferContractDocumentServiceType, { name: "createServiceType" }))(
  connect(mapStateToProps, { addToCreateServiceType, addCreatedServiceType })(EstablishmentService),
);
