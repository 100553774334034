import React from "react";
import { Switch, Route } from "react-router-dom";

import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import UserList from "./UserList";

const UserRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/zaposlenik/"
        exact
        render={props => <UserList {...props} tableName={"administrationEmployees"} />}
      />

      <Route path="/administracija/zaposlenik/create" exact component={CreateUser} />
      <Route path="/administracija/zaposlenik/edit/:id" exact component={EditUser} />
    </Switch>
  );
};

export default UserRouter;
