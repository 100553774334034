import { FILTER_SPRING, FILTER_AUTUMN, FILTER_CHRISTMASS_AND_NY } from "./labels";

export const COLUMN_SIFRA_2_NAME = "Šifra 2";
export const COLUMN_OFF_KEY_NAME = "Falš";

export const PAGINATION_LIMIT = 10;
export const PAGINATION_OFFSET = 0;

// offer statuses
export const OFFER_STATUS_DRAFT = 1;
export const OFFER_STATUS_SENT = 2;
export const OFFER_STATUS_STORNO = 3;
export const OFFER_STATUS_ACCEPTED = 4;

// contract statuses
export const CONTRACT_STATUS_SENT = 1;
export const CONTRACT_STATUS_SIGNED = 2;
export const CONTRACT_STATUS_STORNO = 3;

// offer contract document types
export const OFFER_TYPE_GROUP = 1;
export const OFFER_TYPE_TOUR = 2;
export const OFFER_TYPE_ALOTMAN = 3;
export const OFFER_TYPE_PROGRAM_ONLY = 4;

// GUIDES
// context dispatch types
export const GUIDES_LIST = "GUIDES_LIST";
export const GUIDES_ROW_EXPANDED = "GUIDES_ROW_EXPANDED";
export const GUIDES_FILTERS_OPEN = "GUIDES_FILTERS_OPEN";
export const GUIDES_GRID_FILTERS = "GRID_FILTERS";
export const GUIDES_SEASON_SELECTED = "GUIDES_SEASON_SELECTED";
export const GUIDES_FILTER_YEAR = "GUIDES_FILTER_YEAR";
export const GUIDE_SERVICE_TYPES = "GUIDE_SERVICE_TYPES";
export const GUIDE_GROUP_OPERATIVE_STATUS_TYPES = "GUIDE_GROUP_OPERATIVE_STATUS_TYPES";
export const GUIDES_DRAWER = "GUIDES_DRAWER";
export const GUIDES_DRAWER_PAYLOAD = "GUIDES_DRAWER_PAYLOAD";
export const GUIDE_PROGRAM_DAY_ACTIVITIES = "PROGRAM_DAY_ACTIVITIES";
export const GUIDE_ACTIVITY_SELECTED_PARTNERS = "GUIDE_ACTIVITY_SELECTED_PARTNERS";
export const GUIDE_PARTNER_SEARCH = "GUIDE_PARTNER_SEARCH";
export const GUIDE_PARTNER_SEARCH_PARTNER_SELECTED = "GUIDE_PARTNER_SEARCH_PARTNER_SELECTED";
export const GUIDE_PARTNER_SEARCH_CONTACT_SELECTED = "GUIDE_PARTNER_SEARCH_CONTACT_SELECTED";
export const GUIDE_PROGRAM_GUIDE_SELECTED = "GUIDE_PROGRAM_GUIDE_SELECTED";
export const GUIDE_ANNOUNCEMENT_PARTNERS = "GUIDE_ANNOUNCEMENT_PARTNERS";
export const GUIDE_ANNOUNCEMENT_DRAWER = "GUIDE_ANNOUNCEMENT_DRAWER";
export const GUIDE_ANNOUNCEMENT_PAYLOAD = "GUIDE_ANNOUNCEMENT_PAYLOAD";
export const GUIDE_SUPPLIER_ANNOUNCEMENTS = "GUIDE_SUPPLIER_ANNOUNCEMENTS";
export const GUIDE_ANNOUNCEMENT_SELECT_TYPE_DIALOG = "GUIDE_ANNOUNCEMENT_SELECT_TYPE_DIALOG";

export const GUIDE_ANNOUNCEMENT_STATUS_TYPES = "GUIDE_ANNOUNCEMENT_STATUS_TYPES";
export const GUIDE_VOUCHER_STATUS_TYPES = "GUIDE_VOUCHER_STATUS_TYPES";
export const GUIDE_CONSTANTS = "GUIDE_CONSTANTS";
export const ANNOUNCEMENT_CONSTANTS = "ANNOUNCEMENT_CONSTANTS";
export const ANNOUNCEMENT_SELECTED = "ANNOUNCEMENT_SELECTED";

// group operativa status
export const GROUP_OPERATIVA_STATUS_PENDING = "Pending";
export const GROUP_OPERATIVA_STATUS_FIX = "Fix";
export const GROUP_OPERATIVA_STATUS_STORNO = "Storno";

// background color of rows
export const BACKGROUND_COLOR_PENDING = "#ffffff";
export const BACKGROUND_COLOR_FIX = "#ddf8de";
export const BACKGROUND_COLOR_STORNO = "#ffcdd2";

export const BACKGROUND_COLOR_DEFAULT = "#ffffff";

// row ids
export const ROW_ID_PROGRAM_DAYS = "program_days";
export const ROW_ID_OPERATIVE_GROUP_STATUS = "group_operativa_status";
export const ROW_ID_GUIDE_LAYOUT_COMMENT = "guide_layout_comment";
export const ROW_ID_GUIDE_NOTICES = "guide_notices";
export const ROW_ID_GUIDE_BUS_TRANSFER = "transport";
export const ROW_ID_GUIDE_SERVICES = "guide_services";
export const ROW_ID_GUIDE_CATALOG = "catalog";
export const ROW_ID_GUIDE_PAYMENT = "payment";
export const ROW_ID_GUIDE_ADDITIONAL_FUNCTIONS = "additionalFunctions";

// type ids of guide drawers
export const GUIDE_DRAWER_PROGRAM_DAYS = "GUIDE_DRAWER_PROGRAM_DAYS";
export const GUIDE_DRAWER_OPERATIVE_GROUP_STATUS = "GUIDE_DRAWER_GROUP_STATUS";
export const GUIDE_DRAWER_ANNOUNCEMENT = "GUIDE_DRAWER_ANNOUNCEMENT";
export const GUIDE_DRAWER_ANNOUNCEMENT_ORDER = "GUIDE_DRAWER_ANNOUNCEMENT_ORDER";
export const GUIDE_DRAWER_COMMENT = "GUIDE_DRAWER_COMMENTS";
export const GUIDE_DRAWER_NOTICES = "GUIDE_DRAWER_NOTICES";
export const GUIDE_DRAWER_SERVICES = "GUIDE_DRAWER_SERVICES";
export const GUIDE_DRAWER_BUS_TRANSFER = "GUIDE_DRAWER_BUS_TRANSFER";
export const GUIDE_DRAWER_CATALOG = "GUIDE_DRAWER_CATALOG";
export const GUIDE_DRAWER_PAYMENT = "GUIDE_DRAWER_PAYMENT";

// dates of periods in DD-MM format
export const GUIDES_SEASON_SPRING_ID = "1";
export const GUIDES_SEASON_AUTUMN_ID = "2";
export const GUIDES_SEASON_CHRISTMASS_NY_ID = "3";

// dates of periods in DD-MM format
export const guidesSeasonSpring = {
  id: "1",
  label: FILTER_SPRING,
  enum: "PROLJECE",
};

export const guidesSeasonAutumn = {
  id: "2",
  label: FILTER_AUTUMN,
  enum: "JESEN",
};

export const guidesSeasonChristmassAndNy = {
  id: "3",
  label: FILTER_CHRISTMASS_AND_NY,
  enum: "BOZIC_I_NG",
};

export const guidesSeasonList = [guidesSeasonSpring, guidesSeasonAutumn, guidesSeasonChristmassAndNy];

export const GUIDE_SPRING_DATE_FROM = "01-01";
export const GUIDE_SPRING_DATE_TO = "31-07";

export const GUIDE_AUTUMN_DATE_FROM = "01-08";
export const GUIDE_AUTUMN_DATE_TO = "20-11";

export const GUIDE_CHRISTMASS_NY_DATE_FROM = "21-11";
export const GUIDE_CHRISTMASS_NY_DATE_TO = "31-12";

// ADMINISTRATION

// ANNOUNCEMENTS
export const ANNOUNCEMENT_TYPE_ESTABLISHMENT = "1";
export const ANNOUNCEMENT_TYPE_PARTNER = "2";

export const ANNOUNCEMENT = "ANNOUNCEMENT";
export const ANNOUNCEMENT_INPUT_FIELD_EDIT = "ANNOUNCEMENT_INPUT_FIELD_EDIT";
export const ANNOUNCEMENT_COPY_STATE = "ANNOUNCEMENT_COPY_STATE";
export const ANNOUNCEMENT_VOUCHER_CREATE = "ANNOUNCEMENT_VOUCHER_CREATE";

// hotel announcement types
export const HOTEL_GUIDE_ANNOUNCEMENT = "HOTEL_GUIDE_ANNOUNCEMENT";
export const HOTEL_NO_GUIDE_ANNOUNCEMENT = "HOTEL_NO_GUIDE_ANNOUNCEMENT";

// guide announcement types
export const GUIDE_ANNOUNCEMENT = "GUIDE_ANNOUNCEMENT";
export const GUIDE_TOUR_ANNOUNCEMENT = "GUIDE_TOUR_ANNOUNCEMENT";
export const GUIDE_LOCAL_ANNOUNCEMENT = "GUIDE_LOCAL_ANNOUNCEMENT";
export const AGENCY_ANNOUNCEMENT = "AGENCY_ANNOUNCEMENT";
export const AGENCY_TOUR_ANNOUNCEMENT = "AGENCY_TOUR_ANNOUNCEMENT";
export const AGENCY_LOCAL_ANNOUNCEMENT = "AGENCY_LOCAL_ANNOUNCEMENT";
export const AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT = "AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT";
export const SUPPLIER_ANNOUNCEMENT = "SUPPLIER_ANNOUNCEMENT";
export const PHRASE_ANNOUNCEMENT = "PHRASE_ANNOUNCEMENT";

// types
export const announcementTypes = [
  {
    id: 1,
    name: HOTEL_GUIDE_ANNOUNCEMENT,
    description: "Hotel s vodičem",
    type: ANNOUNCEMENT_TYPE_ESTABLISHMENT,
  },

  {
    id: 2,
    name: HOTEL_NO_GUIDE_ANNOUNCEMENT,
    description: "Hotel bez vodiča",
    type: ANNOUNCEMENT_TYPE_ESTABLISHMENT,
  },
  {
    id: 3,
    name: GUIDE_ANNOUNCEMENT,
    description: "Vodič",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },

  {
    id: 4,
    name: GUIDE_TOUR_ANNOUNCEMENT,
    description: "Vodič (Tura)",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 5,
    name: "GUIDE_LOCAL_ANNOUNCEMENT",
    description: "Vodič (Lokalci)",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 6,
    name: AGENCY_ANNOUNCEMENT,
    description: "Agencija",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 7,
    name: AGENCY_TOUR_ANNOUNCEMENT,
    description: "Agencija (Tura)",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 8,
    name: AGENCY_LOCAL_ANNOUNCEMENT,
    description: "Agencija (Lokalci)",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 9,
    name: AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
    description: "Agencija (Lokalci - Program)",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 10,
    name: SUPPLIER_ANNOUNCEMENT,
    description: "Dobavljač",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
  {
    id: 11,
    name: PHRASE_ANNOUNCEMENT,
    description: "Fraze",
    type: ANNOUNCEMENT_TYPE_PARTNER,
  },
];

/**
 * voucher types
 **/

// voucher bianco hotel
export const VOUCHER_HOTEL = {
  id: 1,
  type_name: "VOUCHER_HOTEL",
  description: "Voucher - Hotel",
};

// voucher bianco hotel
export const VOUCHER_PROGRAM = {
  id: 2,
  type_name: "VOUCHER_PROGRAM",
  description: "Voucher - Program",
};

// voucher bianco hotel
export const VOUCHER_HOTEL_FOREIGN_AGENCY = {
  id: 3,
  type_name: "VOUCHER_HOTEL_STRANA_AGENCIJA",
  description: "Voucher - Hotel stranoj agenicji",
};

// voucher bianco hotel
export const VOUCHER_TICKETS_AND_RESTAURANTS = {
  id: 4,
  type_name: "VOUCHER_ULAZNICE_I_RESTORANI",
  description: "Voucher - Ulaznice i restorani",
};

// voucher bianco hotel
export const VOUCHER_GUIDES_FOREIGN_AGENCY = {
  id: 5,
  type_name: "VOUCHER_VODIC_STRANOJ_AGENCIJI",
  description: "Voucher - Vodič stranoj agenciji",
};

// row ids
export const ANNOUNCEMENT_ROW_ID_EDIT = "edit";
export const ANNOUNCEMENT_ORDER_ROW_ID_EDIT = "edit";

// VOUCHERS
// context types
export const ANNOUNCEMENT_VOUCHERS = "ANNOUNCEMENT_VOUCHERS";
export const VOUCHER_SELECTED = "VOUCHER_SELECTED";

// ANNOUNCEMENT ORDERS
// context types
export const ANNOUNCEMENT_ORDERS = "ANNOUNCEMENT_ORDERS";

/**
 * local storage constants
 */

export const LOCAL_STORAGE_ADMINISTRATION_ANN_TEMPLATE = "LOCAL_STORAGE_ADMINISTRATION_ANN_TEMPLATE";
export const LOCAL_STORAGE_GUIDES_TABLE = "guidesTable";
export const LOCAL_STORAGE_ANNOUNCEMENTS_TABLE = "announcementsTable";
export const LOCAL_STORAGE_ANNOUNCEMENT_ORDERS_TABLE = "announcementOrdersTable";
