import React from "react";
// ui
import { Grid, Typography, Card, Checkbox } from "@material-ui/core";
// hooks
import useContactSubItem from "./hooks/useContactSub";
//style
import "./style.css";

/**
 * ContactSubItem is used for showing contacts in partner search dialog
 */
export default function ContactSubItem({ contact }) {
  const { contactIdSelected, handleOnContactSelect } = useContactSubItem();

  // return root
  return (
    <Card className="cardContactSub" key={contact.id}>
      <Grid container className="containerContactSub" spacing={16}>
        <Grid item xs={2}>
          <Typography className="labelContactSub" variant="caption" color="textSecondary">
            Ime
          </Typography>

          <Typography className="valueContactSub" variant="body2" color="textPrimary">
            {contact.first_name}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="labelContactSub" variant="caption" color="textSecondary">
            Prezime
          </Typography>

          <Typography className="valueContactSub" variant="body2" color="textPrimary">
            {contact.last_name}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="labelContactSub" variant="caption" color="textSecondary">
            Telefon
          </Typography>

          <Typography className="valueContactSub" variant="body2" color="textPrimary">
            {contact.phone}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className="labelContactSub" variant="caption" color="textSecondary">
            Email
          </Typography>

          <Typography className="valueContactSub" variant="body2" color="textPrimary">
            {contact.email ? contact.email : "prazno"}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Checkbox
            color="primary"
            checked={contactIdSelected ? contactIdSelected === contact.id : false}
            onChange={() => handleOnContactSelect(contact.id)}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
