import React, { useState, Fragment, useEffect } from "react";

// ui
import { Grid, Typography, withStyles, Input } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Check";

// hooks
import useEditInput from "./hooks/useEditInput";

// style
const styles = () => ({
  button: {
    height: 8,
    width: 8,
    fontSize: 20,
  },

  itemEditIcon: {
    maxWidth: 24,
  },

  itemSaveIcon: {
    maxWidth: 30,
  },

  hint: {
    whiteSpace: "pre-line",
    lineHeight: "1.5em",
  },

  value: {
    fontWeight: "bold",
    whiteSpace: "pre-line",
    lineHeight: "1.5em",
  },

  input: {
    fontSize: "inherit",
    paddingTop: "inherit",
    paddingBottom: 8,
    lineHeight: "inherit",
  },
});

/**
 * Edit input component
 */
const EditInput = ({ classes, keyOfValue, value, onSaveClick }) => {
  // hooks
  const { announcementCopyState, handleEditMode, isEdit } = useEditInput(keyOfValue);

  // state
  const [inputValue, setInputValue] = useState();

  // handle on value prop change
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // return root
  return (
    <Fragment>
      {!isEdit || announcementCopyState ? (
        <Grid container alignItems="center">
          <Grid item xs>
            {value ? (
              <Typography className={classes.value} variant="body1">
                {value}
              </Typography>
            ) : (
              <Typography className={classes.hint} variant="body1">
                Molimo unesite tekst
              </Typography>
            )}
          </Grid>
          <Grid item xs className={classes.itemEditIcon}>
            {!announcementCopyState && (
              <IconButton className={classes.button} aria-label="Ažuriraj polje">
                <EditIcon
                  fontSize="inherit"
                  onClick={() => {
                    setInputValue(value);
                    handleEditMode(true);
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="baseline" spacing={16}>
          <Grid item xs>
            <Typography className={classes.value} variant="body1" component="div">
              <Input
                autoFocus={!inputValue}
                fullWidth
                multiline={true}
                value={inputValue || ""}
                className={classes.input}
                onChange={event => setInputValue(event.target.value)}
              />
            </Typography>
          </Grid>
          <Grid item xs className={classes.itemSaveIcon}>
            <IconButton className={classes.button} aria-label="Prekid">
              <CancelIcon
                onClick={() => {
                  setInputValue(null);
                  handleEditMode(false);
                }}
              />
            </IconButton>
          </Grid>

          <Grid item xs className={classes.itemSaveIcon}>
            <IconButton color="primary" className={classes.button} aria-label="Spremi">
              <SaveIcon onClick={() => onSaveClick(keyOfValue, inputValue)} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(EditInput);
