import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";

import RegionForm from "./RegionForm";

import { updateRegion } from "../../../../graphql/mutation/administration";

import { prepareForSending } from "../../../../utility/prepare";
import { fetchRegion } from "../../../../graphql/query/administration";
import TableTitle from "src/components/UI/TableTitle";

class EditRegion extends Component {
  state = {
    name: "",
    country_id: "",
  };

  async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: fetchRegion,
        variables: {
          id: this.props.region.id,
        },
      });

      const { getRegion } = response.data;

      this.setState({
        ...prepareForSending(getRegion, ["__typename"]),
      });
    } catch (error) {}
  }

  changeFieldData = obj => {
    this.setState({
      [obj.key]: obj.value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      this.props.closeEdit();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title={`Ažuriranje regije ${this.props.region.id}`} onBackPressed={this.props.closeEdit} />
        <RegionForm
          onSubmit={this.submit}
          region={this.state}
          cancelToggle={this.props.closeEdit}
          onChangeField={this.changeFieldData}
        />
      </Fragment>
    );
  }
}

export default graphql(updateRegion)(withApollo(EditRegion));
