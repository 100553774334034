import { useCallback } from "react";

// libs
import { debounce, get } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideSchedules,
  selectProgramDayActivities,
  setGuideSchedules,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";

/**
 * useGuideComment is entry point of data for guideComment component
 */
export default function useGuideComment() {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // change program day title
  const [updateGuideExtraFields] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  /**
   * handle on comment change
   */
  const handleOnGuideCommentUpdate = async guideNotices => {
    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: programDayActivities.program_id,
          guide_notices: guideNotices,
        },
      },
    });

    const guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran komentar");

      const guideNotice = get(guideUpdate, "guide_notices", "");
      // handle on program update
      const programDayActivitiesUpdate = {
        ...programDayActivities,
        guide_notices: guideNotice,
      };

      dispatch(setProgramDayActivities(programDayActivitiesUpdate));

      // update list guides
      const listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === programDayActivities.program_id) {
          return {
            ...guide,
            guide_notices: guideNotice,
          };
        }
        return guide;
      });

      dispatch(setGuideSchedules(listGuidesUpdate));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  const delayedCallback = debounce(handleOnGuideCommentUpdate, 1000);

  const onGuideNoticesChange = useCallback(
    event => {
      //setDayTitle(event.target.value);
      event.persist();

      let guideNotices = get(event, "target.value");

      delayedCallback(guideNotices);
    },
    [delayedCallback],
  );

  return {
    guideNotices: get(programDayActivities, "guide_notices", null),
    onGuideNoticesChange,
  };
}
