import React from "react";

// libs
import { get } from "lodash";
// ui
import { Typography, Card, CardContent } from "@material-ui/core";
// components
import ContactSubItem from "./components/ContactSubItem/ContactSubItem";
// style
import "./style.css";

export default function GuideSub({ row }) {
  const contacts = get(row, "original.contacts");

  // return root
  return (
    <Card>
      <CardContent>
        <Typography className="labelGuideSelect" variant="subheading" color="textPrimary">
          Odabir vodiča
        </Typography>

        {contacts && contacts.map(contact => <ContactSubItem contact={contact} key={contact.id} />)}
      </CardContent>
    </Card>
  );
}
