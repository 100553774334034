import React from "react";

// libs
import { get } from "lodash";
import moment from "moment";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";

// constants
import { FONT_SIZE_VALUE, MARGIN_PAGE } from "../utils/constants";

// components
import HeaderVoucher from "../Header";
import VoucherTitle from "../Title";
import DateLabel from "../Date";
import VoucherTextField from "../TextField";
import VoucherUserDetails from "../UserDetails";
import VoucherSignature from "../GuideSignature";
import VoucherFooter from "../Footer";
import VoucherSubject from "../Subject";
import VoucherFullWidthText from "../FullWidthText";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: MARGIN_PAGE,
    flexGrow: 1,
  },
});

// VoucherProgram
export default function VoucherProgram({ voucher }) {
  // vocuher data from announcement
  const voucherData = get(voucher, "voucher_data", {});

  // supplier details for subject
  const supplierLabel = get(voucherData, "partner_client_name.label", "");
  const supplierValue = get(voucherData, "partner_client_name.value", "");

  // date created
  const dateCreated = moment(get(voucher, "date", "")).format("DD.MM.YYYY");

  // voucher title
  const voucherTitle = get(voucher, "voucher_title", "");

  // group
  const groupLabel = get(voucherData, "group.label", "");
  const groupValue = get(voucherData, "group.value", "");

  // termin
  const terminLabel = get(voucherData, "termin.label", "");
  const terminValue = get(voucherData, "termin.value", "");

  // stamp
  const voucherStamp = get(voucher, "voucher_signature_text", "");

  // payment text
  const voucherPaymentText = get(voucher, "voucher_payment_text", "");

  // voucher created text
  const voucherCreatedByText = get(voucher, "voucher_created_text", "");

  // voucher user details
  const voucherUserFirstName = get(voucher, "user_first_name", "");
  const voucherUserLastName = get(voucher, "user_last_name", "");

  // voucher signature text
  const voucherSignatureText = get(voucher, "voucher_signature_text", "");

  // guide
  const guideFirstName = get(voucherData, "guide_first_name", "");
  const guideLastName = get(voucherData, "guide_last_name", "");

  const guideFullName = `${guideFirstName && guideFirstName} ${guideLastName && guideLastName}`;

  // footer
  const voucherFooter = get(voucher, "voucher_footer_text", "");

  // return root
  return (
    <Document title={`${voucherTitle}-${dateCreated}`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/** header */}
          <HeaderVoucher />

          {/** subject - supplier */}
          <VoucherSubject subjectText={`${supplierLabel}: ${supplierValue}`} />

          {/** date */}
          <DateLabel value={dateCreated} />

          {/** title */}
          <VoucherTitle title={voucherTitle} />

          {/** group */}
          <VoucherTextField label={groupLabel} value={groupValue} />

          {/** termin */}
          <VoucherTextField label={terminLabel} value={terminValue} />

          {/** service */}
          <VoucherTextField label={"usluga"} value="HP(večera+doručak)" />

          {/** number of persons */}
          <VoucherTextField label={"broj osoba"} value="32" />

          {/** voucher validation */}
          <VoucherFullWidthText
            text={voucherStamp}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSans" }}
          />

          {/** voucher payment */}
          <VoucherFullWidthText
            text={voucherPaymentText}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSansBold", textDecoration: "underline" }}
          />

          {/** user details */}
          <VoucherUserDetails
            createdByText={voucherCreatedByText}
            firstName={voucherUserFirstName}
            lastName={voucherUserLastName}
          />

          {/** signature */}
          <VoucherSignature signatureText={voucherSignatureText} guideName={guideFullName} />

          <VoucherFooter footerText={voucherFooter} />
        </View>
      </Page>
    </Document>
  );
}
