import React, { Component } from "react";

class PreviewFreeText extends Component {
  render() {
    const {
      voucher: { remark },
    } = this.props;

    return (
      remark && (
        <div>
          <div className="preview-content">
            <div className="preview-content-title " />
            <div>
              <div disabled id="remark" className="preview-content-div preview-textarea">
                {remark}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default PreviewFreeText;
