import React, { Component } from "react";

import CreateCity from "./City/CreateCity";
import EditCity from "./City/EditCity";
import CityList from "./City/CityList";
import CreateRegion from "./Region/CreateRegion";
import EditRegion from "./Region/EditRegion";
import RegionList from "./Region/RegionList";
import CreateCountry from "./Country/CreateCountry";
import EditCountry from "./Country/EditCountry";
import CountryList from "./Country/CountryList";

const ActivityTabsHOC = WrappedComponent => {
  return class extends Component {
    state = {
      cityCreate: false,
      cityEdit: false,
      city: "",
      countryCreate: false,
      countryEdit: false,
      country: "",
      regionCreate: false,
      regionEdit: false,
      region: "",
    };

    cityCreateToggle = () => {
      this.setState(prevState => {
        return {
          cityCreate: !prevState.cityCreate,
        };
      });
    };

    cityEditOpen = city => {
      this.setState({
        city,
        cityEdit: true,
      });
    };

    cityEditClose = () => {
      this.setState({
        city: "",
        cityEdit: false,
      });
    };

    regionCreateToggle = () => {
      this.setState(prevState => {
        return {
          regionCreate: !prevState.regionCreate,
        };
      });
    };

    regionEditOpen = region => {
      this.setState({
        region,
        regionEdit: true,
      });
    };

    regionEditClose = () => {
      this.setState({
        region: "",
        regionEdit: false,
      });
    };

    countryCreateToggle = () => {
      this.setState(prevState => {
        return {
          countryCreate: !prevState.countryCreate,
        };
      });
    };

    countryEditOpen = country => {
      this.setState({
        country,
        countryEdit: true,
      });
    };

    countryEditClose = () => {
      this.setState({
        country: "",
        countryEdit: false,
      });
    };

    showInTabCity = () => {
      const { cityCreate, cityEdit, city } = this.state;

      if (cityCreate) {
        return <CreateCity cancelToggle={this.cityCreateToggle} />;
      }
      if (cityEdit) {
        return <EditCity closeEdit={this.cityEditClose} city={city} />;
      }

      return <CityList tableName={"cityTable"} toggleCreate={this.cityCreateToggle} openEdit={this.cityEditOpen} />;
    };

    showInTabRegion = () => {
      const { regionCreate, regionEdit, region } = this.state;

      if (regionCreate) {
        return <CreateRegion cancelToggle={this.regionCreateToggle} />;
      }
      if (regionEdit) {
        return <EditRegion region={region} closeEdit={this.regionEditClose} />;
      }

      return (
        <RegionList tableName={"regionTable"} toggleCreate={this.regionCreateToggle} openEdit={this.regionEditOpen} />
      );
    };

    showInTabCountry = () => {
      const { countryCreate, countryEdit, country } = this.state;

      if (countryCreate) {
        return <CreateCountry cancelToggle={this.countryCreateToggle} />;
      }
      if (countryEdit) {
        return <EditCountry country={country} closeEdit={this.countryEditClose} />;
      }
      return (
        <CountryList
          tableName={"countryTable"}
          toggleCreate={this.countryCreateToggle}
          openEdit={this.countryEditOpen}
        />
      );
    };

    render() {
      return (
        <WrappedComponent
          showInTabCity={this.showInTabCity}
          showInTabRegion={this.showInTabRegion}
          showInTabCountry={this.showInTabCountry}
        />
      );
    }
  };
};

export default ActivityTabsHOC;
