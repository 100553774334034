// libs
import { useSelector } from "react-redux";

// redux state
import { selectGuideScheduleRowExpanded } from "src/views/Guides/guidesSlice";

/**
 * useExpander is entry point of data for expander column component
 */
export default function useExpander(programId) {
  // redux state
  const guidesRowExpanded = useSelector(selectGuideScheduleRowExpanded);

  const expanded = !!guidesRowExpanded[programId];

  return {
    expanded,
  };
}
