import React from "react";

// libs
import { get } from "lodash";

// ui
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";

// components
import EditInput from "../EditInput";

// style
const styles = () => ({
  root: {
    marginBottom: 12,
  },

  container: {
    marginTop: 16,
  },

  tableCellLabel: {
    borderWidth: 1,
    borderColor: "#616161",
    borderStyle: "solid",
    padding: "8px !important",
    maxWidth: 80,
  },
  tableCellValue: {
    borderWidth: 1,
    borderColor: "#616161",
    borderStyle: "solid",
    padding: "8px !important",
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  itemValue: {
    marginLeft: 8,
  },

  value: {
    fontWeight: "bold",
  },

  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

/**
 * HotelDetailsList
 */
const HotelDetailsList = ({ classes, label, hotelList, handleOnUpdateHotelDetails }) => {
  // root
  return (
    <Grid container className={classes.container} justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      </Grid>

      <Grid item xs className={classes.itemValue}>
        <div className={classes.root}>
          {hotelList &&
            hotelList.map((hotel, index) => {
              return (
                <div key={hotel.establishment_id}>
                  {/* name */}
                  <Typography className={classes.value} variant="body1">
                    {get(hotel, "hotel_name.value", "")}
                  </Typography>

                  {/* address */}
                  <Typography className={classes.value} variant="body1">
                    {`${get(hotel, "hotel_address_1.value", "")}, ${get(hotel, "hotel_address_2.value", "")}`}
                  </Typography>

                  {/* check_in */}
                  <Grid
                    container
                    className={classes.container}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography className={classes.label} variant="body1">
                        {`${get(hotel, "hotel_check_in.label", "")} : `}
                      </Typography>
                    </Grid>

                    <Grid item xs className={classes.itemValue}>
                      <EditInput
                        keyOfValue={"hotel_check_in"}
                        value={get(hotel, "hotel_check_in.value", "")}
                        onSaveClick={(keyOfValue, value) =>
                          handleOnUpdateHotelDetails(hotel.establishment_id, keyOfValue, value)
                        }
                      />
                    </Grid>
                  </Grid>

                  {/* check out */}
                  <Grid
                    container
                    className={classes.container}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography className={classes.label} variant="body1">
                        {`${get(hotel, "hotel_check_out.label", "")} : `}
                      </Typography>
                    </Grid>

                    <Grid item xs className={classes.itemValue}>
                      <EditInput
                        keyOfValue={"hotel_check_out"}
                        value={get(hotel, "hotel_check_out.value", "")}
                        onSaveClick={(keyOfValue, value) =>
                          handleOnUpdateHotelDetails(hotel.establishment_id, keyOfValue, value)
                        }
                      />
                    </Grid>
                  </Grid>

                  {/* breakfast time */}
                  <Grid
                    container
                    className={classes.container}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography className={classes.label} variant="body1">
                        {`${get(hotel, "hotel_breakfast_time.label", "")} : `}
                      </Typography>
                    </Grid>

                    <Grid item xs className={classes.itemValue}>
                      <EditInput
                        keyOfValue={"hotel_breakfast_time"}
                        value={get(hotel, "hotel_breakfast_time.value", "")}
                        onSaveClick={(keyOfValue, value) =>
                          handleOnUpdateHotelDetails(hotel.establishment_id, keyOfValue, value)
                        }
                      />
                    </Grid>
                  </Grid>

                  {/* dinner time */}
                  <Grid
                    container
                    className={classes.container}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography className={classes.label} variant="body1">
                        {`${get(hotel, "hotel_dinner_time.label", "")} : `}
                      </Typography>
                    </Grid>

                    <Grid item xs className={classes.itemValue}>
                      <EditInput
                        keyOfValue={"hotel_dinner_time"}
                        value={get(hotel, "hotel_dinner_time.value", "")}
                        onSaveClick={(keyOfValue, value) =>
                          handleOnUpdateHotelDetails(hotel.establishment_id, keyOfValue, value)
                        }
                      />
                    </Grid>
                  </Grid>

                  {/** divider */}
                  <Divider className={classes.divider} />
                </div>
              );
            })}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(HotelDetailsList);
