import { useEffect, useState, useCallback } from "react";
// libs
import _, { debounce } from "lodash";
import { useQuery } from "react-apollo";

// graphql
import { listActivity } from "src/graphql/query/administration";

// constants
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "src/utility/constants";

const initialInputVariables = {
  input: {
    paginationLimit: {
      limit: PAGINATION_LIMIT,
      offset: PAGINATION_OFFSET,
    },
  },
};

/**
 * usePartnerActivities is entry point of data for partnerActivities component
 */
export default function usePartnerActivities() {
  const [listActivities, setListActivities] = useState([]);

  const [where, setWhere] = useState();
  const [hasMore, setHasMore] = useState(true);

  /**
   * fetch activities query
   */
  const { data, loading, fetchMore } = useQuery(listActivity, {
    variables: initialInputVariables,
    notifyOnNetworkStatusChange: true,
  });

  /**
   * handle on scroll to bottom
   */
  const handleOnScrollToBottom = () => {
    if (!hasMore) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: listActivities.length,
          },
          where: where,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult, ...rest }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        // we're retreiving PAGINATION_LIMIT partners each time,
        if (fetchMoreResult.listActivity.length < PAGINATION_LIMIT) {
          setHasMore(false);
        }

        return {
          ...previousResult,
          listActivity: [...previousResult.listActivity, ...fetchMoreResult.listActivity],
        };
      },
    });
  };

  /**
   * handle on input change
   */
  const handleAutocompleteInputChange = value => {
    let whereParsed;

    if (where) {
      whereParsed = JSON.parse(where);

      // checking if previous fetch name and current value are empty
      // skip fetch if same requests
      if (!whereParsed.name && !value) {
        return;
      }
    } else {
      return;
    }

    const updatedWhere = JSON.stringify({
      name: value,
    });
    setWhere(updatedWhere);

    fetchMore({
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
          where: updatedWhere,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult, ...rest }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        // we're retreiving PAGINATION_LIMIT partners each time,
        if (fetchMoreResult.listActivity.length >= PAGINATION_LIMIT) {
          setHasMore(true);
        }

        return {
          previousResult,
          listActivity: [...fetchMoreResult.listActivity],
        };
      },
    });
  };

  const handleAutocompleteInputChangeDebounced = debounce(handleAutocompleteInputChange, 350);

  // get activity list when something change
  useEffect(() => {
    let listActivityResponse = _.get(data, "listActivity", []);

    if (listActivityResponse.length) {
      setListActivities(listActivityResponse);
    }
  }, [data]);

  /**
   * get activities to show
   */
  const getActivitiesForView = useCallback(() => {
    const activitiesForView = listActivities.map(activity => {
      const name = activity.activity_translations.find(activityTranslation => activityTranslation.language_id === "1")
        .name;
      return { id: activity.id, name: name };
    });

    return activitiesForView;
  }, [listActivities]);

  /**
   * get activity selected
   */
  const getActivitySelected = partnerId => {
    return listActivities.find(partner => partner.id === partnerId);
  };

  return {
    getActivitiesForView,
    getActivitySelected,
    handleAutocompleteInputChangeDebounced,
    handleOnScrollToBottom,
    loading,
  };
}
