import { config } from "./globals";

export const findServiceTypeByCroLang = serviceType => {
  return (
    serviceType &&
    serviceType.service_type_translations &&
    serviceType.service_type_translations.find(({ language_id }) => config.language[String(language_id)] === "Croatian")
  );
};

export const findServiceTypeByLangId = (serviceType, lang_id) => {
  return (
    serviceType &&
    serviceType.service_type_translations &&
    serviceType.service_type_translations.find(({ language_id }) => String(language_id) === String(lang_id))
  );
};
