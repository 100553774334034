import React, { Component } from "react";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Button from "@material-ui/core/Button";

import EditServiceTranslations from "./EditPartnerClientServiceTranslationForm";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { decimalNumbersTwoDigits } from "../../../../../utility/numbers";
import FormValidator from "../../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

const fitCard = { width: "fit-content" };

class EditPartnerClientServiceForm extends Component {
  static schema = {
    properties: {
      price: {
        format: "numeric",
        errorMessage: {
          format: "Cijena mora biti broj",
        },
      },
      partner_client_service_type_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati tip usluge",
      },
      partner_client_service_translations: {
        type: "array",
        minItems: 1,
        errorMessage: "Potreban je barem jedan prijevod",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi jezici moraju imati naziv",
            },
            description: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi opisi moraju biti ispunjeni",
            },
          },
        },
      },
    },
  };

  render() {
    const {
      listLanguage,
      listPartnerClientServiceType,
      service,
      changePartnerServiceField,
      saveChanges,
      removeService,
      cancelChanges,
      changePartnerServiceTranslationField,
    } = this.props;

    return (
      <FormValidator schema={EditPartnerClientServiceForm.schema} submit={saveChanges} data={service}>
        {({ error, errorMsg, submitAndRecreate }) => (
          <Grid item xl={6}>
            <Card style={fitCard}>
              <CardHeader title="Izmjena podataka usluge partnera / klijenta" />
              <CardText>
                <Grid container spacing={24}>
                  <Grid item sm={12} lg={6}>
                    <FormControl fullWidth error={error("/price")}>
                      <InputLabel shrink={true} htmlFor="price">
                        Cijena
                      </InputLabel>
                      <Input
                        id="price"
                        name="price"
                        value={service.price ? service.price : ""}
                        onChange={({ target: { value } }) =>
                          changePartnerServiceField({
                            key: "price",
                            value: decimalNumbersTwoDigits(value),
                          })
                        }
                      />
                      {error("/price") && <FormHelperText>{errorMsg("/price")}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} lg={6}>
                    <FormControl fullWidth error={error("/partner_client_service_type_id")}>
                      <TextField
                        fullWidth
                        id="select-currency"
                        select
                        label="Tip usluge"
                        className=""
                        value={service.partner_client_service_type_id || ""}
                        onChange={({ target: { value } }) =>
                          changePartnerServiceField({
                            key: "partner_client_service_type_id",
                            value,
                          })
                        }
                      >
                        {listPartnerClientServiceType &&
                          listPartnerClientServiceType.map(type => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.desc}
                            </MenuItem>
                          ))}
                      </TextField>
                      {error("/partner_client_service_type_id") && (
                        <FormHelperText>{errorMsg("/partner_client_service_type_id")}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <EditServiceTranslations
                  error={error}
                  errorMsg={errorMsg}
                  listLanguage={listLanguage}
                  changePartnerServiceTranslationField={changePartnerServiceTranslationField}
                  service_translations={service.partner_client_service_translations}
                />
              </CardText>
              <CardActions className="cardactions-button-padding">
                <Button variant="contained" size="small" onClick={submitAndRecreate}>
                  Spremi
                </Button>
                <Button variant="outlined" size="small" onClick={() => removeService(service.id)}>
                  Izbriši
                </Button>
                <Button variant="outlined" size="small" onClick={cancelChanges}>
                  Odustani
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
      </FormValidator>
    );
  }
}

export default EditPartnerClientServiceForm;
