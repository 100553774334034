import React from "react";
import moment from "moment";

import DateRangePicker from "./DateRangePicker";
import "./CustomDateRangePicker.css";
import { formatToFilterDate, formatFromFilterDate } from "../../utility/globals";

export const DEFAULT_EMPTY_DATE = "0000-00-00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_DELIMITER = " - ";

class CustomDateRangePicker extends React.Component {
  from = DEFAULT_EMPTY_DATE;
  to = DEFAULT_EMPTY_DATE;

  handleFromChange = date => {
    const { filter } = this.props;
    let { from, to } = this;

    const dates = filter && filter.value && filter.value.split(DEFAULT_DELIMITER);

    from = (dates && dates[0]) || DEFAULT_EMPTY_DATE;
    to = (dates && dates[1]) || DEFAULT_EMPTY_DATE;

    let fromNew = null;

    if (!date) {
      fromNew = DEFAULT_EMPTY_DATE;
      to = DEFAULT_EMPTY_DATE;
    } else {
      fromNew = formatFromFilterDate(date).toISOString();
    }

    if (fromNew === from) {
      return;
    }

    this.props.onChange(fromNew + DEFAULT_DELIMITER + to);
  };

  handleToChange = date => {
    const { filter } = this.props;
    let { from, to } = this;

    const dates = filter && filter.value && filter.value.split(DEFAULT_DELIMITER);

    from = (dates && dates[0]) || DEFAULT_EMPTY_DATE;
    to = (dates && dates[1]) || DEFAULT_EMPTY_DATE;

    let toNew = null;

    if (!date) {
      toNew = DEFAULT_EMPTY_DATE;
      from = DEFAULT_EMPTY_DATE;
    } else {
      toNew = formatToFilterDate(date).toISOString();
    }

    if (toNew === to) {
      return;
    }

    this.props.onChange(from + DEFAULT_DELIMITER + toNew);
  };

  fromPicker = from => {
    if (!from) {
      return null;
    }
    return moment(from, DEFAULT_DATE_FORMAT).isValid() ? moment(from, DEFAULT_DATE_FORMAT) : null;
  };

  toPicker = to => {
    if (!to) {
      return null;
    }
    return moment(to, DEFAULT_DATE_FORMAT).isValid() ? moment(to, DEFAULT_DATE_FORMAT) : null;
  };

  render() {
    const { filter } = this.props;
    let { from, to } = this;

    const dates = filter && filter.value && filter.value.split(DEFAULT_DELIMITER);

    from = (dates && dates[0]) || DEFAULT_EMPTY_DATE;
    to = (dates && dates[1]) || DEFAULT_EMPTY_DATE;

    return (
      <div id="customDRP">
        <DateRangePicker
          from={this.fromPicker(from)}
          to={this.toPicker(to)}
          handleFromChange={this.handleFromChange}
          handleToChange={this.handleToChange}
          appendToBody
          small
          anchorDirection="right"
          noBorder
          showClearDates
        />
      </div>
    );
  }
}

export default CustomDateRangePicker;
