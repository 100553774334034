import React, { useState, useEffect } from "react";

// ui
import { Grid, MenuItem, TextField, Typography, withStyles } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Check";

// hooks
import useLanguageInput from "./hooks/useLanguageInput";

// style
const styles = theme => ({
  container: {
    marginTop: 16,
  },

  itemEditIcon: {
    maxWidth: 24,
  },

  itemSaveIcon: {
    maxWidth: 30,
  },

  button: {
    height: 8,
    width: 8,
    fontSize: 20,
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  itemValue: {
    marginLeft: 8,
  },

  value: {
    fontWeight: "bold",
  },

  input: {
    fontSize: "inherit",
  },

  //style for font size
  resize: {
    fontSize: 13,
  },
});

const announcementLanguages = [
  { id: "1", name: "Engleski" },
  { id: "2", name: "Njemački" },
  { id: "3", name: "Talijanski" },
  { id: "4", name: "Hrvatski" },
  { id: "5", name: "Francuski" },
];

/**
 * LanguageInput is used for handling on language select in announcement
 */
const LanguageInput = ({ classes, label, keyOfValue, value, onSaveClick }) => {
  // hooks
  const { handleEditMode, isEdit } = useLanguageInput(keyOfValue);

  // state
  const [inputValue, setInputValue] = useState("1");

  // handle on value prop change
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // return root
  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item>
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      </Grid>

      <Grid item xs className={classes.itemValue}>
        {!isEdit ? (
          <Grid container alignItems="baseline">
            <Grid item xs>
              {value ? (
                <Typography className={classes.value} variant="body1">
                  {value}
                </Typography>
              ) : (
                <Typography className={classes.hint} variant="body1">
                  Odaberite jezik
                </Typography>
              )}
            </Grid>
            <Grid item xs className={classes.itemEditIcon}>
              <IconButton className={classes.button} aria-label="Ažuriraj polje">
                <EditIcon fontSize="inherit" onClick={() => handleEditMode(true)} />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="flex-start" spacing={16}>
            <Grid item xs>
              <TextField
                className={classes.textField}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                select
                fullWidth
                value={inputValue || ""}
                onChange={event => setInputValue(event.target.value)}
              >
                {announcementLanguages
                  ? announcementLanguages.map(announcementLanguage => (
                      <MenuItem key={announcementLanguage.id} value={announcementLanguage.name}>
                        <Typography variant="body1">{announcementLanguage.name}</Typography>
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
            <Grid item xs className={classes.itemSaveIcon}>
              <IconButton className={classes.button} aria-label="Prekid">
                <CancelIcon
                  onClick={() => {
                    setInputValue(value);
                    handleEditMode(false);
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item xs className={classes.itemSaveIcon}>
              <IconButton color="primary" className={classes.button} aria-label="Spremi">
                <SaveIcon onClick={() => onSaveClick(keyOfValue, inputValue)} />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LanguageInput);
