import * as actionTypes from "../actionTypes";

// all offers filter value
export const setAllOffersInitialQueryInput = (limit, offset, selectedFilters, allOffersFilter) => {
  return {
    type: actionTypes.ALL_OFFERS_INITIAL_QUERY,
    limit: limit,
    offset: offset,
    selectedFilters: selectedFilters,
    allOffersFilter: allOffersFilter,
  };
};

//EDIT DATA
export const changeEditData = obj => {
  return {
    type: actionTypes.CHANGE_EDIT_DATA,
    param: obj,
  };
};

export const toggleCreateSubtermin = () => {
  return {
    type: actionTypes.TOGGLE_CREATE_SUBTERMIN,
  };
};

export const toggleEditSubtermin = id => {
  return {
    type: actionTypes.TOGGLE_EDIT_SUBTERMIN,
    id,
  };
};

export const cancelCreateSubtermin = () => {
  return {
    type: actionTypes.CANCEL_CREATE_SUBTERMIN,
  };
};

export const cancelEditSubtermin = () => {
  return {
    type: actionTypes.CANCEL_EDIT_SUBTERMIN,
  };
};

export const pickEstablishment = data => {
  return {
    type: actionTypes.PICK_ESTABLISHMENT,
    data,
  };
};

export const pickSubterminEstablishment = data => {
  return {
    type: actionTypes.PICK_SUBTERMIN_ESTABLISHMENT,
    data,
  };
};

export const setupInitialOfferDraft = obj => {
  return {
    type: actionTypes.SETUP_INITIAL_DRAFT_OFFER,
    param: obj,
  };
};

export const setupOfferDraftAsync = obj => {
  return {
    type: actionTypes.SETUP_DRAFT_OFFER_ASYNC,
    param: obj,
  };
};

export const changeDocumentParam = obj => {
  return {
    type: actionTypes.CHANGE_OFFER_CONTRACT_DOCUMENT_PARAMETER,
    param: obj,
  };
};

export const addTermin = () => {
  return {
    type: actionTypes.ADD_TERMIN,
  };
};

export const addAlotmanTermin = () => {
  return {
    type: actionTypes.ADD_ALOTMAN_TERMIN,
  };
};

export const saveEditedTermin = (termin, old_termin) => {
  return {
    type: actionTypes.SAVE_EDITED_TERMIN,
    termin,
    old_termin,
  };
};

export const setAddedTermin = addedTermin => {
  return {
    type: actionTypes.SET_ADDED_TERMIN,
    addedTermin,
  };
};

export const setAddedAlotmanTermin = addedTermin => {
  return {
    type: actionTypes.SET_ADDED_ALOTMAN_TERMIN,
    addedTermin,
  };
};

export const resetProgramDays = days => {
  return {
    type: actionTypes.RESET_PROGRAM_DAYS,
  };
};

export const createProgramDays = days => {
  return {
    type: actionTypes.CREATE_PROGRAM_DAYS,
    days,
  };
};

export const setProgramToSend = value => {
  return {
    type: actionTypes.SET_PROGRAM_TO_SEND,
    value,
  };
};

export const changeProgramDayDescription = (dayId, value) => {
  return {
    type: actionTypes.CHANGE_PROGRAM_DAY_DESCRIPTION,
    dayId,
    value,
  };
};

export const changeProgramDayTitle = (dayId, value) => {
  return {
    type: actionTypes.CHANGE_PROGRAM_DAY_TITLE,
    dayId,
    value,
  };
};

export const changeProgramDayContent = (programDayId, programDayContentId, key, id, value) => {
  return {
    type: actionTypes.CHANGE_PROGRAM_DAY_CONTENT,
    programDayId,
    programDayContentId,
    key,
    id,
    value,
  };
};

export const addContigent = (id, dontResetFields) => {
  return {
    type: actionTypes.ADD_CONTIGENT,
    id,
    dontResetFields,
  };
};

export const addAlotmanContigent = termin => {
  return {
    type: actionTypes.ADD_ALOTMAN_CONTIGENT,
    termin,
  };
};

export const saveEditedContigentTypologySum = (contigent, old_contigent) => {
  return {
    type: actionTypes.SAVE_EDITED_CONTIGENT_TYPOLOGY_SUM,
    contigent,
    old_contigent,
  };
};

export const removeContigent = id => {
  return {
    type: actionTypes.REMOVE_CONTIGENT,
    id,
  };
};

export const addSubterminContigent = id => {
  return {
    type: actionTypes.ADD_SUBTERMIN_CONTIGENT,
    id,
  };
};

export const saveNewSubtermin = newSubtermin => {
  return {
    type: actionTypes.SAVE_NEW_SUBTERMIN,
    newSubtermin,
  };
};

export const addSubterminPeriod = () => {
  return {
    type: actionTypes.ADD_SUBTERMIN_PERIOD,
  };
};

export const setSubterminToEdit = index => {
  return {
    type: actionTypes.COPY_SUBTERMIN_TO_EDIT,
    index,
  };
};

export const removeSubtermin = id => {
  return {
    type: actionTypes.REMOVE_SUBTERMIN,
    id,
  };
};

export const removeSubterminContigent = id => {
  return {
    type: actionTypes.REMOVE_SUBTERMIN_CONTIGENT,
    id,
  };
};

export const removeSubterminPeriod = id => {
  return {
    type: actionTypes.REMOVE_SUBTERMIN_PERIOD,
    id,
  };
};

export const changeSubterminField = obj => {
  return {
    type: actionTypes.CHANGE_SUBTERMIN_FIELD,
    obj,
  };
};

export const saveEditedSubtermin = () => {
  return {
    type: actionTypes.SAVE_EDITED_SUBTERMIN,
  };
};

// services_objects_to_create
export const addToCreateServiceType = service_type => {
  return {
    type: actionTypes.ADD_TO_CREATE_SERVICE_TYPE,
    service_type,
  };
};

export const updateToCreateServiceType = service_type => {
  return {
    type: actionTypes.UPDATE_TO_CREATE_SERVICE_TYPE,
    service_type,
  };
};

export const removeToCreateServiceType = id => {
  return {
    type: actionTypes.REMOVE_TO_CREATE_SERVICE_TYPE,
    id,
  };
};

// documentServices
export const addCreatedServiceType = service_type => {
  return {
    type: actionTypes.ADD_CREATED_SERVICE_TYPE,
    service_type,
  };
};

export const updateCreatedServiceType = service_type => {
  return {
    type: actionTypes.UPDATE_CREATED_SERVICE_TYPE,
    service_type,
  };
};

export const removeCreatedServiceType = id => {
  return {
    type: actionTypes.REMOVE_CREATED_SERVICE_TYPE,
    id,
  };
};

export const addAttachment = attachment => {
  return {
    type: actionTypes.ADD_ATTACHMENT,
    attachment,
  };
};

export const removeAttachment = id => {
  return {
    type: actionTypes.REMOVE_ATTACHMENT,
    id,
  };
};

export const removeCreateServiceType = service_type_id => {
  return {
    type: actionTypes.REMOVE_CREATE_SERVICE_TYPE,
    service_type_id,
  };
};

export const removeTermin = id => {
  return {
    type: actionTypes.REMOVE_TERMIN,
    id,
  };
};

export const changeOfferParameter = obj => {
  return {
    type: actionTypes.CHANGE_OFFER_PARAMETER,
    param: obj,
  };
};

export const setOfferToEdit = offer => {
  return {
    type: actionTypes.FETCHED_OFFER_TO_EDIT,
    offer,
  };
};

export const copyUpdatedOffer = offer => {
  return {
    type: actionTypes.COPY_UPDATED_OFFER,
    offer,
  };
};

export const changeOfferCopy = offer_copy => {
  return {
    type: actionTypes.CHANGE_OFFER_COPY_FIELDS,
    offer_copy,
  };
};

export const saveCreatedProgram = program => {
  return {
    type: actionTypes.SAVE_CREATED_PROGRAM,
    program,
  };
};

export const copyUpdatedProgramDays = program => {
  return {
    type: actionTypes.COPY_UPDATED_PROGRAM_DAYS,
    program,
  };
};

export const changePriceStructure = changes => {
  return {
    type: actionTypes.CHANGE_PRICE_STRUCTURE,
    changes,
  };
};

export const changePriceStructurePrice = changes => {
  return {
    type: actionTypes.CHANGE_PRICE_STRUCTURE_PRICE,
    changes,
  };
};

export const resetPriceStructureChanges = (price_structure, terminId) => {
  return {
    type: actionTypes.RESET_PRICE_STRUCTURE_CHANGES,
    price_structure,
    terminId,
  };
};

export const submitCreatedoffer = changes => {
  return {
    type: actionTypes.SUBMIT_CREATED_OFFER,
    changes,
  };
};

export const resetStore = () => {
  return {
    type: actionTypes.RESET_STORE,
  };
};

export const changeTemplateLanguage = template => {
  return {
    type: actionTypes.CHANGE_TEMPLATE_LANGUAGE,
    template,
  };
};

export const addAdditionalService = service => {
  return {
    type: actionTypes.ADD_ADDITIONAL_SERVICE,
    service,
  };
};

export const changeAdditionalService = (param, index) => {
  return {
    type: actionTypes.CHANGE_ADDITIONAL_SERVICE,
    param,
    index,
  };
};

export const deleteAdditionalService = id => {
  return {
    type: actionTypes.DELETE_ADDITIONAL_SERVICE,
    id,
  };
};

export const setProgramDaysSum = value => {
  return {
    type: actionTypes.SET_PROGRAM_DAYS,
    value,
  };
};
