import React, { Component } from "react";
import { formatDate } from "../../../utility/dates";

class PreviewTermin extends Component {
  render() {
    const { from, to } = this.props.voucher;

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>TERMIN</h4>
        </div>
        <div disabled id="from_to" className="preview-content-div preview-textarea">
          {from && formatDate(from)} - {to && formatDate(to)}
        </div>
      </div>
    );
  }
}

export default PreviewTermin;
