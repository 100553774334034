import React, { Fragment, Component } from "react";
import { graphql } from "react-apollo";

import RaisedButton from "material-ui/RaisedButton";

import { listActivityType } from "../../../../graphql/query/administration";
import EditIcon from "../../../../components/UI/EditIcon";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

class ActivityTypeList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: activity => <EditIcon onClick={() => this.props.openEdit(activity)} />,
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Opis",
        accessor: "desc",
      },
    ],
  };

  render() {
    const {
      toggleCreate,
      data: { loading, listActivityType },
    } = this.props;

    if (loading) {
      return <div> Loading . . . </div>;
    }

    return (
      <Fragment>
        <ReactTableCustom
          data={listActivityType || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  graphql(listActivityType, {
    options: {
      fetchPolicy: "network-only",
    },
  })(ActivityTypeList),
);
