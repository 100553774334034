import React, { Component } from "react";

import ReactTableCustom from "../../../../../components/UI/ReactTable";
import BootstrapLikeInput from "../../../../../components/UI/BootstrapLikeInput";

class CalculatorEstablishmentInfo extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(nextProps.establishment.establishment_info) !==
        JSON.stringify(this.props.establishment.establishment_info) ||
      nextProps.establishment.index !== this.props.establishment.index
    );
  }

  changeEstablishmentInfoField = (prevState, establishment_id, index, key, value) => {
    return {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        establishments: prevState.calculator.establishments.map((est, est_index) => {
          if (establishment_id === est.id && est_index === index) {
            return {
              ...est,
              establishment_info: {
                ...est.establishment_info,
                [key]: value,
              },
            };
          }
          return est;
        }),
      },
    };
  };

  render() {
    const { vat, getEstablishmentMethods, establishment } = this.props;

    const data = [{ ...establishment.establishment_info, index: establishment.index }];

    const { changeEstablishmentInfo, changeEstablishmentInfoQuantity } = getEstablishmentMethods({
      changeEstablishmentInfoField: this.changeEstablishmentInfoField,
      changeEstablishmentInfoQuantityField: this.changeEstablishmentInfoField,
    });

    return (
      <div className="content-print">
        <ReactTableCustom
          data={data}
          columns={[
            {
              Header: "INFO",
              columns: [
                {
                  Header: "SMJEŠTAJ",
                  id: "service",
                  accessor: d => (
                    <BootstrapLikeInput
                      value={d.service}
                      onChange={changeEstablishmentInfo(establishment.id, establishment.index, "service")}
                    />
                  ),
                  Footer: <strong>UKUPNO SMJEŠTAJ</strong>,
                },
                {
                  Header: "PDV",
                  id: "vat",
                  width: 65,
                  accessor: d => (
                    <div className="print-span-container">
                      <select
                        className="booking-select "
                        onChange={changeEstablishmentInfo(establishment.id, establishment.index, "vat")}
                        value={d.vat}
                      >
                        <option value="" />
                        {vat &&
                          vat.map(v => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          ))}
                      </select>
                      <span className="select-print" style={{ display: "none" }}>
                        {d.vat}
                      </span>
                    </div>
                  ),
                },
                {
                  Header: "KOLIČINA",
                  id: "quantity",
                  width: 75,
                  accessor: d => {
                    return (
                      <BootstrapLikeInput
                        value={d.quantity}
                        onChange={changeEstablishmentInfoQuantity(establishment.id, d.index, "quantity")}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          innerProps={{
            pageSize: data.length,
            showPagination: false,
            sortable: false,
            filterable: false,
            filterAll: false,
            noDataText: "Nema dodanih usluga",
          }}
        />
      </div>
    );
  }
}

export default CalculatorEstablishmentInfo;
