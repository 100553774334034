// libs
import { createSlice } from "@reduxjs/toolkit";

export const voucherSlice = createSlice({
  name: "voucher",
  initialState: {
    isVoucherViewerOpened: false,
    voucherSelected: {},
  },
  reducers: {
    // voucher list
    setAnnouncementVouchers: (state, action) => {
      state.announcementVouchers = action.payload;
    },

    // add voucher to list
    addAnnouncementVoucher: (state, action) => {
      const announcementVouchersUpdated = [action.payload, ...state.announcementVouchers];

      state.announcementVouchers = announcementVouchersUpdated;
    },

    // voucher selected
    setVoucherSelected: (state, action) => {
      state.isVoucherViewerOpened = action.payload.isVoucherViewerOpened;
      state.voucherSelected = action.payload.voucherSelected;
    },
  },
});

export const { setAnnouncementVouchers, addAnnouncementVoucher, setVoucherSelected } = voucherSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`

// voucher list
export const selectAnnouncementVouchers = state => state.voucher.announcementVouchers;

// voucher viewer open state
export const selectVoucherViewerOpened = state => state.voucher.isVoucherViewerOpened;

// voucher selected
export const selectVoucherSelected = state => state.voucher.voucherSelected;

export default voucherSlice.reducer;
