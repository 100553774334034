import React, { Component } from "react";
import moment from "moment";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

import { withRouter } from "react-router-dom";

import Paper from "material-ui/Paper";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import Button from "@material-ui/core/Button";

import DateRangePicker from "../../../../../components/UI/DateRangePicker";

import EstablishmentData from "./EstablishmentData";

import { connect } from "react-redux";

import * as actions from "../../../../../store/actions";
import ContigentsList from "../../EstablishmentData/ContigentsList";
import { Typography } from "@material-ui/core";
import { contigentFragment, terminsFragment } from "../../../../../graphql/fragments";
import { prepareForSending } from "../../../../../utility/prepare";

import toastr from "toastr";

const createTermin = gql`
  mutation(
    $from: DateTime!
    $to: DateTime!
    $termin_period_id: ID
    $contigents: [CreateContigentInput]
    $offer_contract_document_id: ID
    $price_structure: CreatePriceStructureInput!
  ) {
    createTerminPeriod(
      input: {
        from: $from
        to: $to
        termin_period_id: $termin_period_id
        contigents: $contigents
        price_structure: $price_structure
        offer_contract_document_id: $offer_contract_document_id
      }
    ) {
      ...Termin
      termin_period_id
      contigents {
        ...Contigent
      }
    }
  }
  ${terminsFragment}
  ${contigentFragment}
`;

class SubterminForm extends Component {
  render() {
    const {
      title,
      subtermin,
      termin_periods,
      changeSubterminField,
      listEstablishment,
      language_id,
      removeContigent,
      cancel,
      save,
      create,
    } = this.props;
    const { from, to } = termin_periods[0];

    return (
      <Paper style={{ minHeight: "470px", position: "relative" }}>
        <Toolbar>
          <ToolbarGroup firstChild={true}>
            <Typography variant="title" color="inherit" style={{ padding: "0px 10px" }}>
              {title}
            </Typography>
          </ToolbarGroup>
        </Toolbar>
        <div style={{ padding: "10px", display: "grid", gridRowGap: "10px" }}>
          <div className="form-unit-double">
            <div className="field-label is-normal">
              <label className="label">Termin</label>
            </div>
            <DateRangePicker
              from={subtermin.from}
              to={subtermin.to}
              handleFromChange={date =>
                changeSubterminField({
                  key: "from",
                  value: date,
                })
              }
              handleToChange={date =>
                changeSubterminField({
                  key: "to",
                  value: date,
                })
              }
              isOutsideRange={day => day.isBefore(from) || day.isAfter(to)}
              initialVisibleMonth={() => moment(from)}
            />
          </div>

          <EstablishmentData
            create={create}
            listEstablishment={listEstablishment}
            listEstablishmentQuery={this.props.listEstablishmentQuery}
          />

          <ContigentsList
            language_id={language_id}
            removeSubterminContigent={removeContigent}
            termin_periods={[subtermin]}
          />

          <div className="subtermin-create-buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={save}
              disabled={
                !language_id ||
                !subtermin.from ||
                !subtermin.to ||
                (!subtermin.contigents || !subtermin.contigents.length)
              }
            >
              Spremi
            </Button>
            <Button variant="contained" color="primary" onClick={cancel}>
              Odustani
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

class CreateSubtermin extends Component {
  addTerminHandler = async () => {
    // if it's in crete mode
    if (!this.props.match.params.id) {
      return this.props.saveNewSubtermin();
    }

    try {
      // if we're in edit mode
      const { subtermin, termin_periods, transport, offer_contract_document_id } = this.props;

      if (this.props.match.params.id && subtermin.id.includes("-")) {
        const response = await this.props.mutate({
          variables: {
            ...prepareForSending(subtermin, ["id", "typology"]),
            transport,
            termin_period_id: termin_periods[0].id,
            price_structure: {
              tourist_tax: "0",
              comment_price_structure: "",
            },
            offer_contract_document_id,
          },
        });

        this.props.saveNewSubtermin(prepareForSending(response.data.createTerminPeriod, ["__typename"]));

        toastr.success("Uspješno kreiran termin");
      } else {
      }
    } catch (error) {}
  };

  render() {
    const {
      create_subtermin,
      changeSubterminField,
      listEstablishment,
      subtermin,
      removeSubterminContigent,
      cancelCreateSubtermin,
      language_id,
      ...rest
    } = this.props;

    return create_subtermin ? (
      <SubterminForm
        create={true}
        language_id={language_id}
        title="Stvaranje podtermina"
        changeSubterminField={changeSubterminField}
        listEstablishment={listEstablishment}
        subtermin={subtermin}
        removeContigent={removeSubterminContigent}
        save={this.addTerminHandler}
        cancel={cancelCreateSubtermin}
        {...rest}
      />
    ) : null;
  }
}

const CreateSubterminGraphql = graphql(createTermin)(withRouter(CreateSubtermin));

const updateTermin = gql`
  mutation($id: ID!, $from: DateTime, $to: DateTime) {
    updateTerminPeriod(input: { id: $id, patch: { from: $from, to: $to } }) {
      id
    }
  }
`;

class EditSubtermin extends Component {
  saveChanges = async () => {
    const { subtermin } = this.props;

    if (subtermin.id.includes("-")) {
      this.props.saveEditedSubtermin();
    } else {
      try {
        this.props.mutate({
          variables: {
            id: subtermin.id,
            from: subtermin.from,
            to: subtermin.to,
          },
        });

        this.props.saveEditedSubtermin();

        toastr.success("Termin uspješno ažuriran");
      } catch (error) {}
    }
  };

  render() {
    const {
      edit_subtermin,
      changeSubterminField,
      listEstablishment,
      subtermin,
      removeSubterminContigent,
      cancelEditSubtermin,
      language_id,
      ...rest
    } = this.props;

    return edit_subtermin ? (
      <SubterminForm
        language_id={language_id}
        title="Ažuriranje podtermina"
        changeSubterminField={changeSubterminField}
        listEstablishment={listEstablishment}
        subtermin={subtermin}
        removeContigent={removeSubterminContigent}
        save={this.saveChanges}
        cancel={cancelEditSubtermin}
        {...rest}
      />
    ) : null;
  }
}

const EditSubterminGraphql = graphql(updateTermin)(withRouter(EditSubtermin));

class Subtermin extends Component {
  render() {
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        ...this.props,
      }),
    );
  }
}

function Usage(props) {
  return (
    <Subtermin {...props}>
      <CreateSubterminGraphql />
      <EditSubterminGraphql />
    </Subtermin>
  );
}

const mapStateToProps = state => {
  const {
    subtermin_from,
    subtermin_to,
    subtermin,
    create_subtermin,
    transport,
    edit_subtermin,
    offer: {
      offer_contract_document: { language_id, termin_periods, id: offer_contract_document_id },
    },
  } = state.offer;

  return {
    subtermin_from,
    subtermin_to,
    subtermin,
    create_subtermin,
    edit_subtermin,
    language_id,
    transport,
    termin_periods,
    offer_contract_document_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeSubterminField: obj => dispatch(actions.changeSubterminField(obj)),
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    addSubterminPeriod: () => dispatch(actions.addSubterminPeriod()),
    saveNewSubtermin: termin => dispatch(actions.saveNewSubtermin(termin)),
    removeSubterminPeriod: () => dispatch(actions.removeSubterminPeriod()),
    removeSubterminContigent: id => dispatch(actions.removeSubterminContigent(id)),
    saveEditedSubtermin: () => dispatch(actions.saveEditedSubtermin()),
    cancelEditSubtermin: () => dispatch(actions.cancelEditSubtermin()),
    cancelCreateSubtermin: () => dispatch(actions.cancelCreateSubtermin()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Usage);
