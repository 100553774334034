import React from "react";

// hooks
import useBusTransfer from "./hooks/useBusTransfer";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import BusTransferContainer from "./components/Container";

// constants
import { TITLE_GUIDE_DRAWER_BUS_TRANSFER } from "src/utility/labels";

/**
 * BusTransfer drawer is used for showing data of olivari bus transfer and its comment
 */
export default function BusTransfer() {
  const { handleOnBusTransferStatusChange, isDrawerOpen, loading, onCloseDrawer } = useBusTransfer();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      title={TITLE_GUIDE_DRAWER_BUS_TRANSFER}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnBusTransferStatusChange}
      customWidth="33%"
    >
      <BusTransferContainer />
    </DrawerWrapper>
  );
}
