import React from "react";
import { Switch, Route } from "react-router-dom";

import EditTemplate from "./EditTemplate";
import TemplateList from "./TemplateList";

const TemplateRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/predlozak/"
        exact
        render={props => <TemplateList {...props} tableName={"administrationTemplates"} />}
      />

      <Route path="/administracija/predlozak/edit/:id" exact component={EditTemplate} />
    </Switch>
  );
};

export default TemplateRouter;
