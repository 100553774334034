// libs
import toastr from "toastr";
import { get } from "lodash";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectGuideScheduleDrawers,
  selectGuideSchedules,
  setGuideDrawerPayload,
  setGuideDrawers,
  setGuideSchedules,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";
// constants

import { GUIDE_DRAWER_PAYMENT } from "src/utility/constants";

/**
 * usePayment is entry point of data for payment drawer
 */
export default function usePayment() {
  // redux state
  const guideDrawers = useSelector(selectGuideScheduleDrawers);
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // change program day title
  const [updateGuideExtraFields, { loading }] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_PAYMENT,
        isOpen: false,
      }),
    );
  };

  /**
   * handle on payment text change
   */
  const handleOnPaymentTextChange = async () => {
    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: guidePayload.programId,
          payment: guidePayload.payment,
        },
      },
    });

    const guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran tekst naplate");

      const payment = get(guideUpdate, "payment", "");

      // update list guides
      const listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === guidePayload.programId) {
          return {
            ...guide,
            payment: payment,
          };
        }
        return guide;
      });

      // update guide shedules list in state
      dispatch(setGuideSchedules(listGuidesUpdate));
      // close drawer
      onCloseDrawer();
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    handleOnPaymentTextChange,
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_PAYMENT],
    loading,
    onCloseDrawer,
  };
}
