import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import React from "react";
import Popover from "react-awesome-popover";
import { connect } from "react-redux";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import { changeAdministrationTypologyData } from "../../../../store/actions";
import TypologyTranslations from "./TypologyTranslations";

const ShowTypologyForm = ({
  typology,
  establishment,
  listLanguage,
  setForEdit,
  createMode,
  editTypology,
  removeTypologyFromEstablishment,
  listTypologyGroup,
}) => (
  <Card>
    <CardHeader title="Tipologija" />
    <CardText>
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor="establishment_code">
              Alotmanski kod
            </InputLabel>
            <Input
              id="establishment_code"
              name="establishment_code"
              value={typology.establishment_code ? typology.establishment_code : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor="code">
              Kod
            </InputLabel>
            <Input
              id="code"
              name="code"
              disabled
              value={typology.code ? typology.code : ""}
              onChange={({ target: { value } }) =>
                changeAdministrationTypologyData({
                  key: "code",
                  value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor="persons_capacity">
              Kapacitet osoba
            </InputLabel>
            <Input
              id="persons_capacity"
              name="persons_capacity"
              value={typology.persons_capacity ? typology.persons_capacity : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel shrink={true} htmlFor="priority">
              Prioritet
            </InputLabel>
            <Input
              id="priority"
              name="priority"
              value={typology.priority.toString() ? typology.priority.toString() : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <AutocompleteSelect
            label="Grupa tipologije"
            defaultValue={typology.typology_group_id || ""}
            disabled
            dataSource={listTypologyGroup && listTypologyGroup.map(obj => ({ value: obj.id, label: obj.name }))}
            placeholder="Odabir grupe tipologije"
          />
        </Grid>
      </Grid>
      <TypologyTranslations
        createMode={createMode}
        editTypology={editTypology}
        typology_translations={typology.typology_translations}
        listLanguage={listLanguage}
      />
    </CardText>
    <CardActions className="cardactions-button-padding">
      <Button variant="contained" size="small" onClick={() => setForEdit(typology)}>
        Izmijeni
      </Button>
      <Popover action="click" placement="bottom" contentClass="rap-popover-content">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={establishment.typologies && establishment.typologies.length < 2}
        >
          Izbriši
        </Button>
        <div className="pad">
          <Button
            variant="contained"
            size="small"
            classes={{ root: "is-success" }}
            onClick={removeTypologyFromEstablishment}
          >
            Potvrdi
          </Button>
        </div>
      </Popover>
    </CardActions>
  </Card>
);

const mapStateToProps = state => {
  const { establishment } = state.administration;
  return { establishment };
};

export default connect(mapStateToProps, { changeAdministrationTypologyData })(ShowTypologyForm);
