import React from "react";
import ReactToPrint from "react-to-print";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { blue500, grey900, fullWhite } from "material-ui/styles/colors";
import RaisedButton from "material-ui/RaisedButton";

import { Paper } from "@material-ui/core";

class AllContractsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { selectDataToShow } = this.props;

    return (
      <Toolbar className="toolbar-transparent toolbar-sm">
        <ToolbarGroup>
          <ToolbarTitle
            text="Ugovori"
            style={{
              color: grey900
            }}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ReactToPrint
            pageStyle={`
           @media print{
                       @page {
                         size: landscape;
                       }
                       .ReactTable .-pagination{
                         height:50px
                       }
                     }`}
            trigger={() => (
              <RaisedButton
                label="Print"
                backgroundColor={blue500}
                labelColor={fullWhite}
              />
            )}
            content={() => this.props.contentToPrint()}
          />

          <Paper style={{ marginLeft: "5px", height: "36px" }}>
            <select
              style={{ height: "inherit" }}
              className="booking-select "
              onChange={({ target: { value } }) => selectDataToShow(value)}
              value={this.props.checked}
            >
              <option value="0">Aktivno</option>
              <option value="1">Arhivirano</option>
              <option value="2">Stornirano</option>
            </select>
          </Paper>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

export default AllContractsToolbar;
