import React, { Fragment } from "react";

// ui
import Button from "@material-ui/core/Button";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { blue500 } from "material-ui/styles/colors";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";

// hooks
import useAdditionalFunctions from "./hooks/useAdditionalFunctions";

// custom mui theme
const theme = createMuiTheme({
  palette: {
    primary: { main: blue500 },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: "5px",
      },
      raisedPrimary: {
        maxWidth: 150,
        color: "white",
      },
    },
    MuiSvgIcon: {
      root: {
        marginRight: 8,
        fontSize: 20,
      },
    },
  },
});

/**
 * AdditionalFunctions column
 */
export default function AdditionalFunctions({
  programId,
  externalId,
  themeName,
  terminFrom,
  terminTo,
  hasDictate,
  dictateId,
}) {
  const { handleOnAnnouncementClick, handleOnAnnouncementOrderClick } = useAdditionalFunctions(programId);
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <Button
            onClick={() => handleOnAnnouncementClick(programId)}
            color="primary"
            fullWidth
            variant="contained"
            size="small"
          >
            Najava
          </Button>
        </Grid>

        <Grid item xs={8}>
          <Button
            onClick={() =>
              handleOnAnnouncementOrderClick(
                programId,
                externalId,
                themeName,
                terminFrom,
                terminTo,
                hasDictate,
                dictateId,
              )
            }
            color="primary"
            fullWidth
            variant="contained"
            size="small"
          >
            {hasDictate ? (
              "Nalog"
            ) : (
              <Fragment>
                <AddIcon />
                Kreiraj nalog
              </Fragment>
            )}
          </Button>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}
