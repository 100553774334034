// libs
import { useTableLocalStorage } from "src/hooks/useTableLocalStorage";
import moment from "moment";
import { LOCAL_STORAGE_GUIDES_TABLE } from "src/utility/constants";

/**
 * useYearFilter is used for data handling for YearFilter component
 */
export default function useYearFilter() {
  // local storage
  const { tableState, setTableState } = useTableLocalStorage(LOCAL_STORAGE_GUIDES_TABLE);

  const filterDateSelected = moment(tableState.dateYearFilterSelected) || moment();

  /**
   * handle on filter year change
   */
  const handleYearChange = date => {
    setTableState({ ...tableState, pageSelected: 0, dateYearFilterSelected: date });
  };

  return { filterDateSelected, handleYearChange };
}
