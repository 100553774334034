import React, { Component } from "react";
import { graphql } from "react-apollo";
import moment from "moment";
import toastr from "toastr";
import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import DatePicker from "../../../../components/UI/DatePicker";
import { terminsFragment } from "../../../../graphql/fragments";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

class NotificationStateDrawer extends Component {
  state = {
    notification_booking_state_date: "",
    def: false,
  };

  updateData = () => {
    const { notification_booking_state_date, def } = this.state;

    /**
     * check to see if this is a subTermin || if it has a parentId, then we'll change a parent termin notification_booking_state_date
     * and show that in list
     */
    let id = this.props.parent_id ? this.props.parent_id : this.props.id;

    if (notification_booking_state_date !== this.props.notification_booking_state_date) {
      try {
        this.props.mutate({
          variables: {
            id: id,
            patch: {
              notification_booking_state_date,
              def,
            },
          },
          update: (store, { data: { updateTerminPeriod } }) => {
            store.writeFragment({
              id: `Termin:${updateTerminPeriod.id}`,
              fragmentName: "Termin",
              fragment: terminsFragment,
              data: updateTerminPeriod,
            });
          },
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  changeDef = def => () => {
    const { notification_booking_state_date } = this.props;

    this.setState({
      notification_booking_state_date: def === true ? moment() : notification_booking_state_date,
      def,
    });
  };

  componentDidMount = () => {
    const { notification_booking_state_date, def } = this.props;

    this.setState({ notification_booking_state_date, def });
  };

  render() {
    const { toggle, open } = this.props;

    return (
      <DrawerWrapper toggle={toggle} update={this.updateData} open={open} title="Datum dostave bookinga">
        <div className="form-control-grid">
          <DatePicker
            value={this.state.notification_booking_state_date}
            handleChange={date => this.setState({ notification_booking_state_date: date })}
            vertical
          />
        </div>
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={this.state.def} onChange={this.changeDef(!this.state.def)} color="primary" />}
            label="Def"
          />
        </FormGroup>
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(NotificationStateDrawer);
