// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectAgencyLocalAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_AGENCY_LOCAL_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useAgencyLocalAnnouncement is entry point of data for agency local announcement form component
 */
export default function useAgencyLocalAnnouncement() {
  // redux state
  const agencyLocalAnnouncement = useSelector(selectAgencyLocalAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updateAgencyLocalAnnouncement, { loading }] = useMutation(UPDATE_AGENCY_LOCAL_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdateAgencyLocalAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updateAgencyLocalAnnouncement({
      variables: {
        input: {
          id: agencyLocalAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateAgencyLocalAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ agencyLocalAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  /**
   * handle on update hotel details
   */
  const handleOnUpdateHotelDetails = async (establishmentId, keyOfValue, value) => {
    const updateAnnouncement = await updateAgencyLocalAnnouncement({
      variables: {
        input: {
          id: agencyLocalAnnouncement.id,
          hotel_extra_fields: [
            {
              establishment_id: establishmentId,
              [keyOfValue]: value,
            },
          ],
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateAgencyLocalAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ agencyLocalAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    agencyLocalAnnouncement,
    handleOnUpdateAgencyLocalAnnouncement,
    handleOnUpdateHotelDetails,
    loading,
  };
}
