import React from "react";
import Button from "@material-ui/core/Button";
import FormValidator from "../../../../components/UI/FormValidator";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { listLanguage } from "../../../../graphql/query/administration";
import { graphql } from "react-apollo";
import BootstrapLikeInput from "../../../../components/UI/BootstrapLikeInput";

const form = {
  padding: "24px",
};

const ServiceTypeForm = ({
  title,
  serviceType,
  onSubmit,
  cancelToggle,
  fetchLanguages: { listLanguage },
  changeTranslationField,
}) => {
  if (!listLanguage) {
    return "Dohvaćam jezike";
  }

  return (
    <FormValidator schema={ServiceTypeForm.schema} submit={onSubmit} data={serviceType}>
      {({ error, errorMsg, submit }) => (
        <div style={form}>
          <h3>{title}</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Jezik</TableCell>
                <TableCell>Naziv</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listLanguage.map((language, index) => {
                const translation =
                  serviceType &&
                  serviceType.service_type_translations &&
                  serviceType.service_type_translations.find(translation => translation.language_id === language.id);

                if (!translation) {
                  return null;
                }

                const name = translation.name;

                return (
                  <TableRow key={language.id} className="align-top">
                    <TableCell component="th" scope="row">
                      {language.desc}
                    </TableCell>
                    <TableCell>
                      <BootstrapLikeInput
                        label=""
                        id="name"
                        error={error(`/service_type_translations/${index}/name`)}
                        errorMsg={errorMsg(`/service_type_translations/${index}/name`)}
                        value={name}
                        onChange={changeTranslationField(language.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <br />
          <Button variant="contained" onClick={submit}>
            Spremi
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={cancelToggle}>
            Poništi
          </Button>
        </div>
      )}
    </FormValidator>
  );
};

ServiceTypeForm.schema = {
  properties: {
    service_type_translations: {
      type: "array",
      minItems: 1,
      errorMessage: "Potreban je barem jedan prijevod",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            minLength: 1,
            isNotEmpty: true,
            errorMessage: "Svi jezici moraju imati naziv",
          },
        },
      },
    },
  },
};

export default graphql(listLanguage, {
  name: "fetchLanguages",
})(ServiceTypeForm);
