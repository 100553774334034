import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useAgencyLocalProgramAnnouncement from "./hooks/useAgencyLocalProgramAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import ProgramDetails from "../ProgramDetails";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * AgencyLocalProgramAnnouncement
 */
const AgencyLocalProgramAnnouncement = ({ classes }) => {
  // hooks
  const {
    agencyLocalProgramAnnouncement,
    handleOnUpdateAgencyLocalProgramAnnouncement,
  } = useAgencyLocalProgramAnnouncement();

  const title = get(agencyLocalProgramAnnouncement, "announcement_title.value", "");
  const intro = get(agencyLocalProgramAnnouncement, "intro_message.placeholder", "");
  const footer = get(agencyLocalProgramAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* accommodation */}
      <AnnouncementTextField
        announcementField={get(agencyLocalProgramAnnouncement, "accommodation")}
        keyOfValue={"accommodation"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyLocalProgramAnnouncement}
      />

      {/* olivari_guide_with_group */}
      <AnnouncementTextField
        announcementField={get(agencyLocalProgramAnnouncement, "olivari_guide_with_group")}
        keyOfValue={"olivari_guide_with_group"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyLocalProgramAnnouncement}
      />

      {/* program */}
      <ProgramDetails
        label={`${get(agencyLocalProgramAnnouncement, "program_label.value", "")} : `}
        programList={get(agencyLocalProgramAnnouncement, "program", "")}
      />

      {/* payment */}
      <AnnouncementTextField
        announcementField={get(agencyLocalProgramAnnouncement, "payment")}
        keyOfValue={"payment"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateAgencyLocalProgramAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AgencyLocalProgramAnnouncement);
