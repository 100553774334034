import gql from "graphql-tag";
import { attachmentFragment } from "../fragments";

export const updateInquiry = gql`
  mutation($id: ID, $patch: UpdateInquiry!) {
    updateInquiry(input: { id: $id, patch: $patch }) {
      id
      external_id
      title
      remark
      partner_client_id
      attachments {
        ...Attachment
      }
      offer_contract_documents {
        id
      }
    }
  }
  ${attachmentFragment}
`;

export const createInquiry = gql`
  mutation($input: CreateInquiryInput!) {
    createInquiry(input: $input) {
      id
      external_id
      title
      remark
      partner_client_id
      attachments {
        ...Attachment
      }
      offer_contract_documents {
        id
      }
    }
  }
  ${attachmentFragment}
`;

export const inquiryAttachmentUpload = gql`
  mutation($inquiry_id: ID!, $file: Upload!) {
    inquiryAttachmentUpload(inquiry_id: $inquiry_id, file: $file) {
      ...Attachment
    }
  }
  ${attachmentFragment}
`;

export const deleteInquiryFile = gql`
  mutation($file_id: ID!) {
    deleteInquiryFile(file_id: $file_id)
  }
`;
