import { Button, CircularProgress, withStyles } from "@material-ui/core";
import gql from "graphql-tag";
import RaisedButton from "material-ui/RaisedButton";
import { blue500, fullWhite, grey800, grey900, red500 } from "material-ui/styles/colors";
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import React from "react";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { offerChanges, programDaysChanges } from "../../../utility/globals";
import OfferForm from "./OfferForm";

export const OfferDuplicate = React.createContext();

class OfferToolbar extends React.Component {
  static DuplicateOffer = ({ id, clicked }) =>
    id ? (
      <RaisedButton label="Duplicirajte ponudu" backgroundColor={blue500} labelColor={fullWhite} onClick={clicked} />
    ) : null;

  duplicating = false;

  state = {
    open: false,
    duplicating: false,
    saving: false,
    savingArchiveStatus: false,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openInNewWindow = () => {
    const windowHeight = window.innerHeight;

    localStorage.setItem("preview", JSON.stringify(this.props.preview));

    window.open(
      `/ponude/pregled`,
      "_blank",
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=793.7007874,height=${windowHeight}`,
    );
  };

  duplicateOffer = async offer_contract_document_id => {
    try {
      this.setState({
        duplicating: true,
      });
      const response = await this.props.mutate({
        variables: {
          offer_contract_document_id,
        },
      });

      toastr.success("Ponuda uspješno duplicirana i prebačeni ste na editiranje nove ponude");
      this.setState({
        duplicating: false,
      });
      this.props.history.push(`/ponude/edit/${response.data.copyOffer.id}`);
    } catch (error) {
      this.setState({
        duplicating: false,
      });
    }
  };

  submit = async errors => {
    if (errors && errors.length) {
      return toastr.error("Pregledajte sva polja", null, {
        showMethod: "show",
      });
    }

    if (this._isMounted) {
      this.setState({ saving: true });

      await this.props.onSave();

      if (this._isMounted) {
        this.setState({ saving: false });
      }
    }
  };

  submit = async errors => {
    if (errors && errors.length) {
      return toastr.error("Pregledajte sva polja", null, {
        showMethod: "show",
      });
    }

    if (this._isMounted) {
      this.setState({ saving: true });

      await this.props.onSave();

      if (this._isMounted) {
        this.setState({ saving: false });
      }
    }
  };

  render() {
    const { classes } = this.props;

    let isSubmitable = true;

    if (this.props.match.params.id) {
      const offer_changes = offerChanges(this.props.offer, this.props.offer_copy) || {};
      const program_day_changes = programDaysChanges(this.props.program_days, this.props.program_days_copy) || {};

      // if in edit mode and have changes
      isSubmitable = !!Object.keys(offer_changes).length || !!Object.keys(program_day_changes).length;
    }

    return (
      <OfferForm.Consumer>
        {({ errors }) => (
          <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
            <ToolbarGroup>
              <ToolbarTitle
                text={this.props.title}
                style={{
                  color: grey900,
                }}
              />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton
                label="Pregled"
                backgroundColor={blue500}
                labelColor={fullWhite}
                onClick={this.openInNewWindow}
              />

              <OfferDuplicate.Consumer>
                {({ offer_contract_document_id }) =>
                  this.state.duplicating ? (
                    <Button variant="contained" disabled color="secondary" className={classes.button}>
                      <CircularProgress
                        classes={{
                          root: `${classes.leftIcon} ${classes.iconSmall}`,
                        }}
                      />
                      Dupliciram
                    </Button>
                  ) : (
                    <OfferToolbar.DuplicateOffer
                      id={this.props.match.params.id}
                      clicked={() => this.duplicateOffer(offer_contract_document_id)}
                    />
                  )
                }
              </OfferDuplicate.Consumer>

              {this.state.saving ? (
                <Button variant="contained" disabled color="secondary" className={classes.button}>
                  <CircularProgress
                    classes={{
                      root: `${classes.leftIcon} ${classes.iconSmall}`,
                    }}
                  />
                  Spremanje
                </Button>
              ) : (
                <RaisedButton
                  onClick={() => this.submit(errors)}
                  disabled={!isSubmitable}
                  label="Spremi"
                  backgroundColor={errors && errors.length ? red500 : grey800}
                  labelColor={fullWhite}
                />
              )}
            </ToolbarGroup>
          </Toolbar>
        )}
      </OfferForm.Consumer>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
    width: "20px !important",
    height: "20px !important",
  },
});

const copyOffer = gql`
  mutation($offer_contract_document_id: ID!) {
    copyOffer(offer_contract_document_id: $offer_contract_document_id) {
      id
    }
  }
`;

const mapStateToProps = ({ offer }) => ({
  preview: offer.offer,
  offer: offer.offer,
  offer_copy: offer.offer_copy,
  program_days: offer.program_days,
  program_days_copy: offer.program_days_copy,
});

export default graphql(copyOffer)(connect(mapStateToProps)(withRouter(withStyles(styles)(OfferToolbar))));
