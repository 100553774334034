import _ from "lodash";
import { updateOfferContractDocumentParam, updateOfferParam } from "./utility";
import { changeEditData } from "./offerReducerFunctions";

/**
 * add service type to state
 */
export const addToCreateServiceTypeHandler = (state, service_type) => {
  let { service_objects_to_create } = state.offer.offer_contract_document;

  if (!service_objects_to_create) {
    service_objects_to_create = [];
  }

  const services_type_create_clone = [...service_objects_to_create];

  services_type_create_clone.push(service_type);

  return updateOfferContractDocumentParam(changeEditData(state, { param: { key: "service_type", value: "" } }), {
    param: {
      key: "service_objects_to_create",
      value: services_type_create_clone,
    },
  });
};

/**
 * update service type in state
 */
export const updateToCreateServiceTypeHandler = (state, service_type) => {
  let { service_objects_to_create } = state.offer.offer_contract_document;

  const services_type_create_clone = [...service_objects_to_create];

  const index = services_type_create_clone.findIndex(serviceType => serviceType.id === service_type.id);

  services_type_create_clone[index] = service_type;

  return updateOfferContractDocumentParam(changeEditData(state, { param: { key: "service_type", value: "" } }), {
    param: {
      key: "service_objects_to_create",
      value: services_type_create_clone,
    },
  });
};

/**
 * remove created service type from state
 */
export const removeToCreateServiceTypeHandler = (state, id) => {
  let { service_objects_to_create } = state.offer.offer_contract_document;

  if (!service_objects_to_create) {
    service_objects_to_create = [];
  }

  const service_objects_to_create_edited = service_objects_to_create.filter(serviceType => serviceType.id !== id);

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "service_objects_to_create",
      value: service_objects_to_create_edited,
    },
  });
};

/**
 * remove to create service type from state
 */
export const removeServiceTypeHandler = (state, service_type_id) => {
  let { documentServices } = state.offer;

  let { service_type_ids_to_delete } = state.offer.offer_contract_document;

  if (!service_type_ids_to_delete) {
    service_type_ids_to_delete = [];
  }

  const service_types_clone = documentServices.filter(serviceType => serviceType.id !== service_type_id);
  const service_type_ids_to_delete_clone = [...service_type_ids_to_delete];
  service_type_ids_to_delete_clone.push(service_type_id);

  return updateOfferParam(
    updateOfferContractDocumentParam(changeEditData(state, { param: { key: "service_type", value: "" } }), {
      param: {
        key: "service_type_ids_to_delete",
        value: service_type_ids_to_delete_clone,
      },
    }),
    {
      param: {
        key: "documentServices",
        value: service_types_clone,
      },
    },
  );
};

/**
 * add created service type to document services
 */
export const addCreatedServiceTypeHandler = (state, service_type) => {
  let { offer_contract_document_services } = state.offer.offer_contract_document;

  if (!offer_contract_document_services) {
    offer_contract_document_services = [];
  }

  const offer_contract_document_services_clone = [...offer_contract_document_services];

  offer_contract_document_services_clone.push(service_type);

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "offer_contract_document_services",
      value: offer_contract_document_services_clone,
    },
  });
};

/**
 * add created service type to document services
 */
export const updateCreatedServiceTypeHandler = (state, service_type) => {
  let { offer_contract_document_services } = state.offer.offer_contract_document;

  const offer_contract_document_services_clone = [...offer_contract_document_services];

  const index = offer_contract_document_services_clone.findIndex(serviceType => serviceType.id === service_type.id);

  offer_contract_document_services_clone[index] = service_type;

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "offer_contract_document_services",
      value: offer_contract_document_services_clone,
    },
  });
};

/**
 * delete created service type to document services
 */
export const removeCreatedServiceTypeHandler = (state, id) => {
  let { offer_contract_document_services } = state.offer.offer_contract_document;

  let offer_contract_document_services_clone = offer_contract_document_services.filter(
    serviceType => _.parseInt(serviceType.id) !== id,
  );

  return updateOfferContractDocumentParam(state, {
    param: {
      key: "offer_contract_document_services",
      value: offer_contract_document_services_clone,
    },
  });
};
