import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  circleIndeterminate: {
    animation: "none",
    strokeDasharray: "80px, 200px",
    strokeDashoffset: "0px",
  },
};

/**
 * FixedCircularProgress - fix not rendering well with animation when show on apollo query run
 */
const FixedCircularProgress = ({ classes, color, size, thickness }) => {
  return (
    <CircularProgress
      color={color || "primary"}
      size={size || 40}
      thickness={thickness || 3.6}
      classes={{ circleIndeterminate: classes.circleIndeterminate }}
    />
  );
};

export default withStyles(styles)(FixedCircularProgress);
