import React from "react";

// libs
import { get } from "lodash";

// components
import VoucherStatus from "../VoucherStatus";
import AnnouncementStatus from "../AnnouncementStatus";
import AnnouncementActions from "../AnnouncementActions";

// utils
import { formatDate } from "src/utility/dates";

export default [
  {
    Header: "Partner",
    id: "partner",
    accessor: "partner",
    width: 140,
    Cell: row => <div style={{ textAlign: "center", width: "100%" }}>{row.value}</div>,
  },

  {
    Header: "Vodič",
    id: "guide_name",
    Cell: row => {
      const guideId = get(row, "original.guide_id");
      const guideFirstName = get(row, "original.guide_first_name");
      const guideLastName = get(row, "original.guide_last_name");
      return (
        <div style={{ textAlign: "center", width: "100%" }}>{guideId ? `${guideFirstName} ${guideLastName}` : "-"}</div>
      );
    },
  },
  {
    Header: "Tip partnera",
    id: "partner_type",
    accessor: "partner_type",
    Cell: row => <div style={{ textAlign: "center", width: "100%" }}>{row.value}</div>,
  },

  {
    Header: "Status najave",
    id: "announcement_status",
    Cell: row => {
      const announcementStatus = get(row, "original.announcement_status");
      const terminPeriodContigentId = get(row, "original.termin_period_contigent_id");
      const programDayContentId = get(row, "original.program_day_content_id");
      return (
        <AnnouncementStatus
          announcementStatus={announcementStatus}
          terminPeriodContigentId={terminPeriodContigentId}
          programDayContentId={programDayContentId}
        />
      );
    },
  },

  {
    Header: "Status vouchera",
    id: "voucher_status",
    Cell: row => {
      const voucherStatus = get(row, "original.voucher_status");
      const terminPeriodContigentId = get(row, "original.termin_period_contigent_id");
      const programDayContentId = get(row, "original.program_day_content_id");
      return (
        <VoucherStatus
          voucherStatus={voucherStatus}
          terminPeriodContigentId={terminPeriodContigentId}
          programDayContentId={programDayContentId}
        />
      );
    },
  },

  {
    Header: "Termin - od",
    id: "from",
    accessor: "from",
    Cell: row => <div style={{ textAlign: "center", width: "100%" }}>{row.value ? formatDate(row.value) : "-"}</div>,
  },

  {
    Header: "Termin - do",
    id: "to",
    accessor: "to",
    Cell: row => <div style={{ textAlign: "center", width: "100%" }}>{row.value ? formatDate(row.value) : "-"}</div>,
  },

  {
    // Additional functions / Dodatne funkcije
    Header: "",
    width: 200,
    maxWidth: 200,
    filterable: false,
    Cell: row => {
      const guideId = get(row, "original.guide_id");
      const establishmentId = get(row, "original.establishment_id");

      return <AnnouncementActions guideId={guideId} establishmentId={establishmentId} />;
    },
  },
];
