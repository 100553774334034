import React, { Component } from "react";
import { graphql } from "react-apollo";

import gql from "graphql-tag";

import Drawer from "material-ui/Drawer";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import WorkIcon from "@material-ui/icons/Work";
import Checkbox from "@material-ui/core/Checkbox";

import toastr from "toastr";

import { NotificationContext } from "./Header";
import { listNotifications } from "../../graphql/query/notification";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

const getNotification = gql`
  subscription {
    notification {
      id
      notification_type_id
      notification_type {
        id
        code
      }
      offer_contract_document_id
      message
    }
  }
`;

const setSeen = gql`
  mutation($id: ID!) {
    seenNotification(id: $id) {
      id
    }
  }
`;

class HistoryLog extends Component {
  state = {
    checked: [1],
  };

  subscribe = () =>
    this.props.data.subscribeToMore({
      document: getNotification,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        toastr.success(
          subscriptionData.data.notification.message,
          subscriptionData.data.notification.notification_type.code,
          {
            timeOut: 5000,
            positionClass: "toast-bottom-right",
          },
        );

        return {
          ...prev,
          listNotification: [...prev.listNotification, subscriptionData.data.notification],
        };
      },
    });

  componentWillMount() {
    this.unsubscribe = this.subscribe();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  notificationSeen = id => () => {
    this.setState({ checked: id }, () => {
      //callback
      this.props.mutate({
        variables: {
          id,
        },
        update: (cache, { data: { seenNotification } }) => {
          const { listNotification } = cache.readQuery({
            query: listNotifications,
          });

          const updatedNotifications = listNotification.filter(notification => notification.id !== seenNotification.id);

          cache.writeQuery({
            query: listNotifications,
            data: { listNotification: updatedNotifications },
          });

          setTimeout(() => {
            this.setState({ checked: "" });
          }, 1000);
        },
      });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ listNotification }) => (
          <Drawer width={300} openSecondary={true} open={this.props.historyLog} containerClassName="historylog-top">
            <div className={classes.root}>
              <List>
                {listNotification &&
                  listNotification.map(notification => {
                    if (!notification) {
                      return null;
                    }
                    return (
                      <ListItem key={notification.id} dense button className={classes.listItem}>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                        <ListItemText
                          primary={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: notification.message,
                              }}
                            />
                          }
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            onClick={this.notificationSeen(notification.id)}
                            checked={this.state.checked === notification.id}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </Drawer>
        )}
      </NotificationContext.Consumer>
    );
  }
}

export default graphql(setSeen)(withStyles(styles)(HistoryLog));
