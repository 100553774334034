import React from "react";

//ui
import { withStyles } from "@material-ui/core/styles";

// components
import AutocompleteSelect from "src/components/UI/AutocompleteSelect";
import useActivity from "./hooks/useActivity";

const styles = {
  root: {
    marginTop: 8,
  },
  card: {
    minWidth: 275,
  },
  title: {
    marginTop: 8,
  },
};

/**
 * used for rendering activity item in list
 */
function ActivityItem({ classes, labelCount, programDayId, programDayContent }) {
  const {
    getActivitiesForView,
    loadingFetch,
    loadingUpdateActivity,
    loadingDeleteActivity,
    handleOnActivitySelect,
    handleOnDeleteActivity,
  } = useActivity();

  const activity = activity_id => {
    if (activity_id) {
      return `activity_id-${activity_id}`;
    }
  };

  return (
    <AutocompleteSelect
      isClearable={true}
      isLoading={loadingUpdateActivity || loadingDeleteActivity || loadingFetch}
      placeholder={getActivitiesForView().length ? `Odaberite aktivnost ${labelCount + 1}` : "dohvacam podatke"}
      dataSource={getActivitiesForView()}
      key={programDayContent.program_day_content_id}
      style={{ width: "auto" }}
      defaultValue={activity(programDayContent.activity_id)}
      autocompleteHandler={selected => {
        if (selected) {
          handleOnActivitySelect(selected, programDayId, programDayContent.program_day_content_id);
        } else {
          handleOnDeleteActivity(programDayId, programDayContent.program_day_content_id);
        }
      }}
    />
  );
}

export default withStyles(styles)(ActivityItem);
