import React from "react";

// libs
import { Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";

// assets
import rdaLogo from "src/assets/rdaLogo.jpg";
import gpnLogo from "src/assets/gpnLogo.jpg";
import upaLogo from "src/assets/upaLogo.png";

// Create styles
const styles = StyleSheet.create({
  // header container
  container: {
    display: "flex",
    flexDirection: "row",
  },

  containerCenter: {
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  // column logo
  columnLogo: {
    flexGrow: 4,
    flexBasis: 0,
    marginTop: 32,
  },

  columnHeader: {
    flexGrow: 2,
    flexBasis: 0,
    margin: 8,
  },

  columnImage: {
    marginRight: 8,
  },

  imageLogo: {
    marginLeft: 20,
    width: 130,
  },

  imageSmallLogo: {
    maxWidth: 30,
    height: "auto",
  },

  imageUpaLogo: {
    marginTop: 8,
    maxWidth: 80,
    height: "auto",
  },

  text: {
    fontSize: 8,
    marginTop: 8,
    fontFamily: "OpenSans",
  },

  textPaddingLeft: {
    fontSize: 8,
    marginTop: 8,
    paddingLeft: 30,
    fontFamily: "OpenSans",
  },

  textBold: {
    fontSize: 8,
    marginTop: 8,
    fontFamily: "OpenSansBold",
  },

  link: {
    fontSize: 8,
    marginTop: 8,
    color: "#1E88E5",
    fontFamily: "OpenSans",
  },
});

// HeaderVoucher
export default function HeaderVoucher() {
  return (
    <View style={styles.container}>
      {/** 1st container */}
      <View style={styles.columnLogo}>
        <Image style={styles.imageLogo} src="https://www.olivari-travel.com/images/logo.png" />
        <Text style={styles.text}>Complete tourist service Croatia & Southeast Europe</Text>
      </View>

      {/** 2nd container */}
      <View style={styles.columnHeader}>
        <Text style={styles.textBold}>OLIVARI d.o.o.</Text>
        <Text style={styles.text}>DUBAŠLJANSKA 111</Text>
        <Text style={styles.text}>HR-51511 Malinska</Text>
        <Text style={styles.text}>Tel: +385 51 850 000</Text>
        <Text style={styles.text}>Fax +385 51 859 000</Text>

        <Text style={styles.text}>Mobile: +385 91 654 8274 </Text>
        <Text style={styles.textPaddingLeft}>(+385 91 OLIVARI)</Text>

        {/** mail */}
        <View style={styles.container}>
          <Text style={styles.text}>Email: </Text>
          <Link style={styles.link} href="mailto:groups@volivari.com">
            groups@volivari.com
          </Link>
        </View>

        {/** web */}
        <View style={styles.container}>
          <Text style={styles.text}>Web: </Text>
          <Link style={styles.link} href="https://www.olivari-travel.com/">
            olivari-travel.com
          </Link>
        </View>
      </View>

      {/** 3rd container */}
      <View style={styles.columnHeader}>
        <Text style={styles.textBold}>RDA Member Nr. A1 8221 </Text>
        <Text style={styles.text}>Global Passenger Network</Text>
        <Text style={styles.text}>Member for Croatia</Text>
        <Text style={styles.text}>ID COD: HR-AB-51-040143504</Text>
        <Text style={styles.text}>OIB: 19778110320</Text>

        <View style={styles.containerCenter}>
          <View style={styles.columnImage}>
            <Image style={styles.imageSmallLogo} src={rdaLogo} />
          </View>

          <View>
            <Image style={styles.imageSmallLogo} src={gpnLogo} />
          </View>
        </View>

        <View>
          <Image style={styles.imageUpaLogo} src={upaLogo} />
        </View>
      </View>
    </View>
  );
}
