import moment from "moment";

export const prepareForSending = (obj = {}, keysToDelete = [], keysToSkip = []) => {
  if (typeof obj === "string" || typeof obj === "number") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(obj => prepareForSending(obj, keysToDelete, keysToSkip));
  } else {
    return Object.keys(obj).reduce((acc, key) => {
      // if key is  in array which is not meant to be deleted
      if (keysToSkip.includes(key)) {
        return {
          ...acc,
          [key]: obj[key],
        };
      }

      if (keysToDelete.includes(key)) {
        return acc;
      }

      // Date is object, so check if date first
      if (moment(obj[key], "YYYY-MM-DD", true).isValid()) {
        return { ...acc, [key]: obj[key] };
      }

      // If new object
      if (typeof obj[key] === "object" && obj[key] !== null) {
        return {
          ...acc,
          [key]: prepareForSending(obj[key], keysToDelete, keysToSkip),
        };
      }

      if (!keysToDelete.includes(key)) {
        return {
          ...acc,
          [key]: obj[key],
        };
      }

      return acc;
    }, {});
  }
};

export const removeAllEmptyKeys = (obj, keysToSkipDelete = []) => {
  if (Array.isArray(obj)) {
    return obj.map(obj => removeAllEmptyKeys(obj));
  } else {
    return Object.keys(obj).reduce((acc, key) => {
      if (keysToSkipDelete.includes(key)) {
        return { ...acc, [key]: obj[key] };
      }

      // Date is object, so check if date first
      if (!(new Date(obj[key]).toDateString() === "Invalid Date")) {
        return { ...acc, [key]: obj[key] };
      }

      // If  object
      if (typeof obj[key] === "object") {
        return {
          ...acc,
          [key]: removeAllEmptyKeys(obj[key]),
        };
      }

      if (obj[key]) {
        return { ...acc, [key]: obj[key] };
      }

      return acc;
    }, {});
  }
};
