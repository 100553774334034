import React from "react";

// libs
import { get } from "lodash";

// ui
import { blue500, grey900 } from "material-ui/styles/colors";
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { Button, Paper } from "@material-ui/core";
import AddIcon from "material-ui/svg-icons/content/add-circle";
import ClearIcon from "@material-ui/icons/Clear";

// constants
import { LABEL_RESET_FILTERS, TITLE_TOOLBAR_ANNOUNCEMENTS } from "src/utility/labels";
import { announcementTypes, PHRASE_ANNOUNCEMENT } from "src/utility/constants";

// hooks
import useToolbar from "./hooks/useToolbar";
/**
 * ToolbarView announcement
 */
export default function ToolbarView() {
  const {
    // variables
    announcementTypeSelected,
    // functions
    getIsResetEnabled,
    handleOnAnnouncementPhraseCreateClick,
    handleOnAnnouncementTypeSelect,
    handleOnFilterResetClick,
  } = useToolbar();

  // return root
  return (
    <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
      <ToolbarGroup>
        <ToolbarTitle
          text={`${TITLE_TOOLBAR_ANNOUNCEMENTS} -  ${get(
            announcementTypes.find(announcementType => announcementType.name === announcementTypeSelected),
            "description",
          )}`}
          style={{
            color: grey900,
          }}
        />

        {/** show add icon for phrase announcement */}
        {announcementTypeSelected === PHRASE_ANNOUNCEMENT && (
          <AddIcon onClick={handleOnAnnouncementPhraseCreateClick} color={blue500} style={{ cursor: "pointer" }} />
        )}
      </ToolbarGroup>

      <ToolbarGroup>
        <Button
          id="announcements-delete-filters"
          disabled={!getIsResetEnabled()}
          color="primary"
          style={{ marginRight: 16 }}
          variant="outlined"
          size="small"
          onClick={handleOnFilterResetClick}
        >
          <ClearIcon size={16} />
          {LABEL_RESET_FILTERS}
        </Button>

        <Paper style={{ marginLeft: "5px", height: "36px" }}>
          <select
            style={{ height: "inherit" }}
            className="booking-select "
            value={announcementTypeSelected}
            onChange={event => {
              return handleOnAnnouncementTypeSelect(event.target.value);
            }}
          >
            {announcementTypes
              ? announcementTypes.map(announcementType => (
                  <option key={announcementType.id} value={announcementType.name}>
                    {announcementType.description}
                  </option>
                ))
              : null}
          </select>
        </Paper>
      </ToolbarGroup>
    </Toolbar>
  );
}
