import React, { Component } from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { getPartnerClient } from "../../../graphql/query/administration";
import { withRouter } from "react-router-dom";

class PreviewSignature extends Component {
  state = {
    partner_client: null,
  };

  componentDidMount = async () => {
    if (!this.props.match.path.includes("ugovor")) {
      return;
    }

    if (this.props.partner_client) {
      this.setState({
        partner_client: this.props.partner_client,
      });
      return;
    }

    try {
      const response = await this.props.client.query({
        query: getPartnerClient,
        variables: {
          id: this.props.partner_client_id,
        },
      });
      this.setState({
        partner_client: response.data.getPartnerClient,
      });
    } catch (error) {}
  };

  dataToSet() {
    const { partner_client } = this.state;
    return partner_client.first_name && partner_client.last_name
      ? `${partner_client.first_name} ${partner_client.last_name}`
      : partner_client.name;
  }

  render() {
    if (!this.props.match.path.includes("ugovor")) {
      return null;
    }

    const { partner_client } = this.state;
    const { user } = this.props;

    if (!partner_client) {
      return null;
    }

    return (
      <div className="preview-item-price preview-signature-container bold-text">
        <div>
          <div>{user && user.signature_url ? <img src={user.signature_url} alt="Potpis zaposlenika" /> : null}</div>
          <p />
          <p>{user ? `${user.first_name} ${user.last_name}` : null}</p>
        </div>
        <div>
          <div />
          <p />
          <p>{this.dataToSet()}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      created_by_user,
      offer_contract_document: { partner_client_id, partner_client },
    },
  } = state.preview;

  return {
    user: created_by_user,
    partner_client_id,
    partner_client,
  };
};

export default connect(mapStateToProps)(withRouter(withApollo(PreviewSignature)));
