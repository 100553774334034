// libs
import _ from "lodash";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { listActivity, listPartnerClient } from "src/graphql/query/administration";

// constants
import { where_partner_type_guide } from "src/utility/globals";

// redux state
import { selectProgramDayActivities } from "src/views/Guides/guidesSlice";

/**
 * useProgramDays is entry point of data for programDays component
 */
export default function useProgramDays() {
  const { data: dataListActivity, loading: loadingFetchActivity } = useQuery(listActivity, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { data: dataPartnerClient, loading: loadingPartnerClient } = useQuery(listPartnerClient, {
    variables: {
      input: {
        where: where_partner_type_guide,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);

  return {
    listPartners: _.get(dataPartnerClient, "listPartnerClient", []),
    listActivities: _.get(dataListActivity, "listActivity", []),
    loading: loadingFetchActivity || loadingPartnerClient,
    programDayActivities,
  };
}
