import React, { Component } from "react";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";

import { withRouter } from "react-router-dom";
// Shared components
import Comment from "../../../../components/UI/CommentTextArea";
import DateRangePicker from "../../../../components/UI/DateRangePicker";

import TerminsShow from "./TerminsShow";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";

import { dateDiff } from "../../../../utility/dates";
import { mainTerminFragment, contigentFragment } from "../../../../graphql/fragments";
import { prepareForSending } from "../../../../utility/prepare";

import toastr from "toastr";
import { config, autosize } from "../../../../utility/globals";
import { createProgram } from "../../../../graphql/mutation/offers";

const createTermin = gql`
  mutation(
    $from: DateTime!
    $to: DateTime!
    $offer_contract_document_id: ID
    $price_structure: CreatePriceStructureInput!
    $contigents: [CreateContigentInput]
    $subtermin_periods: [CreateSubTerminPeriodInput]
  ) {
    createTerminPeriod(
      input: {
        from: $from
        to: $to
        offer_contract_document_id: $offer_contract_document_id
        price_structure: $price_structure
        contigents: $contigents
        subtermin_periods: $subtermin_periods
      }
    ) {
      ...MainTermin
      contigents {
        ...Contigent
      }
    }
  }
  ${mainTerminFragment}
  ${contigentFragment}
`;

class TerminData extends Component {
  addTerminHandler = async () => {
    // if this is first termin
    if (this.props.termin_periods && !this.props.termin_periods.length) {
      this.insertFirstTermin();
    } else {
      this.addAnotherTermin();
    }
  };

  async insertFirstTermin() {
    let termin = false;
    this.props.onAddTermin();

    // if we're in edit mode
    if (this.props.match.params.id) {
      const { termin_periods, offer_contract_document_id, from, to, contigents, transport } = this.props;

      const added_contigents = termin_periods && termin_periods.length ? termin_periods[0].contigents : null;

      const typology_group_ids = prepareForSending(added_contigents || contigents, ["sold", "termin_period_id"]).map(
        ({ typology }) => typology.typology_group_id,
      );

      const typology_group_unique_ids = typology_group_ids.filter(
        (tgid, i) => typology_group_ids.findIndex(tgid_2 => tgid === tgid_2) === i,
      );

      const price_typology = typology_group_unique_ids.reduce((obj, curr) => {
        return {
          ...obj,
          [curr]: 0,
        };
      }, {});

      const response = await this.props.mutate({
        variables: {
          to,
          from,
          transport,
          price_structure: {
            tourist_tax: "0",
            comment_price_structure: "",
            price_typology: JSON.stringify(price_typology),
          },
          contigents: prepareForSending(added_contigents || contigents, [
            "sold",
            "typology",
            "termin_period_id",
            "__typename",
          ]),
          subtermin_periods: [],
          offer_contract_document_id,
        },
      });

      termin = response.data.createTerminPeriod;

      this.props.setAddedTermin(prepareForSending(response.data.createTerminPeriod, ["__typename"]));

      toastr.success("Uspješno kreiran termin");
    }

    if (termin) {
      // Kreiranje programa
      this.props.createProgramDays(dateDiff(termin.from, termin.to, "days") + 1);
      this.props.copyUpdatedProgramDays(dateDiff(termin.from, termin.to, "days") + 1);
    } else {
      // Kreiranje programa
      this.props.createProgramDays(dateDiff(this.props.from, this.props.to, "days") + 1);
      this.props.copyUpdatedProgramDays(dateDiff(this.props.from, this.props.to, "days") + 1);
    }

    if (termin) {
      try {
        const { program_days } = this.props;

        const response = await this.props.createProgram({
          variables: {
            termin_period_id: termin.id,
            program_days: [...prepareForSending(program_days, ["id"])],
          },
        });

        this.props.saveCreatedProgram(response.data.createProgram);
        this.props.copyUpdatedProgramDays(response.data.createProgram);
        this.props.setProgramToSend(response.data.createProgram.id);
      } catch (error) {}
    }
  }

  async addAnotherTermin() {
    try {
      this.props.onAddTermin();
      // if we're in edit mode
      if (this.props.match.params.id) {
        const { termin_periods, offer_contract_document_id, from, to, contigents, transport } = this.props;

        const added_contigents = termin_periods && termin_periods.length ? termin_periods[0].contigents : null;

        const typology_group_ids = prepareForSending(added_contigents || contigents, ["sold", "termin_period_id"]).map(
          ({ typology }) => typology.typology_group_id,
        );

        const typology_group_unique_ids = typology_group_ids.filter(
          (tgid, i) => typology_group_ids.findIndex(tgid_2 => tgid === tgid_2) === i,
        );

        const price_typology = typology_group_unique_ids.reduce((obj, curr) => {
          return {
            ...obj,
            [curr]: 0,
          };
        }, {});

        const response = await this.props.mutate({
          variables: {
            to,
            from,
            transport,
            price_structure: {
              tourist_tax: "0",
              comment_price_structure: "",
              price_typology: JSON.stringify(price_typology),
            },
            contigents: prepareForSending(added_contigents || contigents, [
              "sold",
              "typology",
              "termin_period_id",
              "__typename",
            ]),
            subtermin_periods: [],
            offer_contract_document_id,
          },
        });

        this.props.setAddedTermin(prepareForSending(response.data.createTerminPeriod, ["__typename"]));

        toastr.success("Uspješno kreiran termin");
      } else {
      }
    } catch (error) {}
  }

  componentDidUpdate() {
    const { from, to } = this.props;
    if (from && to) {
      this.addTerminHandler();
    }
    this.autoResize();
  }

  componentDidMount = () => {
    this.autoResize();
  };

  autoResize() {
    if (document.getElementsByName(`comment_term_period`).length) {
      autosize(document.getElementsByName(`comment_term_period`)[0]);
    }
  }

  render() {
    const { offer_contract_document_type_id, isNotDraft } = this.props;

    return (
      <React.Fragment>
        {/* Termini */}
        {!isNotDraft ? (
          <div className="cols-2">
            <div className="form-section-one">
              <div className="form-unit-triple-icon">
                <div
                  className="field-label is-normal"
                  style={{
                    paddingTop: "0.2em",
                  }}
                >
                  <label className="label">Termin</label>
                </div>
                {!(
                  config.offer_type[offer_contract_document_type_id] === "Tour" &&
                  this.props.termin_periods &&
                  this.props.termin_periods.length
                ) ? (
                  <DateRangePicker
                    from={this.props.from}
                    to={this.props.to}
                    handleFromChange={date =>
                      this.props.changeEditData({
                        key: "from",
                        value: date,
                      })
                    }
                    handleToChange={date =>
                      this.props.changeEditData({
                        key: "to",
                        value: date,
                      })
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Selected termins */}
        <TerminsShow
          listEstablishment={this.props.listEstablishment}
          listEstablishmentQuery={this.props.listEstablishmentQuery}
          isNotDraft={isNotDraft}
        />

        {/* Termini komentar */}
        <div className="cols-2">
          <div className="form-section-one">
            <div className="form-unit-double">
              <div />
              <Comment
                name="comment_term_period"
                defaultValue={this.props.comment_term_period}
                label="Komentar termina"
                inputProps={{ disabled: isNotDraft }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    from,
    to,
    contigents,
    offer: {
      offer_contract_document: { id, comment_term_period, termin_periods, offer_contract_document_type_id },
    },
    transport,
    program_days,
  } = state.offer;
  return {
    offer_contract_document_id: id,
    from,
    to,
    contigents,
    termin_periods,
    comment_term_period,
    offer_contract_document_type_id,
    transport,
    program_days,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddTermin: () => dispatch(actions.addTermin()),
    changeEditData: obj => dispatch(actions.changeEditData(obj)),
    createProgramDays: days => dispatch(actions.createProgramDays(days)),
    setAddedTermin: termin => dispatch(actions.setAddedTermin(termin)),
    saveCreatedProgram: program => dispatch(actions.saveCreatedProgram(program)),
    copyUpdatedProgramDays: program => dispatch(actions.copyUpdatedProgramDays(program)),
    setProgramToSend: value => dispatch(actions.setProgramToSend(value)),
  };
};

export default compose(
  graphql(createTermin),
  graphql(createProgram, { name: "createProgram" }),
)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(TerminData)),
);
