import { useCallback, useState, useEffect } from "react";

// libs
import { get } from "lodash";
import toastr from "toastr";
import { useQuery, useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideSchedules,
  selectProgramDayActivities,
  setGuideScheduleActivityPartners,
  setGuideSchedules,
  setPartnerSearchValues,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { GET_PARTNER_CLIENT } from "src/graphql/query/operative";
import { UPDATE_PROGRAM_DAY_CONTENT } from "src/graphql/mutation/operative"; // graphql
import { listPartnerClient } from "src/graphql/query/administration";

// utils
import { removeDuplicateObjects, where_partner_type_guide } from "src/utility/globals";

/**
 * usePartner is entry point of data for partnerItem component
 */
export default function usePartner(partnerClientId) {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // partner pagination variables
  const [listPartner, setListPartner] = useState([]);

  // change program day activities mutation
  const [updateProgramDayActivities, { loading: loadingUpdatePartnerClient }] = useMutation(UPDATE_PROGRAM_DAY_CONTENT);
  // partner client search item
  let showPartnerClientSearch = {
    id: -1,
    name: "Pretraga partnera",
  };

  /**
   * fetch partner list query
   */
  const { data: dataPartnerClient, loading: loadingPartnerClient } = useQuery(listPartnerClient, {
    variables: {
      input: {
        where: where_partner_type_guide,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  // fetch partnerClient
  const { data } = useQuery(GET_PARTNER_CLIENT, {
    variables: { id: partnerClientId },
    skip: !partnerClientId,
    notifyOnNetworkStatusChange: true,
  });

  /**
   * get  partner list to show
   */
  const getPartnerListToShow = () => {
    if (partnerClientId) {
      const selectedPartnerClient = get(data, "getPartnerClient", null);

      if (selectedPartnerClient) {
        return removeDuplicateObjects([showPartnerClientSearch, selectedPartnerClient, ...listPartner]);
      } else {
        return [showPartnerClientSearch, ...listPartner];
      }
    } else {
      return [showPartnerClientSearch, ...listPartner];
    }
  };

  // save selected partner in context
  useEffect(() => {
    const selectedPartnerClient = get(data, "getPartnerClient", null);

    if (selectedPartnerClient) {
      dispatch(setGuideScheduleActivityPartners(selectedPartnerClient));
    }
  }, [data, dispatch]);

  // get partner client list when something change
  useEffect(() => {
    const listPartnerClientResponse = get(dataPartnerClient, "listPartnerClient", []);

    if (listPartnerClientResponse) {
      setListPartner(listPartnerClientResponse);
    }
  }, [dataPartnerClient]);

  // handle on partner search click
  const handleOnPartnerSearchClick = (programDayId, programDayContentId) => {
    dispatch(
      setPartnerSearchValues({
        isDialogPartnerSearchOpen: true,
        currentProgramDayId: programDayId,
        currentProgramDayContentId: programDayContentId,
      }),
    );
  };

  // handle on partner select
  const handleOnPartnerSelect = useCallback(
    async (partnerClientId, programDayId, programDayContentId) => {
      const updateProgramDayResponse = await updateProgramDayActivities({
        variables: {
          input: {
            program_day_content_id: programDayContentId,
            partner_client_id: partnerClientId,
          },
        },
      });

      const programUpdated = get(updateProgramDayResponse, "data.UpdateProgramDayContentAndGuideCalendarFields");

      if (programUpdated) {
        //todo handle on program update
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programUpdated.program_day_content_id) {
                  return {
                    ...programDayContent,
                    partner_client_id: programUpdated.partner_client_id,
                    guide_id: programUpdated.guide_id,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });

        // update program day activities
        dispatch(
          setProgramDayActivities({
            ...programDayActivities,
            guides: get(programUpdated, "guides", []),
            program_days: program_days_updated,
          }),
        );

        // update list guides
        const listGuidesUpdate = listGuides.map(guide => {
          if (guide.program_id === programDayActivities.program_id) {
            return {
              ...guide,
              guides: get(programUpdated, "guides", []),
            };
          }
          return guide;
        });

        // update guide schedules
        dispatch(setGuideSchedules(listGuidesUpdate));
      } else {
        toastr.error("Greška prilikom unosa partnera");
      }
    },
    [dispatch, listGuides, programDayActivities, updateProgramDayActivities],
  );

  return {
    getPartnerListToShow,
    loading: loadingPartnerClient || loadingUpdatePartnerClient,
    handleOnPartnerSearchClick,
    handleOnPartnerSelect,
  };
}
