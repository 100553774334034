import React from "react";

// components
import ToolbarView from "../Toolbar";
import ListContainer from "../ListContainer";

export default function AnnouncementContainer() {
  // return root
  return (
    <div>
      <ToolbarView />
      <ListContainer />
    </div>
  );
}
