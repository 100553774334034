// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawers, setGuideDrawerPayload, setGuideDrawers } from "src/views/Guides/guidesSlice";

// constants
import { GUIDE_DRAWER_ANNOUNCEMENT } from "src/utility/constants";

/**
 * useAnnouncement is entry point of data for AnnouncementDrawer
 */
export default function useAnnouncement() {
  // redux state
  const guideDrawers = useSelector(selectGuideScheduleDrawers);

  const dispatch = useDispatch();

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setGuideDrawerPayload(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_ANNOUNCEMENT,
        isOpen: false,
      }),
    );
  };

  return {
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_ANNOUNCEMENT],
    onCloseDrawer,
  };
}
