import { FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import React from "react";
import Popover from "react-awesome-popover";
import { connect } from "react-redux";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import FormValidator from "../../../../components/UI/FormValidator";
import { changeAdministrationTypologyData } from "../../../../store/actions";
import TypologyForm from "./TypologyForm";
import TypologyTranslations from "./TypologyTranslations";

const EditTypologyForm = ({
  editTypology,
  typology,
  establishment,
  listLanguage,
  saveEdited,
  changeTypologyData,
  removeTypologyFromEstablishment,
  cancelChanges,
  changeEditedTypologyTranslation,
  listTypologyGroup,
}) =>
  typology &&
  editTypology &&
  typology.id === editTypology.id && (
    <FormValidator schema={TypologyForm.schema} submit={saveEdited} data={{ ...editTypology }}>
      {({ error, errorMsg, submit }) => (
        <Card>
          <CardHeader title={`Izmjeni podatke tipologije ${editTypology.id}`} />
          <CardText>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/establishment_code")}>
                  <InputLabel shrink={true} htmlFor="establishment_code">
                    Alotmanski kod
                  </InputLabel>
                  <Input
                    id="establishment_code"
                    name="establishment_code"
                    value={editTypology.establishment_code ? editTypology.establishment_code : ""}
                    onChange={({ target: { value } }) =>
                      changeTypologyData({
                        key: "establishment_code",
                        value,
                      })
                    }
                  />
                  {error("/establishment_code") && <FormHelperText>{errorMsg("/establishment_code")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={error("/code")}>
                  <InputLabel shrink={true} htmlFor="code">
                    Kod
                  </InputLabel>
                  <Input
                    id="code"
                    name="code"
                    value={editTypology.code ? editTypology.code : ""}
                    onChange={({ target: { value } }) =>
                      changeTypologyData({
                        key: "code",
                        value,
                      })
                    }
                  />
                  {error("/code") && <FormHelperText>{errorMsg("/code")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth error={error("/persons_capacity")}>
                  <InputLabel shrink={true} htmlFor="persons_capacity">
                    Kapacitet osoba
                  </InputLabel>
                  <Input
                    id="persons_capacity"
                    name="persons_capacity"
                    className=""
                    value={editTypology.persons_capacity ? editTypology.persons_capacity : ""}
                    onChange={({ target: { value } }) =>
                      changeTypologyData({
                        key: "persons_capacity",
                        value,
                      })
                    }
                  />
                  {error("/persons_capacity") && <FormHelperText>{errorMsg("/persons_capacity")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth error={error("/priority")}>
                  <InputLabel shrink={true} htmlFor="priority">
                    Prioritet
                  </InputLabel>
                  <Input
                    id="priority"
                    name="priority"
                    className=""
                    value={editTypology.priority.toString() ? editTypology.priority.toString() : ""}
                    onChange={({ target: { value } }) =>
                      changeTypologyData({
                        key: "priority",
                        value,
                      })
                    }
                  />
                  {error("/priority") && <FormHelperText>{errorMsg("/priority")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <AutocompleteSelect
                  label="Grupa tipologije"
                  error={error("/typology_group_id")}
                  errorMsg={errorMsg("/typology_group_id")}
                  defaultValue={editTypology.typology_group_id || ""}
                  autocompleteHandler={value =>
                    changeTypologyData({
                      key: "typology_group_id",
                      value,
                    })
                  }
                  dataSource={listTypologyGroup && listTypologyGroup.map(obj => ({ value: obj.id, label: obj.name }))}
                  placeholder="Odabir grupe tipologije"
                />
              </Grid>
            </Grid>
            <TypologyTranslations
              error={error}
              errorMsg={errorMsg}
              changeLanguageTextField={changeEditedTypologyTranslation}
              editTypology={editTypology}
              typology_translations={editTypology.typology_translations}
              listLanguage={listLanguage}
            />
          </CardText>
          <CardActions className="cardactions-button-padding">
            <Grid container spacing={8}>
              <Grid item>
                <Button variant="contained" size="small" onClick={submit}>
                  Spremi izmjene
                </Button>
              </Grid>
              <Grid item>
                <Popover action="click" placement="bottom" contentClass="rap-popover-content">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={establishment.typologies && establishment.typologies.length < 2}
                  >
                    Izbriši
                  </Button>
                  <div className="pad">
                    <Button
                      variant="contained"
                      size="small"
                      classes={{ root: "is-success" }}
                      onClick={removeTypologyFromEstablishment}
                    >
                      Potvrdi
                    </Button>
                  </div>
                </Popover>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="small" onClick={cancelChanges}>
                  Odustani
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      )}
    </FormValidator>
  );

const mapStateToProps = state => {
  const { establishment } = state.administration;
  return { establishment };
};

export default connect(mapStateToProps, { changeAdministrationTypologyData })(EditTypologyForm);
