// ui
import { Grid, Typography, withStyles } from "@material-ui/core";
// libs
import { get } from "lodash";
import React from "react";
import EditInput from "../EditInput";

const styles = () => ({
  container: {
    marginTop: 16,
  },

  label: {
    lineHeight: "1.5em",
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  itemValue: {
    marginLeft: 8,
  },

  value: {
    lineHeight: "1.5em",
    fontWeight: "bold",
  },
});

/**
 * AnnouncementTextField
 */
const AnnouncementTextField = ({ classes, keyOfValue, announcementField, isEditable, handleOnUpdateAnnouncement }) => {
  // check for null field
  if (!announcementField) {
    return null;
  }

  const fieldLabel = `${get(announcementField, "label", "")} : `;
  const fieldPlaceholder = get(announcementField, "placeholder", "");
  const fieldValue = get(announcementField, "value", "");

  // return root
  return (
    <Grid container className={classes.container} justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography className={classes.label} variant="body1">
          {fieldLabel}
        </Typography>
      </Grid>

      <Grid item xs className={classes.itemValue}>
        {isEditable ? (
          <EditInput
            keyOfValue={keyOfValue}
            value={fieldValue ? fieldValue : fieldPlaceholder}
            onSaveClick={(keyOfValue, value) => handleOnUpdateAnnouncement(keyOfValue, value)}
          />
        ) : (
          <Typography className={classes.value} variant="body1">
            {fieldValue}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AnnouncementTextField);
