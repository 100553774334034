import React from "react";
import { graphql } from "react-apollo";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { grey800, grey900, fullWhite, blue500 } from "material-ui/styles/colors";
import RaisedButton from "material-ui/RaisedButton";
import Popover, { PopoverAnimationVertical } from "material-ui/Popover";

import ReactToPrint from "react-to-print";

import Filters from "./Filters";
import { AllBookingsContext } from "./AllBookings";
import { Paper } from "@material-ui/core";
import gql from "graphql-tag";
import { formatDate, getCurrentDate } from "../../../utility/dates";

import { sortContigentsByTypologyPriorityWithoutListEstablishment } from "src/utility/establishment";
// excel files
import ReactExport from "react-data-export";
import { BOOKING_EXCEL_FILENAME, LABEL_EXPORT_EXCEL } from "src/utility/labels";
import { get } from "lodash-es";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const LCDS = gql`
  {
    listOfferContractDocumentSegment {
      id
      desc
      code
    }
  }
`;

const printBookingStyle = `
@media print{
  @page {
    size: landscape;
  }

  .ReactTable{
    width:270 mm
  }

  .ReactTable .-pagination{
    height:50px
  }

  .ReactTable .rt-tbody .rt-tr-group{
    height: fit-content;
  }

  .area-to-print{
    display: none !important;
  }                      
 

  .ReactTable {
    font-size: 6pt;
  }

  .print-span-container {
    width: 100%;
    text-align: center;
  }

  .booking-select {
    display: none;
  }

  .select-print {
    display: inline !important;
    text-align: center;
  }


}`;

class BookingsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      printXml: [],
      excelExportDataSet: null,
    };
  }

  componentDidMount = () => {
    this.props.getResetXml(this.resetXml);
  };

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  showPrintArea = () => {
    const el = document.getElementById("area-to-print");
    el.style.display = "block";
  };

  hidePrintArea = () => {
    const el = document.getElementById("area-to-print");
    el.style.display = "none";
  };

  rowCol = (row, col) => {
    switch (col) {
      case "offer_contract_document_segment_id":
        const { listOfferContractDocumentSegment } = this.props.data;
        const segment =
          listOfferContractDocumentSegment && listOfferContractDocumentSegment.find(type => type.id === row[col]);
        return (segment && segment.desc) || "";
      case "from":
        return formatDate(row[col]);
      case "to":
        return formatDate(row[col]);
      case "notification_booking_state_date":
        return formatDate(row[col]);
      case "establishment_chain":
        return row[col].props.children[0].props.children;
      case "establishment":
        return row[col].props.children[0].props.children;
      case "contigents_list":
        return row[col].props.children.reduce((sen, curr) => {
          if (!(curr && curr.props && curr.props.children && Array.isArray(curr.props.children))) {
            return sen;
          }

          return (sen += curr.props.children.reduce((sen2, curr2) => {
            if (!curr2) {
              return sen2;
            }

            if (typeof curr2 === "string" && curr2.trim() === "") {
              return (sen2 += "\n");
            }
            if (typeof curr2 === "string") {
              return (sen2 += curr2);
            }
            // multiple typologies
            return (sen2 += curr2.reduce((sen3, curr3) => {
              if (!(curr3 && curr3.props && curr3.props.children)) {
                return sen3;
              }

              const cl = (sen3 += curr3.props.children.reduce((sen4, curr4) => {
                return (sen4 += curr4);
              }, ""));

              if (curr2.length > 1) {
                return cl + "\n";
              }

              return cl;
            }, ""));
          }, ""));
        }, "");
      case "persons_capacity_sold":
        return row[col].props.children.reduce((sen, curr) => {
          if (!(curr && curr.props && curr.props.children)) {
            return sen;
          }
          return (sen += curr.props.children.reduce((sen2, curr2) => {
            if (!(curr2 && Array.isArray(curr2))) {
              return sen2;
            }

            // multiple typologies
            return (sen2 += curr2.reduce((sen3, curr3) => {
              if (!(curr3 && curr3.props && curr3.props.children)) {
                return sen3;
              }

              const cl = (sen3 += curr3.props.children.reduce((sen4, curr4) => {
                return (sen4 += curr4);
              }, ""));

              if (curr2.length > 1) {
                return cl + "\n";
              }

              return cl;
            }, ""));
          }, ""));
        }, "");
      default:
        // check if boolean
        if (typeof row[col] === typeof true) {
          return row[col] ? "Da" : "Ne";
        }

        // if null
        if (!row[col]) {
          return "";
        }

        return row[col];
    }
  };

  rowColMain = (row, col) => {
    switch (col) {
      case "booking_external_id":
        return get(row, "_subRows[0].booking_external_id", "");
      case "code1":
        return row[col].props.title;
      case "off_key":
        return row[col].props.title;
      case "from":
        return row[col].props.title;
      case "to":
        return row[col].props.title;
      case "inquiry_external_id":
        return row[col].props.title;
      default:
        return row[col];
    }
  };

  getContigentsTextForExcel = contigents => {
    const sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

    let contigentsText = "";
    sortedContigents &&
      sortedContigents.forEach(contigent => {
        contigentsText =
          contigentsText + `\n ${get(contigent, "typologies_sum", "")} / ${get(contigent, "typology.code", "")}`;
      });

    return contigentsText;
  };

  getExcelExportDataSet = () => {
    const tableData = this.props.reactTable.getResolvedState();
    const colsNames = tableData.columns.map(col => col.Header);

    const data = tableData.sortedData.map(data => {
      // group
      if (data._subRows.length === 1) {
        return { ...data._subRows[0], bookingType: "group" };
      }
      //tura
      else {
        return { ...data, bookingType: "tour" };
      }
    });

    let rowCellData = [];

    data.forEach((el, index) => {
      if (el.bookingType === "group") {
        const { _original } = el;

        const group = tableData.columns
          .filter(column => column.Header !== "Otvori")
          .map(({ id }) => {
            if (id === "contigents_list") {
              let contigentsText = this.getContigentsTextForExcel(this.rowCol(_original, "contigents"));

              return {
                value: contigentsText,
                style: { alignment: { wrapText: true, vertical: "bottom", horizontal: "left", width: { wpx: 190 } } },
              };
            }

            if (this.rowCol(_original, id) === undefined) {
              return { value: "" };
            }

            return { value: this.rowCol(_original, id) };
          });

        rowCellData = [...rowCellData, group];
      }
      // tour
      else {
        // read main termin
        const mainTermin = tableData.columns
          .filter(column => column.Header !== "Otvori")
          .map(({ id }) => {
            if (this.rowColMain(el, id) === undefined) {
              return { value: "" };
            }

            return { value: this.rowColMain(el, id) };
          });

        rowCellData = [...rowCellData, mainTermin];

        // read his subtermins
        el._subRows.forEach(({ _original }) => {
          const subterminList = tableData.columns
            .filter(column => column.Header !== "Otvori")
            .map(({ id }) => {
              if (id === "contigents_list") {
                let contigentsText = this.getContigentsTextForExcel(this.rowCol(_original, "contigents"));

                return {
                  value: contigentsText,
                  style: { alignment: { wrapText: true, vertical: "bottom", horizontal: "left", width: { wpx: 190 } } },
                };
              }

              if (this.rowCol(_original, id) === undefined) {
                return { value: "" };
              }

              return { value: this.rowCol(_original, id) };
            });

          rowCellData = [...rowCellData, subterminList];
        });
      }
    });

    // format header names
    const headers = colsNames
      .filter(column => column !== "Otvori")
      .map(colName => {
        if (colName === "Ugovoreno") {
          return { title: colName, width: { wpx: 190 }, style: { font: { bold: true } } };
        }

        return { title: colName, width: { wpx: 120 }, style: { font: { bold: true } } };
      });

    this.setState({
      excelExportDataSet: [
        {
          columns: headers,
          data: rowCellData,
        },
      ],
    });
  };

  resetXml = () => {
    if (this.state.excelExportDataSet) {
      this.setState({ excelExportDataSet: null });
    }
  };

  render() {
    const { showBookings, changeShowBookings } = this.props;
    return (
      <AllBookingsContext.Consumer>
        {({ listDatagridFilter, columns, dataToShow }) => {
          return (
            <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
              <ToolbarGroup>
                <ToolbarTitle
                  text="Booking"
                  style={{
                    color: grey900,
                  }}
                />
              </ToolbarGroup>
              <ToolbarGroup>
                <div onMouseEnter={this.showPrintArea} onMouseLeave={this.hidePrintArea}>
                  <ReactToPrint
                    pageStyle={printBookingStyle}
                    trigger={() => <RaisedButton label="Print" backgroundColor={blue500} labelColor={fullWhite} />}
                    content={() => this.props.contentToPrint()}
                  />
                </div>

                <RaisedButton
                  label="Generiraj EXCEL"
                  backgroundColor={grey800}
                  labelColor={fullWhite}
                  style={{
                    marginRight: "0px",
                    marginLeft: "24px",
                  }}
                  onClick={this.getExcelExportDataSet}
                />

                {this.state.excelExportDataSet && (
                  <div style={{ marginLeft: 24 }}>
                    <ExcelFile
                      filename={`${BOOKING_EXCEL_FILENAME}_${getCurrentDate()}`}
                      element={
                        <RaisedButton
                          onClick={this.resetXml}
                          label={LABEL_EXPORT_EXCEL}
                          backgroundColor={grey800}
                          labelColor={fullWhite}
                        />
                      }
                    >
                      <ExcelSheet dataSet={this.state.excelExportDataSet} name="booking" />
                    </ExcelFile>
                  </div>
                )}

                <RaisedButton
                  label="Kolone"
                  backgroundColor={grey800}
                  labelColor={fullWhite}
                  onClick={this.handleClick}
                />
                <Popover
                  open={this.state.open}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  targetOrigin={{ horizontal: "left", vertical: "top" }}
                  onRequestClose={this.handleRequestClose}
                  animation={PopoverAnimationVertical}
                >
                  <Filters {...this.props} columns={listDatagridFilter} />
                </Popover>
                <Paper style={{ marginLeft: "5px", height: "36px" }}>
                  <select
                    style={{ height: "inherit" }}
                    className="booking-select "
                    onChange={({ target: { value } }) => changeShowBookings(value)}
                    value={showBookings}
                  >
                    <option value="0">Aktivni</option>
                    <option value="1">Arhivirani</option>
                    <option value="2">Svi</option>
                  </select>
                </Paper>
              </ToolbarGroup>
            </Toolbar>
          );
        }}
      </AllBookingsContext.Consumer>
    );
  }
}

export default graphql(LCDS)(BookingsToolbar);
