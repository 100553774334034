import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from "@material-ui/core";

import { getAlotmanDay } from "../../../../graphql/query/alotman";

class AlotmanDay extends React.Component {
  state = {};

  seeContract = id => () => {
    this.props.history.push(`/alotman/contracts/${id}`);
  };

  render() {
    const {
      selectedDate,
      data: { loading },
    } = this.props;

    if (loading) {
      return <div>Loading...</div>;
    }

    const {
      getAlotmanDay: {
        typology: { code },
        sold,
        sum,
        available_state,
        contracts,
      },
    } = this.props.data;

    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField id="fix_kontigent" label="Tip sobe" type="text" value={code || ""} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField id="fix_kontigent" label="Odabrani datum" type="text" value={selectedDate || ""} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              id="fix_kontigent"
              label="Ukupno kontigenata"
              type="text"
              value={typeof sum === "number" ? sum : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              id="fix_kontigent"
              label="Prodano"
              type="text"
              value={typeof sold === "number" ? sold : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              id="fix_kontigent"
              label="Raspoloživo"
              type="text"
              value={typeof available_state === "number" ? available_state : ""}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Table id="listFixContigentsDateTable">
            <TableHead>
              <TableRow>
                <TableCell>Br. ugovora</TableCell>
                <TableCell>Klijent</TableCell>
                <TableCell numeric padding="none">
                  Prodano
                </TableCell>
                <TableCell padding="dense">Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* example row */}
              {(contracts &&
                contracts.map(alotman => {
                  const {
                    offer_contract_document: { contract, partner_client },
                  } = alotman;

                  const { reservations } = contract;

                  let total_sold_by_contract = 0;

                  total_sold_by_contract =
                    reservations &&
                    reservations.reduce((total, reservation) => (total += reservation.reservation_status), 0);

                  return (
                    <TableRow key={alotman.id}>
                      <TableCell component="th" scope="row">
                        {contract.id}
                      </TableCell>
                      <TableCell>{partner_client.name}</TableCell>
                      <TableCell numeric>{total_sold_by_contract}</TableCell>
                      <TableCell>
                        <Button onClick={this.seeContract(contract.id)}>Link</Button>
                      </TableCell>
                    </TableRow>
                  );
                })) || <TableRow />}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  graphql(getAlotmanDay, {
    options: ({ alotman_day_id: id }) => ({
      variables: {
        id,
      },
    }),
  }),
)(AlotmanDay);
