/* eslint-disable react/prop-types */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";

import FormHelperText from "@material-ui/core/FormHelperText";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";
import Select from "react-select";
import "react-select/dist/react-select.css";

class Option extends React.Component {
  handleClick = event => {
    this.props.onSelect(this.props.option, event);
  };

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props;

    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

function SelectWrapped(props) {
  const { classes, onMenuScrollToBottom, isLoading, isClearable, autoBlur, ...other } = props;

  let clearable = true;

  if (typeof isClearable !== "undefined") {
    clearable = isClearable;
  }

  return (
    <Select
      autoBlur={autoBlur || false}
      clearable={clearable}
      isLoading={isLoading}
      onMenuScrollToBottom={onMenuScrollToBottom}
      optionComponent={Option}
      noResultsText={<Typography>{"Nema pronađenih rezultata"}</Typography>}
      arrowRenderer={arrowProps => {
        return arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
      }}
      clearRenderer={() => <ClearIcon />}
      valueComponent={valueProps => {
        const { value, children, onRemove } = valueProps;

        const onDelete = event => {
          event.preventDefault();
          event.stopPropagation();
          onRemove(value);
        };

        if (onRemove) {
          return (
            <Chip
              tabIndex={-1}
              label={children}
              className={classes.chip}
              deleteIcon={<CancelIcon onTouchEnd={onDelete} />}
              onDelete={onDelete}
            />
          );
        }

        return <div className="Select-value">{children}</div>;
      }}
      {...other}
    />
  );
}

const ITEM_HEIGHT = 48;

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 4,
  },
  "@global": {
    ".Select-control": {
      border: "none",
      marginTop: "-7px",
      "&:hover": {
        boxShadow: "none",
      },
    },
    ".Select-input": {
      paddingLeft: "0px",
    },
    ".Select-placeholder, .Select--single > .Select-control .Select-value": {
      paddingLeft: "0px",
    },
    ".Select": {
      height: "19px",
    },
    ".Select-loading-zone": {
      verticalAlign: "inherit",
    },
    ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
      paddingRight: "53px",
      color: "black",
    },
    ".Select.is-focused:not(.is-open) > .Select-control": {
      boxShadow: "none",
    },
    // FOR DROPDOWN
    ".Select-menu-outer": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: "absolute",
      left: 0,
      top: `calc(100% + ${theme.spacing.unit}px)`,
      width: "100%",
      zIndex: 2,
      maxHeight: ITEM_HEIGHT * 4.5,
    },
    ".Select-menu": {
      maxHeight: ITEM_HEIGHT * 4.5,
      overflowY: "auto",
    },
    ".Select-menu div": {
      boxSizing: "content-box",
    },
  },
});

class AutocompleteReactSelect extends React.Component {
  selectWrapper = props => {
    const { onMenuScrollToBottom, isLoading, isClearable, autoBlur } = this.props;

    return (
      <SelectWrapped
        {...props}
        autoBlur={autoBlur}
        isClearable={isClearable}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={isLoading}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <FormControl fullWidth error={this.props.error || false}>
        {this.props.label ? <InputLabel shrink={true}>{this.props.label}</InputLabel> : null}
        <Input
          fullWidth
          className="custom-autocomplete-underline"
          inputComponent={this.selectWrapper}
          value={this.props.defaultValue}
          onChange={this.props.autocompleteHandler}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          inputProps={{
            classes,
            simpleValue: true,
            options: this.props.dataSource,
            ...this.props.inputProps,
          }}
        />
        {this.props.error && <FormHelperText>{this.props.errorMsg}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withStyles(styles)(AutocompleteReactSelect);
