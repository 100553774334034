import React from "react";

// libs
import { get } from "lodash";

// ui
import classNames from "classnames";
import { Button, Card, CardActions, CardContent, Grid, Typography, withStyles } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/ContentCopy";

// hooks
import useAnnouncementOrderEditForm from "./hooks/useAnnouncementOrderEditForm";

// utils
import { formatDate } from "src/utility/dates";

// components
import ShowField from "./components/ShowField";
import EditInput from "./components/EditInput";
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";

// styles
const styles = theme => ({
  content: {
    padding: 24,
  },

  button: {
    margin: theme.spacing.unit,
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

/**
 * AnnouncementOrderEditForm
 */
const AnnouncementOrderEditForm = ({ classes, announcementOrderId }) => {
  // hooks
  const {
    // variables
    announcementOrder,
    loading,
    // functions
    copyAnnouncementOrderToClipboard,
    handleOnUpdateAnnouncementOrder,
    onBackClick,
  } = useAnnouncementOrderEditForm(announcementOrderId);

  if (loading) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <Grid style={{ marginTop: 18 }} container spacing={8} alignItems="center">
            <Grid item xs={2}>
              <FixedCircularProgress color="inherit" size={16} thickness={6} />
            </Grid>

            <Grid item xs={10}>
              <Typography variant="body1">Dohvaćam podatke</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  // return root
  return (
    <Card>
      <CardContent id="announcement_order" className={classes.content}>
        {/** contract no */}
        <ShowField label={"Ugovor"} textValue={get(announcementOrder, "dictate_data.external_id", "")} />
        {/** theme / group name */}
        <ShowField label={"Grupa"} textValue={get(announcementOrder, "dictate_data.theme_name", "")} />
        {/** termin */}
        <ShowField
          label={"Termin"}
          textValue={`${formatDate(get(announcementOrder, "dictate_data.from", ""))} - ${formatDate(
            get(announcementOrder, "dictate_data.to", ""),
          )}`}
        />
        {/** invoice */}
        <EditInput
          caption={"Datum fakture"}
          keyOfValue={"dictate_invoice_text"}
          value={get(announcementOrder, "dictate_invoice_text", "")}
          onSaveClick={(keyOfValue, value) => handleOnUpdateAnnouncementOrder(keyOfValue, value)}
        />

        {/** room text */}
        <EditInput
          caption={"Struktura soba"}
          keyOfValue={"dictate_room_text"}
          value={get(announcementOrder, "dictate_room_text", "")}
          onSaveClick={(keyOfValue, value) => handleOnUpdateAnnouncementOrder(keyOfValue, value)}
        />

        {/** gratis text */}
        <EditInput
          caption={"Gratis"}
          keyOfValue={"dictate_gratis_text"}
          value={get(announcementOrder, "dictate_gratis_text", "")}
          onSaveClick={(keyOfValue, value) => handleOnUpdateAnnouncementOrder(keyOfValue, value)}
        />

        {/** extra text */}
        <EditInput
          caption={"Dodatno"}
          keyOfValue={"dictate_extra_text"}
          value={get(announcementOrder, "dictate_extra_text", "")}
          onSaveClick={(keyOfValue, value) => handleOnUpdateAnnouncementOrder(keyOfValue, value)}
        />
      </CardContent>

      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          className={classes.button}
          disabled={!announcementOrder}
          onClick={onBackClick}
        >
          Natrag
        </Button>

        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.button}
          disabled={!announcementOrder}
          onClick={() => {
            copyAnnouncementOrderToClipboard("announcement_order");
          }}
        >
          <CopyIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
          Kopiraj nalog
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(AnnouncementOrderEditForm);
