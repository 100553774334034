import gql from "graphql-tag";
import {
  offerFragment,
  offerContractDocumentFragment,
  inquiryFragment,
  mainTerminFragment,
  terminsFragment,
  templateFragment,
  dailyTerminPrograms,
  contigentFragment,
  attachmentFragment,
  auditLogFragment,
  alotmanTemplateFragment,
} from "../fragments";

export const allOffers = gql`
  query($input: Condition) {
    listOffer(input: $input) {
      id
      external_id
      off_key
      count
      lock_owner {
        id
        first_name
        last_name
      }
      offer_contract_document {
        id
        inquiry_id
        inquiry_external_id
        attachments {
          ...Attachment
        }
        partner_client {
          id
          name
        }
        document_code
        document_date_validity
        offer_contract_document_type {
          id
          desc
        }
        created_at
        last_edit_by_user_time
        last_edit_by_user {
          id
          username
        }
      }
      offer_status {
        id
        desc
      }
    }
  }
  ${attachmentFragment}
`;

export const fetchOffer = gql`
  query($id: ID!) {
    getOffer(id: $id) {
      ...OfferContent
      offer_contract_document {
        ...OfferContractDocument
        ...Inquiry
        programs {
          ...DailyProgram
        }
        contract {
          id
          external_id
        }
        attachments {
          ...Attachment
        }
        termin_periods {
          ...MainTermin
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
          programs {
            ...DailyProgram
          }
        }
        ...Template
        ...AlotmanTemplate
      }
    }
  }
  ${offerFragment}
  ${offerContractDocumentFragment}
  ${inquiryFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${templateFragment}
  ${alotmanTemplateFragment}
  ${dailyTerminPrograms}
  ${contigentFragment}
  ${attachmentFragment}
`;

export const createOfferData = gql`
  {
    listLanguage {
      id
      code
    }
    listOfferStatus {
      id
      desc
      code
    }

    listOfferContractDocumentType {
      id
      desc
    }

    listPartnerClientService {
      id
      price
      partner_client_service_translations {
        id
        name
        description
        language_id
      }
    }

    listTemplateTranslation {
      id
      vat
      free_place
      price_validity
      surcharges
      sojourn_tax
      payment
      storno
      program_changes
      content
      program
      room_list
      booking_state
      document_date_validity_comment
      comment_contigent
      language_id
    }

    listActivity {
      id
      default_price
      activity_type {
        id
        desc
        code
      }
      activity_translations {
        id
        name
        desc
        language_id
      }
    }

    listOfferContractDocumentSegment {
      id
      desc
      code
    }

    listPriceStructureType {
      id
      desc
      code
    }

    listServiceType {
      id
      service_type_translations {
        id
        name
        language_id
        language {
          id
          desc
        }
      }
    }
  }
`;

export const getOfferLog = gql`
  query($id: ID) {
    getOfferLog(id: $id) {
      offers {
        ...AuditLog
      }
      offer_contract_documents {
        ...AuditLog
      }
      termin_periods {
        ...AuditLog
      }
      contigents {
        ...AuditLog
      }
      services {
        ...AuditLog
      }
      additional_services {
        ...AuditLog
      }
    }
  }

  ${auditLogFragment}
`;
