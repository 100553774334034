import React, { Component } from "react";
import { connect } from "react-redux";

import { changeDocumentParam, changeTemplateLanguage } from "../../../../store/actions";
import { decimalNumbersTwoDigits } from "../../../../utility/numbers";

import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import TemplateVatHOC from "./TemplateVat";
import TemplateTaxHOC from "./TemplateTax";
import TemplateFreePlaceHOC from "./TemplateFreePlace";
import TemplateMinPersonsHOC from "./TemplateMinPersons";
import TemplateBookingStateHOC from "./TemplateBookingState";
import TemplateRoomStateHOC from "./TemplateRoomState";
import TemplateProgramHOC from "./TemplateProgram";
import TemplateContentHOC from "./TemplateContent";
import TemplateProgramChangesHOC from "./TemplateProgramChanges";
import TemplateStornoHOC from "./TemplateStorno";
import TemplatePayingHOC from "./TemplatePaying";
import TemplateSurchargesHOC from "./TemplateSurcharges";

class Templates extends Component {
  componentDidUpdate(prevProps) {
    const { changeTemplateLanguage, language_id } = this.props;

    if (language_id && prevProps.language_id !== language_id) {
      // setting up all template content
      const template = this.retreiveLanguageTemplateContents();
      if (template) {
        changeTemplateLanguage(template);
      }

      this.changeTemplateValuesDynamically();
    }
  }

  componentDidMount = () => {
    // if not in edit
    if (!this.props.match.params.id) {
      // setting up all template content
      const { changeTemplateLanguage } = this.props;
      const template = this.retreiveLanguageTemplateContents();

      // adding values to template content
      if (template) {
        changeTemplateLanguage(template);
      }

      this.changeTemplateValuesDynamically();
    }
  };

  changeTemplateValuesDynamically() {
    const {
      sojourn_tax_value,
      notification_price_validity,
      notification_booking_state,
      notification_room_list,
      notification_storno,
      notification_payment,
      comment_contigent,
      document_date_validity_comment,
    } = this.props;

    const arr = [
      {
        name: "sojourn_tax_value",
        value: sojourn_tax_value,
      },
      {
        name: "notification_price_validity",
        value: notification_price_validity,
      },
      {
        name: "notification_booking_state",
        value: notification_booking_state,
      },
      {
        name: "notification_room_list",
        value: notification_room_list,
      },
      {
        name: "notification_storno",
        value: notification_storno,
      },
      {
        name: "notification_payment",
        value: notification_payment,
      },
      {
        name: "comment_contigent",
        value: comment_contigent,
      },
      {
        name: "document_date_validity_comment",
        value: document_date_validity_comment,
      },
    ];

    arr.forEach(obj => this.changeContent({ target: obj }));
  }

  changeContent = ({ target: { name, value } }) => {
    const { changeDocumentParam } = this.props;

    const template = this.retreiveLanguageTemplateContents();

    switch (name) {
      case "sojourn_tax_value":
        if (template) {
          changeDocumentParam({
            key: name,
            value: decimalNumbersTwoDigits(value),
          });
          changeDocumentParam({
            key: "sojourn_tax_content",
            value: template.sojourn_tax.replace("${}", Number(decimalNumbersTwoDigits(value)).toFixed(2)),
          });
        }
        break;
      case "notification_price_validity":
        if (template) {
          changeDocumentParam({
            key: name,
            value: value,
          });
          changeDocumentParam({
            key: "price_validity_content",
            value: template.price_validity.replace("${}", value),
          });
        }
        break;
      case "notification_booking_state":
        if (template) {
          changeDocumentParam({
            key: name,
            value: value,
          });
          changeDocumentParam({
            key: "booking_state_content",
            value: template.booking_state.replace("${}", value),
          });
        }
        break;
      case "notification_room_list":
        if (template) {
          changeDocumentParam({
            key: name,
            value: value,
          });
          changeDocumentParam({
            key: "room_list_content",
            value: template.room_list.replace("${}", value),
          });
        }
        break;
      case "notification_storno":
        if (template) {
          changeDocumentParam({
            key: name,
            value: value,
          });
          changeDocumentParam({
            key: "storno_content",
            value: template.storno.replace("${}", value),
          });
        }
        break;
      case "notification_payment":
        if (template) {
          changeDocumentParam({
            key: name,
            value: value,
          });
          changeDocumentParam({
            key: "payment_content",
            value: template.payment.replace("${}", value),
          });
        }
        break;
      // contigent comment template
      case "comment_contigent":
        if (template) {
          changeDocumentParam({
            key: "comment_contigent",
            value: template.comment_contigent,
          });
        }
        break;

      // document date validity comment template
      case "document_date_validity_comment":
        if (template) {
          changeDocumentParam({
            key: "document_date_validity_comment",
            value: template.document_date_validity_comment,
          });
        }
        break;

      default:
        changeDocumentParam({
          key: name,
          value,
        });
        break;
    }
  };

  retreiveLanguageTemplateContents() {
    const { language_id: lang_id, listTemplateTranslation } = this.props;

    return lang_id ? listTemplateTranslation.find(({ language_id }) => language_id === lang_id) : null;
  }

  render() {
    const { isNotDraft, language_id } = this.props;

    return (
      <React.Fragment>
        <div className="form-unit-double">
          <div />
          {!language_id ? (
            <Typography variant="body2" gutterBottom>
              Potrebno je odabrati jezik prije upisa teksta
            </Typography>
          ) : (
            <div />
          )}

          <TemplateVatHOC
            isNotDraft={isNotDraft}
            vat_content={this.props.vat_content}
            changeContent={this.changeContent}
          />
        </div>
        <TemplateTaxHOC
          isNotDraft={isNotDraft}
          sojourn_tax_value={this.props.sojourn_tax_value}
          sojourn_tax_content={this.props.sojourn_tax_content}
          changeContent={this.changeContent}
        />

        <TemplateFreePlaceHOC
          isNotDraft={isNotDraft}
          free_place_content={this.props.free_place_content}
          changeContent={this.changeContent}
        />

        <div>
          <h2>Ostale usluge</h2>
        </div>

        <div className="form-unit-double">
          <TemplateMinPersonsHOC
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
            notification_price_validity={this.props.notification_price_validity}
            price_validity_content={this.props.price_validity_content}
          />

          <TemplateBookingStateHOC
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
            booking_state_content={this.props.booking_state_content}
            notification_booking_state={this.props.notification_booking_state}
          />

          <TemplateRoomStateHOC
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
            notification_room_list={this.props.notification_room_list}
            room_list_content={this.props.room_list_content}
          />
        </div>

        <div className="form-unit-double">
          <TemplateProgramHOC
            program_content={this.props.program_content}
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
          />

          <TemplateContentHOC
            content_content={this.props.content_content}
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
          />

          <TemplateProgramChangesHOC
            program_changes_content={this.props.program_changes_content}
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
          />
        </div>

        <div className="form-unit-double">
          <TemplateStornoHOC
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
            notification_storno={this.props.notification_storno}
            storno_content={this.props.storno_content}
          />

          <TemplatePayingHOC
            isNotDraft={isNotDraft}
            changeContent={this.changeContent}
            notification_payment={this.props.notification_payment}
            payment_content={this.props.payment_content}
          />
        </div>

        <TemplateSurchargesHOC
          isNotDraft={isNotDraft}
          changeContent={this.changeContent}
          surcharges_content={this.props.surcharges_content}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      offer_contract_document: {
        language_id,
        sojourn_tax_value,
        notification_price_validity,
        notification_booking_state,
        notification_room_list,
        notification_storno,
        notification_payment,
        sojourn_tax_content,
        vat_content,
        free_place_content,
        price_validity_content,
        surcharges_content,
        payment_content,
        storno_content,
        program_changes_content,
        content_content,
        program_content,
        room_list_content,
        booking_state_content,
        comment_contigent,
        document_date_validity_comment,
      },
    },
  } = state.offer;

  return {
    language_id,
    sojourn_tax_value,
    notification_price_validity,
    notification_booking_state,
    notification_room_list,
    notification_storno,
    notification_payment,
    sojourn_tax_content,
    vat_content,
    free_place_content,
    price_validity_content,
    surcharges_content,
    payment_content,
    storno_content,
    program_changes_content,
    content_content,
    program_content,
    room_list_content,
    booking_state_content,
    comment_contigent,
    document_date_validity_comment,
  };
};

export default connect(mapStateToProps, { changeDocumentParam, changeTemplateLanguage })(withRouter(Templates));
