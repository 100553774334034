import React, { Fragment, PureComponent } from "react";
import { withApollo } from "react-apollo";

import { formatDate } from "../../utility/dates";

class PreviewHeaderDBData extends PureComponent {
  render() {
    const {
      voucher: {
        contract: {
          offer_contract_document: { partner_client },
        },
        typology: { establishment },
      },
    } = this.props;

    return (
      <div id="preview-header-db-data" className="preview-header-db-data">
        <div className="preview-client-data">
          <Fragment>
            <p>{partner_client && partner_client.name}</p>
            <br />
            <p>{establishment.name}</p>
            <p>{establishment.address1}</p>
            <p>
              {establishment.city.postal_code}, {establishment.city.name}
            </p>
            <p>Tel: {establishment.phone}</p>
          </Fragment>
        </div>
        <div>
          <p className="preview-float-right">Malinska, {formatDate()}</p>
        </div>
      </div>
    );
  }
}

export default withApollo(PreviewHeaderDBData);
