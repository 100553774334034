import React, { Component } from "react";

import Comment from "../../../../components/UI/CommentTextArea";

import { connect } from "react-redux";

import DatePicker from "../../../../components/UI/DatePicker";
import { changeDocumentParam } from "../../../../store/actions";
import { autosize } from "../../../../utility/globals";

class OfferDateValidity extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`document_date_validity_comment`).length) {
      autosize(document.getElementsByName(`document_date_validity_comment`)[0]);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="cols-2">
          <div className="form-unit-double">
            <div className="field-label" style={{ paddingTop: "0.375em" }}>
              <label className="label">Datum opcije ponude</label>
            </div>
            <div>
              <DatePicker value={this.props.document_date_validity} disabled={true} />
              <Comment
                defaultValue={this.props.document_date_validity_comment}
                label="Komentar"
                name="document_date_validity_comment"
                rows={3}
                inputProps={{ disabled: true }}
              />
            </div>
          </div>
          <div />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    from,
    to,
    offer: {
      offer_contract_document: { document_date_validity, document_date_validity_comment },
    },
  } = state.offer;
  return {
    from,
    to,
    document_date_validity,
    document_date_validity_comment,
  };
};

export default connect(
  mapStateToProps,
  { changeDocumentParam },
)(OfferDateValidity);
