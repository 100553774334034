import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";

import { createActivity } from "../../../../graphql/mutation/administration";

import ActivityForm from "./ActivityForm";
import { listLanguage as languages } from "../../../../graphql/query/administration";
import TableTitle from "src/components/UI/TableTitle";

class CreateActivity extends React.Component {
  state = {
    activity: {
      default_price: "",
      activity_type_id: "",
      activity_translations: [],
    },
    listLanguage: [],
  };

  async componentDidMount() {
    const res = await this.props.client.query({
      query: languages,
    });

    const { listLanguage } = res.data;

    // Set languages for creating activity_translations
    if (listLanguage) {
      const activity_translations = listLanguage.map(({ id }) => ({
        desc: "",
        name: "",
        language_id: id,
      }));

      this.setState(prevState => {
        return {
          listLanguage,
          activity: {
            ...prevState.activity,
            activity_translations,
          },
        };
      });
    }
  }

  changeFieldData = obj => {
    this.setState(prevState => {
      return {
        activity: {
          ...prevState.activity,
          [obj.key]: obj.value,
        },
      };
    });
  };

  changeActivityTranslationField = (obj, language_id) => {
    const {
      activity: { activity_translations },
    } = this.state;

    const at_clone = activity_translations.map(at => {
      if (at.language_id === language_id) {
        return {
          ...at,
          [obj.key]: obj.value,
        };
      }
      return at;
    });

    this.setState(prevState => {
      return {
        activity: {
          ...prevState.activity,
          activity_translations: at_clone,
        },
      };
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state.activity,
        },
      });

      this.props.cancelToggle();
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <Fragment>
        <TableTitle title="Kreiranje nove aktivnosti" onBackPressed={this.props.cancelToggle} />
        <ActivityForm
          onSubmit={this.submit}
          listLanguage={this.state.listLanguage}
          activity={this.state.activity}
          cancelToggle={this.props.cancelToggle}
          onChangeField={this.changeFieldData}
          editTranslation={this.changeActivityTranslationField}
        />
      </Fragment>
    );
  }
}

export default graphql(createActivity)(withApollo(CreateActivity));
