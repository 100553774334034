import React from "react";
import FlatButton from "material-ui/FlatButton";
import Popover, { PopoverAnimationVertical } from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

import KeyboardArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import { getPersonName } from "../../utility/user";

export default class PersonDropdown extends React.Component {
  state = {
    open: false,
  };

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  toSettings() {
    this.props.history.push(`/korisnik/postavke`);
  }

  logOut() {
    localStorage.clear();
    this.props.history.push("/");
  }

  render() {
    return (
      <div>
        <FlatButton
          onClick={this.handleClick}
          label={getPersonName().username}
          labelPosition="before"
          icon={<KeyboardArrowDownIcon />}
        />
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onRequestClose={this.handleRequestClose}
          animation={PopoverAnimationVertical}
        >
          <Menu>
            <MenuItem primaryText="Postavke" onClick={this.toSettings.bind(this)} />
          </Menu>
          <Menu>
            <MenuItem primaryText="Odjava" onClick={this.logOut.bind(this)} />
          </Menu>
        </Popover>
      </div>
    );
  }
}
