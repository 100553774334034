import { useEffect, useState } from "react";

// libs
import { get, debounce } from "lodash";
import { useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// graphql
import { FETCH_LIST_GUIDE_LOCAL_ANNOUNCEMENT } from "src/graphql/query/announcement";

// redux state
import {
  selectGuideLocalAnnouncements,
  setGuideLocalAnnouncements,
  setDrawerOpen,
  setAnnouncementSelected,
} from "../../../announcementSlice";

// columns for list
import guideLocalAnnouncementColumns from "src/views/Announcements/components/GuideLocalAnnouncementList/components/Columns/GuideLocalAnnouncementColumns";

// constants
import { ANNOUNCEMENT_ROW_ID_EDIT, GUIDE_LOCAL_ANNOUNCEMENT } from "src/utility/constants";
import { DEBOUNCE_FETCH_DELAY } from "src/utility/reactTable";
import { useAnnouncementsLocalStorage } from "src/views/Announcements/hooks/useAnnouncementsLocalStorage";

/**
 * useGuideLocalAnnouncementList is entry point of data for guideLocalAnnouncement component
 */
export default function useGuideAnnouncementList() {
  // local storage
  const { tableState, setTableState } = useAnnouncementsLocalStorage();

  const announcementPageSelected = tableState.announcementsPageSelected[GUIDE_LOCAL_ANNOUNCEMENT];
  const announcementPageSizeSelected = tableState.announcementsPageSizeSelected[GUIDE_LOCAL_ANNOUNCEMENT];
  const announcementFilters = tableState.announcementsFiltersSelected[GUIDE_LOCAL_ANNOUNCEMENT];

  // redux state
  const listGuideLocalAnnouncement = useSelector(selectGuideLocalAnnouncements);

  const dispatch = useDispatch();

  // state
  const [pages, setPages] = useState(null);

  // temp variables
  const canPrevious = announcementPageSelected > 0;
  const canNext = announcementPageSelected + 1 < pages;

  /**
   * handle on filter change
   */
  // prepare filters for where condition
  const prepareFilters = filtered => {
    let filtersToSend = {};

    filtered.forEach(obj => {
      // check ofr different sending key/values then ones received for table list

      switch (obj.id) {
        case "id":
          filtersToSend = {
            ...filtersToSend,
            announcement_id: obj.value,
          };
          break;

        case "hotel":
          filtersToSend = {
            ...filtersToSend,
            establishment_name: obj.value,
          };
          break;

        case "guide":
          filtersToSend = {
            ...filtersToSend,
            g_name: obj.value,
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  };

  // queries
  const { data, loading } = useQuery(FETCH_LIST_GUIDE_LOCAL_ANNOUNCEMENT, {
    variables: {
      input: {
        pagination_limit: {
          limit: announcementPageSizeSelected,
          offset: announcementPageSelected * announcementPageSizeSelected,
        },
        where: {
          ...(announcementFilters.length && prepareFilters(announcementFilters)),
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // set announcement list in context
  useEffect(() => {
    const listGuideLocalAnnouncement = get(data, "listGuideLocalAnnouncementFilter");

    if (listGuideLocalAnnouncement) {
      const totalTableCount = get(listGuideLocalAnnouncement, "[0].total_table_count");

      const pages = totalTableCount ? Math.ceil(totalTableCount / announcementPageSizeSelected) : 1;

      setPages(pages);

      dispatch(setGuideLocalAnnouncements(listGuideLocalAnnouncement));
    }
  }, [announcementPageSizeSelected, data, dispatch]);

  /**
   * table list props
   */
  // table tr props, used for styling background row based on group operativa status
  const getAnnouncementTableTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        // Depending on which row is clicked, open drawer
        switch (column.id) {
          case ANNOUNCEMENT_ROW_ID_EDIT:
            dispatch(setDrawerOpen(true));
            dispatch(
              setAnnouncementSelected({
                id: get(rowInfo, "original.id", ""),
                announcement_type: GUIDE_LOCAL_ANNOUNCEMENT,
              }),
            );
            break;
          default:
        }
      },
    };
  };

  /**
   * handle announcements filters
   */
  const setFiltered = filtered => {
    setTableState({
      ...tableState,
      announcementsFiltersSelected: {
        ...tableState.announcementsFiltersSelected,
        [GUIDE_LOCAL_ANNOUNCEMENT]: filtered,
      },
      announcementsPageSelected: { ...tableState.announcementsPageSelected, [GUIDE_LOCAL_ANNOUNCEMENT]: 0 },
    });
  };

  // on filtered change
  const onAnnouncementsFilterChange = debounce(setFiltered, DEBOUNCE_FETCH_DELAY);

  /**
   * on announcement table page change
   */
  const onAnnouncementTablePageChange = pageSelected => {
    setTableState({
      ...tableState,
      announcementsPageSelected: {
        ...tableState.announcementsPageSelected,
        [GUIDE_LOCAL_ANNOUNCEMENT]: pageSelected,
      },
    });
  };

  /**
   * on announcement table page change
   */
  const onAnnouncementTablePageSizeChange = pageSizeSelected => {
    setTableState({
      ...tableState,
      announcementsPageSizeSelected: {
        ...tableState.announcementsPageSizeSelected,
        [GUIDE_LOCAL_ANNOUNCEMENT]: pageSizeSelected,
      },
    });
  };

  return {
    // variables
    announcementFilters,
    announcementPageSelected,
    announcementPageSizeSelected,
    canPrevious,
    canNext,
    guideLocalAnnouncementColumns,
    listGuideLocalAnnouncement,
    loading,
    pages,

    // functions
    getAnnouncementTableTdProps,
    onAnnouncementsFilterChange,
    onAnnouncementTablePageChange,
    onAnnouncementTablePageSizeChange,
  };
}
