import React, { Fragment } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";

import PartnerServiceTypesForm from "./PartnerServiceTypeForm";

import { AdministrationTabs } from "../AdministrationTabs";
import { updatePartnerClientServiceType } from "../../../graphql/mutation/administration";
import { getPartnerClientServiceType as fetchPCST } from "../../../graphql/query/administration";
import { prepareForSending } from "../../../utility/prepare";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class EditPartnerServiceType extends React.Component {
  state = {
    desc: "",
  };

  async componentDidMount() {
    try {
      const res = await this.props.client.query({
        query: fetchPCST,
        variables: {
          id: this.props.match.params.id,
        },
      });

      const { getPartnerClientServiceType } = res.data;

      this.setState({ ...getPartnerClientServiceType });
    } catch (error) {}
  }

  changeField = ({ target: { name, value } }) =>
    this.setState({
      [name]: value,
    });

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...prepareForSending(this.state, ["__typename"]),
        },
      });

      toastr.success("Tip usluge partnera uspješno ažuriran");

      this.props.history.push("/administracija/tip_usluge_partnera");
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle
          title="Ažuriranje tipa usluge partnera"
          onBackPressed={() => {
            this.props.history.push("/administracija/tip_usluge_partnera");
          }}
        />

        <PartnerServiceTypesForm
          serviceType={{ ...this.state }}
          onChangeField={this.changeField}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(graphql(updatePartnerClientServiceType))(withApollo(EditPartnerServiceType)),
  "Ažuriranje tipa usluge partnera",
);
