import React from "react";

// hooks
import useGuideNotices from "./hooks/useGuideNotice";

// constants
import { TITLE_GUIDE_NOTICES } from "src/utility/labels";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import GuideNoticeContainer from "./components/Container";

/**
 * GuideNotice drawer is used for showing data for guide notice
 */
export default function GuideNotice() {
  const { handleOnGuideNoticesSave, isDrawerOpen, loading, onCloseDrawer } = useGuideNotices();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnGuideNoticesSave}
      title={TITLE_GUIDE_NOTICES}
      customWidth="85%"
    >
      <GuideNoticeContainer />
    </DrawerWrapper>
  );
}
