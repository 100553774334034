import React, { Fragment } from "react";

// context

// hooks
import useListContainer from "./hooks/useListContainer";
// constants
import {
  HOTEL_GUIDE_ANNOUNCEMENT,
  HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GUIDE_ANNOUNCEMENT,
  GUIDE_TOUR_ANNOUNCEMENT,
  GUIDE_LOCAL_ANNOUNCEMENT,
  AGENCY_ANNOUNCEMENT,
  AGENCY_TOUR_ANNOUNCEMENT,
  AGENCY_LOCAL_ANNOUNCEMENT,
  AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  SUPPLIER_ANNOUNCEMENT,
  PHRASE_ANNOUNCEMENT,
} from "src/utility/constants";
// components
import HotelGuideAnnouncementList from "../HotelGuideAnnouncementList";
import HotelNoGuideAnnouncementList from "../HotelNoGuideAnnouncementList";
import GuideAnnouncementList from "../GuideAnnouncementList";
import GuideTourAnnouncementList from "../GuideTourAnnouncementList";
import GuideLocalAnnouncementList from "../GuideLocalAnnouncementList";
import AgencyAnnouncementList from "../AgencyAnnouncementList";
import AgencyTourAnnouncementList from "../AgencyTourAnnouncementList";
import AgencyLocalAnnouncementList from "../AgencyLocalAnnouncementList";
import AgencyLocalProgramAnnouncementList from "../AgencyLocalProgramAnnouncementList";
import SupplierAnnouncementList from "../SupplierAnnouncementList";
import PhraseAnnouncementList from "../PhraseAnnouncementList";
import PhraseAnnouncementCreate from "../PhraseAnnouncementCreate";

/**
 * ListContainer is used for showing list of different types of announcements
 */
export default function ListContainer() {
  const { announcementTypeSelected } = useListContainer();

  const renderAnnouncementList = announcementTypeSelected => {
    switch (announcementTypeSelected) {
      case HOTEL_GUIDE_ANNOUNCEMENT:
        return <HotelGuideAnnouncementList />;

      case HOTEL_NO_GUIDE_ANNOUNCEMENT:
        return <HotelNoGuideAnnouncementList />;

      case GUIDE_ANNOUNCEMENT:
        return <GuideAnnouncementList />;

      case GUIDE_TOUR_ANNOUNCEMENT:
        return <GuideTourAnnouncementList />;

      case GUIDE_LOCAL_ANNOUNCEMENT:
        return <GuideLocalAnnouncementList />;

      case AGENCY_ANNOUNCEMENT:
        return <AgencyAnnouncementList />;

      case AGENCY_TOUR_ANNOUNCEMENT:
        return <AgencyTourAnnouncementList />;

      case AGENCY_LOCAL_ANNOUNCEMENT:
        return <AgencyLocalAnnouncementList />;

      case AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT:
        return <AgencyLocalProgramAnnouncementList />;

      case SUPPLIER_ANNOUNCEMENT:
        return <SupplierAnnouncementList />;

      case PHRASE_ANNOUNCEMENT:
        return <PhraseAnnouncementList />;

      default:
        return null;
    }
  };

  // return root
  return (
    <Fragment>
      {renderAnnouncementList(announcementTypeSelected)}
      <PhraseAnnouncementCreate />
    </Fragment>
  );
}
