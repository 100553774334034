import React from "react";

// ui
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { Grid, Typography } from "@material-ui/core";
import { grey900 } from "material-ui/styles/colors";

// constants
import { TITLE_TOOLBAR_ANNOUNCEMENT_ORDER_EDIT } from "src/utility/labels";
import useToolbarView from "./hooks/useToolbarView";

// components
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";

/**
 * ToolbarView
 */
export default function AnnouncementOrderEditToolbarView({ announcementOrderId }) {
  const { loading } = useToolbarView();

  // return root
  return (
    <Toolbar style={{ padding: "0", backgroundColor: "transparent" }}>
      <ToolbarGroup>
        <ToolbarTitle
          text={`${TITLE_TOOLBAR_ANNOUNCEMENT_ORDER_EDIT} ${announcementOrderId}`}
          style={{
            color: grey900,
          }}
        />
      </ToolbarGroup>

      {loading && (
        <ToolbarGroup>
          <Grid style={{ marginTop: 18 }} container spacing={16} alignItems="center">
            <Grid item xs={2}>
              <FixedCircularProgress color="inherit" size={16} thickness={6} />
            </Grid>

            <Grid item xs={10}>
              <Typography variant="body1">Pričekajte</Typography>
            </Grid>
          </Grid>
        </ToolbarGroup>
      )}
    </Toolbar>
  );
}
