// libs
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideScheduleFiltersOpen,
  selectGuideScheduleGridFilters,
  setGuideScheduleFiltersOpen,
} from "src/views/Guides/guidesSlice";

// component hook state
import { useToolbarState } from "../../../../hooks/useToolbarState";

// graphql
import { UPDATE_COLUMNS } from "src/graphql/mutation/operative";

/**
 * useGridFilters is used for data handling for GridFilter component
 */
export default function useGridFilters() {
  // redux state
  const filtersOpen = useSelector(selectGuideScheduleFiltersOpen);
  const gridFilters = useSelector(selectGuideScheduleGridFilters);

  const dispatch = useDispatch();

  // toolbar component state
  const [toolbarState, setToolbarState] = useToolbarState();

  // change active column mutation
  const [changeActiveColumn] = useMutation(UPDATE_COLUMNS);

  const setFiltersOpen = filtersOpen => {
    dispatch(setGuideScheduleFiltersOpen(filtersOpen));
  };

  // change active guide column with mutation hook
  const changeActiveGuidesColumns = (datagrid_filter_id, active) => {
    changeActiveColumn({
      variables: {
        datagrid_filter_id,
        active,
      },
    });
  };

  const handleOnCloseFilter = () => {
    setToolbarState({ filtersOpen: false });
  };

  return {
    toolbarState,
    setToolbarState,
    filtersOpen,
    setFiltersOpen,
    gridFilters,
    changeActiveGuidesColumns,
    handleOnCloseFilter,
  };
}
