import React from "react";

import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import CustomTextArea from "../../../components/UI/CommentTextArea";
import FormValidator from "../../../components/UI/FormValidator";
import { Typography } from "@material-ui/core";

class TemplateForm extends React.Component {
  static schema = {
    properties: {
      vat: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Valjanost cijene mora biti tekst",
      },
      free_place: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Slobodno mjesto mora biti tekst",
      },
      price_validity: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Minimalni broj osoba mora biti tekst",
      },
      surcharges: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Dodatno mora biti tekst",
      },
      program_changes: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Izmjene programa mora biti tekst",
      },
      content: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Sadržaj mora biti tekst",
      },
      program: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Program mora biti tekst",
      },
      booking_state: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Stanje rezervacije mora biti tekst",
      },
      room_list: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Stanje soba mora biti tekst",
      },
      sojourn_tax: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Boraviša pristrojba mora biti text",
      },
      payment: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Plaćanje mora biti tekst",
      },
      storno: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Storno mora biti tekst",
      },
      comment_contigent: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Kontigent mora biti tekst",
      },
      document_date_validity_comment: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Komentar na datum opcije ponude mora biti tekst",
      },
    },
  };

  render() {
    const { template, changeTemplateField, onSubmit } = this.props;

    return (
      <FormValidator schema={TemplateForm.schema} submit={onSubmit} data={template}>
        {({ error, errorMsg, submit }) => (
          <form className="user-form" onSubmit={submit}>
            <Typography variant="body2" gutterBottom>
              {"Potrebno je postaviti ${} znak na dijelove texta koji će se dinamički mijenjati"}
            </Typography>
            <CustomTextArea
              error={error("/vat")}
              errorMsg={errorMsg("/vat")}
              label="PDV"
              defaultValue={template.vat}
              customOnChange={changeTemplateField("vat")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/sojourn_tax")}
              errorMsg={errorMsg("/sojourn_tax")}
              label="Boravišna pristrojba"
              defaultValue={template.sojourn_tax}
              customOnChange={changeTemplateField("sojourn_tax")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/free_place")}
              errorMsg={errorMsg("/free_place")}
              label="Sl. mjesto"
              defaultValue={template.free_place}
              customOnChange={changeTemplateField("free_place")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/price_validity")}
              errorMsg={errorMsg("/price_validity")}
              label="Minimalni broj osoba"
              defaultValue={template.price_validity}
              customOnChange={changeTemplateField("price_validity")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/surcharges")}
              errorMsg={errorMsg("/surcharges")}
              label="Dodatno"
              defaultValue={template.surcharges}
              customOnChange={changeTemplateField("surcharges")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/program_changes")}
              errorMsg={errorMsg("/program_changes")}
              label="Izmjene programa"
              defaultValue={template.program_changes}
              customOnChange={changeTemplateField("program_changes")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/content")}
              errorMsg={errorMsg("/content")}
              label="Fotografije"
              defaultValue={template.content}
              customOnChange={changeTemplateField("content")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/program")}
              errorMsg={errorMsg("/program")}
              label="Sadržaj programa"
              defaultValue={template.program}
              customOnChange={changeTemplateField("program")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/booking_state")}
              errorMsg={errorMsg("/booking_state")}
              label="Stanje rezervacije"
              defaultValue={template.booking_state}
              customOnChange={changeTemplateField("booking_state")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/room_list")}
              errorMsg={errorMsg("/room_list")}
              label="Stanje soba"
              defaultValue={template.room_list}
              customOnChange={changeTemplateField("room_list")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/payment")}
              errorMsg={errorMsg("/payment")}
              label="Plaćanje"
              defaultValue={template.payment}
              customOnChange={changeTemplateField("payment")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/storno")}
              errorMsg={errorMsg("/storno")}
              label="Storno"
              defaultValue={template.storno}
              customOnChange={changeTemplateField("storno")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/comment_contigent")}
              errorMsg={errorMsg("/comment_contigent")}
              label="Kontigent"
              defaultValue={template.comment_contigent}
              customOnChange={changeTemplateField("comment_contigent")}
            />
            <br />
            <br />
            <CustomTextArea
              error={error("/document_date_validity_comment")}
              errorMsg={errorMsg("/document_date_validity_comment")}
              label="Datum opcije ponude"
              defaultValue={template.document_date_validity_comment}
              customOnChange={changeTemplateField("document_date_validity_comment")}
            />
            <br />
            <br />
            <Button
              color="primary"
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/administracija/predlozak/");
              }}
            >
              Poništi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="primary" onClick={submit}>
              Spremi
            </Button>
          </form>
        )}
      </FormValidator>
    );
  }
}

export default withRouter(TemplateForm);
