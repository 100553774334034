import gql from "graphql-tag";

import {
  mainTerminFragment,
  terminsFragment,
  bookingOCDocument,
  contigentFragment,
  bookingContigentFragment,
} from "../fragments";

export const listTerminPeriodStatus = gql`
  {
    listTerminPeriodStatus {
      id
      desc
    }
  }
`;

export const listBooking = gql`
  {
    listBooking {
      id
      external_id
      name
      created_by_user {
        id
        first_name
        last_name
      }
      contract_status_id
      contract_status {
        id
        desc
        code
      }
      booking_document {
        id
        termin_periods {
          ...MainTermin
          notification_booking_state_date
          termin_period_status_id
          termin_period_status {
            id
            desc
          }
          subtermin_periods {
            ...Termin
            termin_period_status_id
            termin_period_status {
              id
              desc
            }
            contigents {
              ...Contigent
            }
          }
          contigents {
            ...Contigent
          }
        }
      }
      offer_contract_document {
        ...Document
        contract {
          id
        }
      }
    }
  }
  ${terminsFragment}
  ${contigentFragment}
  ${mainTerminFragment}
  ${bookingOCDocument}
`;

export const listBookingAllData = gql`
  {
    listBookingOptionDescription {
      id
      desc
    }

    listDatagridFilter(datagrid_filter_type_id: 4, on_user: true) {
      #list for booking
      id
      desc
      column
      active
      datagrid_filter_type {
        desc
      }
    }

    listOfferContractDocumentSegment {
      id
      desc
      code
    }

    listTerminPeriodStatus {
      id
      desc
    }
  }
`;

export const listBookingQuery = gql`
  query($input: Condition) {
    listBooking(input: $input) {
      id
      external_id
      name
      count
      created_by_user {
        id
        first_name
        last_name
      }
      contract_status_id
      contract_status {
        id
        desc
        code
      }
      booking_document {
        id
        termin_periods {
          ...MainTermin
          notification_booking_state_date
          termin_period_status_id
          termin_period_status {
            id
            desc
          }
          subtermin_periods {
            ...Termin
            termin_period_status_id
            termin_period_status {
              id
              desc
            }
            contigents {
              ...BookingContigent
            }
          }
          contigents {
            ...BookingContigent
          }
        }
      }
      offer_contract_document {
        ...Document
        contract {
          id
        }
      }
    }
  }
  ${bookingOCDocument}
  ${terminsFragment}
  ${mainTerminFragment}
  ${bookingContigentFragment}
`;
export const listBookingByTerminPeriod = gql`
  query($input: Condition) {
    listBooking(input: $input) {
      id
      from
      to
      nr_of_buses
      driver
      guide
      contacted
      driver_guide_comment
      receipt
      remark
      transport
      def
      count
      notification_booking_state_date
      delivered_state
      termin_period_status_id
      termin_period_status {
        id
        desc
        code
      }
      booking_option_description_id
      booking_option_description {
        id
        code
        desc
      }
      price_structure {
        id
        calculator
        price_typology_1
        price_typology_2
        price_typology_3
        price_typology_4
        price_typology_5
        price_typology_6
        price_typology_7
        price_typology_8
        price_typology_9
        price_typology_10
        price_typology
        tourist_tax
        calculator
        comment_price_structure
        price_structure_type_id
        termin_period_id
      }
      booking_document {
        id
        offer_contract_document_id
        offer_contract_document {
          id
          document_code
          inquiry_id
          inquiry_external_id
          code1
          comment_term_period
          comment_establishment
          comment_contigent
          comment_service
          offer_contract_document_type_id
          offer_contract_document_segment_id
          offer_contract_document_segment {
            id
            desc
            code
          }
          partner_client {
            id
            name
            phones
            fax
            address
            comments
            cities {
              id
              name
              postal_code
            }
          }
          inquiry {
            id
            off_key
          }
          contract {
            id
            external_id
            name
            count
            created_by_user {
              id
              first_name
              last_name
            }
            contract_status_id
            contract_status {
              id
              desc
              code
            }
          }
        }
      }
      contigents {
        id
        typologies_sum
        sold
        termin_period_id
        typology_id
        typology {
          id
          code
          persons_capacity
          priority
          typology_group_id
          typology_group {
            id
            name
          }
          typology_translations {
            id
            desc
            name
            language_id
            typology_id
            language {
              id
              code
              desc
            }
          }
          establishment_id
          establishment {
            id
            name
            establishment_chain_id
            establishment_chain {
              id
              name
            }
            city {
              id
              name
              count
              postal_code
              area_code
              region_id
              region {
                id
                name
                country {
                  id
                  name
                  iso_3166_code
                }
              }
            }
            typologies {
              id
              code
              establishment_code
              persons_capacity
              priority
              typology_group_id
              typology_group {
                id
                name
              }
              establishment_id
              establishment {
                id
                name
                web
                email
                address1
                address2
                phone
                fax
                rating
                contact_person1
                contact_person2
                breakfast_and_dinner_times
                breakfast_and_dinner_buffet_or_menu
                currency_exchange
                currency_exchange_comment
                parking
                parking_comment
                wellnes
                wellnes_comment
                pools
                pools_comment
                city_id
                establishment_legal_entity_id
                establishment_chain_id
                city {
                  id
                  name
                  count
                  postal_code
                  area_code
                  region_id
                  region {
                    id
                    name
                    country {
                      id
                      name
                      iso_3166_code
                    }
                  }
                }
                address1
              }
            }
          }
        }
      }
      subtermin_periods {
        id
        from
        to
        driver
        guide
        contacted
        driver_guide_comment
        receipt
        remark
        transport
        def
        notification_booking_state_date
        delivered_state
        termin_period_status_id
        termin_period_status {
          id
          desc
          code
        }
        booking_option_description_id
        booking_option_description {
          id
          code
          desc
        }
        booking_document {
          id
          offer_contract_document_id
          offer_contract_document {
            id
            document_code
            inquiry_id
            inquiry_external_id
            code1
            comment_term_period
            comment_establishment
            comment_contigent
            comment_service
            offer_contract_document_type_id
            offer_contract_document_segment_id
            offer_contract_document_segment {
              id
              desc
              code
            }
            partner_client {
              id
              name
              phones
              fax
              address
              comments
              cities {
                id
                name
                postal_code
              }
            }
            contract {
              id
              external_id
              name
              count
              created_by_user {
                id
                first_name
                last_name
              }
              contract_status_id
              contract_status {
                id
                desc
                code
              }
            }
          }
        }
        contigents {
          id
          typologies_sum
          sold
          termin_period_id
          typology_id
          typology {
            id
            code
            persons_capacity
            priority
            typology_group_id
            typology_group {
              id
              name
            }
            typology_translations {
              id
              desc
              name
              language_id
              typology_id
              language {
                id
                code
                desc
              }
            }
            establishment_id
            establishment {
              id
              name
              establishment_chain_id
              establishment_chain {
                id
                name
              }
              city {
                id
                name
                count
                postal_code
                area_code
                region_id
                region {
                  id
                  name
                  country {
                    id
                    name
                    iso_3166_code
                  }
                }
              }
              typologies {
                id
                code
                establishment_code
                persons_capacity
                priority
                typology_group_id
                typology_group {
                  id
                  name
                }
                establishment_id
                establishment {
                  id
                  name
                  web
                  email
                  address1
                  address2
                  phone
                  fax
                  rating
                  contact_person1
                  contact_person2
                  breakfast_and_dinner_times
                  breakfast_and_dinner_buffet_or_menu
                  currency_exchange
                  currency_exchange_comment
                  parking
                  parking_comment
                  wellnes
                  wellnes_comment
                  pools
                  pools_comment
                  city_id
                  establishment_legal_entity_id
                  establishment_chain_id
                  city {
                    id
                    name
                    count
                    postal_code
                    area_code
                    region_id
                    region {
                      id
                      name
                      country {
                        id
                        name
                        iso_3166_code
                      }
                    }
                  }
                  address1
                }
              }
            }
          }
        }
      }
    }
  }
`;
