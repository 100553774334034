import React, { Component } from "react";
import { graphql } from "react-apollo";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import { terminsFragment } from "../../../../graphql/fragments";

import TextField from "@material-ui/core/TextField";

import toastr from "toastr";

class NumberOfBussesDrawer extends Component {
  state = {
    nr_of_buses: 0,
  };

  handleNumberOfBussesChange = () => {
    const { nr_of_buses } = this.state;

    if (nr_of_buses !== this.props.nr_of_buses) {
      try {
        this.props.mutate({
          variables: {
            id: this.props.id,
            patch: {
              nr_of_buses,
            },
          },
          update: (store, { data: { updateTerminPeriod } }) => {
            store.writeFragment({
              id: `Termin:${updateTerminPeriod.id}`,
              fragmentName: "Termin",
              fragment: terminsFragment,
              data: updateTerminPeriod,
            });
          },
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  componentDidMount = () => {
    const { nr_of_buses } = this.props;
    this.setState({ nr_of_buses });
  };

  render() {
    const { toggle, open } = this.props;

    return (
      <DrawerWrapper toggle={toggle} update={this.handleNumberOfBussesChange} open={open} title="Broj Buseva">
        <div className="form-control-grid">
          <TextField
            id="number"
            label="Broj buseva"
            value={this.state.nr_of_buses}
            onChange={event => {
              const { value } = event.target;
              // handle negative number input
              if (value >= 0) {
                return this.setState({ nr_of_buses: value });
              }
            }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </div>
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(NumberOfBussesDrawer);
