import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import purple from "@material-ui/core/colors/purple";

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    "&$cssFocused": {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: purple[500],
    },
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  borderError: { border: "1px solid red" },
});

/**
 * used for rendering textField multiline and with borders
 */
const TextFieldMultiline = ({
  classes,
  error,
  inputProps,
  defaultValue,
  value,
  helperText,
  margin,
  name,
  onChange,
  onBlur,
  rows,
  label,

  placeholder,
}) => {
  return (
    <div className={classes.container}>
      <TextField
        value={value || ""}
        margin={margin}
        name={name}
        onChange={onChange}
        error={error}
        multiline
        rows={rows || "4"}
        fullWidth={true}
        helperText={helperText}
        label={label}
        onBlur={onBlur}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.bootstrapRoot,
            input: `${classes.bootstrapInput} ${error ? classes.borderError : ""}`,
          },
          ...inputProps,
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.bootstrapFormLabel,
        }}
      />
    </div>
  );
};

export default withStyles(styles)(TextFieldMultiline);
