import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateStorno extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_v =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("notification_storno");
      }).length;

    const prev_v =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("notification_storno");
      }).length;

    const next_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("storno_content");
      }).length;

    const prev_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("storno_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.notification_storno !== this.props.notification_storno ||
      nextProps.storno_content !== this.props.storno_content ||
      next_content !== prev_content ||
      next_v !== prev_v
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`storno_content`).length) {
      autosize(document.getElementsByName(`storno_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Storno</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/notification_storno")}>
            <InputLabel htmlFor="notification_storno">Podsjetnik za storno</InputLabel>
            <Input
              id="notification_storno"
              className="template-input"
              inputProps={{
                name: "notification_storno",
              }}
              value={this.props.notification_storno}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/notification_storno")}</FormHelperText>
          </FormControl>
          <div>
            <TextArea
              error={error("/offer_contract_document/storno_content")}
              inputProps={{
                name: "storno_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.storno_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/storno_content")}>
              {errorMsg("/offer_contract_document/storno_content")}
            </FormHelperText>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TemplateStornoHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateStorno />
  </OfferForm.ConsumerHOC>
);

export default TemplateStornoHOC;
