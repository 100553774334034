import gql from "graphql-tag";

// fragments
import {
  hotelNoGuideAnnouncementFragment,
  hotelguideAnnouncementFragment,
  guideAnnouncementFragment,
  guideTourAnnouncementFragment,
  agencyAnnouncementFragment,
  agencyTourAnnouncementFragment,
  agencyLocalProgramAnnouncementFragment,
  agencyLocalAnnouncementFragment,
  supplierAnnouncementFragment,
  phraseAnnouncementFragment,
  supplierPartnerFragment,
} from "../announcementFragments";

/**
 * announcement
 */

// update announcement or voucher status
export const UPDATE_ANNOUNCEMENT_VOUCHER_STATUS = gql`
  mutation($input: UpdateAnnouncementVoucherStatusInput!) {
    updateAnnounecementVoucherStatus(input: $input) {
      ...SupplierPartner
    }
  }
  ${supplierPartnerFragment}
`;

// create hotel guide announcement
export const CREATE_HOTEL_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: CreateHotelGuideAnnouncementInput!) {
    createHotelGuideAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
      guide_id
      guide_first_name
      guide_last_name
    }
  }
`;

// create hotel guide announcement
export const CREATE_HOTEL_NO_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: CreateHotelNoGuideAnnouncementInput!) {
    createHotelNoGuideAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create guide announcement
export const CREATE_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: CreateGuideAnnouncementInput!) {
    createGuideAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create guide tour announcement
export const CREATE_GUIDE_TOUR_ANNOUNCEMENT = gql`
  mutation($input: CreateGuideAnnouncementInput!) {
    createGuideTourAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create guide local announcement
export const CREATE_GUIDE_LOCAL_ANNOUNCEMENT = gql`
  mutation($input: CreateGuideLocalAnnouncementInput!) {
    createGuideLocalAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create agency announcement
export const CREATE_AGENCY_ANNOUNCEMENT = gql`
  mutation($input: CreateAgencyAnnouncementInput!) {
    createAgencyAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create agency tour announcement
export const CREATE_AGENCY_TOUR_ANNOUNCEMENT = gql`
  mutation($input: CreateAgencyAnnouncementInput!) {
    createAgencyTourAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create agency local announcement
export const CREATE_AGENCY_LOCAL_ANNOUNCEMENT = gql`
  mutation($input: CreateAgencyAnnouncementInput!) {
    createAgencyLocalAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create agency local program announcement
export const CREATE_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT = gql`
  mutation($input: CreateAgencyAnnouncementInput!) {
    createAgencyLocalProgramAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create supplier announcement
export const CREATE_SUPPLIER_ANNOUNCEMENT = gql`
  mutation($input: CreateSupplierAnnouncementInput!) {
    createSupplierAnnouncement(input: $input) {
      id
      announcement_type
      language_id
      language_desc
    }
  }
`;

// create phrase announcement
export const CREATE_PHRASE_ANNOUNCEMENT = gql`
  mutation($input: CreateAnnouncementPhraseInput!) {
    createPhraseAnnouncement(input: $input) {
      id

      phrase_title {
        value
      }

      phrase_text {
        value
      }

      language_desc
    }
  }
`;

// update hotel no guide announcement
export const UPDATE_HOTEL_NO_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: UpdateHotelNoGuideAnnouncementInput!) {
    updateHotelNoGuideAnnouncement(input: $input) {
      ...HotelNoGuideAnnouncement
    }
  }
  ${hotelNoGuideAnnouncementFragment}
`;

// update hotel guide announcement
export const UPDATE_HOTEL_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: UpdateHotelGuideAnnouncementInput!) {
    updateHotelGuideAnnouncement(input: $input) {
      ...HotelGuideAnnouncement
    }
  }
  ${hotelguideAnnouncementFragment}
`;

// update guide announcement
export const UPDATE_GUIDE_ANNOUNCEMENT = gql`
  mutation($input: UpdateGuideAnnouncementInput!) {
    updateGuideAnnouncement(input: $input) {
      ...AnnouncementGuide
    }
  }
  ${guideAnnouncementFragment}
`;

// update guide tour announcement
export const UPDATE_GUIDE_TOUR_ANNOUNCEMENT = gql`
  mutation($input: UpdateGuideAnnouncementInput!) {
    updateGuideTourAnnouncement(input: $input) {
      ...AnnouncementGuide
    }
  }
  ${guideTourAnnouncementFragment}
`;

// update guide local announcement
export const UPDATE_GUIDE_LOCAL_ANNOUNCEMENT = gql`
  mutation($input: UpdateGuideLocalAnnouncementInput!) {
    updateGuideLocalAnnouncement(input: $input) {
      ...AnnouncementGuide
    }
  }
  ${guideTourAnnouncementFragment}
`;

// update agency announcement
export const UPDATE_AGENCY_ANNOUNCEMENT = gql`
  mutation($input: UpdateAgencyAnnouncementInput!) {
    updateAgencyAnnouncement(input: $input) {
      ...AnnouncementAgency
    }
  }
  ${agencyAnnouncementFragment}
`;

// update agency tour announcement
export const UPDATE_AGENCY_TOUR_ANNOUNCEMENT = gql`
  mutation($input: UpdateAgencyAnnouncementInput!) {
    updateAgencyTourAnnouncement(input: $input) {
      ...AnnouncementAgency
    }
  }
  ${agencyTourAnnouncementFragment}
`;

// update agency local announcement
export const UPDATE_AGENCY_LOCAL_ANNOUNCEMENT = gql`
  mutation($input: UpdateAgencyAnnouncementInput!) {
    updateAgencyLocalAnnouncement(input: $input) {
      ...AnnouncementAgency
    }
  }
  ${agencyLocalAnnouncementFragment}
`;

// update agency local program announcement
export const UPDATE_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT = gql`
  mutation($input: UpdateAgencyAnnouncementInput!) {
    updateAgencyLocalProgramAnnouncement(input: $input) {
      ...AnnouncementAgency
    }
  }
  ${agencyLocalProgramAnnouncementFragment}
`;

// update supplier announcement
export const UPDATE_SUPPLIER_ANNOUNCEMENT = gql`
  mutation($input: UpdateSupplierAnnouncementInput!) {
    updateSupplierAnnouncement(input: $input) {
      ...AnnouncementSupplier
    }
  }
  ${supplierAnnouncementFragment}
`;

// update phrase announcement
export const UPDATE_PHRASE_ANNOUNCEMENT = gql`
  mutation($input: UpdateAnnouncementPhraseInput!) {
    updatePhraseAnnouncement(input: $input) {
      ...AnnouncementPhrase
    }
  }
  ${phraseAnnouncementFragment}
`;
