import React, { Component, Fragment } from "react";
import OfferForm from "../OfferForm";
import { FormHelperText } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateProgram extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("program_content");
      }).length;

    const prev_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("program_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.program_content !== this.props.program_content ||
      next_content !== prev_content
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`program_content`).length) {
      autosize(document.getElementsByName(`program_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Sadržaj programa</label>
        </div>
        <div>
          <TextArea
            error={error("/offer_contract_document/program_content")}
            inputProps={{
              name: "program_content",
              disabled: isNotDraft,
            }}
            defaultValue={this.props.program_content}
            customOnChange={this.props.changeContent}
            rows="3"
          />
          <FormHelperText error={error("/offer_contract_document/program_content")}>
            {errorMsg("/offer_contract_document/program_content")}
          </FormHelperText>
        </div>
      </Fragment>
    );
  }
}

const TemplateProgramHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateProgram />
  </OfferForm.ConsumerHOC>
);

export default TemplateProgramHOC;
