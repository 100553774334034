import { useCallback, useEffect } from "react";

// libs
import { useQuery } from "react-apollo";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectAnnouncementDrawerOpen,
  setGuideScheduleAnnouncementDrawerOpen,
  setGuideScheduleConstants,
  setSupplierAnnouncements,
} from "src/views/Guides/guidesSlice";
import { setAnnouncementSelected } from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { listLanguage as LIST_LANGUAGE } from "src/graphql/query/administration";
import { setAnnouncementVouchers } from "../components/VoucherList/voucherSlice";

/**
 * useAnnouncementEdit is entry point of data for announcementDrawer
 */
export default function useAnnouncementEdit() {
  // redux state
  const isAnnouncementDrawerOpen = useSelector(selectAnnouncementDrawerOpen);

  const dispatch = useDispatch();

  // get languages types
  const { data: dataLanguages } = useQuery(LIST_LANGUAGE, {
    skip: !isAnnouncementDrawerOpen,
  });

  // save list language
  useEffect(() => {
    if (dataLanguages) {
      const listLanguageResponse = get(dataLanguages, "listLanguage");
      dispatch(
        setGuideScheduleConstants({
          listLanguage: listLanguageResponse,
        }),
      );
    }
  }, [dataLanguages, dispatch]);

  // handle on drawer close
  const handleOnDrawerClose = useCallback(() => {
    // set announcement drawer open
    dispatch(setGuideScheduleAnnouncementDrawerOpen(false));

    // set supplier announcements
    dispatch(setSupplierAnnouncements([]));

    // set announcement vouchers
    dispatch(setAnnouncementVouchers([]));

    // reset announcement selected
    dispatch(setAnnouncementSelected(null));
  }, [dispatch]);

  return { handleOnDrawerClose, isAnnouncementDrawerOpen };
}
