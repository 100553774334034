import React from "react";

// ui
import Tooltip from "src/components/UI/Tooltip";

// hook
import useLanguageColumn from "./hooks/useLanguageColumn";

/**
 * Language columns
 */
export default function LanguageColumn({ languageId }) {
  // hooks
  const { language } = useLanguageColumn(languageId);

  return (
    <Tooltip title={language.desc || " "}>
      <div className="ReactTableFirstColumnDiv">{language.desc || ""}</div>
    </Tooltip>
  );
}
