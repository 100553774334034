import React from "react";

// ui
import Grid from "@material-ui/core/Grid";

// hooks
import usePartner from "./hooks/usePartner";
// components
import AutocompleteSelect from "src/components/UI/AutocompleteSelect";
import ContactItem from "../ContactItem";
// constants
import { HINT_GUIDE_PARTNER_SELECT } from "src/utility/labels";

/**
 * used for rendering partner item in list
 */
export default function PartnerItem({ programDayId, programDayContent }) {
  const { getPartnerListToShow, loading, handleOnPartnerSearchClick, handleOnPartnerSelect } = usePartner(
    programDayContent.partner_client_id,
  );

  // return root
  return (
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <AutocompleteSelect
          autoBlur={true}
          key={programDayContent.program_day_content_id}
          defaultValue={programDayContent.partner_client_id || ""}
          placeholder={HINT_GUIDE_PARTNER_SELECT}
          isLoading={loading}
          dataSource={getPartnerListToShow().map(client => ({
            value: client.id,
            label: client.name,
          }))}
          autocompleteHandler={partnerClientId => {
            partnerClientId === -1
              ? handleOnPartnerSearchClick(programDayId, programDayContent.program_day_content_id)
              : handleOnPartnerSelect(partnerClientId, programDayId, programDayContent.program_day_content_id);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <ContactItem
          partnerClientId={programDayContent.partner_client_id}
          programDayId={programDayId}
          programDayContent={programDayContent}
        />
      </Grid>
    </Grid>
  );
}
