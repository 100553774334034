import { useCallback } from "react";

// libs
import { debounce, get } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideSchedules,
  selectProgramDayActivities,
  setGuideSchedules,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_PROGRAM_THEME_NAME } from "src/graphql/mutation/operative";

/**
 * useGuideComment is entry point of data for guideComment component
 */
export default function useSubject() {
  // redux state
  const listGuides = useSelector(selectGuideSchedules);
  const programDayActivities = useSelector(selectProgramDayActivities);

  const dispatch = useDispatch();

  // change program theme_name
  const [updateThemeName] = useMutation(UPDATE_PROGRAM_THEME_NAME);

  /**
   * handle on theme name change
   */
  const handleOnThemeNameUpdate = async themeName => {
    const updateProgramDayResponse = await updateThemeName({
      variables: {
        input: {
          offer_contract_document_id: programDayActivities.offer_contract_document_id,
          theme_name: themeName,
        },
      },
    });

    const programUpdate = get(updateProgramDayResponse, "data.updateThemeName");

    if (programUpdate) {
      toastr.success("Uspješno ažuriran predmet");

      // handle on program update
      const programDayActivitiesUpdate = {
        ...programDayActivities,
        theme_name: programUpdate.theme_name,
      };

      dispatch(setProgramDayActivities(programDayActivitiesUpdate));

      // update guide schedules
      const guideSchedulesUpdate = listGuides.map(guideSchedule => {
        if (guideSchedule.program_id === programDayActivities.program_id) {
          return {
            ...guideSchedule,
            theme_name: programUpdate.theme_name,
          };
        }
        return guideSchedule;
      });

      dispatch(setGuideSchedules(guideSchedulesUpdate));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  const delayedCallback = debounce(handleOnThemeNameUpdate, 1000);

  const onThemeNameChange = useCallback(
    event => {
      //setDayTitle(event.target.value);
      event.persist();

      const themeName = get(event, "target.value");

      delayedCallback(themeName);
    },
    [delayedCallback],
  );

  return {
    themeName: get(programDayActivities, "theme_name", null),
    onThemeNameChange,
  };
}
