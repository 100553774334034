import React, { Component } from "react";
import { graphql } from "react-apollo";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import debounce from "lodash/debounce";

import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";

import { listCities } from "../../../../graphql/query/administration";
import { cityInfo } from "../../../../utility/city";
import { FormHelperText } from "@material-ui/core";
import FormValidator from "../../../../components/UI/FormValidator";
import { removeDuplicateObjects } from "../../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";
import get from "lodash/get";

const form = {
  padding: "24px",
};

class EstablishmentChainForm extends Component {
  state = {
    city: this.props.establishmentChain.city,
    fetchingMoreCities: false,
  };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  handleAutocompleteInputChange = value => {
    let where = this.variables.input.where ? JSON.parse(this.variables.input.where) : null;

    // checking if previous fetch name and current value are empty
    // skip fetch if same requests
    if (where && (!where.name && !value)) {
      return;
    }

    where = this.variables.input.where
      ? JSON.stringify({
          ...JSON.parse(this.variables.input.where),
          name: value,
        })
      : JSON.stringify({
          name: value,
        });

    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: where,
      },
    };

    this.props.listCitiesQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listCity.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listCity: [...fetchMoreResult.listCity],
        };
      },
    });
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 500);

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listCity = get(this.props, "listCitiesQuery.listCity", []);

    if (this.fetchingResults === false && this.hasMoreItems && listCity && listCity.length >= PAGINATION_LIMIT) {
      this.setState({ fetchingMoreCities: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listCitiesQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listCity.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listCity: [...previousResult.listCity, ...fetchMoreResult.listCity],
            };
          },
        });

        this.setState({ fetchingMoreCities: false });
      });
    }
  };

  render() {
    const { title, establishmentChain, onChangeField, onSubmit, cancelToggle } = this.props;

    const listCity = get(this.props, "listCitiesQuery.listCity", []);
    const loadingCities = get(this.props, "listCitiesQuery.loading");

    if (loadingCities) {
      return <div>Loading cities . . . </div>;
    }

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    // Inserting picked city to top,
    // So it can be picked in init array  even if it's not fetched from first city list
    const citiesToShow =
      this.state.city && listCity && listCity.length
        ? removeDuplicateObjects([this.state.city, ...listCity]).map(city => ({
            value: city.id,
            label: cityInfo(city),
          }))
        : listCity.map(city => ({
            value: city.id,
            label: cityInfo(city),
          }));

    return (
      <FormValidator schema={EstablishmentChainForm.schema} submit={onSubmit} data={establishmentChain}>
        {({ error, errorMsg, submit }) => (
          <div style={form} className="establishment-chain-form">
            <h3>{title}</h3>
            <div className="form-control-grid">
              <FormControl fullWidth error={error("/name")}>
                <InputLabel shrink={true} htmlFor="name">
                  Ime smještajnog lanca
                </InputLabel>
                <Input
                  id="name"
                  name="name"
                  value={establishmentChain.name ? establishmentChain.name : ""}
                  onChange={({ target: { value } }) => onChangeField({ key: "name", value })}
                />
                {error("/name") && <FormHelperText>{errorMsg("/name")}</FormHelperText>}
              </FormControl>
            </div>
            <div className="form-control-grid">
              <AutocompleteSelect
                label="Grad"
                error={error("/city_id")}
                errorMsg={errorMsg("/city_id")}
                defaultValue={establishmentChain.city_id || ""}
                autocompleteHandler={value => {
                  this.setState({
                    city: listCity.find(city => city.id === value) || this.state.city,
                  });
                  return onChangeField({ key: "city_id", value });
                }}
                dataSource={citiesToShow}
                onMenuScrollToBottom={this.handleScroller}
                isLoading={this.state.fetchingMoreCities}
                placeholder="Odabir grada"
                inputProps={{
                  onInputChange: this.handleAutocompleteInputChangeDebounced,
                }}
              />
            </div>
            <br />
            <Button variant="contained" onClick={submit}>
              Spremi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" onClick={cancelToggle}>
              Poništi
            </Button>
          </div>
        )}
      </FormValidator>
    );
  }
}

EstablishmentChainForm.schema = {
  properties: {
    name: {
      type: "string",
      minLength: 1,
      isNotEmpty: true,
      errorMessage: "Ime pravnog objekta ne smije biti prazno",
    },
    city_id: {
      type: "integer",
      minimum: 1,
      errorMessage: "Potrebno je izabrati grad",
    },
  },
};

export default graphql(listCities, {
  name: "listCitiesQuery",
  options: {
    fetchPolicy: "network-only",
    variables: {
      input: {
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
      },
    },
  },
})(EstablishmentChainForm);
