import React, { Fragment, Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { NavLink } from "react-router-dom";

import { listTemplateTranslation, listLanguage } from "../../../graphql/query/administration";
import { AdministrationTabs } from "../AdministrationTabs";
import ReactTableCustom from "../../../components/UI/ReactTable";
import EditIcon from "../../../components/UI/EditIcon";
import Tooltip from "./../../../components/UI/Tooltip";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class TemplateList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: template => (
          <div className="ReactTableFirstColumnDiv">
            <NavLink to={`/administracija/predlozak/edit/${template.id}`} className="ReactTableFirstColumnDiv">
              <EditIcon />
            </NavLink>
          </div>
        ),
      },
      {
        Header: "Jezik",
        accessor: ({ language_id }) => {
          const { listLanguage } = this.props.listLanguage;
          if (!listLanguage) {
            return "";
          }

          const language = listLanguage.find(({ id }) => id === language_id);

          return (
            <Tooltip title={language.desc || " "}>
              <div className="ReactTableFirstColumnDiv">{language.desc || ""}</div>
            </Tooltip>
          );
        },
        id: "language",
      },
      {
        Header: "PDV",
        id: "vat",
        accessor: ({ vat }) => (
          <Tooltip title={vat || " "}>
            <div className="ReactTableFirstColumnDiv">{vat || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Boravišna pristrojba",
        id: "sojourn_tax",
        accessor: ({ sojourn_tax }) => (
          <Tooltip title={sojourn_tax || " "}>
            <div className="ReactTableFirstColumnDiv">{sojourn_tax || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Sl. mjesto",
        id: "free_place",
        accessor: ({ free_place }) => (
          <Tooltip title={free_place || " "}>
            <div className="ReactTableFirstColumnDiv">{free_place || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Minimalni broj osoba",
        id: "price_validity",
        accessor: ({ price_validity }) => (
          <Tooltip title={price_validity || " "}>
            <div className="ReactTableFirstColumnDiv">{price_validity || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Dodatno",
        id: "surcharges",
        accessor: ({ surcharges }) => (
          <Tooltip title={surcharges || " "}>
            <div className="ReactTableFirstColumnDiv">{surcharges || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Izmjene programa",
        id: "program_changes",
        accessor: ({ program_changes }) => (
          <Tooltip title={program_changes || " "}>
            <div className="ReactTableFirstColumnDiv">{program_changes || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Fotografije",
        id: "content",
        accessor: ({ content }) => (
          <Tooltip title={content || " "}>
            <div className="ReactTableFirstColumnDiv">{content || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Sadržaj programa",
        id: "program",
        accessor: ({ program }) => (
          <Tooltip title={program || " "}>
            <div className="ReactTableFirstColumnDiv">{program || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Stanje rezervacije",
        id: "booking_state",
        accessor: ({ booking_state }) => (
          <Tooltip title={booking_state || " "}>
            <div className="ReactTableFirstColumnDiv">{booking_state || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Stanje soba",
        id: "room_list",
        accessor: ({ room_list }) => (
          <Tooltip title={room_list || " "}>
            <div className="ReactTableFirstColumnDiv">{room_list || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Plaćanje",
        id: "payment",
        accessor: ({ payment }) => (
          <Tooltip title={payment || " "}>
            <div className="ReactTableFirstColumnDiv">{payment || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Storno",
        id: "storno",
        accessor: ({ storno }) => (
          <Tooltip title={storno || " "}>
            <div className="ReactTableFirstColumnDiv">{storno || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Kontigent",
        id: "comment_contigent",
        accessor: ({ comment_contigent }) => (
          <Tooltip title={comment_contigent || " "}>
            <div className="ReactTableFirstColumnDiv">{comment_contigent || ""}</div>
          </Tooltip>
        ),
      },
      {
        Header: "Datum opcije ponude",
        id: "document_date_validity_comment",
        accessor: ({ document_date_validity_comment }) => (
          <Tooltip title={document_date_validity_comment || " "}>
            <div className="ReactTableFirstColumnDiv">{document_date_validity_comment || ""}</div>
          </Tooltip>
        ),
      },
    ],
  };

  render() {
    const {
      data: { loading, listTemplateTranslation },
    } = this.props;

    if (loading) {
      return <div> Loading . . . </div>;
    }

    const { listLanguage } = this.props.listLanguage;
    if (!listLanguage) {
      return "";
    }

    return (
      <Fragment>
        <TableTitle title="Lista predložaka" />

        <ReactTableCustom
          data={listTemplateTranslation || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  AdministrationTabs(
    compose(
      graphql(listTemplateTranslation, {
        options: {
          fetchPolicy: "network-only",
        },
      }),
      graphql(listLanguage, {
        name: "listLanguage",
        options: {
          fetchPolicy: "network-only",
        },
      }),
    )(TemplateList),
    "Predlošci",
  ),
);
