// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawers, setGuideDrawers, setProgramDayActivities } from "src/views/Guides/guidesSlice";
// constants
import { GUIDE_DRAWER_PROGRAM_DAYS } from "src/utility/constants";

/**
 * useTripActivities is entry point of data for tripActivitiesDrawer
 */
export default function useTripActivities() {
  // redux state
  const guideDrawers = useSelector(selectGuideScheduleDrawers);

  const dispatch = useDispatch();

  // on close drawer handle function
  const onCloseDrawer = () => {
    dispatch(setProgramDayActivities(null));
    dispatch(
      setGuideDrawers({
        drawerType: GUIDE_DRAWER_PROGRAM_DAYS,
        isOpen: false,
      }),
    );
  };

  return {
    isDrawerOpen: guideDrawers[GUIDE_DRAWER_PROGRAM_DAYS],
    onCloseDrawer,
  };
}
