import { ApolloLink } from "apollo-link";
import toastr from "toastr";
import { wsLink } from "../apollo";

export const getTokenMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      Authorization: localStorage.getItem("token") || null
    }
  }));

  return forward(operation);
});

export const setTokenAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map(res => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    const token = headers.get("authorization");

    if (token) {
      localStorage.setItem("token", token);
      wsLink.subscriptionClient.tryReconnect();
      toastr.info(
        "Promijenjene su role korisnika, osvježite stranicu kako bi vidjeli nove izmjene",
        "Promjena role",
        {
          positionClass: "toast-top-full-width",
          showDuration: "3000",
          hideDuration: "10000",
          timeOut: "50000",
          closeButton: true
        }
      );
    }

    return res;
  });
});
