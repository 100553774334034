import React, { Component } from "react";

import EstablishmentList from "./Establishment/EstablishmentList";
import EstablishmentChainList from "./EstablishmentChain/EstablishmentChainList";
import EstablishmentLegalEntityList from "./EstablishmentLegalEntity/EstablishmentLegalEntityList";
import CreateEstablishment from "./Establishment/CreateEstablishment";
import EditEstablishment from "./Establishment/EditEstablishment";
import CreateEstablishmentChain from "./EstablishmentChain/CreateEstablishmentChain";
import EditEstablishmentChain from "./EstablishmentChain/EditEstablishmentChain";
import CreateEstablishmentLegalEntity from "./EstablishmentLegalEntity/CreateEstablishmentLegalEntity";
import EditEstablishmentLegalEntity from "./EstablishmentLegalEntity/EditEstablishmentLegalEntity";

const EstablishmentTabsHOC = WrappedComponent => {
  return class extends Component {
    state = {
      establishmentCreate: false,
      establishmentEdit: false,
      establishment: "",
      establishmentChainCreate: false,
      establishmentChainEdit: false,
      establishmentChain: "",
      establishmentLegalEntityCreate: false,
      establishmentLegalEntityEdit: false,
      establishmentLegalEntity: "",
    };

    establishmentCreateToggle = () => {
      this.setState(prevState => {
        return {
          establishmentCreate: !prevState.establishmentCreate,
        };
      });
    };

    establishmentEditOpen = establishment => {
      this.setState({
        establishment,
        establishmentEdit: true,
      });
    };

    establishmentEditClose = () => {
      this.setState({
        establishment: "",
        establishmentEdit: false,
      });
    };

    establishmentChainCreateToggle = () => {
      this.setState(prevState => {
        return {
          establishmentChainCreate: !prevState.establishmentChainCreate,
        };
      });
    };

    establishmentChainEditOpen = establishmentChain => {
      this.setState({
        establishmentChain,
        establishmentChainEdit: true,
      });
    };

    establishmentChainEditClose = () => {
      this.setState({
        establishmentChain: "",
        establishmentChainEdit: false,
      });
    };

    establishmentLegalEntityCreateToggle = () => {
      this.setState(prevState => {
        return {
          establishmentLegalEntityCreate: !prevState.establishmentLegalEntityCreate,
        };
      });
    };

    establishmentLegalEntityEditOpen = establishmentLegalEntity => {
      this.setState({
        establishmentLegalEntity,
        establishmentLegalEntityEdit: true,
      });
    };

    establishmentLegalEntityEditClose = () => {
      this.setState({
        establishmentLegalEntity: "",
        establishmentLegalEntityEdit: false,
      });
    };

    showInTabEstablishment = () => {
      const { establishmentCreate, establishmentEdit, establishment } = this.state;

      if (establishmentCreate) {
        return <CreateEstablishment cancelToggle={this.establishmentCreateToggle} />;
      }
      if (establishmentEdit) {
        return (
          <EditEstablishment
            closeEdit={this.establishmentEditClose}
            establishment_id={establishment.id}
            establishment={establishment.id}
          />
        );
      }
      return (
        <EstablishmentList
          tableName={"administrationEstablishmentTable"}
          toggleCreate={this.establishmentCreateToggle}
          openEdit={this.establishmentEditOpen}
        />
      );
    };

    showInTabEstablishmentChain = listEstablishmentChain => {
      const { establishmentChainCreate, establishmentChainEdit, establishmentChain } = this.state;

      if (establishmentChainCreate) {
        return <CreateEstablishmentChain cancelToggle={this.establishmentChainCreateToggle} />;
      }
      if (establishmentChainEdit) {
        return (
          <EditEstablishmentChain
            establishmentChain={establishmentChain}
            closeEdit={this.establishmentChainEditClose}
          />
        );
      }
      return (
        <EstablishmentChainList
          tableName={"administrationEstablishmentChainTable"}
          toggleCreate={this.establishmentChainCreateToggle}
          openEdit={this.establishmentChainEditOpen}
          listEstablishmentChain={listEstablishmentChain}
        />
      );
    };

    showInTabEstablishmentLegalEntity = listEstablishmentLegalEntity => {
      const { establishmentLegalEntityCreate, establishmentLegalEntityEdit, establishmentLegalEntity } = this.state;

      if (establishmentLegalEntityCreate) {
        return <CreateEstablishmentLegalEntity cancelToggle={this.establishmentLegalEntityCreateToggle} />;
      }
      if (establishmentLegalEntityEdit) {
        return (
          <EditEstablishmentLegalEntity
            establishmentLegalEntity={establishmentLegalEntity}
            closeEdit={this.establishmentLegalEntityEditClose}
          />
        );
      }
      return (
        <EstablishmentLegalEntityList
          tableName={"administrationLegalEntityTable"}
          toggleCreate={this.establishmentLegalEntityCreateToggle}
          openEdit={this.establishmentLegalEntityEditOpen}
          listEstablishmentLegalEntity={listEstablishmentLegalEntity}
        />
      );
    };

    render() {
      return (
        <WrappedComponent
          showInTabEstablishment={this.showInTabEstablishment}
          showInTabEstablishmentChain={this.showInTabEstablishmentChain}
          showInTabEstablishmentLegalEntity={this.showInTabEstablishmentLegalEntity}
        />
      );
    }
  };
};

export default EstablishmentTabsHOC;
