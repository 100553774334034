import React from "react";

// ui
import { TextField, MenuItem, Grid } from "@material-ui/core";
// hooks
import useGroupStatusContainer from "./hooks/useGroupStatusContainer";

/**
 * GuideStatus is used for handling guide status
 */
export default function GroupStatusContainer() {
  const { groupOperativeStatusTypes, groupStatus, onGroupStatusChange } = useGroupStatusContainer();

  // return root
  return (
    <Grid container>
      <Grid item xs={8}>
        <TextField
          select
          fullWidth
          label="Status"
          value={groupStatus || ""}
          onChange={event => onGroupStatusChange(event.target.value)}
        >
          {groupOperativeStatusTypes
            ? groupOperativeStatusTypes.map(operativeStatusType => (
                <MenuItem key={`key-${operativeStatusType.name}`} value={operativeStatusType.description}>
                  {operativeStatusType.description}
                </MenuItem>
              ))
            : null}
        </TextField>
      </Grid>
    </Grid>
  );
}
