import { combineReducers } from "@reduxjs/toolkit";
import offer from "./offer/offerReducer";
import contract from "./contract/contractReducer";
import booking from "./booking/bookingReducer";
import administration from "./administration/administrationReducer";
import alotman from "./alotman/alotmanReducer";

import user from "./user/userReducer";
import preview from "./preview/previewReducer";

// slices
import announcementTemplateReducer from "src/views/Administration/TemplateAnnouncement/templateAnnouncementSlice";
import guideScheduleReducer from "src/views/Guides/guidesSlice";
import announcementeEditReducer from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";
import announcementReducer from "src/views/Announcements/announcementSlice";
import voucherReducer from "src/views/Guides/Container/components/Drawers/components/Announcement/components/AnnouncementEdit/components/VoucherList/voucherSlice";
import announcementOrdersReducer from "src/views/AnnouncementOrders/announcementOrdersSlice";

/**
 * CombineReducers is a helper function that combines our images and videos reducers into a single reducer function that we can now pass to the creatorStore function.
 */
const rootReducer = combineReducers({
  offer,
  contract,
  booking,
  administration,
  user,
  preview,
  alotman,

  announcementTemplate: announcementTemplateReducer,
  guideSchedule: guideScheduleReducer,
  announcementEdit: announcementeEditReducer,
  announcement: announcementReducer,
  voucher: voucherReducer,
  announcementOrder: announcementOrdersReducer,
});

export default rootReducer;
