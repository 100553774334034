import React, { Component } from "react";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import CircularProgress from "material-ui/CircularProgress";

import ContractForm from "../ContractForm/ContractForm";

// GRAPHQL
import { fetchContract } from "../../../graphql/query/contract";

import { connect } from "react-redux";

import { documentAttachmentUpload, deleteDocumentFile } from "../../../graphql/mutation/offers";
import toastr from "toastr";

import * as actions from "../../../store/actions";
import { updateContract } from "../../../graphql/mutation/contract";

class EditContract extends Component {
  state = {
    loading: true,
    error: "",
    selectedFiles: "",
  };

  onDrop = files => {
    this.setState(
      {
        selectedFiles: files,
      },
      () => this.uploadAttachments(),
    );
  };

  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      const response = await this.props.client.query({
        query: fetchContract,
        variables: { id: this.props.match.params.id },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        const contract = response.data.getContract;

        if (!contract) {
          return this.setState({ error: "Nije pronađen ugovor!" });
        }

        if (contract) {
          this.setState({
            loading: false,
          });
        }

        this.props.onSetOfferToEdit(contract);
      }
    } catch (error) {}
  }

  uploadAttachments = async () => {
    if (this.state.selectedFiles) {
      const { selectedFiles } = this.state;

      if (selectedFiles && selectedFiles.length) {
        try {
          selectedFiles.forEach(async file => {
            const { offer_contract_document } = this.props.offer;

            const res = await this.props.uploadDocument({
              variables: {
                offer_contract_document_id: offer_contract_document.id,
                file,
              },
            });

            const { documentAttachmentUpload } = res.data;

            this.props.addAttachment(documentAttachmentUpload);
          });
          this.setState({ selectedFiles: "" });

          toastr.success("Datoteka uspješno uploadana");
        } catch (error) {}
      }
    }
  };

  deleteUploadedFile = async file_id => {
    try {
      await this.props.deleteDocumentFile({
        variables: {
          file_id,
        },
      });

      this.props.removeAttachment(file_id);
      toastr.success("Datoteka uspješno izbrisana");
    } catch (error) {}
  };

  update = async () => {
    try {
      const { id } = this.props.match.params;
      const {
        contract_status_id,
        storno_comment,
        external_id,
        offer_contract_document: { release_day },
      } = this.props.offer;

      await this.props.mutate({
        variables: {
          id,
          patch: {
            contract_status_id,
            storno_comment,
            release_day,
            external_id,
          },
        },
      });

      toastr.success(`Uspješno ažuriran ugovor ${id}`);

      this.props.history.push("/ugovor");
    } catch (error) {}
  };

  render() {
    const { loading, error } = this.state;

    if (error) {
      return (
        <div>
          {error}
          <a href=":;" onClick={this.props.history.goBack} style={{ color: "#5C6BC0", cursor: "pointer" }}>
            Vrati se natrag
          </a>
        </div>
      );
    }

    if (loading || !this.props.offer.id) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <ContractForm
          title={`Ažuriranje ugovora ${this.props.match.params.id}`}
          onSave={this.update}
          onDrop={this.onDrop}
          files={this.state.selectedFiles}
          deleteUploadedFile={this.deleteUploadedFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    offer: state.offer.offer,
    program_to_send: state.offer.program_to_send,
    program_days: state.offer.program_days,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetOfferToEdit: obj => dispatch(actions.setOfferToEdit(obj)),
    addAttachment: attachment => dispatch(actions.addAttachment(attachment)),
    removeAttachment: id => dispatch(actions.removeAttachment(id)),
  };
};

export default compose(
  graphql(documentAttachmentUpload, { name: "uploadDocument" }),
  graphql(deleteDocumentFile, { name: "deleteDocumentFile" }),
  graphql(updateContract),
)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withApollo(EditContract)),
);
