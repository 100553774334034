import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose, get } from "lodash";
import toastr from "toastr";
import PartnerClientForm from "./PartnerClientForm";
import { guidIdGenerator } from "../../../../utility/numbers";
import { listLanguage } from "../../../../graphql/query/administration";
import { createPartnerClient } from "../../../../graphql/mutation/administration";
import { prepareForSending } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

const form = {
  padding: "24px",
};

class CreatePartnerClient extends Component {
  state = {
    partner_client: {
      name: "",
      first_name: "",
      last_name: "",
      fax: "",
      address: "",
      url: "",
      oib: "",
      city_id: "",
      language_id: "",
      languages: "",
      partner_client_type_ids: [],
      partner_client_activities: [],
      contacts: [],
      services: [],
    },
    contact_data: {
      name: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      partner_client_id: "",
    },
    service_data: {
      price: "0",
      partner_client_service_type_id: "",
    },
  };

  changePartnerClientField = obj => {
    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          [obj.key]: obj.value,
        },
      };
    });
  };

  /**
   * add partner activity
   */
  addPartnerActivity = activity => {
    const {
      partner_client: { partner_client_activities },
    } = this.state;

    // return if partner already exists in list
    if (partner_client_activities.some(partnerActivity => partnerActivity.activity_id === activity.id)) {
      return;
    }

    const activitiesClone = [...partner_client_activities];
    // get name of activity
    const activityTranslation = get(activity, "activity_translations", []).find(
      translation => translation.language_id === "1",
    );

    const activityName = get(activityTranslation, "name", "");
    activitiesClone.push({
      activity_id: activity.id,
      name: activityName,
    });

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
        },
      };
    });
  };

  /**
   * delete partner activity
   */
  deletePartnerActivity = activity => {
    const {
      partner_client: { partner_client_activities },
    } = this.state;

    // handle list for view
    const activitiesClone = partner_client_activities.filter(
      partnerActivity => partnerActivity.activity_id !== activity.activity_id,
    );

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
        },
      };
    });
  };

  /**
   * update partner activity price
   */
  updatePartnerActivityPrice = (activityId, price) => {
    const {
      partner_client: { partner_client_activities },
    } = this.state;

    const activitiesClone = partner_client_activities.map(partnerActivity => {
      if (partnerActivity.activity_id === activityId) {
        return {
          ...partnerActivity,
          price: price,
        };
      } else {
        return partnerActivity;
      }
    });

    this.setState(prevState => {
      return {
        partner_client: {
          ...prevState.partner_client,
          partner_client_activities: activitiesClone,
        },
      };
    });
  };

  editedPartnerContact = contact => {
    const {
      partner_client,
      partner_client: { contacts },
    } = this.state;

    const contacts_clone = contacts.map(cont => {
      if (cont.id === contact.id) {
        return contact;
      }
      return cont;
    });

    this.setState({
      partner_client: {
        ...partner_client,
        contacts: contacts_clone,
      },
    });
  };

  changeCreateContact = obj =>
    this.setState(prevState => {
      return {
        contact_data: {
          ...prevState.contact_data,
          [obj.key]: obj.value,
        },
      };
    });

  addContact = () => {
    const {
      partner_client,
      partner_client: { contacts },
      contact_data,
    } = this.state;

    const contacts_clone = [...contacts];

    contacts_clone.push({ ...contact_data, id: guidIdGenerator() });

    this.setState({
      partner_client: {
        ...partner_client,
        contacts: contacts_clone,
      },
      contact_data: {
        name: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        partner_client_id: "",
      },
    });
  };

  removeContact = contact_id => {
    const {
      partner_client,
      partner_client: { contacts },
    } = this.state;

    const contact_clone = contacts.filter(contact => contact.id !== contact_id);

    this.setState({
      partner_client: {
        ...partner_client,
        contacts: contact_clone,
      },
    });
  };

  /**
   * Service
   */
  editedPartnerService = service => {
    const {
      partner_client,
      partner_client: { services },
    } = this.state;

    const services_clone = services.map(ser => {
      if (ser.id === service.id) {
        return service;
      }
      return ser;
    });

    this.setState({
      partner_client: {
        ...partner_client,
        services: services_clone,
      },
    });
  };

  setupCreateServiceData = partner_client_service_translations =>
    this.setState(prevState => {
      return {
        service_data: {
          ...prevState.service_data,
          partner_client_service_translations,
        },
      };
    });

  changeCreateService = obj =>
    this.setState(prevState => {
      return {
        service_data: {
          ...prevState.service_data,
          [obj.key]: obj.value,
        },
      };
    });

  changeCreateServiceTranslation = (obj, language_id) =>
    this.setState(prevState => {
      const translations = prevState.service_data.partner_client_service_translations.map(translation => {
        if (translation.language_id === language_id) {
          return {
            ...translation,
            [obj.key]: obj.value,
          };
        }
        return translation;
      });

      return {
        service_data: {
          ...prevState.service_data,
          partner_client_service_translations: translations,
        },
      };
    });

  addService = () => {
    const {
      partner_client,
      partner_client: { services },
      service_data,
    } = this.state;

    const services_clone = [...services];

    services_clone.push({ ...service_data, id: guidIdGenerator() });
    const translations_copy = [...service_data.partner_client_service_translations];

    this.setState({
      partner_client: {
        ...partner_client,
        services: services_clone,
      },
      service_data: {
        price: "",
        partner_client_service_type_id: "",
        partner_client_service_translations: translations_copy.map(translation => ({
          ...translation,
          name: "",
          description: "",
          partner_client_service_id: "",
        })),
      },
    });
  };

  removeService = service_id => {
    const {
      partner_client,
      partner_client: { services },
    } = this.state;

    const services_clone = services.filter(service => service.id !== service_id);

    this.setState({
      partner_client: {
        ...partner_client,
        services: services_clone,
      },
    });
  };

  addPartnerClientType = id => {
    const {
      partner_client,
      partner_client: { partner_client_type_ids },
    } = this.state;

    if (partner_client_type_ids.includes(id)) {
      return;
    }

    const type_clone = [...partner_client_type_ids];
    type_clone.push(id);

    this.setState({
      partner_client: {
        ...partner_client,
        partner_client_type_ids: type_clone,
      },
    });
  };

  removePartnerClientType = partner_type_id => {
    const {
      partner_client,
      partner_client: { partner_client_type_ids },
    } = this.state;

    const type_clone = partner_client_type_ids.filter(id => id !== partner_type_id);

    this.setState({
      partner_client: {
        ...partner_client,
        partner_client_type_ids: type_clone,
      },
    });
  };

  submit = async () => {
    try {
      // prepare partner client activities
      const partnerClientActivities = this.state.partner_client.partner_client_activities.map(partnerActivity => {
        return { activity_id: partnerActivity.activity_id, price: partnerActivity.price };
      });

      await this.props.mutate({
        variables: {
          ...prepareForSending(this.state.partner_client, ["id", "partner_client_activities"]),
          partner_client_activities: partnerClientActivities,
        },
      });

      toastr.success("Partner kreiran");
      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    const {
      data: { loading, listLanguage },
    } = this.props;

    if (loading) {
      return <div>Dohvaćam jezike . . .</div>;
    }

    return (
      <Fragment>
        <TableTitle title="Kreiranje partnera" onBackPressed={this.props.cancelToggle} />
        <div style={form}>
          <PartnerClientForm
            partner_client={this.state.partner_client}
            onChangePartnerField={this.changePartnerClientField}
            contact_data={this.state.contact_data}
            service_data={this.state.service_data}
            addPartnerActivity={this.addPartnerActivity}
            deletePartnerActivity={this.deletePartnerActivity}
            updatePartnerActivityPrice={this.updatePartnerActivityPrice}
            addContact={this.addContact}
            removeContact={this.removeContact}
            editedPartnerContact={this.editedPartnerContact}
            editedPartnerService={this.editedPartnerService}
            removeService={this.removeService}
            changeCreateContact={this.changeCreateContact}
            listLanguage={listLanguage}
            setupCreateServiceData={this.setupCreateServiceData}
            changeCreateServiceTranslation={this.changeCreateServiceTranslation}
            changeCreateService={this.changeCreateService}
            addService={this.addService}
            addPartnerClientType={this.addPartnerClientType}
            removePartnerClientType={this.removePartnerClientType}
            save={this.submit}
            cancelToggle={this.props.cancelToggle}
            submitTitle="Kreiraj"
          />
        </div>
      </Fragment>
    );
  }
}

export default compose(graphql(listLanguage), graphql(createPartnerClient))(CreatePartnerClient);
