import React from "react";

// ui
import { Grid, Typography, withStyles } from "@material-ui/core";

// style
const styles = () => ({
  container: {
    marginTop: 16,
  },

  labelItem: {
    minWidth: 80,
  },

  textField: {
    fontWeight: "bold",
  },
});

/**
 * ShowField
 */
const ShowField = ({ classes, label, textValue }) => {
  // return root
  return (
    <div className={classes.container}>
      <Grid container spacing={8}>
        <Grid className={classes.labelItem} item>
          <Typography variant="body1" className={classes.textField}>
            {`${label}: `}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" className={classes.textField}>
            {textValue}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ShowField);
