import { useState } from "react";

// libs
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectIsSelectTypeOpen,
  selectSupplierPartnerGuides,
  selectLanguages,
  setSupplierAnnouncements,
  selectSupplier,
  selectSupplierAnnouncements,
  setGuideScheduleSelectTypeOpen,
} from "src/views/Guides/guidesSlice";

import { setAnnouncementSelected } from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// hooks
// hotel announcements
import useCreateHotelGuideAnnouncement from "./useCreateHotelGuideAnnouncement";
import useCreateHotelNoGuideAnnouncement from "./useCreateHotelNoGuideAnnouncement";
// guide announcements
import useCreateGuideAnnouncement from "./useCreateGuideAnnouncement";
import useCreateGuideTourAnnouncement from "./useCreateGuideTourAnnouncement";
import useCreateGuideLocalAnnouncement from "./useCreateGuideLocalAnnouncement";
// agency announcements
import useCreateAgencyAnnouncement from "./useCreateAgencyAnnouncement";
import useCreateAgencyTourAnnouncement from "./useCreateAgencyTourAnnouncement";
import useCreateAgencyLocalAnnouncement from "./useCreateAgencyLocalAnnouncement";
import useCreateAgencyLocalProgramAnnouncement from "./useCreateAgencyLocalProgramAnnouncement";

import useCreateSupplierAnnouncement from "./useCreateSupplierAnnouncement";
import useCreatePhraseAnnouncement from "./useCreatePhraseAnnouncement";

// constants
import {
  HOTEL_GUIDE_ANNOUNCEMENT,
  HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GUIDE_ANNOUNCEMENT,
  GUIDE_TOUR_ANNOUNCEMENT,
  SUPPLIER_ANNOUNCEMENT,
  AGENCY_ANNOUNCEMENT,
  AGENCY_TOUR_ANNOUNCEMENT,
  AGENCY_LOCAL_ANNOUNCEMENT,
  AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  PHRASE_ANNOUNCEMENT,
  GUIDE_LOCAL_ANNOUNCEMENT,
} from "src/utility/constants";

/**
 * useTypeSelect is entry point of data for announcement type select component
 */
export default function useTypeSelect() {
  // redux state
  const supplierPartnerGuides = useSelector(selectSupplierPartnerGuides);
  const isSelectTypeOpen = useSelector(selectIsSelectTypeOpen);
  const listLanguage = useSelector(selectLanguages);
  const supplier = useSelector(selectSupplier);
  const supplierAnnouncements = useSelector(selectSupplierAnnouncements);

  const dispatch = useDispatch();

  // component state
  const [announcementTypeSelected, setAnnouncementTypeSelected] = useState();
  const [languageSelected, setLanguageSelected] = useState();
  const [guideSelected, setGuideSelected] = useState({});

  // hooks
  // hotel announcements
  const { handleCreateHotelGuideAnnouncement } = useCreateHotelGuideAnnouncement();
  const { handleCreateHotelNoGuideAnnouncement } = useCreateHotelNoGuideAnnouncement();
  // guide announcements
  const { handleCreateGuideAnnouncement } = useCreateGuideAnnouncement();
  const { handleCreateGuideTourAnnouncement } = useCreateGuideTourAnnouncement();
  const { handleCreateGuideLocalAnnouncement } = useCreateGuideLocalAnnouncement();
  // agency announcements
  const { handleCreateAgencyAnnouncement } = useCreateAgencyAnnouncement();
  const { handleCreateAgencyTourAnnouncement } = useCreateAgencyTourAnnouncement();
  const { handleCreateAgencyLocalAnnouncement } = useCreateAgencyLocalAnnouncement();
  const { handleCreateAgencyLocalProgramAnnouncement } = useCreateAgencyLocalProgramAnnouncement();
  const { handleCreateSupplierAnnouncement } = useCreateSupplierAnnouncement();
  const { handleCreatePhraseAnnouncement } = useCreatePhraseAnnouncement();

  /**
   * handle on type select dialog close
   */

  const handleOnDialogClose = () => {
    // set select type open in state
    dispatch(setGuideScheduleSelectTypeOpen(false));

    // selet component state
    setAnnouncementTypeSelected(null);
    setLanguageSelected(null);
    setGuideSelected(null);
  };

  /**
   * handle on announcement type select
   */
  const onAnnouncementTypeSelect = announcementType => {
    setAnnouncementTypeSelected(announcementType);
    setLanguageSelected(null);
    setGuideSelected(null);
  };

  /**
   * handle on guide select
   */
  const onGuideSelect = guideId => {
    setGuideSelected(guideId);
    setLanguageSelected(null);
  };

  /**
   * check for possible combination for hotelGuideAnnouncement
   * look for possible announcement with selected language and guide
   */
  const announcementHotelGuideTypeDisabled = () => {
    // check for null listLanguage
    if (!listLanguage) {
      return false;
    }

    // filter hotel guide announcements
    const hotelGuideAnnouncements = supplierAnnouncements
      .filter(announcement => announcement.announcement_type === HOTEL_GUIDE_ANNOUNCEMENT)
      .map(announcement => {
        return { guide_id: announcement.guide_id, language_id: announcement.language_id };
      });

    //  check if there's announcements created in all languages for all guides
    for (let index = 0; index < supplierPartnerGuides.length; index++) {
      const guide = supplierPartnerGuides[index];
      const guideAnnouncements = hotelGuideAnnouncements.filter(
        announcement => announcement.guide_id === guide.guide_id,
      );
      if (guideAnnouncements.length < listLanguage.length) {
        return false;
      }
    }

    return true;
  };

  /**
   * check if this announcement type is available, ie if can be created in some languge
   * check if guide exists in establishment for "HOTEL_GUIDE_ANNOUNCEMENT" type
   */
  const announcementTypeDisabled = announcementType => {
    let announcementsCombinationCreated = false;

    // check if hotelGuideAnnouncement is selected but there's no guide in this program
    if (announcementType === HOTEL_GUIDE_ANNOUNCEMENT) {
      if (!supplierPartnerGuides.length) {
        return true;
      }
      // check if there's possible combination to create
      return announcementHotelGuideTypeDisabled();
    }

    // check for posible announcement type with language creation
    const announcementsLanguageIds = supplierAnnouncements
      .filter(announcement => announcement.announcement_type === announcementType)
      .map(announcement => announcement.language_id);

    if (announcementsLanguageIds && listLanguage) {
      const languageIds = listLanguage.map(language => language.id);

      announcementsCombinationCreated = languageIds.every(v => announcementsLanguageIds.includes(v));
    }

    return announcementsCombinationCreated;
  };

  /**
   * check for combination of language disabled for announcementHotelGuide
   */
  const announcementHotelGuideLanguageDisabled = languageId => {
    if (!guideSelected) {
      return true;
    }

    const announcementsHotelGuide = supplierAnnouncements.filter(
      announcement =>
        announcement.announcement_type === HOTEL_GUIDE_ANNOUNCEMENT && announcement.guide_id === guideSelected,
    );

    if (announcementsHotelGuide.some(announcement => announcement.language_id === languageId)) {
      return true;
    }

    return false;
  };

  /**
   * check for which language announcement has not been created
   */
  const announcementLanguageDisabled = languageId => {
    if (!announcementTypeSelected) {
      return true;
    }

    // check if hotelGuideAnnouncement is selected
    if (announcementTypeSelected === HOTEL_GUIDE_ANNOUNCEMENT) {
      return announcementHotelGuideLanguageDisabled(languageId);
    }

    // check for other announcement types
    const announcementWithSelectedType = supplierAnnouncements.filter(
      announcement => announcement.announcement_type === announcementTypeSelected,
    );

    // check if announcement has been created in all languages for this type
    if (announcementWithSelectedType.some(announcement => announcement.language_id === languageId)) {
      return true;
    }

    return false;
  };

  /**
   * check if this guide is disabled
   * if announcements in all languages have been created for this guide
   */
  const announcementGuideDisabled = guideId => {
    if (!announcementTypeSelected || !listLanguage) {
      return true;
    }

    // filter hotel guide announcements
    const guideAnnouncements = supplierAnnouncements.filter(
      announcement => announcement.announcement_type === HOTEL_GUIDE_ANNOUNCEMENT && announcement.guide_id === guideId,
    );

    return guideAnnouncements.length === listLanguage.length;
  };

  /**
   * get announcement create disable
   */
  const getCreateAnnouncementDisabled = () => {
    return (
      !announcementTypeSelected ||
      !languageSelected ||
      (announcementTypeSelected === HOTEL_GUIDE_ANNOUNCEMENT && !guideSelected)
    );
  };

  /**
   * on create announcement
   */
  const handleOnCreateAnnouncement = async () => {
    let announcementFetch;

    switch (announcementTypeSelected) {
      case HOTEL_GUIDE_ANNOUNCEMENT:
        announcementFetch = await handleCreateHotelGuideAnnouncement(languageSelected, guideSelected);
        break;

      case HOTEL_NO_GUIDE_ANNOUNCEMENT:
        announcementFetch = await handleCreateHotelNoGuideAnnouncement(languageSelected);
        break;

      case GUIDE_ANNOUNCEMENT:
        announcementFetch = await handleCreateGuideAnnouncement(languageSelected);
        break;

      case GUIDE_TOUR_ANNOUNCEMENT:
        announcementFetch = await handleCreateGuideTourAnnouncement(languageSelected);
        break;

      case GUIDE_LOCAL_ANNOUNCEMENT:
        announcementFetch = await handleCreateGuideLocalAnnouncement(languageSelected);
        break;

      case AGENCY_ANNOUNCEMENT:
        announcementFetch = await handleCreateAgencyAnnouncement(languageSelected);
        break;

      case AGENCY_TOUR_ANNOUNCEMENT:
        announcementFetch = await handleCreateAgencyTourAnnouncement(languageSelected);
        break;

      case AGENCY_LOCAL_ANNOUNCEMENT:
        announcementFetch = await handleCreateAgencyLocalAnnouncement(languageSelected);
        break;

      case AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT:
        announcementFetch = await handleCreateAgencyLocalProgramAnnouncement(languageSelected);
        break;

      case SUPPLIER_ANNOUNCEMENT:
        announcementFetch = await handleCreateSupplierAnnouncement(languageSelected);
        break;

      case PHRASE_ANNOUNCEMENT:
        announcementFetch = await handleCreatePhraseAnnouncement(languageSelected);
        break;
      default:
        return toastr.warning("Nije moguće kreirati ovu vrstu najave");
    }

    if (announcementFetch) {
      // check if this announcement has already been created
      if (supplierAnnouncements.some(announcement => announcement.id === announcementFetch.id)) {
        toastr.warning("Ova kombinacija najave je već kreirana");
        return;
      }

      toastr.success("Uspješno kreirana najava");

      // update supplier announcements
      dispatch(setSupplierAnnouncements([announcementFetch, ...supplierAnnouncements]));

      // select last announcement created
      dispatch(
        setAnnouncementSelected({
          id: announcementFetch.id,
          announcement_type: announcementFetch.announcement_type,
        }),
      );

      // close dialog
      handleOnDialogClose();
    } else {
      toastr.error("Greška prilikom kreiranja najave");
    }
  };

  return {
    // variables
    announcementTypeSelected,
    guides: supplierPartnerGuides,
    guideSelected,
    isSelectTypeOpen,
    languageSelected,
    listLanguage,
    supplier,

    // functions
    announcementGuideDisabled,
    announcementLanguageDisabled,
    announcementTypeDisabled,
    getCreateAnnouncementDisabled,
    handleOnCreateAnnouncement,
    handleOnDialogClose,
    onAnnouncementTypeSelect,
    onGuideSelect,
    setLanguageSelected,
  };
}
