import React, { Component } from "react";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateSurcharges extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`surcharges_content`).length) {
      autosize(document.getElementsByName(`surcharges_content`)[0]);
    }
  }
  render() {
    const { isNotDraft } = this.props;

    return (
      <div className="form-unit-double">
        <div className="field-label ">
          <label className="label">Dodatno</label>
        </div>
        <div>
          <TextArea
            inputProps={{
              name: "surcharges_content",
              disabled: isNotDraft,
            }}
            defaultValue={this.props.surcharges_content}
            customOnChange={this.props.changeContent}
            rows="3"
          />
        </div>
      </div>
    );
  }
}

export default TemplateSurcharges;
