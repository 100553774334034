import React, { Component } from "react";
import int from "../intLang";

class PreviewRoom extends Component {
  render() {
    const {
      typology: { typology_translations, establishment_code },
      contract: {
        offer_contract_document: { language_id },
      },
    } = this.props.voucher;

    const typo = typology_translations.find(typo => String(typo.language_id) === String(language_id));

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int[language_id].room}</h4>
        </div>

        <div disabled id="typology" className="preview-content-div preview-textarea">
          {(typo && `1 - ${typo.name} (${establishment_code})`) || ""}
        </div>
      </div>
    );
  }
}

export default PreviewRoom;
