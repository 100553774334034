import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { config, removeDuplicateObjects } from "../../../utility/globals";
import { round } from "../../../utility/numbers";
import { formatDate } from "../../../utility/dates";

import { getTypology } from "../../../graphql/query/administration";

class PriceStructureTermins extends Component {
  state = {
    ui: null,
  };

  async componentDidMount() {
    const { int, type_id, termin_periods, client, language_id } = this.props;

    if (config.offer_type[type_id] !== "Tour") {
      let ui = (
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.price}</h4>
          </div>
          <div className="preview-content-div preview-no-padding bold-text">
            {
              await Promise.all(
                termin_periods &&
                  termin_periods.map(async termin => {
                    const { contigents, price_structure } = termin;

                    // getting typology data
                    const typology =
                      (await Promise.all(
                        contigents &&
                          contigents.map(async ({ typology_id }, index) => {
                            const response = await client.query({
                              query: getTypology,
                              variables: {
                                id: typology_id,
                              },
                            });

                            const typology = response.data.getTypology;

                            return typology;
                          }),
                      )) || [];

                    const typologyFiltered = removeDuplicateObjects(typology).reduce((arr, curr_typo) => {
                      if (arr.find(t => t.typology_group_id === curr_typo.typology_group_id)) {
                        return arr;
                      }
                      return [...arr, curr_typo];
                    }, []);

                    // Reading prices based on typology capacity
                    const prices = typologyFiltered.map((typology, index) => {
                      const typology_translated = typology.typology_translations.find(
                        translation => translation.language_id === language_id,
                      );

                      // Checking which price structure type it is
                      const price_title =
                        config.price_structure_type[price_structure.price_structure_type_id] !== "Per person"
                          ? int.price_per_day
                          : int.price_per_person;

                      const price_typology = JSON.parse(price_structure.price_typology);

                      const formattedPrice = price_typology[typology.typology_group_id]
                        ? round(price_typology[typology.typology_group_id]).toFixed(2)
                        : "0.00";
                      return (
                        <div className="preview-item-price" key={index}>
                          <span className="preview-uppercase">{`${price_title} ${typology_translated.name}[${typology.persons_capacity}]`}</span>
                          <span>{` = ${formattedPrice} €`}</span>
                        </div>
                      );
                    });

                    const tax_title =
                      config.price_structure_type[price_structure.price_structure_type_id] !== "Per person"
                        ? int.tourist_tax_day
                        : int.tourist_tax_person;

                    prices.push(
                      <div className="preview-item-price" key={termin.id}>
                        <span className="preview-item-price preview-uppercase">{tax_title}</span>
                        <span>{` = ${round(price_structure.tourist_tax).toFixed(2)} €`}</span>
                      </div>,
                    );

                    return (
                      <Fragment key={termin.id}>
                        <p key={termin.id}>
                          {formatDate(termin.from)} - {formatDate(termin.to)}
                        </p>
                        {prices}
                        <hr />
                        {price_structure.comment_price_structure && (
                          <div disabled id="comment_price_structure" className="preview-textarea">
                            {price_structure.comment_price_structure.trim()}
                          </div>
                        )}
                      </Fragment>
                    );
                  }),
              )
            }
          </div>
        </div>
      );

      this.setState({
        ui,
      });
    }
  }

  render() {
    return this.state.ui;
  }
}

class PriceStructureSubtermin extends Component {
  state = {
    ui: null,
  };

  async componentDidMount() {
    const { int, type_id, termin_periods, client, language_id } = this.props;

    if (config.offer_type[type_id] === "Tour") {
      //Taking only first subtermin
      const { subtermin_periods, price_structure } = termin_periods[0];
      const termin = termin_periods[0];
      const { contigents } = subtermin_periods[0];

      // getting typology data
      const typology =
        (await Promise.all(
          contigents &&
            contigents.map(async ({ typology_id }) => {
              const response = await client.query({
                query: getTypology,
                variables: {
                  id: typology_id,
                },
              });

              const typology = response.data.getTypology;

              return typology;
            }),
        )) || [];

      const typologyFiltered = removeDuplicateObjects(typology).reduce((arr, curr_typo) => {
        if (arr.find(t => t.typology_group_id === curr_typo.typology_group_id)) {
          return arr;
        }
        return [...arr, curr_typo];
      }, []);

      // Reading prices based on typology capacity
      const prices = typologyFiltered.map(typology => {
        const typology_translated = typology.typology_translations.find(
          translation => translation.language_id === language_id,
        );

        // Checking which price structure type it is
        const price_title =
          config.price_structure_type[price_structure.price_structure_type_id] !== "Per person"
            ? int.price_per_day
            : int.price_per_person;

        const price_typology = JSON.parse(price_structure.price_typology);

        return (
          <div className="preview-item-price" key={typology.id}>
            <span className="preview-uppercase">{`${price_title} ${typology_translated.name}[${typology.persons_capacity}]`}</span>
            <span>{` = ${round(price_typology[typology.typology_group_id]).toFixed(2)} €`}</span>
          </div>
        );
      });

      const tax_title =
        config.price_structure_type[price_structure.price_structure_type_id] !== "Per person"
          ? int.tourist_tax_day
          : int.tourist_tax_person;

      prices.push(
        <div className="preview-item-price" key={termin.id}>
          <span className="preview-item-price preview-uppercase">{tax_title}</span>
          <span>{` = ${round(price_structure.tourist_tax).toFixed(2)} €`}</span>
        </div>,
      );

      let ui = (
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.price}</h4>
          </div>
          <div className="preview-content-div preview-no-padding bold-text">
            <p key={termin.id}>
              {formatDate(termin.from)} - {formatDate(termin.to)}
            </p>
            {prices}
          </div>
        </div>
      );

      this.setState({
        ui,
      });
    }
  }

  render() {
    return this.state.ui;
  }
}

const mapStateToProps = state => {
  const {
    templateInt,
    listTypology,
    preview: {
      offer_contract_document: { offer_contract_document_type_id: type_id, termin_periods, language_id },
    },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();
  return {
    int,
    type_id,
    termin_periods,
    listTypology,
    language_id,
  };
};

const PriceStructureTerminsPreview = connect(mapStateToProps)(withApollo(PriceStructureTermins));
const PriceStructureSubterminPreview = connect(mapStateToProps)(withApollo(PriceStructureSubtermin));

function PriceStructureUsage() {
  return (
    <Fragment>
      <PriceStructureTerminsPreview />
      <PriceStructureSubterminPreview />
    </Fragment>
  );
}

export default PriceStructureUsage;
