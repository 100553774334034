import { useEffect, useState } from "react";

// libs
import { get, debounce } from "lodash";
import { useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// graphql
import { FETCH_ANNOUNCEMENT_ORDERS } from "src/graphql/query/announcementOrders";

// redux state
import { selectAnnouncementOrders, setAnnouncementOrders } from "src/views/AnnouncementOrders/announcementOrdersSlice";

// columns for list
import announcementOrderColumns from "src/views/AnnouncementOrders/List/ListView/components/Columns/AnnouncementOrderColumns";

// constants
import { ANNOUNCEMENT_ORDER_ROW_ID_EDIT } from "src/utility/constants";
import { DEBOUNCE_FETCH_DELAY } from "src/utility/reactTable";
import { useAnnouncementOrdersLocalStorage } from "src/views/AnnouncementOrders/useAnnouncementOrdersLocalStorage";

/**
 * useAnnouncementOrderList is entry point of data for announcement order list component
 */
export default function useAnnouncementOrderList() {
  // local storage
  const { tableState, setTableState } = useAnnouncementOrdersLocalStorage();

  const announcementOrdersPageSelected = tableState.announcementOrdersPageSelected;
  const announcementOrdersPageSizeSelected = tableState.announcementOrdersPageSizeSelected;
  const announcementOrdersFilters = tableState.announcementOrdersFiltersSelected;

  // redux state
  const announcementOrders = useSelector(selectAnnouncementOrders);

  const dispatch = useDispatch();

  // state
  const [pages, setPages] = useState(null);

  // temp variables
  const canPrevious = announcementOrdersPageSelected > 0;
  const canNext = announcementOrdersPageSelected + 1 < pages;

  /**
   * prepare filters for where condition
   */
  const prepareFilters = filtered => {
    let filtersToSend = {};

    filtered.forEach(obj => {
      // check ofr different sending key/values then ones received for table list

      switch (obj.id) {
        case "id":
          filtersToSend = {
            ...filtersToSend,
            dictate_id: obj.value,
          };
          break;

        default:
          filtersToSend = {
            ...filtersToSend,
            [obj.id]: obj.value,
          };
      }
    });

    return filtersToSend;
  };

  // queries
  const { data, loading } = useQuery(FETCH_ANNOUNCEMENT_ORDERS, {
    variables: {
      input: {
        pagination_limit: {
          limit: announcementOrdersPageSizeSelected,
          offset: announcementOrdersPageSelected * announcementOrdersPageSizeSelected,
        },
        where: {
          ...(announcementOrdersFilters.length && prepareFilters(announcementOrdersFilters)),
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // set announcement orders in state
  useEffect(() => {
    const announcementOrders = get(data, "listDictate");

    if (announcementOrders) {
      const totalTableCount = get(announcementOrders, "[0].total_table_count");

      const pages = totalTableCount ? Math.ceil(totalTableCount / announcementOrdersPageSizeSelected) : 1;

      setPages(pages);

      dispatch(setAnnouncementOrders(announcementOrders));
    }
  }, [announcementOrdersPageSizeSelected, data, dispatch]);

  /**
   * table list props
   */
  // table tr props, used for styling background row based on group operativa status
  const getAnnouncementTableTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        // Depending on which row is clicked, open drawer
        switch (column.id) {
          case ANNOUNCEMENT_ORDER_ROW_ID_EDIT:
            break;
          default:
        }
      },
    };
  };

  /**
   * handle announcements filters
   */
  const setFiltered = filtered => {
    setTableState({
      ...tableState,
      announcementOrdersFiltersSelected: filtered,
      announcementOrdersPageSelected: 0,
    });
  };

  // on filtered change
  const onAnnouncementOrdersFilterChange = debounce(setFiltered, DEBOUNCE_FETCH_DELAY);

  /**
   * on announcement table page change
   */
  const onAnnouncementOrdersTablePageChange = pageSelected => {
    setTableState({
      ...tableState,
      announcementOrdersPageSelected: pageSelected,
    });
  };

  /**
   * on announcement table page change
   */
  const onAnnouncementOrdersTablePageSizeChange = pageSizeSelected => {
    setTableState({
      ...tableState,
      announcementOrdersPageSizeSelected: pageSizeSelected,
    });
  };

  return {
    // variables
    announcementOrderColumns,
    announcementOrders,
    announcementOrdersFilters,
    announcementOrdersPageSelected,
    announcementOrdersPageSizeSelected,
    canPrevious,
    canNext,
    loading,
    pages,

    // functions
    getAnnouncementTableTdProps,
    onAnnouncementOrdersFilterChange,
    onAnnouncementOrdersTablePageChange,
    onAnnouncementOrdersTablePageSizeChange,
  };
}
