import _ from "lodash";
import moment from "moment";
import { prepareForSending } from "./prepare";

export const config = {
  offer_type: {
    1: "Group",
    2: "Tour",
    3: "Alotman",
    4: "Program only",
  },
  offer_status: {
    1: "Draft",
    2: "Sent",
    3: "Storno",
    4: "Accepted",
  },
  languages: {
    1: "HR",
    2: "EN",
    3: "DE",
    4: "IT",
  },
  contract_status: {
    1: "Sent",
    2: "Accepted",
    3: "Storno",
  },
  price_structure_type: {
    1: "Per person",
    2: "Per day",
  },
  language: {
    "1": "Croatian",
    "2": "English",
    "3": "German",
    "4": "Italian",
  },
};

export function autosize(textarea) {
  const el = textarea;

  setTimeout(function() {
    el.style.cssText = "height:auto; padding:0";
    // for box-sizing other than "content-box" use:
    el.style.cssText = "-moz-box-sizing:content-box";
    el.style.cssText = "height:" + el.scrollHeight + "px";
  }, 0);
}

export const removeDuplicateObjects = arr =>
  arr.filter((obj_1, i) => arr.findIndex(obj_2 => obj_2.id === obj_1.id) === i);

export const removeDuplicateInts = arr => arr.filter((a, i) => arr.findIndex(b => a === b) === i);

export function getObjectKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function PrintElem(elem) {
  document.body.style = "@media print{@page { size: landscape; }}";

  document.body.innerHTML = elem;
  window.print();
}

export function validateForNonEmptyKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    return !!obj[key]
      ? {
          ...acc,
          [key]: obj[key],
        }
      : acc;
  }, {});
}

// when accessing deeply nested objects, use this instead of long checks
// replaces: user && user.settings && user.settings.etc
// with: getNestedObject(user, ["settings","etc"])
export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);
};

export const where_agency = JSON.stringify(`{ "partner_client_type": { "code": "agencija" } }`);

export const where_agency_2 = JSON.stringify({
  partner_client_type: { code: "agencija" },
});

export const where_partner_type_guide = JSON.stringify({
  partner_client_type: { code: "Vodič" },
});

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

// frormat filter date
export const formatFilterDate = (date = moment()) => {
  return date
    .clone()
    .utcOffset(0)
    .set({
      hour: 0,
      minute: 0,
      millisecond: 0,
    });
};

export const formatFromFilterDate = (date = moment()) => {
  return date
    .clone()
    .utcOffset(0)
    .set({
      hour: 0,
      minute: 0,
      millisecond: 0,
    });
};

export const formatToFilterDate = (date = moment()) => {
  return date
    .clone()
    .utcOffset(0)
    .set({
      hour: 23,
      minute: 59,
      millisecond: 0,
    });
};

export const offerChanges = (offer, offer_copy) => {
  const off = prepareForSending(prepareForSending(offer, ["id"], ["offer_contract_document"]), [
    "__typename",
    "programs",
    "referents",
    "is_editable",
    "termin_period_status",
    "attachments",
    "services",
    "service_types",
    "establishment",
    "additional_services",
    "typology",
    "sold",
    "contract",
    "partner_client",
    "created_at",
    "booking_option_description",
    "booking_option_description_id",
    "termin_periods",
    "offer_contract_document_services",
    "archived_status",
  ]);

  const off_copy = prepareForSending(prepareForSending(offer_copy, ["id"], ["offer_contract_document"]), [
    "__typename",
    "programs",
    "referents",
    "is_editable",
    "termin_period_status",
    "attachments",
    "services",
    "service_types",
    "establishment",
    "additional_services",
    "typology",
    "sold",
    "contract",
    "partner_client",
    "created_at",
    "booking_option_description",
    "booking_option_description_id",
    "termin_periods",
    "offer_contract_document_services",
    "archived_status",
  ]);

  const objDiffs = prepareForSending(difference(off, off_copy));

  return objDiffs;
};

/**
 *
 * @param {currentProgramDays} program_days
 * @param {*trackProgramDaysChanges} program_days_copy
 * check for changes in programDays
 */
export const programDaysChanges = (program_days, program_days_copy) => {
  if (program_days && program_days_copy) {
    const objDiffs = difference(program_days, program_days_copy);

    return objDiffs;
  }
};
