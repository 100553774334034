import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useSupplierAnnouncement from "./hooks/useSupplierAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import LanguageInput from "../LanguageInput";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * SupplierAnnouncement
 */
const SupplierAnnouncement = ({ classes }) => {
  // hooks
  const { handleOnUpdateSupplierAnnouncement, supplierAnnouncement } = useSupplierAnnouncement();

  const title = get(supplierAnnouncement, "announcement_title.value", "");
  const intro = get(supplierAnnouncement, "intro_message.placeholder", "");
  const footer = get(supplierAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* group */}
      <AnnouncementTextField announcementField={get(supplierAnnouncement, "group")} keyOfValue={"group"} />

      {/* date */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "date")}
        keyOfValue={"date"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* time */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "time")}
        keyOfValue={"time"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* language */}
      <LanguageInput
        keyOfValue="language_text"
        label={`${get(supplierAnnouncement, "language_text.label", "")} :`}
        value={get(supplierAnnouncement, "language_text.value", "")}
        onSaveClick={(keyOfValue, value) => handleOnUpdateSupplierAnnouncement(keyOfValue, value)}
      />

      {/* number of persons */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "nr_of_persons")}
        keyOfValue={"nr_of_persons"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* olivari guide with group */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "olivari_guide_with_group")}
        keyOfValue={"olivari_guide_with_group"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* payment */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "payment")}
        keyOfValue={"payment"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* service */}
      <AnnouncementTextField
        announcementField={get(supplierAnnouncement, "service_favor")}
        keyOfValue={"service_favor"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateSupplierAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(SupplierAnnouncement);
