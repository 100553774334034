import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import UserForm from "./UserForm";

import { createUser, userSignatureUpload } from "../../../graphql/mutation/user";

import { AdministrationTabs } from "../AdministrationTabs";
import FormValidator from "../../../components/UI/FormValidator";

import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class CreateUser extends React.Component {
  static schema = {
    properties: {
      first_name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      last_name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      username: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      password: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
      role_ids: {
        type: "array",
        minItems: 1,
        errorMessage: "Potrebno je odabrati barem jednu rolu",
      },
    },
  };

  state = {
    user: {
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      signature_url: "",
      role_ids: [],
    },
    selectedFiles: "",
  };

  deletePreparedFileToUpload = index => {
    if (this.state.selectedFiles && this.state.selectedFiles.length) {
      const selectedFiles = [...this.state.selectedFiles];

      selectedFiles.splice(index, 1);

      this.setState({
        selectedFiles,
      });
    }
  };

  changeUserData = obj => {
    this.setState(prevState => {
      return {
        user: {
          ...prevState.user,
          [obj.key]: obj.value,
        },
      };
    });
  };

  addRole = async role_id => {
    const { user } = this.state;

    if (!role_id) {
      return;
    }

    const user_role_ids = [...user.role_ids];

    user_role_ids.push({ id: role_id, role_id });

    this.setState(prevState => {
      return {
        user: {
          ...prevState.user,
          role_ids: user_role_ids,
        },
      };
    });
  };

  removeRole = id => {
    const {
      user: { role_ids },
    } = this.state;
    const role_ids_clone = role_ids.filter(role => role.id !== id);

    this.setState(prevState => {
      return {
        user: {
          ...prevState.user,
          role_ids: [...role_ids_clone],
        },
      };
    });
  };

  prepareRoleIds = () => this.state.user.role_ids.map(role => role.id);

  onDrop = files => {
    this.setState({
      selectedFiles: files,
    });
  };

  uploadAttachment = async id => {
    const { selectedFiles } = this.state;

    if (selectedFiles && selectedFiles.length) {
      selectedFiles.forEach(async file => {
        await this.props.uploadDocument({
          variables: {
            user_id: id,
            file,
          },
        });
      });
    }
  };

  submit = async () => {
    try {
      const res = await this.props.mutate({
        variables: {
          ...this.state.user,
          role_ids: this.prepareRoleIds(),
        },
      });

      const { createUser } = res.data;

      this.uploadAttachment(createUser.id);

      toastr.success("Korisnik uspješno kreiran");
      this.props.history.push("/administracija/zaposlenik");
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle
          title="Kreiranje novog zaposlenika"
          onBackPressed={() => {
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/zaposlenik/");
            } else {
              this.props.history.goBack();
            }
          }}
        />

        <FormValidator schema={CreateUser.schema} submit={this.submit} data={this.state.user}>
          {({ error, errorMsg, submit }) => (
            <UserForm
              onSubmit={submit}
              error={error}
              errorMsg={errorMsg}
              user={this.state.user}
              removeRole={this.removeRole}
              addRole={this.addRole}
              changeUserData={this.changeUserData}
              onDrop={this.onDrop}
              files={this.state.selectedFiles}
              deletePreparedFileToUpload={this.deletePreparedFileToUpload}
            />
          )}
        </FormValidator>
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(graphql(createUser), graphql(userSignatureUpload, { name: "uploadDocument" }))(CreateUser),
  "Kreiranje zaposlenika",
);
