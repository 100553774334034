import React from "react";

//ui
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, IconButton, Typography } from "@material-ui/core";

// libs
import styled from "styled-components";

// hooks
import useDayItem from "./hooks/useDayItem";

// components
import ActivityItem from "../ActivityItem";
import PartnerItem from "../PartnerItem";
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";

// override style
const styles = {
  root: {
    marginTop: 8,
  },
  item: {
    marginTop: 8,
    marginBottom: 8,
  },
  card: {
    minWidth: 275,
    marginBottom: 16,
  },
  title: {
    marginTop: 8,
  },
};

const OverParentSelect = styled.div`
  .Select-menu,
  .Select-menu:hover {
    position: fixed;

    background-color: #fff;
    border: 1px solid #ccc;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
  }
`;

/**
 * used for rendering day content in list
 */
function DayItem({ classes, index, programDayId, programDayContent }) {
  const { guideIdSelected, handleOnRowDelete, loadingDelete, programDayContentdIdToDelete } = useDayItem(programDayId);

  // check if day is active
  if (!programDayContent.is_active) {
    return null;
  }

  // check if guide id is selected in filter
  if (guideIdSelected && guideIdSelected !== programDayContent.guide_id) {
    return null;
  }

  // return root
  return (
    <OverParentSelect>
      <Grid container className={classes.root} spacing={8} key={programDayContent.program_day_content_id}>
        <Grid item className={classes.item} xs={2}>
          <Typography className={classes.title} variant="body2">
            Aktivnost {index + 1}
          </Typography>
        </Grid>

        <Grid item className={classes.item} xs={4}>
          <ActivityItem labelCount={index} programDayId={programDayId} programDayContent={programDayContent} />
        </Grid>

        <Grid item className={classes.item} xs={5}>
          <PartnerItem programDayId={programDayId} programDayContent={programDayContent} />
        </Grid>
        <Grid container justify="center" alignItems="center" item xs={1}>
          {loadingDelete && programDayContentdIdToDelete === programDayContent.program_day_content_id ? (
            <FixedCircularProgress color="inherit" size={20} thickness={6} />
          ) : (
            <IconButton aria-label="Delete">
              <DeleteIcon onClick={() => handleOnRowDelete(programDayContent.program_day_content_id)} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </OverParentSelect>
  );
}

export default withStyles(styles)(DayItem);
