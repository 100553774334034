import React, { PureComponent } from "react";

import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import ReactTableCustom from "../../../../../components/UI/ReactTable";
import BootstrapLikeInput from "../../../../../components/UI/BootstrapLikeInput";

import { guidIdGenerator, round } from "../../../../../utility/numbers";

const style = { padding: "24px" };

class CalculatorServicesInAbsolutePrice extends PureComponent {
  addAbsolutePriceService = state => {
    const {
      calculator: { services_in_absolute_price },
      service_in_absolute_price,
    } = state;

    const services_in_absolute_price_clone = [...services_in_absolute_price];
    services_in_absolute_price_clone.push({
      ...service_in_absolute_price,
      id: guidIdGenerator(),
    });

    return {
      ...state,
      calculator: {
        ...state.calculator,
        services_in_absolute_price: services_in_absolute_price_clone,
      },
    };
  };

  changeAbsolutePriceService = (prevState, value, name, abs_price_id) => {
    const newState = {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        services_in_absolute_price: prevState.calculator.services_in_absolute_price.map(abs_price => {
          if (abs_price.id === abs_price_id) {
            // Using state reducer to check if recalculation is needed
            const new_abs = this.stateReducer(
              {
                ...abs_price,
                [name]: value,
              },
              name,
              prevState.calculator.calculator_header.grp_min_quantity,
            );

            return {
              ...new_abs,
            };
          }
          return abs_price;
        }),
      },
    };

    return newState;
  };

  stateReducer = (abs, type, grp_min_quantity) => {
    if (type === "price" || type === "quantity") {
      const total = round(round(abs.quantity) * round(abs.price));
      const pr_izl = round(round(total) / round(grp_min_quantity));
      const exit = pr_izl;

      return {
        ...abs,
        total,
        pr_izl,
        exit,
      };
    }
    return abs;
  };

  render() {
    const { services_in_absolute_price, vat, getAbsolutePriceService } = this.props;
    const { onAdding, changeAbsoluteServiceField, changeAbsoluteServiceNumsField } = getAbsolutePriceService({
      onAdding: this.addAbsolutePriceService,
      changeAbsoluteServiceField: this.changeAbsolutePriceService,
      changeAbsolutePriceServiceNumsField: this.changeAbsolutePriceService,
    });

    return (
      <DialogContent style={style} className="page-break content-print">
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <ReactTableCustom
              data={services_in_absolute_price}
              columns={[
                {
                  Header: "VODIčI / AUTOBUSI I  OSTALE USLUGE U APSOLUTNOM IZNOSU",
                  columns: [
                    {
                      Header: "USLUGA",
                      id: "service",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.service}
                          onChange={e => changeAbsoluteServiceField(e)("service")(d.id)}
                        />
                      ),
                      Footer: <strong>UKUPNO VODIČ / BUS</strong>,
                    },
                    {
                      Header: "PDV",
                      id: "vat",
                      accessor: d => (
                        <div className="print-span-container">
                          <select
                            className="booking-select "
                            onChange={e => changeAbsoluteServiceField(e)("vat")(d.id)}
                            value={d.vat}
                          >
                            <option value="" />
                            {vat &&
                              vat.map(v => (
                                <option key={v} value={v}>
                                  {v}
                                </option>
                              ))}
                          </select>
                          <span className="select-print" style={{ display: "none" }}>
                            {d.vat}
                          </span>
                        </div>
                      ),
                    },
                    {
                      Header: "IZNOS",
                      id: "price",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.price}
                          onChange={e => changeAbsoluteServiceNumsField(e)("price")(d.id)}
                        />
                      ),
                    },
                    {
                      Header: "KOLIČINA",
                      id: "quantity",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.quantity}
                          onChange={e => changeAbsoluteServiceNumsField(e)("quantity")(d.id)}
                        />
                      ),
                    },
                    {
                      Header: "UKUPNO",
                      id: "total",
                      Cell: ({ original: { total } }) =>
                        total && typeof total !== "undefined"
                          ? Number(total).toFixed(2) + " €"
                          : Number(0).toFixed(2) + " €",
                      accessor: "total",
                    },
                    {
                      Header: "PR. IZL",
                      accessor: "pr_izl",
                      Cell: ({ original: { pr_izl } }) =>
                        pr_izl && typeof pr_izl !== "undefined"
                          ? Number(pr_izl).toFixed(2) + " €"
                          : Number(0).toFixed(2) + " €",
                      Footer: () => {
                        return (
                          <strong>
                            {Number(
                              services_in_absolute_price.reduce((total, current) => {
                                return total + current.pr_izl;
                              }, 0),
                            ).toFixed(2)}{" "}
                            €
                          </strong>
                        );
                      },
                    },
                    {
                      Header: "IZLAZ",
                      accessor: "exit",
                      Cell: ({ original: { exit } }) =>
                        exit && typeof exit !== "undefined"
                          ? Number(exit).toFixed(2) + " €"
                          : Number(0).toFixed(2) + " €",
                      Footer: (
                        <strong>
                          {Number(
                            services_in_absolute_price.reduce((total, current) => total + current.exit, 0),
                          ).toFixed(2)}{" "}
                          €
                        </strong>
                      ),
                    },
                  ],
                },
              ]}
              innerProps={{
                pageSize: services_in_absolute_price.length,
                showPagination: false,
                sortable: false,
                filterable: false,
                filterAll: false,
                noDataText: "Nema dodanih usluga",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={onAdding}>
              DODAJ USLUGU
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}

export default CalculatorServicesInAbsolutePrice;
