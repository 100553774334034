import React, { Component } from "react";
import OfferForm from "../OfferForm";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateTax extends Component {
  shouldComponentUpdate(nextProps) {
    // to check if there are inquiry validation error
    const next_err_tax_value =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("sojourn_tax_value");
      }).length;

    const prev_err_tax_value =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("sojourn_tax_value");
      }).length;

    const next_err_tax_content =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes("sojourn_tax_content");
      }).length;

    const prev_err_tax_content =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes("sojourn_tax_content");
      }).length;

    return (
      nextProps.isNotDraft !== this.props.isNotDraft ||
      nextProps.sojourn_tax_value !== this.props.sojourn_tax_value ||
      nextProps.sojourn_tax_content !== this.props.sojourn_tax_content ||
      next_err_tax_content !== prev_err_tax_content ||
      next_err_tax_value !== prev_err_tax_value
    );
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`sojourn_tax_content`).length) {
      autosize(document.getElementsByName(`sojourn_tax_content`)[0]);
    }
  }

  render() {
    const { error, errorMsg, isNotDraft } = this.props;

    return (
      <div className="form-unit-double">
        <div className="field-label ">
          <label className="label">Boravišna pristrojba</label>
        </div>
        <div>
          <FormControl error={error("/offer_contract_document/sojourn_tax_value")}>
            <InputLabel htmlFor="sojourn_tax_value">Boravišna pristrojba</InputLabel>
            <Input
              id="sojourn_tax_value"
              className="template-input"
              inputProps={{
                name: "sojourn_tax_value",
              }}
              value={this.props.sojourn_tax_value}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
            <FormHelperText>{errorMsg("/offer_contract_document/sojourn_tax_value")}</FormHelperText>
          </FormControl>

          <div>
            <TextArea
              error={error("/offer_contract_document/sojourn_tax_content")}
              inputProps={{
                name: "sojourn_tax_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.sojourn_tax_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
            <FormHelperText error={error("/offer_contract_document/sojourn_tax_content")}>
              {errorMsg("/offer_contract_document/sojourn_tax_content")}
            </FormHelperText>
          </div>
        </div>
      </div>
    );
  }
}

const TemplateTaxHOC = props => (
  <OfferForm.ConsumerHOC {...props}>
    <TemplateTax />
  </OfferForm.ConsumerHOC>
);

export default TemplateTaxHOC;
