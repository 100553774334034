// libs
import { useQuery } from "react-apollo";

// graphql
import { ALL_GUIDES } from "src/graphql/query/operative";

/**
 * useGudies is used for fetching guidesList
 */
export const useFetchGuides = variables => {
  const { data, loading, error, fetchMore } = useQuery(ALL_GUIDES, {
    variables: {
      ...variables,
    },
    notifyOnNetworkStatusChange: true,
  });

  return { dataFetchGuides: data, loadingFetchGudies: loading, errorFetchGuides: error, fetchMoreGuides: fetchMore };
};
