import React from "react";
import debounce from "lodash/debounce";

// Shared components
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import Comment from "../../../../components/UI/CommentTextArea";

import EstablishmentContigent from "./EstablishmentContigent";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import { config, removeDuplicateObjects, autosize } from "../../../../utility/globals";
import get from "lodash/get";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";

class EstablishmentData extends React.Component {
  state = { fetchingMore: false };

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  where = {};

  fetchDataAfterInputChange = async () => {
    await this.props.listEstablishmentQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listEstablishment.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listEstablishment: [...fetchMoreResult.listEstablishment],
        };
      },
    });

    this.setState({ fetchingMore: false });
  };

  handleAutocompleteInputChange = async value => {
    if (this.where.name === value) {
      return;
    }

    this.where = { name: value };
    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: JSON.stringify(this.where),
      },
    };

    this.setState({ fetchingMore: true }, this.fetchDataAfterInputChange);
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`comment_establishment`).length) {
      autosize(document.getElementsByName(`comment_establishment`)[0]);
    }
  }

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listEstablishment &&
      listEstablishment.length >= PAGINATION_LIMIT
    ) {
      this.setState({ fetchingMore: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listEstablishmentQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listEstablishment.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listEstablishment: [...previousResult.listEstablishment, ...fetchMoreResult.listEstablishment],
            };
          },
        });

        this.setState({ fetchingMore: false });
      });
    }
  };

  render() {
    const { establishment, termin_periods, isNotDraft } = this.props;

    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);
    const loadingEstablishments = get(this.props, "listEstablishmentQuery.loading");

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    if (config.offer_type[this.props.offer_contract_document_type_id] === "Tour") {
      return null;
    }

    const combinedEstablishments = establishment
      ? removeDuplicateObjects(listEstablishment.concat(establishment))
      : listEstablishment;

    const allEstablishments = combinedEstablishments.map(est => {
      const nameToShow = `${est.name} - ${est.city ? est.city.name : ""}`;

      return {
        label: nameToShow,
        value: est.id,
      };
    });

    return (
      <React.Fragment>
        {/* Establishment */}
        <React.Fragment>
          <div className="form-unit-double">
            <div className="field-label is-autocomplete">
              <label className="label">Smještaj</label>
            </div>
            <AutocompleteSelect
              defaultValue={this.props.establishment_id}
              placeholder="Odaberite smještajnu jedinicu"
              autocompleteHandler={selected => {
                this.props.onPickedEstablishment({
                  key: "establishment_id",
                  value: selected ? selected : "",
                  listEstablishment: combinedEstablishments,
                });
              }}
              dataSource={allEstablishments}
              disabled={isNotDraft}
              inputProps={{
                onInputChange: this.handleAutocompleteInputChangeDebounced,
              }}
              onMenuScrollToBottom={this.handleScroller}
              isLoading={loadingEstablishments || this.state.fetchingMore}
            />
          </div>
          {/* Komentar establishmenta  */}
          <div className="form-unit-double">
            <div />
            <Comment
              name="comment_establishment"
              defaultValue={this.props.comment_establishment}
              label="Komentar na smještaj"
              inputProps={{ disabled: isNotDraft }}
            />
          </div>
        </React.Fragment>

        <EstablishmentContigent
          termin_periods={termin_periods}
          listEstablishment={combinedEstablishments}
          isNotDraft={isNotDraft}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    establishment,
    offer: {
      offer_contract_document: { comment_establishment, offer_contract_document_type_id, termin_periods },
    },
  } = state.offer;
  return {
    establishment,
    establishment_id: state.offer.establishment_id,
    offer_contract_document_type_id,
    termin_periods,
    comment_establishment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPickedEstablishment: obj => dispatch(actions.pickEstablishment(obj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentData);
