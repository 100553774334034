import { useCallback } from "react";

// libs
import { debounce, get, omit } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideSchedules,
  selectProgramDayActivities,
  setGuideSchedules,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_PROGRAM_DAY_CONTENT, UPDATE_PROGRAM_DAY_TITLE } from "src/graphql/mutation/operative";

// hooks
import useProgramDay from "./useProgramDay";

/**
 * useDayContainer is entry point of data for dayContainer component
 */
export default function useDayContainer(programDayId) {
  // redux state
  const programDayActivities = useSelector(selectProgramDayActivities);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // hooks
  const { programDay } = useProgramDay(programDayId);

  // change program day activities mutation
  const [updateProgramDayActivities, { loading: loadingUpdateProgramDayActivities }] = useMutation(
    UPDATE_PROGRAM_DAY_CONTENT,
  );

  // change program day title
  const [updateProgramDayTitle, { loading: loadingUpdateProgramDayTitle }] = useMutation(UPDATE_PROGRAM_DAY_TITLE);

  /**
   * handle on title change
   */

  const handleOnTitleUpdate = async programDayTitle => {
    const updateProgramDayResponse = await updateProgramDayTitle({
      variables: {
        input: {
          program_day_id: programDayId,
          title: programDayTitle,
        },
      },
    });

    const programDayTitleUpdate = get(updateProgramDayResponse, "data.UpdateProgramDayTitleField");

    if (programDayTitleUpdate) {
      toastr.success("Uspješno ažuriran naslov");

      //todo handle on program update
      const programDaysUpdated = programDayActivities.program_days.map(programDay => {
        if (programDay.program_day_id === programDayId) {
          return {
            ...programDay,
            title: programDayTitleUpdate.title,
          };
        }

        return programDay;
      });

      dispatch(setProgramDayActivities({ ...programDayActivities, program_days: programDaysUpdated }));

      // prepare programDayUpdate list for listGuides, since is not same as in program object
      const guidesProgramDaysUpdated = programDaysUpdated.map(programDay => {
        return omit(programDay, ["program_day_contents"]);
      });

      // update list guides
      const listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === programDayActivities.program_id) {
          return {
            ...guide,
            program_days: guidesProgramDaysUpdated,
          };
        }
        return guide;
      });

      dispatch(setGuideSchedules(listGuidesUpdate));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  const delayedCallback = debounce(handleOnTitleUpdate, 1000);

  const onTitleChange = useCallback(
    event => {
      //setDayTitle(event.target.value);
      event.persist();

      let programDayTitle = get(event, "target.value");

      delayedCallback(programDayTitle);
    },
    [delayedCallback],
  );

  /**
   * get if there exist program day content with activeStatus : false
   * so we can activate it
   */
  const getIfProgramDayContentDisabledExists = () => {
    if (!programDayActivities) {
      return;
    }

    // find first possible programDayContentId to add from list
    const programDaySelected = programDayActivities.program_days.find(
      programDay => programDay.program_day_id === programDayId,
    );

    const programDayContentToShow = programDaySelected.program_day_contents.find(
      programDayContent => programDayContent.is_active === false,
    );

    return !Boolean(programDayContentToShow);
  };

  /**
   * handle on add activity
   */
  const handleOnAddActivity = useCallback(
    async programDayId => {
      if (!programDayActivities) {
        return;
      }

      // find first possible programDayContentId to add from list
      const programDaySelected = programDayActivities.program_days.find(
        programDay => programDay.program_day_id === programDayId,
      );

      if (!programDaySelected) {
        return;
      }

      const programDayContentToShow = programDaySelected.program_day_contents.find(
        programDayContent => programDayContent.is_active === false,
      );

      if (!programDayContentToShow) {
        return toastr.warning("Dodan je maksimalni broj aktivnosti");
      }

      const updateProgramDayResponse = await updateProgramDayActivities({
        variables: {
          input: {
            program_day_content_id: programDayContentToShow.program_day_content_id,
            is_active: true,
          },
        },
      });

      const programUpdated = get(updateProgramDayResponse, "data.UpdateProgramDayContentAndGuideCalendarFields");

      if (programUpdated) {
        toastr.success("Uspješno dodana aktivnost");

        //todo handle on program update
        const program_days_updated = programDayActivities.program_days.map(programDay => {
          if (programDay.program_day_id === programDayId) {
            return {
              ...programDay,
              program_day_contents: programDay.program_day_contents.map(programDayContent => {
                if (programDayContent.program_day_content_id === programUpdated.program_day_content_id) {
                  return {
                    ...programDayContent,
                    is_active: programUpdated.is_active,
                  };
                }

                return programDayContent;
              }),
            };
          }

          return programDay;
        });

        dispatch(setProgramDayActivities({ ...programDayActivities, program_days: program_days_updated }));
      } else {
        toastr.error("Greška prilikom ažuriranja");
      }
    },
    [dispatch, programDayActivities, updateProgramDayActivities],
  );

  return {
    getIfProgramDayContentDisabledExists,
    handleOnAddActivity,
    handleOnTitleUpdate,
    loadingUpdateProgramDayActivities,
    loadingUpdateProgramDayTitle,
    onTitleChange,
    programDay,
  };
}
