import React, { Component } from "react";
import { withApollo } from "react-apollo";

import { signin } from "../graphql/query/user";

import Paper from "material-ui/Paper";
import TextField from "@material-ui/core/TextField";
import RaisedButton from "material-ui/RaisedButton";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import "./Login.css";
import { wsLink } from "../apollo/apollo";
import FormValidator from "../components/UI/FormValidator";

import YouTube from "react-youtube";

const style = {
  maxWidth: 400,
  padding: 20,
  textAlign: "center",
  display: "block",
  margin: "0 auto"
};

const formStyle = {
  width: "250px",
  margin: "0px auto"
};

const FAILED_ATTEMPTS_TO_SHOW_YOUTUBE = 8;

class Login extends Component {
  static schema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        isNotEmpty: true,
        minLength: 1,
        errorMessage: "Korisničko ime ne smije biti prazno"
      },
      password: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Zaporka polje ne smije biti prazna"
      }
    },
    required: ["username", "password"]
  };

  state = {
    username: "",
    password: "",
    validation: false,
    failedAttemptsCounter: 0
  };

  onFormChangeHandler = name => ({ target: { value } }) => {
    this.setState({
      [name]: value
    });
  };

  onSubmit = async () => {
    const { username, password } = this.state;
    try {
      const response = await this.props.client.query({
        query: signin,
        variables: { username, password },
        fetchPolicy: "network-only"
      });

      const {
        signin: { jwt }
      } = response.data;

      this.storeToken(jwt);

      wsLink.subscriptionClient.tryReconnect();

      this.props.history.push("/");
    } catch (error) {
      let { failedAttemptsCounter } = this.state;
      failedAttemptsCounter++;
      this.setState({
        failedAttemptsCounter
      });
    }
  };

  storeToken(jwt) {
    localStorage.setItem("token", jwt);
  }

  render() {
    const { username, password, failedAttemptsCounter } = this.state;

    return (
      <FormValidator
        schema={Login.schema}
        submit={this.onSubmit}
        data={{ username, password }}
      >
        {({ error, errorMsg, submit }) => (
          <div className="Login row middle-xs">
            <div className="col-xs-12">
              <div className="box">
                <Paper style={style} zDepth={1}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      this.onSubmit();
                    }}
                    style={formStyle}
                  >
                    <h1 className="login-title">Olivari Travel login</h1>
                    <h2 style={{ fontWeight: "400" }}>Prijava na račun</h2>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Korisničko ime"
                        error={error("/username")}
                        onChange={this.onFormChangeHandler("username")}
                      />
                      {error("/username") && (
                        <FormHelperText error={error("/username")}>
                          {errorMsg("/username")}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <TextField
                        name="current-password"
                        type="password"
                        label="Zaporka"
                        error={error("/password")}
                        onChange={this.onFormChangeHandler("password")}
                      />
                      {error("/password") && (
                        <FormHelperText error={error("/password")}>
                          {errorMsg("/password")}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <br />
                    <br />
                    <br />
                    <br />

                    <RaisedButton
                      type="submit"
                      label="Prijava"
                      primary={true}
                      onClick={submit}
                    />
                  </form>
                </Paper>
                {failedAttemptsCounter >= FAILED_ATTEMPTS_TO_SHOW_YOUTUBE ? (
                  <div className="ne-moze">
                    <YouTube
                      videoId={"fOEp1GiVsWs"}
                      opts={{ playerVars: { autoplay: 1 } }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </FormValidator>
    );
  }
}

export default withApollo(Login);
