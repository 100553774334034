import React from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";

import { connect } from "react-redux";
import toastr from "toastr";
import { deleteEstablishment, updateEstablishment } from "../../../../graphql/mutation/administration";
import { getEstablishment } from "../../../../graphql/query/administration";
import { copyFetchedAdministrationData } from "../../../../store/actions";
import { prepareForSending } from "../../../../utility/prepare";
import EstablishmentForm from "./EstablishmentForm";
import TableTitle from "src/components/UI/TableTitle";
class EditEstablishment extends React.Component {
  state = {
    establishment: "",
  };

  async componentDidMount() {
    const response = await this.props.client.query({
      query: getEstablishment,
      variables: { id: this.props.establishment_id },
    });

    if (response.data.getEstablishment) {
      this.props.copyFetchedAdministrationData({
        establishment: response.data.getEstablishment,
      });
      // set establishment for editing
      this.setState({
        establishment: response.data.getEstablishment,
      });
    }
  }

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        establishment: {
          ...prevState.establishment,
          [obj.key]: obj.value,
        },
      };
    });

  typologyAdded = typology => {
    const {
      establishment,
      establishment: { typologies },
    } = this.state;

    const typologies_clone = [...typologies];
    typologies_clone.push(typology);

    this.setState({
      establishment: {
        ...establishment,
        typologies: typologies_clone,
      },
    });
  };

  typologyEdited = typology => {
    const {
      establishment,
      establishment: { typologies },
    } = this.state;

    const typologies_clone = typologies.map(typo => {
      if (typo.id === typology.id) {
        return typology;
      }
      return typo;
    });

    this.setState({
      establishment: {
        ...establishment,
        typologies: typologies_clone,
      },
    });
  };

  typologyRemove = typology_id => {
    const {
      establishment,
      establishment: { typologies },
    } = this.state;

    const typologies_clone = typologies.filter(typo => typo.id !== typology_id);

    this.setState({
      establishment: {
        ...establishment,
        typologies: typologies_clone,
      },
    });
  };

  submit = async () => {
    const { establishment } = this.state;
    try {
      await this.props.mutate({
        variables: {
          id: this.props.establishment_id,
          patch: {
            ...prepareForSending(establishment, ["__typename", "id", "city", "typologies", "typology_group"]),
          },
        },
      });

      toastr.success("Uspješno ažurirano");

      this.props.closeEdit();
    } catch (error) {}
  };

  delete = async () => {
    try {
      await this.props.deleteEstablishment({
        variables: {
          id: this.props.establishment_id,
        },
      });

      toastr.success("Uspješno izbrisano");

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    if (!this.state.establishment) {
      return null;
    }
    return (
      <div>
        <TableTitle title="Ažuriranje smještaja" onBackPressed={this.props.closeEdit} />

        <EstablishmentForm
          establishment={this.state.establishment}
          onSubmit={this.submit}
          cancelToggle={this.props.closeEdit}
          onChangeField={this.onChangeField}
          onAddTypology={this.typologyAdded}
          typologyEdited={this.typologyEdited}
          typologyRemove={this.typologyRemove}
          onDelete={this.delete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { establishment } = state.administration;
  return { establishment };
};

export default connect(mapStateToProps, { copyFetchedAdministrationData })(
  compose(
    graphql(updateEstablishment),
    graphql(deleteEstablishment, {
      name: "deleteEstablishment",
    }),
  )(withApollo(EditEstablishment)),
);
