import initialState from "./initialState";
import { clonedPartnerClientArrays } from "./utility";
import { guidIdGenerator } from "../offer/helpers";

export const fetchedDataToEdit = (state, action) => {
  return {
    ...state,
    ...action.obj,
  };
};

export const changeEstablishment = (state, action) => {
  return {
    ...state,
    establishment: {
      ...state.establishment,
      [action.param.key]: action.param.value,
    },
  };
};

export const changeEstablishmentChain = (state, action) => {
  return {
    ...state,
    establishment_chain: {
      ...state.establishment_chain,
      [action.param.key]: action.param.value,
    },
  };
};

export const changeUser = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      [action.param.key]: action.param.value,
    },
  };
};

export const changePartnerClient = (state, action) => {
  return {
    ...state,
    partner_client: {
      ...state.partner_client,
      [action.param.key]: action.param.value,
    },
  };
};

export const addPartnerClientContact = state => {
  const { partner_client_name, partner_client_phone, partner_client_email } = state;

  const [contact_persons, phones, emails] = clonedPartnerClientArrays(state);

  contact_persons.push(partner_client_name);
  phones.push(partner_client_phone);
  emails.push(partner_client_email);

  return {
    ...state,
    partner_client: {
      ...state.partner_client,
      contact_persons,
      phones,
      emails,
    },
    partner_client_name: "",
    partner_client_phone: "",
    partner_client_email: "",
  };
};

export const changeTypology = (state, action) => {
  return {
    ...state,
    typology: {
      ...state.typology,
      [action.param.key]: action.param.value,
    },
  };
};

export const editTypology = (state, param, id) => {
  const {
    establishment: { typologies },
  } = state;

  const typologies_clone = typologies.map(typology => {
    if (typology.id === id) {
      return {
        ...typology,
        [param.key]: param.value,
      };
    }
    return typology;
  });

  return {
    ...state,
    establishment: {
      ...state.establishment,
      typologies: typologies_clone,
    },
  };
};

export const removeTypology = (state, id) => {
  const {
    establishment: { typologies },
  } = state;

  const typologies_clone = typologies.filter(typo => typo.id !== id);

  return {
    ...state,
    establishment: {
      ...state.establishment,
      typologies: typologies_clone,
    },
  };
};

export const addTypology = state => {
  const {
    establishment: { typologies },
  } = state;

  const typologies_clone = [...typologies, { ...state.typology }];

  const typology_translations = state.typology.typology_translations.map(translation => ({
    ...translation,
    id: guidIdGenerator(),
    desc: "",
    name: "",
  }));

  return {
    ...state,
    establishment: {
      ...state.establishment,
      typologies: typologies_clone,
    },
    typology: {
      ...initialState.typology,
      id: guidIdGenerator(),
      typology_translations,
    },
  };
};

export const saveEditedTypology = (state, typology) => {
  const {
    establishment: { typologies },
  } = state;

  const typologies_clone = typologies.map(typo => {
    if (typo.id === typology.id) {
      return typology;
    } else {
      return typo;
    }
  });

  return {
    ...state,
    establishment: {
      ...state.establishment,
      typologies: typologies_clone,
    },
  };
};

export const changeTypologyTranslationField = (state, obj, language_id) => {
  const {
    typology,
    typology: { typology_translations },
  } = state;

  typology.typology_translations = typology_translations.map(translation => {
    if (translation.language_id === language_id) {
      return {
        ...translation,
        [obj.key]: obj.value,
      };
    }
    return translation;
  });

  return {
    ...state,
    typology: { ...typology },
  };
};

export const changeService = (state, action) => {
  return {
    ...state,
    service: {
      ...state.service,
      [action.param.key]: action.param.value,
    },
  };
};

export const changeServiceType = (state, action) => {
  return {
    ...state,
    service_type: {
      ...state.service_type,
      [action.param.key]: action.param.value,
    },
  };
};

export const changeActivity = (state, action) => {
  return {
    ...state,
    activity: {
      ...state.activity,
      [action.param.key]: action.param.value,
    },
  };
};

export const changeActivityType = (state, action) => {
  return {
    ...state,
    activity_type: {
      ...state.activity_type,
      [action.param.key]: action.param.value,
    },
  };
};
