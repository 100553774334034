import React, { Component, Fragment } from "react";
import int from "../intLang";

class PreviewService extends Component {
  render() {
    const {
      service_type,
      starting_service,
      ending_service,
      contract: {
        offer_contract_document: { language_id },
      },
    } = this.props.voucher;

    const service_type_name = service_type.service_type_translations.find(
      s => String(s.language_id) === String(language_id),
    ).name;
    const service_start_name = starting_service.service_translations.find(
      s => String(s.language_id) === String(language_id),
    ).name;
    const service_end_name = ending_service.service_translations.find(
      s => String(s.language_id) === String(language_id),
    ).name;

    return (
      <Fragment>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int[language_id].service}</h4>
          </div>
          <div disabled id="service_type_name" className="preview-content-div preview-textarea">
            {service_type_name}
          </div>
        </div>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int[language_id].service_start}</h4>
          </div>
          <div disabled id="service_start_name" className="preview-content-div preview-textarea">
            {service_start_name}
          </div>
        </div>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int[language_id].service_end}</h4>
          </div>
          <div disabled id="service_end_name" className="preview-content-div preview-textarea">
            {service_end_name}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PreviewService;
