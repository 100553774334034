import React, { Component } from "react";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import { connect } from "react-redux";
import toastr from "toastr";
import moment from "moment";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import ReactTableCustom from "../../../../components/UI/ReactTable";
import {
  sortContigentsByTypologyPriorityWithoutListEstablishment,
  findTypologyByCroLang,
} from "../../../../utility/establishment";

import { removeTermin, saveEditedContigentTypologySum } from "../../../../store/actions";
import { dateDiff, formatDate } from "../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import { Paper, FormHelperText } from "@material-ui/core";

import { config } from "../../../../utility/globals";
import OfferForm from "../OfferForm";
import { withRouter } from "react-router-dom";
import { AlotmanContigentEdit, AlotmanContigentShow } from "./AlotmanContigent";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class AlotmanTerminsList extends Component {
  state = {
    terminToEdit: null,
    oldTermin: null,
    contigentToEdit: null,
    blockedDaysMessage: null,
  };

  openToEdit = (expanded, index) => {
    let termin = this.dataToShow()[index[0]];

    termin = {
      ...termin,
      from: moment(termin.from, "DD-MM-YYYY"),
      to: moment(termin.to, "DD-MM-YYYY"),
    };

    if (!this.state.expanded) {
      this.setState({
        expanded,
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
        contigentToEdit: null,
        blockedDaysMessage: null,
      });
    } else {
      this.setState({
        expanded: {
          [index[0]]: !this.state.expanded[index[0]],
        },
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
        contigentToEdit: null,
        blockedDaysMessage: null,
      });
    }
  };

  dataToShow = () => {
    return this.props.termins.map(termin => {
      const { contigents } = termin;
      let establishment_name;

      const contigents_list = !this.props.language_id
        ? "Odaberite jezik prije prikaza kontigenata"
        : sortContigentsByTypologyPriorityWithoutListEstablishment(contigents).map((contigent, index) => {
            establishment_name = contigent.typology.establishment.name;

            const typology_translated = findTypologyByCroLang(contigent.typology);

            return (
              <div key={index}>
                {contigent.typologies_sum +
                  " / " +
                  (contigent.typology
                    ? `${typology_translated.name} (${contigent.typology.establishment_code}) [${
                        contigent.typology.persons_capacity
                      }]`
                    : "")}
              </div>
            );
          });

      return {
        ...termin,
        from: formatDate(termin.from),
        to: formatDate(termin.to),
        diff: dateDiff(termin.from, termin.to, "day"),
        contigents_list,
        establishment_name,
      };
    });
  };

  deleteTermin = async id => {
    // if we're in edit mode
    if (this.props.match.params.id) {
      try {
        const response = await this.props.deleteTerminPeriod({
          variables: {
            id,
          },
        });

        toastr.success("Uspješno izbrisan termin");
        this.props.removeTermin(response.data.deleteTerminPeriod);
      } catch (error) {}
    } else {
      this.props.removeTermin(id);
    }
  };

  prepareContigentToEdit = contigent => () => {
    const typology_translated = contigent.typology.typology_translations.find(
      translation => translation.language_id === this.props.language_id,
    );

    this.setState({
      contigentToEdit: {
        ...contigent,
        persons_capacity: `${typology_translated.name} [${contigent.typology.persons_capacity}]`,
      },
      oldContigent: contigent,
    });
  };

  changeTypologySum = ({ target: { value } }) =>
    this.setState(prev => ({
      contigentToEdit: {
        ...prev.contigentToEdit,
        typologies_sum: value,
      },
    }));

  typologyName = contigent => {
    const typology_translated =
      contigent.typology &&
      contigent.typology.typology_translations &&
      contigent.typology.typology_translations.find(translation => translation.language_id === this.props.language_id);

    return `${(typology_translated && typology_translated.name) || ""} [${contigent.typology.persons_capacity}]`;
  };

  saveUpdatedContigent = () => {
    const { contigentToEdit, oldContigent, terminToEdit } = this.state;

    // Still in creating offer
    if (contigentToEdit.id.includes("-")) {
      this.props.saveEditedContigentTypologySum(contigentToEdit, oldContigent);

      this.setState({
        contigentToEdit: null,
        oldContigent: null,
        terminToEdit: {
          ...terminToEdit,
          contigents: terminToEdit.contigents.map(contigent => {
            if (contigent.id === oldContigent.id) {
              return {
                ...contigent,
                typologies_sum: contigentToEdit.typologies_sum,
              };
            }
            return contigent;
          }),
        },
      });
    } else {
      try {
        this.props.updateContigent({
          variables: {
            id: contigentToEdit.id,
            typologies_sum: contigentToEdit.typologies_sum,
          },
        });

        this.props.saveEditedContigentTypologySum(contigentToEdit, oldContigent);

        this.setState({
          contigentToEdit: null,
          oldContigent: null,
          terminToEdit: {
            ...terminToEdit,
            contigents: terminToEdit.contigents.map(contigent => {
              if (contigent.id === oldContigent.id) {
                return {
                  ...contigent,
                  typologies_sum: contigentToEdit.typologies_sum,
                };
              }
              return contigent;
            }),
          },
        });

        toastr.success("Kontigent uspješno ažuriran");
      } catch (error) {}
    }
  };

  render() {
    const { offer_contract_document_type_id, isNotDraft } = this.props;

    if (config.offer_type[offer_contract_document_type_id.toString()] === "Tour") {
      return null;
    }

    const { terminToEdit } = this.state;

    return (
      <OfferForm.Consumer>
        {({ error, errorMsg }) => (
          <div className="cols-2">
            <div className="form-section-one">
              {error("/termin_periods") && (
                <FormHelperText error={error("/termin_periods")}>{errorMsg("/termin_periods")}</FormHelperText>
              )}
              <Paper elevation={1} className={error("/termin_periods") ? "textarea-error" : ""}>
                <ReactTableCustom
                  data={this.dataToShow()}
                  columns={[
                    {
                      Header: "Edit",
                      columns: [
                        {
                          expander: true,
                          Header: () => <strong>More</strong>,
                          width: 65,

                          Expander: ({ isExpanded, ...rest }) => {
                            return !isNotDraft ? (
                              <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                            ) : null;
                          },
                          style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none",
                          },
                        },
                      ],
                    },
                    {
                      Header: "TERMINI",
                      columns: [
                        {
                          Header: "Od",
                          accessor: "from",
                        },
                        {
                          Header: "Do",
                          accessor: "to",
                        },
                        {
                          Header: "Dana",
                          accessor: "diff",
                          width: 70,
                        },
                        {
                          Header: "Kontigent",
                          id: "contigents_list",
                          accessor: d => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "0 auto",
                              }}
                            >
                              {d.contigents_list}
                            </div>
                          ),
                          width: 150,
                        },
                        {
                          Header: "Smještaj",
                          accessor: "establishment_name",
                        },
                        {
                          Header: "Brisanje",
                          id: "delete",
                          accessor: termin =>
                            !isNotDraft ? (
                              <DeleteForeverIcon
                                className={this.props.classes.icon}
                                onClick={() => this.deleteTermin(termin.id)}
                              />
                            ) : null,
                        },
                      ],
                    },
                  ]}
                  innerProps={{
                    pageSize: this.dataToShow().length,
                    showPagination: false,
                    sortable: false,
                    filterable: false,
                    filterAll: false,
                    expanded: this.state.expanded,
                    onExpandedChange: this.openToEdit,
                    SubComponent: () => {
                      if (!terminToEdit) {
                        return "";
                      }

                      const { contigentToEdit } = this.state;

                      return (
                        <div style={{ padding: "12px" }}>
                          {terminToEdit.contigents.map(contigent => {
                            if (contigentToEdit && contigentToEdit.id === contigent.id) {
                              return (
                                <AlotmanContigentEdit
                                  key={contigent.id}
                                  contigentToEdit={contigentToEdit}
                                  saveUpdatedContigent={this.saveUpdatedContigent}
                                  changeTypologySum={this.changeTypologySum}
                                />
                              );
                            } else {
                              return (
                                <AlotmanContigentShow
                                  key={contigent.id}
                                  contigent={contigent}
                                  prepareContigentToEdit={this.prepareContigentToEdit}
                                  typologyName={this.typologyName}
                                />
                              );
                            }
                          })}
                        </div>
                      );
                    },
                  }}
                />
              </Paper>
            </div>
          </div>
        )}
      </OfferForm.Consumer>
    );
  }
}

const updateTermin = gql`
  mutation($id: ID!, $from: DateTime, $to: DateTime) {
    updateTerminPeriod(input: { id: $id, patch: { from: $from, to: $to } }) {
      id
    }
  }
`;

const updateContigent = gql`
  mutation($id: ID!, $typologies_sum: Int) {
    updateContigent(input: { id: $id, patch: { typologies_sum: $typologies_sum } }) {
      id
    }
  }
`;

const deleteTerminPeriod = gql`
  mutation($id: ID!) {
    deleteTerminPeriod(id: $id)
  }
`;

const mapStateToProps = state => {
  const { termin_periods, offer_contract_document_type_id, language_id } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termins: termin_periods,
    create_subtermin,
    edit_subtermin,
    language_id,
    offer_contract_document_type_id,
  };
};

export default compose(
  graphql(updateTermin),
  graphql(updateContigent, { name: "updateContigent" }),
  graphql(deleteTerminPeriod, { name: "deleteTerminPeriod" }),
)(
  connect(
    mapStateToProps,
    { removeTermin, saveEditedContigentTypologySum },
  )(withStyles(styles)(withRouter(AlotmanTerminsList))),
);
