import React, { Component } from "react";
import toastr from "toastr";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { listTerminPeriodStatus } from "../../../../graphql/query/booking";
import { setTerminPeriodStatus } from "../../../../graphql/mutation/booking";
import { terminsFragment } from "../../../../graphql/fragments";
import CommentTextArea from "../../../../components/UI/CommentTextArea";

class StatusDrawer extends Component {
  state = {
    termin_period_status_id: "",
    termin_storno_comment: "",
  };

  changeTerminStatusId = termin_period_status_id => {
    this.setState({ termin_period_status_id });
  };

  changeStorno = termin_storno_comment =>
    this.setState({
      termin_storno_comment,
    });

  componentDidMount = () => {
    this.changeTerminStatusId(this.props.termin_period_status_id);
  };

  dataToSend = () => {
    const { termin_period_status_id, termin_storno_comment } = this.state;

    if (termin_period_status_id === "3" && termin_storno_comment) {
      return {
        termin_period_status_id,
        termin_storno_comment,
      };
    } else {
      return {
        termin_period_status_id,
      };
    }
  };

  updateData = async () => {
    try {
      await this.props.mutate({
        variables: {
          termin_id: this.props.termin.id,
          ...this.dataToSend(),
        },
        update: (store, { data: { setTerminPeriodStatus } }) => {
          store.writeFragment({
            id: `Termin:${setTerminPeriodStatus.id}`,
            fragmentName: "Termin",
            fragment: terminsFragment,
            data: setTerminPeriodStatus,
          });
        },
      });

      toastr.success("Uspješno ažurirano");

      this.props.toggle();
      this.props.fetchInitialQuery();
    } catch (error) {}
  };

  render() {
    const {
      open,
      toggle,
      data: { loading, listTerminPeriodStatus },
    } = this.props;

    const { termin_period_status_id, termin_storno_comment } = this.state;

    if (loading && !listTerminPeriodStatus) {
      return null;
    }

    return (
      <DrawerWrapper toggle={toggle} update={this.updateData} open={open} title="Promjena statusa">
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              label="Status"
              value={termin_period_status_id || ""}
              onChange={({ target: { value } }) => this.changeTerminStatusId(value)}
            >
              {listTerminPeriodStatus &&
                listTerminPeriodStatus.map(type => (
                  <MenuItem key={`key-${type.id}`} value={type.id}>
                    {type.desc}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {termin_period_status_id === "3" ? (
            <Grid item xs={12}>
              <CommentTextArea
                defaultValue={termin_storno_comment}
                label="Storno termina"
                customOnChange={({ target: { value } }) => this.changeStorno(value)}
              />
            </Grid>
          ) : null}
        </Grid>
      </DrawerWrapper>
    );
  }
}

export default compose(graphql(setTerminPeriodStatus), graphql(listTerminPeriodStatus))(StatusDrawer);
