import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { updateTerminPeriod, updateBooking } from "../../../graphql/mutation/booking";

export const BookingModalsContext = React.createContext();

class BookingModalsHandler extends Component {
  state = {
    termin: {},
    statusDrawer: false,
    sellingsDrawer: false,
    guideDriverDrawer: false,
    establishmentDrawer: false,
    terminsDrawer: false,
    deliveredStateDrawer: false,
    remarkDrawer: false,
    receiptDrawer: false,
    notificationStateDrawer: false,
    transportDrawer: false,
    calcContrDrawer: false,
    expanded: {},
  };

  componentDidUpdate() {
    // Doing this so that I could update calculator if new is made
    const newTermin = this.props.dataToShow().find(termin => {
      if (termin && this.state.termin) {
        return String(termin.id) === this.state.termin.id;
      }
      return false;
    });

    if (
      newTermin &&
      newTermin.price_structure &&
      newTermin.price_structure.calculator !== this.state.termin.price_structure.calculator
    ) {
      this.setState({
        termin: newTermin,
      });
    }
  }

  componentDidMount() {
    // set all rows as default expanded, currently there can only be max 100 rows per page
    let expanded = {};

    for (let i = 0; i < 100; i++) {
      expanded = { ...expanded, [i]: true };
    }

    this.setState({ expanded: expanded });
  }

  resetExpander = () => {
    Object.keys(this.state.expanded).length && this.setState({ expanded: {} });
  };

  //Reducer for opening drawers
  reducerCheck = (type, data) => {
    switch (type) {
      case "guide":
        return this.toggleGuideDriverDrawer(data);
      case "driver":
        return this.toggleGuideDriverDrawer(data);
      case "driver_guide_comment":
        return this.toggleGuideDriverDrawer(data);
      case "booking_status":
        return this.toggleStatusDrawer(data);
      case "from":
        return this.toggleTerminsDrawer(data);
      case "to":
        return this.toggleTerminsDrawer(data);
      case "establishment":
        return this.toggleEstablishmentDrawer(data);
      case "establishment_chain":
        return this.toggleEstablishmentDrawer(data);
      case "city":
        return this.toggleEstablishmentDrawer(data);
      case "contigents_list":
        return this.toggleEstablishmentDrawer(data);
      case "persons_capacity_sold":
        return this.toggleSellingsDrawer(data);
      case "delivered_state":
        return this.toggleDeliveredStateDrawer(data);
      case "remark":
        return this.toggleRemarkDrawer(data);
      case "receipt":
        return this.toggleReceiptDrawer(data);
      case "notification_booking_state_date":
        return this.toggleNotificationStateDrawer(data);
      case "transport":
        return this.toggleTransportDrawer(data);
      case "booking_external_id":
        return this.toggleCalcContrDrawer(data);
      case "nr_of_buses":
        return this.toggleNumberOfBussesDrawer(data);
      default:
        return;
    }
  };

  toggleStatusDrawer = termin => {
    this.setState(prevState => {
      return {
        termin,
        statusDrawer: !prevState.statusDrawer,
        ...this.closeOtherToggles("statusDrawer"),
      };
    });
  };

  toggleNotificationStateDrawer = termin => {
    this.setState(prevState => {
      return {
        termin,
        notificationStateDrawer: !prevState.notificationStateDrawer,
        ...this.closeOtherToggles("notificationStateDrawer"),
      };
    });
  };

  toggleTransportDrawer = termin => {
    this.setState(prevState => {
      return {
        termin,
        transportDrawer: !prevState.transportDrawer,
        ...this.closeOtherToggles("transportDrawer"),
      };
    });
  };

  toggleCalcContrDrawer = termin => {
    this.setState(prevState => {
      return {
        termin,
        calcContrDrawer: !prevState.calcContrDrawer,
        ...this.closeOtherToggles("calcContrDrawer"),
      };
    });
  };

  toggleSellingsDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.sellingsDrawer) {
        termin = { ...editTermin };
      }

      return {
        sellingsDrawer: !prevState.sellingsDrawer,
        termin,
        ...this.closeOtherToggles("sellingsDrawer"),
      };
    });
  };

  toggleGuideDriverDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.guideDriverDrawer) {
        termin = { ...editTermin };
      }

      return {
        guideDriverDrawer: !prevState.guideDriverDrawer,
        termin,
        ...this.closeOtherToggles("guideDriverDrawer"),
      };
    });
  };

  toggleEstablishmentDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.establishmentDrawer) {
        termin = { ...editTermin };
      }

      return {
        establishmentDrawer: !prevState.establishmentDrawer,
        termin,
        ...this.closeOtherToggles("establishmentDrawer"),
      };
    });
  };

  toggleTerminsDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.terminsDrawer) {
        termin = { ...editTermin };
      }

      return {
        terminsDrawer: !prevState.terminsDrawer,
        termin,
        ...this.closeOtherToggles("terminsDrawer"),
      };
    });
  };

  toggleDeliveredStateDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.deliveredStateDrawer) {
        termin = { ...editTermin };
      }

      return {
        deliveredStateDrawer: !prevState.deliveredStateDrawer,
        termin,
        ...this.closeOtherToggles("deliveredStateDrawer"),
      };
    });
  };

  toggleRemarkDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.remarkDrawer) {
        termin = { ...editTermin };
      }

      return {
        remarkDrawer: !prevState.remarkDrawer,
        termin,
        ...this.closeOtherToggles("remarkDrawer"),
      };
    });
  };

  toggleReceiptDrawer = editTermin => {
    this.setState(prevState => {
      let termin = {};

      if (!prevState.receiptDrawer) {
        termin = { ...editTermin };
      }

      return {
        receiptDrawer: !prevState.receiptDrawer,
        termin,
        ...this.closeOtherToggles("receiptDrawer"),
      };
    });
  };

  toggleNumberOfBussesDrawer = termin => {
    this.setState(prevState => {
      return {
        termin,
        numberOfBussesDrawer: !prevState.numberOfBussesDrawer,
        ...this.closeOtherToggles("numberOfBussesDrawer"),
      };
    });
  };

  closeOtherToggles(toggling) {
    let obj = {};
    Object.keys(this.state).forEach(key => {
      if (toggling !== key && key !== "termin" && key !== "booking" && key !== "expanded") {
        obj = {
          ...obj,
          [key]: false,
        };
      }
    });

    return obj;
  }

  paxNumber = contigents => {
    let initialValue = 0;

    let sum = contigents.reduce(function(accumulator, contigent) {
      return accumulator + contigent.typologies_sum * contigent.typology.persons_capacity;
    }, initialValue);

    return sum;
  };

  changeContractSegment = async (booking_id, value) => {
    try {
      await this.props.updateBooking({
        variables: {
          id: booking_id,
          patch: {
            offer_contract_document_segment_id: value,
          },
        },
      });
    } catch (error) {}
  };

  changeContacted = async (id, contacted) => {
    try {
      await this.props.updateTermin({
        variables: {
          id: id,
          patch: {
            contacted,
          },
        },
      });
    } catch (error) {}
  };

  changeBookingOptionDescription = async (id, value) => {
    try {
      await this.props.updateTermin({
        variables: {
          id,
          patch: {
            booking_option_description_id: value,
          },
        },
      });
    } catch (error) {}
  };

  changeExpandedRows = (newExpanded, index) => {
    const expandedToSet = {
      ...this.state.expanded, // keep other rows
      [index[0]]: !this.state.expanded[index[0]], // toggle clicked row
    };

    this.setState({ expanded: expandedToSet });
  };

  getStateAndActions = () => ({
    ...this.state,
    changeContractSegment: this.changeContractSegment,
    changeBookingOptionDescription: this.changeBookingOptionDescription,
    changeContacted: this.changeContacted,
    changeExpandedRows: this.changeExpandedRows,
    toggleGuideDriverDrawer: this.toggleGuideDriverDrawer,
    toggleDeliveredStateDrawer: this.toggleDeliveredStateDrawer,
    toggleEstablishmentDrawer: this.toggleEstablishmentDrawer,
    toggleReceiptDrawer: this.toggleReceiptDrawer,
    toggleRemarkDrawer: this.toggleRemarkDrawer,
    toggleSellingsDrawer: this.toggleSellingsDrawer,
    toggleStatusDrawer: this.toggleStatusDrawer,
    toggleTerminsDrawer: this.toggleTerminsDrawer,
    toggleNotificationStateDrawer: this.toggleNotificationStateDrawer,
    toggleTransportDrawer: this.toggleTransportDrawer,
    toggleCalcContrDrawer: this.toggleCalcContrDrawer,
    toggleNumberOfBussesDrawer: this.toggleNumberOfBussesDrawer,
    reducerCheck: this.reducerCheck,
  });

  render() {
    return (
      <React.Fragment>
        <BookingModalsContext.Provider
          value={{ ...this.getStateAndActions(), fetchInitialQuery: this.props.fetchInitialQuery }}
        >
          {this.props.children}
        </BookingModalsContext.Provider>
      </React.Fragment>
    );
  }
}

export default compose(
  graphql(updateTerminPeriod, { name: "updateTermin" }),
  graphql(updateBooking, { name: "updateBooking" }),
)(BookingModalsHandler);
