import React from "react";

//ui
import { AppBar, IconButton, Dialog, Slide, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// hooks
import useAnnouncementEdit from "./hooks/useAnnouncementEdit";

// components
import { TITLE_GUIDE_ANNOUNCEMENT_EDIT } from "src/utility/labels";
import AnnouncementEditContainer from "./components/Container";

// dialog transition
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

/**
 * AnnouncementEdit
 */
export default function AnnouncementEdit() {
  // hooks
  const { handleOnDrawerClose, isAnnouncementDrawerOpen } = useAnnouncementEdit();

  return (
    <Dialog
      fullScreen
      open={isAnnouncementDrawerOpen || false}
      onClose={handleOnDrawerClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#eeeeee",
        },
      }}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="title" color="inherit">
            {TITLE_GUIDE_ANNOUNCEMENT_EDIT}
          </Typography>

          <IconButton style={{ marginLeft: "auto" }} color="inherit" onClick={handleOnDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AnnouncementEditContainer />
    </Dialog>
  );
}
