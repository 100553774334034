import gql from "graphql-tag";
import { terminsFragment, mainTerminFragment, contigentFragment, fixContigentFragment } from "../fragments";

export const updateOfferContractDocument = gql`
  mutation($id: ID!, $patch: UpdateOfferContractDocument!) {
    updateOfferContractDocument(input: { id: $id, patch: $patch }) {
      id
      guide
      driver
      driver_guide_comment
    }
  }
`;

export const updateBooking = gql`
  mutation($id: ID!, $patch: UpdateBooking!) {
    updateBooking(input: { id: $id, patch: $patch }) {
      id
      contract_status_id
      contract_status {
        id
        desc
        code
        __typename
      }
      offer_contract_document {
        id
        offer_contract_document_segment_id
        offer_contract_document_segment {
          id
          desc
          code
        }
      }
      storno_comment
      booking_document {
        id
        contacted
        termin_periods {
          id
          from
          to
          subtermin_periods {
            id
            from
            to
          }
        }
        __typename
      }

      __typename
    }
  }
`;

export const updateContigent = gql`
  mutation($id: ID!, $patch: UpdateContigent!) {
    updateContigent(input: { id: $id, patch: $patch }) {
      ...Contigent
    }
  }
  ${contigentFragment}
`;

export const updateFixContigent = gql`
  mutation($id: ID!, $sold: Int) {
    updateFixContigent(input: { id: $id, patch: { sold: $sold } }) {
      ...FixContigent
    }
  }
  ${fixContigentFragment}
`;

export const deleteContigent = gql`
  mutation($id: ID!) {
    deleteContigent(id: $id)
  }
`;

export const createContigent = gql`
  mutation($typologies_sum: Int!, $termin_period_id: ID, $typology_id: ID!) {
    createContigent(
      input: { typologies_sum: $typologies_sum, termin_period_id: $termin_period_id, typology_id: $typology_id }
    ) {
      ...Contigent
    }
  }
  ${contigentFragment}
`;

export const updateTerminPeriod = gql`
  mutation($id: ID!, $patch: UpdateTerminPeriod!) {
    updateTerminPeriod(input: { id: $id, patch: $patch }) {
      ...MainTermin
      termin_period_status_id
      nr_of_buses
      termin_period_status {
        id
        desc
      }
      subtermin_periods {
        ...Termin
        termin_period_status_id
        termin_period_status {
          id
          desc
        }
        contigents {
          ...Contigent
        }
      }
      contigents {
        ...Contigent
      }
    }
  }
  ${terminsFragment}
  ${contigentFragment}
  ${mainTerminFragment}
`;

export const setTerminPeriodStatus = gql`
  mutation($termin_id: ID!, $termin_period_status_id: ID!) {
    setTerminPeriodStatus(termin_id: $termin_id, termin_period_status_id: $termin_period_status_id) {
      ...MainTermin
      termin_period_status_id
      termin_period_status {
        id
        desc
      }
      subtermin_periods {
        ...Termin
        termin_period_status_id
        termin_period_status {
          id
          desc
        }
        contigents {
          ...Contigent
        }
      }
      contigents {
        ...Contigent
      }
    }
  }
  ${terminsFragment}
  ${contigentFragment}
  ${mainTerminFragment}
`;
