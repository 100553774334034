// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_SUPPLIER_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateSupplierAnnouncement is used for createHotelNoGuideAnnouncement mutation
 */
export default function useCreateSupplierAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createSupplierAnnouncement] = useMutation(CREATE_SUPPLIER_ANNOUNCEMENT);

  const handleCreateSupplierAnnouncement = async languageSelected => {
    const createAnnouncementResponse = await createSupplierAnnouncement({
      variables: {
        input: {
          program_id: programId,
          guide_id: supplier.guide_id,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createSupplierAnnouncement");
  };

  return {
    handleCreateSupplierAnnouncement,
  };
}
