import React from "react";

import { Tabs, Tab } from "material-ui/Tabs";
import PartnerTabsHOC from "./PartnerTabsHOC";

class PartnerTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = value => {
    this.setState({ value: value });
  };

  render() {
    const { showInTabPartner, showInTabPartnerType } = this.props;

    return (
      <Tabs value={this.state.value} onChange={this.handleChange}>
        <Tab label="Partner" value={0}>
          <div className="form">{showInTabPartner()}</div>
        </Tab>
        <Tab label="Tip partnera" value={1}>
          <div className="form">{showInTabPartnerType()}</div>
        </Tab>
      </Tabs>
    );
  }
}

export default PartnerTabsHOC(PartnerTabs);
