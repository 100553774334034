import { guidIdGenerator } from "../offer/helpers";

export default {
  establishment_chain: {
    name: "",
    city_id: "",
  },
  establishment: {
    name: "",
    web: "",
    address1: "",
    address2: "",
    email: "",
    phone: "",
    fax: "",
    oib: "",
    contact_person1: "",
    contact_person2: "",
    rating: "",
    breakfast_and_dinner_times: "",
    breakfast_and_dinner_buffet_or_menu: "",
    currency_exchange: "",
    currency_exchange_comment: "",
    parking: "",
    parking_comment: "",
    wellnes: "",
    wellnes_comment: "",
    pools: "",
    pools_comment: "",
    typologies: [],
    city_id: "",
    establishment_legal_entity_id: "",
    establishment_chain_id: "",
  },
  service: {
    name: "",
    service_type_id: "",
  },
  service_type: {
    desc: "",
    code: "",
  },
  activity: {
    name: "",
    activity_type_id: "",
  },
  activity_type: {
    desc: "",
    code: "",
  },
  typology: {
    id: guidIdGenerator(),
    code: "",
    establishment_code: "",
    persons_capacity: "",
    priority: "0",
    establishment_id: "",
    typology_translations: [],
    typology_group_id: "",
  },
  user: {
    first_name: "",
    last_name: "",
    username: "",
    signature_url: "",
    password: "",
    role_ids: ["1"],
  },
  partner_client_name: "",
  partner_client_phone: "",
  partner_client_email: "",
  partner_client: {
    name: "", //String!
    contact_persons: [], // [String]
    phones: [], // [String]
    fax: "", // String
    address: "", // String
    emails: [], //[String]
    url: "", //String
    service_tickets: [], //[String]
    service_foods: [], // [String]
    services: [], // [String]
    comments: "", //String
    oib: "", //Float
    partner_client_type_ids: [], //[ID!]
    city_ids: [], // [ID]
  },
};
