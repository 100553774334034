import React from "react";
import { Switch, Route } from "react-router-dom";

// components
import AnnouncementTemplateContainer from "./List";
import EditAnnouncementTemplate from "./Edit";

const TemplateAnnouncementRouter = () => {
  return (
    <Switch>
      <Route path="/administracija/najava_predlozak/" exact component={AnnouncementTemplateContainer} />
      <Route path="/administracija/najava_predlozak/edit/:id" exact component={EditAnnouncementTemplate} />
    </Switch>
  );
};

export default TemplateAnnouncementRouter;
