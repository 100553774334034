// libs
import { useSelector } from "react-redux";

// redux state
import { selectGuideScheduleRowExpanded } from "src/views/Guides/guidesSlice";

/**
 * useActivities is entry point of data for activities column component
 */
export default function useActivities(programId) {
  // redux state
  const guidesRowExpanded = useSelector(selectGuideScheduleRowExpanded);

  const expanded = !!guidesRowExpanded[programId];

  return {
    expanded,
  };
}
