import React, { Component } from "react";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import toastr from "toastr";
import _ from "lodash";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import ReactTableCustom from "../../../../components/UI/ReactTable";
import { establishmentContigentsAndHotels } from "../../../../utility/establishment";

import {
  changeEditData,
  removeTermin,
  saveEditedTermin,
  removeToCreateServiceType,
  removeCreatedServiceType,
} from "../../../../store/actions";

import { deleteOfferContractDocumentServiceType } from "../../../../graphql/mutation/offers";

import { dateDiff, formatDate, getMomentFromString } from "../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import DateRangePicker from "../../../../components/UI/DateRangePicker";
import { config } from "../../../../utility/globals";
import OfferForm from "../OfferForm";
import { withRouter } from "react-router-dom";
import { prepareForSending } from "../../../../utility/prepare";

const updateTermin = gql`
  mutation($id: ID!, $from: DateTime, $to: DateTime) {
    updateTerminPeriod(input: { id: $id, patch: { from: $from, to: $to } }) {
      id
    }
  }
`;

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class TerminsList extends Component {
  state = {
    terminToEdit: null,
    oldTermin: null,
    showTypeModal: false,
    terminToDeleteId: null,
    serviceToCreateToDelete: [],
    serviceCreatedToDelete: [],
  };

  openToEdit = (expanded, index) => {
    let termin = this.dataToShow()[index[0]];
    termin = {
      ...termin,
      // from: moment(termin.from, "DD-MM-YYYY"),
      // to: moment(termin.to, "DD-MM-YYYY"),
    };

    if (!this.state.expanded) {
      this.setState({
        expanded,
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
      });
    } else {
      this.setState({
        expanded: {
          [index[0]]: !this.state.expanded[index[0]],
        },
        terminToEdit: { ...termin },
        oldTermin: { ...termin },
      });
    }
  };

  changeTerminFrom = from => {
    if (from) {
      this.setState(prev => ({
        terminToEdit: {
          ...prev.terminToEdit,
          from,
        },
      }));
    }
  };

  changeTerminTo = to => {
    if (to) {
      this.setState(prev => ({
        terminToEdit: {
          ...prev.terminToEdit,
          to,
        },
      }));
    }
  };

  saveChanges = async () => {
    const { terminToEdit, oldTermin } = this.state;
    const terminToEditCpy = prepareForSending(terminToEdit, [
      "diff",
      "contigents_list",
      "establishment_name",
      "__typename",
    ]);
    const oldTerminCpy = prepareForSending(oldTermin, ["diff", "establishment_name", "contigents_list", "__typename"]);

    if (terminToEdit.id.includes("-")) {
      this.props.saveEditedTermin(terminToEditCpy, oldTerminCpy);

      this.setState({
        expanded: {},
        terminToEdit: null,
        oldTermin: null,
      });
    } else {
      try {
        this.props.mutate({
          variables: {
            id: terminToEditCpy.id,
            from: terminToEditCpy.from,
            to: terminToEditCpy.to,
          },
        });

        this.props.saveEditedTermin(terminToEditCpy, oldTerminCpy);

        this.setState({
          expanded: {},
          terminToEdit: null,
          oldTermin: null,
        });

        toastr.success("Termin uspješno ažuriran");
      } catch (error) {}
    }
  };

  dataToShow = () => {
    return [...this.props.termins]
      .sort(function(a, b) {
        return new Date(a.from) - new Date(b.from);
      })
      .map(termin => {
        const { contigents } = termin;
        const {
          establishment_names: establishment_name,
          contigent_lists: contigents_list,
        } = establishmentContigentsAndHotels(contigents);

        return {
          ...termin,
          diff: dateDiff(termin.from, termin.to, "day"),
          contigents_list,
          establishment_name,
        };
      });
  };

  /**
   *  check if there's connected created service types in this termin period
   */
  getConnectedServicesTypesCreated = terminForDelete => {
    const { offer_contract_document_services, termins } = this.props;

    let servicesToDelete = [];

    if (offer_contract_document_services) {
      // check for created serviceTypes
      const serviceTypesToCreateOverlaping = offer_contract_document_services.filter(ocds => {
        return (
          (getMomentFromString(ocds.service_from).isSameOrAfter(getMomentFromString(terminForDelete.from)) &&
            getMomentFromString(ocds.service_from).isSameOrBefore(getMomentFromString(terminForDelete.to))) ||
          (getMomentFromString(ocds.service_to).isSameOrAfter(getMomentFromString(terminForDelete.from)) &&
            getMomentFromString(ocds.service_to).isSameOrBefore(getMomentFromString(terminForDelete.to)))
        );
      });

      if (serviceTypesToCreateOverlaping) {
        serviceTypesToCreateOverlaping.map(async service => {
          // find termins with dates that could handle found service
          const ter = termins.filter(termin => {
            return (
              termin.id !== terminForDelete.id &&
              getMomentFromString(termin.from).isSameOrBefore(getMomentFromString(service.service_from)) &&
              getMomentFromString(termin.to).isSameOrAfter(getMomentFromString(service.service_to))
            );
          });

          if (!ter.length) {
            //briši servis
            servicesToDelete = [...servicesToDelete, service.id];
          }
          return;
        });
      }
    }
    return servicesToDelete;
  };

  /**
   *  check if there's connected to create service types in this termin period
   */
  getConnectedServicesTypesToCreate = terminForDelete => {
    const { service_objects_to_create, termins } = this.props;

    let servicesToDelete = [];

    if (service_objects_to_create) {
      // check for created serviceTypes
      const serviceTypesToCreateOverlaping = service_objects_to_create.filter(ocds => {
        return (
          (getMomentFromString(ocds.service_from).isSameOrAfter(getMomentFromString(terminForDelete.from)) &&
            getMomentFromString(ocds.service_from).isSameOrBefore(getMomentFromString(terminForDelete.to))) ||
          (getMomentFromString(ocds.service_to).isSameOrAfter(getMomentFromString(terminForDelete.from)) &&
            getMomentFromString(ocds.service_to).isSameOrBefore(getMomentFromString(terminForDelete.to)))
        );
      });

      if (serviceTypesToCreateOverlaping) {
        serviceTypesToCreateOverlaping.map(async service => {
          // find termins with dates that could handle found service
          const ter = termins.filter(termin => {
            return (
              termin.id !== terminForDelete.id &&
              getMomentFromString(termin.from).isSameOrBefore(getMomentFromString(service.service_from)) &&
              getMomentFromString(termin.to).isSameOrAfter(getMomentFromString(service.service_to))
            );
          });

          if (!ter.length) {
            //briši servis
            servicesToDelete = [...servicesToDelete, service.id];
          }
          return;
        });
      }
    }
    return servicesToDelete;
  };

  /**
   * handle termin delete, check for connected serviceTypes
   */
  handleDeleteTermin = terminToDelete => {
    // check if there's connected to create service types to delete
    let servicesToCreateToDelete = this.getConnectedServicesTypesToCreate(terminToDelete);

    // check if there's connected created service types to delete
    let servicesCreatedToDelete = this.getConnectedServicesTypesCreated(terminToDelete);

    // check if there's connected serviceType with termin periods
    if (servicesToCreateToDelete.length || servicesCreatedToDelete.length) {
      // show dialog
      this.setState({
        showTypeModal: true,
        terminToDeleteId: terminToDelete.id,
        servicesToCreateToDelete: servicesToCreateToDelete,
        servicesCreatedToDelete: servicesCreatedToDelete,
      });
    } else {
      this.deleteTermin(terminToDelete.id);
    }
  };

  /**
   * delete termin
   */
  deleteTermin = async terminId => {
    // delete termin normally
    // if we're in edit mode
    if (this.props.match.params.id) {
      try {
        const response = await this.props.deleteTerminPeriod({
          variables: {
            id: terminId,
          },
        });
        toastr.success("Uspješno izbrisan termin");
        this.props.removeTermin(response.data.deleteTerminPeriod);
      } catch (error) {
        toastr.error("Greška prilikom brisanja termina");
      }
    } else {
      this.props.removeTermin(terminId);
    }
  };
  /**
   * dialog handle close change
   */
  handleCloseChangeType = () =>
    this.setState({
      showTypeModal: false,
      terminToDeleteId: null,
      servicesToCreateToDelete: [],
      servicesCreatedToDelete: [],
    });

  /**
   * dialog handle close change
   */
  handleAcceptChangeType = async () => {
    // delete all termins and connected document_services
    try {
      const { terminToDeleteId, servicesToCreateToDelete, servicesCreatedToDelete } = this.state;
      const { removeToCreateServiceType, removeCreatedServiceType } = this.props;

      // delete to create service types
      if (servicesToCreateToDelete.length) {
        for (let id of servicesToCreateToDelete) {
          removeToCreateServiceType(id);
        }
      }

      // delete to create service types
      if (servicesCreatedToDelete.length) {
        await Promise.all(
          servicesCreatedToDelete.map(async serviceToDeleteId => {
            const response = await this.props.mutationDeleteCreatedServiceType({
              variables: {
                id: serviceToDeleteId,
              },
            });
            // get service type from response
            const serviceTypeDeleteIdResponse = _.get(response, "data.deleteOfferContractDocumentServiceType", null);

            if (serviceTypeDeleteIdResponse) {
              removeCreatedServiceType(serviceTypeDeleteIdResponse);
            }
          }),
        );
        if (servicesCreatedToDelete.length > 1) {
          toastr.success("Usluge uspješno izbrisane");
        } else {
          toastr.success("Usluga uspješno izbrisana");
        }
      }

      // delete termin period
      this.deleteTermin(terminToDeleteId);

      this.setState({
        showTypeModal: false,
        terminToDeleteId: null,
        servicesToCreateToDelete: [],
        servicesCreatedToDelete: [],
      });
    } catch (error) {
      toastr.error("Greška prilikom brisanja termina i osnovnih usluga");
    }
  };

  /**
   * dialog handle close change
   */
  render() {
    const { offer_contract_document_type_id, isNotDraft } = this.props;

    if (config.offer_type[offer_contract_document_type_id.toString()] === "Tour") {
      return null;
    }

    const { terminToEdit } = this.state;

    return (
      <OfferForm.Consumer>
        {({ error, errorMsg }) => (
          <div className="cols-2">
            <div className="form-section-one">
              {error("/termin_periods") && (
                <FormHelperText error={error("/termin_periods")}>{errorMsg("/termin_periods")}</FormHelperText>
              )}

              {this.state.showTypeModal ? (
                <div>
                  <Dialog
                    open={this.state.showTypeModal}
                    onClose={this.handleCloseChangeType}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Brisanjem termina izbrisat će se povezane osnovne usluge koje imaju datum unutar datuma termina
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">Želite li izbrisati termin?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleCloseChangeType} color="primary">
                        Odustani
                      </Button>
                      <Button onClick={this.handleAcceptChangeType} color="primary" autoFocus>
                        Prihvati
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : null}

              <Paper elevation={1} className={error("/termin_periods") ? "textarea-error" : ""}>
                <ReactTableCustom
                  data={this.dataToShow()}
                  columns={[
                    {
                      Header: "Edit",
                      columns: [
                        {
                          expander: true,
                          Header: () => <strong>More</strong>,
                          width: 65,

                          Expander: ({ isExpanded, ...rest }) => {
                            return !isNotDraft ? (
                              <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                            ) : null;
                          },
                          style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none",
                          },
                        },
                      ],
                    },
                    {
                      Header: "TERMINI",
                      columns: [
                        {
                          Header: "Od",
                          id: "from",
                          accessor: d => formatDate(d.from),
                        },
                        {
                          Header: "Do",
                          id: "to",
                          accessor: d => formatDate(d.to),
                        },
                        {
                          Header: "Dana",
                          accessor: "diff",
                          width: 70,
                        },
                        {
                          Header: "Kontigent",
                          id: "contigents_list",
                          accessor: d => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "0 auto",
                              }}
                            >
                              {d.contigents_list}
                            </div>
                          ),
                          width: 150,
                        },
                        {
                          Header: "Smještaj",
                          id: "establishment_name",
                          accessor: d => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "0 auto",
                              }}
                            >
                              {d.establishment_name}
                            </div>
                          ),
                        },
                        {
                          Header: "Brisanje",
                          id: "delete",
                          accessor: termin =>
                            !isNotDraft ? (
                              <DeleteForeverIcon
                                className={this.props.classes.icon}
                                onClick={() => this.handleDeleteTermin(termin)}
                              />
                            ) : null,
                        },
                      ],
                    },
                  ]}
                  innerProps={{
                    pageSize: this.dataToShow().length,
                    showPagination: false,
                    sortable: false,
                    filterable: false,
                    filterAll: false,
                    expanded: this.state.expanded,
                    onExpandedChange: this.openToEdit,
                    SubComponent: () => {
                      if (!terminToEdit) {
                        return "";
                      }

                      return (
                        <div style={{ padding: "12px" }}>
                          <DateRangePicker
                            from={getMomentFromString(terminToEdit.from)}
                            to={getMomentFromString(terminToEdit.to)}
                            handleFromChange={this.changeTerminFrom}
                            handleToChange={this.changeTerminTo}
                            appendToBody
                          />
                          <div className="subtermin-create-buttons">
                            <Button variant="contained" color="primary" onClick={this.saveChanges}>
                              Spremi
                            </Button>
                          </div>
                        </div>
                      );
                    },
                  }}
                />
              </Paper>
            </div>
          </div>
        )}
      </OfferForm.Consumer>
    );
  }
}

const deleteTerminPeriod = gql`
  mutation($id: ID!) {
    deleteTerminPeriod(id: $id)
  }
`;

const mapStateToProps = state => {
  const {
    termin_periods,
    offer_contract_document_type_id,
    language_id,
    service_objects_to_create,
    offer_contract_document_services,
  } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termins: termin_periods,
    create_subtermin,
    edit_subtermin,
    language_id,
    offer_contract_document_type_id,
    service_objects_to_create,
    offer_contract_document_services,
  };
};

export default compose(
  graphql(updateTermin),
  graphql(deleteTerminPeriod, { name: "deleteTerminPeriod" }),
  graphql(deleteOfferContractDocumentServiceType, { name: "mutationDeleteCreatedServiceType" }),
)(
  connect(
    mapStateToProps,
    {
      changeEditData,
      removeTermin,
      saveEditedTermin,
      removeToCreateServiceType,
      removeCreatedServiceType,
    },
  )(withStyles(styles)(withRouter(TerminsList))),
);
