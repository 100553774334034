import React from "react";
import Tooltip from "src/components/UI/Tooltip";

export const tooltipCell = translation => {
  return (
    <Tooltip title={translation || " "}>
      <div className="ReactTableFirstColumnDiv">{translation || ""}</div>
    </Tooltip>
  );
};
