import React from "react";

// hooks
import useAnnouncement from "./hooks/useAnnouncement";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import Container from "./components/Container";

// constants
import { TITLE_GUIDE_ANNOUNCEMENT } from "src/utility/labels";

/**
 * Announcement drawer is used for showing data for guides announcements
 */
export default function Announcement() {
  const { isDrawerOpen, onCloseDrawer } = useAnnouncement();

  return (
    <DrawerWrapper open={isDrawerOpen} toggle={onCloseDrawer} title={TITLE_GUIDE_ANNOUNCEMENT} customWidth="85%">
      <Container />
    </DrawerWrapper>
  );
}
