import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import decode from "jwt-decode";

import Dashboard from "./containers/Dashboard";
import Login from "./containers/Login";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import PreviewOffer from "./views/Offers/OfferForm/PreviewOffer/PreviewOffer";
import PreviewContract from "./views/Contract/ContractForm/PreviewContract/PreviewContract";
import PreviewVoucher from "./components/Preview/PreviewVoucher";

const isAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    const t = decode(token);
    const { exp } = t;
    const currentTime = new Date().getTime() / 1000;

    // Token expired
    if (exp < currentTime) {
      localStorage.removeItem("token");
      return false;
    }
  } catch (err) {
    // Not valid token
    localStorage.removeItem("token");
    return false;
  }

  return true;
};

const RouteMiddleware = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);

const LoginRouteMiddleware = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

const App = () => (
  <MuiThemeProvider>
    <Switch>
      <LoginRouteMiddleware path="/login" exact component={Login} />
      <RouteMiddleware path="/ponude/pregled" exact component={PreviewOffer} />
      <RouteMiddleware path="/ugovor/pregled/:id" exact component={PreviewContract} />
      <RouteMiddleware path="/voucher" exact component={PreviewVoucher} />
      <RouteMiddleware path="/" component={Dashboard} />;
    </Switch>
  </MuiThemeProvider>
);

export default App;
