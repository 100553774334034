import React, { Component } from "react";
import debounce from "lodash/debounce";

import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";

import DeleteIcon from "material-ui/svg-icons/action/delete";
import AddIcon from "material-ui/svg-icons/content/add-circle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import toastr from "toastr";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import DrawerWrapper from "../../../../components/UI/Drawer";

import { Card, CardHeader, CardText } from "material-ui/Card";

import {
  getEstablishmentById,
  sortTypologies,
  sortContigentsByTypologyPriorityWithoutListEstablishment,
} from "../../../../utility/establishment";

import { updateContigent, deleteContigent, createContigent } from "../../../../graphql/mutation/booking";

import { guidIdGenerator } from "../../../../store/reducers/offer/helpers";
import { listEstablishment } from "../../../../graphql/query/administration";
import { removeDuplicateObjects } from "../../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";
import get from "lodash/get";

class EstablishmentDrawer extends Component {
  state = {
    contigents: "",
    establishments: [],
    fetchingMore: false,
  };

  contigentsClone = () => {
    const { contigents } = this.state;
    return [...contigents];
  };

  contigentCopy = id => {
    const contigents_clone = this.contigentsClone();
    const contigentIndex = contigents_clone.findIndex(cont => cont.id === id);
    return { ...contigents_clone[contigentIndex] };
  };

  contigentSave = (id, contigent) => {
    const contigents_clone = this.contigentsClone();
    const contigentIndex = contigents_clone.findIndex(cont => cont.id === id);

    contigents_clone[contigentIndex] = contigent;

    this.setState({
      contigents: contigents_clone.map(c => ({ ...c })),
    });
  };

  establishmentChange = (value, id) => {
    const { establishments } = this.state;

    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    const establishment = getEstablishmentById(value, listEstablishment);

    const contigent = this.contigentCopy(id);

    contigent["establishment_id"] = value;
    contigent["typology_id"] = "";
    contigent["typologies"] = establishment ? establishment.typologies : [];

    this.setState({ establishments: establishments.concat(establishment) });
    this.contigentSave(id, contigent);
  };

  typologyChange = (value, id) => {
    const contigent = this.contigentCopy(id);
    contigent["typology_id"] = value;
    contigent["typology"] = contigent.typologies.find(typo => typo.id === value);
    contigent["errorTypologyId"] = this.isFieldInvalidNumeric(value);
    this.contigentSave(id, contigent);
  };

  typologySumChange = (value, id) => {
    const contigent = this.contigentCopy(id);
    contigent["typologies_sum"] = value;
    contigent["errorTypologySum"] = this.isFieldInvalidNumeric(value);
    this.contigentSave(id, contigent);
  };

  addNewContigent = () => {
    const contigents = this.contigentsClone();

    contigents.push({
      id: guidIdGenerator(),
      typologies_sum: "",
      typology_id: "",
      termin_period_id: contigents[0].termin_period_id,
    });

    // user (probably) intends to add another contigent from not yet
    // displayed hotel, so fetch (partial) hotel list now
    this.fetchEstablishments();

    this.setState({ contigents });
  };

  addNewContigentWithFilledEstablishment = establishment_id => {
    const contigents = this.contigentsClone();
    const establishment = getEstablishmentById(establishment_id, this.state.establishments);

    contigents.push({
      id: guidIdGenerator(),
      establishment_id: establishment_id,
      typologies_sum: "",
      typology_id: "",
      typologies: establishment ? establishment.typologies : [],
      termin_period_id: contigents[0].termin_period_id,
    });

    this.setState({ contigents });
  };

  deleteContigentFromContigents = id => {
    const contigents = this.contigentsClone();

    const contigents_clone = contigents.filter(contigent => contigent.id !== id);

    this.setState({
      contigents: contigents_clone.map(c => ({ ...c })),
      contigents_clone,
    });
  };

  deleteContigent = async id => {
    const { contigents } = this.state;

    const index = contigents.findIndex(contigent => contigent.id === id);

    // If user is deleting empty contigent, one which is not sent to server
    if (contigents[index] && (!contigents[index].id || contigents[index].id.includes("-"))) {
      contigents.splice(index, 1);
      return this.setState({
        contigents,
      });
    }

    // if there are empty contigents
    if (!(contigents[1] && contigents[1].id)) {
      alert("NEĆE BITI KONTIGENTATA");
    } else {
      const { id } = contigents[index];

      try {
        if (window.confirm("Sve promjene koje nisu spremljene, biti će izbrisane. Nastaviti?")) {
          const {
            data: { deleteContigent },
          } = await this.props.deleteContigent({
            variables: {
              id,
            },
            update: () => this.props.refetchLastListBookingQuery(),
          });

          this.deleteContigentFromContigents(deleteContigent);

          toastr.success("Uspješno izbrisan kontigent");
        }
      } catch (e) {}
    }
  };

  prepareContigentToSend = contigent => {
    let obj = {};

    const cloned_contigent = this.state.contigents_clone.find(cont => cont.id === contigent.id);

    if (contigent.typology_id !== cloned_contigent.typology_id) {
      obj["typology_id"] = contigent.typology_id;
    }
    if (contigent.typologies_sum !== cloned_contigent.typologies_sum) {
      obj["typologies_sum"] = contigent.typologies_sum;
    }

    return obj;
  };

  checkIfUpdated = contigent => {
    const { contigents_clone } = this.state;

    const original_contigent = contigents_clone.find(cont => cont.id === contigent.id);

    return (
      original_contigent &&
      contigent.id === original_contigent.id &&
      (contigent.typology_id !== original_contigent.typology_id ||
        contigent.typologies_sum !== original_contigent.typologies_sum)
    );
  };

  updateContigent = async contigent => {
    // if contigent is not new
    if (this.checkIfUpdated(contigent)) {
      try {
        await this.props.updateContigent({
          variables: {
            id: contigent.id,
            patch: this.prepareContigentToSend(contigent),
          },
        });

        this.setState(prev => ({
          contigents_clone: prev.contigents_clone.map(c => {
            if (c.id === contigent.id) {
              return { ...contigent };
            }
            return c;
          }),
        }));

        toastr.success("Uspješno ažurirano");

        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    }
  };

  createNewContigent = async (contigent, id) => {
    try {
      const {
        data: { createContigent },
      } = await this.props.createContigent({
        variables: {
          termin_period_id: contigent.termin_period_id,
          typologies_sum: contigent.typologies_sum,
          typology_id: contigent.typology_id,
        },
        update: () => this.props.refetchLastListBookingQuery(),
      });

      contigent["id"] = createContigent.id;

      this.setState(prev => ({
        contigents_clone: [...prev.contigents_clone, { ...contigent }],
      }));

      this.contigentSave(id, contigent);

      toastr.success("Uspješno kreiran kontigent");

      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };

  updateData = () => {
    const { contigents, contigents_clone } = this.state;

    contigents.forEach(async contigent => {
      const existingContigent = contigents_clone.find(cont => cont.id === contigent.id);

      let invalid = false;
      contigent["errorTypologySum"] = false;
      contigent["errorTypologyId"] = false;
      if (this.isFieldInvalidNumeric(contigent.typologies_sum)) {
        contigent["errorTypologySum"] = true;
        invalid = true;
      }
      if (this.isFieldInvalidNumeric(contigent.typology_id)) {
        contigent["errorTypologyId"] = true;
        invalid = true;
      }

      if (invalid) {
        return;
      }

      if (!existingContigent && contigent.id.includes("-")) {
        await this.createNewContigent({ ...contigent }, contigent.id);
      } else {
        await this.updateContigent({ ...contigent });
      }
    });

    this.setState({ contigents: contigents });
  };

  // store previously queried name here, so we don't repeat same query
  where = { name: "" };
  paginationLimit = {
    limit: PAGINATION_LIMIT,
    offset: PAGINATION_OFFSET,
  };

  fetchEstablishments = async () => {
    this.paginationLimit.limit = PAGINATION_LIMIT;
    this.paginationLimit.offset = PAGINATION_OFFSET;

    await this.props.listEstablishmentQuery.fetchMore({
      variables: {
        input: {
          paginationLimit: this.paginationLimit,
          where: JSON.stringify(this.where),
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listEstablishment.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listEstablishment: [...fetchMoreResult.listEstablishment],
        };
      },
    });

    this.setState({ fetchMore: false });
  };

  handleAutocompleteInputChange = value => {
    if (this.where.name === value) {
      return;
    }

    this.where = { name: value };

    this.setState({ fetchingMore: true }, this.fetchEstablishments);
  };

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    if (
      this.fetchingResults === false &&
      this.hasMoreItems &&
      listEstablishment &&
      listEstablishment.length >= PAGINATION_LIMIT
    ) {
      this.setState({ fetchingMore: true }, async () => {
        this.fetchingResults = true;

        this.paginationLimit.offset = this.paginationLimit.offset + this.paginationLimit.limit;

        await this.props.listEstablishmentQuery.fetchMore({
          variables: {
            input: {
              paginationLimit: this.paginationLimit,
              where: JSON.stringify(this.where),
            },
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listEstablishment.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listEstablishment: [...previousResult.listEstablishment, ...fetchMoreResult.listEstablishment],
            };
          },
        });

        this.setState({ fetchingMore: false });
      });
    }
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

  componentDidMount = () => {
    const { contigents } = this.props;

    let establishments = [];

    const contigents_clone =
      contigents &&
      contigents.map(contigent => {
        establishments.push(contigent.typology.establishment);

        return {
          ...contigent,
          establishment_id: contigent.typology.establishment.id,
          typologies: contigent.typology.establishment.typologies,
        };
      });

    this.setState({
      establishments: removeDuplicateObjects(establishments),
      contigents: [...contigents_clone.map(c => ({ ...c }))],
      contigents_clone,
    });
  };

  isFieldInvalidNumeric = value => {
    return !((typeof value === "number" || typeof value === "string") && value % 1 === 0 && value !== "");
  };

  hotelSelected = (contigent, sortedContigents) => {
    let hotelSelected = <React.Fragment />;

    if (contigent.establishment_id) {
      hotelSelected =
        contigent.typologies && contigent.typologies.length ? (
          // hotel is selected and has typologies
          <React.Fragment>
            {sortedContigents.map(
              cont =>
                // iterate through all contigents, and display others that are in the same hotel
                cont.establishment_id === contigent.establishment_id ? (
                  <React.Fragment key={cont.id}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="kontigent_typology_id"
                        select
                        label="Tipologija"
                        className=""
                        error={cont.errorTypologyId}
                        value={cont.typology_id || ""}
                        onChange={({ target: { value } }) => this.typologyChange(value, cont.id)}
                      >
                        {cont.typologies &&
                          sortTypologies(cont.typologies).map((typology, typo_index) => (
                            <MenuItem key={typo_index} value={typology.id}>
                              {typology.code} ({typology.persons_capacity})
                            </MenuItem>
                          ))}
                      </TextField>
                      {cont.errorTypologyId && <FormHelperText id="name-error-text">Required field</FormHelperText>}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} htmlFor="pools_comment">
                          Kontigent
                        </InputLabel>
                        <Input
                          id="kontigent_typologies_sum"
                          name="kontigent_typologies_sum"
                          error={cont.errorTypologySum}
                          value={cont.typologies_sum || ""}
                          onChange={({ target: { value } }) => this.typologySumChange(value, cont.id)}
                        />
                        {cont.errorTypologySum && (
                          <FormHelperText id="name-error-text">Can only contain numbers</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} className="ver-center">
                      <div className="flex-right">
                        <DeleteIcon onClick={() => this.deleteContigent(cont.id)} />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : // not in the same hotel, dont do anything
                null,
            )}
            <Grid item xs={12} className="ver-center">
              <div className="flex-right">
                <AddIcon onClick={() => this.addNewContigentWithFilledEstablishment(contigent.establishment_id)} />
              </div>
            </Grid>
          </React.Fragment>
        ) : (
          // Hotel selected but does not have any typology
          <React.Fragment>
            <Grid item xs={12}>
              <span style={{ color: "#F44336" }}>Odabrani hotel nema dodijeljene tipologije.</span>
            </Grid>
          </React.Fragment>
        );
    }

    return hotelSelected;
  };

  render() {
    const { open, toggle } = this.props;
    const { contigents, establishments } = this.state;

    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);
    const loading = get(this.props, "listEstablishmentQuery.loading");

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    if (!contigents) {
      return null;
    }

    let establishmentsShown = [];
    let sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

    // combine establishments and listEstablishment and remove duplicates
    // establishments gives us est. that are already used (from contigents)
    // listEstablishment gives us est. that user is searching for
    const combinedEstablishments = removeDuplicateObjects(listEstablishment.concat(establishments));

    return (
      <DrawerWrapper toggle={toggle} update={this.updateData} open={open} title="Izmjena kontigenta, hotela">
        {sortedContigents.map(contigent => {
          // check if hotel from this contigent is already shown
          if (
            establishmentsShown.find(curr => {
              return curr === contigent.establishment_id;
            })
          ) {
            return null;
          }

          if (contigent.establishment_id) {
            establishmentsShown.push(contigent.establishment_id);
          }

          return (
            <Card key={contigent.id} className="card-create" style={{ margin: "10px 0px" }}>
              <CardHeader title={contigent.id.includes("-") ? "Kreiranje kontigenta" : `Kontigent`} />
              <CardText>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <AutocompleteSelect
                          label="Hotel"
                          defaultValue={contigent.establishment_id || ""}
                          autocompleteHandler={value => this.establishmentChange(value, contigent.id)}
                          disabled={Boolean(contigent.establishment_id)}
                          dataSource={combinedEstablishments
                            .filter(
                              // if hotel isn't selected, it will filter out every already displayed hotel
                              // if hotel is selected, it will filter out other displayed hotels except this one (so it can be displayed)
                              establishment =>
                                contigent.establishment_id ? true : !establishmentsShown.includes(establishment.id),
                            )
                            .map(establishment => ({
                              value: establishment.id,
                              label: `${establishment.name} - ${establishment.city ? establishment.city.name : ""}`,
                            }))}
                          placeholder="Odabir hotela"
                          onMenuScrollToBottom={this.handleScroller}
                          isLoading={contigent.id.includes("-") && (loading || this.state.fetchingMore)}
                          inputProps={{
                            onInputChange: this.handleAutocompleteInputChangeDebounced,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={24}>
                      {this.hotelSelected(contigent, sortedContigents)}
                    </Grid>
                  </Grid>
                </Grid>
              </CardText>
            </Card>
          );
        })}
        <Grid item xs={12} className="ver-center">
          <div className="flex-right">
            <AddIcon onClick={this.addNewContigent} />
          </div>
        </Grid>
      </DrawerWrapper>
    );
  }
}

export default compose(
  graphql(updateContigent, { name: "updateContigent" }),
  graphql(createContigent, { name: "createContigent" }),
  graphql(deleteContigent, { name: "deleteContigent" }),
  graphql(listEstablishment, {
    name: "listEstablishmentQuery",
    options: {
      fetchPolicy: "network-only",
      variables: {
        input: {
          paginationLimit: { limit: PAGINATION_LIMIT, offset: PAGINATION_OFFSET },
        },
      },
    },
  }),
)(withApollo(EstablishmentDrawer));
