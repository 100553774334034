import gql from "graphql-tag";

// fetch announcement orders
export const FETCH_ANNOUNCEMENT_ORDERS = gql`
  query($input: DictateConditionInput) {
    listDictate(input: $input) {
      id
      total_table_count
      program_id
      dictate_room_text
      dictate_extra_text
      dictate_gratis_text
      dictate_invoice_text
      date
      dictate_data {
        theme_name
        external_id
        pax
        from
        to
      }
    }
  }
`;

// fetch single announcement order
export const FETCH_ANNOUNCEMENT_ORDER = gql`
  query($id: ID!) {
    getDictate(id: $id) {
      id
      program_id
      dictate_room_text
      dictate_extra_text
      dictate_gratis_text
      dictate_invoice_text
      date
      dictate_data {
        theme_name
        external_id
        pax
        from
        to
      }
    }
  }
`;
