import React from "react";
import Dropzone from "react-dropzone";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";

class Upload extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Grid container>
          <Dropzone
            onDrop={this.props.onDrop}
            className="dropzone"
            maxSize={256000000}
            multiple={this.props.multiple !== undefined ? this.props.multiple : true}
          >
            <p>Klikni ili povucite i ispustite datoteku</p>
          </Dropzone>
        </Grid>
        {this.props.files.length ? <h3>Datoteke pripremljene za slanje serveru</h3> : null}
        <Grid container>
          {this.props.files &&
            this.props.files.map((f, index) => (
              <Grid item xs={12} key={f.name}>
                <Grid container>
                  <Grid item xs={3} className="flex-left">
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                    <ListItemText primary={f.name} secondary={f.size + " Bytes"} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="Delete">
                      <DeleteIcon onClick={() => this.props.deletePreparedFileToUpload(index)} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default Upload;
