import React from "react";

// ui
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";

// hooks
import usePhraseCreate from "./hooks/usePhraseAnnouncementCreate";

// contants
import { TITLE_ANNOUNCEMENT_PHRASE_EDIT } from "src/utility/labels";

const styles = theme => ({
  content: {
    padding: 16,
  },

  container: {
    marginTop: 8,
  },

  textInput: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

/**
 * transition of dialog
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

/**
 * PhraseCreateAnnouncement component used for createing phrase announcement
 */
const PhraseAnnouncementCreate = ({ classes }) => {
  // hook
  const {
    // variables
    isPhraseCreateOpen,
    listLanguage,
    phraseTitle,
    languageSelected,
    phraseText,
    // functions
    getPhraseCreateDisabled,
    handleOnCreatePhraseAnnouncement,
    handleOnDialogClose,
    setPhraseTitle,
    setLanguageSelected,
    setPhraseText,
  } = usePhraseCreate();

  // return root
  return (
    <Dialog
      fullScreen
      open={isPhraseCreateOpen || false}
      onClose={handleOnDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="title" color="inherit">
            {TITLE_ANNOUNCEMENT_PHRASE_EDIT}
          </Typography>

          <IconButton style={{ marginLeft: "auto" }} color="inherit" onClick={handleOnDialogClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.content}>
        {/** phrase text */}
        <div className={classes.container}>
          <TextField
            className={classes.textField}
            id="phraseTitle"
            label="Naslov"
            fullWidth={true}
            value={phraseTitle}
            onChange={event => setPhraseTitle(event.target.value)}
            margin="normal"
          />
        </div>

        {/** language select */}
        <div>
          <TextField
            className={classes.textInput}
            select
            fullWidth
            label="Jezik"
            value={languageSelected || ""}
            onChange={event => setLanguageSelected(event.target.value)}
          >
            {listLanguage
              ? listLanguage.map(language => (
                  <MenuItem key={language.id} value={language.id}>
                    {language.desc}
                  </MenuItem>
                ))
              : null}
          </TextField>
        </div>
        {/** phrase text */}
        <div className={classes.container}>
          <TextField
            className={classes.textField}
            id="multiline-flexible"
            label="Text fraze"
            fullWidth={true}
            multiline
            rows="10"
            value={phraseText}
            onChange={event => setPhraseText(event.target.value)}
            margin="normal"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleOnDialogClose}>
          Prekid
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={getPhraseCreateDisabled()}
          onClick={handleOnCreatePhraseAnnouncement}
        >
          Kreiraj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PhraseAnnouncementCreate);
