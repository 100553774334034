import React from "react";

// libs
import { get } from "lodash";
import moment from "moment";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";

// components
import HeaderVoucher from "../Header";
import VoucherSubject from "../Subject";

// constants
import { FONT_SIZE_VALUE, MARGIN_PAGE } from "../utils/constants";

// components
import VoucherTitle from "../Title";
import DateLabel from "../Date";
import VoucherTextField from "../TextField";
import VoucherListField from "../ListField";
import VoucherUserDetails from "../UserDetails";
import VoucherGuideSignature from "../GuideSignature";
import VoucherFooter from "../Footer";
import VoucherFullWidthText from "../FullWidthText";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  section: {
    margin: MARGIN_PAGE,
    flexGrow: 1,
  },
});

// VoucherHotel
export default function VoucherHotel({ voucher }) {
  // voucher data from announcement
  const voucherData = get(voucher, "voucher_data", {});

  // voucher data from announcement
  const voucherUniqueNr = get(voucher, "voucher_unique_nr", {});

  // voucher title
  const voucherTitle = get(voucher, "voucher_title", "");

  // hotel
  const hotelLabel = get(voucherData, "hotel_label.value", "");
  const hotelName = get(voucherData, "hotel[0].hotel_name.value", "");

  // date created
  const dateCreated = moment(get(voucher, "date", "")).format("DD.MM.YYYY");

  // group
  const groupLabel = get(voucherData, "group.label", "");
  const groupValue = get(voucherData, "group.value", "");

  // termin
  const terminLabel = get(voucherData, "termin.label", "");
  const terminValue = get(voucherData, "termin.value", "");

  // rooming
  const roomingLabel = get(voucherData, "rooming_label.value", "");
  const roomingList =
    get(voucherData, "rooming", []) &&
    get(voucherData, "rooming", []).map(
      rooming => `${get(rooming, "contigent_value", "")} ${get(rooming, "typology_value", "")}`,
    );

  // stamp
  const voucherStamp = get(voucher, "voucher_stamp_text", "");

  // payment text
  const voucherPaymentText = get(voucher, "voucher_payment_text", "");

  // voucher created text
  const voucherCreatedByText = get(voucher, "voucher_created_text", "");

  // voucher signature text
  const voucherSignatureText = get(voucher, "voucher_signature_text", "");

  // voucher user details
  const voucherUserFirstName = get(voucher, "user_first_name", "");
  const voucherUserLastName = get(voucher, "user_last_name", "");

  // guide
  const guideFirstName = get(voucherData, "guide_first_name", "");
  const guideLastName = get(voucherData, "guide_last_name", "");

  const guideFullName = `${guideFirstName && guideFirstName} ${guideLastName && guideLastName}`;

  // footer
  const voucherFooter = get(voucher, "voucher_footer_text", "");

  // return root
  return (
    <Document title={`${voucherTitle} - ${dateCreated} ( ${voucherUniqueNr} )`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/** header */}
          <HeaderVoucher />

          {/** subject */}
          <VoucherSubject subjectText={`${hotelLabel}: ${hotelName}`} />

          {/** created at */}
          <DateLabel value={dateCreated} />

          {/** title */}
          <VoucherTitle title={voucherTitle} />

          {/** group */}
          <VoucherTextField label={groupLabel} value={groupValue} />

          {/** termin */}
          <VoucherTextField label={terminLabel} value={terminValue} />

          {/** service */}
          <VoucherTextField label={"usluga"} value="HP(večera+doručak)" />

          {/** rooming */}
          {roomingLabel && roomingList ? <VoucherListField label={roomingLabel} values={roomingList} /> : null}

          {/** voucher validation stamp */}
          <VoucherFullWidthText
            text={voucherStamp}
            addTopMargin={true}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSans" }}
          />

          <VoucherFullWidthText
            text={voucherPaymentText}
            addTopMargin={false}
            textStyle={{ fontSize: FONT_SIZE_VALUE, fontFamily: "OpenSansBold", textDecoration: "underline" }}
          />

          {/** user details */}
          <VoucherUserDetails
            createdByText={voucherCreatedByText}
            firstName={voucherUserFirstName}
            lastName={voucherUserLastName}
          />

          {/** guide signature */}
          <VoucherGuideSignature signatureText={voucherSignatureText} guideName={guideFullName} />

          {/** footer */}
          <VoucherFooter footerText={voucherFooter} />
        </View>
      </Page>
    </Document>
  );
}
