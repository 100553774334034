import React from "react";

// hooks
import useLayoutComment from "./hooks/useGroupStatus";

// constants
import { TITLE_GUIDE_GROUP_STATUS } from "src/utility/labels";

// components
import DrawerWrapper from "src/components/UI/Drawer";
import GroupStatusContainer from "./components/Container";

/**
 * GroupStatus drawer is used for showing data for changing group status
 */
export default function GroupStatus() {
  const { handleOnGroupStatusChange, isDrawerOpen, loading, onCloseDrawer } = useLayoutComment();

  return (
    <DrawerWrapper
      open={isDrawerOpen}
      toggle={onCloseDrawer}
      isLoading={loading}
      isSaveVisible={true}
      onSave={handleOnGroupStatusChange}
      title={TITLE_GUIDE_GROUP_STATUS}
      customWidth="40%"
    >
      <GroupStatusContainer handleOnGroupStatusChange={handleOnGroupStatusChange} />
    </DrawerWrapper>
  );
}
