import { useEffect, useState } from "react";

/**
 * useAnnouncements hooks for set up controlled react-table component
 */
export default function useAnnouncements(announcementsPageSelected, pages, onAnnouncementPageChange) {
  // component state
  const [pageState, setPageState] = useState(announcementsPageSelected);

  useEffect(() => {
    setPageState(announcementsPageSelected);
  }, [announcementsPageSelected]);

  // get safe page
  const getSafePage = pageToSelect => {
    if (Number.isNaN(pageToSelect)) {
      pageToSelect = announcementsPageSelected;
    }
    return Math.min(Math.max(pageToSelect, 0), pages - 1);
  };

  // apply page
  const applyPage = e => {
    if (e) {
      e.preventDefault();
    }
    const page = pageState;
    setPageState(page === "" ? announcementsPageSelected : page);
    onAnnouncementPageChange(page === "" ? announcementsPageSelected : page);
  };

  // on enter press
  const onPageJumpEnterPress = e => {
    if (e.which === 13 || e.keyCode === 13) {
      applyPage();
    }
  };

  // change page
  const changePage = page => {
    page = getSafePage(page);
    setPageState(page);
    if (announcementsPageSelected !== page) {
      onAnnouncementPageChange(page);
    }
  };

  return {
    pageState,
    applyPage,
    getSafePage,
    changePage,
    onPageJumpEnterPress,
    setPageState,
  };
}
