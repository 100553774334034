import React, { Component, Fragment } from "react";
import debounce from "lodash/debounce";
import { Paper, Button } from "@material-ui/core";

import AlotmanReservationsList from "./AlotmanReservationsList";
import Toolbar from "./Toolbar";
import AutocompleteSelect from "../../../components/UI/AutocompleteSelect";

import CustomDatePicker from "../../../components/UI/CustomDatePicker";

import { formatDate } from "../../../utility/dates";
import ReservationDrawer from "./ReservationDrawer";

// import { reservations } from "../fakeData";
import { config, removeDuplicateObjects } from "../../../utility/globals";
import { graphql } from "react-apollo";
import { listEstablishment } from "../../../graphql/query/administration";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";
import get from "lodash/get";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

export default withLocalStorageHOC(
  graphql(listEstablishment, {
    name: "listEstablishmentQuery",
    options: {
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
        },
      },
    },
  })(
    class AlotmanReservations extends Component {
      state = {
        hotel: this.props.tableState.hotel || null,
        selected: {},
        reservation: {},
        selectAll: 0,
        fetchingMore: false,
        columns: [
          {
            id: "checkbox",
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
              return (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.selected[original.id] === true}
                  onChange={() => this.toggleRow(original.id)}
                />
              );
            },
            Header: x => {
              return (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.selectAll === 1}
                  ref={input => {
                    if (input) {
                      input.indeterminate = this.state.selectAll === 2;
                    }
                  }}
                  onChange={() => this.toggleSelectAll()}
                />
              );
            },
            sortable: false,
            width: 45,
          },
          {
            Header: "ID",
            width: 90,
            accessor: "id",
          },
          {
            Header: "Gost",
            accessor: "guest_name",
          },
          {
            Header: "Agencija",
            accessor: "partner_client_name",
          },
          {
            Header: "Tipologija",
            accessor: "typology_name",
          },
          {
            Header: "Alotmanski kod",
            accessor: "establishment_code",
          },
          {
            Header: "Dolazak",
            show: true,
            id: "from",
            width: 112,
            accessor: "from",
            Filter: ({ filter, onChange }) => <CustomDatePicker placeholder="" value={filter} onChange={onChange} />,
            Cell: ({ original }) => (original.from ? formatDate(original.from) : ""),
          },
          {
            Header: "Odlazak",
            show: true,
            id: "to",
            width: 112,
            Cell: ({ original }) => (original.to ? formatDate(original.to) : ""),
            accessor: "to",
            Filter: ({ filter, onChange }) => <CustomDatePicker placeholder="" value={filter} onChange={onChange} />,
          },
          {
            Cell: row => {
              return (
                <Button variant="outlined" size="small" onClick={() => this.toggleDrawer(row.original)}>
                  Pregledaj
                </Button>
              );
            },
            filterable: false,
          },
        ],
        reservations: [],
        active: typeof this.props.tableState.active === "boolean" ? this.props.tableState.active : "",
      };

      changeStatus = ({ target: { value } }) => {
        this.props.setTableState({
          ...this.props.tableState,
          active: String(value).length ? String(value) === "true" : value,
        });
        this.setState({
          active: String(value).length ? String(value) === "true" : value,
        });
      };

      toggleDrawer = (reservation = null) => {
        this.setState(prevState => {
          return {
            drawer: !prevState.drawer,
            reservation,
          };
        });
      };

      variables = {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
        },
      };

      where = {};

      handleAutocompleteInputChange = value => {
        if (this.where.name === value) {
          return;
        }
        this.setState({ fetchingMore: true }, async () => {
          this.where = { name: value };
          this.variables = {
            ...this.variables,
            input: {
              ...this.variables.input,
              paginationLimit: {
                limit: PAGINATION_LIMIT,
                offset: PAGINATION_OFFSET,
              },
              where: JSON.stringify(this.where),
            },
          };

          await this.props.listEstablishmentQuery.fetchMore({
            variables: this.variables,
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }

              if (fetchMoreResult.listEstablishment.length >= PAGINATION_LIMIT) {
                this.hasMoreItems = true;
              }

              return {
                previousResult,
                listEstablishment: [...fetchMoreResult.listEstablishment],
              };
            },
          });

          this.setState({ fetchingMore: false });
        });
      };

      handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 350);

      fetchingResults = false;
      hasMoreItems = true;

      handleScroller = async () => {
        const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

        if (
          this.fetchingResults === false &&
          this.hasMoreItems &&
          listEstablishment &&
          listEstablishment.length >= PAGINATION_LIMIT
        ) {
          this.setState({ fetchingMore: true }, async () => {
            this.fetchingResults = true;

            this.variables.input.paginationLimit.offset =
              this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

            await this.props.listEstablishmentQuery.fetchMore({
              variables: this.variables,
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult;
                }

                // we're retreiving PAGINATION_LIMIT partners each time,
                if (fetchMoreResult.listEstablishment.length < PAGINATION_LIMIT) {
                  this.hasMoreItems = false;
                }

                return {
                  ...previousResult,
                  listEstablishment: [...previousResult.listEstablishment, ...fetchMoreResult.listEstablishment],
                };
              },
            });

            this.setState({ fetchingMore: false });
          });
        }
      };

      toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
          selected: newSelected,
          selectAll: 2,
        });
      }

      toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
          this.state.reservations.forEach(x => {
            newSelected[x.id] = true;
          });
        }

        this.setState({
          selected: newSelected,
          selectAll: this.state.selectAll === 0 ? 1 : 0,
        });
      }

      isSelected = key => {
        return this.state.selected[key];
      };

      changeState = key => value => {
        this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: [], hotel: value });
        this.setState(prev => ({ ...prev, [key]: value }));
      };

      setReservations = reservations => {
        this.setState({
          reservations: reservations.length ? this.formatReservationsForDisplay(reservations) : reservations,
        });
      };

      formatReservation = reservation => {
        const typology_name = reservation.typology.typology_translations.find(
          ({ language_id }) => config.language[String(language_id)] === "Croatian",
        );

        const establishment_code = reservation.typology.establishment_code;

        const { termin_periods } = reservation.contract.offer_contract_document;

        let typologies = [];

        termin_periods.forEach(({ contigents }) => {
          contigents.forEach(({ typology }) => {
            typologies.push(typology);
          });
        });

        typologies = removeDuplicateObjects(typologies);

        return {
          ...reservation,
          establishment_code,
          partner_client_name:
            (reservation.contract &&
              reservation.contract.offer_contract_document &&
              reservation.contract.offer_contract_document.partner_client &&
              reservation.contract.offer_contract_document.partner_client.name) ||
            "",
          typology_name: (typology_name && typology_name.name + ` [ ${reservation.typology.persons_capacity} ]`) || "",
          contract: {
            ...reservation.contract,
            typologies,
          },
        };
      };

      formatReservationsForDisplay = reservations => {
        return reservations.map(reservation => {
          return this.formatReservation(reservation);
        });
      };

      updateReservationInReservations = updatedReservation => {
        this.setState(prev => ({
          ...prev,
          reservations: prev.reservations.map(reservation => {
            if (reservation.id === updatedReservation.id) {
              return this.formatReservation(updatedReservation);
            }

            return this.formatReservation(reservation);
          }),
        }));
      };

      componentDidUpdate = (_, prevState) => {
        if (this.state.hotel !== prevState.hotel) {
          this.setState({
            selected: {},
            selectAll: 0,
          });
        }
      };

      render() {
        const { hotel } = this.state;

        const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);
        const listEstablishmentLoading = get(this.props, "listEstablishmentQuery.loading");

        if (!listEstablishment) {
          return null;
        }

        if (this.fetchingResults === true) {
          this.fetchingResults = false;
        }

        const checkboxProps = {
          getTrProps: (s, r) => {
            const selected = r && this.isSelected(r.original.id);
            return {
              style: {
                ...(selected && { background: "#d5fdd5" }),
              },
            };
          },
        };

        const combinedEstablishments = hotel
          ? removeDuplicateObjects(listEstablishment.concat(hotel))
          : listEstablishment;

        const allEstablishments = combinedEstablishments.map(est => {
          const nameToShow = `${est.name} - ${est.city ? est.city.name : ""}`;

          return {
            label: nameToShow,
            value: est.id,
          };
        });

        return (
          <div>
            <Toolbar
              contentToPrint={() => this.componentRefInquiry}
              selectedVouchers={this.state.selected}
              reservations={this.state.reservations}
              changeStatus={this.changeStatus}
              checked={this.state.active}
            />

            <Paper elevation={1} className="alotman-reservations-hotel-pick ">
              <AutocompleteSelect
                label="Hotel"
                defaultValue={hotel || ""}
                autocompleteHandler={this.changeState("hotel")}
                dataSource={allEstablishments}
                onMenuScrollToBottom={this.handleScroller}
                isLoading={listEstablishmentLoading || this.state.fetchingMore}
                placeholder="Odabir hotela"
                inputProps={{
                  onInputChange: this.handleAutocompleteInputChangeDebounced,
                }}
              />
            </Paper>
            {this.state.hotel && (
              <Fragment>
                <AlotmanReservationsList
                  tableName="alotmanReservations"
                  ref={el => (this.componentRefInquiry = el)}
                  establishment_id={this.state.hotel}
                  checkboxProps={checkboxProps}
                  setReservations={this.setReservations}
                  {...this.state}
                />
                <ReservationDrawer
                  open={this.state.drawer}
                  toggle={this.toggleDrawer}
                  update
                  updateReservationInReservations={this.updateReservationInReservations}
                  reservation={this.state.reservation}
                  title="Ažuriraj rezervaciju"
                />
              </Fragment>
            )}
          </div>
        );
      }
    },
  ),
);
