import gql from "graphql-tag";

/**
 * announcement fragments
 */
// hotel and partner type
export const supplierPartnerFragment = gql`
  fragment SupplierPartner on HotelAndPartnerType {
    partner
    partner_type
    announcement_status
    voucher_status
    hotel_guide
    partner_client_id
    guide_id
    guide_first_name
    guide_last_name
    establishment_id
    from
    to
    termin_period_contigent_id
    program_day_content_id
  }
`;

// hotel table
export const hotelTableFragment = gql`
  fragment HotelTable on HotelTable {
    establishment_id
    hotel_title_label {
      label
      value
      placeholder
      readonly
    }
    hotel_name {
      label
      value
      placeholder
      readonly
    }
    hotel_address_1 {
      label
      value
      placeholder
      readonly
    }
    hotel_address_2 {
      label
      value
      placeholder
      readonly
    }
    hotel_check_in {
      label
      value
      placeholder
      readonly
    }
    hotel_check_out {
      label
      value
      placeholder
      readonly
    }
    hotel_breakfast_time {
      label
      value
      placeholder
      readonly
    }
    hotel_dinner_time {
      label
      value
      placeholder
      readonly
    }
  }
`;

// hotel guide announcement
export const hotelguideAnnouncementFragment = gql`
  fragment HotelGuideAnnouncement on HotelGuideAnnouncement {
    id
    program_id
    establishment_id
    language_id
    language_desc
    announcement_title {
      label
      value
      placeholder
      readonly
    }
    intro_message {
      label
      value
      placeholder
      readonly
    }
    group {
      label
      value
      placeholder
      readonly
    }
    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    guide {
      label
      value
      placeholder
      readonly
    }
    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }
    guide_accommodation {
      label
      value
      placeholder
      readonly
    }
    dinner_drink {
      label
      value
      placeholder
      readonly
    }
    bus_parking {
      label
      value
      placeholder
      readonly
    }
    important_notice {
      label
      value
      placeholder
      readonly
    }
    footer_message {
      label
      value
      placeholder
      readonly
    }

    rooming_label {
      value
    }

    rooming {
      hotel_name
      typology_label
      typology_value
      contigent_label
      contigent_value
    }
  }
  ${hotelTableFragment}
`;

// hotel no guide announcement
export const hotelNoGuideAnnouncementFragment = gql`
  fragment HotelNoGuideAnnouncement on HotelNoGuideAnnouncement {
    id
    program_id
    establishment_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    group {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    bus_driver_contact {
      label
      value
      placeholder
      readonly
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    bus_parking {
      label
      value
      placeholder
      readonly
    }

    important_notice {
      label
      value
      placeholder
      readonly
    }

    rooming_label {
      value
    }

    rooming {
      hotel_name
      typology_label
      typology_value
      contigent_label
      contigent_value
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// guide announcement
export const guideAnnouncementFragment = gql`
  fragment AnnouncementGuide on AnnouncementGuide {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    group {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    bus_driver_contact {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    important_notice {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    invoice_warrant {
      label
      value
      placeholder
      readonly
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// guide tour announcement
export const guideTourAnnouncementFragment = gql`
  fragment AnnouncementGuide on AnnouncementGuide {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    group {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    bus_driver_contact {
      label
      value
      placeholder
      readonly
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    important_notice {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    invoice_warrant {
      label
      value
      placeholder
      readonly
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// guide local announcement
export const guideLocalAnnouncementFragment = gql`
  fragment AnnouncementGuide on AnnouncementGuide {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    group {
      label
      value
      placeholder
      readonly
    }

    date {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    nr_of_persons {
      label
      value
      placeholder
      readonly
    }

    group_treffpunkt {
      label
      value
      placeholder
      readonly
    }

    bus_driver_contact {
      label
      value
      placeholder
      readonly
    }

    service_favor {
      label
      value
      placeholder
      readonly
    }

    payment {
      label
      value
      placeholder
      readonly
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// agency announcement
export const agencyAnnouncementFragment = gql`
  fragment AnnouncementAgency on AnnouncementAgency {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    payment {
      label
      value
      placeholder
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// agency tour announcement
export const agencyTourAnnouncementFragment = gql`
  fragment AnnouncementAgency on AnnouncementAgency {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    bus_parking {
      label
      value
      placeholder
    }

    olivari_guide_with_group {
      label
      value
      placeholder
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    payment {
      label
      value
      placeholder
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// agency local announcement
export const agencyLocalAnnouncementFragment = gql`
  fragment AnnouncementAgency on AnnouncementAgency {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    bus_parking {
      label
      value
      placeholder
      readonly
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    payment {
      label
      value
      placeholder
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// agency local program announcement
export const agencyLocalProgramAnnouncementFragment = gql`
  fragment AnnouncementAgency on AnnouncementAgency {
    id
    program_id
    language_id
    language_desc

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
      readonly
    }

    accommodation {
      label
      value
      placeholder
      readonly
    }

    olivari_guide_with_group {
      label
      value
      placeholder
      readonly
    }

    termin {
      label
      value
      placeholder
      readonly
    }

    hotel_label {
      value
    }

    hotel {
      ...HotelTable
    }

    voucher_accommodation {
      label
      value
      placeholder
      readonly
    }

    dinner_drink {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    program_label {
      value
    }

    program {
      date
      desc
    }

    payment {
      label
      value
      placeholder
    }

    footer_message {
      label
      value
      placeholder
      readonly
    }
  }
  ${hotelTableFragment}
`;

// supplier announcement
export const supplierAnnouncementFragment = gql`
  fragment AnnouncementSupplier on AnnouncementSupplier {
    id

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    intro_message {
      label
      value
      placeholder
    }

    group {
      label
      value
      placeholder
      readonly
    }

    date {
      label
      value
      placeholder
      readonly
    }

    time {
      label
      value
      placeholder
      readonly
    }

    language_text {
      label
      value
      placeholder
      readonly
    }

    nr_of_persons {
      label
      value
      placeholder
      readonly
    }

    olivari_guide_with_group {
      label
      value
      placeholder
      readonly
    }

    guide {
      label
      value
      placeholder
      readonly
    }

    payment {
      label
      value
      placeholder
      readonly
    }

    service_favor {
      label
      value
      placeholder
    }

    footer_message {
      label
      value
      placeholder
    }
  }
`;

// phrase announcement
export const phraseAnnouncementFragment = gql`
  fragment AnnouncementPhrase on AnnouncementPhrase {
    id

    announcement_title {
      label
      value
      placeholder
      readonly
    }

    phrase_title {
      label
      value
      placeholder
    }

    phrase_text {
      label
      value
      placeholder
    }
  }
`;
