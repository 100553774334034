import React, { Component } from "react";
import { graphql } from "react-apollo";
import toastr from "toastr";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import CommentTextArea from "../../../../components/UI/CommentTextArea";

class RemarkDrawer extends Component {
  state = {
    remark: ""
  };

  updateData = () => {
    const { remark } = this.state;
    if (remark !== this.props.remark) {
      try {
        this.props.mutate({
          variables: {
            id: this.props.id,
            patch: {
              remark
            }
          }
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  componentDidMount = () => {
    const { remark } = this.props;

    this.setState({ remark });
  };

  render() {
    const { toggle, open } = this.props;

    return (
      <DrawerWrapper
        toggle={toggle}
        update={this.updateData}
        open={open}
        title="Napomena"
      >
        <CommentTextArea
          defaultValue={this.state.remark}
          label="Napomena"
          customOnChange={({ target: { value } }) =>
            this.setState({ remark: value })
          }
        />
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(RemarkDrawer);
