import { cloneDeep } from "lodash";

import { config } from "../../../utility/globals";

export const fetchedOfferHandler = (state, offer) => {
  const {
    offer_contract_document: { termin_periods, offer_contract_document_type_id, programs },
  } = offer;

  let program_days = null;
  let program_to_send = null;

  // Setting program days
  if (config.offer_type[offer_contract_document_type_id] === "Program only") {
    try {
      program_days = programs && programs.length ? programs[0].program_days : null;
      program_to_send = programs && programs.length ? programs[0].id : "";
    } catch (error) {}
  } else if (
    termin_periods &&
    termin_periods.length > 0 &&
    config.offer_type[offer_contract_document_type_id] !== "Program only"
  ) {
    try {
      program_days =
        termin_periods[0].programs && termin_periods[0].programs.length
          ? termin_periods[0].programs[0].program_days
          : null;
      program_to_send =
        termin_periods[0].programs && termin_periods[0].programs.length ? termin_periods[0].programs[0].id : "";
    } catch (error) {}
  }

  let transport = false;
  // to set transport

  if (config.offer_type[offer_contract_document_type_id] === "Tour") {
    transport =
      termin_periods &&
      termin_periods.length &&
      termin_periods[0].subtermin_periods &&
      termin_periods[0].subtermin_periods.length
        ? termin_periods[0].subtermin_periods[0].transport
        : false;
  } else {
    transport = termin_periods && termin_periods.length ? termin_periods[0].transport : false;
  }

  return {
    ...state,
    offer: {
      ...offer,
      offer_contract_document: {
        ...offer.offer_contract_document,
        service_objects_to_create: [],
        service_type_ids_to_delete: [],
      },
    },
    offer_copy: {
      ...cloneDeep(offer),
      offer_contract_document: {
        ...cloneDeep(offer.offer_contract_document),
        service_objects_to_create: [],
        service_type_ids_to_delete: [],
      },
    },
    transport,
    program_days,
    program_days_copy: cloneDeep(program_days),
    program_to_send,
  };
};

export const changeEditData = (state, action) => {
  return {
    ...state,
    [action.param.key]: action.param.value,
  };
};

export const changeTemplateLanguage = (state, template) => {
  return {
    ...state,
    offer: {
      ...state.offer,
      offer_contract_document: {
        ...state.offer.offer_contract_document,
        vat_content: template.vat,
        sojourn_tax_content: template.sojourn_tax,
        free_place_content: template.free_place,
        price_validity_content: template.price_validity,
        surcharges_content: template.surcharges,
        payment_content: template.payment,
        storno_content: template.storno,
        program_changes_content: template.program_changes,
        content_content: template.content,
        program_content: template.program,
        room_list_content: template.room_list,
        booking_state_content: template.booking_state,
        comment_contigent: template.comment_contigent,
        document_date_validity_comment: template.document_date_validity_comment,
      },
    },
  };
};

export const addAttachmentHandler = (state, attachment) => {
  const { attachments } = state.offer.offer_contract_document;

  const atachments_clone = [...attachments];
  atachments_clone.push(attachment);

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_contract_document: {
        ...state.offer.offer_contract_document,
        attachments: atachments_clone,
      },
    },
  };
};

export const removeAttachmentHandler = (state, id) => {
  const { attachments } = state.offer.offer_contract_document;

  const atachments_clone = attachments.filter(att => att.id !== id);

  return {
    ...state,
    offer: {
      ...state.offer,
      offer_contract_document: {
        ...state.offer.offer_contract_document,
        attachments: atachments_clone,
      },
    },
  };
};

export const copyUpdatedOffer = (state, offer) => {
  return {
    ...state,
    offer: cloneDeep(offer),
    offer_copy: cloneDeep(offer),
    program_days_copy: cloneDeep(state.program_days),
  };
};

export { addToCreateServiceTypeHandler, updateToCreateServiceTypeHandler } from "./serviceFunctions";

export {
  addTerminHandler,
  addAlotmanTerminHandler,
  changePriceStructure,
  changePriceStructurePrice,
  removeTerminHandler,
  addContigentHandler,
  establishmentPicked,
  removeContigentHandler,
} from "./terminFunctions";

export {
  saveSubterminHandler,
  addSubterminPeriodHandler,
  removeSubterminContigent,
  saveEditedSubtermin,
  setSubterminToEdit,
  toggleCreateSubtermin,
  toggleEditSubtermin,
  subterminEstablishmentPicked,
  addSubterminContigentHandler,
  removeSubtermin,
  subterminEstablishmentObjectPicked,
  removeSubterminHandler,
} from "./subterminFunctions";

export {
  changeProgramDayContentHandler,
  changeProgramDayDescription,
  createProgramDaysHandler,
  changeProgramDayTitleHandler,
  copyUpdatedProgramDays,
} from "./dailyProgramFunctions";

export { addAdditionalService, changeAdditionalService, deleteAdditionalService } from "./additionalServicesFunctions";
