import React, { Component } from "react";

import { round } from "../../../utility/numbers";
import { withApollo } from "react-apollo";
import { getActivity } from "../../../graphql/query/administration";
import { connect } from "react-redux";

class AdditionalServices extends Component {
  state = {
    additional_service: null,
  };

  componentDidMount = async () => {
    const { additional_services, language_id, client } = this.props;

    if (additional_services && additional_services.length) {
      try {
        const additional_service = await Promise.all(
          additional_services.map(async (service, index) => {
            const { activity_id } = service;

            const response = await client.query({
              query: getActivity,
              variables: {
                id: activity_id,
              },
            });

            const activity = response.data.getActivity;

            const activity_translated = activity.activity_translations.find(
              translation => translation.language_id === language_id,
            );

            return (
              <div
                className="preview-no-padding preview-no-margin  preview-item-price "
                key={`additional_price_${index}`}
              >
                <span className="preview-item-price preview-uppercase">{activity_translated.name}</span>
                <span>{` = ${round(service.actual_price).toFixed(2)} €`}</span>
              </div>
            );
          }),
        );

        this.setState({
          additional_service,
        });
      } catch (error) {}
    }
  };

  render() {
    const { int } = this.props;

    if (!this.state.additional_service || !this.state.additional_service.length) {
      return null;
    }

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.additional_services}</h4>
        </div>
        <div className="preview-content-div preview-no-padding bold-text">{this.state.additional_service}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    templateInt,
    listActivity,
    preview: {
      offer_contract_document: { additional_services, language_id },
    },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();
  return {
    int,
    additional_services,
    listActivity,
    language_id,
  };
};

export default connect(mapStateToProps)(withApollo(AdditionalServices));
