import React, { Fragment, PureComponent } from "react";
import { withApollo } from "react-apollo";

import { formatDate } from "../../utility/dates";
import { getPartnerClient } from "../../graphql/query/administration";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class PreviewHeaderDBData extends PureComponent {
  state = {
    partner_client: null,
  };

  componentDidMount = async () => {
    if (this.props.partner_client_id) {
      try {
        const response = await this.props.client.query({
          query: getPartnerClient,
          variables: {
            id: this.props.partner_client_id,
          },
        });

        this.setState({
          partner_client: response.data.getPartnerClient,
        });
      } catch (error) {}
    }
  };

  render() {
    const { offer_created_at, contract_created_at, referents, int, match } = this.props;
    const { partner_client } = this.state;

    return (
      <div id="preview-header-db-data" className="preview-header-db-data">
        <div className="preview-client-data">
          {partner_client && (
            <Fragment>
              <p>{partner_client.name}</p>
              <p>{partner_client.address}</p>
              <p>{`${
                partner_client.city.region && partner_client.city.region.country
                  ? partner_client.city.region.country.name
                  : ""
              } - ${partner_client.city.postal_code},  ${partner_client.city.name}`}</p>
              <p>{partner_client.fax && "Fax: " + partner_client.fax}</p>
              <br />
              {referents && referents[0] && (
                <Fragment>
                  <p>{referents[0].first_name + " " + referents[0].last_name}</p>
                  <p>{referents[0].email}</p>
                  <p>{referents[0].phone && int.partner_client_phone + ": " + referents[0].phone}</p>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
        <div>
          <p className="preview-float-right">
            Malinska, {match.path.includes("ugovor") ? formatDate(contract_created_at) : formatDate(offer_created_at)}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_created_at,
      contract_created_at,
      created_at,
      offer_contract_document: { partner_client_id, referents },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    partner_client_id,
    offer_created_at,
    created_at,
    contract_created_at,
    referents,
    int,
  };
};

export default connect(mapStateToProps)(withApollo(withRouter(PreviewHeaderDBData)));
