import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_DOUBLE_TOP, FONT_SIZE_VALUE, MARGIN_CONTAINER_TOP } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  containerWithMargin: {
    marginTop: MARGIN_CONTAINER_DOUBLE_TOP,
  },

  containerNoMargin: {
    marginTop: MARGIN_CONTAINER_TOP,
  },

  text: {
    fontSize: FONT_SIZE_VALUE,
    fontFamily: "OpenSans",
  },

  textBold: {
    fontSize: FONT_SIZE_VALUE,
    fontFamily: "OpenSansBold",
  },
});

// FullWidthText
export default function VoucherFullWidthText({ text, textStyle, addTopMargin }) {
  return (
    <View style={addTopMargin ? styles.containerWithMargin : styles.containerNoMargin}>
      <Text style={textStyle}>{`${text}`}</Text>
    </View>
  );
}
