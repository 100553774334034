import React, { Fragment } from "react";

// ui
import { Table, TableCell, TableBody, TableRow, Typography, withStyles } from "@material-ui/core";

// style
const styles = theme => ({
  container: {
    marginTop: 16,
  },

  tableCellLabel: {
    borderWidth: 1,
    borderColor: "#616161",
    borderStyle: "solid",
    padding: "8px !important",
    maxWidth: 80,
  },
  tableCellValue: {
    borderWidth: 1,
    borderColor: "#616161",
    borderStyle: "solid",
    padding: "8px !important",
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
});

/**
 * ProgramDetails
 */
const ProgramDetails = ({ classes, label, programList }) => {
  // root
  return (
    <div>
      <div className={classes.container}>
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      </div>
      <div className={classes.container} style={{ width: 600 }}>
        {programList && (
          <Table className={classes.table}>
            <TableBody>
              {programList.map((program, index) => {
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell padding="dense" scope="row" className={classes.tableCellLabel}>
                        <Typography className={classes.label} variant="body1">
                          {program.date}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding="dense" className={classes.tableCellValue}>
                        <Typography variant="body1">{program.desc}</Typography>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ProgramDetails);
