import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";

import { setAllBookingsInitialQueryInput } from "../../../store/actions";
import { listBookingAllData, listBookingByTerminPeriod } from "../../../graphql/query/booking";

import Paper from "material-ui/Paper";

import {
  sortContigentsByTypologyPriorityWithoutListEstablishment,
  establishmentContigentsAndHotels,
} from "../../../utility/establishment";
import { cityInfo } from "../../../utility/city";
import { config } from "../../../utility/globals";
import { DEBOUNCE_FETCH_DELAY } from "../../../utility/reactTable";

import "../../../styles/booking.css";

import AllBookingDrawers from "./Drawers/AllBookingDrawers";
import BookingModalsHandler from "./BookingModalsHandler";
import BookingColumns from "./BookingsColumns";
import { Bookings } from "./Bookings";
import Toolbar from "./Toolbar";
import { DEFAULT_EMPTY_DATE } from "../../../components/UI/CustomDateRangePicker";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";

export const AllBookingsContext = React.createContext();

const mutationMsg = gql`
  subscription {
    mutationMsg(section: Booking)
  }
`;

const getPreparedFilters = (filtersSelected, allBookingsFilter) => {
  // prepare filters sent from react table (or empty object if there are none)
  let filtersPrepared =
    filtersSelected && filtersSelected.length
      ? Object.assign(...filtersSelected.map(entry => ({ [entry.id]: entry.value })))
      : {};

  if (
    filtersPrepared.notification_booking_state_date &&
    filtersPrepared.notification_booking_state_date.includes(DEFAULT_EMPTY_DATE)
  ) {
    delete filtersPrepared.notification_booking_state_date;
  }

  /* FILTERS */
  // do not apply custom filters if it's already filtered by document_date_validity
  switch (allBookingsFilter) {
    case "0":
      // aktivno - default
      filtersPrepared = {
        ...filtersPrepared,
        document_pipeline: "true",
      };
      break;
    case "1":
      // arhivirano
      filtersPrepared = {
        ...filtersPrepared,
        document_pipeline: "false",
      };
      break;
    case "2":
      // svi (all) - when retrieving all records, we do not add any special field
      break;
    default:
      break;
  }
  return JSON.stringify(filtersPrepared);
};

class AllBookings extends Component {
  state = {
    //booking_status: "0", // contained in props
    filtersPrepared: {},
    pages: null,
    loading: true,
    columns: [...BookingColumns],
  };

  toggleColumn = id => () => {
    const cols = this.state.columns.map(col => (col.id === id ? { ...col, show: !col.show } : col));
    this.setState({
      columns: cols,
    });
  };

  fetchMoreObject = {};
  _isMounted = false;

  subscribe = () => {
    if (!this.props.listBooking) {
      return;
    }

    this.props.listBooking.subscribeToMore({
      document: mutationMsg,
      updateQuery: async (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        if (this._isMounted) {
          let response;

          // If we have changed filtering or pages ( this.fetchMoreObject is changed in fetchData)
          if (Object.keys(this.fetchMoreObject).length) {
            response = await this.props.listBooking.fetchMore(this.fetchMoreObject);
          } else {
            response = await this.props.listBooking.refetch();
          } // refetch default query

          return {
            ...prev,
            listBooking: [...response.data.listBooking],
          };
        }
      },
    });
  };

  showEstablishmentChains = contigents => {
    let chains = {}; // keep chains here under id-name (key-value) pair
    let toShow = []; // chains to be shown

    for (const entry of contigents) {
      // iterate over contigents and get their est. chains
      const chain = entry.typology.establishment.establishment_chain;
      // use id as key, so that no chains are repeated
      chains[chain.id] = chain.name;
    }

    for (const key in chains) {
      // add to array of elements to be displayed
      toShow.push(<div key={key}>{chains[key]}</div>);
    }

    return <div>{toShow}</div>;
  };

  showSortedHotelsByContigentsPriority = contigents => {
    let establishmentsShown = [];
    let establishmentsToShow = null;

    let sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

    establishmentsToShow = sortedContigents.map(contigent => {
      if (
        establishmentsShown.find(curr => {
          return curr === contigent.typology.establishment_id;
        })
      ) {
        return null;
      }

      if (contigent.typology.establishment_id) {
        establishmentsShown.push(contigent.typology.establishment_id);
        return <div key={contigent.typology.establishment_id}>{contigent.typology.establishment.name}</div>;
      }
      return null;
    });
    return <div>{establishmentsToShow}</div>;
  };

  showContigentsSortedByHotel = (contigents, showSum) => {
    // arg 1: contigents - list of contigents from termin.contigents
    // arg 2: showSum - whether to show typologies_sum or sold field
    let establishmentsShown = [];
    let sortedContigents = sortContigentsByTypologyPriorityWithoutListEstablishment(contigents);

    return (
      <div>
        {sortedContigents.map(contigent => {
          if (
            establishmentsShown.find(curr => {
              return curr === contigent.typology.establishment_id;
            })
          ) {
            return null;
          }

          if (contigent.typology.establishment_id) {
            establishmentsShown.push(contigent.typology.establishment_id);
          }

          return (
            <React.Fragment key={`main${contigent.id}`}>
              {/* put space between rows of hotels*/}
              {establishmentsShown.length > 1 ? <div>&nbsp;</div> : null}
              {/* list contigents for  hotel */}
              {sortedContigents.map(cont =>
                cont.typology.establishment_id === contigent.typology.establishment_id ? (
                  <div key={`sub${cont.id}`}>
                    {showSum ? cont.typologies_sum : cont.sold} /
                    {`${cont.typology.code} [${cont.typology.persons_capacity}]`}
                  </div>
                ) : null,
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  componentDidMount() {
    this._isMounted = true;

    this.unsubscribe = this.subscribe();

    // set initial query params so our graphql HOC function does not get called every time on filtering
    const limit = this.props.tableState.pageSizeSelected;
    const offset = this.props.tableState.pageSelected * this.props.tableState.pageSizeSelected;

    this.props.setAllBookingsInitialQueryInput(
      limit,
      offset,
      this.props.tableState.filtersSelected,
      this.props.tableState.allBookingsFilter || "0",
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidUpdate(prevProps) {
    // since we receive showBookings from props, change state and fetch new data once it changes
    if (this.props.showBookings !== prevProps.showBookings) {
      this.setState({ loading: true }, this.applyFiltersAndFetch);
    }
  }

  // react table's function on a change
  fetchData = async state => {
    // we've arrived either debounced or not, so filtering can be reset
    this.filtering = false;

    // set new state when ReactTable changes and query with new changes
    this.setState(
      {
        loading: true,
      },
      this.applyFiltersAndFetch,
    );
  };

  filtering = false;
  fetchData = this.fetchData.bind(this);
  // ^ debounced version of "fetchData"
  fetchDataWithDebounce = debounce(this.fetchData, DEBOUNCE_FETCH_DELAY);

  fetchStrategy = state => {
    if (this.filtering) {
      this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: state.filtered });
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  onFilteredChange = () => {
    this.filtering = true; // when the filter changes, that means someone is typing
  };

  // our custom function, called whenever react table changes or any of filters not directly in react table
  applyFiltersAndFetch = async () => {
    this.resetXml();
    const { pageSelected, pageSizeSelected, allBookingsFilter } = this.props.tableState;

    let filtersToSend = getPreparedFilters(this.props.tableState.filtersSelected, allBookingsFilter || "0");

    // Setting new object in fetchMore, so that we could reuse it in subscription
    this.fetchMoreObject = {
      fetchPolicy: "network-only",
      variables: {
        input: {
          paginationLimit: {
            limit: pageSizeSelected,
            offset: pageSelected * pageSizeSelected,
          },
          where: filtersToSend,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (this._isMounted) {
          // we have new data, reset previous expanders
          this.resetExpander();

          if (fetchMoreResult && fetchMoreResult.listBooking && fetchMoreResult.listBooking.length) {
            // when we get normal reponse, ceil up page number
            this.setState({
              pages: Math.ceil(fetchMoreResult.listBooking[0].count / pageSizeSelected),
              loading: false,
            });
          }
          // when query returns empty array (no results for those filters)
          else {
            this.setState({
              pages: null,
              loading: false,
            });
          }

          return {
            ...previousResult,
            listBooking: [...fetchMoreResult.listBooking],
          };
        }
      },
    };

    await this.props.listBooking.fetchMore(this.fetchMoreObject);
  };

  applyFiltersAndFetchDebonced = debounce(this.applyFiltersAndFetch, DEBOUNCE_FETCH_DELAY);

  refetchLastListBookingQuery = () => this.applyFiltersAndFetch();

  transformListBooking = listBooking =>
    listBooking &&
    listBooking.map(termin_period => {
      const shortTerminPeriod = { ...termin_period };
      const contract = {
        ...termin_period.booking_document.offer_contract_document.contract,
      };
      const booking_document = { ...termin_period.booking_document };
      const offer_contract_document = {
        ...termin_period.booking_document.offer_contract_document,
      };

      delete booking_document.offer_contract_document;
      delete offer_contract_document.contract;
      delete shortTerminPeriod.booking_document;

      const booking = {
        ...contract,
        booking_document: {
          ...booking_document,
          termin_periods: [shortTerminPeriod],
        },
        offer_contract_document: { ...offer_contract_document },
      };

      return booking;
    });

  dataToShow = () => {
    if (!this.props.listBooking) {
      return [];
    }

    const { listBooking } = this.props.listBooking;

    let arrayToShow = [];
    let counter = 0;

    const transformToBooking = this.transformListBooking(listBooking);

    transformToBooking &&
      transformToBooking.map(booking => {
        const {
          booking_document: { termin_periods },
          offer_contract_document: { offer_contract_document_type_id },
        } = booking;

        /**
         * if advanced, also add parent id so we can edit and show notification_booking_state_date from parent termin
         * so there will be showed only one and the same notification_booking_state_date
         */
        if (config.offer_type[offer_contract_document_type_id] === "Tour") {
          const { price_structure } = termin_periods[0];
          const booking_termins = termin_periods[0].subtermin_periods.map(termin => {
            return this.prepareTerminForReactTable(
              booking,
              {
                ...termin,
                parent_id: termin_periods[0].id,
                notification_booking_state_date: termin_periods[0].notification_booking_state_date,
                price_structure,
              },
              counter,
            );
          });
          counter++;

          arrayToShow = arrayToShow.concat(booking_termins);
        } else {
          const booking_termins = termin_periods.map(termin => {
            return this.prepareTerminForReactTable(booking, termin, counter++);
          });

          arrayToShow = arrayToShow.concat(booking_termins);
        }

        return "";
      });

    return arrayToShow;
  };

  prepareTerminForReactTable(booking, termin, counter) {
    const {
      offer_contract_document: {
        offer_contract_document_segment_id,
        partner_client,
        document_code,
        //inquiry_id,
        inquiry_external_id,
        code1,
        inquiry,
      },
    } = booking;

    if (!termin.contigents[0]) {
      return {};
    }

    // Every termin has it's more typologies from same hotel, so I take only first establishment from first typology
    const { establishment } = termin.contigents[0].typology;

    // City
    const city = establishment.city;
    const place = cityInfo(city);

    const {
      establishment_names: establishment_name,
      contigent_lists: contigents_list,
    } = establishmentContigentsAndHotels(termin.contigents);

    return {
      ...termin,
      counter,
      booking_id: booking.id,
      booking_external_id: booking.external_id,
      booking_status: termin.termin_period_status.desc,
      offer_contract_document_segment_id,
      agency: partner_client.name,
      document_code,
      //inquiry_id,
      inquiry_external_id: inquiry_external_id,
      code1,
      off_key: inquiry.off_key,
      establishment: <div>{establishment_name}</div>,
      city: place,
      establishment_chain: this.showEstablishmentChains(termin.contigents),
      contigents_list: <div>{contigents_list}</div>,
      persons_capacity_sold: this.showContigentsSortedByHotel(termin.contigents, false),
      pax: termin.contigents.reduce(function(accumulator, contigent) {
        return accumulator + contigent.sold * contigent.typology.persons_capacity;
      }, 0),
    };
  }

  resetExpander = () => {};
  getResetExpander = fun => (this.resetExpander = fun);

  resetXml = () => {};

  getResetXml = fun => {
    this.resetXml = fun;
  };

  render() {
    const {
      listOfferContractDocumentSegment,
      listTerminPeriodStatus,
      listDatagridFilter,
      listBookingOptionDescription,
    } = this.props.data;

    const { columns, loading } = this.state;

    let ui = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        getResetExpander: this.getResetExpander,
        dataToShow: this.dataToShow,
      }),
    );

    return (
      <AllBookingsContext.Provider
        value={{
          columns,
          getResetXml: this.getResetXml,
          toggleColumn: this.toggleColumn,
          dataToShow: this.dataToShow,
          listOfferContractDocumentSegment,
          listTerminPeriodStatus,
          listDatagridFilter,
          listBookingOptionDescription,
          refetchLastListBookingQuery: this.refetchLastListBookingQuery,
          innerTableProps: {
            onFetchData: this.fetchStrategy,
            onFilteredChange: this.onFilteredChange,

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,

            loading: loading || this.props.loadingRefetch,
            pages: this.state.pages,
            manual: true,
            sortable: false,
          },
        }}
      >
        {ui}
      </AllBookingsContext.Provider>
    );
  }
}

class BookingUsage extends PureComponent {
  state = {
    resetXml: false,
    loadingRefetch: false,
  };

  changeShowBookings = allBookingsFilter => {
    this.props.setTableState({ ...this.props.tableState, pageSelected: 0, allBookingsFilter: allBookingsFilter });
  };

  fetchInitialQuery = async () => {
    const { pageSelected, pageSizeSelected, allBookingsFilter } = this.props.tableState;

    if (allBookingsFilter === "2") {
      return;
    }
    this.setState({ ...this.state, loadingRefetch: true });
    if (this.props.listBooking) {
      let filtersToSend = getPreparedFilters(this.props.tableState.filtersSelected, allBookingsFilter || "0");

      await this.props.listBooking.refetch({
        input: {
          paginationLimit: {
            limit: pageSizeSelected,
            offset: pageSelected * pageSizeSelected,
          },
          where: filtersToSend,
        },
      });
      this.setState({ ...this.state, loadingRefetch: false });
    }
  };

  getData = reactTable => {
    this.reactTable = reactTable;
  };

  changeResetXml = val => this.setState({ resetXml: val });

  render() {
    const { props } = this;

    const all_bookings_filter = this.props.tableState.allBookingsFilter || "0";

    return (
      <AllBookings {...props} showBookings={all_bookings_filter} loadingRefetch={this.state.loadingRefetch}>
        {/* Init handling drawers */}
        <BookingModalsHandler fetchInitialQuery={this.fetchInitialQuery}>
          <AllBookingsContext.Consumer>
            {({ getResetXml }) => (
              <Toolbar
                getResetXml={getResetXml}
                reactTable={this.reactTable}
                contentToPrint={() => this.componentRef}
                showBookings={all_bookings_filter}
                changeShowBookings={this.changeShowBookings}
              />
            )}
          </AllBookingsContext.Consumer>
          {/* Showing React table */}
          <Paper zDepth={1}>
            <Bookings ref={el => (this.componentRef = el)} showBookings={all_bookings_filter} getData={this.getData} />
          </Paper>
          {/* Drawers */}
          <AllBookingDrawers />
        </BookingModalsHandler>
      </AllBookings>
    );
  }
}
BookingUsage.title = "Render Props";

const mapStateToProps = state => {
  const { limit, offset, selectedFilters, allBookingsFilter } = state.booking;

  return {
    limit,
    offset,
    selectedFilters,
    allBookingsFilter,
  };
};

export default withLocalStorageHOC(
  connect(mapStateToProps, { setAllBookingsInitialQueryInput })(
    compose(
      graphql(listBookingAllData),
      graphql(listBookingByTerminPeriod, {
        name: "listBooking",
        skip: ({ limit, offset }) => {
          return !limit && !offset;
        },
        options: ({ limit, offset, selectedFilters, allBookingsFilter }) => ({
          fetchPolicy: "network-only",
          variables: {
            // This is default query on component mount
            input: {
              paginationLimit: {
                limit: limit,
                offset: offset,
              },
              where: getPreparedFilters(selectedFilters, allBookingsFilter),
            },
          },
        }),
      }),
    )(BookingUsage),
  ),
);
