import gql from "graphql-tag";
import {
  attachmentFragment,
  offerContractDocumentFragment,
  inquiryFragment,
  mainTerminFragment,
  terminsFragment,
  templateFragment,
  dailyTerminPrograms,
  contigentFragment,
  userFragment,
  partnerClientFragment,
  serviceFragment,
  serviceTypeFragment,
  alotmanTemplateFragment,
} from "../fragments";

export const allContracts = gql`
  query($input: Condition, $alotman: Boolean) {
    listContract(input: $input, alotman: $alotman) {
      id
      external_id
      name
      count
      created_at
      contract_created_at
      last_edit_by_user_time
      last_edit_by_user {
        id
        username
      }
      created_by_user {
        ...User
      }
      storno_comment
      contract_status_id
      contract_status {
        id
        desc
      }
      offer_contract_document {
        id
        inquiry_id
        inquiry_external_id
        partner_client {
          id
          name
        }
        attachments {
          ...Attachment
        }
        document_code
        document_date_validity
        offer_contract_document_type {
          id
          desc
        }
        created_at
      }
    }
  }
  ${attachmentFragment}
  ${userFragment}
`;

export const fetchContract = gql`
  query($id: ID!) {
    getContract(id: $id) {
      id
      external_id
      name
      contract_created_at
      contract_status_id
      storno_comment
      created_by_user {
        ...User
      }
      offer_contract_document {
        ...OfferContractDocument
        ...Inquiry
        offer_contract_document_type_id
        release_day
        offer_contract_document_type {
          id
          desc
        }
        programs {
          ...DailyProgram
        }
        offer_contract_document_segment_id
        offer_contract_document_segment {
          id
          desc
        }
        contract {
          id
        }
        attachments {
          ...Attachment
        }
        termin_periods {
          ...MainTermin
          price_structure {
            id
            price_structure_type {
              id
              desc
            }
          }
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
          programs {
            ...DailyProgram
          }
        }
        ...Template
        ...AlotmanTemplate
      }
    }
  }
  ${offerContractDocumentFragment}
  ${inquiryFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${templateFragment}
  ${alotmanTemplateFragment}
  ${dailyTerminPrograms}
  ${contigentFragment}
  ${attachmentFragment}
  ${userFragment}
`;

export const contractData = gql`
  {
    listLanguage {
      id
      code
    }

    listContractStatus {
      id
      desc
    }

    listPartnerClientService {
      id
      price
      partner_client_service_translations {
        id
        name
        description
        language_id
      }
    }

    listActivity {
      id
      default_price
      activity_type {
        id
        desc
        code
      }
      activity_translations {
        id
        name
        desc
        language_id
      }
    }
  }
`;

export const listAlotman = gql`
  query($input: Condition) {
    listContract(input: $input, alotman: true) {
      id
      external_id
      name
      count
      created_at
      alotman_contacted
      contract_created_at
      last_edit_by_user_time
      last_edit_by_user {
        id
        username
      }
      created_by_user {
        ...User
      }
      offer_contract_document {
        id
        inquiry_id
        release_day
        inquiry_external_id
        partner_client {
          ...Partner
        }
        attachments {
          ...Attachment
        }
        offer_contract_document_services {
          service_types {
            ...ServiceType
            services {
              ...Service
            }
          }
        }
        termin_periods {
          ...MainTermin
          contigents {
            ...Contigent
          }
          subtermin_periods {
            ...Termin
            contigents {
              ...Contigent
            }
          }
        }
      }
    }
  }
  ${attachmentFragment}
  ${userFragment}
  ${mainTerminFragment}
  ${terminsFragment}
  ${contigentFragment}
  ${partnerClientFragment}
  ${serviceTypeFragment}
  ${serviceFragment}
`;
