import React, { Component } from "react";
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";

import CircularProgress from "material-ui/CircularProgress";

import OfferForm from "../OfferForm/OfferForm";

// GRAPHQL
import { fetchOffer } from "../../../graphql/query/offers";

import { connect } from "react-redux";

import _ from "lodash";

import {
  updateOffer,
  documentAttachmentUpload,
  deleteDocumentFile,
  updateProgram,
} from "../../../graphql/mutation/offers";

import * as actions from "../../../store/actions";
import { prepareForSending } from "../../../utility/prepare";
import toastr from "toastr";
import { createContract } from "../../../graphql/mutation/contract";
import { difference, config, offerChanges } from "../../../utility/globals";

const removeActive = gql`
  mutation($offer_id: ID!) {
    removeActiveUserOnOffer(offer_id: $offer_id)
  }
`;

class EditOffer extends Component {
  state = {
    loading: true,
    error: "",
    selectedFiles: "",
  };

  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;

    const { is_editable } = this.props.offer;

    const token = localStorage.getItem("token");

    if (this.props.match.params.id && is_editable && token) {
      this.props.unlockDocument({
        variables: {
          offer_id: this.props.match.params.id,
        },
      });
    }
  }

  onDrop = files => {
    this.setState(
      {
        selectedFiles: files,
      },
      () => this.uploadAttachments(),
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchOffer();
  }

  componentDidUpdate(prevProp) {
    // When offer is duplicated, fetch new offer
    if (prevProp.match.params.id !== this.props.match.params.id) {
      this.fetchOffer();
    }
  }

  fetchOffer = async () => {
    try {
      const response = await this.props.client.query({
        query: fetchOffer,
        variables: { id: this.props.match.params.id },
        fetchPolicy: "network-only",
      });

      if (this._isMounted) {
        const offer = response.data.getOffer;

        if (!offer) {
          return this.setState({ error: "Nije pronađena ponuda" });
        }

        if (this.isEditable(offer)) {
          if (offer) {
            this.setState({
              loading: false,
            });
          }

          this.props.onSetOfferToEdit(offer);
        }
      }
    } catch (error) {}
  };

  isEditable(offer) {
    const { is_editable } = offer;

    if (is_editable) {
      return true;
    } else {
      const windowHeight = window.innerHeight;
      localStorage.setItem("preview", JSON.stringify(offer));
      window.open(
        `/ponude/pregled`,
        "_blank",
        `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=793.7007874,height=${windowHeight}`,
      );
      this.props.history.goBack();
      return false;
    }
  }

  uploadAttachments = async () => {
    if (this.state.selectedFiles) {
      const { selectedFiles } = this.state;

      if (selectedFiles && selectedFiles.length) {
        try {
          selectedFiles.forEach(async file => {
            const { offer_contract_document } = this.props.offer;

            const res = await this.props.is({
              variables: {
                offer_contract_document_id: offer_contract_document.id,
                file,
              },
            });

            const { documentAttachmentUpload } = res.data;

            this.props.addAttachment(documentAttachmentUpload);
          });
          this.setState({ selectedFiles: "" });

          toastr.success("Datoteka uspješno uploadana");
        } catch (error) {}
      }
    }
  };

  deleteUploadedFile = async file_id => {
    try {
      await this.props.deleteDocumentFile({
        variables: {
          file_id,
        },
      });

      this.props.removeAttachment(file_id);
      toastr.success("Datoteka uspješno izbrisana");
    } catch (error) {}
  };

  update = async () => {
    const offerDiffs = offerChanges(this.props.offer, this.props.offer_copy) || {};

    let editOfferResponse = null;
    let editProgramDaysResponse = null;

    try {
      if (Object.keys(offerDiffs).length) {
        editOfferResponse = await this.props.mutate({
          variables: {
            id: this.props.match.params.id,
            patch: offerDiffs,
          },
        });
      }

      const {
        offer_contract_document: { offer_contract_document_type_id },
      } = this.props.offer;

      if (config.offer_type[offer_contract_document_type_id] !== "Alotman") {
        const program_days = prepareForSending(this.props.program_days || {}, ["__typename", "program_day_id"]);

        const program_days_copy = prepareForSending(this.props.program_days_copy || {}, [
          "__typename",
          "program_day_id",
        ]);

        const program_days_diffs = difference(program_days, program_days_copy);

        if (this.props.program_to_send && program_days_diffs.length) {
          editProgramDaysResponse = await this.props.updateProgram({
            variables: {
              id: this.props.program_to_send,
              patch: {
                program_days: [...prepareForSending(this.props.program_days, ["__typename", "program_day_id"])],
              },
            },
          });
        }
      }
      const { offer_status_id, offer_contract_document } = this.props.offer;

      // Offer changed into accepted, create contract
      if (offer_status_id === "4") {
        const response = await this.props.createContract({
          variables: {
            offer_contract_document_id: offer_contract_document.id,
          },
        });

        // check if contract has already been created
        const contractID = _.get(response, "data.createContract.id", null);

        if (contractID) {
          toastr.success(`Uspješno ažurirana ponuda i kreiran ugovor ${contractID}`);
          return this.props.history.push(`/ugovor/edit/${contractID}`);
        }
      }

      toastr.success("Uspješno ažurirano");

      const updateOffer = _.get(editOfferResponse, "data.updateOffer");
      const updateProgram = _.get(editProgramDaysResponse, "data.updateProgram");

      // update copy offer
      if (updateOffer) {
        this.props.copyUpdatedOffer(prepareForSending(updateOffer, ["__typename"]));
      }

      // update copy porgram days
      if (updateProgram) {
        this.props.copyUpdatedProgramDays(prepareForSending(updateProgram.program_days, ["__typename"]));
      }
    } catch (error) {
      // todo handle catch error
      toastr.error("Greška prilikom ažuriranja ponude");
    }
  };

  render() {
    const { loading, error } = this.state;

    if (error) {
      return (
        <div>
          {error}
          <a href=":;" onClick={this.props.history.goBack} style={{ color: "#5C6BC0", cursor: "pointer" }}>
            Vrati se natrag
          </a>
        </div>
      );
    }

    if (loading || !this.props.offer.id) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <OfferForm
          title={`Ažuriranje ponude ${this.props.match.params.id}`}
          onSave={this.update}
          onDrop={this.onDrop}
          files={this.state.selectedFiles}
          deleteUploadedFile={this.deleteUploadedFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    offer: state.offer.offer,
    offer_copy: state.offer.offer_copy,
    program_to_send: state.offer.program_to_send,
    program_days: state.offer.program_days,
    program_days_copy: state.offer.program_days_copy,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetOfferToEdit: obj => dispatch(actions.setOfferToEdit(obj)),
    addAttachment: attachment => dispatch(actions.addAttachment(attachment)),
    removeAttachment: id => dispatch(actions.removeAttachment(id)),
    copyUpdatedOffer: offer => dispatch(actions.copyUpdatedOffer(offer)),
    copyUpdatedProgramDays: program => dispatch(actions.copyUpdatedProgramDays(program)),
  };
};

export default compose(
  graphql(documentAttachmentUpload, { name: "is" }),
  graphql(deleteDocumentFile, { name: "deleteDocumentFile" }),
  graphql(updateProgram, { name: "updateProgram" }),
  graphql(createContract, { name: "createContract" }),
  graphql(removeActive, { name: "unlockDocument" }),
  graphql(updateOffer),
)(connect(mapStateToProps, mapDispatchToProps)(withApollo(EditOffer)));
