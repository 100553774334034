import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateTypologyGroup from "./CreateTypologyGroup";
import EditTypologyGroup from "./EditTypologyGroup";
import TypologyGroupList from "./TypologyGroupList";

const TypologyGroupRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/grupa_tipologije/"
        exact
        render={props => <TypologyGroupList {...props} tableName={"typologyGroupTable"} />}
      />
      <Route path="/administracija/grupa_tipologije/create" exact component={CreateTypologyGroup} />
      <Route path="/administracija/grupa_tipologije/edit/:id" exact component={EditTypologyGroup} />
    </Switch>
  );
};

export default TypologyGroupRouter;
