import React from "react";
import { Switch, Route } from "react-router-dom";

import CreatePartnerServiceType from "./CreatePartnerServiceType";
import EditPartnerServiceType from "./EditPartnerServiceType";
import PartnerServiceTypesList from "./PartnerServiceTypesList";

const PartnerServiceTypesRouter = () => {
  return (
    <Switch>
      <Route
        path="/administracija/tip_usluge_partnera/"
        exact
        render={props => <PartnerServiceTypesList {...props} tableName={"administrationPartnerServiceTypes"} />}
      />
      <Route path="/administracija/tip_usluge_partnera/create" exact component={CreatePartnerServiceType} />
      <Route path="/administracija/tip_usluge_partnera/edit/:id" exact component={EditPartnerServiceType} />
    </Switch>
  );
};

export default PartnerServiceTypesRouter;
