import React from "react";
import ReactToPrint from "react-to-print";

import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import { blue500, grey900, fullWhite } from "material-ui/styles/colors";
import RaisedButton from "material-ui/RaisedButton";

import { Paper } from "@material-ui/core";
import DatePicker from "../../../components/UI/DatePicker";

class AlotmanContractsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { selectBookingChange } = this.props;

    return (
      <Toolbar className="toolbar-transparent toolbar-sm">
        <ToolbarGroup>
          <ToolbarTitle
            text="Popis alotmanskih ugovora"
            style={{
              color: grey900,
            }}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ReactToPrint
            pageStyle={`
              @media print{
                          @page {
                            size: landscape;
                          }
                          .ReactTable .-pagination{
                            height:50px
                          }
                        }`}
            trigger={() => <RaisedButton label="Print" backgroundColor={blue500} labelColor={fullWhite} />}
            content={() => this.props.contentToPrint()}
          />

          <Paper className="toolbar-filter">
            <DatePicker
              value={this.props.from_reservation}
              handleChange={this.props.changeFilterDate("from_reservation")}
              placeholder="Od"
              small
              showClearDate
            />
          </Paper>
          <Paper className="toolbar-filter">
            <DatePicker
              value={this.props.to_reservation}
              handleChange={this.props.changeFilterDate("to_reservation")}
              placeholder="Do"
              small
              showClearDate
            />
          </Paper>

          <Paper style={{ marginLeft: "5px", height: "36px" }}>
            <select
              style={{ height: "inherit" }}
              className="booking-select "
              onChange={({ target: { value } }) => {
                let stop_booking = value;
                if (value === "true") {
                  stop_booking = true;
                } else if (value === "false") {
                  stop_booking = false;
                }
                selectBookingChange(stop_booking);
              }}
              value={this.props.stop_booking}
            >
              <option value="" />
              <option value={false}>Stop Booking - NE</option>
              <option value={true}>Stop Booking - DA</option>
            </select>
          </Paper>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

export default AlotmanContractsToolbar;
