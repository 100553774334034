import * as actionTypes from "../actionTypes";

export const copyFetchedAdministrationData = obj => {
  return {
    type: actionTypes.FETCHED_ADMINISTRATION_DATA_TO_EDIT,
    obj
  };
};

export const resetAdministrationData = () => {
  return {
    type: actionTypes.RESET_ADMINISTRATION_DATA
  };
};

//EDIT DATA
export const changeAdministrationEstablishmentData = obj => {
  return {
    type: actionTypes.CHANGE_ESTABLISHMENT_DATA,
    param: obj
  };
};

export const changeAdministrationEstablishmentChainData = obj => {
  return {
    type: actionTypes.CHANGE_ESTABLISHMENT_CHAIN_DATA,
    param: obj
  };
};

export const changeAdministrationActivityTypeData = obj => {
  return {
    type: actionTypes.CHANGE_ACTIVITY_TYPE_DATA,
    param: obj
  };
};

export const changeAdministrationActivityData = obj => {
  return {
    type: actionTypes.CHANGE_ACTIVITY_DATA,
    param: obj
  };
};

export const changeAdministrationServiceData = obj => {
  return {
    type: actionTypes.CHANGE_SERVICE_DATA,
    param: obj
  };
};

export const changeAdministrationUserData = obj => {
  return {
    type: actionTypes.CHANGE_USER_ADMINISTRATION_DATA,
    param: obj
  };
};

export const changeAdministrationServiceTypeData = obj => {
  return {
    type: actionTypes.CHANGE_SERVICE_TYPE_DATA,
    param: obj
  };
};

export const changePartnerClientData = obj => {
  return {
    type: actionTypes.CHANGE_PARTNER_CLIENT_DATA,
    param: obj
  };
};

export const addPartnerClientContactData = obj => {
  return {
    type: actionTypes.ADD_PARTNER_CLIENT_CONTACT_DATA
  };
};

// TYPOLOGY
export const changeAdministrationTypologyData = obj => {
  return {
    type: actionTypes.CHANGE_TYPOLOGY_DATA,
    param: obj
  };
};

export const editAdministrationTypologyData = (obj, id) => {
  return {
    type: actionTypes.EDIT_TYPOLOGY_DATA,
    param: obj,
    id
  };
};

export const saveAdministrationEditedTypologyData = typology => {
  return {
    type: actionTypes.SAVE_EDITED_TYPOLOGY_DATA,
    typology
  };
};

export const changeTypologyTranslationData = (obj, language_id) => {
  return {
    type: actionTypes.CHANGE_CREATING_TYPOLOGY_TRANSLATION_FIELD,
    obj,
    language_id
  };
};

export const removeAdministrationTypologyFromEstablishment = id => {
  return {
    type: actionTypes.REMOVE_TYPOLOGY_FROM_ESTABLISHMENT,
    id
  };
};

export const addAdministrationTypologyToEstablishment = obj => {
  return {
    type: actionTypes.ADD_TYPOLOGY,
    param: obj
  };
};
