import { updateOfferContractDocumentParam } from "./utility";

export const addAdditionalService = (state, service) => {
  const {
    offer: {
      offer_contract_document: {
        additional_services,
        additional_services_to_add
      }
    }
  } = state;

  const additional_services_clone = [...additional_services];

  // If  offer id exists , it means it is in edit mode
  if (state.offer.id) {
    state = updateOfferContractDocumentParam(state, {
      param: {
        key: "additional_services_to_add",
        value: additional_services_to_add
          ? [...additional_services_to_add, service]
          : [service]
      }
    });
  }

  additional_services_clone.push(service);

  const newState = updateOfferContractDocumentParam(state, {
    param: { key: "additional_services", value: additional_services_clone }
  });

  return newState;
};

export const changeAdditionalService = (state, param, index) => {
  const {
    offer: {
      offer_contract_document: { additional_services }
    }
  } = state;

  const additional_services_clone = [...additional_services];
  const additional_service = {
    ...additional_services_clone[index],
    [param.key]: param.value
  };

  additional_services_clone[index] = additional_service;

  const newState = updateOfferContractDocumentParam(state, {
    param: { key: "additional_services", value: additional_services_clone }
  });

  return newState;
};

export const deleteAdditionalService = (state, id) => {
  const {
    offer: {
      offer_contract_document: {
        additional_services,
        additional_services_ids_delete,
        additional_services_to_add
      }
    }
  } = state;

  let additional_services_clone = [...additional_services];

  const additional_service = additional_services_clone.find(as => as.id === id);

  // If  offer id  and additional_service exists , it means it is in edit mode
  // if id includes '-' it means that this service is for adding
  if (state.offer.id && additional_service && !id.includes("-")) {
    state = updateOfferContractDocumentParam(state, {
      param: {
        key: "additional_services_ids_delete",
        value: additional_services_ids_delete
          ? [...additional_services_ids_delete, additional_service.id]
          : [additional_service.id]
      }
    });
  }

  if (additional_services_to_add) {
    state = updateOfferContractDocumentParam(state, {
      param: {
        key: "additional_services_to_add",
        value: additional_services_to_add.filter(as => as.id !== id)
      }
    });
  }

  if (additional_service) {
    additional_services_clone = additional_services_clone.filter(
      as => as.id !== additional_service.id
    );
  }

  return updateOfferContractDocumentParam(state, {
    param: { key: "additional_services", value: additional_services_clone }
  });
};
