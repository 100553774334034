import React from "react";

// libs
import { useSelector } from "react-redux";
import { get } from "lodash";

// redux state
import { selectLanguages } from "../../../../announcementSlice";

// constants
import {
  COLUMN_TITLE_ANNOUNCEMENT_ID,
  COLUMN_TITLE_ANNOUNCEMENT_GROUP,
  COLUMN_TITLE_ANNOUNCEMENT_GUIDE,
  COLUMN_TITLE_ANNOUNCEMENT_HOTELS,
  COLUMN_TITLE_ANNOUNCEMENT_LANGUAGE,
  COLUMN_TITLE_ANNOUNCEMENT_EDIT,
  COLUMN_TITLE_ANNOUNCEMENT_FROM,
  COLUMN_TITLE_ANNOUNCEMENT_TO,
  COLUMN_TITLE_ANNOUNCEMENT_CONTRACT,
  COLUMN_TITLE_ANNOUNCEMENT_PARTNER,
} from "src/utility/labels";

import { ANNOUNCEMENT_ROW_ID_EDIT } from "src/utility/constants";

// utils
import { formatFilterDate } from "src/utility/globals";

// components
import ColumnHotels from "../../../ColumnHotels";
import EditIcon from "src/components/UI/EditIcon";
import FilterLanguage from "../../../FilterLanguage";
import FilterDate from "../../../FilterDate";

/**
 * agency local announcement columns
 */
export default [
  {
    Header: COLUMN_TITLE_ANNOUNCEMENT_EDIT,
    id: ANNOUNCEMENT_ROW_ID_EDIT,
    accessor: "id",
    width: 65,
    filterable: false,
    Cell: ({ value }) => (
      <div style={{ textAlign: "center", width: "100%" }}>
        <EditIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    // announcement id
    id: "id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_ID,
    width: 80,
    accessor: "id",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
    Filter: ({ filter, onChange }) => {
      return (
        <input
          style={{ width: "100%" }}
          type="number"
          value={filter ? filter.value : ""}
          onChange={event => {
            const value = event.target.value;
            // check for int range
            if (value.length <= 10) {
              return onChange(value);
            }
          }}
        />
      );
    },
  },

  {
    // contract NO
    id: "external_id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_CONTRACT,
    accessor: "external_id",
    width: 80,
    Cell: ({ value }) => <div style={{ textAlign: "left", width: "100%" }}>{value}</div>,
  },

  {
    // partner_name
    id: "partner_name",
    Header: COLUMN_TITLE_ANNOUNCEMENT_PARTNER,
    accessor: "partner_name",
    width: 150,
    Cell: ({ value }) => <div style={{ textAlign: "left", width: "100%" }}>{get(value, "value")}</div>,
  },

  {
    // group
    id: "group",
    Header: COLUMN_TITLE_ANNOUNCEMENT_GROUP,
    width: 170,
    accessor: "group",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{get(value, "value")}</div>,
  },

  {
    // guide
    Header: COLUMN_TITLE_ANNOUNCEMENT_GUIDE,
    width: 80,
    accessor: "guide",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{get(value, "value")}</div>,
  },

  {
    // date from
    id: "from",
    Header: COLUMN_TITLE_ANNOUNCEMENT_FROM,
    accessor: "from",
    width: 120,
    Cell: ({ value }) => {
      return <div style={{ textAlign: "center", width: "100%" }}>{value}</div>;
    },
    Filter: ({ filter, onChange }) => {
      const handleOnDateChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <FilterDate dateSelected={filter} handleOnDateChange={handleOnDateChange} />;
    },
  },
  {
    // date to
    id: "to",
    Header: COLUMN_TITLE_ANNOUNCEMENT_TO,
    accessor: "to",
    width: 120,
    Cell: ({ value }) => {
      return <div style={{ textAlign: "center", width: "100%" }}>{value}</div>;
    },
    Filter: ({ filter, onChange }) => {
      const handleOnDateChange = date => {
        let valueNew = null;
        if (!date) {
          valueNew = "";
        } else {
          valueNew = formatFilterDate(date).toISOString();
        }

        onChange(valueNew);
      };

      return <FilterDate dateSelected={filter} handleOnDateChange={handleOnDateChange} />;
    },
  },

  {
    // hotels
    Header: COLUMN_TITLE_ANNOUNCEMENT_HOTELS,
    id: "hotel",
    width: 170,
    accessor: "hotel",
    Cell: ({ value }) => {
      return <ColumnHotels hotelList={value} />;
    },
  },

  {
    // language
    id: "language_id",
    Header: COLUMN_TITLE_ANNOUNCEMENT_LANGUAGE,
    width: 110,
    accessor: "language_desc",
    Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
    Filter: ({ filter, onChange }) => {
      const listLanguage = useSelector(selectLanguages);

      return <FilterLanguage filter={filter} onChange={onChange} languages={listLanguage} />;
    },
  },
];
