// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_GUIDE_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateGuideAnnouncement is used for CreateGuideAnnouncement mutation
 */
export default function useCreateGuideAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createGuideAnnouncement] = useMutation(CREATE_GUIDE_ANNOUNCEMENT);

  const handleCreateGuideAnnouncement = async languageSelected => {
    const createAnnouncementResponse = await createGuideAnnouncement({
      variables: {
        input: {
          program_id: programId,
          guide_id: supplier.guide_id,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createGuideAnnouncement");
  };
  return {
    handleCreateGuideAnnouncement,
  };
}
