import React, { Component } from "react";
import debounce from "lodash/debounce";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import TextArea from "../../../../components/UI/CommentTextArea";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import { autosize } from "../../../../utility/globals";
import { Grid } from "@material-ui/core";

class DailyRow extends Component {
  onChangeTitle = day_id => event => {
    event.persist();

    this.changeTitleDebounced(day_id, event);
  };

  changeTitle(day_id, event) {
    this.props.changeProgramDayTitle(day_id, event.target.value);
  }

  changeTitleDebounced = debounce(this.changeTitle, 500);

  changeDescription = day_id => ({ target: { value } }) => this.props.changeProgramDayDescription(day_id, value);

  titleRef = React.createRef();

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.day) !== JSON.stringify(nextProps.day) ||
      this.props.language_id !== nextProps.language_id ||
      nextProps.isNotDraft !== this.props.isNotDraft
    ) {
      return true;
    }
    return false;
  }

  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    const { day } = this.props;

    if (document.getElementsByName(`program_day_content_${day.id}`).length) {
      autosize(document.getElementsByName(`program_day_content_${day.id}`)[0]);
    }
  }

  activity = daily_content => {
    if (daily_content.activity_id) {
      return `activity_id-${daily_content.activity_id}`;
    } else {
      if (daily_content.partner_client_service_id) {
        return `partner_client_service_id-${daily_content.partner_client_service_id}`;
      } else {
        return "";
      }
    }
  };

  render() {
    const { day, selectToShow, selectContent, isNotDraft } = this.props;

    return (
      <TableRow key={day.id}>
        <TableCell component="th" scope="row">
          <div className="form-unit-double">
            <div className="field-label is-normal">
              <label className="label">{`Day ${this.props.dayNumber(day.id)}`}</label>
            </div>
            <div className={`padding-right-16`}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Input
                      placeholder="Naslov"
                      disabled={isNotDraft}
                      ref={this.titleRef}
                      defaultValue={day.title || ""}
                      onChange={this.onChangeTitle(day.id)}
                    />
                    <p />
                  </FormControl>
                </Grid>
              </Grid>
              <div className="cols-10 ">
                {day &&
                  day.program_day_contents &&
                  day.program_day_contents.map((daily_content, index) => {
                    return (
                      <AutocompleteSelect
                        placeholder={`Odaberite aktivnost ${++index}`}
                        dataSource={selectToShow}
                        disabled={isNotDraft}
                        key={daily_content.id}
                        style={{ width: "auto" }}
                        defaultValue={this.activity(daily_content)}
                        autocompleteHandler={selected => {
                          selectContent(day.id, daily_content.id, selected);
                        }}
                      />
                    );
                  })}
                <br />
              </div>
              <TextArea
                name={`program_day_content_${day.id}`}
                defaultValue={day.description}
                inputProps={{
                  disabled: isNotDraft,
                }}
                customOnChange={this.changeDescription(day.id)}
                rows="6"
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default DailyRow;
