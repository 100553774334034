import React, { Component } from "react";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Button from "@material-ui/core/Button";
import CreateServiceTranslations from "./CreatePartnerClientServiceTranslationForm";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { decimalNumbersTwoDigits } from "../../../../../utility/numbers";
import FormValidator from "../../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

class CreatePartnerClientServiceForm extends Component {
  static schema = {
    properties: {
      price: {
        format: "numeric",
        errorMessage: {
          format: "Cijena mora biti broj",
        },
      },
      partner_client_service_type_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati tip usluge",
      },
      partner_client_service_translations: {
        type: "array",
        minItems: 1,
        errorMessage: "Potreban je barem jedan prijevod",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi jezici moraju imati naziv",
            },
            description: {
              type: "string",
              isNotEmpty: true,
              minLength: 1,
              errorMessage: "Svi opisi moraju biti ispunjeni",
            },
          },
        },
      },
    },
  };

  componentDidMount = () => {
    const translations = this.props.listLanguage.map(language => ({
      name: "",
      description: "",
      partner_client_service_id: "",
      language_id: language.id,
    }));

    this.props.setupCreateServiceData(translations);
  };

  render() {
    const {
      listLanguage,
      listPartnerClientServiceType,
      service,
      changeCreateService,
      addService,
      changeTranslationField,
    } = this.props;

    return (
      <FormValidator schema={CreatePartnerClientServiceForm.schema} submit={addService} data={service}>
        {({ error, errorMsg, submitAndRecreate }) => (
          <Grid container spacing={24}>
            <Grid item xl={6}>
              <Card className="card-create">
                <CardHeader title="Kreiranje usluge partnera / klijenta" />
                <CardText>
                  <Grid container spacing={24}>
                    <Grid item sm={12} lg={6}>
                      <FormControl fullWidth error={error("/price")}>
                        <InputLabel shrink={true} htmlFor="price">
                          Cijena
                        </InputLabel>
                        <Input
                          id="price"
                          name="price"
                          value={service.price ? service.price : ""}
                          onChange={({ target: { value } }) =>
                            changeCreateService({
                              key: "price",
                              value: decimalNumbersTwoDigits(value),
                            })
                          }
                        />
                        {error("/price") && <FormHelperText>{errorMsg("/price")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                      <FormControl fullWidth error={error("/partner_client_service_type_id")}>
                        <TextField
                          fullWidth
                          id="select-currency"
                          select
                          label="Tip usluge"
                          className=""
                          value={service.partner_client_service_type_id || ""}
                          onChange={({ target: { value } }) =>
                            changeCreateService({
                              key: "partner_client_service_type_id",
                              value,
                            })
                          }
                        >
                          {listPartnerClientServiceType &&
                            listPartnerClientServiceType.map(type => (
                              <MenuItem key={type.id} value={type.id}>
                                {type.desc}
                              </MenuItem>
                            ))}
                        </TextField>
                        {error("/partner_client_service_type_id") && (
                          <FormHelperText>{errorMsg("/partner_client_service_type_id")}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <CreateServiceTranslations
                    error={error}
                    errorMsg={errorMsg}
                    listLanguage={listLanguage}
                    changeTranslationField={changeTranslationField}
                    service_translations={service.partner_client_service_translations}
                  />
                </CardText>
                <CardActions className="cardactions-button-padding">
                  <Button variant="contained" size="small" onClick={submitAndRecreate}>
                    Dodaj uslugu
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
      </FormValidator>
    );
  }
}

export default CreatePartnerClientServiceForm;
