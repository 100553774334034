import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React from "react";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import moment from "moment";
import _ from "lodash";
import toastr from "toastr";

import {
  updateOfferContractDocumentServiceType,
  deleteOfferContractDocumentServiceType,
} from "../../../../graphql/mutation/offers";

import DateRangePicker from "../../../../components/UI/DateRangePicker";
import ReactTableCustom from "../../../../components/UI/ReactTable";

import {
  updateToCreateServiceType,
  removeToCreateServiceType,
  removeCreateServiceType,
  updateCreatedServiceType,
  removeCreatedServiceType,
} from "../../../../store/actions";

import { findServiceTypeByLangId } from "../../../../utility/services";
import OfferForm from "../OfferForm";
import { FormHelperText, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "../../../../utility/dates";

// icon styles
const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
});

class ServiceList extends React.Component {
  state = {
    serviceTypeToEdit: null,
    oldServiceType: null,
  };

  /**
   * service type termin util functions
   */
  openToEdit = (expanded, index) => {
    let serviceType = this.dataToShow()[index[0]];
    serviceType = {
      ...serviceType,
      service_from: moment(serviceType.service_from, "DD-MM-YYYY"),
      service_to: moment(serviceType.service_to, "DD-MM-YYYY"),
    };

    if (!this.state.expanded) {
      this.setState({
        expanded,
        serviceTypeToEdit: { ...serviceType },
        oldServiceType: { ...serviceType },
      });
    } else {
      this.setState({
        expanded: {
          [index[0]]: !this.state.expanded[index[0]],
        },
        serviceTypeToEdit: { ...serviceType },
        oldServiceType: { ...serviceType },
      });
    }
  };

  /**
   * service type change termin from
   */
  changeServiceTypeDateFrom = service_from => {
    if (service_from) {
      this.setState(prev => ({
        serviceTypeToEdit: {
          ...prev.serviceTypeToEdit,
          service_from,
        },
      }));
    }
  };

  /**
   * service type change termin to
   */
  changeServiceTypeDateTo = service_to => {
    if (service_to) {
      this.setState(prev => ({
        serviceTypeToEdit: {
          ...prev.serviceTypeToEdit,
          service_to,
        },
      }));
    }
  };

  /**
   * update CREATED service type
   */
  updateCreatedServiceType = async service_type => {
    try {
      const response = await this.props.mutationUpdateCreatedServiceType({
        variables: {
          id: service_type.id,
          offer_contract_document_id: this.props.offer_contract_document_id,
          service_type_id: service_type.service_type_id,
          service_from: service_type.service_from,
          service_to: service_type.service_to,
        },
      });

      // get service type from response
      const serviceTypeUpdateResponse = _.get(response, "data.updateOfferContractDocumentServiceType", null);

      if (serviceTypeUpdateResponse) {
        toastr.success("Usluga uspješno ažurirana");

        // save service type without added properties
        this.props.updateCreatedServiceType(
          _.pick(serviceTypeUpdateResponse, ["id", "service_type_id", "service_from", "service_to"]),
        );

        this.setState({
          expanded: {},
          serviceTypeToEdit: null,
          oldServiceType: null,
        });
      }
    } catch {
      // handle error
      alert("Greška prilikom ažuriranja usluge");
    }
  };

  /**
   * delete CREATED service type with mutation
   */
  deleteCreatedServiceType = async service_type_id => {
    try {
      const response = await this.props.mutationDeleteCreatedServiceType({
        variables: {
          id: service_type_id,
        },
      });

      // get service type from response
      const serviceTypeDeleteIdResponse = _.get(response, "data.deleteOfferContractDocumentServiceType", null);

      if (serviceTypeDeleteIdResponse) {
        toastr.success("Usluga uspješno izbrisana");
        this.props.removeCreatedServiceType(serviceTypeDeleteIdResponse);
      }
    } catch {
      // handle error
      alert("Greška prilikom brisanja");
    }
  };

  /**
   * update TO_CREATE service type
   */
  updateToCreateServiceType = async serviceTypeToEdit => {
    // save service type without added properties
    await this.props.updateToCreateServiceType(
      _.pick(serviceTypeToEdit, ["id", "service_type_id", "service_from", "service_to"]),
    );

    this.setState({
      expanded: {},
      serviceTypeToEdit: null,
      oldServiceType: null,
    });
  };

  /**
   * delete TO_CREATE service type
   */
  deleteToCreateServiceType = async service_type_id => {
    this.props.removeToCreateServiceType(service_type_id);
  };

  /**
   * update service type
   */
  updateServiceType = () => {
    const { serviceTypeToEdit } = this.state;

    if (serviceTypeToEdit.key === "created") {
      this.updateCreatedServiceType(serviceTypeToEdit);
    } else if (serviceTypeToEdit.key === "to_create") {
      this.updateToCreateServiceType(serviceTypeToEdit);
    }
  };

  /**
   * remove service type
   */
  removeServiceType = service_type => {
    if (service_type.key === "created") {
      this.deleteCreatedServiceType(service_type.id);
    } else if (service_type.key === "to_create") {
      this.deleteToCreateServiceType(service_type.id);
    }
  };

  /**
   * find out which days are not in termin from to period
   */
  isDayBlocked = (day, service_type_to_edit_id) => {
    const { termin_periods, service_objects_to_create, offer_contract_document_services } = this.props;
    let blocked = true;

    // check for enabled dates from termin periods
    for (let i = 0; i < termin_periods.length; i++) {
      if (
        moment(day).isSameOrAfter(termin_periods[i].from, "day") &&
        moment(day).isSameOrBefore(termin_periods[i].to, "day")
      ) {
        blocked = false;
        break;
      }
    }

    // check for enabled dates from service_objects_to_create checked periods without serviceType which is edited
    if (!_.isNil(service_objects_to_create) && service_objects_to_create.length > 0) {
      // remove serviceTypeToEdit from list, since those days does not have to be blocked
      let serviceObjectsToCreateFiltered = service_objects_to_create.filter(
        serviceType => serviceType.id !== service_type_to_edit_id,
      );

      for (let i = 0; i < serviceObjectsToCreateFiltered.length; i++) {
        if (
          moment(day).isSameOrAfter(serviceObjectsToCreateFiltered[i].service_from, "day") &&
          moment(day).isSameOrBefore(serviceObjectsToCreateFiltered[i].service_to, "day")
        ) {
          blocked = true;
          break;
        }
      }
    }

    // check for enabled dates from created service type checked periods
    if (!_.isNil(offer_contract_document_services) && offer_contract_document_services.length > 0) {
      // remove serviceTypeToEdit from list, since those days does not have to be blocked
      let documentServicesFiltered = offer_contract_document_services.filter(
        serviceType => serviceType.id !== service_type_to_edit_id,
      );

      for (let i = 0; i < documentServicesFiltered.length; i++) {
        if (
          moment(day).isSameOrAfter(documentServicesFiltered[i].service_from, "day") &&
          moment(day).isSameOrBefore(documentServicesFiltered[i].service_to, "day")
        ) {
          blocked = true;
          break;
        }
      }
    }

    return blocked;
  };

  /**
   * data to show in service type list
   */
  dataToShow = () => {
    const { offer_contract_document_services, service_objects_to_create, listServiceType, language_id } = this.props;

    const service_types_created =
      offer_contract_document_services && offer_contract_document_services.length
        ? offer_contract_document_services.map(serviceTypeCreated => {
            const serviceType = listServiceType.find(
              service => _.parseInt(service.id) === _.parseInt(serviceTypeCreated.service_type_id),
            );

            const st = findServiceTypeByLangId(serviceType, language_id);

            const name = (st && st.name) || "";

            const id = serviceTypeCreated.id;
            const service_type_id = serviceTypeCreated.service_type_id;

            const service_from = formatDate(serviceTypeCreated.service_from);
            const service_to = formatDate(serviceTypeCreated.service_to);

            if (serviceType) {
              return {
                id,
                service_type_id,
                service_from,
                service_to,
                name,
                key: "created",
              };
            }
            return {};
          })
        : [];

    const service_types_to_create =
      service_objects_to_create && service_objects_to_create.length
        ? service_objects_to_create.map(serviceTypeToCreate => {
            const serviceType = listServiceType.find(
              service => _.parseInt(service.id) === _.parseInt(serviceTypeToCreate.service_type_id),
            );
            const st = findServiceTypeByLangId(serviceType, language_id);

            const name = (st && st.name) || "";

            const id = serviceTypeToCreate.id;
            const service_type_id = serviceTypeToCreate.service_type_id;

            const service_from = formatDate(serviceTypeToCreate.service_from);
            const service_to = formatDate(serviceTypeToCreate.service_to);

            if (serviceType) {
              return {
                id,
                service_type_id,
                service_from,
                service_to,
                name,
                key: "to_create",
              };
            }
            return {};
          })
        : [];

    return service_types_created.concat(service_types_to_create);
  };

  render() {
    const { isNotDraft } = this.props;
    const { serviceTypeToEdit } = this.state;

    return (
      <OfferForm.Consumer>
        {({ error, errorMsg }) => {
          const errorMessage =
            error("/offer_contract_document_services") || error("/service_objects_to_create") ? (
              <FormHelperText error={error("/offer_contract_document_services") || error("/service_objects_to_create")}>
                {errorMsg("/offer_contract_document_services")}
              </FormHelperText>
            ) : null;

          return (
            <div className={(errorMessage && "form-section-one") || ""}>
              {errorMessage}

              <div
                className={
                  error("/offer_contract_document_services") || error("/service_objects_to_create")
                    ? "textarea-error"
                    : ""
                }
              >
                <ReactTableCustom
                  data={this.dataToShow()}
                  columns={[
                    ...(!isNotDraft
                      ? [
                          {
                            Header: "Edit",
                            columns: [
                              {
                                expander: true,
                                Header: () => <strong>More</strong>,
                                width: 65,

                                Expander: ({ isExpanded, ...rest }) => {
                                  return !isNotDraft ? (
                                    <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                                  ) : null;
                                },
                                style: {
                                  cursor: "pointer",
                                  fontSize: 25,
                                  padding: "0",
                                  textAlign: "center",
                                  userSelect: "none",
                                },
                              },
                            ],
                          },
                        ]
                      : []),

                    {
                      Header: "Dodane usluge",
                      columns: [
                        {
                          Header: "Usluga",
                          accessor: "name",
                          Cell: ({ value }) => <div style={{ textAlign: "center", width: "100%" }}>{value}</div>,
                        },
                        {
                          Header: "Datum od",
                          accessor: "service_from",
                          Cell: ({ value }) => (
                            <div style={{ textAlign: "center", width: "100%" }}>
                              {value !== "Invalid date" ? value : "Nema datuma"}
                            </div>
                          ),
                        },
                        {
                          Header: "Datum do",
                          accessor: "service_to",
                          Cell: ({ value }) => (
                            <div style={{ textAlign: "center", width: "100%" }}>
                              {value !== "Invalid date" ? value : "Nema datuma"}
                            </div>
                          ),
                        },
                        ...(!isNotDraft
                          ? [
                              {
                                Header: "Izbriši",
                                id: "delete_service",
                                accessor: service_type =>
                                  !isNotDraft ? (
                                    <DeleteForeverIcon
                                      className={this.props.classes.icon}
                                      onClick={() => this.removeServiceType(service_type)}
                                    />
                                  ) : null,
                              },
                            ]
                          : []),
                      ],
                    },
                  ]}
                  innerProps={{
                    pageSize: this.dataToShow().length,
                    showPagination: false,
                    sortable: false,
                    filterable: false,
                    filterAll: false,
                    expanded: this.state.expanded,
                    onExpandedChange: this.openToEdit,
                    ...(!isNotDraft
                      ? {
                          SubComponent: () => {
                            if (!serviceTypeToEdit) {
                              return "";
                            }

                            return (
                              <div style={{ padding: "12px" }}>
                                <DateRangePicker
                                  from={serviceTypeToEdit.service_from._isValid ? serviceTypeToEdit.service_from : null}
                                  to={serviceTypeToEdit.service_to._isValid ? serviceTypeToEdit.service_to : null}
                                  handleFromChange={this.changeServiceTypeDateFrom}
                                  handleToChange={this.changeServiceTypeDateTo}
                                  // Rerender function to update styling
                                  isDayBlocked={day => this.isDayBlocked(day, serviceTypeToEdit.id)}
                                  appendToBody
                                />
                                <div className="subtermin-create-buttons">
                                  <Button variant="contained" color="primary" onClick={this.updateServiceType}>
                                    Spremi
                                  </Button>
                                </div>
                              </div>
                            );
                          },
                        }
                      : {}),
                  }}
                />
              </div>
            </div>
          );
        }}
      </OfferForm.Consumer>
    );
  }
}

const mapStateToProps = state => {
  const {
    offer: {
      offer_contract_document: { id: offer_contract_document_id, termin_periods, offer_contract_document_services },
    },
  } = state.offer;

  return {
    offer_contract_document_id,
    offer_contract_document_services,
    termin_periods,
  };
};

export default compose(
  graphql(updateOfferContractDocumentServiceType, { name: "mutationUpdateCreatedServiceType" }),
  graphql(deleteOfferContractDocumentServiceType, { name: "mutationDeleteCreatedServiceType" }),
)(
  connect(mapStateToProps, {
    updateToCreateServiceType,
    removeToCreateServiceType,
    removeCreateServiceType,
    updateCreatedServiceType,
    removeCreatedServiceType,
  })(withStyles(styles)(ServiceList)),
);
