import React, { Component, Fragment } from "react";
import { FormControl, InputLabel, Input } from "@material-ui/core";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplatePaying extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`payment_content`).length) {
      autosize(document.getElementsByName(`payment_content`)[0]);
    }
  }

  render() {
    const { isNotDraft } = this.props;

    return (
      <Fragment>
        <div className="field-label ">
          <label className="label">Plaćanje</label>
        </div>
        <div>
          <FormControl>
            <InputLabel htmlFor="notification_payment">Podsjetnik za plaćanje</InputLabel>
            <Input
              id="notification_payment"
              className="template-input"
              inputProps={{
                name: "notification_payment",
              }}
              value={this.props.notification_payment}
              onChange={this.props.changeContent}
              disabled={isNotDraft}
            />
          </FormControl>
          <div>
            <TextArea
              inputProps={{
                name: "payment_content",
                disabled: isNotDraft,
              }}
              defaultValue={this.props.payment_content}
              customOnChange={this.props.changeContent}
              rows="3"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TemplatePaying;
