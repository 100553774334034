import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getNestedObject } from "../../../../utility/globals";
import moment from "moment";
import { getPartnerClient } from "../../../../graphql/query/administration";
import { withApollo } from "react-apollo";

const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";
class AuditLogHandler extends React.PureComponent {
  state = {
    partnerClientOld: "",
    partnerClientNew: "",
  };

  componentDidMount() {
    const {
      entry: { before, after, subKey },
    } = this.props;

    // we only need fetch partner_client
    if (subKey !== "partner_client_id") {
      return;
    }

    this.fetchPartnerClient(before, false);
    this.fetchPartnerClient(after, true);
  }

  fetchPartnerClient = async (id, isAfter) => {
    // try to find in cache too
    const response = await this.props.client.query({
      query: getPartnerClient,
      variables: {
        id: id,
      },
    });

    this.setState({
      [isAfter ? "partnerClientNew" : "partnerClientOld"]: getNestedObject(response, [
        "data",
        "getPartnerClient",
        "name",
      ]),
    });
  };

  render() {
    let {
      entry: { before, after, changedField, index, time, user, subKey, key, deleted },
      listOfferStatus,
      listOfferContractDocumentType,
      listOfferContractDocumentSegment,
      listLanguage,
      listServiceType,
    } = this.props;

    switch (key) {
      case "offers":
        // determine key name
        switch (subKey) {
          case "offer_status_id":
            changedField = "Status";

            before = getNestedObject(listOfferStatus.find(entry => entry.id === before.toString()), ["desc"]);

            after = getNestedObject(listOfferStatus.find(entry => entry.id === after.toString()), ["desc"]);
            break;
          case "storno_comment":
            changedField = "Storno komentar";
            break;
          case "external_id":
            changedField = "Broj identifikatora";
            break;
          case "off_key":
            changedField = "Off key";
            break;
          /*
          // this doesnt exist yet in offer, but it should. Soon(tm)
          case "transport":
            changedField = "Transport";
            before = before ? "Da" : "Ne";
            after = after ? "Da" : "Ne";
            break;*/
          default:
            break;
        }
        break;
      case "offer_contract_documents":
        switch (subKey) {
          case "partner_client_id":
            changedField = "Agencija/Klijent";
            before = this.state.partnerClientOld;
            after = this.state.partnerClientNew;
            break;
          case "comment_establishment":
            changedField = "Smještaj komentar";
            break;
          case "comment_service":
            changedField = "Osnovna usluga komentar";
            break;
          case "comment_contigent":
            changedField = "Kontigent komentar";
            break;
          case "service_package":
            changedField = "Paket usluga komentar";
            break;
          case "document_date_validity_comment":
            changedField = "Datum opcije ponude komentar";
            break;
          case "language_id":
            changedField = "Jezik";
            before = getNestedObject(listLanguage.find(entry => entry.id === before.toString()), ["code"]);
            after = getNestedObject(listLanguage.find(entry => entry.id === after.toString()), ["code"]);
            break;
          case "offer_contract_document_segment_id":
            changedField = "Segment";
            before = getNestedObject(listOfferContractDocumentSegment.find(entry => entry.id === before.toString()), [
              "desc",
            ]);
            after = getNestedObject(listOfferContractDocumentSegment.find(entry => entry.id === after.toString()), [
              "desc",
            ]);
            break;
          case "offer_contract_document_type_id":
            changedField = "Tip ponude";
            before = getNestedObject(listOfferContractDocumentType.find(entry => entry.id === before.toString()), [
              "desc",
            ]);
            after = getNestedObject(listOfferContractDocumentType.find(entry => entry.id === after.toString()), [
              "desc",
            ]);
            break;
          case "document_code":
            changedField = "Kod upita";
            break;
          case "vat_content":
            changedField = "Porez";
            break;
          case "sojourn_tax_value":
            changedField = "Boravišna pristrojba";
            break;
          case "sojourn_tax_content":
            changedField = "Boravišna pristrojba";
            break;
          case "free_place_content":
            changedField = "Slobodno mjesto";
            break;
          case "price_validity_content":
            changedField = "Minimalni broj osoba";
            break;
          case "notification_price_validity":
            changedField = "Minimalni broj osoba";
            break;
          case "notification_booking_state":
            changedField = "Podsjetnik za booking";
            break;
          case "booking_state_content":
            changedField = "Stanje bookinga";
            break;
          case "room_list_content":
            changedField = "Stanje soba";
            break;
          case "notification_room_list":
            changedField = "Podsjetnik za sobe";
            break;
          case "program_content":
            changedField = "Program";
            break;
          case "content_content":
            changedField = "Sadržaj";
            break;
          case "program_changes_content":
            changedField = "Promjene programa";
            break;
          case "storno_content":
            changedField = "Storno";
            break;
          case "notification_storno":
            changedField = "Podsjetnik za storno";
            break;
          case "payment_content":
            changedField = "Plaćanje";
            break;
          case "notification_payment":
            changedField = "Podsjetnik za plaćanje";
            break;
          case "surcharges_content":
            changedField = "Dodatno";
            break;
          default:
            break;
        }
        break;
      case "termin_periods":
        switch (subKey) {
          // remove from here, this is transport from booking!
          // case "transport":
          //   changedField = "Transport";
          //   before = before ? "Da" : "Ne";
          //   after = after ? "Da" : "Ne";
          //   break;
          case "from":
            changedField = "Od (termin " + (index + 1) + ")";
            before = moment(before).format(DEFAULT_DATE_FORMAT);
            after = moment(after).format(DEFAULT_DATE_FORMAT);
            break;
          case "to":
            changedField = "Do (termin " + (index + 1) + ")";
            before = moment(before).format(DEFAULT_DATE_FORMAT);
            after = moment(after).format(DEFAULT_DATE_FORMAT);
            break;
          default:
            break;
        }
        break;
      case "contigents":
        switch (subKey) {
          case "typologies_sum":
            changedField = "Broj tipologija (kontigent r.b. " + index + 1 + ")";
            break;
          default:
            break;
        }
        break;
      case "services":
        switch (subKey) {
          case "service_id":
            before = getNestedObject(listServiceType.find(entry => entry.id === before), [0, "name"]);
            if (!deleted) {
              after = before;
            }
            break;
          default:
            break;
        }
        break;
      case "additional_services":
        switch (subKey) {
          case "actual_price":
            changedField = "Cijena dodatne usluge";
            break;
          case "activity_id":
            changedField = "Aktivnost";
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    // handle cases as usual, but if it's deleted, change after text
    if (deleted) {
      after = "OBRISANO";
    }

    return (
      <TableRow>
        <TableCell padding="none" component="th" scope="row" classes={{ body: "color-red" }}>
          {before}
        </TableCell>
        <TableCell padding="dense" classes={{ body: "color-green" }}>
          {after}
        </TableCell>
        <TableCell padding="dense">{changedField}</TableCell>
        <TableCell padding="dense">{user}</TableCell>
        <TableCell padding="none">{moment(time).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
      </TableRow>
    );
  }
}

export default withApollo(AuditLogHandler);
