// libs
import { useSelector } from "react-redux";

// redux state
import { selectAnnouncementSelected } from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

/**
 * useAnnouncementEditContainer is entry point of data for announcementEditContainer component
 */
export default function useAnnouncementEditContainer() {
  // redux state
  const announcementSelected = useSelector(selectAnnouncementSelected);

  return { announcementSelected };
}
