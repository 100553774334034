import { useEffect } from "react";

// libs
import { get } from "lodash";
import { useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// mutations
import { PROGRAM_DAY_ACTIVITIES } from "src/graphql/query/operative";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectProgramDayActivities,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

/**
 * useTripContainer is entry point of data for tripContainerComponent
 */
export default function useTripContainer() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const programDayActivities = useSelector(selectProgramDayActivities);

  const dispatch = useDispatch();

  // fetch trip activities
  const { data, loading } = useQuery(PROGRAM_DAY_ACTIVITIES, {
    variables: { program_id: guidePayload.programId },
    skip: !guidePayload.programId,
    notifyOnNetworkStatusChange: true,
  });

  // set guides in context
  useEffect(() => {
    const programDayActivities = get(data, "getProgramDayActivityPartnerGuideLayout");

    if (programDayActivities) {
      dispatch(setProgramDayActivities(programDayActivities));
    }
  }, [data, dispatch]);

  return {
    loading,
    programDayActivities,
  };
}
