import React, { Component } from "react";

import Preview from "../../../../components/Preview/Preview";
import { setPreview } from "../../../../store/actions/previewActions";

import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { createOfferData } from "../../../../graphql/query/offers";

class PreviewOffer extends Component {
  async componentDidMount() {
    const preview = localStorage.getItem("preview");

    try {
      const response = await this.props.client.query({
        query: createOfferData
      });

      this.props.setPreview(JSON.parse(preview), response.data);
    } catch (error) {}
  }

  render() {
    return <Preview />;
  }
}

export default connect(
  null,
  { setPreview }
)(withApollo(PreviewOffer));
