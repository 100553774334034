import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Chip } from "@material-ui/core";

import { changeDocumentParam } from "../../../../store/actions";

const ContractField = props =>
  props.contract_id ? (
    <div className="form-unit-double">
      <div className="field-label is-autocomplete">
        <label className="label">Ugovor</label>
      </div>
      <div>
        <Chip
          label={props.contract_external_id}
          onClick={() =>
            props.history.push(`/ugovor/edit/${props.contract_id}`)
          }
          target="_blank"
        />
      </div>
    </div>
  ) : null;

export default connect(
  ({
    offer: {
      offer: {
        offer_contract_document: { contract }
      }
    }
  }) => ({
    contract_id: contract ? contract.id : "",
    contract_external_id: contract ? contract.external_id : ""
  }),
  { changeDocumentParam }
)(withRouter(ContractField));
