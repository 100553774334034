import React, { Component } from "react";
import CreateActivity from "./Activity/CreateActivity";
import EditActivity from "./Activity/EditActivity";
import ActivityList from "./Activity/ActivityList";
import CreateActivityType from "./ActivityType/CreateActivityType";
import EditActivityType from "./ActivityType/EditActivityType";
import ActivityTypeList from "./ActivityType/ActivityTypeList";

const ActivityTabsHOC = WrappedComponent => {
  return class extends Component {
    state = {
      activityCreate: false,
      activityEdit: false,
      activity: "",
      activityTypeCreate: false,
      activityTypeEdit: false,
      activityType: "",
    };

    activityCreateToggle = () => {
      this.setState(prevState => {
        return {
          activityCreate: !prevState.activityCreate,
        };
      });
    };

    activityEditOpen = activity => {
      this.setState({
        activity,
        activityEdit: true,
      });
    };

    activityEditClose = () => {
      this.setState({
        activity: "",
        activityEdit: false,
      });
    };

    activityTypeCreateToggle = () => {
      this.setState(prevState => {
        return {
          activityTypeCreate: !prevState.activityTypeCreate,
        };
      });
    };

    activityTypeEditOpen = activityType => {
      this.setState({
        activityType,
        activityTypeEdit: true,
      });
    };

    activityTypeEditClose = () => {
      this.setState({
        activityType: "",
        activityTypeEdit: false,
      });
    };

    showInTabActivity = () => {
      const { activityCreate, activityEdit, activity } = this.state;

      if (activityCreate) {
        return <CreateActivity cancelToggle={this.activityCreateToggle} />;
      }
      if (activityEdit) {
        return <EditActivity closeEdit={this.activityEditClose} activity={activity} />;
      }
      return (
        <ActivityList
          tableName={"administrationActivitiesTable"}
          toggleCreate={this.activityCreateToggle}
          openEdit={this.activityEditOpen}
        />
      );
    };

    showInTabActivityType = () => {
      const { activityTypeCreate, activityTypeEdit, activityType } = this.state;

      if (activityTypeCreate) {
        return <CreateActivityType cancelToggle={this.activityTypeCreateToggle} />;
      }
      if (activityTypeEdit) {
        return <EditActivityType activityType={activityType} closeEdit={this.activityTypeEditClose} />;
      }
      return (
        <ActivityTypeList
          tableName={"administrationActivityTypesTable"}
          toggleCreate={this.activityTypeCreateToggle}
          openEdit={this.activityTypeEditOpen}
        />
      );
    };

    render() {
      return (
        <WrappedComponent
          showInTabActivity={this.showInTabActivity}
          showInTabActivityType={this.showInTabActivityType}
        />
      );
    }
  };
};

export default ActivityTabsHOC;
