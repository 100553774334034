import React, { Component } from "react";
import InquiryForm from "../Form/InquiryForm";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import { fetchInquiry } from "../../../graphql/query/inquiry";
import { updateInquiry, deleteInquiryFile, inquiryAttachmentUpload } from "../../../graphql/mutation/inquiry";
import { listPartnerClient } from "../../../graphql/query/administration";

import toastr from "toastr";
import { where_agency_2 } from "../../../utility/globals";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";

class EditInquiry extends Component {
  state = {
    inquiry: {
      partner_client_id: "",
      title: "",
      remark: "",
      external_id: "",
      off_key: "",
      year: "",
    },
    selectedFiles: "",
  };

  async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: fetchInquiry,
        variables: { id: this.props.match.params.id },
        fetchPolicy: "network-only",
      });

      const {
        data: { getInquiry },
      } = response;
      await this.setFetchedInquiryFromServer(getInquiry);
    } catch (error) {}
  }

  setFetchedInquiryFromServer = async getInquiry => {
    this.setState({
      inquiry: {
        ...getInquiry,
      },
      inquiry_clone: {
        ...getInquiry,
        partner_client_id: parseInt(getInquiry.partner_client_id),
      },
    });
  };

  onDrop = files => {
    this.setState(
      {
        selectedFiles: files,
      },
      () => this.uploadAttachments(),
    );
  };

  changeData = name => selected =>
    this.setState(oldState => {
      return {
        inquiry: { ...oldState.inquiry, [name]: selected },
      };
    });

  changeInquiryFieldData = name => ({ target: { value } }) =>
    this.setState(oldState => {
      return {
        inquiry: { ...oldState.inquiry, [name]: value },
      };
    });

  uploadAttachments = async () => {
    if (this.state.selectedFiles) {
      const { selectedFiles } = this.state;

      if (selectedFiles && selectedFiles.length) {
        try {
          selectedFiles.forEach(async file => {
            const res = await this.props.uploadDocument({
              variables: {
                inquiry_id: this.state.inquiry.id,
                file,
              },
            });

            const { inquiryAttachmentUpload } = res.data;

            toastr.success("Datoteke uspješno prenešene");

            this.setState(oldState => {
              const attachments_clone = [...oldState.inquiry.attachments];
              attachments_clone.push(inquiryAttachmentUpload);

              return {
                inquiry: {
                  ...oldState.inquiry,
                  attachments: attachments_clone,
                },
              };
            });
          });
          this.setState({ selectedFiles: "" });
        } catch (error) {}
      }
    }
  };

  submitData = async () => {
    const {
      inquiry,
      inquiry: { partner_client_id, title, remark, external_id, off_key },
      inquiry_clone,
    } = this.state;

    const valueArray = external_id.split("-");

    if (external_id === "-") {
      toastr.warning(
        "Format povijesnog broja mora biti zapisan kao xxxx-yy ili xxxx i obavezno odabrati godinu na koju se upit odnosi",
      );
      return;
    }

    // invalid - 12345 , 1234 - valid
    if (valueArray.length === 1 && valueArray[0].length > 4) {
      toastr.warning("Format povijesnog broja može imati maksimalno 4 slova/broja i potrebno je odabrati godinu upita");
      return;
    }

    // 969-2020-20 - invalid
    if (valueArray.length > 2) {
      toastr.warning(
        "Format povijesnog broja mora biti zapisan kao xxxx-yy ili xxxx i obavezno odabrati godinu na koju se upit odnosi",
      );
      return;
    }

    // 12345-20 - invalid
    // 1234-202 - invalid
    if (valueArray[0].length > 4 || (valueArray[1] && valueArray[1].length > 2)) {
      toastr.warning("Format povijesnog broja može imati maksimalno 4 slova/broja + '-' + godinu u formatu YY");
      return;
    }

    if (valueArray[0].length > 4 || (valueArray[1] && valueArray[1].length > 2)) {
      toastr.warning("Format povijesnog broja može imati maksimalno 4 slova/broja + '-' + godinu u formatu YY");
      return;
    }

    if (!/^\d+$/.test(valueArray[0]) || !/^\d+$/.test(valueArray[1])) {
      toastr.warning("Povijesni broj mora biti zapisan kao broj");
      return;
    }

    if (JSON.stringify(inquiry) !== JSON.stringify(inquiry_clone)) {
      try {
        await this.props.mutate({
          variables: {
            id: inquiry_clone.id,
            patch: {
              partner_client_id,
              title,
              remark,
              external_id,
              off_key,
            },
          },
        });

        // set inquiry clone to updated inquiry
        this.setState({
          inquiry_clone: {
            ...inquiry,
          },
        });

        toastr.success("Podaci upita ažurirani");
      } catch (error) {}
    } else {
      toastr.warning("Nema promjena u podacima upita");
    }
  };

  deleteUploadedFile = async file_id => {
    try {
      await this.props.deleteInquiryFile({
        variables: {
          file_id,
        },
      });

      this.setState(oldState => {
        return {
          inquiry: {
            ...oldState.inquiry,
            attachments: oldState.inquiry.attachments.filter(attach => attach.id !== file_id),
          },
        };
      });

      toastr.success("Uspješno izbrisano");
    } catch (error) {}
  };

  render() {
    const {
      listPartnerClient: { loading, listPartnerClient },
    } = this.props;

    if (loading || !listPartnerClient) {
      return <div> Waiting for data from server . . . </div>;
    }

    if (!this.state.inquiry.id) {
      return null;
    }

    return (
      <InquiryForm
        title={`Uređivanje upita ${this.state.inquiry.id}`}
        actionTitle="Ažuriraj"
        submitData={this.submitData}
        changeData={this.changeData}
        inquiry={this.state.inquiry}
        listPartnerClient={listPartnerClient}
        onDrop={this.onDrop}
        deleteUploadedFile={this.deleteUploadedFile}
        files={this.state.selectedFiles}
        changeInquiryFieldData={this.changeInquiryFieldData}
        listPartnerClientQuery={this.props.listPartnerClient}
        edit
      />
    );
  }
}

export default compose(
  graphql(updateInquiry),
  graphql(inquiryAttachmentUpload, { name: "uploadDocument" }),

  graphql(deleteInquiryFile, { name: "deleteInquiryFile" }),
  graphql(listPartnerClient, {
    name: "listPartnerClient",
    options: {
      variables: {
        input: {
          paginationLimit: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
          },
          where: where_agency_2,
        },
      },
    },
  }),
)(withApollo(EditInquiry));
