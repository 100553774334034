import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";

import "../../../styles/offerForm.css";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// GRAPHQL
import { createOfferData } from "../../../graphql/query/offers";
import { updateOfferArchiveStatus } from "../../../graphql/mutation/offers";
// redux store
import { resetStore, changeOfferParameter } from "../../../store/actions";

import Paper from "material-ui/Paper";
import CircularProgress from "material-ui/CircularProgress";
import Grid from "@material-ui/core/Grid";

// Shared components
import DividerUI from "../../../components/UI/Divider";
import OfferToolbar, { OfferDuplicate } from "./OfferToolbar";

// CreateOffer parts
import AgencyAndPrograms from "./AgencyAndPrograms/AgencyAndPrograms";
import EstablishmentData from "./EstablishmentData/EstablishmentData";
import TerminData from "./TerminData/TerminData";
import PriceStructure from "./PriceStructure/PriceStructure";
import DailyProgram from "./DailyProgram/DailyProgram";
import OfferDateValidity from "./OfferDateValidity/OfferDateValidity";
import DateStatusType from "./DateStatusType/DateStatusType";
import EstablishmentService from "./Service/EstablishmentService";
import Templates from "./Templates/Templates";
import AdditionalOffers from "./AdditionalOffers/AdditionalOffers";

import DocumentsUpload from "./DocumentsUpload/Documents";
import InquiryData from "../../Inquiry/InquiryData/InquiryData";
import ContractField from "./ContractField/ContractField";
import DailyProgramUsage from "./DailyProgram/DailyProgramUsage";
import FormValidator from "../../../components/UI/FormValidator";
import { offer_schema } from "./schema";
import { withRouter } from "react-router";
import AuditLog from "./AuditLog/AuditLog";
import { listPartnerClient, listEstablishment } from "../../../graphql/query/administration";
import { where_agency_2, config } from "../../../utility/globals";
import ShowDailyProgram from "./DailyProgram/ShowDailyProgram";
import ProgramOnlyDailyProgramCreate from "./DailyProgram/ProgramOnlyDailyProgramCreate";
import AlotmanTermin from "./Alotman/AlotmanTermin";
import AlotmanTemplates from "./Templates/AlotmanTemplates";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../utility/constants";

const OfferFormContext = React.createContext();

class OfferForm extends Component {
  static ConsumerHOC = props => (
    <OfferFormContext.Consumer>
      {({ error, errorMsg, errors }) =>
        React.Children.map(props.children, child =>
          React.cloneElement(child, {
            ...props,
            error,
            errorMsg,
            errors,
          }),
        )
      }
    </OfferFormContext.Consumer>
  );
  static Consumer = OfferFormContext.Consumer;

  static schema = offer_schema;

  state = {
    doTheValidation: true,
  };

  componentWillUnmount() {
    this.props.resetStore();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.match.params.id && !this.state.doTheValidation) {
      this.setState({
        doTheValidation: true,
      });
    }
    // if in edit and changing status
    else if (
      this.props.match.params.id &&
      Number(this.props.offer_status_id) !== Number(this.props.offer_copy_status_id) &&
      !this.state.doTheValidation
    ) {
      this.setState({
        doTheValidation: true,
      });
    } else if (
      this.props.match.params.id &&
      Number(this.props.offer_status_id) === Number(this.props.offer_copy_status_id) &&
      this.state.doTheValidation
    ) {
      this.setState({
        doTheValidation: false,
      });
    }
  }

  render() {
    if (!this.props.listPartnerClientQuery || !this.props.listEstablishmentQuery) {
      return <CircularProgress />;
    }

    const loading = get(this.props, "data.loading");

    const listOfferStatus = get(this.props, "data.listOfferStatus", []);
    const listLanguage = get(this.props, "data.listLanguage", []);
    const listOfferContractDocumentType = get(this.props, "data.listOfferContractDocumentType", []);
    const listTemplateTranslation = get(this.props, "data.listTemplateTranslation", []);
    const listServiceType = get(this.props, "data.listServiceType", []);
    const listOfferContractDocumentSegment = get(this.props, "data.listOfferContractDocumentSegment", []);
    const listPriceStructureType = get(this.props, "data.listPriceStructureType", []);
    const listActivity = get(this.props, "data.listActivity", []);
    const listPartnerClientService = get(this.props, "data.listPartnerClientService", []);

    const listPartnerClientLoading = get(this.props, "listPartnerClientQuery.loading");

    const listEstablishmentLoading = get(this.props, "listEstablishmentQuery.loading");
    const listEstablishment = get(this.props, "listEstablishmentQuery.listEstablishment", []);

    if (loading || listEstablishmentLoading || listPartnerClientLoading) {
      return <CircularProgress />;
    }

    const {
      offer,
      offer: {
        offer_status_id,
        offer_contract_document: { offer_contract_document_type_id },
      },
    } = this.props;
    const isNotDraft = offer_status_id !== "1";

    // Audit log
    let auditLog = null;
    if (offer.id) {
      auditLog = (
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <DividerUI subheaderText="Lista izmjena na dokumentu" />
          </Grid>
          <Grid item xs={12}>
            <div className="auditLogLimited">
              <AuditLog offerId={offer.id} />
            </div>
          </Grid>
        </Grid>
      );
    }

    const isAlotman = config.offer_type[offer_contract_document_type_id] === "Alotman";

    const programOnlyTermin = config.offer_type[offer_contract_document_type_id] === "Program only" && (
      <Fragment>
        <ProgramOnlyDailyProgramCreate />
      </Fragment>
    );

    const terminStandardAndTour = config.offer_type[offer_contract_document_type_id] !== "Program only" &&
      config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
        <Fragment>
          <TerminData
            listEstablishment={listEstablishment}
            listEstablishmentQuery={this.props.listEstablishmentQuery}
            isNotDraft={isNotDraft}
          />
        </Fragment>
      );

    let establishmentData = null;
    if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
      establishmentData = (
        <EstablishmentData
          isNotDraft={isNotDraft}
          listEstablishment={listEstablishment}
          listEstablishmentQuery={this.props.listEstablishmentQuery}
        />
      );
    }

    let priceStructure = null;
    if (config.offer_type[offer_contract_document_type_id] !== "Program only") {
      priceStructure = <PriceStructure listPriceStructureType={listPriceStructureType} isNotDraft={isNotDraft} />;
    }

    return (
      <FormValidator
        // recreate form on state change
        key={this.state.doTheValidation ? "formValidator" - new Date().getTime() : null}
        schema={this.state.doTheValidation ? OfferForm.schema : {}}
        submit={this.props.onSave}
        data={cloneDeep(offer)}
      >
        {({ error, errorMsg, errors, submit, validator }) => {
          return (
            <OfferFormContext.Provider
              value={{
                error,
                errorMsg,
                errors: validator.validator.errors,
                listOfferStatus,
                listOfferContractDocumentType,
                listOfferContractDocumentSegment,
                listLanguage,
                listServiceType,
              }}
            >
              <React.Fragment>
                {/* // Using this only to get offer_contract_document id */}
                <OfferDuplicate.Provider
                  value={{
                    offer_contract_document_id: this.props.match.params.id ? offer.offer_contract_document.id : null,
                  }}
                >
                  <OfferToolbar title={this.props.title} onSave={submit} />
                </OfferDuplicate.Provider>
                <Paper>
                  <form className="form">
                    <div style={{ paddingBottom: "16px" }}>
                      {/* STATUS & OFFER TYPE PICK */}
                      <DateStatusType
                        listOfferStatus={listOfferStatus}
                        listOfferContractDocumentType={listOfferContractDocumentType}
                        listOfferContractDocumentSegment={listOfferContractDocumentSegment}
                        listLanguage={listLanguage}
                        isNotDraft={isNotDraft}
                      />
                    </div>
                    <Grid container spacing={32}>
                      <Grid item xs={12}>
                        <ContractField />
                      </Grid>
                      <Grid item xs={12}>
                        <DocumentsUpload
                          deletePreparedFileToUpload={this.props.deletePreparedFileToUpload}
                          deleteUploadedFile={this.props.deleteUploadedFile}
                          onDrop={this.props.onDrop}
                          files={this.props.files}
                          isNotDraft={isNotDraft}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InquiryData />
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container spacing={24}>
                          <Grid item xs={12}>
                            <DividerUI subheaderText="Info o klijentu i programu" />
                          </Grid>
                          <Grid item xs={12}>
                            <AgencyAndPrograms
                              error={error}
                              errorMsg={errorMsg}
                              listPartnerClientQuery={this.props.listPartnerClientQuery}
                              isNotDraft={isNotDraft}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {auditLog}
                      </Grid>
                    </Grid>{" "}
                    <div className="second-wrapper">
                      <DividerUI subheaderText="Odabir smještaja i termina" />
                      {programOnlyTermin}
                      {terminStandardAndTour}
                    </div>
                    <div className="cols-2">
                      <div className="form-section-one">
                        {establishmentData}

                        {/* Alotman Termin when contigent is picked */}
                        {config.offer_type[offer_contract_document_type_id] === "Alotman" && (
                          <AlotmanTermin isNotDraft={isNotDraft} />
                        )}

                        <EstablishmentService listServiceType={listServiceType} isNotDraft={isNotDraft} />
                        {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
                          <AdditionalOffers listActivity={listActivity} isNotDraft={isNotDraft} />
                        )}
                      </div>
                    </div>
                    <div className="second-wrapper">
                      {/* SHOWING AFTER TERMIN IS PICKED */}

                      {priceStructure}

                      {isAlotman && <AlotmanTemplates isNotDraft={isNotDraft} />}
                      {!isAlotman && (
                        <Templates listTemplateTranslation={listTemplateTranslation} isNotDraft={isNotDraft} />
                      )}
                      <OfferDateValidity isNotDraft={isNotDraft} />
                      {/* NOT showing only in alotman */}
                      {config.offer_type[offer_contract_document_type_id] !== "Alotman" && (
                        <React.Fragment>
                          {/* Program po danima */}
                          <DividerUI subheaderText="Program po danu i aktivnostima" />
                          <DailyProgramUsage
                            listActivity={listActivity}
                            listPartnerClientService={listPartnerClientService}
                          >
                            <ShowDailyProgram isNotDraft={isNotDraft} />
                            <DailyProgram isNotDraft={isNotDraft} />
                          </DailyProgramUsage>
                        </React.Fragment>
                      )}
                    </div>
                  </form>
                </Paper>
              </React.Fragment>
            </OfferFormContext.Provider>
          );
        }}
      </FormValidator>
    );
  }
}

const mapStateToProps = state => {
  const { offer, offer_copy } = state.offer;

  const offer_status_id = get(offer, "offer_status_id");
  const offer_copy_status_id = get(offer_copy, "offer_status_id");

  return {
    offer,
    offer_status_id,
    offer_copy_status_id,
  };
};

export default connect(mapStateToProps, {
  resetStore,
  changeOfferParameter,
})(
  compose(
    graphql(createOfferData),
    graphql(listPartnerClient, {
      name: "listPartnerClientQuery",
      // do not execute this query until createOfferData is done, check this by looking whether listOfferStatus is set
      skip: ({ data: { listOfferStatus } }) => !listOfferStatus,
      options: {
        fetchPolicy: "network-only",
        variables: {
          input: {
            paginationLimit: {
              limit: PAGINATION_LIMIT,
              offset: PAGINATION_OFFSET,
            },
            where: where_agency_2,
          },
        },
      },
    }),
    graphql(listEstablishment, {
      name: "listEstablishmentQuery",
      // do not execute this query until createOfferData is done, check this by looking whether listOfferStatus is set
      skip: ({ data: { listOfferStatus } }) => !listOfferStatus,
      options: {
        fetchPolicy: "network-only",
        variables: {
          input: {
            paginationLimit: {
              limit: PAGINATION_LIMIT,
              offset: PAGINATION_OFFSET,
            },
          },
        },
      },
    }),
    graphql(updateOfferArchiveStatus, { name: "mutationUpdateOfferArchiveStatus" }),
  )(withRouter(OfferForm)),
);
