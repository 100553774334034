import React from "react";
import moment from "moment";

import DatePicker from "./DatePicker";
import "./CustomDatePicker.css";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

class CustomDatePicker extends React.Component {
  pickedDay = value => {
    if (!value) {
      return null;
    }
    return moment(value.value, DEFAULT_DATE_FORMAT).isValid() ? moment(value.value, DEFAULT_DATE_FORMAT) : null;
  };

  render() {
    let { value, onChange, ...rest } = this.props;

    return (
      <div id="customSDP">
        <DatePicker
          value={this.pickedDay(value)}
          handleChange={this.props.onChange}
          appendToBody
          numberOfMonths={1}
          showClearDate
          small
          noBorder
          {...rest}
        />
      </div>
    );
  }
}

export default CustomDatePicker;
