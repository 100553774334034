import React from "react";

// hooks
import useSubject from "./hooks/useSubject";

// components
import TextFieldMultiline from "src/components/UI/TextFieldMultilineUncontrolled";
// style
import "./style.css";
// constants
import { TITLE_GUIDE_SUBJECT, HINT_GUIDE_THEME_NAME } from "src/utility/labels";

/**
 * used for rendering subject input value
 */
export default function Subject() {
  // hook
  const { themeName, onThemeNameChange } = useSubject();

  // return root
  return (
    <div>
      <label className="label">{TITLE_GUIDE_SUBJECT}</label>

      <TextFieldMultiline
        rows={1}
        defaultValue={themeName}
        placeholder={HINT_GUIDE_THEME_NAME}
        onChange={event => onThemeNameChange(event)}
      />
    </div>
  );
}
