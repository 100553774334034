import decode from "jwt-decode";

export const getRoleInfo = (role_id, listRole) => {
  const role = listRole.find(r => r.id === role_id);
  return role.desc;
};

export const getPersonName = () => {
  const token = localStorage.getItem("token");
  const userData = decode(token);

  return userData;
};

export const isRoleGranted = role_permision => {
  const token = localStorage.getItem("token");

  try {
    const userData = decode(token);

    // is admin
    if (userData.roles.includes(1)) {
      return true;
    }

    for (let i = 0; i < userData.roles.length; i++) {
      // User has permission
      if (role_permision.includes(userData.roles[i])) {
        return true;
      }
    }
  } catch (err) {
    return false;
  }

  return false;
};
