import React from "react";

// ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";

// hooks
import useTypeSelect from "./hooks/useTypeSelect";

// constants
import {
  announcementTypes,
  ANNOUNCEMENT_TYPE_PARTNER,
  ANNOUNCEMENT_TYPE_ESTABLISHMENT,
  HOTEL_GUIDE_ANNOUNCEMENT,
  PHRASE_ANNOUNCEMENT,
} from "src/utility/constants";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

/**
 * TypeSelect component used for selecting announcement type in create
 */
const TypeSelect = ({ classes }) => {
  // hook
  const {
    // variables
    announcementTypeSelected,
    guides,
    guideSelected,
    isSelectTypeOpen,
    languageSelected,
    listLanguage,
    supplier,

    // functions
    announcementGuideDisabled,
    announcementLanguageDisabled,
    announcementTypeDisabled,
    getCreateAnnouncementDisabled,
    handleOnCreateAnnouncement,
    handleOnDialogClose,
    onAnnouncementTypeSelect,
    onGuideSelect,
    setLanguageSelected,
  } = useTypeSelect();

  // return root
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={isSelectTypeOpen || false} onClose={handleOnDialogClose}>
      <DialogTitle id="confirmation-dialog-title">Kreiranje najave</DialogTitle>
      <DialogContent>
        <Grid container alignItems="flex-start">
          {/* announcement type select */}
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Tip najave</FormLabel>
              <RadioGroup
                aria-label="Tip najave"
                name="tip-najave"
                className={classes.group}
                value={announcementTypeSelected}
                onChange={event => onAnnouncementTypeSelect(event.target.value)}
              >
                {announcementTypes
                  .filter(announcement => {
                    // remove phrase announcement for type select
                    if (announcement.name === PHRASE_ANNOUNCEMENT) {
                      return false;
                    }

                    if (supplier.establishment_id) {
                      return announcement.type === ANNOUNCEMENT_TYPE_ESTABLISHMENT;
                    } else {
                      return announcement.type === ANNOUNCEMENT_TYPE_PARTNER;
                    }
                  })
                  .map(announcementType => (
                    <FormControlLabel
                      disabled={announcementTypeDisabled(announcementType.name)}
                      value={announcementType.name}
                      key={announcementType.name}
                      control={<Radio />}
                      label={announcementType.description}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* guides select */}
          <Grid item>
            {announcementTypeSelected === HOTEL_GUIDE_ANNOUNCEMENT && (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Vodič</FormLabel>
                <RadioGroup
                  aria-label="Vodič"
                  name="vodic"
                  className={classes.group}
                  value={guideSelected}
                  onChange={event => onGuideSelect(event.target.value)}
                >
                  {guides &&
                    guides.map(guide => (
                      <FormControlLabel
                        disabled={announcementGuideDisabled(guide.guide_id)}
                        value={guide.guide_id}
                        key={guide.guide_id}
                        control={<Radio />}
                        label={`${guide.guide_first_name} ${guide.guide_last_name}`}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            )}
          </Grid>

          {/* language select */}
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Jezik</FormLabel>
              <RadioGroup
                aria-label="Jezik"
                name="jezik"
                className={classes.group}
                value={languageSelected}
                onChange={event => setLanguageSelected(event.target.value)}
              >
                {listLanguage &&
                  listLanguage.map(language => (
                    <FormControlLabel
                      disabled={announcementLanguageDisabled(language.id)}
                      value={language.id}
                      key={language.desc}
                      control={<Radio />}
                      label={language.desc}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleOnDialogClose}>
          Prekid
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={getCreateAnnouncementDisabled()}
          onClick={handleOnCreateAnnouncement}
        >
          Kreiraj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TypeSelect);
