import React, { PureComponent } from "react";

import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import ReactTableCustom from "../../../../../components/UI/ReactTable";
import BootstrapLikeInput from "../../../../../components/UI/BootstrapLikeInput";
import { guidIdGenerator, round } from "../../../../../utility/numbers";

const style = { padding: "24px" };

class CalculatorServicesPerPerson extends PureComponent {
  componentDidUpdate() {}

  addServicePerPerson = state => {
    const {
      calculator: { services_per_person },
      service_per_person,
    } = state;

    const services_per_person_clone = [...services_per_person];
    services_per_person_clone.push({
      ...service_per_person,
      id: guidIdGenerator(),
    });

    return {
      ...state,
      calculator: {
        ...state.calculator,
        services_per_person: services_per_person_clone,
      },
    };
  };

  changeServicePerPerson = (prevState, value, name, abs_price_id) => {
    const newState = {
      ...prevState,
      calculator: {
        ...prevState.calculator,
        services_per_person: prevState.calculator.services_per_person.map(abs_price => {
          if (abs_price.id === abs_price_id) {
            // Using state reducer to check if recalculation is needed
            const new_abs = this.stateReducer(
              {
                ...abs_price,
                [name]: value,
              },
              name,
            );

            return {
              ...new_abs,
            };
          }
          return abs_price;
        }),
      },
    };

    return newState;
  };

  stateReducer = (abs, type) => {
    if (type === "price" || type === "quantity" || type === "ruc") {
      const pr_izl = round(round(abs.price) + round(abs.ruc || 0));
      const exit = pr_izl;

      return {
        ...abs,
        pr_izl,
        exit,
      };
    }
    return abs;
  };

  render() {
    const { services_per_person, vat, getServicePerPerson } = this.props;

    const { onAdding, changeServicePerPerson, changeServicePerPersonNum } = getServicePerPerson({
      onAdding: this.addServicePerPerson,
      changeServicePerPerson: this.changeServicePerPerson,
      changeServicePerPersonNumField: this.changeServicePerPerson,
    });

    return (
      <DialogContent style={style} className="page-break content-print">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <ReactTableCustom
              data={services_per_person}
              columns={[
                {
                  Header: "OSTALO / PO OSOBI",
                  columns: [
                    {
                      Header: "USLUGA",
                      id: "service",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.service}
                          onChange={e => changeServicePerPerson(e)("service")(d.id)}
                        />
                      ),
                      Footer: <strong>UKUPNO OSTALO</strong>,
                    },
                    {
                      Header: "PDV",
                      id: "vat",
                      accessor: d => (
                        <div className="print-span-container">
                          <select
                            className="booking-select "
                            onChange={e => changeServicePerPerson(e)("vat")(d.id)}
                            value={d.vat}
                          >
                            <option value="" />
                            {vat &&
                              vat.map(v => (
                                <option key={v} value={v}>
                                  {v}
                                </option>
                              ))}
                          </select>
                          <span className="select-print" style={{ display: "none" }}>
                            {d.vat}
                          </span>
                        </div>
                      ),
                    },
                    {
                      Header: "IZNOS",
                      id: "price",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.price}
                          onChange={e => changeServicePerPersonNum(e)("price")(d.id)}
                        />
                      ),
                    },
                    {
                      Header: "KOLIČINA",
                      id: "quantity",
                      accessor: d => (
                        <BootstrapLikeInput
                          value={d.quantity}
                          onChange={e => changeServicePerPersonNum(e)("quantity")(d.id)}
                        />
                      ),
                    },
                    {
                      Header: "RUC",
                      id: "ruc",
                      accessor: d => (
                        <BootstrapLikeInput value={d.ruc} onChange={e => changeServicePerPersonNum(e)("ruc")(d.id)} />
                      ),
                    },
                    {
                      Header: "PR. IZL",
                      accessor: "pr_izl",
                      Cell: ({ original: { pr_izl } }) =>
                        pr_izl && typeof pr_izl !== "undefined"
                          ? Number(pr_izl).toFixed(2) + " €"
                          : Number(0).toFixed(2) + "€",
                      Footer: () => {
                        return (
                          <strong>
                            {Number(
                              services_per_person.reduce((total, current) => {
                                return total + current.pr_izl;
                              }, 0),
                            ).toFixed(2)}{" "}
                            €
                          </strong>
                        );
                      },
                    },
                    {
                      Header: "IZLAZ",
                      accessor: "exit",
                      Cell: ({ original: { exit } }) =>
                        exit && typeof exit !== "undefined" ? Number(exit).toFixed(2) + " €" : Number(0).toFixed(2),
                      Footer: (
                        <strong>
                          {Number(services_per_person.reduce((total, current) => total + current.exit, 0)).toFixed(2)} €
                        </strong>
                      ),
                    },
                  ],
                },
              ]}
              innerProps={{
                pageSize: services_per_person.length,
                showPagination: false,
                sortable: false,
                filterable: false,
                filterAll: false,
                noDataText: "Nema dodanih usluga",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={onAdding}>
              DODAJ USLUGU
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}

export default CalculatorServicesPerPerson;
