import React, { Component, Fragment } from "react";

import SellingsDrawer from "./SellingsDrawer";
import StatusDrawer from "./StatusDrawer";
import GuideDriverDrawer from "./GuideDriverDrawer";
import EstablishmentDrawer from "./EstablishmentsDrawer";
import TerminsDrawer from "./TerminsDrawer";
import DeliveredStateDrawer from "./DeliveredStateDrawer";
import RemarkDrawer from "./RemarkDrawer";
import ReceiptDrawer from "./ReceiptDrawer";

import { BookingModalsContext } from "../BookingModalsHandler";
import NotificationStateDrawer from "./NotificationStateDrawer";
import TransportDrawer from "./TransportDrawer";
import CalculatorAndContractDrawer from "./CalculatorAndContractDrawer";
import NumberOfBussesDrawer from "./NumberOfBussesDrawer";
import { AllBookingsContext } from "../AllBookings";

class AllBookingDrawers extends Component {
  static GuideDriverDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ termin, toggleGuideDriverDrawer, guideDriverDrawer }) =>
        guideDriverDrawer ? (
          <GuideDriverDrawer
            id={termin.id}
            guide={termin.guide}
            driver={termin.driver}
            comment={termin.driver_guide_comment}
            toggle={toggleGuideDriverDrawer}
            open={guideDriverDrawer}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static StatusDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ termin, statusDrawer, toggleStatusDrawer, fetchInitialQuery }) =>
        statusDrawer ? (
          <StatusDrawer
            termin={termin}
            open={statusDrawer}
            termin_period_status_id={termin.termin_period_status_id}
            toggle={toggleStatusDrawer}
            fetchInitialQuery={fetchInitialQuery}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static CalcAndContrDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ termin, calcContrDrawer, toggleCalcContrDrawer }) =>
        calcContrDrawer ? (
          <CalculatorAndContractDrawer termin={termin} open={calcContrDrawer} toggle={toggleCalcContrDrawer} />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static EstablishmentDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ termin, establishmentDrawer, toggleEstablishmentDrawer }) =>
        establishmentDrawer ? (
          <AllBookingsContext.Consumer>
            {({ refetchLastListBookingQuery }) => (
              <EstablishmentDrawer
                contigents={termin && termin.contigents ? termin.contigents : []}
                open={establishmentDrawer}
                toggle={toggleEstablishmentDrawer}
                refetchLastListBookingQuery={refetchLastListBookingQuery}
              />
            )}
          </AllBookingsContext.Consumer>
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static TerminsDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ terminsDrawer, toggleTerminsDrawer, termin }) =>
        terminsDrawer ? (
          <TerminsDrawer
            toggle={toggleTerminsDrawer}
            open={terminsDrawer}
            id={termin.id}
            from={termin.from}
            to={termin.to}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static SellingsDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ sellingsDrawer, toggleSellingsDrawer, termin }) =>
        sellingsDrawer ? (
          <SellingsDrawer open={sellingsDrawer} toggle={toggleSellingsDrawer} contigents={termin.contigents} />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static DeliveredStateDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ deliveredStateDrawer, toggleDeliveredStateDrawer, termin }) =>
        deliveredStateDrawer ? (
          <DeliveredStateDrawer
            open={deliveredStateDrawer}
            toggle={toggleDeliveredStateDrawer}
            id={termin.id}
            delivered_state={termin.delivered_state}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static RemarkDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ remarkDrawer, toggleRemarkDrawer, termin }) =>
        remarkDrawer ? (
          <RemarkDrawer open={remarkDrawer} toggle={toggleRemarkDrawer} id={termin.id} remark={termin.remark} />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static ReceiptDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ receiptDrawer, toggleReceiptDrawer, termin, fetchInitialQuery }) =>
        receiptDrawer ? (
          <ReceiptDrawer
            open={receiptDrawer}
            toggle={toggleReceiptDrawer}
            id={termin.id}
            fetchInitialQuery={fetchInitialQuery}
            receipt={termin.receipt}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static NotificationStateDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ notificationStateDrawer, toggleNotificationStateDrawer, termin }) =>
        notificationStateDrawer ? (
          <NotificationStateDrawer
            open={notificationStateDrawer}
            toggle={toggleNotificationStateDrawer}
            id={termin.id}
            parent_id={termin.parent_id}
            def={termin.def}
            notification_booking_state_date={termin.notification_booking_state_date}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static TransportDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ transportDrawer, toggleTransportDrawer, termin }) =>
        transportDrawer ? (
          <TransportDrawer
            open={transportDrawer}
            toggle={toggleTransportDrawer}
            id={termin.id}
            transport={termin.transport}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  static NumberOfBussesDrawer = () => (
    <BookingModalsContext.Consumer>
      {({ numberOfBussesDrawer, toggleNumberOfBussesDrawer, termin }) =>
        numberOfBussesDrawer ? (
          <NumberOfBussesDrawer
            open={numberOfBussesDrawer}
            toggle={toggleNumberOfBussesDrawer}
            id={termin.id}
            nr_of_buses={termin.nr_of_buses}
          />
        ) : null
      }
    </BookingModalsContext.Consumer>
  );

  render() {
    return (
      <Fragment>
        <AllBookingDrawers.GuideDriverDrawer />
        <AllBookingDrawers.DeliveredStateDrawer />
        <AllBookingDrawers.EstablishmentDrawer />
        <AllBookingDrawers.ReceiptDrawer />
        <AllBookingDrawers.RemarkDrawer />
        <AllBookingDrawers.SellingsDrawer />
        <AllBookingDrawers.StatusDrawer />
        <AllBookingDrawers.TerminsDrawer />
        <AllBookingDrawers.NotificationStateDrawer />
        <AllBookingDrawers.TransportDrawer />
        <AllBookingDrawers.CalcAndContrDrawer />
        <AllBookingDrawers.NumberOfBussesDrawer />
      </Fragment>
    );
  }
}

export default AllBookingDrawers;
