// libs
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

// constants
import { GUIDES_SEASON_SPRING_ID } from "src/utility/constants";

export const guidesSlice = createSlice({
  name: "guides",
  initialState: {
    guidesRowExpanded: {},
    guideSchedulesPageSelected: 0,
    guideSchedulesPageSizeSelected: 10,
    guideSchedulesFilters: [],
    guideDrawers: {},
    guidePayload: {},
    activityPartnerList: [],
    isDialogPartnerSearchOpen: false,
    guidesSeasonSelectedID: GUIDES_SEASON_SPRING_ID,
    filterDateSelected: moment(),
    isAnnouncementDrawerOpen: false,
    supplierAnnouncements: [],
    announcementVouchers: [],
  },

  reducers: {
    // guide schedule list
    setGuideSchedules: (state, action) => {
      state.listGuides = action.payload;
    },

    // guide schedule single row expanded value
    setGuideScheduleRowExpanded: (state, action) => {
      state.guidesRowExpanded = action.payload;
    },

    // guide schedule table page selected
    setGuideSchedulesPageSelected: (state, action) => {
      state.guideSchedulesPageSelected = action.payload;
    },

    // guide schedule rows number selected
    setGuideSchedulesPageSizeSelected: (state, action) => {
      state.guideSchedulesPageSizeSelected = action.payload;
    },

    // guide schedule filters
    setGuideSchedulesFilters: (state, action) => {
      state.guideSchedulesFilters = action.payload;
    },

    // guide schedule filters open/close state
    setGuideScheduleFiltersOpen: (state, action) => {
      state.filtersOpen = action.payload;
    },

    // guide schedule column filters
    setGuideScheduleGridFilters: (state, action) => {
      state.gridFilters = action.payload;
    },

    // guide schedule season filter id value selected
    setGuideScheduleSeasonFilterSelected: (state, action) => {
      state.guidesSeasonSelectedID = action.payload;
    },

    // guide schedule date / year filter value selected
    setGuideScheduleDateYearFilterSelected: (state, action) => {
      state.filterDateSelected = action.payload;
    },

    // guide schedule service type list
    setGuideScheduleServiceTypes: (state, action) => {
      state.guideServiceTypes = action.payload;
    },

    // handle which guide drawer is open value
    setGuideDrawers: (state, action) => {
      state.guideDrawers = {
        [action.payload.drawerType]: action.payload.isOpen,
      };
    },

    // payload value for gudie schedule drawers
    setGuideDrawerPayload: (state, action) => {
      state.guidePayload = action.payload;
    },

    // guide schedule program day with activities values
    setProgramDayActivities: (state, action) => {
      state.programDayActivities = action.payload;
    },

    // set guide schedule activity partners
    setGuideScheduleActivityPartners: (state, action) => {
      const partnerSelected = action.payload;

      let updatedActivityPartners = [];

      const partnerAlreadyExist = state.activityPartnerList.some(partner => partner.id === partnerSelected.id);

      if (partnerAlreadyExist) {
        updatedActivityPartners = state.activityPartnerList.map(partner =>
          partner.id === partnerSelected.id ? partnerSelected : partner,
        );
      } else {
        updatedActivityPartners = [...state.activityPartnerList, partnerSelected];
      }

      state.activityPartnerList = updatedActivityPartners;
    },

    // partner search dialog values
    setPartnerSearchValues: (state, action) => {
      state.isDialogPartnerSearchOpen = action.payload.isDialogPartnerSearchOpen;
      state.currentProgramDayId = action.payload.currentProgramDayId;
      state.currentProgramDayContentId = action.payload.currentProgramDayContentId;
    },

    // partner selected id value
    setPartnerSelected: (state, action) => {
      state.partnerIdSelected = action.payload;
    },

    // partner contact selected id value, partner contact is equal to guide
    setPartnerContactSelected: (state, action) => {
      state.contactIdSelected = action.payload;
    },

    // contact / guide selected id value
    setGuideSelected: (state, action) => {
      state.guideIdSelected = action.payload;
    },

    // guide schedule partners for creating announcements
    setGuideScheduleAnnouncementPartners: (state, action) => {
      state.supplierPartners = action.payload.supplierPartners || state.supplierPartners;
      state.supplierPartnerGuides = action.payload.allGuides || state.allGuides;
      state.guideLayoutComment = action.payload.guideLayoutComment || state.guideLayoutComment;
      state.programId = action.payload.programId || state.programId;
    },

    // guide schedule announcement drawer open
    setGuideScheduleAnnouncementDrawerOpen: (state, action) => {
      state.isAnnouncementDrawerOpen = action.payload;
    },

    // guide schedule announcement drawer payload
    setGuideScheduleAnnouncementDrawerPayload: (state, action) => {
      state.programId = action.payload.programId;
      state.supplier = action.payload.supplier;
    },

    // supplier announcements
    setSupplierAnnouncements: (state, action) => {
      state.supplierAnnouncements = action.payload;
    },

    // guide schedule select type open value
    setGuideScheduleSelectTypeOpen: (state, action) => {
      state.isSelectTypeOpen = action.payload;
    },

    // guide schedule constant values
    setGuideScheduleConstants: (state, action) => {
      // group operative status types
      if (action.payload.listLanguage) {
        state.listLanguage = action.payload.listLanguage;
      }

      // group operative status types
      if (action.payload.groupOperativeStatusTypes) {
        state.groupOperativeStatusTypes = action.payload.groupOperativeStatusTypes;
      }

      // announcement types
      if (action.payload.announcementTypes) {
        state.announcementTypes = action.payload.announcementTypes;
      }

      // announcement status types
      if (action.payload.announcementStatusTypes) {
        state.announcementStatusTypes = action.payload.announcementStatusTypes;
      }

      // voucher status types
      if (action.payload.voucherStatusTypes) {
        state.voucherStatusTypes = action.payload.voucherStatusTypes;
      }
    },
  },
});

export const {
  setGuideSchedules,
  setGuideScheduleRowExpanded,
  setGuideSchedulesPageSelected,
  setGuideSchedulesPageSizeSelected,
  setGuideSchedulesFilters,
  setGuideScheduleFiltersOpen,
  setGuideScheduleGridFilters,
  setGuideScheduleSeasonFilterSelected,
  setGuideScheduleDateYearFilterSelected,
  setGuideScheduleServiceTypes,
  setGuideDrawers,
  setGuideDrawerPayload,
  setProgramDayActivities,
  setGuideScheduleActivityPartners,
  setPartnerSearchValues,
  setPartnerSelected,
  setPartnerContactSelected,
  setGuideSelected,
  setGuideScheduleAnnouncementPartners,
  setGuideScheduleAnnouncementDrawerOpen,
  setGuideScheduleAnnouncementDrawerPayload,
  setSupplierAnnouncements,
  setGuideScheduleSelectTypeOpen,
  setGuideScheduleConstants,
} = guidesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`

// guide schedule list
export const selectGuideSchedules = state => state.guideSchedule.listGuides;

// guide schedule single row expanded value
export const selectGuideScheduleRowExpanded = state => state.guideSchedule.guidesRowExpanded;

// guide schedule page selected
export const selectGuideSchedulesPageSelected = state => state.guideSchedule.guideSchedulesPageSelected;

// guide schedule page selected
export const selectGuideSchedulesPageSizeSelected = state => state.guideSchedule.guideSchedulesPageSizeSelected;

// guide schedules filters
export const selectGuideSchedulesFilters = state => state.guideSchedule.guideSchedulesFilters;

// guide schedule filters open/close state
export const selectGuideScheduleFiltersOpen = state => state.guideSchedule.filtersOpen;

// guide schedule column filters
export const selectGuideScheduleGridFilters = state => state.guideSchedule.gridFilters;

// guide schedule season filter id value selected
export const selectGuideScheduleSeasonSelectedFilter = state => state.guideSchedule.guidesSeasonSelectedID;

// guide schedule date / year filter value selected
export const selectGuideScheduleDateYearFilter = state => state.guideSchedule.filterDateSelected;

// guide schedule service type list
export const selectGuideScheduleServiceTypes = state => state.guideSchedule.guideServiceTypes;

// handle which guide drawer is open value
export const selectGuideScheduleDrawers = state => state.guideSchedule.guideDrawers;

// payload value for gudie schedule drawers
export const selectGuideScheduleDrawerPayload = state => state.guideSchedule.guidePayload;

// guide schedule program day with activities values
export const selectProgramDayActivities = state => state.guideSchedule.programDayActivities;

// set guide schedule activity partners
export const selectActivityPartners = state => state.guideSchedule.activityPartnerList;

// partner search dialog values
export const selectIsDialogPartnerSearchOpen = state => state.guideSchedule.isDialogPartnerSearchOpen;
export const selectCurrentProgramDayId = state => state.guideSchedule.currentProgramDayId;
export const selectCurrentProgramDayContentId = state => state.guideSchedule.currentProgramDayContentId;

// partner id selected
export const selectPartnerIdSelected = state => state.guideSchedule.partnerIdSelected;

// contact id selected
export const selectContactIdSelected = state => state.guideSchedule.contactIdSelected;

// guide id selected
export const selectGuideIdSelected = state => state.guideSchedule.guideIdSelected;

// supplier partners
export const selectSupplierPartners = state => state.guideSchedule.supplierPartners;

// supplier partner guides
export const selectSupplierPartnerGuides = state => state.guideSchedule.supplierPartnerGuides;

// guide layout comment
export const selectGuideLayoutComment = state => state.guideSchedule.guideLayoutComment;

// program id selected
export const selectProgramId = state => state.guideSchedule.programId;

// annonuncement drawer
export const selectAnnouncementDrawerOpen = state => state.guideSchedule.isAnnouncementDrawerOpen;

// supplier selected
export const selectSupplier = state => state.guideSchedule.supplier;

// supplier announcements
export const selectSupplierAnnouncements = state => state.guideSchedule.supplierAnnouncements;

// is select type guideSchedule dialog open
export const selectIsSelectTypeOpen = state => state.guideSchedule.isSelectTypeOpen;

// constants
export const selectGroupOperativeStatusTypes = state => state.guideSchedule.groupOperativeStatusTypes;
export const selectAnnouncementTypes = state => state.guideSchedule.announcementTypes;
export const selectAnnouncementStatusTypes = state => state.guideSchedule.announcementStatusTypes;
export const selectVoucherStatusTypes = state => state.guideSchedule.voucherStatusTypes;
export const selectLanguages = state => state.guideSchedule.listLanguage;

export default guidesSlice.reducer;
