import gql from "graphql-tag";
import {
  alotmanDayFragment,
  contigentFragment,
  mainTerminFragment,
  partnerClientFragment,
  serviceFragment,
  serviceTypeFragment,
  typologyFragment,
} from "../fragments";

export const createAlotmanTermin = gql`
  mutation($establishment_id: ID!, $from: DateTime!, $to: DateTime!, $typology_id: ID!, $sum: Int!) {
    createAlotmanCalendar(
      input: { establishment_id: $establishment_id, from: $from, to: $to, typology_id: $typology_id, sum: $sum }
    ) {
      establishment_id
      alotman_days {
        ...AlotmanDay
      }
    }
  }
  ${alotmanDayFragment}
`;

export const createReservationMutation = gql`
  mutation(
    $contract_id: ID!
    $from: DateTime
    $to: DateTime
    $guest_name: String!
    $typology_id: ID
    $remark: String
    $service_type_id: ID
    $starting_service_id: ID
    $ending_service_id: ID
    $check_in: String
    $check_out: String
  ) {
    createReservation(
      input: {
        contract_id: $contract_id
        from: $from
        to: $to
        guest_name: $guest_name
        typology_id: $typology_id
        remark: $remark
        service_type_id: $service_type_id
        starting_service_id: $starting_service_id
        ending_service_id: $ending_service_id
        check_in: $check_in
        check_out: $check_out
      }
    ) {
      id
    }
  }
`;

export const updateReservationMutation = gql`
  mutation($id: ID!, $patch: UpdateReservation!) {
    updateReservation(input: { id: $id, patch: $patch }) {
      id
      guest_name
      from
      to
      remark
      service_type_id
      starting_service_id
      ending_service_id
      check_in
      check_out
      reservation_status_id
      typology_id
      typology {
        ...Typology
      }
      service_type {
        ...ServiceType
        services {
          ...Service
        }
      }
      contract {
        id
        offer_contract_document {
          id
          language_id
          partner_client {
            ...Partner
          }
          termin_periods {
            ...MainTermin
            contigents {
              ...Contigent
            }
          }
        }
      }
    }
  }

  ${serviceTypeFragment}
  ${serviceFragment}
  ${mainTerminFragment}
  ${contigentFragment}
  ${partnerClientFragment}
  ${typologyFragment}
`;

export const updateAlotmanCalendar = gql`
  mutation(
    $establishment_id: ID!
    $from: DateTime!
    $to: DateTime!
    $typology_id: ID!
    $available_state: Int
    $add_to_state: Int
    $subtract_from_state: Int
    $stop_booking: Boolean
  ) {
    updateAlotmanCalendar(
      input: {
        establishment_id: $establishment_id
        from: $from
        to: $to
        typology_id: $typology_id
        available_state: $available_state
        add_to_state: $add_to_state
        subtract_from_state: $subtract_from_state
        stop_booking: $stop_booking
      }
    ) {
      establishment_id
      alotman_days {
        ...AlotmanDay
      }
    }
  }
  ${alotmanDayFragment}
`;
