import React from "react";

// ui
import { Tabs, Tab } from "material-ui/Tabs";
// components
import TemplateAnnouncementList from "./components/List";

export default function AnnouncementTemplateContainer() {
  return (
    <Tabs>
      <Tab label="Predlošci">
        <TemplateAnnouncementList />
      </Tab>
    </Tabs>
  );
}
