import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import { connect } from "react-redux";
import toastr from "toastr";
import { createTypology, deleteTypology, updateTypologyMutation } from "../../../../graphql/mutation/administration";
import {
  addAdministrationTypologyToEstablishment,
  changeAdministrationTypologyData,
  changeTypologyTranslationData,
  removeAdministrationTypologyFromEstablishment,
  saveAdministrationEditedTypologyData,
} from "../../../../store/actions";
import { prepareForSending } from "../../../../utility/prepare";
import CreateTypologyForm from "./CreateTypologyForm";
import EditTypologyForm from "./EditTypologyForm";
import ShowTypologyForm from "./ShowTypologyForm";

class TypologyForm extends Component {
  static schema = {
    required: ["typology_group_id"],
    properties: {
      establishment_code: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Smještajni kod se mora upisati",
      },
      code: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Kod se mora upisati",
      },
      persons_capacity: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je upisati kapacitet",
      },
      priority: {
        type: "integer",
        minimum: 0,
        errorMessage: "Prioritet mora biti zapisan kao broj",
      },
      typology_group_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je odabrati grupu tipologije",
      },
      typology_translations: {
        type: "array",
        minItems: 1,
        errorMessage: "Potreban je barem jedan prijevod",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi jezici moraju imati naziv",
            },
            desc: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi opisi moraju biti ispunjeni",
            },
          },
        },
      },
    },
  };

  state = {
    editTypology: false,
    typologyEdit: "",
  };

  setForEdit = typology => {
    this.setState({
      editTypology: typology,
    });
  };

  componentDidMount = () => {
    const { listLanguage, createMode, changeAdministrationTypologyData } = this.props;

    // Set languages for creating typologies
    if (createMode && listLanguage) {
      const typology_translations = listLanguage.map(({ id }) => ({
        desc: "",
        name: "",
        language_id: id,
      }));

      changeAdministrationTypologyData({
        key: "typology_translations",
        value: typology_translations,
      });

      return;
    }
  };

  changeTypologyData = param => {
    this.setState(prevState => {
      return {
        editTypology: {
          ...prevState.editTypology,
          [param.key]: param.value,
        },
      };
    });
  };

  saveEdited = async () => {
    const { editTypology } = this.state;
    try {
      await this.props.mutate({
        variables: {
          id: editTypology.id,
          patch: prepareForSending(editTypology, [
            "__typename",
            "establishment_id",
            "establishment",
            "language",
            "typology_group",
          ]),
        },
      });

      this.props.saveAdministrationEditedTypologyData(this.state.editTypology);

      if (this.props.typologyEdited) {
        this.props.typologyEdited(this.state.editTypology);
      }

      this.setState({
        editTypology: false,
      });

      toastr.success("Uspješno ažurirano");
    } catch (error) {}
  };

  cancelChanges = () =>
    this.setState({
      editTypology: false,
    });

  removeTypologyFromEstablishment = async () => {
    const { removeAdministrationTypologyFromEstablishment, typology, deleteTypology } = this.props;

    // Check if we're in the edit or create mode,
    // edit mode creates id as soon as we get creation response from server,
    // Create mode leaves id with - sign
    if (!typology.id.includes("-")) {
      try {
        const response = await deleteTypology({
          variables: {
            id: typology.id,
          },
        });

        const id = response.data.deleteTypology;

        // remove from establishment state in establishment form
        if (this.props.typologyRemove) {
          this.props.typologyRemove(id);
        }

        removeAdministrationTypologyFromEstablishment(id);
        toastr.success("Tipologija izbrisana");
      } catch (error) {
        toastr.warning("Neuspješno brisanje");
      }
    } else {
      removeAdministrationTypologyFromEstablishment(typology.id);
    }
  };

  changeEditedTypologyTranslation = (obj, language_id) => {
    const {
      editTypology,
      editTypology: { typology_translations },
    } = this.state;

    const typology_translations_clone = typology_translations.map(translation => {
      if (translation.language_id === language_id) {
        return {
          ...translation,
          [obj.key]: obj.value,
        };
      }
      return translation;
    });

    this.setState({
      editTypology: {
        ...editTypology,
        typology_translations: typology_translations_clone,
      },
    });
  };

  addNewTypologyToEstablishment = async () => {
    const { typology, establishment_id } = this.props;

    // if we're in the editing mode it means that there is establishment_id
    if (establishment_id) {
      try {
        const response = await this.props.createTypology({
          variables: {
            ...prepareForSending(typology, ["id"]),
            establishment_id,
          },
        });

        const { id } = response.data.createTypology;

        this.props.changeAdministrationTypologyData({ key: "id", value: id }, typology.id);

        // update establishment form state
        if (this.props.onAddTypology) {
          this.props.onAddTypology(prepareForSending(response.data.createTypology, ["__typename"]));
        }

        this.props.addAdministrationTypologyToEstablishment();

        toastr.success("Uspješno dodana tipologija");
      } catch (error) {}
    } else {
      this.props.addAdministrationTypologyToEstablishment();
    }
  };

  render() {
    const { typology, createMode, listLanguage, listTypologyGroup } = this.props;

    const { editTypology } = this.state;

    let formToShow = null;

    if (createMode) {
      // Create
      formToShow = (
        <CreateTypologyForm
          listTypologyGroup={listTypologyGroup}
          createMode={createMode}
          listLanguage={listLanguage}
          changeTypologyTranslationData={this.props.changeTypologyTranslationData}
          addNewTypologyToEstablishment={this.addNewTypologyToEstablishment}
          typology={typology}
        />
      );
    } else {
      // Edit mode
      if (typology && editTypology && typology.id === editTypology.id) {
        formToShow = (
          <EditTypologyForm
            changeEditedTypologyTranslation={this.changeEditedTypologyTranslation}
            editTypology={editTypology}
            listTypologyGroup={listTypologyGroup}
            changeTypologyData={this.changeTypologyData}
            typology={typology}
            listLanguage={listLanguage}
            saveEdited={this.saveEdited}
            removeTypologyFromEstablishment={this.removeTypologyFromEstablishment}
            cancelChanges={this.cancelChanges}
          />
        );
      } else {
        // Display mode
        formToShow = (
          <ShowTypologyForm
            createMode={createMode}
            editTypology={editTypology}
            typology={typology}
            setForEdit={this.setForEdit}
            listTypologyGroup={listTypologyGroup}
            listLanguage={listLanguage}
            removeTypologyFromEstablishment={this.removeTypologyFromEstablishment}
          />
        );
      }
    }

    return (
      <Grid item xs={12} lg={6}>
        {formToShow}
      </Grid>
    );
  }
}

export default connect(
  null,
  {
    changeAdministrationTypologyData,
    addAdministrationTypologyToEstablishment,
    removeAdministrationTypologyFromEstablishment,
    saveAdministrationEditedTypologyData,
    changeTypologyTranslationData,
  },
)(
  compose(
    graphql(updateTypologyMutation),
    graphql(createTypology, {
      name: "createTypology",
    }),
    graphql(deleteTypology, {
      name: "deleteTypology",
    }),
  )(TypologyForm),
);
