import React, { Component } from "react";
import { graphql } from "react-apollo";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import CommentTextArea from "../../../../components/UI/CommentTextArea";
import toastr from "toastr";

class ReceiptDrawer extends Component {
  state = {
    receipt: "",
  };

  updateData = () => {
    const { receipt } = this.state;
    if (receipt !== this.props.receipt) {
      try {
        this.props.mutate({
          variables: {
            id: this.props.id,
            patch: {
              receipt,
            },
          },
        });
        toastr.success("Uspješno ažurirano");
        this.props.toggle();
        this.props.fetchInitialQuery();
      } catch (error) {}
    }
  };

  componentDidMount = () => {
    const { receipt } = this.props;

    this.setState({ receipt });
  };

  render() {
    const { toggle, open } = this.props;

    return (
      <DrawerWrapper toggle={toggle} update={this.updateData} open={open} title="Račun">
        <div className="form-control-grid">
          <CommentTextArea
            defaultValue={this.state.receipt}
            label="Račun"
            customOnChange={({ target: { value } }) => this.setState({ receipt: value })}
          />
        </div>
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(ReceiptDrawer);
