import React from "react";

// libs
import { Font, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

// ui
import { AppBar, Button, Dialog, IconButton, Toolbar, Typography, withStyles, Grid } from "@material-ui/core";

import { ToolbarGroup } from "material-ui/Toolbar";

import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/FileDownload";

import Slide from "@material-ui/core/Slide";

// fonts
import OpenSans from "src/fonts/Open_Sans/OpenSans-Regular.ttf";
import OpenSansBold from "src/fonts/Open_Sans/OpenSans-Bold.ttf";

// hooks
import useVoucherViewer from "./hooks/useVoucherViewer";

// constants
import {
  VOUCHER_GUIDES_FOREIGN_AGENCY,
  VOUCHER_HOTEL,
  VOUCHER_HOTEL_FOREIGN_AGENCY,
  VOUCHER_PROGRAM,
  VOUCHER_TICKETS_AND_RESTAURANTS,
} from "src/utility/constants";

// components
import FixedCircularProgress from "src/components/UI/FixedCircularProgress";
import VoucherHotel from "./components/VoucherHotel";
import VoucherProgram from "./components/VoucherProgram";
import VoucherTicketsRestaurants from "./components/VoucherTicketsRestaurants/VoucherTicketsRestaurants";
import VoucherHotelForeignAgency from "./components/VoucherHotelForeignAgency";
import VoucherGuideForeignAgency from "./components/VoucherGuideForeignAgency";

// open sans regular
Font.register({
  family: "OpenSans",
  format: "truetype",
  src: OpenSans,
});

// open sans bold
Font.register({
  family: "OpenSansBold",
  format: "truetype",
  src: OpenSansBold,
});

// styles
const styles = theme => ({
  container: {
    padding: 16,
  },
  button: {
    margin: theme.spacing.unit,
  },

  dialogPaper: {
    overflow: "inherit",
  },
});

/**
 * transition of dialog
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const VoucherViewer = ({ classes }) => {
  const {
    // variables
    voucher,
    voucherType,
    loading,
    isVoucherViewerOpened,
    isVoucherFetched,

    // functions
    getRenderedDocumentFileName,
    onVoucherViewerClose,
  } = useVoucherViewer();

  /**
   * workaround for saving with specific filename since PDFViewer still not supporting set filename
   * get rendered document for PDFDownloadLink component
   */
  const getRenderedDocument = () => {
    switch (voucherType) {
      // voucher hotel
      case VOUCHER_HOTEL.type_name:
        return <VoucherHotel voucher={voucher} />;

      // voucher program
      case VOUCHER_PROGRAM.type_name:
        return <VoucherHotel voucher={voucher} />;

      // voucher tickets and restaurants
      case VOUCHER_TICKETS_AND_RESTAURANTS.type_name:
        return <VoucherHotel voucher={voucher} />;

      // voucher hotel foreign agency
      case VOUCHER_HOTEL_FOREIGN_AGENCY.type_name:
        return <VoucherHotel voucher={voucher} />;

      // voucher guide foreign agency
      case VOUCHER_GUIDES_FOREIGN_AGENCY.type_name:
        return <VoucherHotel voucher={voucher} />;

      default:
        return false;
    }
  };

  // render loading view
  const renderLoadingView = () => {
    return (
      <Grid container alignItems="center" className={classes.container} spacing={16}>
        <Grid item>
          <FixedCircularProgress color="inherit" size={18} thickness={6} />
        </Grid>
        <Grid>
          <Typography variant="subheading">Dohvaćanje podataka...</Typography>
        </Grid>
      </Grid>
    );
  };

  // get voucher document based on announcement type
  const renderVoucherView = () => {
    if (!voucherType) {
      return (
        <div className={classes.container}>
          <Typography variant="subheading">Voucher nije podržan</Typography>
        </div>
      );
    }

    switch (voucherType) {
      // voucher hotel
      case VOUCHER_HOTEL.type_name:
        return (
          <PDFViewer width="100%" height="100%">
            <VoucherHotel voucher={voucher} />
          </PDFViewer>
        );

      // voucher program
      case VOUCHER_PROGRAM.type_name:
        return (
          <PDFViewer width="100%" height="100%">
            <VoucherProgram voucher={voucher} />
          </PDFViewer>
        );

      // voucher tickets and restaurants
      case VOUCHER_TICKETS_AND_RESTAURANTS.type_name:
        return (
          <PDFViewer width="100%" height="100%">
            <VoucherTicketsRestaurants voucher={voucher} />
          </PDFViewer>
        );

      // voucher hotel foreign agency
      case VOUCHER_HOTEL_FOREIGN_AGENCY.type_name:
        return (
          <PDFViewer width="100%" height="100%">
            <VoucherHotelForeignAgency voucher={voucher} />
          </PDFViewer>
        );

      // voucher guide foreign agency
      case VOUCHER_GUIDES_FOREIGN_AGENCY.type_name:
        return (
          <PDFViewer width="100%" height="100%">
            <VoucherGuideForeignAgency voucher={voucher} />
          </PDFViewer>
        );

      default:
        return (
          <div className={classes.container}>
            <Typography variant="subheading">Voucher nije podržan</Typography>
          </div>
        );
    }
  };

  // return root
  return (
    <Dialog
      fullScreen
      open={isVoucherViewerOpened}
      onClose={onVoucherViewerClose}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="title" color="inherit">
            Pregled voucher-a
          </Typography>

          <ToolbarGroup style={{ marginLeft: "auto" }}>
            {/** render download link if there's voucher to download */}
            {getRenderedDocument() && isVoucherFetched ? (
              <PDFDownloadLink
                style={{ color: "white", textDecoration: "none" }}
                document={getRenderedDocument()}
                fileName={getRenderedDocumentFileName()}
              >
                {({ blob, url, loading, error }) => (
                  <Button color="inherit" variant="text" size="small" disabled={loading}>
                    <DownloadIcon />
                    Preuzmi
                  </Button>
                )}
              </PDFDownloadLink>
            ) : null}

            <IconButton color="inherit" onClick={onVoucherViewerClose}>
              <CloseIcon />
            </IconButton>
          </ToolbarGroup>
        </Toolbar>
      </AppBar>

      {loading ? renderLoadingView() : renderVoucherView()}
    </Dialog>
  );
};

export default withStyles(styles)(VoucherViewer);
