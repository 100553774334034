import React, { Fragment, Component } from "react";
import { withApollo } from "react-apollo";

import { config } from "../../../utility/globals";
import { numberFormat } from "../../../utility/numbers";
import { getTypology } from "../../../graphql/query/administration";
import { formatDate } from "../../../utility/dates";
import { connect } from "react-redux";

class TerminContigent extends Component {
  state = {
    ui: null,
  };
  componentDidMount = async () => {
    const { type_id, termin, client, language_id } = this.props;

    // If not advanced
    if (config.offer_type[type_id] !== "Tour" && config.offer_type[type_id] !== "Alotman") {
      let ui;

      if (!termin) {
        ui = "Nema termina";
      } else if (!termin.contigents || !termin.contigents.length) {
        ui = "Nema odabranog kontigenta";
      } else {
        ui = [];

        ui = await Promise.all(
          termin.contigents &&
            termin.contigents.map(async ({ typology_id, typologies_sum }, index) => {
              let ui_contigent;

              if (!typology_id) {
                ui_contigent = "Nije odabrana tipologija";
              } else {
                const response = await client.query({
                  query: getTypology,
                  variables: {
                    id: typology_id,
                  },
                });

                const typology = response.data.getTypology;

                const typology_translated = typology.typology_translations.find(
                  translation => translation.language_id === language_id,
                );

                ui_contigent = (
                  <div key={index}>{`${numberFormat(typologies_sum)}  ${typology_translated.name} [${
                    typology.persons_capacity
                  }]`}</div>
                );
              }
              return ui_contigent;
            }),
        );
      }
      this.setState({
        ui,
      });
    }
  };

  render() {
    const { int, comment_contigent } = this.props;

    return this.state.ui ? (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.contigent}</h4>
        </div>
        <div>
          <div className="preview-content-div preview-no-padding preview-no-margin">{this.state.ui}</div>
          {comment_contigent && (
            <div disabled id="comment_contigent" className="preview-content-div preview-textarea">
              {comment_contigent.trim()}
            </div>
          )}
        </div>
      </div>
    ) : (
      this.state.ui
    );
  }
}

class AlotmanTerminContigent extends Component {
  state = {
    ui: null,
  };

  componentDidMount = async () => {
    const { type_id, termin_periods, client, language_id } = this.props;

    if (config.offer_type[type_id] === "Alotman") {
      let ui;

      if (!termin_periods || !termin_periods.length) {
        ui = "Nema termina";
      } else {
        ui = [];

        await Promise.all(
          termin_periods.map(async termin => {
            const contigentsUi = await Promise.all(
              termin &&
                termin.contigents &&
                termin.contigents.map(async ({ typology_id, typologies_sum }, index) => {
                  let ui_contigent;

                  if (!typology_id) {
                    ui_contigent = "Nije odabrana tipologija";
                  } else {
                    const response = await client.query({
                      query: getTypology,
                      variables: {
                        id: typology_id,
                      },
                    });

                    const typology = response.data.getTypology;

                    const typology_translated = typology.typology_translations.find(
                      translation => translation.language_id === language_id,
                    );

                    ui_contigent = (
                      <div key={`${index} - ${typology_id} - ${typologies_sum}`}>{`${numberFormat(typologies_sum)}  ${
                        typology_translated.name
                      } [${typology.persons_capacity}]`}</div>
                    );
                  }

                  return ui_contigent;
                }),
            );

            const termin_ui = (
              <div style={{ display: "flex" }}>
                <div>
                  <div>{`${formatDate(termin.from)} - ${formatDate(termin.to)}`}</div>
                </div>
                <div style={{ marginLeft: 24 }}>{contigentsUi}</div>
              </div>
            );

            ui = [...ui, termin_ui];
          }),
        );
      }

      this.setState({
        ui,
      });
    }
  };

  render() {
    const { int, comment_contigent } = this.props;

    return this.state.ui ? (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.contigent}</h4>
        </div>
        <div>
          <div className="preview-content-div preview-no-padding preview-no-margin">{this.state.ui}</div>
          {comment_contigent.trim().length ? (
            <div disabled id="comment_contigent" className="preview-content-div preview-textarea">
              {comment_contigent.trim()}
            </div>
          ) : null}
        </div>
      </div>
    ) : (
      this.state.ui
    );
  }
}

class SubterminContigents extends Component {
  state = {
    ui: null,
  };

  componentDidMount = async () => {
    const { type_id, termin_periods, client, language_id } = this.props;

    if (config.offer_type[type_id] === "Tour") {
      let ui;

      if (!termin_periods || !termin_periods.length) {
        ui = <div>Nema termina</div>;
      } else {
        const { subtermin_periods } = termin_periods[0];
        if (!subtermin_periods || !subtermin_periods.length) {
          ui = <div>Nema međutermina</div>;
        } else {
          // Getting only contigents from termins and removing same contigents
          let all_contigents = [];
          // subtermin_periods.forEach(({ contigents }) => (all_contigents = [...all_contigents, ...contigents]));
          all_contigents = subtermin_periods[0].contigents;
          // removing same contigents

          all_contigents = all_contigents.filter(
            (obj1, index) =>
              all_contigents.findIndex(
                obj2 => obj2.typology_id === obj1.typology_id && obj2.typologies_sum === obj1.typologies_sum,
              ) === index,
          );

          ui = await Promise.all(
            all_contigents &&
              all_contigents.map(async ({ typology_id, typologies_sum }, index) => {
                let ui_subtermin;

                if (!typology_id) {
                  ui_subtermin = "Nwma odabrane tipologije";
                } else {
                  const response = await client.query({
                    query: getTypology,
                    variables: {
                      id: typology_id,
                    },
                  });

                  const typology = response.data.getTypology;

                  const typology_translated = typology.typology_translations.find(
                    translation => translation.language_id === language_id,
                  );

                  ui_subtermin = (
                    <p key={index}>{`${numberFormat(typologies_sum)}  ${typology_translated.name}[${
                      typology.persons_capacity
                    }]`}</p>
                  );
                }

                return ui_subtermin;
              }),
          );
        }
      }
      this.setState({
        ui,
      });
    }
  };

  render() {
    const { int, comment_contigent } = this.props;

    return this.state.ui ? (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.contigent}</h4>
        </div>

        <div>
          <div className="preview-content-div preview-no-padding preview-no-margin ">{this.state.ui}</div>
          {comment_contigent && (
            <div disabled id="comment_contigent" className="preview-content-div preview-textarea">
              {comment_contigent.trim()}
            </div>
          )}
        </div>
      </div>
    ) : (
      this.state.ui
    );
  }
}

const mapStateToProps = state => {
  const {
    templateInt,
    preview: {
      offer_contract_document: {
        offer_contract_document_type_id: type_id,
        termin_periods,
        language_id,
        comment_contigent,
      },
    },
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();
  const termin = termin_periods[0];

  return {
    int,
    type_id,
    termin,
    termin_periods,
    language_id,
    comment_contigent,
  };
};

const TerminContigentsPreview = connect(mapStateToProps)(withApollo(TerminContigent));
const AlotmanTerminContigentsPreview = connect(mapStateToProps)(withApollo(AlotmanTerminContigent));
const SubterminContigentsPreview = connect(mapStateToProps)(withApollo(SubterminContigents));

function ContigentsUsage() {
  return (
    <Fragment>
      <TerminContigentsPreview />
      <SubterminContigentsPreview />
      <AlotmanTerminContigentsPreview />
    </Fragment>
  );
}

export default ContigentsUsage;
