import React from "react";

// ui
import { TextField, MenuItem, Grid, withStyles } from "@material-ui/core";
// hooks
import useVoucherStatus from "./hooks/useVoucherStatus";

const styles = theme => ({
  container: {
    textAlign: "center",
    width: "100%",
  },

  textField: {
    textAlign: "left",
    margin: theme.spacing.unit,
    minWidth: 120,
    width: "70%",
  },

  circle: {
    width: "100%",
    height: "100%",
    background: "#000",
    borderRadius: 50,
  },
  //style for font size
  resize: {
    fontSize: 13,
  },
});

/**
 * VoucherStatus is used for handling voucher status
 */
const VoucherStatus = ({ classes, voucherStatus, terminPeriodContigentId, programDayContentId }) => {
  const { handleOnVoucherStatusChange, voucherStatusTypes } = useVoucherStatus();

  // return root
  return (
    <div className={classes.container}>
      <TextField
        className={classes.textField}
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
        select
        fullWidth
        value={voucherStatus || ""}
        onChange={event =>
          handleOnVoucherStatusChange(event.target.value, terminPeriodContigentId, programDayContentId)
        }
      >
        {voucherStatusTypes
          ? voucherStatusTypes.map(voucherStatusType => (
              <MenuItem key={`key-${voucherStatusType.name}`} value={voucherStatusType.name}>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <div className={`circle ${voucherStatusType.description}`} />
                  </Grid>
                  <Grid item xs={10}>
                    {voucherStatusType.description}
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          : null}
      </TextField>
    </div>
  );
};

export default withStyles(styles)(VoucherStatus);
