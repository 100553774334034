import React, { Component } from "react";

import "./preview.css";

import PreviewHeader from "./PreviewHeader";
import PreviewHeaderDBData from "./PreviewHeaderDBData";
import PreviewContent from "./PreviewContent/PreviewContent";
import { connect } from "react-redux";
import PreviewTitle from "./PreviewTitle";
import { setLanguage } from "../../store/actions/previewActions";
import { Grid } from "@material-ui/core";

class Preview extends Component {
  language_set = false;

  componentDidMount() {
    if (this.props.preview && !this.language_set) {
      this.props.setLanguage(this.props.preview.offer_contract_document.language_id || 1);

      this.language_set = true;
    }
  }

  componentDidUpdate() {
    if (this.props.preview && !this.language_set) {
      this.props.setLanguage(this.props.preview.offer_contract_document.language_id || 1);

      this.language_set = true;
    }
  }

  render() {
    if (!this.props.preview) {
      return null;
    }

    if (!this.props.language_id) {
      return null;
    }

    const ui = React.Children.map(this.props.children, child => React.cloneElement(child, { ...this.props }));

    return (
      //<div className="preview-container">
      <div className="preview-root">
        <table>
          <tbody>
            <tr>
              <td>
                <div className="preview">{ui}</div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div>
                  <div className="fake-footer" />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="preview-footer">
          <div className="preview-footer-text">
            <div>
              Trgovački sud u Rijeci, MBS 040143504, temeljni kapitali 9.966.000,00 Kn, član uprave Vibor Olivari
            </div>
            <div>Vibor Olivari, voditelj poslovanja/direktor</div>
          </div>
          <Grid container>
            <Grid item xs={3}>
              <div className="preview-footer-header-text">RDA Member Nr. A1 822</div>
              <img src="/images/rda.jpg" alt="RDA" />
            </Grid>
            <Grid item xs={3}>
              <div className="preview-footer-header-text">GPN Member for Croatia</div>
              <img src="/images/gpn.png" alt="gpn" />
            </Grid>
            <Grid item xs={3}>
              <div className="preview-footer-header-text">Member of UHPA</div>
              <img src="/images/upha.png" alt="upha" />
            </Grid>
            <Grid item xs={3}>
              <div className="preview-footer-header-text">ISO 9001:2015</div>
              <img src="/images/r.jpg" alt="r" />
            </Grid>
          </Grid>
        </div>
      </div>
      //</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    preview: state.preview.preview,
    language_id: state.preview.templateInt.language,
  };
};

const PreviewWithProps = connect(
  mapStateToProps,
  { setLanguage },
)(Preview);

function PreviewUsage() {
  return (
    <PreviewWithProps>
      <PreviewHeader />
      <PreviewHeaderDBData />
      <PreviewTitle />
      <PreviewContent />
    </PreviewWithProps>
  );
}

export default PreviewUsage;
