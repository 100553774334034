import React, { Component } from "react";
import { connect } from "react-redux";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import debounce from "lodash/debounce";
import Grid from "@material-ui/core/Grid";
import toastr from "toastr";

import { updateInquiry } from "../../../graphql/mutation/inquiry";

// Shared components
import DividerUI from "../../../components/UI/Divider";
import Remark from "../../../components/UI/CommentTextArea";
import AttachmentItem from "../../../components/UI/AttachmentItem";

class InquiryData extends Component {
  /**
   * render label no documents attached
   */
  renderNoDocumentsUpload() {
    return (
      <Grid container spacing={24} alignItems="center">
        <Grid item xs={12}>
          Nema uploadanih datoteka
        </Grid>
      </Grid>
    );
  }

  /**
   * on field remark change
   */
  onChange = name => event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();

    this.delayedCallback(name, event);
  };

  changeContentField = async (name, { target: { value } }) => {
    try {
      await this.props.mutate({
        variables: {
          id: this.props.inquiry.id,
          patch: {
            remark: value,
          },
        },
      });

      toastr.success("Napomena upita ažurirana");
    } catch (error) {}
  };

  delayedCallback = debounce(this.changeContentField, 1000);

  render() {
    const { inquiry } = this.props;

    if (inquiry) {
      return (
        <React.Fragment>
          <Grid container spacing={16} alignItems="center">
            <Grid item xs={12}>
              <DividerUI subheaderText="Info o povezanom upitu" />
            </Grid>

            <Grid container spacing={16}>
              <Grid item>
                <label className="form-label-right label-margin-block-start-8">Uploadane datoteke</label>
              </Grid>
              <Grid item md container sm={12} spacing={8}>
                {inquiry.attachments && inquiry.attachments.length
                  ? inquiry.attachments.map(file => (
                      <Grid key={file.file_id} container>
                        <AttachmentItem file={file} />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>

            <Grid container spacing={16} style={{ marginTop: 20 }}>
              <Grid item>
                <label className="form-label-right ">Napomena</label>
              </Grid>
              <Grid item container md={6} xs={12}>
                <Remark
                  name="napomena"
                  defaultValue={inquiry.remark || ""}
                  customOnChange={this.onChange("remark")}
                  placeholder="Napomena"
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  const { inquiry } = state.offer.offer.offer_contract_document;

  return {
    inquiry: inquiry,
  };
};

export default connect(mapStateToProps)(compose(graphql(updateInquiry))(withApollo(InquiryData)));
