// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectTemplateAnnouncements,
  setTemplateAnnouncements,
} from "src/views/Administration/TemplateAnnouncement/templateAnnouncementSlice";

// graphql
import { UPDATE_ANNOUNCEMENT_TEMPLATE } from "src/graphql/mutation/administration";

// utils
import { difference } from "src/utility/difference";

/**
 * useAnnouncementContent is entry point of data for announcementContent component
 */
export default function useAnnouncementContent(templateId) {
  // redux state
  const templateAnnouncements = useSelector(selectTemplateAnnouncements);

  const dispatch = useDispatch();

  // mutation update template announcement mutation
  const [updateAnnouncementTemplate] = useMutation(UPDATE_ANNOUNCEMENT_TEMPLATE);

  let templateTranslation = templateAnnouncements.find(templateTranslation => templateTranslation.id === templateId);

  /**
   * handle on template announcement update
   */
  const handleOnTemplateAnnouncementUpdate = async (values, history) => {
    let inputValues = {
      id: templateId,
    };

    const differentValues = difference(values, templateTranslation);

    // prepare input values
    for (const [key, value] of Object.entries(differentValues)) {
      if (key !== "language_id" && key !== "language_desc" && key !== "language_code" && key !== "__typename") {
        inputValues = {
          ...inputValues,
          ...(key !== "id" && { [key]: value.translation }),
        };
      }
    }

    const responseAnnouncementTemplate = await updateAnnouncementTemplate({
      variables: {
        input: inputValues,
      },
    });

    let announcementTemplateUpdate = get(responseAnnouncementTemplate, "data.updateAnnouncementLabelTranslations");

    if (announcementTemplateUpdate) {
      toastr.success("Uspješno ažuriranje");

      // todo handle on program update
      const templateAnnouncementsUpdate = templateAnnouncements.map(templateAnnouncement => {
        if (templateAnnouncement.id === announcementTemplateUpdate.id) {
          return {
            ...announcementTemplateUpdate,
          };
        }

        return templateAnnouncement;
      });

      // update templateAnnouncements
      dispatch(setTemplateAnnouncements(templateAnnouncementsUpdate));

      history.push("/administracija/najava_predlozak/");
    } else {
      toastr.error("Greška prilikom ažuriranja");
    }
  };

  return { handleOnTemplateAnnouncementUpdate, templateTranslation };
}
