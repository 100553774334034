import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { changeDocumentParam } from "../../../../store/actions";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

class ShowDailyProgram extends PureComponent {
  handleChange = () => {
    this.props.changeDocumentParam({
      key: "program_visible",
      value: !this.props.program_visible
    });
  };

  render() {
    const { program_visible, isNotDraft } = this.props;

    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              disabled={isNotDraft}
              checked={program_visible}
              onChange={this.handleChange}
            />
          }
          label={
            program_visible
              ? "Program se prikazuje prilikom printanja"
              : "Program se ne prikazuje prilikom printanja"
          }
        />
      </FormGroup>
    );
  }
}

export default connect(
  state => {
    const { program_visible } = state.offer.offer.offer_contract_document;

    return {
      program_visible
    };
  },
  { changeDocumentParam }
)(ShowDailyProgram);
