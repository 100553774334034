// libs
import { get } from "lodash";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawerPayload, setGuideDrawerPayload } from "src/views/Guides/guidesSlice";

/**
 * useLayoutCommentsContainer is entry point of data for layoutCommentsContainer
 */
export default function useLayoutCommentsContainer() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  /**
   * on guide layout comment change
   */
  const onGuideLayoutCommentChange = useCallback(
    event => {
      let guideLayoutComment = get(event, "target.value");

      dispatch(setGuideDrawerPayload({ ...guidePayload, guideLayoutComment: guideLayoutComment }));
    },
    [dispatch, guidePayload],
  );

  return {
    guideLayoutComment: get(guidePayload, "guideLayoutComment", ""),
    onGuideLayoutCommentChange,
  };
}
