import React, { Component } from "react";
import { graphql } from "react-apollo";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { listRegion } from "../../../../graphql/query/administration";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import FormValidator from "../../../../components/UI/FormValidator";

const form = {
  padding: "24px",
};

class CityForm extends Component {
  static schema = {
    properties: {
      name: {
        type: "string",
        isNotEmpty: true,
        errorMessage: "Ime grada ne smije biti prazno",
      },
      region_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati regiju",
      },
    },
  };

  render() {
    const {
      title,
      city,
      data: { loading, listRegion },
      onChangeField,
      onSubmit,
    } = this.props;
    if (loading) {
      return <div>Loading regije . . . </div>;
    }

    return (
      <FormValidator schema={CityForm.schema} submit={onSubmit} data={city}>
        {({ error, errorMsg, submit }) => (
          <div style={form}>
            <h3>{title}</h3>
            <div className="form-control-grid">
              <FormControl fullWidth /* className={classes.margin} */>
                <InputLabel shrink={true} htmlFor="name" error={error("/name")}>
                  Naziv grada
                </InputLabel>
                <Input
                  id="name"
                  name="name"
                  error={error("/name")}
                  value={city.name ? city.name : ""}
                  onChange={({ target: { value } }) => onChangeField({ key: "name", value })}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                {error("/name") && <FormHelperText error={error("/name")}>{errorMsg("/name")}</FormHelperText>}
              </FormControl>
            </div>
            <div className="form-control-grid">
              <FormControl fullWidth /* className={classes.margin} */>
                <InputLabel shrink={true} htmlFor="postal_code">
                  Poštanski broj
                </InputLabel>
                <Input
                  id="postal_code"
                  name="postal_code"
                  value={city.postal_code ? city.postal_code : ""}
                  onChange={({ target: { value } }) => onChangeField({ key: "postal_code", value })}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </FormControl>
            </div>
            <div className="form-control-grid">
              <FormControl fullWidth /* className={classes.margin} */>
                <InputLabel shrink={true} htmlFor="postal_code">
                  Pozivni broj
                </InputLabel>
                <Input
                  id="area_code"
                  name="area_code"
                  value={city.area_code ? city.area_code : ""}
                  onChange={({ target: { value } }) => onChangeField({ key: "area_code", value })}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </FormControl>
            </div>
            <div className="form-control-grid">
              <AutocompleteSelect
                label="Regija"
                placeholder="Odaberi regiju"
                error={error("/region_id")}
                errorMsg={errorMsg("/region_id")}
                dataSource={listRegion.map(region => ({
                  value: region.id,
                  label: `${region.name}, ${region.country.name}`,
                }))}
                defaultValue={city.region_id}
                autocompleteHandler={selected =>
                  onChangeField({
                    key: "region_id",
                    value: selected,
                  })
                }
              />
            </div>
            <Button variant="contained" color="primary" onClick={submit}>
              Spremi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="outlined" onClick={this.props.cancelToggle}>
              Poništi
            </Button>
          </div>
        )}
      </FormValidator>
    );
  }
}

export default graphql(listRegion)(CityForm);
