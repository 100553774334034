import React from "react";

// libs
import { get } from "lodash";

// components
import AnnouncementOrderEditToolbarView from "./components/Toolbar";
import AnnouncementOrderEditForm from "./components/EditForm";
/**
 * AnnouncementOrderEditContainer
 */
export default function AnnouncementOrderEditContainer(props) {
  // props
  const announcementOrderId = get(props, "match.params.id");

  // return root
  return (
    <div>
      <AnnouncementOrderEditToolbarView announcementOrderId={announcementOrderId} />
      <AnnouncementOrderEditForm announcementOrderId={announcementOrderId} />
    </div>
  );
}
