import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import { getPartnerClientType } from "../../../../graphql/query/administration";
import PartnerClientTypeForm from "./PartnerClientTypeForm";
import { updatePartnerClientType } from "../../../../graphql/mutation/administration";

import { removeAllEmptyKeys, prepareForSending } from "../../../../utility/prepare";
import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class EditPartnerClientType extends Component {
  state = {
    partnerClientType: {
      desc: "",
    },
  };

  async componentDidMount() {
    const { partnerClientType } = this.props;

    const response = await this.props.client.query({
      query: getPartnerClientType,
      variables: { id: partnerClientType.id },
    });

    if (response.data.getPartnerClientType) {
      // set partnerClientType for editing
      this.setState({
        partnerClientType: { ...response.data.getPartnerClientType },
      });
    }
  }

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        partnerClientType: {
          ...prevState.partnerClientType,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { partnerClientType } = this.state;

      await this.props.mutate({
        variables: {
          id: partnerClientType.id,
          ...removeAllEmptyKeys(prepareForSending(partnerClientType), ["__typename", "id"]),
        },
      });
      toastr.success("Tip partnera uspješno ažuriran");

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const { partnerClientType } = this.state;

    return (
      <Fragment>
        <TableTitle title="Ažuriranje tipa partnera" onBackPressed={this.props.closeEdit} />
        <PartnerClientTypeForm
          partnerClientType={partnerClientType}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.closeEdit}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(updatePartnerClientType)(withApollo(EditPartnerClientType));
