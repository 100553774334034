import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import toastr from "toastr";
import EstablishmentLegalEntityForm from "./EstablishmentLegalEntityForm";
import { createEstablishmentLegalEntity } from "../../../../graphql/mutation/administration";
import { removeAllEmptyKeys } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class CreateEstablishmentLegalEntity extends Component {
  state = {
    establishmentLegalEntity: {
      name: "",
      web: "",
      email: "",
      oib: "",
      address1: "",
      address2: "",
      phone: "",
      fax: "",
      contact_person1: "",
      contact_person2: "",
      city_id: "",
    },
  };

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        establishmentLegalEntity: {
          ...prevState.establishmentLegalEntity,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { establishmentLegalEntity } = this.state;

      await this.props.mutate({
        variables: {
          ...removeAllEmptyKeys(establishmentLegalEntity),
        },
        // update: (store, { data: { createEstablishmentLegalEntity } }) => {
        //   const data = store.readQuery({ query: listEstablishmentLegalEntity });

        //   data.listEstablishmentLegalEntity.push(
        //     createEstablishmentLegalEntity
        //   );

        //   store.writeQuery({ query: listEstablishmentLegalEntity, data });
        // }
      });
      toastr.success("Uspješno kreirano");
      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    const { establishmentLegalEntity } = this.state;

    return (
      <Fragment>
        <TableTitle title={`Stvaranje nove firme`} onBackPressed={this.props.cancelToggle} />

        <EstablishmentLegalEntityForm
          establishmentLegalEntity={establishmentLegalEntity}
          onChangeField={this.onChangeField}
          onSubmit={this.submit}
          cancelToggle={this.props.cancelToggle}
        />
      </Fragment>
    );
  }
}

export default graphql(createEstablishmentLegalEntity)(CreateEstablishmentLegalEntity);
