import React, { Fragment } from "react";
import { List, ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";

import { isRoleGranted } from "../../../utility/user";

const renderNestedItems = (props, el, i) => {
  if (props.navList[el].nested) {
    return Object.keys(props.navList[el].nested).map(row => {
      const nestedRow = props.navList[el].nested[row];

      let openTooltipNested = false;
      if (props.minimizeDrawer && row === props.minimizedDrawerHovered) {
        openTooltipNested = true;
      }

      return (
        <Fragment key={i + row}>
          <ListItem
            className={[
              "sidebar-list-item",
              props.active === nestedRow.to ? "sidebar-list-item-active" : "",
              props.minimizeDrawer ? "sidebar-list-item-active-minimized" : "",
            ].join(" ")}
            onClick={() => props.clicked(nestedRow.to)}
            onMouseEnter={() => {
              return props.minimizeDrawer ? props.minimizedDrawerHoveredHandler(row) : null;
            }}
            onMouseLeave={() => {
              return props.minimizeDrawer ? props.minimizedDrawerHoveredHandler("") : null;
            }}
            leftIcon={nestedRow.icon}
            primaryText={!props.minimizeDrawer ? nestedRow.name : null}
            rightIconButton={
              <IconMenu iconButtonElement={<IconButton style={{ display: "none" }} />}>
                <MenuItem className="icon-menu-wraper">{row}</MenuItem>
              </IconMenu>
            }
          />
          {openTooltipNested ? <div className="hoverSidebar">{nestedRow.minimizedTooltip}</div> : null}
        </Fragment>
      );
    });
  }
};

const ListExampleSimple = props => (
  <List>
    {Object.keys(props.navList).map((el, i) => {
      if (!isRoleGranted(props.navList[el].permission)) {
        return null;
      }

      return (
        <Fragment key={i}>
          <ListItem
            className={[
              "sidebar-list-item",
              props.minimizeDrawer && !!props.navList[el].nested ? "sidebar-list-item-hidden" : "",
            ].join(" ")}
            disabled={!!props.navList[el].nested}
            primaryText={!props.minimizeDrawer ? el : null}
          />
          {props.navList[el].nested ? renderNestedItems(props, el, i) : null}
        </Fragment>
      );
    })}
  </List>
);

export default ListExampleSimple;
