import { useEffect } from "react";

//libs
import _ from "lodash";
import { useQuery } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectTemplateAnnouncements,
  setTemplateAnnouncementConstants,
  setTemplateAnnouncements,
} from "src/views/Administration/TemplateAnnouncement/templateAnnouncementSlice";

// graphql
import { TEMPLATE_LIST_ANNOUNCEMENT } from "src/graphql/query/administration";
import { useTableLocalStorage } from "src/hooks/useTableLocalStorage";
import { LOCAL_STORAGE_ADMINISTRATION_ANN_TEMPLATE } from "src/utility/constants";

/**
 * useTemplateAnnouncementList is entry point of data for templateAnnouncementList component
 */
export default function useTemplateAnnouncementList() {
  // local storage
  const { tableState, setTableState } = useTableLocalStorage(LOCAL_STORAGE_ADMINISTRATION_ANN_TEMPLATE);

  // redux state
  const templateAnnouncements = useSelector(selectTemplateAnnouncements);

  const dispatch = useDispatch();

  // fetch list template announcement
  const { data: dataTemplateAnnouncement, loading } = useQuery(TEMPLATE_LIST_ANNOUNCEMENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  // set list template announcement
  useEffect(() => {
    // set template list
    const templateAnnouncements = _.get(dataTemplateAnnouncement, "listAnnouncementLabelTranslations");

    if (templateAnnouncements) {
      // set templateAnnouncements
      dispatch(setTemplateAnnouncements(templateAnnouncements));
    }

    // set language list
    const listLanguage = _.get(dataTemplateAnnouncement, "listLanguage");

    if (listLanguage) {
      // set constants
      dispatch(setTemplateAnnouncementConstants({ listLanguage: listLanguage }));
    }
  }, [dataTemplateAnnouncement, dispatch]);

  return {
    templateAnnouncements,
    loading,
    tableState,
    setTableState,
  };
}
