// libs
import { createSlice } from "@reduxjs/toolkit";

export const announcementEditSlice = createSlice({
  name: "announcementEdit",
  initialState: {},
  reducers: {
    // announcement selected
    setAnnouncementSelected: (state, action) => {
      state.announcementSelected = action.payload;
      // reset announcement edit inputs
      state.announcementEditInputs = {};
    },

    // announcement
    setAnnouncement: (state, action) => {
      state.hotelGuideAnnouncement = action.payload.hotelGuideAnnouncement;
      state.hotelNoGuideAnnouncement = action.payload.hotelNoGuideAnnouncement;
      state.guideAnnouncement = action.payload.guideAnnouncement;
      state.guideTourAnnouncement = action.payload.guideTourAnnouncement;
      state.guideLocalAnnouncement = action.payload.guideLocalAnnouncement;
      state.agencyAnnouncement = action.payload.agencyAnnouncement;
      state.agencyTourAnnouncement = action.payload.agencyTourAnnouncement;
      state.agencyLocalAnnouncement = action.payload.agencyLocalAnnouncement;
      state.agencyLocalProgramAnnouncement = action.payload.agencyLocalProgramAnnouncement;
      state.supplierAnnouncement = action.payload.supplierAnnouncement;
      state.phraseAnnouncement = action.payload.phraseAnnouncement;
    },

    // announcement input field
    setAnnouncementInputField: (state, action) => {
      state.announcementEditInputs = {
        [action.payload.editInputKey]: action.payload.isEdit,
      };
    },

    // announcement copy state
    setAnnouncementCopyState: (state, action) => {
      state.announcementCopyState = action.payload;
    },

    // voucher create dialog open state
    setIsVoucherCreateDialogOpen: (state, action) => {
      state.isVoucherCreateDialogOpen = action.payload;
    },

    // voucher dialog open state
    setVoucherDialogOpen: (state, action) => {
      state.isVoucherDialogOpen = action.payload.isVoucherDialogOpen;
    },
  },
});

export const {
  setAnnouncement,
  setAnnouncementSelected,
  setAnnouncementInputField,
  setAnnouncementCopyState,
  setIsVoucherCreateDialogOpen,
  setVoucherDialogOpen,
} = announcementEditSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`

// announcement selected
export const selectAnnouncementSelected = state => state.announcementEdit.announcementSelected;
// announcement
export const selectHotelGuideAnnouncement = state => state.announcementEdit.hotelGuideAnnouncement;
export const selectHotelNoGuideAnnouncement = state => state.announcementEdit.hotelNoGuideAnnouncement;
export const selectGuideAnnouncement = state => state.announcementEdit.guideAnnouncement;
export const selectGuideTourAnnouncement = state => state.announcementEdit.guideTourAnnouncement;
export const selectGuideLocalAnnouncement = state => state.announcementEdit.guideLocalAnnouncement;
export const selectAgencyAnnouncement = state => state.announcementEdit.agencyAnnouncement;
export const selectAgencyTourAnnouncement = state => state.announcementEdit.agencyTourAnnouncement;
export const selectAgencyLocalAnnouncement = state => state.announcementEdit.agencyLocalAnnouncement;
export const selectAgencyLocalProgramAnnouncement = state => state.announcementEdit.agencyLocalProgramAnnouncement;
export const selectSupplierAnnouncement = state => state.announcementEdit.supplierAnnouncement;
export const selectPhraseAnnouncement = state => state.announcementEdit.phraseAnnouncement;

// announcement edit inputs
export const selectAnnouncementEditInputs = state => state.announcementEdit.announcementEditInputs;

// announcement copy state
export const selectAnnouncementCopyState = state => state.announcementEdit.announcementCopyState;

// vocuher create dialog open state
export const selectIsVoucherCreateDialogOpen = state => state.announcementEdit.isVoucherCreateDialogOpen;

// voucher dialog open state
export const selectIsVoucherDialogOpen = state => state.announcementEdit.isVoucherDialogOpen;

export default announcementEditSlice.reducer;
