import React, { Fragment, Component } from "react";
import { graphql } from "react-apollo";

import RaisedButton from "material-ui/RaisedButton";

import { listService } from "../../../../graphql/query/administration";
import ReactTableCustom from "../../../../components/UI/ReactTable";
import EditIcon from "../../../../components/UI/EditIcon";
import { findServiceTypeByCroLang } from "../../../../utility/services";
import { config } from "../../../../utility/globals";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import TableTitle from "src/components/UI/TableTitle";

class ServiceList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: service => (
          <div className="ReactTableFirstColumnDiv" onClick={() => this.props.openEdit(service)}>
            <EditIcon />
          </div>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Usluga",
        accessor: "name",
      },
      {
        Header: "Tip usluge",
        accessor: "type",
      },
    ],
  };

  render() {
    const {
      toggleCreate,
      data: { loading, listService },
    } = this.props;

    if (loading) {
      return <div>Dohvaćam usluge . . . </div>;
    }

    const dataToShow =
      listService &&
      listService.map(service => {
        const st = service.service_type && findServiceTypeByCroLang(service.service_type);

        return {
          ...service,
          type: (st && st.name) || "",
          name: service.service_translations.find(
            ({ language_id }) => config.language[String(language_id)] === "Croatian",
          ).name,
        };
      });

    return (
      <Fragment>
        <TableTitle title="Lista uključenih usluga" />

        <ReactTableCustom
          data={dataToShow || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
        <RaisedButton className="administration-btn-add-new" label="Dodaj novi" primary={true} onClick={toggleCreate} />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  graphql(listService, {
    options: {
      fetchPolicy: "network-only",
    },
  })(ServiceList),
);
