import React from "react";
import { connect } from "react-redux";
import Upload from "../../../../components/UI/Upload";
import Grid from "@material-ui/core/Grid";

import AttachmentItem from "../../../../components/UI/AttachmentItem";

const DocumentsUpload = props => {
  return (
    <React.Fragment>
      {props.attachments && props.attachments.length ? (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12}>
              <h3>Uploadane datoteke</h3>
            </Grid>
            {props.attachments.map(file => (
              <Grid item xs={12} key={file.file_id}>
                <Grid container>
                  <AttachmentItem file={file} onDelete={() => props.deleteUploadedFile(file.id)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ) : null}
      <Upload onDrop={props.onDrop} files={props.files} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {
    offer_contract_document: { attachments },
  } = state.offer.offer;

  return {
    attachments,
  };
};

export default connect(mapStateToProps)(DocumentsUpload);
