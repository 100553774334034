import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { withRouter } from "react-router-dom";
import FormValidator from "../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

class PartnerServiceTypesForm extends React.Component {
  static schema = {
    properties: {
      desc: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Potrebno je unijeti naziv"
      }
    }
  };

  render() {
    const { onSubmit, serviceType, onChangeField } = this.props;

    return (
      <FormValidator
        schema={PartnerServiceTypesForm.schema}
        submit={onSubmit}
        data={serviceType}
      >
        {({ error, errorMsg, submit }) => (
          <form className="partner-service-type-form" onSubmit={this.onSubmit}>
            <div className="form-control-grid">
              <FormControl fullWidth error={error("/desc")}>
                <InputLabel shrink={true} htmlFor="name">
                  Tip usluge
                </InputLabel>
                <Input
                  value={serviceType.desc}
                  onChange={onChangeField}
                  inputProps={{ name: "desc" }}
                />
                {error("/desc") && (
                  <FormHelperText>{errorMsg("/desc")}</FormHelperText>
                )}
              </FormControl>
            </div>
            <br />
            <Button variant="contained" onClick={submit}>
              Spremi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              onClick={() =>
                this.props.history.push("/administracija/tip_usluge_partnera")
              }
            >
              Poništi
            </Button>
          </form>
        )}
      </FormValidator>
    );
  }
}

export default withRouter(PartnerServiceTypesForm);
