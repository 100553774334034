// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";

import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideScheduleDrawerPayload,
  selectSupplierPartners,
  selectVoucherStatusTypes,
  setGuideScheduleAnnouncementPartners,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_ANNOUNCEMENT_VOUCHER_STATUS } from "src/graphql/mutation/announcement";

/**
 * useVoucherStatus is entry point of data for VoucherStatus component
 */
export default function useVoucherStatus() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const voucherStatusTypes = useSelector(selectVoucherStatusTypes);
  const supplierPartners = useSelector(selectSupplierPartners);

  const dispatch = useDispatch();

  // mutation update announcement status
  const [updateAnnouncementStatus] = useMutation(UPDATE_ANNOUNCEMENT_VOUCHER_STATUS);

  /**
   * handle on voucher status change
   */
  const handleOnVoucherStatusChange = async (voucherStatus, terminPeriodContigentId, programDayContentId) => {
    /* add conditionally terminPeriodContigentId or programDayContentId regarding supplier partner type
     * is it hotel or guide
     */
    const responseUpdateVoucherStatus = await updateAnnouncementStatus({
      variables: {
        input: {
          program_id: guidePayload.programId,
          voucher_status: voucherStatus,
          ...(terminPeriodContigentId && { termin_period_contigent_id: terminPeriodContigentId }),
          ...(programDayContentId && { program_day_content_id: programDayContentId }),
        },
      },
    });

    const updatedSupplier = get(responseUpdateVoucherStatus, "data.updateAnnounecementVoucherStatus");

    // update supplierPartner in context
    if (updatedSupplier) {
      const partnerClientId = get(updatedSupplier, "partner_client_id");
      const guideId = get(updatedSupplier, "guide_id");
      const establishmentId = get(updatedSupplier, "establishment_id");

      const updatedSupplierPartners = supplierPartners.map(supplierPartner => {
        // check if this is guide partner
        if (guideId && supplierPartner.guide_id === guideId) {
          return updatedSupplier;
        }

        // check if this is establishment partner
        if (establishmentId && supplierPartner.establishment_id === establishmentId) {
          return updatedSupplier;
        }

        // check if this is main partner
        if (partnerClientId && supplierPartner.partner_client_id === partnerClientId) {
          return updatedSupplier;
        }

        return supplierPartner;
      });

      // update supplier partners
      dispatch(setGuideScheduleAnnouncementPartners({ supplierPartners: updatedSupplierPartners }));

      toastr.success("Uspješno ažuriran status vouchera");
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return { handleOnVoucherStatusChange, voucherStatusTypes };
}
