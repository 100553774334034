import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";

import PartnerClientTypeForm from "./PartnerClientTypeForm";
import { createPartnerClientType } from "../../../../graphql/mutation/administration";
import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class CreatePartnerClientType extends Component {
  state = {
    partnerClientType: {
      desc: "",
    },
  };

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        partnerClientType: {
          ...prevState.partnerClientType,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { partnerClientType } = this.state;

      await this.props.mutate({
        variables: {
          ...partnerClientType,
        },
      });

      toastr.success("Tip partnera uspješno kreiran");

      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    const { partnerClientType } = this.state;

    return (
      <Fragment>
        <TableTitle title="Kreiranje novog tipa partnera" onBackPressed={this.props.cancelToggle} />
        <PartnerClientTypeForm
          partnerClientType={partnerClientType}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.cancelToggle}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(createPartnerClientType)(CreatePartnerClientType);
