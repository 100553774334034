import { useEffect } from "react";

// libs
import { useLazyQuery } from "react-apollo";
import toastr from "toastr";
import { get } from "lodash";
import { useDispatch } from "react-redux";

// graphql
import {
  GET_HOTEL_GUIDE_ANNOUNCEMENT,
  GET_HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GET_GUIDE_ANNOUNCEMENT,
  GET_GUIDE_TOUR_ANNOUNCEMENT,
  GET_AGENCY_ANNOUNCEMENT,
  GET_AGENCY_TOUR_ANNOUNCEMENT,
  GET_AGENCY_LOCAL_ANNOUNCEMENT,
  GET_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  GET_SUPPLIER_ANNOUNCEMENT,
  GET_PHRASE_ANNOUNCEMENT,
  GET_GUIDE_LOCAL_ANNOUNCEMENT,
} from "src/graphql/query/announcement";
// import { CREATE_VOUCHER } from "src/graphql/mutation/voucher";

// redux state
import {
  setAnnouncement,
  setAnnouncementCopyState,
  setIsVoucherCreateDialogOpen,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";
// import { selectAnnouncementVouchers } from "../../VoucherList/voucherSlice";

// constants
import {
  HOTEL_GUIDE_ANNOUNCEMENT,
  HOTEL_NO_GUIDE_ANNOUNCEMENT,
  GUIDE_ANNOUNCEMENT,
  GUIDE_TOUR_ANNOUNCEMENT,
  AGENCY_ANNOUNCEMENT,
  AGENCY_TOUR_ANNOUNCEMENT,
  AGENCY_LOCAL_ANNOUNCEMENT,
  AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT,
  SUPPLIER_ANNOUNCEMENT,
  PHRASE_ANNOUNCEMENT,
  GUIDE_LOCAL_ANNOUNCEMENT,
} from "src/utility/constants";

/**
 * useAnnouncementForm is entry point of data for announcement from component
 */
export default function useAnnouncementForm(announcementSelected) {
  // // redux state
  const dispatch = useDispatch();

  // hooks
  // fetch hotel guide announcement
  const [
    getHotelGuideAnnouncement,
    { loading: loadingHotelGuideAnnouncement, data: dataHotelGuideAnnouncement },
  ] = useLazyQuery(GET_HOTEL_GUIDE_ANNOUNCEMENT);

  // fetch hotel no guide announcement
  const [
    getHotelNoGuideAnnouncement,
    { loading: loadingHotelNoGuideAnnouncement, data: dataHotelNoGuideAnnouncement },
  ] = useLazyQuery(GET_HOTEL_NO_GUIDE_ANNOUNCEMENT);

  // fetch guide announcement
  const [getGuideAnnouncement, { loading: loadingGuideAnnouncement, data: dataGuideAnnouncement }] = useLazyQuery(
    GET_GUIDE_ANNOUNCEMENT,
  );

  // fetch guide tour announcement
  const [
    getGuideTourAnnouncement,
    { loading: loadingGuideTourAnnouncement, data: dataGuideTourAnnouncement },
  ] = useLazyQuery(GET_GUIDE_TOUR_ANNOUNCEMENT);

  // fetch guide local announcement
  const [
    getGuideLocalAnnouncement,
    { loading: loadingGuideLocalAnnouncement, data: dataGuideLocalAnnouncement },
  ] = useLazyQuery(GET_GUIDE_LOCAL_ANNOUNCEMENT);

  // fetch agency announcement
  const [getAgencyAnnouncement, { loading: loadingAgencyAnnouncement, data: dataAgencyAnnouncement }] = useLazyQuery(
    GET_AGENCY_ANNOUNCEMENT,
  );

  // fetch agency tour announcement
  const [
    getAgencyTourAnnouncement,
    { loading: loadingAgencyTourAnnouncement, data: dataAgencyTourAnnouncement },
  ] = useLazyQuery(GET_AGENCY_TOUR_ANNOUNCEMENT);

  // fetch agency local announcement
  const [
    getAgencyLocalAnnouncement,
    { loading: loadingAgencyLocalAnnouncement, data: dataAgencyLocalAnnouncement },
  ] = useLazyQuery(GET_AGENCY_LOCAL_ANNOUNCEMENT);

  // fetch agency local program announcement
  const [
    getAgencyLocalProgramAnnouncement,
    { loading: loadingAgencyLocalProgramAnnouncement, data: dataAgencyLocalProgramAnnouncement },
  ] = useLazyQuery(GET_AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT);

  // fetch supplier announcement
  const [
    getSupplierAnnouncement,
    { loading: loadingSupplierAnnouncement, data: dataSupplierAnnouncement },
  ] = useLazyQuery(GET_SUPPLIER_ANNOUNCEMENT);

  // fetch phrase announcement
  const [getPhraseAnnouncement, { loading: loadingPhraseAnnouncement, data: dataPhraseAnnouncement }] = useLazyQuery(
    GET_PHRASE_ANNOUNCEMENT,
  );

  // fetch announcement
  useEffect(() => {
    if (announcementSelected) {
      switch (announcementSelected.announcement_type) {
        case HOTEL_GUIDE_ANNOUNCEMENT:
          getHotelGuideAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case HOTEL_NO_GUIDE_ANNOUNCEMENT:
          getHotelNoGuideAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case GUIDE_ANNOUNCEMENT:
          getGuideAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case GUIDE_TOUR_ANNOUNCEMENT:
          getGuideTourAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case GUIDE_LOCAL_ANNOUNCEMENT:
          getGuideLocalAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case AGENCY_ANNOUNCEMENT:
          getAgencyAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case AGENCY_TOUR_ANNOUNCEMENT:
          getAgencyTourAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case AGENCY_LOCAL_ANNOUNCEMENT:
          getAgencyLocalAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case AGENCY_LOCAL_PROGRAM_ANNOUNCEMENT:
          getAgencyLocalProgramAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case SUPPLIER_ANNOUNCEMENT:
          getSupplierAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        case PHRASE_ANNOUNCEMENT:
          getPhraseAnnouncement({ variables: { id: announcementSelected.id } });
          break;

        default:
      }
    }
  }, [
    announcementSelected,
    getAgencyAnnouncement,
    getAgencyLocalAnnouncement,
    getAgencyLocalProgramAnnouncement,
    getAgencyTourAnnouncement,
    getGuideAnnouncement,
    getGuideLocalAnnouncement,
    getGuideTourAnnouncement,
    getHotelGuideAnnouncement,
    getHotelNoGuideAnnouncement,
    getPhraseAnnouncement,
    getSupplierAnnouncement,
  ]);

  // handle hotel guide announcement
  useEffect(() => {
    const hotelGuideAnnouncement = get(dataHotelGuideAnnouncement, "getHotelGuideAnnouncement");

    if (hotelGuideAnnouncement) {
      dispatch(setAnnouncement({ hotelGuideAnnouncement: hotelGuideAnnouncement }));
    }
  }, [dataHotelGuideAnnouncement, dispatch]);

  // handle hotel no guide announcement
  useEffect(() => {
    const hotelNoGuideAnnouncement = get(dataHotelNoGuideAnnouncement, "getHotelNoGuideAnnouncement");

    if (hotelNoGuideAnnouncement) {
      dispatch(setAnnouncement({ hotelNoGuideAnnouncement: hotelNoGuideAnnouncement }));
    }
  }, [dataHotelNoGuideAnnouncement, dispatch]);

  // handle guide announcement
  useEffect(() => {
    const guideAnnouncement = get(dataGuideAnnouncement, "getGuideAnnouncement");

    if (guideAnnouncement) {
      dispatch(setAnnouncement({ guideAnnouncement: guideAnnouncement }));
    }
  }, [dataGuideAnnouncement, dispatch]);

  // handle guide tour announcement
  useEffect(() => {
    const guideTourAnnouncement = get(dataGuideTourAnnouncement, "getGuideTourAnnouncement");

    if (guideTourAnnouncement) {
      dispatch(setAnnouncement({ guideTourAnnouncement: guideTourAnnouncement }));
    }
  }, [dataGuideTourAnnouncement, dispatch]);

  // handle guide local announcement
  useEffect(() => {
    const guideLocalAnnouncement = get(dataGuideLocalAnnouncement, "getGuideLocalAnnouncement");

    if (guideLocalAnnouncement) {
      dispatch(setAnnouncement({ guideLocalAnnouncement: guideLocalAnnouncement }));
    }
  }, [dataGuideLocalAnnouncement, dispatch]);

  // handle agency announcement
  useEffect(() => {
    const agencyAnnouncement = get(dataAgencyAnnouncement, "getAgencyAnnouncement");

    if (agencyAnnouncement) {
      dispatch(setAnnouncement({ agencyAnnouncement: agencyAnnouncement }));
    }
  }, [dataAgencyAnnouncement, dispatch]);

  // handle agency tour announcement
  useEffect(() => {
    const agencyTourAnnouncement = get(dataAgencyTourAnnouncement, "getAgencyTourAnnouncement");

    if (agencyTourAnnouncement) {
      dispatch(setAnnouncement({ agencyTourAnnouncement: agencyTourAnnouncement }));
    }
  }, [dataAgencyTourAnnouncement, dispatch]);

  // handle agency local announcement
  useEffect(() => {
    const agencyLocalAnnouncement = get(dataAgencyLocalAnnouncement, "getAgencyLocalAnnouncement");

    if (agencyLocalAnnouncement) {
      dispatch(setAnnouncement({ agencyLocalAnnouncement: agencyLocalAnnouncement }));
    }
  }, [dataAgencyLocalAnnouncement, dispatch]);

  // handle agency local program announcement
  useEffect(() => {
    const agencyLocalProgramAnnouncement = get(dataAgencyLocalProgramAnnouncement, "getAgencyLocalProgramAnnouncement");

    if (agencyLocalProgramAnnouncement) {
      dispatch(setAnnouncement({ agencyLocalProgramAnnouncement: agencyLocalProgramAnnouncement }));
    }
  }, [dataAgencyLocalProgramAnnouncement, dispatch]);

  // handle supplier announcement
  useEffect(() => {
    const supplierAnnouncement = get(dataSupplierAnnouncement, "getSupplierAnnouncement");

    if (supplierAnnouncement) {
      dispatch(setAnnouncement({ supplierAnnouncement: supplierAnnouncement }));
    }
  }, [dataSupplierAnnouncement, dispatch]);

  // handle phrase announcement
  useEffect(() => {
    const phraseAnnouncement = get(dataPhraseAnnouncement, "getPhraseAnnouncement");

    if (phraseAnnouncement) {
      dispatch(setAnnouncement({ phraseAnnouncement: phraseAnnouncement }));
    }
  }, [dataPhraseAnnouncement, dispatch]);

  /**
   * handle copy announcement in rtf for pasting in emails
   */
  const copyElementRtfToClipboard = async element => {
    // remove all edit buttons before copying to rtf
    await dispatch(setAnnouncementCopyState(true));

    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(typeof element === "string" ? document.getElementById(element) : element);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    dispatch(setAnnouncementCopyState(false));
    toastr.success("Najava kopirana za slanje mailom");
  };

  /**
   * handle on create voucher click
   */
  const onCreateVoucherClick = async () => {
    dispatch(setIsVoucherCreateDialogOpen(true));
  };

  return {
    announcementSelected,
    copyElementRtfToClipboard,
    loading:
      loadingHotelGuideAnnouncement ||
      loadingHotelNoGuideAnnouncement ||
      loadingGuideAnnouncement ||
      loadingGuideTourAnnouncement ||
      loadingGuideLocalAnnouncement ||
      loadingAgencyAnnouncement ||
      loadingAgencyTourAnnouncement ||
      loadingAgencyLocalAnnouncement ||
      loadingAgencyLocalProgramAnnouncement ||
      loadingSupplierAnnouncement ||
      loadingPhraseAnnouncement,
    onCreateVoucherClick,
  };
}
