import React, { Fragment } from "react";

// libs

import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import HotelDetails from "../HotelDetails";
import useHotelGuideAnnouncement from "./hooks/useHotelGuideAnnouncement";
import RoomingDetails from "../RoomingDetails";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * HotelGuideAnnouncement
 */
const HotelGuideAnnouncement = ({ classes }) => {
  // hooks
  const {
    handleOnUpdateHotelDetails,
    handleOnUpdateHotelGuideAnnouncement,
    hotelGuideAnnouncement,
  } = useHotelGuideAnnouncement();

  const title = get(hotelGuideAnnouncement, "announcement_title.value", "");
  const intro = get(hotelGuideAnnouncement, "intro_message.placeholder", "");
  const footer = get(hotelGuideAnnouncement, "footer_message.placeholder", "");

  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* group */}
      <AnnouncementTextField announcementField={get(hotelGuideAnnouncement, "group")} keyOfValue={"group"} />

      {/* termin */}
      <AnnouncementTextField announcementField={get(hotelGuideAnnouncement, "termin")} keyOfValue={"termin"} />

      {/* hotel table */}
      <HotelDetails
        label={`${get(hotelGuideAnnouncement, "hotel_label.value", "")} : `}
        hotel={get(hotelGuideAnnouncement, "hotel", "")}
        handleOnUpdateHotelDetails={handleOnUpdateHotelDetails}
      />

      {/* guide */}
      <AnnouncementTextField announcementField={get(hotelGuideAnnouncement, "guide")} keyOfValue={"guide"} />

      {/* voucher accommodation */}
      <AnnouncementTextField
        announcementField={get(hotelGuideAnnouncement, "voucher_accommodation")}
        keyOfValue={"voucher_accommodation"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateHotelGuideAnnouncement}
      />

      {/* guide accommodation */}
      <AnnouncementTextField
        announcementField={get(hotelGuideAnnouncement, "guide_accommodation")}
        keyOfValue={"guide_accommodation"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateHotelGuideAnnouncement}
      />

      {/* dinner drink */}
      <AnnouncementTextField
        announcementField={get(hotelGuideAnnouncement, "dinner_drink")}
        keyOfValue={"dinner_drink"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateHotelGuideAnnouncement}
      />

      {/* parking */}
      <AnnouncementTextField
        announcementField={get(hotelGuideAnnouncement, "bus_parking")}
        keyOfValue={"bus_parking"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateHotelGuideAnnouncement}
      />

      {/* important notice */}
      <AnnouncementTextField
        announcementField={get(hotelGuideAnnouncement, "important_notice")}
        keyOfValue={"important_notice"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateHotelGuideAnnouncement}
      />

      {/* rooming list */}
      <RoomingDetails
        label={`${get(hotelGuideAnnouncement, "rooming_label.value")} :`}
        roomingList={get(hotelGuideAnnouncement, "rooming", "")}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(HotelGuideAnnouncement);
