import React, { Component } from "react";
import TextArea from "../../../../components/UI/CommentTextArea";
import { autosize } from "../../../../utility/globals";

class TemplateFreePlace extends Component {
  componentDidMount = () => {
    this.autoResize();
  };

  componentDidUpdate() {
    this.autoResize();
  }

  autoResize() {
    if (document.getElementsByName(`free_place_content`).length) {
      autosize(document.getElementsByName(`free_place_content`)[0]);
    }
  }
  render() {
    const { isNotDraft } = this.props;

    return (
      <div className="form-unit-double">
        <div className="field-label ">
          <label className="label">Slobodno mjesto</label>
        </div>
        <div>
          <TextArea
            inputProps={{
              name: "free_place_content",
              disabled: isNotDraft,
            }}
            defaultValue={this.props.free_place_content}
            customOnChange={this.props.changeContent}
            rows="3"
          />
        </div>
      </div>
    );
  }
}

export default TemplateFreePlace;
