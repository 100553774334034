import initialState from "./initialState";
import * as types from "../../actionTypes";

const copiedRoles = state => {
  const roles_copy = [...state.role_ids];
  return roles_copy;
};

const checkIfRoleIsAdded = (state, role_id) => state.role_ids.find(role => role_id);

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_USER_DATA:
      return {
        ...state,
        [action.param.key]: [action.param.value],
      };

    case types.ADD_ROLE_TO_USER:
      // If role is already added return
      if (checkIfRoleIsAdded(state, action.role_id)) {
        return state;
      }
      return {
        ...state,
        role_ids: copiedRoles(state).push(action.role_id),
      };

    case types.REMOVE_ROLE_FROM_USER:
      // If role is not added return
      if (!checkIfRoleIsAdded(state, action.role_id)) {
        return state;
      }
      return {
        ...state,
        role_ids: copiedRoles(state).splice(action.role_index, 1),
      };

    case types.FETCHED_USER_TO_EDIT:
      return {
        ...state,
        ...action.obj,
      };

    case types.RESET_USER_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
