// libs
import { createSlice } from "@reduxjs/toolkit";

export const announcementOrdersSlice = createSlice({
  name: "announcementOrder",
  initialState: {
    announcementOrdersPageSelected: 0,
    announcementOrdersPageSizeSelected: 10,
    announcementOrdersFilters: [],
  },
  reducers: {
    setAnnouncementOrders: (state, action) => {
      state.announcementOrders = action.payload;
    },

    // announcement orders page selected
    setAnnouncementOrdersPageSelected: (state, action) => {
      state.announcementOrdersPageSelected = action.payload;
    },

    // announcement orders table page size selected
    setAnnouncementOrdersPageSizeSelected: (state, action) => {
      state.announcementOrdersPageSizeSelected = action.payload;
    },

    // announcement orders table filters selected
    setAnnouncementOrdersFilters: (state, action) => {
      state.announcementOrdersFilters = action.payload;
    },

    setAnnouncementOrderUpdateLoading: (state, action) => {
      state.announcementOrderUpdateLoading = action.payload;
    },

    setAnnouncementOrder: (state, action) => {
      state.announcementOrder = action.payload;
    },

    // announcement order input field
    setAnnouncementOrderEditInputs: (state, action) => {
      state.announcementOrderEditInputs = {
        [action.payload.editInputKey]: action.payload.isEdit,
      };
    },

    // announcement order copy state
    setAnnouncementOrderCopyState: (state, action) => {
      state.announcementOrderCopyState = action.payload;
    },
  },
});

export const {
  setAnnouncementOrders,
  setAnnouncementOrdersPageSelected,
  setAnnouncementOrdersPageSizeSelected,
  setAnnouncementOrdersFilters,
  setAnnouncementOrderUpdateLoading,
  setAnnouncementOrder,
  setAnnouncementOrderEditInputs,
  setAnnouncementOrderCopyState,
} = announcementOrdersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.reducer_name.value)`

// announcement orders
export const selectAnnouncementOrders = state => state.announcementOrder.announcementOrders;

// announcement orders page selected
export const selectAnnouncementOrdersPageSelected = state => state.announcementOrder.announcementOrdersPageSelected;

// announcement orders page size selected
export const selectAnnouncementOrdersPageSizeSelected = state =>
  state.announcementOrder.announcementOrdersPageSizeSelected;

// announcement orders filters
export const selectAnnouncementOrdersFilters = state => state.announcementOrder.announcementOrdersFilters;

// announcement order update loading
export const selectAnnouncementOrderUpdateLoading = state => state.announcementOrder.announcementOrderUpdateLoading;

// announcement order
export const selectAnnouncementOrder = state => state.announcementOrder.announcementOrder;

// announcement order edit inputs
export const selectAnnouncementOrderEditInputs = state => state.announcementOrder.announcementOrderEditInputs;

// announcement order copy state
export const selectAnnouncementOrderCopyState = state => state.announcementOrder.announcementOrderCopyState;

export default announcementOrdersSlice.reducer;
