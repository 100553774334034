// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import { useSelector } from "react-redux";

// redux state
import { selectSupplier, selectProgramId } from "src/views/Guides/guidesSlice";

// graphql
import { CREATE_GUIDE_TOUR_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useCreateGuideTourAnnouncement is used for CreateGuideTourAnnouncement mutation
 */
export default function useCreateGuideTourAnnouncement() {
  // redux state
  const supplier = useSelector(selectSupplier);
  const programId = useSelector(selectProgramId);

  // mutations
  const [createGuideTourAnnouncement] = useMutation(CREATE_GUIDE_TOUR_ANNOUNCEMENT);

  const handleCreateGuideTourAnnouncement = async languageSelected => {
    const createAnnouncementResponse = await createGuideTourAnnouncement({
      variables: {
        input: {
          program_id: programId,
          guide_id: supplier.guide_id,
          language_id: languageSelected,
        },
      },
    });

    return get(createAnnouncementResponse, "data.createGuideTourAnnouncement");
  };
  return {
    handleCreateGuideTourAnnouncement,
  };
}
