import React, { Component } from "react";
import { formatDate, dateDiff } from "../../../../utility/dates";

import initState from "./initState";
import { withApollo } from "react-apollo";

import { getTypology } from "../../../../graphql/query/administration";
import { decimalNumbersTwoDigits, round, guidIdGenerator } from "../../../../utility/numbers";
import { removeDuplicateInts } from "../../../../utility/globals";

const CalculatorContext = React.createContext();

class Calculator extends Component {
  static Consumer = CalculatorContext.Consumer;

  state = { ...initState };

  componentDidMount() {
    const { termin, calculator } = this.props;

    if (termin && calculator) {
      this.setState({
        calculator,
      });
      return;
    }

    // If it has subtermins
    if (termin.subtermin_periods && termin.subtermin_periods.length) {
      this.initSubtermins();
    } else {
      this.initTermins();
    }
  }

  /**
   * Methods for CREATING CALCULATOR ********************************************
   */
  async initSubtermins() {
    const {
      termin,
      termin: { subtermin_periods },
    } = this.props;

    let establishments = [];

    for (let s = 0; s < subtermin_periods.length; s++) {
      const subtermin = subtermin_periods[s];
      const { contigents } = subtermin;

      let subtermin_establishments = [];
      for (let i = 0; i < contigents.length; i++) {
        const typology = contigents[i].typology || (await this.fetchTypology(contigents[i].typology_id));

        const establishment = typology.establishment;

        if (establishment && typology) {
          const alreadyExists = subtermin_establishments.find(({ id }) => id === establishment.id);
          // if establishment is already inside array
          if (alreadyExists) {
            subtermin_establishments = this.addTypologyToEstablishment(
              subtermin_establishments,
              establishment,
              typology,
            );
          } else {
            // dodaj novi initstate.establishment
            subtermin_establishments.push(
              this.addNewEstablishment(establishment, typology, dateDiff(subtermin.from, subtermin.to, "days")),
            );
          }
        }
      }

      establishments.push(...subtermin_establishments);
      subtermin_establishments = [];
    }

    this.createNewCalendar(termin, establishments);
  }

  async initTermins() {
    const { termin } = this.props;

    const { contigents } = termin;

    // fetching all establishments

    let establishments = [];

    for (let i = 0; i < contigents.length; i++) {
      const typology = contigents[i].typology || (await this.fetchTypology(contigents[i].typology_id));

      const establishment = typology.establishment;

      if (establishment && typology) {
        const alreadyExists = establishments.find(({ id }) => id === establishment.id);
        // if establishment is already inside array
        if (alreadyExists) {
          establishments = this.addTypologyToEstablishment(establishments, establishment, typology);
        } else {
          // dodaj novi initstate.establishment
          establishments.push(
            this.addNewEstablishment(establishment, typology, dateDiff(termin.from, termin.to, "days")),
          );
        }
      }
    }
    this.createNewCalendar(termin, establishments);
  }

  createNewCalendar(termin, establishments) {
    this.setState(
      prevState => ({
        ...prevState,
        calculator: {
          id: guidIdGenerator(),
          ...prevState.calculator,
          calculator_header: {
            from: formatDate(termin.from),
            to: formatDate(termin.to),
            date_diff: dateDiff(termin.from, termin.to, "days"),
            group_name: this.props.document_code,
            grp_quantity: 20,
            grp_min_quantity: 18,
            ruc: 5,
          },
          establishments,
        },
      }),
      () => this.recalculateTotals(this.state),
    );
  }

  addTypologyToEstablishment(establishments, establishment, typology) {
    return establishments.map(est => {
      if (est.id === establishment.id) {
        const found = est.rooms_calculations.find(({ id }) => id === typology.id);
        if (!found) {
          est.rooms_calculations.push({
            ...initState.establishment_calculation,
            id: typology.id,
            capacity: typology.persons_capacity,
            typology_group_id: typology.typology_group_id,
            typology_group: typology.typology_group,
            room_name: `${typology.typology_group && typology.typology_group.name} [${typology.persons_capacity}]`,
          });
        }
      }
      return est;
    });
  }

  addNewEstablishment(establishment, typology, days) {
    return {
      ...initState.establishment,
      id: establishment.id,
      establishment_info: {
        ...initState.establishment.establishment_info,
        service: establishment.name,
        quantity: days,
      },
      rooms_calculations: [
        {
          ...initState.establishment_calculation,
          id: typology.id,
          capacity: typology.persons_capacity,
          typology_group: typology.typology_group,
          typology_group_id: typology.typology_group_id,
          room_name: `${typology.typology_translations[0].name} [${typology.persons_capacity}]`,
        },
      ],
    };
  }

  async fetchTypology(id) {
    // fetching all establishments
    const response = await this.props.client.query({
      query: getTypology,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });

    return Promise.resolve(response.data.getTypology);
  }

  changeCalculatorHeader = callback => key => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, key, value);
      },
      () => this.recalculateEstablishment(this.state),
    );
  };

  getCalculatorHeader = ({ changeCalculatorHeaderField }) => ({
    changeCalculatorHeader: this.changeCalculatorHeader(changeCalculatorHeaderField),
  });

  /**
   * END
   * Methods for CREATING CALCULATOR ********************************************
   */

  /** ********************************************
   *  Using getter props pattern and updating state through them
   *  Setting callback funcions through getAbsolutePriceService
   *  Component: CalculatorServicesInAbsolutePrice
   */

  addAbsolutePriceService = callback => () => {
    if (typeof callback !== "function") {
      return;
    }
    this.setState(prevState => {
      return callback(prevState);
    });
  };

  changeAbsolutePriceService = callback => ({ target: { value } }) => name => abs_price_id => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(prevState => {
      return callback(prevState, value, name, abs_price_id);
    });
  };

  changeAbsolutePriceServiceNums = callback => ({ target: { value } }) => name => abs_price_id => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, decimalNumbersTwoDigits(value), name, abs_price_id);
      },
      () => this.recalculateTotals(this.state),
    );
  };

  getAbsolutePriceService = ({
    onAdding,
    changeAbsoluteServiceField,
    changeAbsolutePriceServiceNumsField,
    ...props
  }) => {
    return {
      onAdding: this.addAbsolutePriceService(onAdding),
      changeAbsoluteServiceField: this.changeAbsolutePriceService(changeAbsoluteServiceField),
      changeAbsoluteServiceNumsField: this.changeAbsolutePriceServiceNums(changeAbsolutePriceServiceNumsField),
      ...props,
    };
  };
  /**
   * ****************************
   */

  /** ********************************************
   *  Using getter props pattern and updating state through them
   *  Setting callback funcions through getAbsolutePriceService
   *  Component: CalculatorServicesPerPerson
   */

  addServicePerPerson = callback => () => {
    if (typeof callback !== "function") {
      return;
    }
    this.setState(prevState => {
      return callback(prevState);
    });
  };

  changeServicePerPerson = callback => ({ target: { value } }) => name => abs_price_id => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, value, name, abs_price_id);
      },
      () => this.recalculateTotals(this.state),
    );
  };

  changeServicePerPersonNum = callback => ({ target: { value } }) => name => abs_price_id => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, decimalNumbersTwoDigits(value), name, abs_price_id);
      },
      () => this.recalculateTotals(this.state),
    );
  };

  getServicePerPerson = ({ onAdding, changeServicePerPerson, changeServicePerPersonNumField, ...props }) => {
    return {
      onAdding: this.addServicePerPerson(onAdding),
      changeServicePerPerson: this.changeServicePerPerson(changeServicePerPerson),
      changeServicePerPersonNum: this.changeServicePerPersonNum(changeServicePerPersonNumField),
      ...props,
    };
  };

  /**
   * *******************************
   */

  /**
   * ESTABLISHMENT
   */

  recalculateTotals = state => {
    this.setState(
      () => {
        const total_calculation = state.calculator.establishments.map(est => {
          // Calculating total calculation
          return {
            ...initState.total_calc,
            id: est.id,
            establishment_info: est.establishment_info.service,
            typologies: est.rooms_calculations.map(typology => {
              return {
                ...initState.total_typology,
                id: typology.id,
                capacity: typology.capacity,
                typology_group_id: typology.typology_group_id,
                typology_group: typology.typology_group,
                room_name: typology.room_name,
                total: round(typology.total),
              };
            }),
          };
        });

        return {
          ...state,
          calculator: {
            ...state.calculator,
            total_calculation,
          },
        };
      },
      () => this.recalculateTotalByCapacity(this.state),
    );
  };

  recalculateTotalByCapacity = state => {
    const { total_calculation } = state.calculator;

    let allTypologiesGroupIds = [];

    total_calculation.forEach(hotel => {
      hotel.typologies.forEach(typology => allTypologiesGroupIds.push(typology.typology_group_id));
    });

    allTypologiesGroupIds = removeDuplicateInts(allTypologiesGroupIds);

    const totals = {};
    const services_in_absolute_price = state.calculator.services_in_absolute_price.reduce((total, current) => {
      return total + current.exit;
    }, 0);
    const services_per_person = state.calculator.services_per_person.reduce((total, current) => {
      return total + current.exit;
    }, 0);

    allTypologiesGroupIds.forEach(typology_group_id => {
      total_calculation.forEach(hotel => {
        hotel.typologies.forEach(typology => {
          if (typology_group_id === typology.typology_group_id) {
            if (totals[`total_price_for_${typology_group_id}`]) {
              totals[`total_price_for_${typology_group_id}`] += typology.total;
            } else {
              totals[`total_price_for_${typology_group_id}`] = typology.total;
            }
          }
        });
      });
    });

    const new_totals = Object.keys(totals).reduce((obj, key) => {
      return {
        ...obj,
        [key]: totals[key] + round(services_in_absolute_price) + round(services_per_person),
      };
    }, {});
    this.setState({ ...state, calculator: { ...state.calculator, totals: new_totals } });
  };

  recalculateEstablishment = state => {
    const { establishments, calculator_header } = state.calculator;

    const establishments_clone = establishments.map(est => {
      return {
        ...est,
        rooms_calculations: est.rooms_calculations.map(typology => {
          return {
            ...this.typologyRecalculate(
              typology,
              est.establishment_info.quantity,
              calculator_header.ruc,
              calculator_header.grp_min_quantity,
            ),
          };
        }),
      };
    });

    this.setState(
      {
        ...state,
        calculator: {
          ...state.calculator,
          establishments: establishments_clone,
        },
      },
      () => this.recalculateTotals(this.state),
    );
  };

  typologyRecalculate = (typology, quantity, header_ruc, grp_min_quantity) => {
    const gratis = this.gratisRecalculate(typology.gratis, typology.import, grp_min_quantity);

    const izn_discount = round((round(typology.import) / 100) * round(typology.discount));
    const izn_commision = round((round(typology.import) - round(izn_discount)) / 100) * typology.commision;

    const net = round(round(typology.import) - round(izn_discount) - round(izn_commision));

    const pre_exit = net > 0 ? round(round(net) + round(header_ruc) + round(gratis.gratis_exit)) : 0;

    const exit = pre_exit;

    const ruc = round(round(exit) - round(net));

    const total = round(round(quantity) * round(exit));

    return {
      ...typology,
      izn_discount,
      izn_commision,
      net,
      pre_exit,
      exit,
      ruc,
      total,
      gratis,
    };
  };

  gratisRecalculate = (gratis, import_price, grp_min_quantity) => {
    const gratis_price = gratis.gratis ? round(round(import_price) / round(grp_min_quantity)) : 0;
    const gratis_exit = round(round(gratis_price) * round(gratis.gratis_quantity));

    return {
      ...gratis,
      gratis_price,
      gratis_exit,
    };
  };

  addEstablishment = callback => establishment_calculation_id => () => {
    if (typeof callback !== "function") {
      return;
    }
    this.setState(
      prevState => {
        return callback(prevState, establishment_calculation_id);
      },
      () => {
        this.recalculateTotals(this.state);
      },
    );
  };

  changeEstablishmentInfo = callback => (establishment_id, index, key) => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }
    this.setState(prevState => {
      return callback(prevState, establishment_id, index, key, value);
    });
  };

  changeEstablishmentInfoQuantity = callback => (establishment_id, index, key) => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }
    this.setState(
      prevState => {
        return callback(prevState, establishment_id, index, key, value.replace(/\D/g, ""));
      },
      () => this.recalculateEstablishment(this.state),
    );
  };

  changeTypologyData = callback => (key, index, establishment_id, typology_id) => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, key, index, establishment_id, typology_id, decimalNumbersTwoDigits(value));
      },
      () => this.recalculateEstablishment(this.state),
    );
  };

  changeTypologyGratisData = callback => (key, index, establishment_id, typology_id) => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, key, index, establishment_id, typology_id, value);
      },
      () => this.recalculateEstablishment(this.state),
    );
  };

  changeTypologyGratisNumsData = callback => (key, index, establishment_id, typology_id) => ({ target: { value } }) => {
    if (typeof callback !== "function") {
      return;
    }

    this.setState(
      prevState => {
        return callback(prevState, key, index, establishment_id, typology_id, decimalNumbersTwoDigits(value));
      },
      () => this.recalculateEstablishment(this.state),
    );
  };

  getEstablishmentMethods = ({
    onAdding,
    changeEstablishmentInfoField,
    changeEstablishmentInfoQuantityField,
    changeTypologyField,
    changeTypologyGratisField,
    changeTypologyGratisNumsField,
  }) => {
    return {
      onAdding: this.addEstablishment(onAdding),
      changeEstablishmentInfo: this.changeEstablishmentInfo(changeEstablishmentInfoField),
      changeEstablishmentInfoQuantity: this.changeEstablishmentInfoQuantity(changeEstablishmentInfoQuantityField),
      changeTypologyData: this.changeTypologyData(changeTypologyField),
      changeTypologyGratisData: this.changeTypologyGratisData(changeTypologyGratisField),
      changeTypologyGratisNumsData: this.changeTypologyGratisNumsData(changeTypologyGratisNumsField),
    };
  };

  /**
   * END ESTABLISHMENT  ********************************************
   */

  getCalculatorData() {
    return {
      calculator: this.state.calculator,
      vat: this.state.vat,
      addAbsolutePriceService: this.addAbsolutePriceService,
      changeAbsolutePriceService: this.changeAbsolutePriceService,
      addServicePerPerson: this.addServicePerPerson,
      changeServicePerPerson: this.changeServicePerPerson,
      getCalculatorHeader: this.getCalculatorHeader,
      getAbsolutePriceService: this.getAbsolutePriceService,
      getServicePerPerson: this.getServicePerPerson,
      getEstablishmentMethods: this.getEstablishmentMethods,
    };
  }

  render() {
    const { children } = this.props;

    const ui =
      typeof children === "function"
        ? children(this.props)
        : React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
              ...this.props,
              calculator: this.state.calculator,
            }),
          );

    return <CalculatorContext.Provider value={{ ...this.getCalculatorData() }}>{ui}</CalculatorContext.Provider>;
  }
}

export default withApollo(Calculator);
