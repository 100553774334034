import React, { Component } from "react";
import { connect } from "react-redux";

class PreviewServicesPackage extends Component {
  render() {
    const { service_package, int } = this.props;

    if (!service_package) {
      return null;
    }

    return (
      <div className="preview-content">
        <div className="preview-content-title ">
          <h4>{int.service_package}</h4>
        </div>

        <div disabled id="preview-service-package" className="preview-content-div preview-textarea">
          {service_package}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: { service_package },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    service_package,
    int,
  };
};

export default connect(mapStateToProps)(PreviewServicesPackage);
