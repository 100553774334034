import React from "react";
import { get } from "lodash";

/**
 * ColumnHotels is used for showing list of announcement hotels
 */
export default function ColumnHotels({ hotelList }) {
  // return root
  return (
    <div>
      {hotelList &&
        hotelList.map(hotel => {
          return (
            <div key={hotel.establishment_id} style={{ textAlign: "center", width: "100%" }}>
              {get(hotel, "hotel_name.value", "")}
            </div>
          );
        })}
    </div>
  );
}
