import React, { Component } from "react";
import { connect } from "react-redux";

class PreviewAlotmanTemplates extends Component {
  render() {
    const {
      children_discounts,
      included_in_the_price,
      comment,
      first_booking_station,
      rooms_are_bookable,
      definitive_room_list,
      pay,
      payment_rules,
      cancellation_costs,
      int,
    } = this.props;

    return (
      <div>
        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.children_discounts}</h4>
          </div>

          <div disabled id="children_discounts" className="preview-content-div preview-textarea">
            {children_discounts.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.included_in_the_price}</h4>
          </div>
          <div disabled id="included_in_the_price" className="preview-content-div preview-textarea">
            {included_in_the_price.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.comment}</h4>
          </div>
          <div disabled id="comment" className="preview-content-div preview-textarea">
            {comment && comment.trim()}
          </div>
        </div>

        <div className="preview-content-title ">
          <h4>{int.other_conditions}</h4>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.first_booking_station}</h4>
          </div>
          <div disabled id="first_booking_station" className="preview-content-div preview-textarea">
            {first_booking_station && first_booking_station.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.rooms_are_bookable}</h4>
          </div>
          <div disabled id="rooms_are_bookable" className="preview-content-div preview-textarea">
            {rooms_are_bookable && rooms_are_bookable.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.definitive_room_list}</h4>
          </div>
          <div disabled id="definitive_room_list" className="preview-content-div preview-textarea">
            {definitive_room_list && definitive_room_list.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.pay}</h4>
          </div>
          <div disabled id="pay" className="preview-content-div preview-textarea">
            {pay && pay.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.payment_rules}</h4>
          </div>
          <div disabled id="payment_rules" className="preview-content-div preview-textarea">
            {payment_rules && payment_rules.trim()}
          </div>
        </div>

        <div className="preview-content">
          <div className="preview-content-title ">
            <h4>{int.cancellation_costs}</h4>
          </div>
          <div disabled id="cancellation_costs" className="preview-content-div preview-textarea">
            {cancellation_costs && cancellation_costs.trim()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    preview: {
      offer_contract_document: {
        children_discounts,
        included_in_the_price,
        comment,
        first_booking_station,
        rooms_are_bookable,
        definitive_room_list,
        pay,
        payment_rules,
        cancellation_costs,
      },
    },
    templateInt,
  } = state.preview;

  const int = templateInt.getLanguageDescriptions();

  return {
    children_discounts,
    included_in_the_price,
    comment,
    first_booking_station,
    rooms_are_bookable,
    definitive_room_list,
    pay,
    payment_rules,
    cancellation_costs,
    int,
  };
};

export default connect(mapStateToProps)(PreviewAlotmanTemplates);
