import React from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import usePhraseAnnouncement from "./hooks/usePhraseAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";

const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * PhraseAnnouncement
 */
const PhraseAnnouncement = ({ classes }) => {
  // hooks
  const { handleOnUpdatePhraseAnnouncement, phraseAnnouncement } = usePhraseAnnouncement();

  const title = get(phraseAnnouncement, "announcement_title.value", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* phrase title */}
      <AnnouncementTextField
        announcementField={get(phraseAnnouncement, "phrase_title")}
        keyOfValue={"phrase_title"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdatePhraseAnnouncement}
      />

      {/* phrase */}
      <AnnouncementTextField
        announcementField={get(phraseAnnouncement, "phrase_text")}
        keyOfValue={"phrase_text"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdatePhraseAnnouncement}
      />
    </div>
  );
};

export default withStyles(styles)(PhraseAnnouncement);
