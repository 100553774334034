import React from "react";
import { withStyles } from "@material-ui/core/styles";
import BorderColor from "@material-ui/icons/BorderColor";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    fontSize: 16,
    cursor: "pointer"
  }
});

function EditIcon(props) {
  const { classes, onClick } = props;
  return (
    <div className={classes.root}>
      <BorderColor className={classes.icon} onClick={onClick} />
    </div>
  );
}

export default withStyles(styles)(EditIcon);
