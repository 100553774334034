// libs
import { useSelector } from "react-redux";

// redux state
import { selectAnnouncementOrderUpdateLoading } from "src/views/AnnouncementOrders/announcementOrdersSlice";

/**
 * useToolbarView is entry point of data for toolbar view component
 */
export default function useToolbarView() {
  // redux state
  const announcementOrderUpdateLoading = useSelector(selectAnnouncementOrderUpdateLoading);

  return {
    loading: announcementOrderUpdateLoading,
  };
}
