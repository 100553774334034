import React from "react";

// ui
import { TextField, withStyles } from "@material-ui/core";

import purple from "@material-ui/core/colors/purple";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    "&$cssFocused": {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: purple[500],
    },
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

/**
 * TextFieldMultilineOutline bootstrap outline style
 */
const TextFieldMultilineOutline = ({
  classes,
  id,
  label,
  placeholder,
  helperText,
  rows,
  rowsMax,
  fullWidth,
  value,
  onChange,
}) => {
  // return root
  return (
    <div>
      <TextField
        id={id || "mutlilineBootstrapText"}
        className="textField"
        label={label}
        placeholder={placeholder || ""}
        helperText={helperText}
        multiline
        rows={rows || "5"}
        rowsMax={rowsMax || "5"}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        margin="normal"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          },
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.bootstrapFormLabel,
        }}
      />
    </div>
  );
};

export default withStyles(styles)(TextFieldMultilineOutline);
