import React from "react";
import Drawer from "material-ui/Drawer";
import Subheader from "material-ui/Subheader";
import IconButton from "material-ui/IconButton";
import MenuIcon from "material-ui/svg-icons/navigation/menu";

import SidebarList from "./SidebarList";

const Sidebar = props => {
  return (
    <Drawer
      docked={true}
      containerClassName={
        !props.minimizeDrawer
          ? "sidebar"
          : ["sidebar", " ", "sidebar-minimized"].join("")
      }
    >
      <Subheader className="sidebar-top">
        {!props.minimizeDrawer ? <span>OLIVARI d.o.o.</span> : null}
        <IconButton
          onClick={props.minimizeDrawerHandler}
          style={{ verticalAlign: "top" }}
        >
          <MenuIcon className="sidebar-top-icon" />
        </IconButton>
      </Subheader>

      <SidebarList {...props} clicked={props.changeActiveLink} />
    </Drawer>
  );
};

export default Sidebar;
