import React from "react";

// ui
import Paper from "material-ui/Paper";
// components
import ReactTableCustom from "src/components/UI/ReactTable";
// hooks
import useGuides from "./hooks";

const GuidesListView = React.forwardRef((props, ref) => {
  const {
    loadingFetchGudies,
    listGuides,
    guidesColumnsShow,
    getGuideTrProps,
    getGuideTdProps,
    guideSchedulesPageSelected,
    guideSchedulesPageSizeSelected,
    guideSchedulesFilters,
    onFilteredChange,
    onGuideTablePageChange,
    onGuideTablePageSizeChange,
    pages,
    canPrevious,
    canNext,
    pageState,
    setPageState,
    getSafePage,
    applyPage,
    onPageJumpEnterPress,
    changePage,
  } = useGuides();

  // return root
  return (
    <div>
      <div className="area-to-print" id="area-to-print" />
      <Paper zDepth={1}>
        <ReactTableCustom
          data={listGuides || []}
          columns={guidesColumnsShow}
          page={guideSchedulesPageSelected}
          innerProps={{
            loading: loadingFetchGudies,
            pages: pages,
            manual: true,
            sortable: false,
            getTrProps: getGuideTrProps,
            getTdProps: getGuideTdProps,
            onFilteredChange: onFilteredChange,
            defaultFiltered: guideSchedulesFilters,
            ref: el => (ref.current = el),
            pageSize: guideSchedulesPageSizeSelected,
            onPageChange: page => {
              onGuideTablePageChange(page);
            },
            onPageSizeChange: (pageSize, pageIndex) => {
              onGuideTablePageSizeChange(pageSize);
            },
            renderPageJump: ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => {
              return (
                <div className="-pageJump">
                  <input
                    aria-label={pageJumpText}
                    type={inputType}
                    onChange={e => {
                      const val = e.target.value;
                      const page = val - 1;
                      if (val === "") {
                        return setPageState(val);
                      }
                      return setPageState(getSafePage(page));
                    }}
                    value={pageState === "" ? "" : pageState + 1}
                    onBlur={applyPage}
                    onKeyPress={onPageJumpEnterPress}
                  />
                </div>
              );
            },
            NextComponent: props => {
              return (
                <button
                  disabled={!canNext}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState + 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
            PreviousComponent: props => {
              return (
                <button
                  disabled={!canPrevious}
                  type="button"
                  className="-btn"
                  onClick={() => {
                    changePage(pageState - 1);
                  }}
                >
                  {props.children}
                </button>
              );
            },
          }}
        />
      </Paper>
    </div>
  );
});

export default GuidesListView;
