import React, { Fragment } from "react";

// libs
import { get } from "lodash";

// ui
import { Typography, withStyles } from "@material-ui/core";

// hooks
import useGuideTourAnnouncement from "./hooks/useGuideTourAnnouncement";

// components
import AnnouncementTextField from "../AnnouncementTextField";
import ProgramDetails from "../ProgramDetails";
import HotelDetailsList from "../HotelDetailsList";

// style
const styles = () => ({
  root: {
    marginTop: 16,
  },

  title: {
    marginTop: 32,
    fontWeight: "bold",
  },

  intro: {
    marginTop: 32,
    marginBottom: 32,
  },

  footer: {
    marginTop: 32,
    marginBottom: 32,
  },
});

/**
 * GuideTourAnnouncement
 */
const GuideTourAnnouncement = ({ classes }) => {
  // hooks
  const {
    guideTourAnnouncement,
    handleOnUpdateGuideTourAnnouncement,
    handleOnUpdateHotelDetails,
  } = useGuideTourAnnouncement();

  const title = get(guideTourAnnouncement, "announcement_title.value", "");
  const intro = get(guideTourAnnouncement, "intro_message.placeholder", "");
  const footer = get(guideTourAnnouncement, "footer_message.placeholder", "");

  // root
  return (
    <div className={classes.root}>
      {/* title */}
      <Typography className={classes.title} align="center" variant="subheading">
        {title}
      </Typography>

      {/* intro */}
      <Typography className={classes.intro} variant="body1">
        {intro.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>

      {/* group */}
      <AnnouncementTextField announcementField={get(guideTourAnnouncement, "group")} keyOfValue={"group"} />

      {/* termin */}
      <AnnouncementTextField announcementField={get(guideTourAnnouncement, "termin")} keyOfValue={"group"} />

      {/* hotel table list */}
      <HotelDetailsList
        label={`${get(guideTourAnnouncement, "hotel_label.value", "")} : `}
        hotelList={get(guideTourAnnouncement, "hotel", "")}
        handleOnUpdateHotelDetails={handleOnUpdateHotelDetails}
      />

      {/* bus driver contact */}
      <AnnouncementTextField
        announcementField={get(guideTourAnnouncement, "bus_driver_contact")}
        keyOfValue={"bus_driver_contact"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideTourAnnouncement}
      />

      {/* voucher accommodation */}
      <AnnouncementTextField
        announcementField={get(guideTourAnnouncement, "voucher_accommodation")}
        keyOfValue={"voucher_accommodation"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideTourAnnouncement}
      />

      {/* dinner drink */}
      <AnnouncementTextField
        announcementField={get(guideTourAnnouncement, "dinner_drink")}
        keyOfValue={"dinner_drink"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideTourAnnouncement}
      />

      {/* important notice */}
      <AnnouncementTextField
        announcementField={get(guideTourAnnouncement, "important_notice")}
        keyOfValue={"important_notice"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideTourAnnouncement}
      />

      {/* program */}
      <ProgramDetails
        label={`${get(guideTourAnnouncement, "program_label.value", "")} : `}
        programList={get(guideTourAnnouncement, "program", "")}
      />

      {/* invoice warrant */}
      <AnnouncementTextField
        announcementField={get(guideTourAnnouncement, "invoice_warrant")}
        keyOfValue={"invoice_warrant"}
        isEditable={true}
        handleOnUpdateAnnouncement={handleOnUpdateGuideTourAnnouncement}
      />

      {/* footer */}
      <Typography className={classes.footer} variant="body1">
        {footer.split("<br>").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(GuideTourAnnouncement);
