import React, { Fragment } from "react";

// lib
import { get } from "lodash";

// ui
import { Divider, Grid, IconButton, List, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import AutocompleteSelect from "src/components/UI/AutocompleteSelect";
// hook
import usePartnerActivities from "./usePartnerActivities";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },

  label: {
    marginTop: 24,
    marginBottom: 8,
  },

  bodyText: {
    marginTop: 8,
  },

  listItem: {
    paddingLeft: 0,
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

/**
 * partner activities
 */
const PartnerActivities = ({
  classes,
  partner,
  addPartnerActivity,
  deletePartnerActivity,
  updatePartnerActivityPrice,
}) => {
  const partnerActivities = get(partner, "partner_client_activities", []);

  const {
    getActivitiesForView,
    getActivitySelected,
    handleAutocompleteInputChangeDebounced,
    handleOnScrollToBottom,
    loading,
  } = usePartnerActivities();

  // return root
  return (
    <div>
      {/** activity list */}
      <br />
      <h3>Povezane aktivnosti</h3>
      <br />

      <AutocompleteSelect
        label="Aktivnost"
        placeholder="Odaberite aktivnost"
        dataSource={
          getActivitiesForView() &&
          getActivitiesForView().map(activity => ({
            value: activity.id,
            label: activity.name,
          }))
        }
        onMenuScrollToBottom={handleOnScrollToBottom}
        isLoading={loading}
        autocompleteHandler={activityId => addPartnerActivity(getActivitySelected(activityId))}
        inputProps={{
          onInputChange: handleAutocompleteInputChangeDebounced,
        }}
      />

      {partnerActivities ? (
        <List disablePadding>
          {partnerActivities.map(activity => {
            return (
              <Fragment key={activity.activity_id}>
                <Grid container spacing={8} alignItems="center" alignContent="center">
                  <Grid item xs={7}>
                    <Typography variant="subheading">{activity.name}</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id="price"
                      label="Cijena"
                      type="number"
                      fullWidth={true}
                      className={classes.textField}
                      value={activity.price}
                      onChange={event => updatePartnerActivityPrice(activity.activity_id, event.target.value)}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton aria-label="Delete">
                      <DeleteIcon onClick={() => deletePartnerActivity(activity)} />
                    </IconButton>
                  </Grid>
                </Grid>

                <Divider />
              </Fragment>
            );
          })}
        </List>
      ) : (
        <Typography className={classes.bodyText} variant="body1">
          Nema povezanih aktivnosti
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(PartnerActivities);
