import gql from "graphql-tag";

export const templateFragment = gql`
  fragment Template on OfferContractDocument {
    sojourn_tax_value
    sojourn_tax_content
    notification_price_validity
    notification_booking_state
    notification_room_list
    notification_storno
    notification_payment
    vat_content
    free_place_content
    price_validity_content
    surcharges_content
    payment_content
    storno_content
    program_changes_content
    content_content
    program_content
    room_list_content
    booking_state_content
  }
`;

export const alotmanTemplateFragment = gql`
  fragment AlotmanTemplate on OfferContractDocument {
    children_discounts
    included_in_the_price
    comment
    first_booking_station
    rooms_are_bookable
    definitive_room_list
    pay
    payment_rules
    cancellation_costs
    release_day
  }
`;

export const cityFragment = gql`
  fragment City on City {
    id
    name
    count
    postal_code
    area_code
    region_id
    region {
      id
      name
      country {
        id
        name
        iso_3166_code
      }
    }
  }
`;

export const typologyEstablishmentFragment = gql`
  fragment TypologyEstablishment on Typology {
    id
    code
    establishment_code
    persons_capacity
    priority
    establishment_id
    typology_group_id
    typology_group {
      id
      name
    }
    typology_translations {
      id
      desc
      name
      language_id
      typology_id
      language {
        id
        code
        desc
      }
    }
    establishment {
      id
      name
      web
      email
      address1
      address2
      phone
      fax
      rating
      contact_person1
      contact_person2
      breakfast_and_dinner_times
      breakfast_and_dinner_buffet_or_menu
      currency_exchange
      currency_exchange_comment
      parking
      parking_comment
      wellnes
      wellnes_comment
      pools
      pools_comment
      city_id
      establishment_legal_entity_id
      establishment_chain_id
      city {
        ...City
      }
    }
  }
  ${cityFragment}
`;

export const establishmentFragment = gql`
  fragment Establishment on Establishment {
    id
    name
    web
    email
    address1
    address2
    phone
    fax
    rating
    contact_person1
    contact_person2
    breakfast_and_dinner_times
    breakfast_and_dinner_buffet_or_menu
    currency_exchange
    currency_exchange_comment
    parking
    parking_comment
    wellnes
    wellnes_comment
    pools
    pools_comment
    city_id
    establishment_legal_entity_id
    establishment_chain_id
    city {
      ...City
    }
    typologies {
      ...TypologyEstablishment
    }
  }
  ${cityFragment}
  ${typologyEstablishmentFragment}
`;

export const typologyFragment = gql`
  fragment Typology on Typology {
    id
    code
    establishment_code
    persons_capacity
    priority
    establishment_id
    typology_group_id
    typology_group {
      id
      name
    }
    establishment {
      ...Establishment
    }
    typology_translations {
      id
      desc
      name
      language_id
      typology_id
      language {
        id
        code
        desc
      }
    }
  }
  ${establishmentFragment}
`;

export const typologyTranslationsFragment = gql`
  fragment TypologyTranslation on TypologyTranslation {
    id
    desc
    name
    language_id
    typology_id
    language {
      id
      code
      desc
    }
  }
`;

export const contigentFragment = gql`
  fragment Contigent on Contigent {
    id
    typologies_sum
    sold
    termin_period_id
    typology_id
    typology {
      ...Typology
    }
  }
  ${typologyFragment}
`;

export const bookingContigentFragment = gql`
  fragment BookingContigent on Contigent {
    id
    typologies_sum
    sold
    termin_period_id
    typology_id
    typology {
      id
      code
      persons_capacity
      priority
      establishment_id
      typology_group_id
      typology_group {
        id
        name
      }
      establishment {
        id
        name
        establishment_chain_id
        establishment_chain {
          id
          name
        }
        city {
          ...City
        }
        typologies {
          ...Typology
        }
      }
      typology_translations {
        id
        desc
        name
        language_id
        typology_id
        language {
          id
          code
          desc
        }
      }
    }
  }
  ${cityFragment}
  ${typologyFragment}
`;

export const fixContigentFragment = gql`
  fragment FixContigent on FixContigent {
    id
    typologies_sum
    sold
    termin_period_id
    typology_id
    typology {
      ...Typology
    }
  }
  ${typologyFragment}
`;

export const terminsFragment = gql`
  fragment Termin on TerminPeriod {
    id
    from
    to
    driver
    guide
    contacted
    driver_guide_comment
    receipt
    remark
    transport
    def
    notification_booking_state_date
    delivered_state
    termin_period_status_id
    termin_period_status {
      id
      desc
      code
    }
    booking_option_description_id
    booking_option_description {
      id
      code
      desc
    }
  }
`;

export const priceStructureFragment = gql`
  fragment PriceStructure on PriceStructure {
    id
    calculator
    price_typology_1
    price_typology_2
    price_typology_3
    price_typology_4
    price_typology_5
    price_typology_6
    price_typology_7
    price_typology_8
    price_typology_9
    price_typology_10
    price_typology
    tourist_tax
    calculator
    comment_price_structure
    price_structure_type_id
    termin_period_id
  }
`;

export const mainTerminFragment = gql`
  fragment MainTermin on TerminPeriod {
    ...Termin
    price_structure {
      ...PriceStructure
    }
    subtermin_periods {
      ...Termin
    }
  }
  ${terminsFragment}
  ${priceStructureFragment}
`;

export const dailyTerminPrograms = gql`
  fragment DailyProgram on Program {
    id
    program_days {
      id
      title
      description
      program_id
      program_day_contents {
        id
        program_day_id
        partner_client_service_id
        activity_id
      }
    }
    termin_period_id
    offer_contract_document_id
  }
`;

export const partnerClientFragment = gql`
  fragment Partner on PartnerClient {
    id
    name
    first_name
    last_name
    phones
    fax
    address
    emails
    url
    comments
    oib
    count
    partner_client_types {
      id
      desc
    }
    services {
      id
      price
      partner_client_service_type_id
      partner_client_service_translations {
        id
        name
        description
        language_id
      }
    }
    language_id
    language {
      id
      desc
    }
    languages
    city_id
    city {
      ...City
    }
    contacts {
      id
      name
      first_name
      last_name
      phone
      email
      partner_client_id
    }
  }
  ${cityFragment}
`;

export const offerContractDocumentFragment = gql`
  fragment OfferContractDocument on OfferContractDocument {
    id
    document_date_validity
    document_date_validity_comment
    inquiry_id
    inquiry_external_id
    created_at
    document_code
    code1
    comment_term_period
    comment_establishment
    comment_contigent
    comment_service
    program_visible
    offer_contract_document_type_id
    offer_contract_document_segment_id
    partner_client_id
    release_day
    partner_client {
      ...Partner
    }
    service_package

    offer_contract_document_services {
      id
      service_from
      service_to
      service_type_id
      service_types {
        service_type_translations {
          id
          name
          language_id
          language {
            id
            desc
          }
        }
      }
    }

    referents {
      id
      name
      first_name
      last_name
      phone
      email
      tag
      partner_client_id
    }
    offer {
      id
      off_key
    }
    language_id
    additional_services {
      id
      actual_price
      activity_id
    }
  }
  ${partnerClientFragment}
`;

export const offerFragment = gql`
  fragment OfferContent on Offer {
    id
    external_id
    off_key
    offer_status_id
    storno_comment
    offer_created_at
    is_editable
    archived_status
  }
`;

export const inquiryFragment = gql`
  fragment Inquiry on OfferContractDocument {
    inquiry {
      id
      remark
      attachments {
        id
        file_id
        filename
      }
    }
  }
`;

export const bookingOCDocument = gql`
  fragment Document on OfferContractDocument {
    id
    document_code
    inquiry_id
    inquiry_external_id
    code1
    comment_term_period
    comment_establishment
    comment_contigent
    comment_service
    offer_contract_document_type_id
    offer_contract_document_segment_id
    offer_contract_document_segment {
      id
      desc
      code
    }
    partner_client {
      id
      name
      phones
      fax
      address
      comments
      cities {
        id
        name
        postal_code
      }
    }
  }
`;

export const establishmentLegalEntityFragment = gql`
  fragment EstablishmentLegalEntity on EstablishmentLegalEntity {
    id
    name
    web
    email
    oib
    address1
    address2
    phone
    fax
    contact_person1
    contact_person2
    city_id
    city {
      ...City
    }
  }
  ${cityFragment}
`;

export const attachmentFragment = gql`
  fragment Attachment on File {
    id
    file_id
    path
    filename
    mimetype
    encoding
  }
`;

export const userFragment = gql`
  fragment User on User {
    id
    username
    first_name
    last_name
    signature_url
    signature_filename
    email
  }
`;

export const auditLogFragment = gql`
  fragment AuditLog on AuditLog {
    id
    user {
      ...User
    }
    trigger_type
    table
    target_id
    created_at
    before_state
    after_state
  }
  ${userFragment}
`;

export const alotmanDayFragment = gql`
  fragment AlotmanDay on AlotmanDay {
    id
    date
    deadline_notice
    typology_id
    typology {
      ...Typology
    }
    stop_booking
    available_state
    sold
    sum
  }
  ${typologyFragment}
`;

export const serviceTypeFragment = gql`
  fragment ServiceType on ServiceType {
    id
    service_type_translations {
      id
      name
      desc
      code
      language_id
      language {
        id
        code
        desc
      }
    }
  }
`;

export const serviceFragment = gql`
  fragment Service on Service {
    id
    service_translations {
      id
      name
      service_id
      language_id
    }
    service_type_id
  }
`;
