import React from "react";
import { graphql } from "react-apollo";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { listCountries } from "../../../../graphql/query/administration";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import { FormHelperText } from "@material-ui/core";
import FormValidator from "../../../../components/UI/FormValidator";

const form = {
  padding: "24px",
};

const RegionForm = ({ title, region, data: { loading, listCountry }, onChangeField, onSubmit, cancelToggle }) => {
  if (loading) {
    return <div>Dohvaćam države . . . </div>;
  }

  return (
    <FormValidator schema={RegionForm.schema} submit={onSubmit} data={region}>
      {({ error, errorMsg, submit }) => (
        <div style={form}>
          <h3>{title}</h3>
          <div className="form-control-grid">
            <FormControl fullWidth /* className={classes.margin} */>
              <InputLabel shrink={true} htmlFor="name" error={error("/name")}>
                Regija
              </InputLabel>
              <Input
                id="name"
                name="name"
                error={error("/name")}
                value={region.name ? region.name : ""}
                onChange={({ target: { value } }) => onChangeField({ key: "name", value })}
              />
              {error("/name") && <FormHelperText error={error("/name")}>{errorMsg("/name")}</FormHelperText>}
            </FormControl>
          </div>
          <div className="form-control-grid">
            <AutocompleteSelect
              label="Država"
              placeholder="Odabir države"
              error={error("/country_id")}
              errorMsg={errorMsg("/country_id")}
              dataSource={listCountry.map(country => ({
                value: country.id,
                label: country.name,
              }))}
              defaultValue={region.country_id}
              autocompleteHandler={selected =>
                onChangeField({
                  key: "country_id",
                  value: selected,
                })
              }
            />
          </div>
          <Button variant="contained" onClick={submit}>
            Spremi
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={cancelToggle}>
            Poništi
          </Button>
        </div>
      )}
    </FormValidator>
  );
};

RegionForm.schema = {
  properties: {
    name: {
      type: "string",
      isNotEmpty: true,
      errorMessage: "Ime grada ne smije biti prazno",
    },
    country_id: {
      type: "integer",
      minimum: 1,
      errorMessage: "Potrebno je izabrati državu",
    },
  },
};

export default graphql(listCountries)(RegionForm);
