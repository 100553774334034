import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";
import get from "lodash/get";
import React, { Component } from "react";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";

import Popover from "react-awesome-popover";
import "react-awesome-popover/dest/react-awesome-popover.css";
import { connect } from "react-redux";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import FormValidator from "../../../../components/UI/FormValidator";
import TrueFalseSelect from "../../../../components/UI/TrueFalseSelectNewMaterial";
import { establishmentsFormQuery, listCities } from "../../../../graphql/query/administration";
import { resetAdministrationData } from "../../../../store/actions";
import { cityInfo } from "../../../../utility/city";
import { sortTypologies } from "../../../../utility/establishment";
import { removeDuplicateObjects } from "../../../../utility/globals";
import TypologyForm from "./TypologyForm";
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "../../../../utility/constants";

const form = {
  padding: "24px",
};

class EstablishmentForm extends Component {
  static schema = {
    type: "object",
    properties: {
      name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Naziv ne smije biti prazan",
      },
      city_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati grad",
      },
      establishment_legal_entity_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati firmu",
      },
      establishment_chain_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati smještajni lanac",
      },
      typologies: {
        type: "array",
        minItems: 1,
        errorMessage: "Potrebano je dodati tipologiju",
      },
    },
  };

  state = {
    city: this.props.establishment.city,
    fetchingMoreCities: false,
  };

  componentWillUnmount() {
    this.props.resetAdministrationData();
  }

  variables = {
    input: {
      paginationLimit: {
        limit: PAGINATION_LIMIT,
        offset: PAGINATION_OFFSET,
      },
    },
  };

  handleAutocompleteInputChange = value => {
    let where = this.variables.input.where ? JSON.parse(this.variables.input.where) : null;

    // checking if previous fetch name and current value are empty
    // skip fetch if same requests
    if (where && (!where.name && !value)) {
      return;
    }

    where = this.variables.input.where
      ? JSON.stringify({
          ...JSON.parse(this.variables.input.where),
          name: value,
        })
      : JSON.stringify({
          name: value,
        });

    this.variables = {
      ...this.variables,
      input: {
        ...this.variables.input,
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
        where: where,
      },
    };

    this.props.listCitiesQuery.fetchMore({
      variables: this.variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        if (fetchMoreResult.listCity.length >= PAGINATION_LIMIT) {
          this.hasMoreItems = true;
        }

        return {
          previousResult,
          listCity: [...fetchMoreResult.listCity],
        };
      },
    });
  };

  handleAutocompleteInputChangeDebounced = debounce(this.handleAutocompleteInputChange, 500);

  fetchingResults = false;
  hasMoreItems = true;

  handleScroller = async () => {
    const listCity = get(this.props, "listCitiesQuery.listCity", []);

    if (this.fetchingResults === false && this.hasMoreItems && listCity && listCity.length >= PAGINATION_LIMIT) {
      this.setState({ fetchingMoreCities: true }, async () => {
        this.fetchingResults = true;

        this.variables.input.paginationLimit.offset =
          this.variables.input.paginationLimit.offset + this.variables.input.paginationLimit.limit;

        await this.props.listCitiesQuery.fetchMore({
          variables: this.variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            // we're retreiving PAGINATION_LIMIT partners each time,
            if (fetchMoreResult.listCity.length < PAGINATION_LIMIT) {
              this.hasMoreItems = false;
            }

            return {
              ...previousResult,
              listCity: [...previousResult.listCity, ...fetchMoreResult.listCity],
            };
          },
        });

        this.setState({ fetchingMoreCities: false });
      });
    }
  };

  render() {
    const {
      establishment,
      data: { loading, listLanguage, listEstablishmentChain, listEstablishmentLegalEntity, listTypologyGroup },
      onChangeField,
      onSubmit,
    } = this.props;

    const listCity = get(this.props, "listCitiesQuery.listCity", []);
    const loadingCities = get(this.props, "listCitiesQuery.loading", []);

    if (loading || loadingCities) {
      return <div>Loading . . . </div>;
    }

    if (this.fetchingResults === true) {
      this.fetchingResults = false;
    }

    // Inserting picked city to top,
    // So it can be picked in init array  even if it's not fetched from first city list
    const citiesToShow =
      this.state.city && listCity && listCity.length
        ? removeDuplicateObjects([this.state.city, ...listCity]).map(city => ({
            value: city.id,
            label: cityInfo(city),
          }))
        : listCity.map(city => ({
            value: city.id,
            label: cityInfo(city),
          }));

    return (
      <FormValidator schema={EstablishmentForm.schema} submit={onSubmit} data={{ ...establishment }}>
        {({ error, errorMsg, submitAndRecreate }) => (
          <div style={form}>
            <div
              className="establishment-form"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "25px",
              }}
            >
              <span className="grid-column-1">
                <div className="form-control-grid">
                  <AutocompleteSelect
                    label="Firma"
                    error={error("/establishment_legal_entity_id")}
                    errorMsg={errorMsg("/establishment_legal_entity_id")}
                    defaultValue={
                      establishment.establishment_legal_entity_id ? establishment.establishment_legal_entity_id : ""
                    }
                    autocompleteHandler={value =>
                      onChangeField({
                        key: "establishment_legal_entity_id",
                        value,
                      })
                    }
                    dataSource={listEstablishmentLegalEntity.map(le => {
                      const info = cityInfo(le.city);
                      return {
                        value: le.id,
                        label: `${le.name} ${info ? " - " + info : info}`,
                      };
                    })}
                    placeholder="Odabir firme"
                  />
                </div>
                <div className="form-control-grid">
                  <AutocompleteSelect
                    error={error("/establishment_chain_id")}
                    errorMsg={errorMsg("/establishment_chain_id")}
                    label="Smještajni lanac"
                    defaultValue={establishment.establishment_chain_id ? establishment.establishment_chain_id : ""}
                    autocompleteHandler={value => onChangeField({ key: "establishment_chain_id", value })}
                    dataSource={
                      listEstablishmentChain &&
                      listEstablishmentChain.map(lc => {
                        const info = cityInfo(lc.city);

                        return {
                          value: lc.id,
                          label: `${lc.name} ${info ? " - " + info : info}`,
                        };
                      })
                    }
                    placeholder="Odabir firme"
                  />
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="oib">
                      OIB
                    </InputLabel>
                    <Input
                      id="oib"
                      name="oib"
                      value={establishment.oib ? establishment.oib : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "oib", value })}
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="rating">
                      Rating
                    </InputLabel>
                    <Input
                      id="rating"
                      name="rating"
                      value={establishment.rating ? establishment.rating : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "rating", value })}
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <AutocompleteSelect
                    label="Grad"
                    error={error("/city_id")}
                    errorMsg={errorMsg("/city_id")}
                    defaultValue={establishment.city_id || ""}
                    autocompleteHandler={value => {
                      this.setState({
                        city: listCity.find(city => city.id === value) || this.state.city,
                      });
                      return onChangeField({ key: "city_id", value });
                    }}
                    onMenuScrollToBottom={this.handleScroller}
                    isLoading={this.state.fetchingMoreCities}
                    dataSource={citiesToShow}
                    placeholder="Odabir grada"
                    inputProps={{
                      onInputChange: this.handleAutocompleteInputChangeDebounced,
                    }}
                  />
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth error={error("/name")}>
                    <InputLabel shrink={true} htmlFor="name">
                      Naziv *
                    </InputLabel>
                    <Input
                      id="name"
                      name="name"
                      value={establishment.name ? establishment.name : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "name", value })}
                    />
                    {error("/name") && <FormHelperText>{errorMsg("/name")}</FormHelperText>}
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="web">
                      Web
                    </InputLabel>
                    <Input
                      id="web"
                      name="web"
                      value={establishment.web ? establishment.web : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "web", value })}
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="address1">
                      Adresa
                    </InputLabel>
                    <Input
                      id="address1"
                      name="web"
                      value={establishment.address1 ? establishment.address1 : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "address1",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="address2">
                      Regija - interno
                    </InputLabel>
                    <Input
                      id="address2"
                      name="web"
                      value={establishment.address2 ? establishment.address2 : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "address2",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>
              </span>

              <span className="grid-column-2">
                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="contact_person1">
                      Kontakt osoba 1
                    </InputLabel>
                    <Input
                      id="contact_person1"
                      name="web"
                      value={establishment.contact_person1 ? establishment.contact_person1 : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "contact_person1",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="contact_person2">
                      Kontakt osoba 2
                    </InputLabel>
                    <Input
                      id="contact_person1"
                      name="contact_person2"
                      value={establishment.contact_person2 ? establishment.contact_person2 : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "contact_person2",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="email">
                      Email
                    </InputLabel>
                    <Input
                      id="email"
                      name="email"
                      value={establishment.email ? establishment.email : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "email", value })}
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="phone">
                      Telefon
                    </InputLabel>
                    <Input
                      id="phone"
                      name="phone"
                      value={establishment.phone ? establishment.phone : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "phone", value })}
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="fax">
                      Fax
                    </InputLabel>
                    <Input
                      id="fax"
                      name="fax"
                      value={establishment.fax ? establishment.fax : ""}
                      onChange={({ target: { value } }) => onChangeField({ key: "fax", value })}
                    />
                  </FormControl>
                </div>
                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <TextField
                      id="breakfast_and_dinner_times"
                      name="breakfast_and_dinner_times"
                      className="preview-content-content preview-textarea textarea-stretch"
                      multiline
                      fullWidth={true}
                      label="Vrijeme doručka i večere"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={establishment.breakfast_and_dinner_times ? establishment.breakfast_and_dinner_times : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "breakfast_and_dinner_times",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <TextField
                      id="breakfast_and_dinner_buffet_or_menu"
                      name="breakfast_and_dinner_buffet_or_menu"
                      className="preview-content-content preview-textarea textarea-stretch"
                      multiline
                      fullWidth={true}
                      label="Ručak, doručak, buffet ili meni"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        establishment.breakfast_and_dinner_buffet_or_menu
                          ? establishment.breakfast_and_dinner_buffet_or_menu
                          : ""
                      }
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "breakfast_and_dinner_buffet_or_menu",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>
              </span>

              <span className="grid-column-3">
                <div className="form-control-grid">
                  <TrueFalseSelect
                    label="Mjenjačnica"
                    returnBoolean
                    value={typeof establishment.currency_exchange === "boolean" ? establishment.currency_exchange : ""}
                    changed={onChangeField}
                    inputProps={{ name: "currency_exchange" }}
                  />
                </div>
                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="currency_exchange_comment">
                      Mjenjačnica napomena
                    </InputLabel>
                    <Input
                      id="currency_exchange_comment"
                      name="currency_exchange_comment"
                      value={establishment.currency_exchange_comment ? establishment.currency_exchange_comment : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "currency_exchange_comment",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <TrueFalseSelect
                    label="Parking"
                    returnBoolean
                    value={typeof establishment.parking === "boolean" ? establishment.parking : ""}
                    changed={onChangeField}
                    inputProps={{ name: "parking" }}
                  />
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="parking_comment">
                      Parking napomena
                    </InputLabel>
                    <Input
                      id="parking_comment"
                      name="parking_comment"
                      value={establishment.parking_comment ? establishment.parking_comment : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "parking_comment",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <TrueFalseSelect
                    label="Wellness"
                    returnBoolean
                    value={typeof establishment.wellnes === "boolean" ? establishment.wellnes : ""}
                    changed={onChangeField}
                    inputProps={{ name: "wellnes" }}
                  />
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="wellnes_comment">
                      Wellnes napomena
                    </InputLabel>
                    <Input
                      id="wellnes_comment"
                      name="wellnes_comment"
                      value={establishment.wellnes_comment ? establishment.wellnes_comment : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "wellnes_comment",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-control-grid">
                  <TrueFalseSelect
                    label="Bazen"
                    returnBoolean
                    value={typeof establishment.pools === "boolean" ? establishment.pools : ""}
                    changed={onChangeField}
                    inputProps={{ name: "pools" }}
                  />
                </div>

                <div className="form-control-grid">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} htmlFor="pools_comment">
                      Bazen napomena
                    </InputLabel>
                    <Input
                      id="pools_comment"
                      name="pools_comment"
                      value={establishment.pools_comment ? establishment.pools_comment : ""}
                      onChange={({ target: { value } }) =>
                        onChangeField({
                          key: "pools_comment",
                          value,
                        })
                      }
                    />
                  </FormControl>
                </div>
              </span>
            </div>
            <div>
              {error("/typologies") && (
                <FormHelperText error={error("/typologies")}>{errorMsg("/typologies")}</FormHelperText>
              )}
              {establishment.typologies && establishment.typologies.length ? (
                <React.Fragment>
                  <h3>Lista tipologija smještaja</h3>
                  <Grid container spacing={24}>
                    {sortTypologies(establishment.typologies).map(typology => (
                      <TypologyForm
                        key={typology.id}
                        changeData={this.editAddedTypology}
                        typology={typology}
                        listLanguage={listLanguage}
                        typologyEdited={this.props.typologyEdited}
                        typologyRemove={this.props.typologyRemove}
                        listTypologyGroup={listTypologyGroup}
                      />
                    ))}
                  </Grid>
                </React.Fragment>
              ) : null}
            </div>
            <div style={{ margin: "34px 0px" }}>
              <Grid container spacing={24}>
                <TypologyForm
                  listTypologyGroup={listTypologyGroup}
                  listLanguage={listLanguage}
                  createMode={true}
                  changeData={this.editAddedTypology}
                  typology={this.props.typology}
                  establishment_id={establishment.id}
                  onAddTypology={this.props.onAddTypology}
                />
              </Grid>
            </div>
            <br />
            <Button variant="contained" onClick={submitAndRecreate}>
              Spremi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" onClick={this.props.cancelToggle}>
              Poništi
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Popover action="click" placement="bottom" contentClass="rap-popover-content">
              <Button variant="contained" color="secondary">
                Izbriši
              </Button>
              <div className="pad">
                <Button variant="contained" classes={{ root: "is-success" }} onClick={this.props.onDelete}>
                  Potvrdi
                </Button>
              </div>
            </Popover>
          </div>
        )}
      </FormValidator>
    );
  }
}

const mapStateToProps = state => {
  return {
    typology: state.administration.typology,
  };
};

export default connect(
  mapStateToProps,
  {
    resetAdministrationData,
  },
)(
  compose(
    graphql(establishmentsFormQuery),
    graphql(listCities, {
      name: "listCitiesQuery",
      options: {
        variables: {
          input: {
            paginationLimit: {
              limit: PAGINATION_LIMIT,
              offset: PAGINATION_OFFSET,
            },
          },
        },
      },
    }),
  )(withApollo(EstablishmentForm)),
);
