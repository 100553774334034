import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import { connect } from "react-redux";
import toastr from "toastr";
import { createEstablishment } from "../../../../graphql/mutation/administration";

import { changeAdministrationEstablishmentData } from "../../../../store/actions";

import EstablishmentForm from "./EstablishmentForm";
import { prepareForSending, removeAllEmptyKeys } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class CreateEstablishment extends React.Component {
  submit = async () => {
    const { establishment } = this.props;
    try {
      const response = await this.props.mutate({
        variables: {
          ...removeAllEmptyKeys(prepareForSending(establishment, ["id"]), ["typologies"]),
        },
      });

      toastr.success("Uspješno kreirano");
      if (response.data.createEstablishment.id) {
        this.props.cancelToggle();
      }
    } catch (error) {
      alert("Greška prilikom kreiranja");
    }
  };

  render() {
    return (
      <React.Fragment>
        <TableTitle title="Kreiranje smještaja" onBackPressed={this.props.cancelToggle} />

        <EstablishmentForm
          onSubmit={this.submit}
          establishment={this.props.establishment}
          cancelToggle={this.props.cancelToggle}
          onChangeField={this.props.changeAdministrationEstablishmentData}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { establishment } = state.administration;

  return { establishment };
};

export default compose(graphql(createEstablishment))(
  connect(mapStateToProps, { changeAdministrationEstablishmentData })(CreateEstablishment),
);
