import React, { Component } from "react";
import { graphql } from "react-apollo";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import ActivityTranslations from "./ActivityTranslations";
import { listActivityType } from "../../../../graphql/query/administration";
import { decimalNumbersTwoDigits } from "../../../../utility/numbers";
import FormValidator from "../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

class ActivityForm extends Component {
  static schema = {
    properties: {
      default_price: {
        format: "numeric",
        errorMessage: "Cijena mora biti broj",
      },
      activity_type_id: {
        type: "integer",
        minimum: 1,
        errorMessage: "Potrebno je izabrati tip aktivnosti",
      },
      activity_translations: {
        type: "array",
        minItems: 1,
        errorMessage: "Potreban je barem jedan prijevod",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi jezici moraju imati naziv",
            },
            desc: {
              type: "string",
              minLength: 1,
              isNotEmpty: true,
              errorMessage: "Svi opisi moraju biti ispunjeni",
            },
          },
        },
      },
    },
  };

  render() {
    const {
      activity,
      data: { loading, listActivityType },
      onChangeField,
      editTranslation,
      listLanguage,
      onSubmit,
    } = this.props;
    if (loading) {
      return <div>Loading tipove aktivnosti . . . </div>;
    }

    if (!listLanguage) {
      return <div>No languages . . .</div>;
    }

    return (
      <FormValidator schema={ActivityForm.schema} submit={onSubmit} data={activity}>
        {({ error, errorMsg, submit }) => {
          return (
            <Grid container spacing={24}>
              <Grid item xl={8}>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} htmlFor="default_price" error={error("/default_price")}>
                        Cijena
                      </InputLabel>
                      <Input
                        id="default_price"
                        name="default_price"
                        error={error("/default_price")}
                        value={activity.default_price ? activity.default_price : ""}
                        onChange={({ target: { value } }) =>
                          onChangeField({
                            key: "default_price",
                            value: decimalNumbersTwoDigits(value),
                          })
                        }
                      />
                      {error("/default_price") && (
                        <FormHelperText error={error("/default_price")}>{errorMsg("/default_price")}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        id="select-activity"
                        select
                        label="Tip aktivnosti"
                        error={error("/activity_type_id")}
                        value={activity.activity_type_id.toString() || ""}
                        onChange={({ target: { value } }) =>
                          onChangeField({
                            key: "activity_type_id",
                            value,
                          })
                        }
                      >
                        {listActivityType &&
                          listActivityType.map(activityType => (
                            <MenuItem key={activityType.id} value={activityType.id}>
                              {activityType.desc}
                            </MenuItem>
                          ))}
                      </TextField>
                      {error("/activity_type_id") && (
                        <FormHelperText error={error("/activity_type_id")}>
                          {errorMsg("/activity_type_id")}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <ActivityTranslations
                      error={error}
                      errorMsg={errorMsg}
                      activity_translations={activity.activity_translations}
                      listLanguage={listLanguage}
                      editTranslation={editTranslation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={24} className="cardactions-button-padding">
                      <Grid item>
                        <Button variant="contained" onClick={submit}>
                          Spremi
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={this.props.cancelToggle}>
                          Poništi
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </FormValidator>
    );
  }
}

export default graphql(listActivityType)(ActivityForm);
