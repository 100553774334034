import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Chip } from "@material-ui/core";

import { changeDocumentParam } from "../../../../store/actions";

const OfferField = props =>
  props.offer_id ? (
    <div className="form-unit-double">
      <div className="field-label is-autocomplete">
        <label className="label">Ponuda</label>
      </div>
      <div>
        <Chip
          label={props.offer_id}
          onClick={() => props.history.push(`/ponude/edit/${props.offer_id}`)}
          target="_blank"
        />
      </div>
    </div>
  ) : null;

export default connect(
  ({
    offer: {
      offer: {
        offer_contract_document: { offer }
      }
    }
  }) => ({
    offer_id: offer ? offer.id : ""
  }),
  { changeDocumentParam }
)(withRouter(OfferField));
