import RaisedButton from "material-ui/RaisedButton";
import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import TableTitle from "src/components/UI/TableTitle";
import { withLocalStorageHOC } from "src/hooks/withLocalStorageHOC";
import EditIcon from "../../../components/UI/EditIcon";
import ReactTableCustom from "../../../components/UI/ReactTable";
import { typologyGroups } from "../../../graphql/query/administration";
import { AdministrationTabs } from "../AdministrationTabs";

class TypologyGroupList extends Component {
  state = {
    columns: [
      {
        Header: "Ažuriraj",
        id: "full",
        width: 65,
        filterable: false,
        accessor: typology_group => (
          <Link className="ReactTableFirstColumnDiv" to={`/administracija/grupa_tipologije/edit/${typology_group.id}`}>
            <EditIcon />
          </Link>
        ),
      },
      {
        Header: "ID",
        width: 55,
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
    ],
  };

  render() {
    const {
      data: { loading, listTypologyGroup },
    } = this.props;

    if (loading) {
      return <div> Loading . . . </div>;
    }

    return (
      <Fragment>
        <TableTitle title="Lista grupe tipologije" />

        <ReactTableCustom
          data={listTypologyGroup || []}
          columns={this.state.columns}
          renderCustomPagination={true}
          innerProps={{
            onFilteredChange: filters => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: 0, filtersSelected: filters });
            },

            onPageChange: page => {
              this.props.setTableState({ ...this.props.tableState, pageSelected: page });
            },

            onPageSizeChange: (pageSize, pageIndex) => {
              this.props.setTableState({
                ...this.props.tableState,
                pageSelected: pageIndex,
                pageSizeSelected: pageSize,
              });
            },

            page: this.props.tableState.pageSelected,
            pageSize: this.props.tableState.pageSizeSelected,
            defaultFiltered: this.props.tableState.filtersSelected,
          }}
        />
        <RaisedButton
          className="administration-btn-add-new"
          label="Dodaj novu"
          primary={true}
          onClick={() => this.props.history.push("/administracija/grupa_tipologije/create")}
        />
      </Fragment>
    );
  }
}

export default withLocalStorageHOC(
  AdministrationTabs(
    graphql(typologyGroups, {
      options: {
        fetchPolicy: "network-only",
      },
    })(TypologyGroupList),
    "Grupe tipologije",
  ),
);
