// libs
import { useMutation } from "react-apollo";
import { get } from "lodash";
import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideTourAnnouncement,
  setAnnouncement,
  setAnnouncementInputField,
} from "src/views/Guides/Container/components/Drawers/components/Announcement/announcementEditSlice";

// graphql
import { UPDATE_GUIDE_TOUR_ANNOUNCEMENT } from "src/graphql/mutation/announcement";

/**
 * useGuideTourAnnouncement is entry point of data for guide tour announcement form component
 */
export default function useGuideTourAnnouncement() {
  // redux state
  const guideTourAnnouncement = useSelector(selectGuideTourAnnouncement);

  const dispatch = useDispatch();

  // mutations
  const [updateGuideTourAnnouncement, { loading }] = useMutation(UPDATE_GUIDE_TOUR_ANNOUNCEMENT);

  /**
   * handle on update announcement
   */
  const handleOnUpdateGuideTourAnnouncement = async (keyOfValue, value) => {
    const updateAnnouncement = await updateGuideTourAnnouncement({
      variables: {
        input: {
          id: guideTourAnnouncement.id,
          [keyOfValue]: value,
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateGuideTourAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ guideTourAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  /**
   * handle on update hotel details
   */
  const handleOnUpdateHotelDetails = async (establishmentId, keyOfValue, value) => {
    const updateAnnouncement = await updateGuideTourAnnouncement({
      variables: {
        input: {
          id: guideTourAnnouncement.id,
          hotel_extra_fields: [
            {
              establishment_id: establishmentId,
              [keyOfValue]: value,
            },
          ],
        },
      },
    });

    const announcementUpdated = get(updateAnnouncement, "data.updateGuideTourAnnouncement");

    if (announcementUpdated) {
      toastr.success("Uspješno ažurirana najava");

      // update announcement
      dispatch(setAnnouncement({ guideTourAnnouncement: announcementUpdated }));

      // close edit input
      dispatch(setAnnouncementInputField({ editInputKey: keyOfValue, isEdit: false }));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  return {
    guideTourAnnouncement,
    handleOnUpdateGuideTourAnnouncement,
    handleOnUpdateHotelDetails,
    loading,
  };
}
