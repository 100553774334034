import React from "react";
import ReactTableCustom from "../../../../../components/UI/ReactTable";
import Calculator from "../Calculator";
import { removeDuplicateInts } from "../../../../../utility/globals";
import { Paper } from "@material-ui/core";

const CalculatorHeaderCalculation = () => (
  <Calculator.Consumer>
    {({ calculator: { total_calculation, totals } }) => {
      let allTypologiesGroupIds = [];

      total_calculation.forEach(hotel => {
        hotel.typologies.forEach(typology => allTypologiesGroupIds.push(typology.typology_group_id));
      });

      allTypologiesGroupIds = removeDuplicateInts(allTypologiesGroupIds);

      function typologyGroupName(typology_group_id) {
        let start = 0;
        const end = total_calculation.length;
        let typologyGroupFound = false;
        let typologyGroup = null;

        while (start < end && !typologyGroupFound) {
          const hotel = total_calculation[start];

          const typology = hotel.typologies.find(typology => typology.typology_group_id === typology_group_id);

          typologyGroupFound = !!typology;

          if (typologyGroupFound) {
            typologyGroup = typology.typology_group;
          }

          start++;
        }

        return typologyGroup && typologyGroup.name;
      }

      const typlogiesColumns = allTypologiesGroupIds.map(typology_group_id => ({
        Header: `${typologyGroupName(typology_group_id)}`,
        id: `typology_group_${typology_group_id}`,
        accessor: hotel => {
          const typology = hotel.typologies.find(typology => typology.typology_group_id === typology_group_id);

          return typology ? Number(typology.total).toFixed(2) + " €" : "";
        },
        Footer: <strong>{Number(totals[`total_price_for_${typology_group_id}`]).toFixed(2)} €</strong>,
      }));

      const getColumns = [
        {
          Header: "Hotel",
          accessor: "establishment_info",
          id: "establishment_info",
          Footer: <strong>TOTAL</strong>,
        },
        ...typlogiesColumns,
      ];

      return (
        <Paper elevation={1} className="content-print">
          <ReactTableCustom
            data={total_calculation}
            columns={getColumns}
            innerProps={{
              pageSize: total_calculation.length || 1,
              showPagination: false,
              filterable: false,
            }}
          />
        </Paper>
      );
    }}
  </Calculator.Consumer>
);

export default CalculatorHeaderCalculation;
