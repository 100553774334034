import { ApolloClient } from "apollo-client";
//import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";
import { from, split } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";

import { getTokenMiddleware, setTokenAfterware } from "./middleware/token";

import toastr from "toastr";

// Create a WebSocket link:
export const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SERVER_URL_WS}/subscriptions`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      token: localStorage.getItem("token"),
    }),
  },
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
});

const cache = new InMemoryCache /*{ addTypename: false }*/();

// const cache = new InMemoryCache({
//   dataIdFromObject: o => {
//     o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`;
//   }
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      switch (error.message) {
        case "Contract Duplication":
          return toastr.warning("Ugovor za ovu ponudu je već prethodno kreiran", "Kreiranje ugovora", {
            positionClass: "toast-top-center",
            closeButton: true,
          });

        default:
          return toastr.warning(error.message, error.path ? error.path[0] : "", {
            positionClass: "toast-top-center",
            closeButton: true,
          });
      }
    });
  }
  if (networkError) {
    toastr.error(networkError.message, "[Network error]", {
      closeButton: true,
      positionClass: "toast-top-full-width",
    });
  }
});

const httpLinkWithMiddleware = from([getTokenMiddleware, errorLink, setTokenAfterware.concat(httpLink)]);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLinkWithMiddleware,
);

const defaultOptions = {
  watchQuery: { fetchPolicy: "network-only", errorPolicy: "ignore" },
  query: { fetchPolicy: "network-only", errorPolicy: "all" },
  mutate: { errorPolicy: "all" },
};

export const client = new ApolloClient({
  link,
  dataIdFromObject: o => o.id,
  cache,
  defaultOptions,
});

export default client;
