import React from "react";

// components
import CustomDatePicker from "src/components/UI/CustomDatePicker";

/**
 * Filter for selecting date from / to
 */
export default function FilterDate({ dateSelected, handleOnDateChange }) {
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <CustomDatePicker value={dateSelected} onChange={handleOnDateChange} />
    </div>
  );
}
