import React, { Component, Fragment } from "react";
import { withApollo, graphql } from "react-apollo";
import { getActivityType } from "../../../../graphql/query/administration";

import ActivityTypeForm from "./ActivityTypeForm";

import { updateActivityType } from "../../../../graphql/mutation/administration";

import { prepareForSending } from "../../../../utility/prepare";
import TableTitle from "src/components/UI/TableTitle";

class EditActivityType extends Component {
  state = {
    activityType: {
      id: "",
      desc: "",
    },
  };

  async componentDidMount() {
    const { activityType } = this.props;

    const response = await this.props.client.query({
      query: getActivityType,
      variables: { id: activityType.id },
    });

    if (response.data.getActivityType) {
      // set establishmentChain for editing
      this.setState({
        activityType: { ...response.data.getActivityType },
      });
    }
  }

  onChangeField = obj =>
    this.setState(prevState => {
      return {
        activityType: {
          ...prevState.activityType,
          [obj.key]: obj.value,
        },
      };
    });

  submit = async () => {
    try {
      const { activityType } = this.state;

      await this.props.mutate({
        variables: {
          id: activityType.id,
          patch: { ...prepareForSending(activityType, ["__typename", "id"]) },
        },
      });

      this.props.closeEdit();
    } catch (error) {}
  };

  render() {
    const { activityType } = this.state;

    return (
      <Fragment>
        <TableTitle title={`Ažuriranje tipa aktivnosti ${activityType.id}`} onBackPressed={this.props.closeEdit} />
        <ActivityTypeForm
          activityType={activityType}
          onChangeField={this.onChangeField}
          cancelToggle={this.props.closeEdit}
          onSubmit={this.submit}
        />
      </Fragment>
    );
  }
}

export default graphql(updateActivityType)(withApollo(EditActivityType));
