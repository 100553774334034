import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FormControl, FormHelperText } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "5px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  borderError: { border: "1px solid red" },
});

function BootstrapLikeInput(props) {
  const { classes, error, errorMsg, classProps, fullWidth, label } = props;

  return (
    <FormControl error={error} fullWidth={!!fullWidth} className={classProps}>
      <TextField
        value={props.value}
        label={label}
        id="bootstrap-input"
        multiline={props.multiline}
        rows={props.rows || "1"}
        className={[classes.root, classProps].join(" ")}
        onChange={props.onChange}
        error={error}
        disabled={props.disabled}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.bootstrapRoot,
            input: `${classes.bootstrapInput} ${error ? classes.borderError : ""}`,
          },
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.bootstrapFormLabel,
        }}
      />
      {error && <FormHelperText error={error}>{errorMsg}</FormHelperText>}
    </FormControl>
  );
}

BootstrapLikeInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BootstrapLikeInput);
