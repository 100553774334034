import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import gql from "graphql-tag";
import debounce from "lodash/debounce";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import AutocompleteSelect from "../../../../components/UI/AutocompleteSelect";
import CommentTextArea from "../../../../components/UI/CommentTextArea";
import { priceStructureFragment } from "../../../../graphql/fragments";
import { changePriceStructure, changePriceStructurePrice, resetPriceStructureChanges } from "../../../../store/actions";
import { formatDate } from "../../../../utility/dates";
import { autosize } from "../../../../utility/globals";
import { decimalNumbersTwoDigits } from "../../../../utility/numbers";
import { prepareForSending } from "../../../../utility/prepare";
import CalculatorModal from "../Calculator/CalculatorModal";
import OfferForm from "../OfferForm";

const updatePriceStructure = gql`
  mutation($id: ID!, $patch: UpdatePriceStructure!) {
    updatePriceStructure(input: { id: $id, patch: $patch }) {
      ...PriceStructure
    }
  }
  ${priceStructureFragment}
`;

class PriceStructureItem extends Component {
  state = {
    editPriceStructure: false,
    terminId: null,
  };

  price_typology_input = {};
  tourist_tax_ref = React.createRef();

  makePriceStructureCopy = price_structure => terminId => () =>
    this.setState({
      editPriceStructure: price_structure,
      terminId,
    });

  componentDidUpdate() {
    this.props.noDuplicatesTypologies.forEach(typology => {
      if (
        this.props.termin.price_structure &&
        this.props.termin.price_structure.price_typology !== null &&
        typeof this.props.termin.price_structure.price_typology !== "undefined"
      ) {
        const price_typology = JSON.parse(this.props.termin.price_structure.price_typology);
        this.price_typology_input[typology.id].value = price_typology[typology.id];
      }
    });

    this.autoResize();
  }

  componentDidMount = () => {
    this.autoResize();
  };

  autoResize() {
    const { termin } = this.props;

    if (document.getElementsByName(`comment_price_structure_${termin.id}`).length) {
      autosize(document.getElementsByName(`comment_price_structure_${termin.id}`)[0]);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevPP = JSON.stringify(this.props.termin.price_structure);
    const nextPP = JSON.stringify(nextProps.termin.price_structure);

    return (
      this.state.editPriceStructure !== nextState.editPriceStructure ||
      this.state.terminId !== nextState.terminId ||
      nextProps.noDuplicatesTypologies !== this.props.noDuplicatesTypologies ||
      nextProps.isNotDraft !== this.props.isNotDraft ||
      prevPP !== nextPP ||
      this.checkForNewValidationErrors(nextProps)
    );
  }

  checkForNewValidationErrors(nextProps) {
    const { index } = this.props;

    // to check if there are tourist_tax validation error
    const next_tt_id =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes(`${index}/price_structure/tourist_tax`);
      }).length;
    const prev_tt_id =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes(`${index}/price_structure/tourist_tax`);
      }).length;

    // to check if there are price_structure_type_id validation error
    const next_ps_id =
      nextProps.errors &&
      nextProps.errors.filter(function(e) {
        return e.dataPath.includes(`${index}/price_structure/price_structure_type_id`);
      }).length;
    const prev_ps_id =
      this.props.errors &&
      this.props.errors.filter(function(e) {
        return e.dataPath.includes(`${index}/price_structure/price_structure_type_id`);
      }).length;

    return next_tt_id !== prev_tt_id || next_ps_id !== prev_ps_id;
  }

  saveEdited = async () => {
    const { editPriceStructure } = this.state;

    const { termin } = this.props;

    try {
      await this.props.mutate({
        variables: {
          id: editPriceStructure.id,
          patch: {
            ...prepareForSending(termin.price_structure, ["id", "__typename"]),
          },
        },
      });

      this.setState({
        editPriceStructure: false,
        terminId: null,
      });

      toastr.success("Uspješno ažurirana cjenovna struktura");
    } catch (error) {}
  };

  resetMadeChanges = () => {
    this.props.resetPriceStructureChanges(this.state.editPriceStructure, this.state.terminId);
    this.setState({
      editPriceStructure: false,
      terminId: null,
    });
  };

  changePriceForTypology(typology_group_id, termin_id, event) {
    Object.keys(this.price_typology_input).forEach(key =>
      (this.price_typology_input[key].value = Number(decimalNumbersTwoDigits(event.target.value))).toFixed(2),
    );
    this.props.changePriceStructurePrice({
      key: typology_group_id,
      id: termin_id,
      value: Number(decimalNumbersTwoDigits(event.target.value)).toFixed(2),
    });
  }

  delayedCallbackPriceForTypology = debounce(this.changePriceForTypology, 1250);

  onChangePriceForTypology = (typology_group_id, termin_id) => event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();

    Object.keys(this.price_typology_input).forEach(
      key => (this.price_typology_input[key].value = decimalNumbersTwoDigits(this.price_typology_input[key].value)),
    );

    this.delayedCallbackPriceForTypology(typology_group_id, termin_id, event);
  };

  changeTouristTaxForTypology(termin_id, event) {
    this.tourist_tax_ref.current.value = Number(decimalNumbersTwoDigits(this.tourist_tax_ref.current.value)).toFixed(2);
    this.props.changePriceStructure({
      key: "tourist_tax",
      id: termin_id,
      value: Number(decimalNumbersTwoDigits(event.target.value)).toFixed(2),
    });
  }

  changeTouristTaxForTypologyDebounced = debounce(this.changeTouristTaxForTypology, 1250);

  onChangeTouristTaxForTypology = termin_id => event => {
    // will remove the synthetic event from the pool and allow references to the event to be retained by user code.
    event.persist();
    this.tourist_tax_ref.current.value = decimalNumbersTwoDigits(this.tourist_tax_ref.current.value);

    this.changeTouristTaxForTypologyDebounced(termin_id, event);
  };

  render() {
    const {
      termin,
      document_code,
      isNotDraft,
      error,
      errorMsg,
      price_typology,
      noDuplicatesTypologies,
      index,
    } = this.props;

    const priceStructureIsNotEdited =
      termin.id === this.state.terminId
        ? JSON.stringify(termin.price_structure) === JSON.stringify(this.state.editPriceStructure)
        : null;

    // if were in create mode
    const showEdit =
      !this.props.match.params.id ||
      (this.props.match.params.id &&
        termin.price_structure &&
        this.state.editPriceStructure.id === termin.price_structure.id);

    return (
      <Card className="price-structure" key={termin.id}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="headline" component="h4">
                {`${formatDate(termin.from)} - ${formatDate(termin.to)}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CalculatorModal termin={termin} document_code={document_code} isNotDraft={isNotDraft} />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteSelect
                error={error(`/termin_periods/${index}/price_structure/price_structure_type_id`)}
                errorMsg={errorMsg(`/termin_periods/${index}/price_structure/price_structure_type_id`)}
                label="Tip cjenovne strukture"
                inputProps={{ disabled: !showEdit || isNotDraft }}
                defaultValue={termin.price_structure ? termin.price_structure.price_structure_type_id : ""}
                autocompleteHandler={value =>
                  this.props.changePriceStructure({
                    key: "price_structure_type_id",
                    id: termin.id,
                    value,
                  })
                }
                placeholder="Tip cj. strukture"
                dataSource={
                  this.props.listPriceStructureType
                    ? this.props.listPriceStructureType.map(type => ({
                        value: type.id,
                        label: type.desc,
                      }))
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              {noDuplicatesTypologies.map(typology => (
                <TextField
                  disabled={!showEdit || isNotDraft}
                  key={typology.id}
                  label={`Cijena za: ${typology.code}`}
                  inputRef={el => (this.price_typology_input[typology.id] = el)}
                  defaultValue={price_typology && price_typology[typology.id]}
                  onChange={this.onChangePriceForTypology(typology.id, termin.id)}
                  fullWidth={true}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  disabled={!showEdit || isNotDraft}
                  label="Boravišna pristrojba"
                  inputRef={this.tourist_tax_ref}
                  defaultValue={termin.price_structure ? termin.price_structure.tourist_tax : ""}
                  onChange={this.onChangeTouristTaxForTypology(termin.id)}
                  fullWidth={true}
                />
                <FormHelperText>{errorMsg(`/termin_periods/${index}/price_structure/tourist_tax`)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CommentTextArea
                inputProps={{ disabled: !showEdit || isNotDraft }}
                name={`comment_price_structure_${termin.id}`}
                label="Komentar"
                defaultValue={
                  termin && termin.price_structure && termin.price_structure.comment_price_structure
                    ? termin.price_structure.comment_price_structure
                    : ""
                }
                customOnChange={({ target: { value } }) =>
                  this.props.changePriceStructure({
                    key: "comment_price_structure",
                    id: termin.id,
                    value,
                  })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        {!isNotDraft ? (
          <CardActions disableActionSpacing className="subtermin-create-buttons">
            {this.props.match.params.id && !this.state.editPriceStructure ? (
              <Button variant="outlined" onClick={this.makePriceStructureCopy(termin.price_structure)(termin.id)}>
                Izmijeni
              </Button>
            ) : null}
            {this.props.match.params.id &&
            termin.price_structure &&
            this.state.editPriceStructure.id === termin.price_structure.id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={priceStructureIsNotEdited}
                onClick={() => (priceStructureIsNotEdited ? null : this.saveEdited())}
              >
                Spremi
              </Button>
            ) : null}
            {this.props.match.params.id &&
            termin.price_structure &&
            this.state.editPriceStructure.id === termin.price_structure.id ? (
              <Button variant="outlined" onClick={this.resetMadeChanges}>
                Odustani
              </Button>
            ) : null}
          </CardActions>
        ) : null}
      </Card>
    );
  }
}

const PriceStructureItemHOC = props => {
  return (
    <OfferForm.Consumer>
      {({ error, errorMsg, errors }) => (
        <PriceStructureItem error={error} errorMsg={errorMsg} errors={errors} {...props} />
      )}
    </OfferForm.Consumer>
  );
};

export default graphql(updatePriceStructure)(
  connect(null, { changePriceStructure, changePriceStructurePrice, resetPriceStructureChanges })(
    withRouter(PriceStructureItemHOC),
  ),
);
