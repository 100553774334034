import { useCallback } from "react";

// libs
import { get, debounce } from "lodash";
import toastr from "toastr";
import { useMutation } from "react-apollo";
// libs
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectGuideLayoutComment,
  selectGuideScheduleDrawerPayload,
  selectGuideSchedules,
  setGuideSchedules,
} from "src/views/Guides/guidesSlice";

// graphql
import { UPDATE_GUIDE_EXTRA_FIELDS } from "src/graphql/mutation/operative";

/**
 * useLayoutComment is entry point of data for layoutComment component
 */
export default function useLayoutComment() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);
  const guideLayoutComment = useSelector(selectGuideLayoutComment);
  const listGuides = useSelector(selectGuideSchedules);

  const dispatch = useDispatch();

  // change program day title
  const [updateGuideExtraFields] = useMutation(UPDATE_GUIDE_EXTRA_FIELDS);

  /**
   * handle on comment change
   */
  const handleOnGuideCommentUpdate = async guideLayoutComment => {
    const updateGuide = await updateGuideExtraFields({
      variables: {
        input: {
          program_id: guidePayload.programId,
          guide_layout_comment: guideLayoutComment,
        },
      },
    });

    const guideUpdate = get(updateGuide, "data.updateGuideLayoutExtraFields");

    if (guideUpdate) {
      toastr.success("Uspješno ažuriran komentar");

      const guideLayoutComment = get(guideUpdate, "guide_layout_comment", "");

      // update list guides
      const listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === guidePayload.programId) {
          return {
            ...guide,
            guide_layout_comment: guideLayoutComment,
          };
        }
        return guide;
      });

      // update guide schedules
      dispatch(setGuideSchedules(listGuidesUpdate));
    } else {
      toastr.error("Greška ažuriranja");
    }
  };

  const delayedCallback = debounce(handleOnGuideCommentUpdate, 1000);

  const onGuideLayoutCommentChange = useCallback(
    event => {
      event.persist();

      const guideLayoutComment = get(event, "target.value");

      delayedCallback(guideLayoutComment);
    },
    [delayedCallback],
  );

  return {
    guideLayoutComment,
    onGuideLayoutCommentChange,
  };
}
