import React, { Component, Fragment } from "react";
import { graphql, withApollo } from "react-apollo";
import ServiceTypeForm from "./ServiceTypeForm";
import { createServiceType } from "../../../../graphql/mutation/administration";
import { listLanguage as fetchLanguages } from "../../../../graphql/query/administration";
import toastr from "toastr";
import TableTitle from "src/components/UI/TableTitle";

class CreateServiceType extends Component {
  state = {
    service_type_translations: [
      {
        name: "",
        language_id: "",
      },
    ],
  };

  componentDidMount = async () => {
    const languages = await this.props.client.query({
      query: fetchLanguages,
    });
    const {
      data: { listLanguage },
    } = languages;

    this.setState(prev => ({
      ...prev,
      service_type_translations: listLanguage.map(language => ({
        name: "",
        language_id: language.id,
      })),
    }));
  };

  changeTranslationFieldData = language_id => ({ target: { value } }) => {
    this.setState(prev => ({
      ...prev,
      service_type_translations: prev.service_type_translations.map(translation => {
        if (translation.language_id === language_id) {
          return {
            ...translation,
            name: value,
          };
        }
        return translation;
      }),
    }));
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });
      toastr.success("Usluga uspješno kreirana");

      this.props.cancelToggle();
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle title={`Stvaranje novog tipa osnovne usluge`} onBackPressed={this.props.cancelToggle} />

        <ServiceTypeForm
          serviceType={this.state}
          changeTranslationField={this.changeTranslationFieldData}
          onSubmit={this.submit}
          cancelToggle={this.props.cancelToggle}
        />
      </Fragment>
    );
  }
}

export default graphql(createServiceType)(withApollo(CreateServiceType));
