import { useCallback } from "react";

// libs
import { get } from "lodash";
import toastr from "toastr";
import { useQuery, useMutation } from "react-apollo";
import { useSelector, useDispatch } from "react-redux";

// redux state
import {
  selectContactIdSelected,
  selectCurrentProgramDayContentId,
  selectCurrentProgramDayId,
  selectGuideSchedules,
  selectIsDialogPartnerSearchOpen,
  selectPartnerIdSelected,
  selectProgramDayActivities,
  setGuideSchedules,
  setPartnerContactSelected,
  setPartnerSearchValues,
  setPartnerSelected,
  setProgramDayActivities,
} from "src/views/Guides/guidesSlice";

// constants
import { PAGINATION_LIMIT, PAGINATION_OFFSET } from "src/utility/constants";

// graphql
import { listPartnerClientPagination } from "src/graphql/query/administration";
import { UPDATE_PROGRAM_DAY_CONTENT } from "src/graphql/mutation/operative";

// constants
import { WARNING_PARTNER_SEARCH_NOT_SELECTED, SUCCESS_PARTNER_SEARCH_SELECT } from "src/utility/labels";

/**
 * usePartnerSearch is entry point of data for partnerSearch component
 */
export default function usePartnerSearch() {
  // redux state
  const isDialogPartnerSearchOpen = useSelector(selectIsDialogPartnerSearchOpen);
  const listGuides = useSelector(selectGuideSchedules);
  const programDayActivities = useSelector(selectProgramDayActivities);
  const currentProgramDayId = useSelector(selectCurrentProgramDayId);
  const currentProgramDayContentId = useSelector(selectCurrentProgramDayContentId);
  const partnerIdSelected = useSelector(selectPartnerIdSelected);
  const contactIdSelected = useSelector(selectContactIdSelected);

  const dispatch = useDispatch();

  // mutation change program day activities
  const [updateProgramDayActivities, { loading: loadingUpdatePartnerClient }] = useMutation(UPDATE_PROGRAM_DAY_CONTENT);

  // handle on partner search click
  const handleOnDialogClose = useCallback(() => {
    dispatch(setPartnerSearchValues({ isDialogPartnerSearchOpen: false }));
  }, [dispatch]);

  // fetch initial guides
  const { data } = useQuery(listPartnerClientPagination, {
    variables: {
      input: {
        paginationLimit: {
          limit: PAGINATION_LIMIT,
          offset: PAGINATION_OFFSET,
        },
      },
    },
    skip: !isDialogPartnerSearchOpen,
  });

  const listPartnerToShow = useCallback(() => {
    const listPartnerClient = get(data, "listPartnerClient");

    return listPartnerClient
      ? listPartnerClient.map(partnerClient => {
          return {
            ...partnerClient,
            language: partnerClient.language ? partnerClient.language.desc : "",
            languages: partnerClient.languages ? partnerClient.languages : "",
            city: `${partnerClient && partnerClient.city && partnerClient.city.name}, ${partnerClient &&
              partnerClient.city &&
              partnerClient.city.region &&
              partnerClient.city.region.name}`,
            country: `${partnerClient &&
              partnerClient.city &&
              partnerClient.city.region &&
              partnerClient.city.region.country &&
              partnerClient.city.region.country.name}`,
            num_services: partnerClient.services.reduce((total, key) => total + 1, 0),
          };
        })
      : [];
  }, [data]);

  /**
   * handle on partner and client save
   */
  const handleOnPartnerSearchSave = useCallback(async () => {
    if (!partnerIdSelected) {
      return toastr.error(WARNING_PARTNER_SEARCH_NOT_SELECTED);
    }

    const updateProgramDayResponse = await updateProgramDayActivities({
      variables: {
        input: {
          program_day_content_id: currentProgramDayContentId,
          partner_client_id: partnerIdSelected,
          guide_id: contactIdSelected,
        },
      },
    });

    let programUpdated = get(updateProgramDayResponse, "data.UpdateProgramDayContentAndGuideCalendarFields");

    if (programUpdated) {
      toastr.success(SUCCESS_PARTNER_SEARCH_SELECT);

      // todo handle on program update
      const program_days_updated = programDayActivities.program_days.map(programDay => {
        if (programDay.program_day_id === currentProgramDayId) {
          return {
            ...programDay,
            program_day_contents: programDay.program_day_contents.map(programDayContent => {
              if (programDayContent.program_day_content_id === currentProgramDayContentId) {
                return {
                  ...programDayContent,
                  partner_client_id: partnerIdSelected,
                  guide_id: contactIdSelected,
                };
              }

              return programDayContent;
            }),
          };
        }

        return programDay;
      });

      // update program day activities
      dispatch(
        setProgramDayActivities({
          ...programDayActivities,
          guides: get(programUpdated, "guides", []),
          program_days: program_days_updated,
        }),
      );

      // update list guides
      const listGuidesUpdate = listGuides.map(guide => {
        if (guide.program_id === programDayActivities.program_id) {
          return {
            ...guide,
            guides: get(programUpdated, "guides", []),
          };
        }
        return guide;
      });

      // update guide schedules
      dispatch(setGuideSchedules(listGuidesUpdate));

      // set all partner search selection to null
      dispatch(setPartnerSelected(null));
      dispatch(setPartnerContactSelected(null));

      // close partner search dialog
      handleOnDialogClose();
    } else {
      toastr.error("Greška u ažuriranju, pokušajte ponovno");
    }
  }, [
    partnerIdSelected,
    updateProgramDayActivities,
    currentProgramDayContentId,
    contactIdSelected,
    programDayActivities,
    dispatch,
    listGuides,
    handleOnDialogClose,
    currentProgramDayId,
  ]);

  return {
    handleOnDialogClose,
    isDialogPartnerSearchOpen,
    loadingUpdatePartnerClient,
    handleOnPartnerSearchSave,
    listPartnerToShow,
  };
}
