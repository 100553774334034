import React, { Component } from "react";
import DateRangePicker from "../../../../components/UI/DateRangePicker";
import { connect } from "react-redux";
import { formatDate } from "../../../../utility/dates";
import moment from "moment";
import AlotmanTerminsList from "./AlotmanTerminsList";
import CommentTextArea from "../../../../components/UI/CommentTextArea";
import {
  changeEditData,
  addAlotmanTermin,
  setAddedAlotmanTermin,
  addAlotmanContigent,
} from "../../../../store/actions";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import { flowRight as compose } from "lodash";
import { prepareForSending } from "../../../../utility/prepare";
import { createTermin, createContigentOffer } from "../../../../graphql/mutation/offers";

import toastr from "toastr";
import { listAlotmanDayByTypology } from "../../../../graphql/query/alotman";
import { formatFromFilterDate, formatToFilterDate } from "../../../../utility/globals";

const mapStateToProps = state => {
  const {
    typology_id,
    typologies_sum,
    offer: {
      offer_contract_document: { id: offer_contract_document_id, termin_periods, comment_term_period, release_day },
    },
  } = state.offer;

  return {
    typology_id,
    typologies_sum,
    termin_periods,
    offer_contract_document_id,
    comment_term_period,
    release_day,
  };
};

const availableStateStyling = foundDay => {
  return (
    foundDay && {
      position: "absolute",
      fontSize: "12px",
      right: 1,
      bottom: -14,
      color: "white",
      padding: 3,
      background: foundDay.stop_booking ? "red" : "green",
      width: "auto",
      height: 12,
      borderRadius: "50%",
    }
  );
};

export default compose(
  graphql(createTermin, { name: "createTermin" }),
  graphql(createContigentOffer, { name: "createContigent" }),
)(
  connect(mapStateToProps, { changeEditData, addAlotmanTermin, setAddedAlotmanTermin, addAlotmanContigent })(
    withRouter(
      withApollo(
        class AlotmanTermin extends Component {
          state = {
            from: null,
            to: null,
            blockedDaysMessage: null,
            termins: [],
          };

          changeEditData = ({ key, value }) => {
            this.setState({
              [key]: value,
            });
          };

          renderDatePickerDay = day => {
            const { termins } = this.state;
            const foundDay = termins.find(termin => formatDate(termin.date) === formatDate(day));

            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <div>{day.format("D")}</div>
                <div style={availableStateStyling(foundDay)}>{foundDay && foundDay.available_state}</div>
              </div>
            );
          };

          isDayBlocked = day => {
            const { termins } = this.state;
            const terminFound = termins.find(termin => formatDate(termin.date) === formatDate(day));

            // termin inside inserted days
            if (terminFound) {
              return !terminFound;
            }

            // look if day is one after termin days ( it can be selected then because it will look only day until then )
            const dayBefore = moment(day)
              .clone()
              .subtract(1, "d");

            return !termins.find(termin => formatDate(termin.date) === formatDate(dayBefore));
          };

          async blockedDates(start, end) {
            try {
              const from = formatFromFilterDate(start).startOf("M");
              const to = formatToFilterDate(end).endOf("M");

              const { typology_id } = this.props;

              const response = await this.props.client.query({
                query: listAlotmanDayByTypology,
                fetchPolicy: "network-only",
                variables: {
                  typology_id,
                  from,
                  to,
                },
              });

              const terminsTOCheck = response.data.listAlotmanDayByTypology;

              const diff = moment(end).diff(start, "days");

              for (let i = 0; i < diff; i++) {
                const checkDate = formatDate(moment(start).add(i, "d"));

                if (!terminsTOCheck.find(day => formatDate(day.date) === checkDate)) {
                  return true;
                }
              }
              return false;
            } catch (error) {}
          }

          terminWithTypology = () => {
            const terminFound = this.props.termin_periods.find(
              termin =>
                formatDate(termin.from) === formatDate(this.state.from) &&
                formatDate(termin.to) === formatDate(this.state.to),
            );
            if (!terminFound) {
              return false;
            }

            const typologyFound = terminFound.contigents.find(
              ({ typology_id }) => Number(typology_id) === Number(this.props.typology_id),
            );
            if (!typologyFound) {
              return false;
            }

            return true;
          };

          createModeTerminAdd = () => {
            this.props.changeEditData({
              key: "from",
              value: this.state.from,
            });

            this.props.changeEditData({
              key: "to",
              value: this.state.to,
            });

            this.setState(
              {
                from: null,
                to: null,
                blockedDaysMessage: null,
              },
              () => {
                this.props.addAlotmanTermin();
              },
            );
          };

          editModeTerminAdd = async () => {
            const terminFound = this.props.termin_periods.find(
              termin =>
                formatDate(termin.from) === formatDate(this.state.from) &&
                formatDate(termin.to) === formatDate(this.state.to),
            );

            if (terminFound) {
              // Create new contigent with termin_id and store to termin in redux store
              this.createNewContigent(terminFound);
            } else {
              // Create new termin with contigent and store to redux store
              this.createNewTermin();
            }
          };

          createNewContigent = async terminFound => {
            const { typologies_sum, typology_id } = this.props;

            try {
              const response = await this.props.createContigent({
                variables: {
                  typologies_sum,
                  typology_id,
                  termin_period_id: terminFound.id,
                },
              });

              const contigent = response.data.createContigent;

              this.setState(
                {
                  from: null,
                  to: null,
                  blockedDaysMessage: null,
                },
                // change props after clearing state
                () => {
                  this.props.addAlotmanContigent({
                    ...terminFound,
                    contigents: [...terminFound.contigents, contigent],
                  });
                },
              );

              toastr.success("Kontigent kreiran");
            } catch (error) {}
          };

          createNewTermin = async () => {
            const { from, to } = this.state;

            const { typologies_sum, typology_id, offer_contract_document_id } = this.props;

            try {
              const response = await this.props.createTermin({
                variables: {
                  to,
                  from,
                  transport: false,
                  price_structure: {
                    tourist_tax: "0",
                    comment_price_structure: "",
                  },
                  contigents: [
                    {
                      typologies_sum,
                      typology_id,
                    },
                  ],
                  offer_contract_document_id,
                },
              });

              const termin = response.data.createTerminPeriod;

              this.setState(
                {
                  from: null,
                  to: null,
                  blockedDaysMessage: null,
                },
                () => {
                  this.props.setAddedAlotmanTermin(prepareForSending(termin, ["__typename"]));
                },
              );

              toastr.success("Termin kreiran");
            } catch (error) {}
          };

          async fetchAlotmanDays(
            from = formatFromFilterDate().startOf("M"),
            to = formatToFilterDate()
              .add(1, "M")
              .endOf("M"),
          ) {
            const { typology_id } = this.props;

            try {
              const response = await this.props.client.query({
                query: listAlotmanDayByTypology,
                variables: {
                  typology_id,
                  from,
                  to,
                },
              });

              this.setState({ termins: response.data.listAlotmanDayByTypology });
            } catch (error) {}
          }

          fetchedOnCDU = false;

          async componentDidUpdate(prevProps, prevState) {
            if (this.props.typology_id && this.props.typologies_sum) {
              if (!this.fetchedOnCDU) {
                this.fetchAlotmanDays();
                this.fetchedOnCDU = true;
              }
            }

            if (
              prevProps.typology_id !== this.props.typology_id &&
              this.props.typology_id &&
              this.props.typologies_sum
            ) {
              this.fetchAlotmanDays();
            }

            if (!this.props.typology_id && !this.props.typologies_sum) {
              if (this.fetchedOnCDU) {
                this.fetchedOnCDU = false;
              }
            }

            if (this.state.from && this.state.to) {
              // there is termin with selected typology
              if (this.terminWithTypology()) {
                return this.setState({
                  from: null,
                  to: null,
                  blockedDaysMessage: "Već postoji termin sa odabranom tipologijom",
                });
              }

              // there are blocked days in selected range
              if (await this.blockedDates(this.state.from, this.state.to)) {
                this.setState({
                  from: null,
                  to: null,
                  blockedDaysMessage: "U odabranom razdoblju postoje dani koji su blokirani",
                });
              }
              // no blocked dates so store this termin
              else {
                // if in edit mode
                if (this.props.location.pathname.includes("edit")) {
                  this.editModeTerminAdd();
                }
                // if in create mode
                else {
                  this.createModeTerminAdd();
                }
              }
            }
          }

          datesChange = newDate => {
            // Fetch new all getAlotmanDays from first day of newDate to newDate + 1 month

            let start = null;
            let end = null;
            if (this.state.from) {
              const clonedDate = formatFromFilterDate(this.state.from);
              const clonedNewDate = newDate.clone();

              start = clonedDate.clone().startOf("M");

              if (clonedNewDate.isAfter(this.state.from, "year") || clonedNewDate.isAfter(this.state.from, "month")) {
                end = formatToFilterDate(clonedNewDate)
                  .add(1, "M")
                  .endOf("M");
              } else {
                end = formatToFilterDate(clonedDate)
                  .add(1, "M")
                  .endOf("M");
              }
            } else {
              const clonedDate = newDate.clone();
              start = formatFromFilterDate(clonedDate).startOf("M");
              end = formatToFilterDate(clonedDate)
                .add(1, "M")
                .endOf("M");
            }

            this.fetchAlotmanDays(start, end);
          };

          render() {
            let isDisabledCalendar = false;
            if (!this.props.typology_id || !this.props.typologies_sum) {
              isDisabledCalendar = true;
            }

            return (
              <div className="form-unit-double">
                <div className="field-label is-autocomplete">
                  <label className="label">Termin</label>
                </div>
                <div>
                  <DateRangePicker
                    showClearDates
                    disabled={isDisabledCalendar}
                    daySize={50}
                    renderDayContents={this.renderDatePickerDay}
                    // Rerender function to update styling
                    isDayBlocked={date => this.isDayBlocked(date)}
                    from={this.state.from}
                    to={this.state.to}
                    handleFromChange={date =>
                      this.changeEditData({
                        key: "from",
                        value: date,
                      })
                    }
                    handleToChange={date =>
                      this.changeEditData({
                        key: "to",
                        value: date,
                      })
                    }
                    onPrevMonthClick={this.datesChange}
                    onNextMonthClick={this.datesChange}
                    onSelectMonthYearChange={this.datesChange}
                  />
                  <br />
                  {this.state.blockedDaysMessage && <div style={{ color: "red" }}>{this.state.blockedDaysMessage}</div>}
                  {this.state.blockedDaysMessage && <br />}

                  <CommentTextArea
                    name="release_day"
                    defaultValue={this.props.release_day}
                    label="Release day"
                    inputProps={{ disabled: this.props.isNotDraft }}
                  />
                  <br />

                  {/* Selected termins */}
                  <AlotmanTerminsList
                    daySize={50}
                    renderDayContents={this.renderDatePickerDay}
                    isDayBlocked={this.isDayBlocked}
                    blockedDates={this.blockedDates}
                    listEstablishment={this.props.listEstablishment}
                    listEstablishmentQuery={this.props.listEstablishmentQuery}
                  />

                  <br />
                  {/* Termini komentar */}
                  <CommentTextArea
                    name="comment_term_period"
                    defaultValue={this.props.comment_term_period}
                    label="Komentar termina"
                    inputProps={{ disabled: this.props.isNotDraft }}
                  />
                </div>
              </div>
            );
          }
        },
      ),
    ),
  ),
);
