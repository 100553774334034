import React from "react";

import { connect } from "react-redux";

import { setActivitiesForAutocomplete, findActivityTranslationLanguageIndex } from "../../../../utility/activities";

import {
  changeProgramDayContent,
  changeProgramDayDescription,
  changeEditData,
  changeProgramDayTitle,
} from "../../../../store/actions";
import { setPartnerServicesForAutocomplete, findServiceTranslationLanguageIndex } from "../../../../utility/partner";
import DailyProgramPagination from "./DailyProgramPagination";
import { config } from "../../../../utility/globals";

// Make function which manipulates with ids
const setActivitiesIdForAutocomplete = activities =>
  activities &&
  activities.map(activity => {
    return {
      ...activity,
      value: `activity_id-${activity.id}`,
    };
  });

// Make function which manipulates with ids
const setPartnerServicesIdForAutocomplete = services =>
  services &&
  services.map(service => {
    return {
      ...service,
      value: `partner_client_service_id-${service.id}`,
    };
  });
class DailyProgram extends React.Component {
  shouldComponentUpdate(nextProps) {
    const checkIfNotPO =
      config.offer_type[this.props.offer_contract_document_type_id] !== "Program only" &&
      ((!this.props.termin_periods && nextProps.termin_periods) ||
        (this.props.termin_periods && !nextProps.termin_periods) ||
        (nextProps.termin_periods[0] &&
          this.props.termin_periods[0] &&
          nextProps.termin_periods[0].from !== this.props.termin_periods[0].from) ||
        (nextProps.termin_periods[0] &&
          this.props.termin_periods[0] &&
          nextProps.termin_periods[0].to !== this.props.termin_periods[0].to));

    if (
      nextProps.program_days !== this.props.program_days ||
      checkIfNotPO ||
      nextProps.language_id !== this.props.language_id ||
      nextProps.isNotDraft !== this.props.isNotDraft
    ) {
      return true;
    }

    return false;
  }

  setProgramAsEmpty(dayId, daily_contentId) {
    const { changeProgramDayContent } = this.props;

    changeProgramDayContent(dayId, daily_contentId, "activity_id", "", "");
    changeProgramDayContent(dayId, daily_contentId, "partner_client_service_id", "", "");
  }

  selectContent = (dayId, daily_contentId, selected) => {
    const { language_id, changeProgramDayContent } = this.props;
    // Do this for removing

    if (!selected) {
      return this.setProgramAsEmpty(dayId, daily_contentId);
    }

    const [key, id] = selected.split("-");

    let value = "";

    if (key === "activity_id") {
      const activity = this.props.listActivity.find(activity => activity.id === id);
      const { activity_translations } = activity;
      const translationId = findActivityTranslationLanguageIndex(activity_translations, language_id);

      value = translationId !== -1 ? activity_translations[translationId].desc : "";
    } else if (key === "partner_client_service_id") {
      const ps = this.props.listPartnerClientService.find(ps => ps.id === id);
      const { partner_client_service_translations } = ps;

      const translationId = findServiceTranslationLanguageIndex(partner_client_service_translations, language_id);
      value = translationId !== -1 ? partner_client_service_translations[translationId].description : "";
    }

    changeProgramDayContent(dayId, daily_contentId, key, id, value);
  };

  render() {
    const { listPartnerClientService, program_days, language_id, listActivity, isNotDraft } = this.props;

    if (!program_days) {
      return <div>Čekam na dodavanje termina ...</div>;
    }

    if (!language_id) {
      return <div>Odaberite jezik . . . </div>;
    }

    const activitiesToShow = setActivitiesIdForAutocomplete(
      setActivitiesForAutocomplete(listActivity, language_id || 1),
    );

    const servicesToShow = setPartnerServicesIdForAutocomplete(
      setPartnerServicesForAutocomplete(listPartnerClientService, language_id),
    );

    const selectToShow = activitiesToShow && servicesToShow && activitiesToShow.concat(servicesToShow);

    return (
      <DailyProgramPagination
        program_days={program_days}
        selectToShow={selectToShow}
        selectContent={this.selectContent}
        isNotDraft={isNotDraft}
        {...this.props}
      />
    );
  }
}

export default connect(
  state => {
    const {
      offer: {
        offer_contract_document: { language_id, offer_contract_document_type_id },
      },
    } = state.offer;
    return { language_id, offer_contract_document_type_id };
  },
  {
    changeProgramDayContent,
    changeProgramDayDescription,
    changeEditData,
    changeProgramDayTitle,
  },
)(DailyProgram);
