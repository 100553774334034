import React from "react";

// libs
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// constants
import { MARGIN_CONTAINER_DOUBLE_TOP, FONT_SIZE_SUB_VALUE } from "../utils/constants";

// Create styles
const styles = StyleSheet.create({
  // container
  container: {
    marginTop: MARGIN_CONTAINER_DOUBLE_TOP,
  },

  text: {
    fontSize: FONT_SIZE_SUB_VALUE,
    fontFamily: "OpenSans",
  },
});

// VoucherUserDetails
export default function VoucherUserDetails({ createdByText, firstName, lastName }) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{createdByText}</Text>
      <Text style={styles.text}>{`${firstName} ${lastName}`}</Text>
      <Text style={styles.text}>{"Putnička agencija Olivari d.o.o"}</Text>

      <Text style={styles.text}>{" Tel: 00385 – 91 – 6548274 (00385 - 91 - OLIVARI)"}</Text>
    </View>
  );
}
