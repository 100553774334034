// libs
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// redux state
import { selectGuideScheduleDrawerPayload, setGuideDrawerPayload } from "src/views/Guides/guidesSlice";

/**
 * useBusTransferContainer is entry point of data for busTransferContainer component
 */
export default function useBusTransferContainer() {
  // redux state
  const guidePayload = useSelector(selectGuideScheduleDrawerPayload);

  const dispatch = useDispatch();

  /**
   * handle on bus transfer status change
   */
  const handleOnBusTransferStatusChange = async status => {
    dispatch(setGuideDrawerPayload({ ...guidePayload, transportStatus: status }));
  };

  return {
    handleOnBusTransferStatusChange,
    transportStatus: get(guidePayload, "transportStatus", false),
  };
}
