import React from "react";
import Tooltip from "src/components/UI/Tooltip";

export const programIdCell = ({ program_id }) => (
  <Tooltip title={program_id || " "}>
    <div className="ReactTableFirstColumnDiv">{program_id || ""}</div>
  </Tooltip>
);

export const groupStatusCell = ({ group_operativa_status }) => (
  <Tooltip title={group_operativa_status || " "}>
    <div className="ReactTableFirstColumnDiv">{group_operativa_status || ""}</div>
  </Tooltip>
);

export const commentLayoutCell = ({ guide_layout_comment }) => (
  <Tooltip title={guide_layout_comment || " "}>
    <div className="ReactTableFirstColumnDiv">{guide_layout_comment || ""}</div>
  </Tooltip>
);

export const guideNoticesCell = ({ guide_notices }) => (
  <Tooltip title={guide_notices || " "}>
    <div className="ReactTableFirstColumnDiv">{guide_notices || ""}</div>
  </Tooltip>
);

export const busTransferCell = ({ transport }) => (
  <Tooltip title={transport ? "Da" : "Ne"}>
    <div className="ReactTableFirstColumnDiv">{transport ? "Da" : "Ne"}</div>
  </Tooltip>
);

export const paymentCell = ({ payment }) => (
  <Tooltip title={payment || " "}>
    <div className="ReactTableFirstColumnDiv">{payment || ""}</div>
  </Tooltip>
);

export const catalogCell = ({ catalog }) => (
  <Tooltip title={catalog || " "}>
    <div className="ReactTableFirstColumnDiv">{catalog || ""}</div>
  </Tooltip>
);
