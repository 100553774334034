import React, { Component } from "react";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import toastr from "toastr";
import gql from "graphql-tag";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";

import ReactTableCustom from "../../../../../components/UI/ReactTable";

import { changeEditData, removeTermin, removeSubtermin, toggleEditSubtermin } from "../../../../../store/actions";
import { dateDiff, formatDate } from "../../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import { Paper, FormHelperText } from "@material-ui/core";
import Subtermin from "./Subtermin";
import OfferForm from "../../OfferForm";
import { withRouter } from "react-router-dom";
import CommentTextArea from "../../../../../components/UI/CommentTextArea";
import { establishmentContigentsAndHotels } from "../../../../../utility/establishment";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
  subterminContainer: {
    padding: "24px 10px",
  },
});

class SubterminList extends Component {
  deleteTermin = id => async () => {
    // if we're in edit mode
    if (this.props.match.params.id) {
      try {
        const response = await this.props.deleteTerminPeriod({
          variables: {
            id,
          },
        });

        toastr.success("Uspješno izbrisan podtermin");
        this.props.removeTermin(response.data.deleteTerminPeriod);
      } catch (error) {}
    } else {
      this.props.removeTermin(id);
    }
  };

  subterminRemove = id => async () => {
    // if we're in edit mode
    if (this.props.match.params.id) {
      try {
        const response = await this.props.deleteTerminPeriod({
          variables: {
            id,
          },
        });

        toastr.success("Uspješno izbrisan podtermin");
        this.props.removeSubtermin(response.data.deleteTerminPeriod);
      } catch (error) {}
    } else {
      this.props.removeSubtermin(id);
    }
  };

  toggleEditSubtermin(id) {
    return () => this.props.toggleEditSubtermin(id);
  }

  render() {
    if (this.props.offer_contract_document_type_id !== "2") {
      return null;
    }

    const { termin, listEstablishment, classes, isNotDraft } = this.props;

    const mainTerminToShow = termin
      ? [
          {
            ...termin,
            from: formatDate(termin.from),
            to: formatDate(termin.to),
            diff: dateDiff(termin.from, termin.to, "day"),
          },
        ]
      : [];

    return (
      <OfferForm.Consumer>
        {({ error, errorMsg }) => (
          <div>
            {error("/termin_periods") && (
              <FormHelperText error={error("/termin_periods")}>{errorMsg("/termin_periods")}</FormHelperText>
            )}
            <Paper
              elevation={1}
              //style={{ minHeight: "550px" }}
              className={error("/termin_periods") ? "textarea-error" : ""}
            >
              <ReactTableCustom
                data={mainTerminToShow}
                columns={[
                  {
                    Header: "TERMIN",
                    columns: [
                      {
                        Header: "Od",
                        accessor: "from",
                      },
                      {
                        Header: "Do",
                        accessor: "to",
                      },
                      {
                        Header: "Dana",
                        accessor: "diff",
                        width: 70,
                      },
                      {
                        Header: "",
                        id: "contigents_list",
                      },
                      {
                        Header: "",
                        accessor: "establishment_name",
                      },
                      {
                        Header: "Brisanje",
                        id: "delete",
                        accessor: termin =>
                          !isNotDraft ? (
                            <DeleteForeverIcon
                              className={this.props.classes.icon}
                              onClick={this.deleteTermin(termin.id)}
                            />
                          ) : null,
                      },
                    ],
                  },
                ]}
                innerProps={{
                  pageSize: mainTerminToShow.length,
                  showPagination: false,
                  sortable: false,
                  filterable: false,
                  filterAll: false,
                  noDataText: "Nema dodanih termina",
                  expanded: { 0: true },
                  // Loading subtermin
                  SubComponent: ({ original: { subtermin_periods } }) => {
                    const dataToShow =
                      subtermin_periods && subtermin_periods.length
                        ? [...subtermin_periods]
                            .sort(function(a, b) {
                              return new Date(a.from) - new Date(b.from);
                            })
                            .map(termin => {
                              const { contigents } = termin;
                              const {
                                establishment_names: establishment_name,
                                contigent_lists: contigents_list,
                              } = establishmentContigentsAndHotels(contigents);

                              return {
                                ...termin,
                                from: formatDate(termin.from),
                                to: formatDate(termin.to),
                                diff: dateDiff(termin.from, termin.to, "day"),
                                contigents_list,
                                establishment_name,
                              };
                            })
                        : [];

                    return (
                      <div className="subtermin-create-termin">
                        <div>
                          {!isNotDraft ? (
                            <div className={classes.subterminContainer}>
                              <Subtermin
                                listEstablishment={listEstablishment}
                                listEstablishmentQuery={this.props.listEstablishmentQuery}
                              />
                            </div>
                          ) : null}

                          <div className={classes.subterminContainer}>
                            <CommentTextArea
                              name="comment_establishment"
                              defaultValue={this.props.comment_establishment}
                              label="Komentar na smještaj"
                              inputProps={{ disabled: isNotDraft }}
                            />
                          </div>
                          <div className={classes.subterminContainer}>
                            <CommentTextArea
                              name="comment_contigent"
                              defaultValue={this.props.comment_contigent}
                              label="Komentar kontigenta"
                              inputProps={{ disabled: isNotDraft }}
                            />
                          </div>
                        </div>

                        <div className={classes.subterminContainer}>
                          {error("/termin_periods/0/subtermin_periods") && (
                            <FormHelperText error={error("/termin_periods/0/subtermin_periods")}>
                              {errorMsg("/termin_periods/0/subtermin_periods")}
                            </FormHelperText>
                          )}
                          <Paper
                            elevation={1}
                            className={error("/termin_periods/0/subtermin_periods") ? "textarea-error" : ""}
                          >
                            <ReactTableCustom
                              data={dataToShow}
                              columns={[
                                {
                                  Header: "PODTERMINI",
                                  columns: [
                                    {
                                      Header: "Od",
                                      accessor: "from",
                                    },
                                    {
                                      Header: "Do",
                                      accessor: "to",
                                    },
                                    {
                                      Header: "Dana",
                                      accessor: "diff",
                                      width: 70,
                                    },
                                    {
                                      Header: "Kontigent",
                                      id: "contigents_list",
                                      accessor: d => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "0 auto",
                                          }}
                                        >
                                          {d.contigents_list}
                                        </div>
                                      ),
                                      width: 150,
                                    },
                                    {
                                      Header: "Smještaj",
                                      id: "establishment_name",
                                      accessor: d => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "0 auto",
                                          }}
                                        >
                                          {d.establishment_name}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: "Ažuriraj",
                                      id: "edit",
                                      accessor: termin =>
                                        !isNotDraft ? (
                                          <EditIcon
                                            className={this.props.classes.icon}
                                            onClick={this.toggleEditSubtermin(termin.id)}
                                          />
                                        ) : null,
                                    },
                                    {
                                      Header: "Brisanje",
                                      id: "delete",
                                      accessor: termin =>
                                        !isNotDraft ? (
                                          <DeleteForeverIcon
                                            className={this.props.classes.icon}
                                            onClick={this.subterminRemove(termin.id)}
                                          />
                                        ) : null,
                                    },
                                  ],
                                },
                              ]}
                              innerProps={{
                                pageSize: dataToShow.length,
                                showPagination: false,
                                sortable: false,
                                filterable: false,
                                filterAll: false,
                                noDataText: "Nema dodanih podtermina",
                              }}
                            />
                          </Paper>
                        </div>
                      </div>
                    );
                  },
                }}
              />
            </Paper>
          </div>
        )}
      </OfferForm.Consumer>
    );
  }
}

const deleteTerminPeriod = gql`
  mutation($id: ID!) {
    deleteTerminPeriod(id: $id)
  }
`;

const mapStateToProps = state => {
  const {
    termin_periods,
    offer_contract_document_type_id,
    language_id,
    comment_establishment,
    comment_contigent,
  } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termin: termin_periods[0],
    create_subtermin,
    edit_subtermin,
    offer_contract_document_type_id,
    language_id,
    comment_establishment,
    comment_contigent,
  };
};

export default graphql(deleteTerminPeriod, { name: "deleteTerminPeriod" })(
  withStyles(styles)(
    connect(
      mapStateToProps,
      { changeEditData, removeTermin, removeSubtermin, toggleEditSubtermin },
    )(withRouter(SubterminList)),
  ),
);
