import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import toastr from "toastr";
import FormValidator from "../../../components/UI/FormValidator";
import { createTypologyGroup } from "../../../graphql/mutation/administration";
import { AdministrationTabs } from "../AdministrationTabs";
import TypologyGroupForm from "./TypologyGroupForm";
import TableTitle from "src/components/UI/TableTitle";

class CreateTypologyGroup extends React.Component {
  static schema = {
    required: ["name"],
    properties: {
      name: {
        type: "string",
        minLength: 1,
        isNotEmpty: true,
        errorMessage: "Polje mora biti ispunjeno",
      },
    },
  };

  state = {
    name: "",
  };

  onChangeData = key => ({ target: { value } }) => {
    this.setState({
      [key]: value,
    });
  };

  submit = async () => {
    try {
      await this.props.mutate({
        variables: {
          ...this.state,
        },
      });

      toastr.success("Tipologija grupe uspješno kreirana");
      this.props.history.push("/administracija/grupa_tipologije");
    } catch (error) {}
  };

  render() {
    return (
      <Fragment>
        <TableTitle
          title="Kreiranje tipologije"
          onBackPressed={() => {
            if (this.props.location.pathname.includes("administracija")) {
              this.props.history.push("/administracija/grupa_tipologije/");
            } else {
              this.props.history.goBack();
            }
          }}
        />
        <FormValidator schema={CreateTypologyGroup.schema} submit={this.submit} data={this.state}>
          {({ error, errorMsg, submit }) => (
            <TypologyGroupForm
              onSubmit={submit}
              error={error}
              errorMsg={errorMsg}
              {...this.state}
              onChangeData={this.onChangeData}
            />
          )}
        </FormValidator>
      </Fragment>
    );
  }
}

export default AdministrationTabs(
  compose(graphql(createTypologyGroup))(CreateTypologyGroup),
  "Kreiranje grupe tipologije",
);
