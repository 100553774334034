import React, { Component } from "react";
import { connect } from "react-redux";

import ReactTableCustom from "../../../../../components/UI/ReactTable";

import { changeEditData, removeTermin, removeSubtermin, toggleEditSubtermin } from "../../../../../store/actions";
import { dateDiff, formatDate } from "../../../../../utility/dates";

import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { establishmentContigentsAndHotels } from "../../../../../utility/establishment";

const styles = () => ({
  icon: {
    cursor: "pointer",
    margin: "0 auto",
  },
  subterminContainer: {
    padding: "24px 10px",
  },
});

class SubterminList extends Component {
  render() {
    if (this.props.offer_contract_document_type_id !== "2") {
      return null;
    }

    const { termin, classes } = this.props;

    const mainTerminToShow = termin
      ? [
          {
            ...termin,
            from: formatDate(termin.from),
            to: formatDate(termin.to),
            diff: dateDiff(termin.from, termin.to, "day"),
          },
        ]
      : [];

    return (
      <div className="cols-2">
        <Paper elevation={1}>
          <ReactTableCustom
            data={mainTerminToShow}
            columns={[
              {
                Header: "TERMIN",
                columns: [
                  {
                    Header: "Od",
                    accessor: "from",
                  },
                  {
                    Header: "Do",
                    accessor: "to",
                  },
                  {
                    Header: "Dana",
                    accessor: "diff",
                    width: 70,
                  },
                  {
                    Header: "",
                    id: "contigents_list",
                  },
                  {
                    Header: "",
                    accessor: "establishment_name",
                  },
                ],
              },
            ]}
            innerProps={{
              pageSize: mainTerminToShow.length,
              showPagination: false,
              sortable: false,
              filterable: false,
              filterAll: false,
              noDataText: "Nema dodanih termina",
              expanded: { 0: true },
              // Loading subtermin
              SubComponent: ({ original: { subtermin_periods } }) => {
                const dataToShow =
                  subtermin_periods && subtermin_periods.length
                    ? [...subtermin_periods]
                        .sort(function(a, b) {
                          return new Date(a.from) - new Date(b.from);
                        })
                        .map(termin => {
                          const { contigents } = termin;
                          const {
                            establishment_names: establishment_name,
                            contigent_lists: contigents_list,
                          } = establishmentContigentsAndHotels(contigents);

                          return {
                            ...termin,
                            from: formatDate(termin.from),
                            to: formatDate(termin.to),
                            diff: dateDiff(termin.from, termin.to, "day"),
                            contigents_list,
                            establishment_name,
                          };
                        })
                    : [];

                return (
                  <div className={classes.subterminContainer}>
                    <ReactTableCustom
                      data={dataToShow}
                      columns={[
                        {
                          Header: "PODTERMINI",
                          columns: [
                            {
                              Header: "Od",
                              accessor: "from",
                            },
                            {
                              Header: "Do",
                              accessor: "to",
                            },
                            {
                              Header: "Dana",
                              accessor: "diff",
                              width: 70,
                            },
                            {
                              Header: "Kontigent",
                              id: "contigents_list",
                              accessor: d => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "0 auto",
                                  }}
                                >
                                  {d.contigents_list}
                                </div>
                              ),
                              width: 150,
                            },
                            {
                              Header: "Smještaj",
                              id: "establishment_name",
                              accessor: d => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "0 auto",
                                  }}
                                >
                                  {d.establishment_name}
                                </div>
                              ),
                            },
                          ],
                        },
                      ]}
                      innerProps={{
                        pageSize: dataToShow.length,
                        showPagination: false,
                        sortable: false,
                        filterable: false,
                        filterAll: false,
                        noDataText: "Nema dodanih podtermina",
                      }}
                    />
                  </div>
                );
              },
            }}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { termin_periods, offer_contract_document_type_id, language_id } = state.offer.offer.offer_contract_document;

  const { create_subtermin, edit_subtermin } = state.offer;

  return {
    termin: termin_periods[0],
    create_subtermin,
    edit_subtermin,
    offer_contract_document_type_id,
    language_id,
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { changeEditData, removeTermin, removeSubtermin, toggleEditSubtermin },
  )(SubterminList),
);
