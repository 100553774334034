import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  formControl: {
    width: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class TrueFalseSelect extends React.Component {
  handleChange = ({ target: { name, value } }) => {
    // select has true and false as strings, but we save and get them as booleans
    // use returnBoolean prop to indicate TrueFalseSelect will return true/false as booleans instead of strings
    const { returnBoolean } = this.props;

    let valueToSave = value;

    if (value === "true") {
      valueToSave = true;
    } else if (value === "false") {
      valueToSave = false;
    }

    this.props.changed({
      key: name,
      value: returnBoolean ? valueToSave : value,
    });
  };

  render() {
    const { classes, label, value } = this.props;

    // if we get boolean values, convert them to strings
    let selectedValue = value;

    if (typeof value === "boolean") {
      if (value) {
        selectedValue = "true";
      } else {
        selectedValue = "false";
      }
    }

    return (
      <FormControl className={classes.formControl}>
        {label ? <InputLabel shrink={true}>{label}</InputLabel> : null}
        <Select value={selectedValue} onChange={this.handleChange} inputProps={{ ...this.props.inputProps }}>
          <MenuItem value="" />
          <MenuItem value="true">Da</MenuItem>
          <MenuItem value="false">Ne</MenuItem>
        </Select>
      </FormControl>
    );
  }
}
export default withStyles(styles)(TrueFalseSelect);
