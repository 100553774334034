import React, { Component } from "react";
import { graphql } from "react-apollo";
import toastr from "toastr";

import DrawerWrapper from "../../../../components/UI/Drawer";

import { updateTerminPeriod } from "../../../../graphql/mutation/booking";
import CommentTextArea from "../../../../components/UI/CommentTextArea";

class DeliveredStateDrawer extends Component {
  state = {
    delivered_state: ""
  };

  updateData = () => {
    const { delivered_state } = this.state;
    if (delivered_state !== this.props.delivered_state) {
      try {
        this.props.mutate({
          variables: {
            id: this.props.id,
            patch: {
              delivered_state
            }
          }
        });

        toastr.success("Uspješno ažurirano");
      } catch (error) {}
    }
  };

  componentDidMount = () => {
    const { delivered_state } = this.props;

    this.setState({ delivered_state });
  };

  render() {
    const { toggle, open } = this.props;

    return (
      <DrawerWrapper
        toggle={toggle}
        update={this.updateData}
        open={open}
        title="Dostavljeno stanje"
      >
        <CommentTextArea
          defaultValue={this.state.delivered_state}
          label="Dostavljeno stanje"
          customOnChange={({ target: { value } }) =>
            this.setState({ delivered_state: value })
          }
        />
      </DrawerWrapper>
    );
  }
}

export default graphql(updateTerminPeriod)(DeliveredStateDrawer);
