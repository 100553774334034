import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NavigationClose from "@material-ui/icons/Close";
import React from "react";
import FixedCircularProgress from "./FixedCircularProgress";

function DrawerWrapper({ children, open, toggle, isLoading, isSaveVisible, onSave, title, update, customWidth }) {
  // if custom width is not set up, default is 33%
  let drawerWidth = customWidth ? customWidth : "33%";

  const style = {
    width: drawerWidth,
  };

  return (
    <Drawer PaperProps={{ style: style }} anchor="right" open={open} onClose={toggle}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="title" color="inherit" style={{ flexGrow: 1 }}>
            {title}
          </Typography>

          {isLoading && <FixedCircularProgress color="inherit" size={18} thickness={6} />}

          {isSaveVisible && (
            <Button onClick={onSave} color="inherit">
              Spremi
            </Button>
          )}

          <IconButton onClick={toggle} color="inherit">
            <NavigationClose />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="container-area">
        <div className=" form-control-grid">{children}</div>
        {update ? (
          <div className="flex-right margin-top-60">
            <Button variant="contained" onClick={update}>
              Spremi
            </Button>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
}

export default DrawerWrapper;
