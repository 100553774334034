import React from "react";
import { Typography } from "@material-ui/core";

export const gratis = d => {
  if (d.gratis.gratis === "true") {
    return "Da";
  } else if (d.gratis.gratis === "false") {
    return "Ne";
  } else {
    return "";
  }
};

export const calclulatorActive = calc => {
  if (calc.isCommercialActive) {
    return (
      <Typography variant="body2" color="error" gutterBottom>
        Aktivan komercijala
      </Typography>
    );
  } else if (calc.isBookingActive) {
    return (
      <Typography variant="body2" color="error" gutterBottom>
        Aktivan booking
      </Typography>
    );
  }

  return null;
};
