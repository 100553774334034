import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import FormValidator from "../../../../components/UI/FormValidator";
import { FormHelperText } from "@material-ui/core";

const form = {
  padding: "24px"
};

const ActivityTypeForm = ({
  title,
  activityType,
  onChangeField,
  onSubmit,
  cancelToggle
}) => {
  return (
    <FormValidator
      schema={ActivityTypeForm.schema}
      submit={onSubmit}
      data={activityType}
    >
      {({ error, errorMsg, submit }) => (
        <div style={form}>
          <h3>{title}</h3>
          <div className="form-control-grid">
            <FormControl fullWidth error={error("/desc")}>
              <InputLabel shrink={true} htmlFor="desc">
                Tip aktivnosti
              </InputLabel>
              <Input
                id="desc"
                name="desc"
                value={activityType.desc ? activityType.desc : ""}
                onChange={({ target: { value } }) =>
                  onChangeField({ key: "desc", value })
                }
              />
              {error("/desc") && (
                <FormHelperText>{errorMsg("/desc")}</FormHelperText>
              )}
            </FormControl>
          </div>
          <br />
          <Button variant="contained" onClick={submit}>
            Spremi
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={cancelToggle}>
            Poništi
          </Button>
        </div>
      )}
    </FormValidator>
  );
};

ActivityTypeForm.schema = {
  properties: {
    desc: {
      type: "string",
      minLength: 1,
      errorMessage: "Polje mora biti ispunjeno"
    }
  }
};

export default ActivityTypeForm;
