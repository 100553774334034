import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText } from "@material-ui/core";
import FormValidator from "../../../../components/UI/FormValidator";

const form = {
  padding: "24px"
};

const PartnerClientTypeForm = ({
  title,
  partnerClientType,
  onChangeField,
  onSubmit,
  cancelToggle
}) => {
  return (
    <FormValidator
      schema={PartnerClientTypeForm.schema}
      submit={onSubmit}
      data={partnerClientType}
    >
      {({ error, errorMsg, submit }) => (
        <div style={form} className="establishment-chain-form">
          <h3>{title}</h3>
          <div className="form-control-grid">
            <FormControl fullWidth error={error("/desc")}>
              <InputLabel shrink={true} htmlFor="desc">
                Tip partnera
              </InputLabel>
              <Input
                id="desc"
                name="desc"
                value={partnerClientType.desc ? partnerClientType.desc : ""}
                onChange={({ target: { value } }) =>
                  onChangeField({ key: "desc", value })
                }
              />
              {error("/desc") && (
                <FormHelperText>{errorMsg("/desc")}</FormHelperText>
              )}
            </FormControl>
          </div>
          <br />
          <Button variant="contained" onClick={submit}>
            Spremi
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={cancelToggle}>
            Poništi
          </Button>
        </div>
      )}
    </FormValidator>
  );
};

PartnerClientTypeForm.schema = {
  properties: {
    desc: {
      type: "string",
      minLength: 1,
      isNotEmpty: true,
      errorMessage: "Potrebno je unijeti naziv"
    }
  }
};

export default PartnerClientTypeForm;
