// libs
import { useQuery } from "react-apollo";

// graphql
import { GUIDE_ENUM_VALUES } from "src/graphql/query/operative";

/**
 * useFetchGuideServicesTypes is used for fetching guide service type enums
 */
export const useFetchGuideEnums = variables => {
  const { data, loading, error } = useQuery(GUIDE_ENUM_VALUES, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
  });

  return {
    dataEnumTypes: data,
    loadingEnumTypes: loading,
    errorEnumTypes: error,
  };
};
